'use strict';

angular.module('postCenterWebClientApp')
  .directive('messageSticker', function () {
    return {
      scope: {
        src: '@'
      },
      templateUrl: 'blocks/message/views/message_sticker.html',
      restrict: 'E',
      controller: ['$scope',
      function ($scope) {
        jQuery('.magnific-popup-image').magnificPopup({
          type:'image',
          image: {
            cursor: null,
          }
        });

        $scope.$watch('src', function(src){
          $scope.actualSrc = getActualImgUrl($scope.src);
        });

        function getActualImgUrl(url){
          if(isFacebookWrappedUrl(url)){
            return URI(url).search(true).u;
          } else {
            return url;
          }
        }

        function isFacebookWrappedUrl(url){
          return url.indexOf("https://l.facebook.com/l.php") === 0;
        }
      }]
    };
  });
