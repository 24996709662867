const Collapse = Vue.component('Collapse', {
  props: {
    open: {type: Boolean, default: true},
    keepOpen: {type: Boolean, default: false},
    draggable: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      default: 'active',
      validate: theme => ['active', 'white'].includes(theme)
    },
    title: {
      type: String,
      default: 'Collapse title'
    },
    detail: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: ''
    },
    iconPosition: {
      type: String,
      default: null,
      validator: pos => ['left', 'right'].includes(pos)
    },
    warning: {
      type: Boolean,
      default: false
    },
    customPadding: {
      type: Object,
      default: () => ({xAxis: 2, yAxis: 2})
    }
  },
  computed: {
    getTheme() {
      return `theme--${this.theme}`;
    },
    bodyClasses() {
      const {xAxis, yAxis} = this.customPadding;
      const pY = `py-${yAxis}`;
      const pX = `px-${xAxis}`;
      return [pY, pX];
    },
    getIcon() {
      return this.warning ? 'fa fa-exclamation-triangle' : `fa-${this.icon}`;
    },
    getIconPosition() {
      const margin =
        this.iconPosition === 'right'
          ? 'ml-2'
          : this.iconPosition === 'left'
          ? 'mr-2'
          : '';
      return this.iconPosition ? `float-${this.iconPosition} ${margin}` : '';
    }
  },
  methods: {
    shouldToggle(event) {
      if (this.keepOpen) {
        event.preventDefault();
        event.stopPropagation();
      }
    }
  },
  template: `
    <details class="new-collapse" :class="getTheme" :open="keepOpen || open">
      <summary class="collapse-header" @click="shouldToggle($event)">
        <span v-if="draggable" class="fa fa-drag float-left"></span>
        <span v-if="icon" class="fa" :class="[getIcon, getIconPosition]"></span>
        <h5 class="bold d-inline">
        {{ title }}
        </h5>
        <span v-if="detail" class="ml-1">{{ detail }}</span>
        <slot name="optional" />
      </summary>
      <div class="collapse-content" :class="bodyClasses">
        <slot></slot>
      </div>
    </details>
  `
});
