'use strict';

angular.module('postCenterWebClientApp')
  .directive('messageBlocked', function () {
    return {
      scope: {
      },
      templateUrl: 'blocks/message/views/message_blocked.html',
      restrict: 'E',
      controller: ['$scope', '$filter',
        function ($scope, $filter) {
          $scope.name = $filter('translate')('MESSAGE_DOCUMENT_BLOCKED');
        }]
    };
  });
