/* eslint-disable vue/no-mutating-props */
/* eslint-disable vue/no-side-effects-in-computed-properties */
const BasicTable = Vue.component('BasicTable', {
  props: {
    rows: {
      type: Array,
      default: () => []
    },
    headings: {
      type: Array,
      default: () => []
    },
    tooltips: {
      type: Array,
      default: null
    },
    sorterFilter: {
      type: Array,
      default: null
    },
    striped: {
      type: Boolean,
      default: false
    },
    noResults: {
      type: String,
      default: ''
    },
    height: {
      type: [String, Number],
      default: 'auto'
    },
    sorterCallback: {
      type: Function,
      default: null
    },
    custom: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      order: 'desc',
      sortedBy: 0,
      active: 0
    };
  },
  computed: {
    stripedStyle() {
      return this.striped ? 'striped' : '';
    },
    getHeight() {
      const isNum = num => !isNaN(num);
      return {
        height: isNum(this.height) ? `${this.height}px` : this.height
      };
    },
    arrowIcon() {
      return this.order === 'desc' ? 'fa-long-arrow-down' : 'fa-long-arrow-up';
    },
    sorting() {
      const sortedRows = this.rows.sort((itemA, itemB) => {
        const isTotal = itemA[0] === '' || itemB[0] === '';
        const sortA =
          itemA[this.sortedBy] === '-' || isTotal ? 0 : itemA[this.sortedBy];
        const sortB =
          itemB[this.sortedBy] === '-' || isTotal ? 0 : itemB[this.sortedBy];
        if (this.order === 'asc') {
          if (typeof sortA === 'string') {
            return sortA.toLowerCase() > sortB.toLowerCase() ? -1 : 1;
          }
          return sortA - sortB;
        }
        if (typeof sortA === 'string') {
          return sortB.toLowerCase() > sortA.toLowerCase() ? -1 : 1;
        }
        return sortB - sortA;
      });
      return sortedRows;
    }
  },
  methods: {
    sortTableBy(index) {
      if (this.sortedBy === index) {
        this.order = this.order === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortedBy = index;
      }
      this.active = index;
    },
    customSorter(head, index) {
      if (!this.sorterFilter || this.sorterFilter.includes(head)) {
        this.sortTableBy(index);
        return this.sorterCallback({
          index,
          label: head,
          order: this.order
        });
      }
    },
    isActive(index) {
      return index === this.active;
    },
    isActiveClass(index) {
      return this.isActive(index) ? 'active' : '';
    }
  },
  template: `
    <div class="basic-table table-scroll" :style="getHeight" :class="stripedStyle">
        <table cellpadding="5">
            <thead>
              <tr class="table-head">
                <slot v-for="(head, index) in headings" name="header">
                  <th
                    v-if="!custom"
                    @click="sortTableBy(index)"
                    :class="isActiveClass(index)">
                    <span
                        v-tippy="{
                          animateFill: false,
                          arrow : true,
                          placement : 'bottom'
                        }"
                        v-if="head.tooltip"
                        :title="head.tooltip"
                      >
                      <i class="fa fa-question-circle-fas tooltip-icon"></i>
                      </span>
                    <span class="title" v-if="head.label">{{head.label}}</span>
                    <span class="title" v-else>{{head}}</span>
                    <span class="fa mx-1" :class="arrowIcon" v-if="isActive(index)" />
                  </th>
                  <th v-if="custom && sorterCallback === null" class="custom">
                    <span
                      v-tippy="{
                        animateFill: false,
                        arrow : true,
                        placement : 'bottom'
                      }"
                      v-if="tooltips"
                      :title="tooltips[index]"
                    >
                        <i class="fa fa-question-circle-fas tooltip-icon"></i>
                    </span>
                    <span class="title">{{head}}</span>
                  </th>
                  <th v-if="custom && sorterCallback !== null" class="custom"
                  :class="isActiveClass(index)"
                  @click="customSorter(head, index)">
                    <span class="title">{{head}}</span>
                    <span class="fa mx-1" :class="arrowIcon" v-if="isActive(index)" />
                  </th>
                  
                </slot>
              </tr>
            </thead>
            <tbody>
            <slot>
            <tr v-for="row in sorting">
              <td v-for="elem in row">
                {{ elem }}
              </td>
            </tr>
            </slot>
            <tr v-if="!custom && !rows.length">
              <td :colspan="headings.length" class="no-results">{{ noResults }}</td>
            </tr>
            </tbody>
        </table>
    </div>
  `
});
