(function(module) {
  'use strict';
  botActivation.$inject = [];
  function botActivation() {
    return {
      templateUrl: 'blocks/case/views/case_bot_activation.html',
      restrict: 'E',
      scope: {
        userObject: '=',
        caseObject: '=',
        bots: '=',
        botTicketState: '='
      },
      controller: 'botActivationCtrl',
      controllerAs: 'vm',
      bindToController: true
    };
  }
  module.directive('botActivation', botActivation);
})(angular.module('postCenterWebClientApp'));
