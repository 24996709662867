'use strict';

var app = angular.module('postCenterWebClientApp');
app.controller('CustomOnlinestatusAddCtrl', function (
  $scope, $rootScope, $filter, CustomOnlineStatuses, AdNotification
) {

  var vm = this;
  vm.labels = [];
  $scope.onlineStatusOptions = buildOnlineStatusOptions();
  $scope.loading = false;
  $scope.showErrorMessage = false;
  vm.index = 0;

  updateStatuses();
  setTimeout(
  () => document.querySelector('.sidebar-content .form-control')?.focus(),
  300
  )

  function updateStatuses() {
    CustomOnlineStatuses.fetch().then(function () {
        vm.statuses = CustomOnlineStatuses.list();
        _.each(vm.statuses, function (status, idx) {
          vm.labels.push((status.label).toLowerCase());
          if (status.index >= vm.index){
            vm.index = status.index + 1;
          }
        });
    });
  }
  function validateLabel(label){
    label = label.toLowerCase();
    if (vm.labels.indexOf(label) > -1){
      return false;
    }
    return true;
  }

  function buildOnlineStatusOptions() {
    var options = {};
    options[2] = $filter('onlineStatusFilter')(2);
    options[1] = $filter('onlineStatusFilter')(1);
    options[0] = $filter('onlineStatusFilter')(0);
    return options;
  }

  function check_boolean_value(value) {
    if (angular.isUndefined(value)){
      return false;
    }
    return value;
  }
  $scope.confirmAddCos = function(){
    $scope.loading = true;
    $scope.showErrorMessage = false;
    var action = 'add_cos';
    var label = $scope.label;
    var status_kind = $scope.online_status;
    var blocks = $scope.blocks;
    var keep_assignation = $scope.keep_assignation;
    var persists = $scope.persists;
    var assignable = $scope.assignable;
    if (!validateLabel($scope.label) ){
      $scope.showErrorMessage = true;
      $scope.errorMessage = 'COS_ERROR_DUPLICATED_NAME';
    } else {
      persists = check_boolean_value(persists);
      blocks = check_boolean_value(blocks);
      assignable = check_boolean_value(assignable);
      keep_assignation = check_boolean_value(keep_assignation);
      var data = {};
      data.label = label;
      data.online_status = status_kind;

      data.blocks = blocks;
      data.keep_assignation = keep_assignation;
      data.persists = persists;
      data.assignable = assignable;
      data.index = vm.index;
      CustomOnlineStatuses.add(data).then( function(){
        AdNotification.success(201, action);
        $rootScope.goToParentState();
        $rootScope.$broadcast('cos:create_cos');
      });
    }
    $scope.loading = false;
  };
});