'use strict';

var app = angular.module('postCenterWebClientApp');

app.controller('NotificationAgendaCtrl', NotificationAgendaCtrl);
NotificationAgendaCtrl.$inject = [
  '$scope',
  '$filter',
  '$rootScope',
  'InitialData',
  'NotificationGroupService',
  'AdNotification',
  'FireTagService'
];

function NotificationAgendaCtrl(
  $scope,
  $filter,
  $rootScope,
  InitialData,
  NotificationGroupService,
  AdNotification,
  FireTagService
) {
  FireTagService.setPageView({
    title: 'Agenda corporativa',
    path: `/${window.location.hash}`
  });
  $scope.loadingUsers = false;
  $scope.loadingGroups = false;
  $rootScope.adminTab.selected = 'notification-agenda';

  var addUserEvent = 'changed_notification_agenda';

  function refreshData() {
    $scope.loadingUsers = true;
    InitialData.refreshNotificationUsers().then(() => {
      $scope.loadingUsers = false;
      $scope.tabs[0].data = angular.copy($rootScope.notificationUsers);
    });

    $scope.loadingGroups = true;
    InitialData.refreshNotificationGroups().then(() => {
      $scope.loadingGroups = false;
      $scope.tabs[1].data = angular.copy($rootScope.notificationGroups);
    });

    InitialData.refreshNotificationEntitySearch();
  }

  $scope.$on('$destroy', () => {
    killAddUserEvent();
  });

  var killAddUserEvent = $rootScope.$on(addUserEvent, () => {
    refreshData();
  });

  $scope.tabs = [
    {
      heading: $filter('translate')('NOTIFICATION_AGENDA_USERS'),
      route: 'admin.notification-agenda.users',
      data: angular.copy($rootScope.notificationUsers),
      delete: deleteAgendaUser
    },
    {
      heading: $filter('translate')('NOTIFICATION_AGENDA_GROUPS'),
      route: 'admin.notification-agenda.groups',
      data: angular.copy($rootScope.notificationGroups),
      delete: deleteAgendaGroup
    }
  ];

  function deleteAgendaUser(user) {
    NotificationGroupService.deleteNotificationUser(user).then(
      () => {
        AdNotification.success(200, 'deleted_notification_agenda_user');
        refreshData();
      },
      () => {
        AdNotification.error(500, 'deleted_notification_agenda_user');
      }
    );
  }

  function deleteAgendaGroup(group) {
    NotificationGroupService.deleteNotificationGroup(group).then(
      () => {
        AdNotification.success(200, 'deleted_notification_agenda_group');
        refreshData();
      },
      () => {
        AdNotification.error(500, 'deleted_notification_agenda_group');
      }
    );
  }
}

app.controller('NotificationAgendaUserHandleCtrl', NotifyAgendaUserHandleCtrl);
NotifyAgendaUserHandleCtrl.$inject = [
  '$scope',
  'NotificationGroupService',
  'AdNotification',
  '$timeout',
  '$rootScope',
  '$transition$'
];

function NotifyAgendaUserHandleCtrl(
  $scope,
  NotificationGroupService,
  AdNotification,
  $timeout,
  $rootScope,
  $transition$
) {
  var promiseCheckEmail;
  var originalEmail;

  $scope.checkEmailAvailability = function () {
    if (promiseCheckEmail) {
      $timeout.cancel(promiseCheckEmail);
    }

    if (originalEmail === $scope.user.email) {
      $scope.myform.email.$setValidity('availability', true);
      return;
    }

    if ($scope.myform.email.$error.email !== true) {
      $scope.checkingEmailAvailability = true;
      promiseCheckEmail = NotificationGroupService.isEmailTaken(
        $scope.user.email
      );

      promiseCheckEmail.then(
        data => {
          $scope.checkingEmailAvailability = false;
          if (data['is_available'] === true) {
            $scope.myform.email.$setValidity('availability', true);
          } else {
            $scope.myform.email.$setValidity('availability', false);
          }
        },
        error => {
          $scope.checkingEmailAvailability = false;
          $scope.myform.email.$setValidity('availability', false);
        }
      );
    }
  };

  $scope.confirmAction = function () {
    if ($scope.action === 'Add') {
      addNewUser();
    } else {
      editUser();
    }
  };

  function addNewUser() {
    $scope.saving = true;
    NotificationGroupService.addNotificationUsers($scope.user).then(
      () => {
        $scope.saving = false;
        $rootScope.$emit('changed_notification_agenda');
        AdNotification.success(201, 'added_notification_agenda_user');
        $rootScope.goToParentState();
      },
      error => {
        AdNotification.error(error.status, 'added_notification_agenda_user');
        $scope.saving = false;
      }
    );
  }

  function editUser() {
    $scope.saving = true;
    NotificationGroupService.editNotificationUsers($scope.user).then(
      () => {
        $scope.saving = false;
        $rootScope.$emit('changed_notification_agenda');
        AdNotification.success(201, 'edit_notification_agenda_user');
        $rootScope.goToParentState();
      },
      error => {
        AdNotification.error(error.status, 'edit_notification_agenda_user');
        $scope.saving = false;
      }
    );
  }

  $scope.isEditing = false;
  if ($transition$.params()['resource_id']) {
    $scope.action = 'Edit';
    $scope.isEditing = true;
    var resourceId = $transition$.params()['resource_id'];
    $scope.user = _.find($rootScope.notificationUsers, user => {
      return user['resource_id'].toString() === resourceId;
    });
    originalEmail = angular.copy($scope.user.email);
  } else {
    $scope.action = 'Add';
    $scope.user = {};
  }

  setTimeout(
    () => document.querySelector('.sidebar-content .form-control')?.focus(),
    200
  );
}

app.controller(
  'NotificationAgendaGroupHandleCtrl',
  NotifyAgendaGroupHandleCtrl
);
NotifyAgendaGroupHandleCtrl.$inject = [
  '$scope',
  'NotificationGroupService',
  'AdNotification',
  '$timeout',
  '$rootScope',
  '$transition$'
];

function NotifyAgendaGroupHandleCtrl(
  $scope,
  NotificationGroupService,
  AdNotification,
  $timeout,
  $rootScope,
  $transition$
) {
  var promiseCheckName;
  var originalName;

  setTimeout(
    () => document.querySelector('.sidebar-content .form-control')?.focus(),
    200
  );

  $scope.checkGroupNameAvailability = function () {
    if (promiseCheckName) {
      $timeout.cancel(promiseCheckName);
    }

    if (originalName === $scope.group.name) {
      $scope.myform.name.$setValidity('availability', true);
      return;
    }

    $scope.checkingGroupNameAvailability = true;
    promiseCheckName = NotificationGroupService.isGroupNameTaken(
      $scope.group.name
    );

    promiseCheckName.then(
      data => {
        $scope.checkingGroupNameAvailability = false;
        if (data['is_available'] === true) {
          $scope.myform.name.$setValidity('availability', true);
        } else {
          $scope.myform.name.$setValidity('availability', false);
        }
      },
      () => {
        $scope.checkingGroupNameAvailability = false;
        $scope.myform.name.$setValidity('availability', false);
      }
    );
  };

  $scope.confirmAction = function () {
    if ($scope.action === 'Add') {
      addNewGroup();
    } else {
      editGroup();
    }
  };

  function addNewGroup() {
    $scope.saving = true;
    $scope.group.users = getUsersFromList($scope.users);

    NotificationGroupService.addNotificationGroup($scope.group).then(
      () => {
        $scope.saving = false;
        $rootScope.$emit('changed_notification_agenda');
        AdNotification.success(201, 'added_notification_agenda_group');
        $rootScope.goToParentState();
      },
      error => {
        AdNotification.error(error.status, 'added_notification_agenda_group');
        $scope.saving = false;
      }
    );
  }

  function getUsersFromList(users) {
    return _.chain(users)
      .filter(user => {
        return user.added;
      })
      .map(user => {
        return {resource_id: user['resource_id']};
      });
  }

  function editGroup() {
    $scope.saving = true;
    $scope.group.users = getUsersFromList($scope.users);

    NotificationGroupService.editNotificationGroup($scope.group).then(
      () => {
        $scope.saving = false;
        $rootScope.$emit('changed_notification_agenda');
        AdNotification.success(201, 'edit_notification_agenda_group');
        $rootScope.goToParentState();
      },
      error => {
        AdNotification.error(error.status, 'edit_notification_agenda_group');
        $scope.saving = false;
      }
    );
  }

  $scope.isEditing = false;
  $scope.users = angular.copy($rootScope.notificationUsers);
  if ($transition$.params()['resource_id']) {
    $scope.action = 'Edit';
    $scope.isEditing = true;
    var resourceId = $transition$.params()['resource_id'];
    $scope.group = _.find($rootScope.notificationGroups, group => {
      return group['resource_id'].toString() === resourceId;
    });

    $scope.users = _.map($scope.users, user => {
      user.added = checkIsAdded(user, $scope.group.users);
      return user;
    });

    function checkIsAdded(user, groupUsers) {
      for (var i in groupUsers) {
        var guser = groupUsers[i];
        if (guser['resource_id'] === user['resource_id']) {
          return true;
        }
      }
      return false;
    }

    originalName = angular.copy($scope.group.name);
  } else {
    $scope.action = 'Add';
    $scope.group = {};
  }
}
