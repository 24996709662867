'use strict';

angular.module('postCenterWebClientApp')
  .directive('cannedResponseSuggestions', function(){
    return {
      templateUrl: 'blocks/canned_response/views/canned_response_suggestions.html',
      require: '^^cannedResponseContext',
      scope: {},
      link: function(scope, elem, attrs, contextCtrl){
        scope.contextCtrl = contextCtrl;
      }
    };
  });
