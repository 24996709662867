class NotificationGroupService {
  constructor(AdRequest) {
    this.AdRequest = AdRequest;
  }
  getNotificationUsers() {
    return this.AdRequest.get('/api/v1/notification_groups/users/');
  }

  getNotificationEntitySearch() {
    return this.AdRequest.get('/api/v1/notification_groups/search/');
  }

  addNotificationUsers({email, first_name, last_name, phone}) {
    const data = {
      email,
      first_name,
      last_name,
      phone
    };
    return this.AdRequest.post('/api/v1/notification_groups/users/', data);
  }

  editNotificationUsers({email, first_name, last_name, phone, resource_id}) {
    const data = {
      email,
      first_name,
      last_name,
      phone
    };
    return this.AdRequest.put(
      `/api/v1/notification_groups/users/${resource_id}/`,
      data
    );
  }

  deleteNotificationUser({resource_id}) {
    return this.AdRequest.delete(
      `/api/v1/notification_groups/users/${resource_id}/`
    );
  }

  isEmailTaken(email) {
    return this.AdRequest.post('/api/v1/notification_groups/user/available/', {
      email
    });
  }

  isGroupNameTaken(name) {
    return this.AdRequest.post('/api/v1/notification_groups/group/available/', {
      name
    });
  }

  addNotificationGroup({name, users}) {
    return this.AdRequest.post('/api/v1/notification_groups/groups/', {
      name,
      users
    });
  }

  editNotificationGroup({name, users, resource_id}) {
    return this.AdRequest.put(
      `/api/v1/notification_groups/groups/${resource_id}/`,
      {
        name,
        users
      }
    );
  }

  deleteNotificationGroup({resource_id}) {
    return this.AdRequest.delete(
      `/api/v1/notification_groups/groups/${resource_id}/`
    );
  }

  getNotificationGroups() {
    return this.AdRequest.get('/api/v1/notification_groups/groups/');
  }
}
angular
  .module('postCenterWebClientApp')
  .service('NotificationGroupService', NotificationGroupService);
