(function (d, s, id) {
  var js;
  var fjs = d.getElementsByTagName(s)[0];
  if (d.getElementById(id)) {
    return;
  }
  js = d.createElement(s);
  js.id = id;
  js.src = '//connect.facebook.net/en_US/sdk.js';
  if (fjs.parentNode) {
    fjs.parentNode.insertBefore(js, fjs);
  }
})(document, 'script', 'facebook-jssdk');
