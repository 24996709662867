'use strict';

(function(){
  var app = angular.module('postCenterWebClientApp');
  app.factory('MetadataHelper', function($window, $rootScope, adDataValidator){
    return {
      checkRequiredFields: function(customDataDefs, newCustomData, form, scrollableView){

        var filterFields = getFilterRequiredFields(customDataDefs, newCustomData);
        var invalidFields = filterFields[0];
        var validFields = filterFields[1];
        var fieldElements = validFields.map(function(key){
          return scrollableView.find('#metadata-' + key);
        });
        markElementAsCorrect(fieldElements);
        fieldElements = invalidFields.map(function(key){
          return scrollableView.find('#metadata-' + key);
        });
        markElementAsWrong(scrollableView, fieldElements);
        if (invalidFields.length === 0){
          return true;
        }
        return false;

      },
      validCountryId: function(customDataDefs, customData, form, view) {

        var country = $rootScope.establishment.country;
        if (country.key !== 'CL') {
          return true;
        }
        return validateField(
          'country_id', customData, view,
          adDataValidator.checkRut, adDataValidator.formatRut
        );

      },
      validEmail: function(customDataDefs, customData, form, view) {
        var noFormat = function (x){return x;}
        return validateField(
          'email', customData, view, adDataValidator.checkEmail, noFormat
        );
      },
      countryIdFormatter: function(value) {
        var country = $rootScope.establishment.country;
        if (country.key !== 'CL') {
          return value;
        } else {
          return adDataValidator.formatRut(value);
        }
      },
      countryIdValidator: function(value) {
        var country = $rootScope.establishment.country;
        if (country.key !== 'CL') {
          return true;
        } else {
          return adDataValidator.checkRut(value);
        }
      }

    };

    function validateField(key, customData, view, validator, formatter) {

      if (adDataValidator.existsAndNotEmpty(key, customData)) {
        var value = formatter(customData[key]);
        customData[key] = value;
        var elementKey, element;
        if (validator(value)) {
          elementKey = '#metadata-' + key;
          element = view.find(elementKey);
          markElementAsCorrect([element]);
          return true;
        } else {
          elementKey = '#metadata-' + key;
          element = view.find(elementKey);
          markElementAsWrong(view, [element]);
          return false;
        }
      }
      return true;
    }

    function markElementAsWrong(scrollableView, elements) {

      var element = elements[0];
      var errorClass = 'custom-data__field-row_red';
      $rootScope.$applyAsync(function(){
        $window.requestAnimationFrame(function(){
          scrollableView.scrollTo(element, 300);
          angular.forEach(elements, function(fieldEle){
            fieldEle.addClass(errorClass);
            fieldEle.find($(".fa")).removeClass('fa-check-circle');
            fieldEle.find($(".fa")).addClass('fa-times-circle');

          });
        });
      });
    }

    function markElementAsCorrect(elements) {
      var errorClass = 'custom-data__field-row_red';
      $rootScope.$applyAsync(function(){
        angular.forEach(elements, function(fieldEle){
          fieldEle.find($(".fa")).removeClass('fa-times-circle');
          fieldEle.find($(".fa")).addClass('fa-check-circle');
          fieldEle.removeClass(errorClass);
        });
      });
    }

    function getFilterRequiredFields(customDataList, newCustomData){
      var invalidFields = [];
      var validFields = [];
      var toValidate = getRequiredCustomData(customDataList);
      for (var i in toValidate) {
        var key = toValidate[i];
        var valueToValidate = newCustomData[key];
        if (angular.isUndefined(valueToValidate) || valueToValidate === '' || valueToValidate === null || adDataValidator.isBlank(valueToValidate)) {
          invalidFields.push(key);
        } else if (angular.isObject(valueToValidate)) {
          var isInvalid = true;
          if (angular.isDate(valueToValidate)){
            isInvalid = false;
          }
          for (var keyValue in valueToValidate) {
            if (valueToValidate[keyValue]) {
              isInvalid = false;
              break;
            }
          }
          if (valueToValidate)
          if(isInvalid){
            invalidFields.push(key);
          } else {
            validFields.push(key);
          }
        } else {
          validFields.push(key);
        }
      }
      return [invalidFields, validFields];
    }

    function getRequiredCustomData(customDataList) {
      return _.filter(customDataList, function(datum){
        return !datum.deleted && datum.required;
      }).map(function(datum){
        return datum.key;
      });
    }

  });
})();
