'use strict';
angular
  .module('postCenterWebClientApp')
  .controller(
    'AnalyticsCtrl',
    function (
      $scope,
      $rootScope,
      AdNotification,
      InitialData,
      $translate,
      AnalyticsService,
      UserService: UserService,
      EstablishmentService: EstablishmentService,
      $window,
      localStorageService,
      AdModal,
      $timeout,
      wootricFactory,
      StylesService,
      VersionService: VersionService,
      AppStateService: AppStateService,
    ) {

      let toDestroy: {[key: string]: (() => {}) | undefined } = {
        onGotEstablishment: undefined
      }

      function redirectIfRequired(trialStatus: TrialStatus) {
        const isEmpty = trialStatus.days_remaining === null || trialStatus.days_remaining === undefined;
        const isExpired = !isEmpty && trialStatus.days_remaining <= 0;
        const inBilling = AppStateService.currentState().isBillingState();
        if (isExpired && !inBilling) {
          AppStateService.goToInbox()
        }
      }

      function authFn() {
        return Promise.all([
          EstablishmentService.getEstablishment(),
          UserService.getProfile(),
          VersionService.getVersion(),
          StylesService.getStyles(),
          EstablishmentService.getTrialStatus()
        ]).then(([establishment, user, version, styles, trialStatus]) => {

          const {
            first_name, last_name, isAnalyst, isAdmin, isSupervisor,
            profile: {language}
          } = user
          
          $scope.version = version
          $scope.establishment = establishment.name;
          $scope.features = establishment.features;
          $scope.showEPA = $scope.features.satisfaction_module;
          $scope.analyticTab = $rootScope.analyticTab;
          $scope.username = `${first_name} ${last_name}`;
          $scope.isAnalyst = isAnalyst;
          $scope.isAdmin = isAdmin;
          $scope.isSupervisor = isSupervisor;
          $scope.styles = styles
          $scope.loader.show = false;

          $translate.use(language);
          /*Lets send location change to intercom*/
          if (typeof $window.Intercom !== 'undefined') {
            $window.Intercom('update');
          }
          wootricFactory.run(user.email, user.date_joined);

          redirectIfRequired(trialStatus);
        })
      }

      toDestroy.onChildReady = $rootScope.$on('SIDEBAR_READY', () => {
        const data = {
          features: $scope.features,
          analyticTab: $scope.analyticTab
        }
        $rootScope.$emit('ANALYTICS_GOT_INITIAL_DATA', data);
      });

      $scope.$on('$destroy', () => {
  
        if (toDestroy.onChildReady) {
          toDestroy.onChildReady();
        }

        analyticsGetSampleOff();
      });

      $scope.openShortcutModal = function () {
        const modalConfig = {
          template: 'blocks/crm/views/shortcut_modal.html'
        };

        AdModal.build(modalConfig);
      };

      function refreshAnalytics() {
        InitialData.refreshAnalytics()
          .then(() => {
            authFn();
          })
          .catch(function (exception) {
            AdNotification.error(exception, 'loading_manage_account');
          });
      }

      function init() {
        $scope.username = ''
        $scope.loader = {
          show: true
        }
        refreshAnalytics()
      }

      init();

      $scope.timeControlOnSelectedPeriod = function (period) {
        const data = {
          delta: period.delta,
          granularity: period.key,
          now: period.now,
          obj: period
        };

        $scope.$broadcast('analytics:gotPeriod', data);
      };

      $scope.timeControlOptions = {
        name: 'analytics_selector',
        onSelected: $scope.timeControlOnSelectedPeriod,
        shortcuts: true
      };

      $scope.timeControlOnSelectedPeriod = function (period) {
        const data = {
          delta: period.delta,
          granularity: period.key,
          now: period.now,
          obj: period
        };

        $scope.$broadcast('analytics:gotPeriod', data);
      };

      $scope.timeControlOptions = {
        name: 'analytics_selector',
        onSelected: $scope.timeControlOnSelectedPeriod,
        shortcuts: true
      };

      const analyticsGetSampleOff = $scope.$on('analytics:getSample', function (event, config) {
        const {name, granularity, delta, now} = config;

        if (!config.silent) {
          $scope.$broadcast('analytics:requestingSample', name);
        }
        AnalyticsService.getStats({metric: name, granularity, delta, now}).then(
          response => {
            if (response && response.data) {
              let labels = response.data.labels || [];
              response.data.labels = labels.map(repairTimestamp);
            }

            const data = {
              name: config.name,
              obj: response
            };

            $scope.$broadcast('analytics:gotSample', data);
          }
        );

        function repairTimestamp(timestamp) {
          let timestampMs = timestamp * 1000;
          if (config.granularity === 'mo' || config.granularity === 'd') {
            const fixedLabel = moment.utc(timestampMs);

            fixedLabel.hour(0);
            fixedLabel.minute(0);
            fixedLabel.second(0);
            fixedLabel.millisecond(0);

            if (config.granularity === 'mo') {
              fixedLabel.date(1);
            }

            const timezone = moment.tz.zone($rootScope.establishment.timezone);
            const offsetInMinutes = timezone.offset(timestampMs);
            const offsetInMillis = offsetInMinutes * 60 * 1000;

            timestampMs = fixedLabel.valueOf() + offsetInMillis;
          }

          return timestampMs;
        }
      });

      $scope.navbarExpanded = localStorageService.get('navbarExpanded');
      if ($scope.navbarExpanded === null) {
        $scope.navbarExpanded = true;
      }

      $scope.navbarToggle = function () {
        $scope.navbarExpanded = !$scope.navbarExpanded;
        localStorageService.set('navbarExpanded', $scope.navbarExpanded);
        $window.requestAnimationFrame(function () {
          $timeout(function () {
            $scope.$broadcast('highchartsng.reflow');
          }, 0);
        });
      };
    }
  );
