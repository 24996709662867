(module => {
  
  AssignmentsCtrl.$inject = [
    '$scope',
    '$rootScope',
    '$filter',
    'UserService',
    'FireTagService'
  ];
  function AssignmentsCtrl(
    $scope,
    $rootScope,
    $filter,
    UserService: UserService,
    FireTagService
  ) {
    const vm = this
    UserService.getProfile().then(user => {
      $scope.isSupervisorNotAdmin = user.isSupervisorNotAdmin
      const translate = property => $filter('translate')(property)

      const directAssignment = {
        enable: true,
        header: translate('ASSIGNMENTS_DIRECT_TITLE')
      };
      const tabs = [
        translate('ASSIGNMENTS_AUTO_ASSIGNMENT_TITLE'),
        translate('ASSIGNMENTS_AUTO_ASSIGNMENT_MONITORING')
      ];
      if (!$scope.isSupervisorNotAdmin) {
        tabs.splice(1, 0, translate('COS_ADMIN_TITLE'))
      }

      $scope.activeTab = 0
      $scope.tabs = [...tabs]
      $scope.legacy = {header: false}
      if (!$scope.isSupervisorNotAdmin && directAssignment.enable) {
        $scope.tabs.push(directAssignment.header)
      }

      $rootScope.adminTab.selected = 'assignments'

      const assignmentOpenConfigWatcherOff = $rootScope.$watch('assignmentOpenConfig', newval => {
        if (newval) {
          $scope.activeTab = 0;
        }
      })

      $scope.$on('$destroy', function () {
        assignmentOpenConfigWatcherOff();
      });

      $scope.setActiveTab = index => {
        $scope.activeTab = index
      }
    })
  
    vm.$onInit = () => {
      FireTagService.setPageView({
        title: 'Administrar asignaciones',
        path: `/${window.location.hash}`
      })
    }
  }
  module.controller('AssignmentsCtrl', AssignmentsCtrl)
})(angular.module('postCenterWebClientApp'))
