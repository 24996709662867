(module => {
  'use strict';
  SettingsNotificationsCtrl.$inject = [
    '$scope',
    '$rootScope',
    'AdNotification',
    'NotificationsService',
    'FireTagService'
  ];
  function SettingsNotificationsCtrl(
    $scope,
    $rootScope,
    AdNotification,
    NotificationsService,
    FireTagService
  ) {
    FireTagService.setPageView({
      title: 'Notificaciones',
      path: `/${window.location.hash}`
    });

    $rootScope.settingsTab.selected = 'notifications';

    $scope.dataset = [];

    $scope.state = {
      save: {
        requestPending: false
      }
    };

    function generateConfigDataset() {
      let dataset = [];

      for (let i = 0; i <= 6; i++) {
        dataset.push({
          week_day_number: i,
          config: []
        });

        for (let j = 0; j < 24; j++) {
          dataset[i]['config'].push({
            local_time: j,
            enabled: false
          });
        }
      }

      return dataset;
    }

    function getConfig(callbacks) {
      NotificationsService.getDelayed()
        .then(response => {
          if (callbacks && typeof callbacks.success === 'function') {
            callbacks.success(response);
          }
        })
        .catch(exception => {
          if (callbacks && typeof callbacks.fail === 'function') {
            callbacks.fail(exception);
          }
        });
    }

    function applyConfigToDataset(configs) {
      let day = {};
      let hour = {};
      angular.forEach(configs, (config, key) => {
        day = _.findWhere($scope.dataset, {
          week_day_number: config.week_day_number
        });
        if (day) {
          hour = _.findWhere(day.config, {local_time: config.local_time});
          if (hour) {
            hour.enabled = true;
          }
        }
      });
    }

    $scope.saveConfig = function() {
      let state = $scope.state.save;
      let action = 'edit_notifications';

      function getFormattedConfig(dataset) {
        let formattedConfig = [];

        angular.forEach(dataset, (day, index) => {
          angular.forEach(day.config, (config, key) => {
            if (config.enabled) {
              formattedConfig.push({
                local_time: config.local_time,
                week_day_number: day.week_day_number
              });
            }
          });
        });
        return formattedConfig;
      }

      if (!state.requestPending) {
        state.requestPending = true;

        NotificationsService.setDelayed(getFormattedConfig($scope.dataset))
          .then(() => {
            AdNotification.success(201, action);
          })
          .catch(exception => {
            AdNotification.error(exception, action);
          })
          .finally(() => {
            state.requestPending = false;
          });
      }
    };

    function init() {
      $scope.dataset = generateConfigDataset();

      getConfig({
        success(config) {
          applyConfigToDataset(config);
        },
        fail() {
          //fail
        }
      });
    }

    init();
  }
  module.controller('SettingsNotificationsCtrl', SettingsNotificationsCtrl);
})(angular.module('postCenterWebClientApp'));
