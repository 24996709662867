const MonitoringSummary = Vue.component('monitoring-summary', {
  props: {
    states: {type: Array, default: () => []}
  },
  data() {
    return {
      selectedState: '',
      openOnly: {not_addressed: true, not_answered: true}
    };
  },
  computed: {...Vuex.mapGetters(['getTableByState'])},
  methods: {
    tableExists(stateKey = '') {
      return this.getTableByState(stateKey);
    },
    toggleDetails(key = '') {
      if (this.selectedState === key) {
        this.selectedState = '';
      } else if (this.tableExists(key)) {
        this.selectedState = key;
      }
    }
  },
  template: `
    <section>
      <ul class="monitoring--main-metrics list-unstyled scrollable pb-1">
        <li class="card card__shadow m-0"
            v-for="state in states"
            :key="state.key"
            @click="toggleDetails(state.key)"
            :class="{'clickable': tableExists(state.key)}">
          <monitoring-ticket-metric :state="state.key"
                                    :title="state.label"
                                    :active="state.key === selectedState"
                                    :force-open-only="openOnly[state.key]" />
        </li>
      </ul>
      <footer v-if="selectedState" :key="selectedState">
        <monitoring-summary-detail :state-key="selectedState" />
      </footer>
    </section>
  `
});

const MonitoringGoToTicket = Vue.component('monitoring-go-to-ticket', {
  computed: {
    href() {
      const {ticketId} = this.$attrs;

      if (!ticketId) {
        return '';
      }

      return `/#/case/${ticketId}`;
    }
  },
  template: `
    <a target="_blank" :href="href">{{$attrs.ticketId}}</a>
  `
});

const MonitoringSummaryDetail = Vue.component('monitoring-summary-detail', {
  props: {
    stateKey: {type: String, required: true}
  },
  data() {
    const defaultColumns = ['name', 'ticketId', 'created', 'sn'];
    const tableColumns = {
      opened: defaultColumns,
      not_addressed: defaultColumns,
      not_answered: defaultColumns,
      not_assigned: defaultColumns.slice(1)
    };
    return {
      page: 1,
      tableColumns,
      loading: false,
      table: {headings: [], rows: []}
    };
  },
  computed: {
    ...Vuex.mapState(['lastUpdateTime']),
    ...Vuex.mapGetters(['getTableByState', 'getChannelTitle']),
    tableKey() {
      return `${this.stateKey}_detail_table`;
    }
  },
  methods: {
    ...Vuex.mapActions(['getMetric']),
    setColumns() {
      const _getColumnComponent = field => {
        if (field === 'ticketId') {
          return 'monitoring-go-to-ticket';
        }

        return 'span';
      };
      const columns = this.tableColumns[this.stateKey].map(column => ({
        field: column,
        label: this.$t(`columns.${column}`),
        component: _getColumnComponent(column)
      }));
      this.table.headings = columns;
    },
    getParsedRows(rows = []) {
      return rows.map(row => ({
        name: row.executive?.name || this.$tc('agent', 0),
        sn: this.getChannelTitle(row.sn),
        ticketId: row.identifier,
        created: moment.unix(row.created).format('DD/MM/YYYY hh:mm')
      }));
    },
    loadRows() {
      if (!this.page) {
        return;
      }

      this.loading = true;
      this.getMetric({state: this.tableKey, page: this.page})
        .then(({cases, page, pages}) => {
          this.page = page === pages ? null : page + 1;
          const {rows: current} = this.table;
          const data = [...current, ...this.getParsedRows(cases)];
          const deduplicated = Array.from(new Set(data))
          this.table.rows = deduplicated
        })
        .catch(() => (this.page = null))
        .finally(() => (this.loading = false));
    }
  },
  mounted() {
    this.setColumns();
    this.loadRows();
  },
  template: `
    <div class="card p-1">
      <p v-if="!loading && !table.rows.length">{{ $t('no_data_available') }}</p>
      <Table v-else
             class="scrollable"
             :headings="table.headings"
             :rows="table.rows"
             v-infinite-scroll="loadRows" />
      <div v-show="loading" class="text-center">
        <Loader size="2x" />
      </div>
    </div>
  `
});
