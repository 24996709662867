'use strict';
(function(module) {
  function caseMetadata() {
    return {
      templateUrl: 'blocks/case/views/case_metadata.html',
      restrict: 'E',
      controller: 'caseMetadataCtrl',
      controllerAs: 'vm',
      scope: {caseObject: '='},
      bindToController: true,
      require: ['^^caseSideView', '^^caseDetail'],
      link: function(scope, _elem, _attrs, ctrls) {
        const caseSideViewCtrl = ctrls[0];
        const caseDetailCtrl = ctrls[1];
        caseDetailCtrl.setCaseMetadataCtrl(scope.ctrl);
        const currentTabWatcherOff = scope.$watch(
          function() {
            return caseSideViewCtrl.currentTab;
          },
          function(currentTab) {
            if (currentTab === 'caseMetadata') {
              caseSideViewCtrl.currentTabAltBg = true;
            }
          }
        );
        scope.$on('$destroy', function () {
          currentTabWatcherOff();
        });
      }
    };
  }
  module.directive('caseMetadata', caseMetadata);
})(angular.module('postCenterWebClientApp'));
