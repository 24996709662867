'use strict';

var app = angular.module('postCenterWebClientApp');
app.directive('allTabs', function() {
  return {
    templateUrl: 'blocks/admin/views/social_accounts/all_tabs.html',
    restrict: 'E',
    scope: {
      currentTab: '=',
      refreshAccounts: '='
    },
    controller: [
      '$scope',
      '$rootScope',
      'EstablishmentService',
      'InitialData',
      'AdNotification',
      'AdRequest',
      'FRONTEND_VUE_URL',
      '$sce',
      function(
        $scope,
        $rootScope,
        EstablishmentService,
        InitialData,
        AdNotification,
        AdRequest,
        FRONTEND_VUE_URL,
        $sce
      ) {
        const vm = this;

        vm.$onInit = () => {
          if ($scope.currentTab.socialNetwork === 'whatsapp') {
            window.addEventListener('message', handleIframeMessage);
          }
        };

        vm.$onDestroy = () => {
          if ($scope.currentTab.socialNetwork === 'whatsapp') {
            window.removeEventListener('message', handleIframeMessage);
          }
        }

        $scope.iFrameUrl = function (sn) {
          const token = AdRequest.getToken()
          let url = `${FRONTEND_VUE_URL}/frames/${sn}/channels?authToken=${token}`
          return $sce.trustAsResourceUrl(url);
        }

        function handleIframeMessage ({data}) {
          if (data.type !== 'configEvent') {
              return;
          }
          const {message} = data;
          const actionMap = {
              notify: notifyMessage,
              redirect: deskRedirect
          };
          const fun = actionMap[message.action];
          if (fun) fun(message);
        }

        function notifyMessage(message) {
          const {text: messageText, type} = message;
          if(type === 'success') {
              AdNotification.success(200, messageText);
          }
          else if(type === 'error'){
            AdNotification.error(400, messageText)
          }
        }

        function deskRedirect({url}) {
            window.location.href = url;
        }
      }
    ]
  };
});
