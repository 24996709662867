(module => {
  'use strict';
  BannedKeywordsCtrl.$inject = [
    '$scope',
    '$rootScope',
    'BannedKeywordsService',
    'AdNotification',
    'InitialData',
    'FireTagService'
  ];

  function BannedKeywordsCtrl(
    $scope,
    $rootScope,
    BannedKeywordsService,
    AdNotification,
    InitialData,
    FireTagService
  ) {
    FireTagService.setPageView({
      title: 'Palabras prohibidas',
      path: `/${window.location.hash}`
    });
    $rootScope.adminTab.selected = 'banned-keywords';

    $scope.$on('$destroy', () => {
      $scope.watchBannedKeywordsOff();
    });

    function refreshData() {
      InitialData.refreshBannedKeywords().then(() => {
        $scope.bannedKeywords = angular.copy($rootScope.bannedKeyword.list);
      });
    }

    $scope.deleteBannedKeyword = function(bannedKeywordId) {
      BannedKeywordsService.deleteBannedKeyword(bannedKeywordId)
        .then(() => {
          AdNotification.success(201, 'delete_banned_keyword_response');
          refreshData();
        })
        .catch((exception) => {
          AdNotification.error(exception, 'delete_banned_keyword_response');
        });
    };

    $scope.watchBannedKeywordsOff = $scope.$watchCollection(
      () => {
        return $rootScope.bannedKeyword.list;
      },
      (newVal, oldVal) => {
        $scope.bannedKeywords = angular.copy($rootScope.bannedKeyword.list);
      }
    );

  }
  module.controller('BannedKeywordsCtrl', BannedKeywordsCtrl);
})(angular.module('postCenterWebClientApp'));
