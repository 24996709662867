/* global i18nWorkload */
const setWorkloadDefault = () => {
  return {
    title: 'title',
    warningRange: {
      low: 20,
      high: 60
    },
    limit: null,
    workloadData: [],
    loadingData: false,
    agents: [],
    departments: [],
    statuses: [],
    filters: {
      department: [],
      online_status: [],
      cmid: [],
      assignment_modes: [],
      pagination_size: 40
    },
    range: {
      from: moment('1900-01-01 00:00').unix(),
      to: moment('1900-01-01 00:00').unix()
    },
    pages: 0,
    elements: 0,
    totals: {
      nonAnswered: [0, 0],
      nonAddressed: [0, 0]
    },
    assignationModeOptions: new Array(5).fill().map((val, index) => {
      return {
        id: index + 1,
        name: i18nWorkload.t(`assignationModeOptions[${index}]`),
        active: true
      };
    }),
    user: null,
    userDepartments: [],
    isSupervisorNotAdmin: null
  };
};
const workloadStore = new Vuex.Store({
  state: setWorkloadDefault(),
  getters: {
    warningRange: state => state.warningRange,
    title: state => state.title,
    limit: state => state.limit,
    workloadData: state => state.workloadData,
    loadingData: state => state.loadingData,
    departments: state => state.departments,
    agents: state => state.agents,
    statuses: state => state.statuses,
    filters: state => state.filters,
    filterBy: state => name => state.filters[name],
    pages: state => state.pages,
    totals: state => state.totals,
    elements: state => state.elements,
    totalElements: state => state.workloadData.length,
    assignationModeOptions: state => state.assignationModeOptions,
    user: state => state.user,
    userDepartments: state => state.user.profile.departments,
    isSupervisorNotAdmin: state => state.user.isSupervisorNotAdmin,
    supervisorWithTeam: (_state, getters) =>
      getters.isSupervisorNotAdmin && getters.userDepartments.length
  },
  mutations: {
    MUTATE(state, {property, value}) {
      state[property] = value;
    },
    UPDATE_LIST(state, {array, payload}) {
      const index = state[array].findIndex(value => value.id === payload.id);
      if (index !== -1) {
        state[array].splice(index, 1, payload);
      } else {
        state[array].push(payload);
      }
    },
    SET_TOTALS(state, {property, value}) {
      state.totals[property] = value;
    },
    UPDATE_FILTERS(state, {filter, payload}) {
      state.filters[filter] = payload;
    },
    SET_USER(state, {value}) {
      state.user = value;
    },
    SET_FILTER_DEPARTMENTS(state, {value}) {
      state.filters.department = value;
    },
    SET_FILTER_AGENTS(state, {value}) {
      state.filters.cmid = value;
    }
  },
  actions: {
    openConfig() {
      this.AssignmentService.openConfig();
    },
    setFilters({commit, dispatch, state}, {filter, payload}) {
      commit('UPDATE_FILTERS', {
        filter,
        payload
      });
      if (filter !== 'page') {
        commit('UPDATE_FILTERS', {
          filter: 'page',
          payload: 1
        });
      }
      if (filter !== 'pagination_size' && filter !== 'page') {
        commit('UPDATE_FILTERS', {
          filter: 'pagination_size',
          payload: 40
        });
      }
      dispatch('getWorkload', filter);
      if (!state.limit) {
        dispatch('getTotals', {
          name: 'opened_and_not_answered_tickets_critical_table',
          property: 'nonAnswered'
        });
      }
      dispatch('getTotals', {
        name: 'opened_and_not_addressed_tickets_critical_table',
        property: 'nonAddressed'
      });
    },
    getTotals({state, commit}, {name, property}) {
      const {
        range,
        warningRange: {low, high},
        filters: {department, online_status, cmid, assignment_modes}
      } = state;
      this.AnalyticsService.getExecutivesKPI({
        name,
        range,
        extra_filters: {
          critical_percentages: [low / 100, high / 100],
          department,
          online_status,
          cmid,
          assignment_modes
        }
      }).then(response => {
        commit('SET_TOTALS', {
          property,
          value: response
        });
      });
    },
    getWorkload({state, commit}, filterBy) {
      const {range, filters: extra_filters} = state;
      commit('MUTATE', {
        property: 'loadingData',
        value: true
      });
      this.AnalyticsService.getExecutivesKPI({
        name: 'executives_paged_table_load_monitoring',
        range,
        extra_filters
      })
        .then(tableData => {
          const {executives, page, pages, elements} = tableData;
          const arrayExecutives = Object.values(executives);
          const keyExecutives = Object.keys(executives);
          const mapExecutives = arrayExecutives.map((executive, index) => {
            return {id: keyExecutives[index], ...executive};
          });
          commit('UPDATE_FILTERS', {filter: 'page', payload: page});
          commit('MUTATE', {
            property: 'elements',
            value: elements
          });
          if (filterBy !== 'page') {
            commit('MUTATE', {
              property: 'workloadData',
              value: []
            });
          }

          commit('MUTATE', {property: 'pages', value: pages});

          if (!elements) {
            commit('MUTATE', {
              property: 'workloadData',
              value: []
            });
          } else {
            mapExecutives.map(executive => {
              commit('UPDATE_LIST', {
                array: 'workloadData',
                payload: executive
              });
            });
          }
          commit('MUTATE', {
            property: 'loadingData',
            value: false
          });
        })
        .catch(() => {
          commit('MUTATE', {
            property: 'loadingData',
            value: false
          });
        });
    },
    async getDepartments({commit, state, getters}) {
      const {userDepartments, isSupervisorNotAdmin} = getters;
      if (state.departments.length === 0) {
        await this.DepartmentService.getDepartments().then(departments => {
          departments.push({
            name: 'Sin departamentos',
            id: 0
          });
          const filterDepartments = departments
            .map(department => {
              const {id, name} = department;
              return {
                name,
                id,
                active: true
              };
            })
            .filter(department => {
              if (isSupervisorNotAdmin && userDepartments.length > 0) {
                return userDepartments.includes(department.id);
              }
              return department;
            })
            .sort((sortA, sortB) => {
              return sortA.name.toLowerCase() > sortB.name.toLowerCase()
                ? 1
                : -1;
            });
          if (isSupervisorNotAdmin && userDepartments.length > 0) {
            const filteredDepartments = departments
              .filter(({id}) => !userDepartments.includes(id))
              .map(({id}) => id);
            commit('SET_FILTER_DEPARTMENTS', {
              value: filteredDepartments
            });
          }
          commit('MUTATE', {
            property: 'departments',
            value: filterDepartments
          });
        });
      }
    },
    async getAgents({commit, state, getters, dispatch}) {
      const {userDepartments, isSupervisorNotAdmin} = getters;
      if (state.agents.length === 0) {
        await this.UsersService.getCms().then(agents => {
          const filterAgents = agents
            .filter(agent => {
              const {departments} = agent;
              if (isSupervisorNotAdmin && userDepartments.length > 0) {
                return departments.find(dep => userDepartments.includes(dep));
              }
              return agent;
            })
            .map(agent => {
              const {cmid: id, name, departments} = agent;
              return {
                name,
                id,
                active: true,
                departments
              };
            })
            .sort(({name: sortA}, {name: sortB}) => {
              return sortA.toLowerCase() > sortB.toLowerCase() ? 1 : -1;
            });
          dispatch('setFilteredAgents', {agents});
          commit('MUTATE', {
            property: 'agents',
            value: filterAgents
          });
        });
      }
    },
    async getStatuses({commit, state}) {
      if (state.statuses.length === 0) {
        this.CustomOnlineStatuses.fetch().then(statuses => {
          const customStatuses = statuses.map(status => {
            const {label: name, resource_id: id} = status;
            return {
              name,
              id,
              active: true
            };
          });
          const filteredStatuses = Array.from(
            new Set(customStatuses.map(status => status.id))
          ).map(id => {
            return customStatuses.find(_status => _status.id === id);
          });
          commit('MUTATE', {
            property: 'statuses',
            value: filteredStatuses
          });
        });
      }
    },
    setFilteredAgents({commit, getters}, {agents}) {
      const {userDepartments, isSupervisorNotAdmin} = getters;
      if (isSupervisorNotAdmin && userDepartments.length > 0) {
        const filteredAgents = agents
          .filter(
            ({departments}) =>
              !departments.find(department =>
                userDepartments.includes(department)
              )
          )
          .map(({cmid: id}) => id);
        commit('SET_FILTER_AGENTS', {
          value: filteredAgents
        });
      }
    }
  }
});
