(function (module) {
  'use strict';
  caseEscalationCtrl.$inject = [
    '$scope',
    '$rootScope',
    'CaseService',
    'UserService',
    'AdNotification',
    'FireTagService',
    'EstablishmentService',
    'ThirdPartyService'
  ];

  function caseEscalationCtrl(
    $scope,
    $rootScope,
    CaseService,
    UserService: UserService,
    AdNotification,
    FireTagService,
    EstablishmentService: EstablishmentService,
    ThirdPartyService
  ) {
    const vm = this;
    vm.$onInit = () => {
      init();
    };
    const init = () => {
      UserService.getProfile().then(user => {
        vm.username = `${user.first_name} ${user.last_name}`;
        vm.email = user.email;
      })
      
      vm.currentView = 'form';
      vm.submitting = false;
      vm.selectedContacts;

      EstablishmentService.getEstablishment().then(establishment => {
        vm.hasLimits = establishment.plan.limits.find(({limit_max}) => limit_max !== -1)
        vm.hasLimits && vm.limits()
      })
    }
    const caseUpdateListenerOff = $scope.$on('case:hasBeenUpdated', (_event, caseObject) => {
      vm.caseObject = caseObject;
      init();
    });

    $scope.$on('$destroy', () => {
      caseUpdateListenerOff();
    });

    vm.submit = function () {
      if (vm.form.$invalid) {
        return;
      }

      const data = {
        to: vm.selectedContacts,
        subject: vm.subject,
        context: vm.context
      };

      vm.submitting = true;
      CaseService.escalate(vm.caseObject['resource_id'], data)
        .then(() => {
          vm.submitting = false;
          vm.currentView = 'success';
          AdNotification.success(201, 'escalate_case');
          vm.hasLimits && vm.limits();
          FireTagService.setEvent({
            name: 'escala_ticket'
          });
        })
        .catch(data => {
          vm.submitting = false;
          vm.currentView = 'error';
          AdNotification.error(data, 'escalate_case');
        });
    };

    vm.returnToForm = function () {
      vm.form.$setUntouched();
      vm.form.$setPristine();
      vm.currentView = 'form';
    };

    vm.cleanForm = function () {
      vm.selectedContacts = '';
      vm.subject = '';
      vm.context = '';
    };

    vm.returnToCleanForm = function () {
      vm.cleanForm();
      vm.currentView = 'form';
      vm.form.$setUntouched();
      vm.form.$setPristine();
    };

    vm.limits = () => {
      const limitId = 'ESC';
      EstablishmentService.getLimits(limitId).then(({count}) => {
        const {
          plan: {limits}
        } = $rootScope.establishment;

        const limitsData = limits.find(({limit_id}) => limit_id === limitId);

        const {limit_max: limit} = limitsData;

        $scope.escLimits = {
          count,
          limit
        };

        $scope.escLimits.pending = limit - count;
        $scope.escLimits.progressValue = (count / limit) * 100;
        $scope.escLimits.showLimits = limit !== -1;
        $scope.escLimits.limitReached = limit === count;
      });
    };

    $scope.showBilling = function () {
      return ThirdPartyService.showBilling();
    };
  }
  module.controller('caseEscalationCtrl', caseEscalationCtrl);
})(angular.module('postCenterWebClientApp'));
