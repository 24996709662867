'use strict';

var app = angular.module('postCenterWebClientApp');
app.directive('replyMessage', function () {
  return {
    restrict: 'E',
    scope: {
      replyToMessage: '=replyTo',
      isSending: '=isSending',
      publishCallback: '=publishCallback',
      caseId: '=',
      discardCallback: '&',
      compactView: '&',
      fallbackTargetUser: '<?'
    },
    templateUrl: 'blocks/message/views/reply_message.html',
    controller: 'ReplyMessageCtrl',
    controllerAs: '$ctrl',
  };
});
