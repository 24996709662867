(module => {
  'use strict';
  module.controller('reAssignTicketsCtrl', reAssignTicketsCtrl);
  reAssignTicketsCtrl.$inject = [
    '$scope',
    'AssignmentService',
    '$rootScope',
    '$filter',
    'AdNotification',
    'AdAvailableSn',
    '$uibModal',
    'UsersService'
  ];
  function reAssignTicketsCtrl(
    $scope,
    AssignmentService,
    $rootScope,
    $filter,
    AdNotification,
    AdAvailableSn,
    $uibModal,
    UsersService
  ) {
    const vm = this;

    vm.$onInit = () => {
      const {
        users,
        accounts = [],
        establishment: {config}
      } = $rootScope;
      vm.users = users;
      vm.accounts = accounts;
      $scope.enableLimits = config.enable_queued_assignment;

      if (!users) {
        _getUsers();
      } else {
        vm.users = users;
      }
      vm.getChangeHistory();

      $scope.availableOptions = [
        {
          name: vm.translate('MASSIVE_ACTION_RE_ASSIGN_NO_ASSIGNATION'),
          id: 'RE_ASSIGN_NO_ASSIGNATION'
        }
      ];
      $scope.allowedPermissions = [
        {
          name: vm.translate('MASSIVE_ACTION_RE_ASSIGN_MANUAL_PERMISSIONS'),
          id: 1
        },
        {
          name: vm.translate('MASSIVE_ACTION_RE_ASSIGN_AUTO_PERMISSIONS'),
          id: 2
        },
        {
          name: vm.translate('MASSIVE_ACTION_RE_ASSIGN_ALL_PERMISSIONS'),
          id: 0
        }
      ];
      $scope.allowedAgentStatus = [
        {
          name: vm.translate('MASSIVE_ACTION_RE_ASSIGN_STATUS_ONLINE'),
          id: 1
        },
        {
          name: vm.translate('MASSIVE_ACTION_RE_ASSIGN_STATUS_OFFLINE'),
          id: 2
        },
        {name: vm.translate('MASSIVE_ACTION_RE_ASSIGN_STATUS_ANY'), id: 0}
      ];
      $scope.currentAction = {
        selection: undefined,
        socialAccount: undefined,
        selectableAgents: [],
        selectAllAgents: true,
        // default assignation and agent status:
        agentQuery: {status: null, assignation: null}
      };
      $scope.numberOfSelectedAgents = 0;
      $scope.currentSocialAccountsTickets = [];
      $scope.currentAgentsSocialAccounts = [];

      $scope.formLoading = false;
      $scope.loading = true;

      $scope.activityLog = [];
      $scope.pagination = {pageNumber: 1};
      $scope.queryAgents = queryAgents;
      $scope.onActionSelected = function() {
        if ($scope.currentAction.selection.id === 'RE_ASSIGN_NO_ASSIGNATION') {
          initNoAssignation();
        }
      };
      const pageNumberWatcherOff = $scope.$watch('pagination.pageNumber', (newValue, oldValue) => {
        if (oldValue !== newValue) {
          newValue = newValue - 1;
          vm.getChangeHistory(newValue);
        }
      });
      $scope.$on('$destroy', () => {
        pageNumberWatcherOff();
      });
    };
    vm.translate = string => $filter('translate')(string);
    vm.formatDate = date => moment(date).format('DD/MM/YYYY, h:mm:ss a');
    vm.formatCalendarDate = date => moment(date).calendar();

    const _getUsers = () => {
      UsersService.getUsers().then(_users => {
        vm.users = _users;
      });
    };
    const queryAgents = () => {
      const agents = getAllAgents();
      const {agentQuery: query} = $scope.currentAction;

      // Channel
      const channels = $scope.getAvailableAccounts();
      const selectedChannel = $scope.currentAction.socialAccount;
      const accounts = filterChannelAccounts(channels, selectedChannel);

      const filteredAgents = filterAgents(agents, query, accounts);

      $scope.currentAction.selectableAgents = filteredAgents;
      $scope.numberOfSelectedAgents = filteredAgents.length;

      return filteredAgents;
    };

    const getAllAgents = () => {
      const socialNetworks = $scope.currentAgentsSocialAccounts;
      const currentSocialNetwork = $scope.currentAction.socialAccount;
      return socialNetworks[currentSocialNetwork].agents;
    };

    const initNoAssignation = () => {
      // Init object with social network and empty list of agents
      var agentPerSocialNetwork = {};
      const idToSn = {};

      vm.accounts.map(_account => {
        if (!agentPerSocialNetwork[_account.sn]) {
          agentPerSocialNetwork[_account.sn] = {
            agents: []
          };
        }
        idToSn[_account.id] = _account.sn;
      });

      // Get open tickets with no assignation
      AssignmentService.checkOpenTickets().then(result => {
        // For agent, put in his corresponding social network list.
        for (var index = 0; index < result.agents.length; index++) {
          var agent = result.agents[index];

          agent.assignations.map(assignation => {
            if (assignation.mode > 0 && idToSn[assignation.id] != null) {
              agentPerSocialNetwork[idToSn[assignation.id]].agents.push(agent);
            }
          });
        }

        // The social network agent list must not have duplicity.
        for (let sn in agentPerSocialNetwork) {
          const agentPerSocialNetworkWithId = agentPerSocialNetwork[
            sn
          ].agents.map(agent => {
            return agent.cmid;
          });

          agentPerSocialNetwork[sn].agents = agentPerSocialNetwork[
            sn
          ].agents.filter((value, index) => {
            return agentPerSocialNetworkWithId.indexOf(value.cmid) === index;
          });
        }

        const socialAccounts = result.unassigned || [];
        socialAccounts.map(sn => {
          sn.label = AdAvailableSn.getTitle(sn.sn);
          return sn;
        });
        // Expose the vars to scope.
        $scope.currentSocialAccountsTickets = socialAccounts;
        $scope.currentAgentsSocialAccounts = agentPerSocialNetwork || [];
      });
    };

    $scope.onSocialAccountSelected = function() {
      $scope.resetCheckboxes();

      // Only get online agents by default
      $scope.currentAction.agentQuery = {
        status: $scope.allowedAgentStatus.find(({id}) => id === 1),
        assignation: $scope.allowedPermissions.find(({id}) => id === 0)
      };
      queryAgents();
    };

    $scope.callbackSelectAgent = function(agent) {
      if (agent.selected) {
        $scope.numberOfSelectedAgents += 1;
      } else {
        $scope.numberOfSelectedAgents -= 1;
      }
      if (
        $scope.numberOfSelectedAgents <
        $scope.currentAction.selectableAgents.length
      ) {
        $scope.currentAction.selectAllAgents = false;
      } else if (
        $scope.numberOfSelectedAgents ===
        $scope.currentAction.selectableAgents.length
      ) {
        $scope.currentAction.selectAllAgents = true;
      }
    };

    $scope.callbackSelectAllAgent = function() {
      if ($scope.currentAction.selectableAgents.length > 0) {
        const selectionStatus = $scope.currentAction.selectAllAgents;
        for (
          var index = 0;
          index < $scope.currentAction.selectableAgents.length;
          index++
        ) {
          $scope.currentAction.selectableAgents[
            index
          ].selected = selectionStatus;
        }

        if (selectionStatus) {
          $scope.numberOfSelectedAgents =
            $scope.currentAction.selectableAgents.length;
        } else {
          $scope.numberOfSelectedAgents = 0;
        }
      }
    };

    $scope.resetCheckboxes = function() {
      $scope.currentAction.selectAllAgents = true;
      $scope.numberOfSelectedAgents = 0;
    };

    $scope.assignTickets = function() {
      $scope.formLoading = true;
      let allowedPermissions = [1, 2];
      const selectedAgents = $scope.currentAction.selectableAgents
        .filter(agent => agent.selected)
        .map(agent => agent.cmid);
      const assignationMode = $scope.currentAction.agentQuery.assignation.id;

      if (assignationMode > 0) {
        allowedPermissions = [assignationMode];
      }

      const data = {
        cms: selectedAgents,
        social_network: $scope.currentAction.socialAccount,
        permissions: allowedPermissions
      };

      AssignmentService.getRebalance(data)
        .then(() => {
          initNoAssignation();
          $scope.currentAction.selectableAgents = undefined;
          $scope.currentAction.socialAccount = undefined;
          $scope.resetCheckboxes();
          const successMessage = vm.translate(
            'MASSIVE_ACTION_RE_ASSIGN_SUCCESS'
          );
          AdNotification.notify(successMessage, 'success');
          $scope.formLoading = false;
          vm.getChangeHistory();
        })
        .catch(() => {
          const errorMessage = vm.translate('MASSIVE_ACTION_RE_ASSIGN_ERROR');
          $scope.formLoading = false;
          AdNotification.notify(errorMessage, 'error');
        });
    };

    vm.getChangeHistory = (page = 0) => {
      $scope.loading = true;

      AssignmentService.getHistoryRebalance(page).then(response => {
        const {list = [], elements, page_size: pageSize} = response;
        $scope.activityLog = list.map(val => {
          return _parseLogEvent(val);
        });
        $scope.pagination.totalItems = elements;
        $scope.pagination.pageSize = pageSize;
        $scope.loading = false;
      });
    };

    const _parseLogEvent = event => {
      const created = vm.formatDate(event.created * 1000);
      const startTooltip = vm.formatCalendarDate(event.created * 1000);

      const params = {
        author: event.author.name
      };

      if ('to' in event.extra_data) {
        const to =
          vm.users.find(user => user.id === event.extra_data.to) ||
          vm.translate('ASSIGNMENTS_ACTIVITY_LOG_DELETED_USER');
        params.to = `${to.first_name} ${to.last_name}`;
      }

      if ('social_network' in event.extra_data) {
        params.sn_name = AdAvailableSn.getTitle(
          event.extra_data.social_network
        );
        params.sn = event.extra_data.social_network;
      }

      return {
        created,
        startTooltip,
        reasonText: event.extra_data.what,
        params
      };
    };

    $scope.openHelpModal = function() {
      $uibModal.open({
        templateUrl: 'blocks/analytics/views/dashboard_help_modal.html',
        size: 'md',
        controller: ['$scope', '$uibModalInstance', '$filter', modalController]
      });
      function modalController($scope, $uibModalInstance, $filter) {
        $scope.title = 'MASSIVE_ACTION_RE_ASSIGN_TICKETS_HELP_TITLE';
        var workloadRange = $rootScope.establishment.config.workload_range;
        $scope.helpText = vm.translate(
          'MASSIVE_ACTION_RE_ASSIGN_TICKETS_HELP_BODY',
          {
            workload_range: workloadRange
          }
        );
        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };
      }
    };

    $scope.getAvailableAccounts = () =>
      $rootScope.accounts.map(account => {
        const {sn, id} = account;
        return {sn, id};
      });

    $scope.openAgentHelpModal = function() {
      $uibModal.open({
        templateUrl: 'blocks/analytics/views/dashboard_help_modal.html',
        size: 'md',
        controller: ['$scope', '$uibModalInstance', modalController]
      });
      function modalController($scope, $uibModalInstance) {
        $scope.title = 'MASSIVE_ACTION_RE_ASSIGN_TICKETS_AGENT_HELP_TITLE';
        var workloadRange = $rootScope.establishment.config.workload_range;
        $scope.helpText = vm.translate(
          'MASSIVE_ACTION_RE_ASSIGN_TICKETS_AGENT_HELP_BODY',
          {
            workload_range: workloadRange
          }
        );
        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };
      }
    };

    const filterAgentsByStatus = (agents = [], status) => {
      const statusList = {
        0: 'any',
        1: 'online',
        2: 'offline'
      };

      const selectableAgents = agents
        .filter(
          agent => status === 0 || agent.online_status === statusList[status]
        )
        .map(agent => Object.assign({selected: true}, agent));

      return selectableAgents;
    };

    const filterAgentsByAssignation = (
      agents = [],
      requiredPermission = 0,
      channelAccounts = []
    ) => {
      // Check if agent IS assigned to channel and HAS the needed permissions
      const _checkChannelAssignment = assignation => {
        const isAssignedToChannel = channelAccounts.includes(assignation.id);
        const hasChannelPermission =
          requiredPermission === 0 || assignation.mode === requiredPermission;

        return isAssignedToChannel && hasChannelPermission;
      };

      const filteredAgents = agents.filter(agent => {
        const agentAssignments = agent.assignations || [];
        const ableToManageThisChannel = agentAssignments.some(
          _checkChannelAssignment
        );

        return ableToManageThisChannel;
      });

      return filteredAgents;
    };

    const filterAgents = (agents = [], query = {}, channels = []) => {
      const agentsByStatus = filterAgentsByStatus(agents, query.status.id);
      const agentsByAssignation = filterAgentsByAssignation(
        agentsByStatus,
        query.assignation.id,
        channels
      );

      return agentsByAssignation;
    };

    const filterChannelAccounts = (channels = [], selectedChannel = '') => {
      const response = channels
        .filter(channel => channel.sn === selectedChannel)
        .map(channel => channel.id);

      return response;
    };
  }
})(angular.module('postCenterWebClientApp'));
