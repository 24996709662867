const SignupRegister = Vue.component('SignupRegister', {
  computed: {
    ...Vuex.mapGetters(['counterSteps'])
  },
  template: `<section class="container-fluid signup-wrapper no-gutter">
                <div class="signup-box justify-content-center">
                  <FirstStep v-if="counterSteps === 1"/>
                  <SecondStep v-else />
                </div>
            </section>`
});
