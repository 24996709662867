'use strict';

class CosHandler {
  UsersService: UsersService
  
  timeLimit: number = 10 * 1000
  lastRequest: Date | null = null
  cms: Cm[] = []
  users: { [key: string]: User } = {}

  constructor(
    AdRequest: AdRequestService,
    UsersService: UsersService,
  ) {
    this.UsersService = UsersService
    AdRequest.loggedIn.subscribe(() => {
      this.getCms()
    })
  }

  getCms(): Promise<Cm[]> {
    return this._requestUsers().then(() => {
      return this.cms
    })
  }

  _requestUsers(): Promise<void> {
    const now = new Date();

    if (
      this.lastRequest == null ||
      now - this.lastRequest >= this.timeLimit
    ) {
      this.lastRequest = now;
      return Promise.all([
        this.UsersService.getCms(),
        this.UsersService.getUsers()
      ]).then(allStars => {
        const [cms, users] = allStars;
        this._parseUsersAndCms(users, cms);
      });
    } else {
      return Promise.resolve();
    }
  }

  _parseUsersAndCms(users: User[], cms: Cm[]): void {
    const self = this;
    var index;

    for (index = 0; index < users.length; index++) {
      var user = users[index];

      this.users[user.id] = user;
    }

    this.cms = cms.map(cm => {
      var user = self.users[cm.cmid];
      if (user != null) {
        cm.online_status = user.profile.online_status;
      }
      return cm;
    });
  }
}

angular
  .module('postCenterWebClientApp')
  .service('CosHandler', CosHandler)
