const EpaFirstStep = Vue.component('EpaFirstStep', {
  computed: {
    ...Vuex.mapGetters('epaConfig', ['editingSurvey'])
  },
  template: `<div class="d-flex">
    <div class="flex-col-12">
      <p>{{ $t('survey.label') }}</p>
      <InputText
        :label="$t('survey.placeholder')"
        :input-error="!editingSurvey.name"
        :error-message="$t('survey.emptyNameMessage')"
        v-model="editingSurvey.name"
        class="w-65"
      />

      <p class="my-2 w-65">{{ $t('chooseAccounts') }}</p>
      <EpaAvailableAccounts :active-accounts="editingSurvey.accounts" />
    </div>
    <div class="flex-col-4">
      <img
        src="images/analizar-canales.svg"
        :alt="$t('image.alt')"
        width="80%"
      />
      <p v-html="$t('image.title')" />
    </div>
  </div>`,
  i18n: {
    messages: {
      es: {
        image: {
          title:
            '<strong>Identifica el objetivo</strong> que deseas alcanzar y <strong>establece el público.</strong>',
          alt: 'Crea una encuesta'
        },
        survey: {
          label: 'Introduce un nombre para identificar esta encuesta.',
          emptyNameMessage: 'La encuesta debe tener un nombre.',
          placeholder: 'Nombre Encuesta'
        },
        chooseAccounts:
          'Elige las cuentas en las que deseas que aparezca la encuesta, puedes elegir más de uno.'
      },
      pt: {
        image: {
          title:
            '<strong>Identifique a meta </strong>que você deseja alcançar e <strong>defina o público.</strong>',
          alt: 'Crie uma pesquisa.'
        },
        survey: {
          label: 'Digite um nome para identificar esta pesquisa.',
          emptyNameMessage: 'A pesquisa deve ter um nome.',
          placeholder: 'Nome pesquisa'
        },
        chooseAccounts:
          'Escolha as contas nas quais deseja que a pesquisa apareça, você pode escolher mais de uma.'
      },
      en: {
        image: {
          title:
            '<strong>Identify the goal</strong> you want to achieve and <strong>set the audience.</strong>',
          alt: 'Create a survey'
        },
        survey: {
          label: 'Enter a name to identify this survey.',
          emptyNameMessage: 'The survey must have a name.',
          placeholder: 'Survey name'
        },
        chooseAccounts:
          'Choose the accounts in which you want the survey to appear, you can choose more than one.'
      }
    }
  }
});
