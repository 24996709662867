class AnalyticsService {

  AdRequest: AdRequestService
  Analytics

  constructor(AdRequest, Analytics) {
    this.AdRequest = AdRequest;
    this.Analytics = Analytics;
  }
  getStats({metric, granularity, delta, now}) {
    const end = this.getFormatTime(now);
    const params = {
      metric,
      granularity,
      delta,
      end
    };
    return this.AdRequest.post('/api/v1/stat/', params);
  }
  async getExecutivesKPI({
    dashboard = true,
    name = 'executives_paged_table',
    range,
    extra_filters
  }) {
    const {from, to} = range;
    const payload = {
      name,
      now: moment.unix(to).toISOString(),
      until: moment.unix(from).toISOString(),
      extra_filters
    };
    const path = dashboard ? 'dashboard' : 'helpdesk';
    try {
      const response = await this.AdRequest.post(
        `/api/v1/stat/${path}/executives/`,
        payload
      );
      return response;
    } catch (error) {
      if (error.status != 401) {
        this.Analytics.notify('error', 500, error)
      }
      return []
    }
  }
  async getIndicators(group = 'tickets') {
    try {
      const kpiList = await this.AdRequest.get(`/analytics/v2/list/${group}/`);
      return kpiList;
    } catch (error) {
      throw new Error(error);
    }
  }
  async getTicketMetrics({
    now,
    until,
    name = '',
    sn = null,
    cmid,
    account = null,
    page = 1,
    departments = null
  }) {
    try {
      const payload = {
        name,
        now: moment.unix(now).toISOString(),
        until: moment.unix(until).toISOString(),
        account,
        extra_filters: {cmid, page, sn, departments}
      };
      const response = await this.AdRequest.post(
        '/api/v1/stat/dashboard/tickets/',
        payload
      );
      return response;
    } catch (error) {
      this.Analytics.notify('error', 500, error);
      return null;
    }
  }
  async getChartData({identifier = '', from, to}) {
    if (!identifier) {
      throw new Error('Missing chart params');
    }
    const rangeFrom = !from ? moment().add(-1, 'months').unix() : from;
    const rangeTo = !to ? moment().unix() : to;
    const queryParams = `?range_from=${rangeFrom}&range_to=${rangeTo}`;

    try {
      const visualize = await this.AdRequest.get(
        `/analytics/v2/visualize/${identifier}/${queryParams}`
      );
      return visualize;
    } catch (error) {
      throw new Error(error);
    }
  }
  async getFileData(pathname) {
    try {
      const file = await this.AdRequest.get(pathname);
      return file;
    } catch (error) {
      throw new Error(error);
    }
  }
  getFormatTime(time) {
    if (!time) {
      return Math.floor(new Date().getTime() / 1000);
    }
    return Math.floor(time.clone().toDate().getTime() / 1000);
  }
}
angular
  .module('postCenterWebClientApp')
  .service('AnalyticsService', AnalyticsService);
