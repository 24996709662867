const i18nEpa = new VueI18n({
  locale: 'es',
  fallbackLocale: ['es', 'en', 'pt'],
  silentTranslationWarn: true,
  messages: {
    es: {
      accounts: 'Cuenta | Cuentas',
      toggleSurvey: 'Habilitar | Deshabilitar',
      withoutName: 'Sin nombre',
      toast: {
        safe: {
          create: 'Encuesta creada exitosamente!',
          updated: 'Encuesta actualizada exitosamente!',
          remove: 'Encuesta eliminada'
        },
        errors: {
          name: 'No podra ser activada esta encuesta sin un nombre',
          create: 'Se ha producido un error al crear una encuesta',
          active: 'Se ha producido un error al activar esta encuesta',
          remove: 'Se ha producido un error al eliminar esta encuesta'
        },
        warnings: {
          channels:
            'Esta encuesta no podrá ser activada sin al menos seleccionar un canal'
        }
      },
      survey: {
        res: {
          title: 'Resolución',
          desc: 'Esta pregunta inicia procesos distintos de acuerdo a la respuesta del usuario',
          question: 'Establece la pregunta de resolución',
          text: '¿Resolvimos tu problema?'
        },
        csat: {
          title: 'CSAT | Índice de satisfaccón del cliente',
          desc: 'Esta pregunta sirve para conocer la percepción que tienen tus clientes sobre el servicio que les has proporcionado',
          question: 'Establece la pregunta de calificación de atención',
          text: '¿Qué tan buena fue la atención de nuestros ejecutivos?'
        },
        fcr: {
          title: 'FCR | Resolución del primer contacto',
          desc: 'Esta pregunta sirve para obtener el porcentaje de solicitudes de clientes que se resolvieron en el primer contacto',
          question: 'Establece la pregunta de resolución de primer contacto',
          text: '¿Tu solicitud fue respondida en el primer contacto?'
        },
        ces: {
          title: 'CES | Índice de esfuerzo del cliente',
          desc: 'Esta pregunta sirve para saber cuánto necesitan esforzarse nuestros clientes para resolver la solicitud por la cuál nos están contactando',
          question: 'Establece la pregunta de esfuerzo del cliente',
          text: '¿Qué tan difícil fue resolver tu solicitud con nuestro servicio de atención?'
        },
        open: {
          title: 'Pregunta abierta',
          question: 'Establece una pregunta extra para respuestas abiertas',
          text: 'Agrega tus sugerencias o comentarios'
        },
        goodbye_message: 'Gracias por tu respuesta, Hasta luego',
        answer_tags: {
          option: 'Sí | No',
          options: {
            lowest: 'Muy mala | Muy difícil',
            low: 'Mala | Difícil',
            middle: 'Neutral | Ni facil ni difícil',
            high: 'Buena | Facil',
            highest: 'Muy buena | Muy facil'
          }
        }
      },
      tooltips: {
        usedAccount: 'Esta cuenta ya posee una encuesta activa',
        spamTimeout:
          'Tiempo mínimo que debe transcurrir entre el envío de una encuesta y la siguiente a un mismo usuario. Si introduces 0 se desactivará el umbral de spam.'
      },
      buttons: {
        save: 'Guardar encuesta',
        cancel: 'Cancelar',
        goToResults: 'Ir a resultados de encuestas',
        createSurvey: 'Crear encuesta',
        testSurvey: 'Probar encuesta',
        removeSurvey: 'Eliminar encuesta',
        contactExecutive: 'Contactar'
      },
      confirm: {
        surveyChanged: {
          title: 'Ver encuesta',
          description:
            'Para ver otra encuesta, guarda los cambios en la encuesta actual.',
          onConfirm: 'Entendido'
        },
        activeSurvey: {
          title: 'Atención',
          desc: {
            message:
              'La encuesta está habilitada, al guardar los cambios estos se verán reflejados inmediatamente en las encuestas que se envíen a partir de este momento.',
            confirm: '¿Estás seguro que deseas guardar los cambios?'
          },
          onConfirm: 'Sí, guardar',
          onCancel: 'No, regresar a encuesta'
        }
      }
    },
    pt: {
      accounts: 'Conta | Contas',
      toggleSurvey: 'Habilitar | Desabilitar',
      withoutName: 'Sem nome',
      toast: {
        safe: {
          create: 'Pesquisa criada com sucesso!',
          updated: 'Pesquisa atualizada com sucesso!',
          remove: 'Pesquisa eliminada'
        },
        errors: {
          name: 'Esta pesquisa não pode ser ativada sem um nome',
          create: 'Ocorreu um erro ao criar uma pesquisa',
          active: 'Ocorreu um erro ao ativar esta pesquisa',
          remove: 'Ocorreu um erro ao remover esta pesquisa'
        },
        warnings: {
          channels:
            'Esta pesquisa não pode ser ativada sem pelo menos selecionar um canal'
        }
      },
      survey: {
        res: {
          title: 'Resolução',
          desc: 'Esta pergunta inicia diferentes processos de acordo com a resposta do usuário.',
          question: 'Define a questão da resolução',
          text: 'Já resolvemos seu problema?'
        },
        csat: {
          title: 'CSAT | Índice de satisfação do cliente',
          desc: 'Esta pergunta é usada para descobrir como seus clientes percebem o serviço que você lhes prestou.',
          question: 'Define a questão da qualificação da atenção',
          text: 'Quão boa foi a atenção de nossos executivos?'
        },
        fcr: {
          title: 'FCR | Resolução do primeiro contato',
          desc: 'Esta pergunta é utilizada para obter a porcentagem de consultas dos clientes que foram resolvidas no primeiro contato.',
          question: 'Define a primeira questão de resolução de contato',
          text: 'Seu pedido foi atendido no primeiro contato?'
        },
        ces: {
          title: 'CES | Índice de esforço do cliente',
          desc: 'Esta pergunta é para descobrir o quanto nossos clientes precisam trabalhar para resolver o pedido que estão entrando em contato conosco.',
          question: 'Definir a questão do esforço do cliente',
          text: 'Quão difícil foi resolver seu pedido com nosso serviço de atendimento ao cliente?'
        },
        open: {
          title: 'Pergunta aberta',
          question: 'Definir uma pergunta extra para respostas abertas',
          text: 'Adicione suas sugestões ou comentários'
        },
        goodbye_message: 'Obrigado pela sua resposta, até logo',
        answer_tags: {
          option: 'Sim | Não',
          options: {
            lowest: 'Muito ruim | Muito difícil',
            low: 'Ruim | Difícil',
            middle: 'Neutro | Nem fácil nem difícil',
            high: 'Bom | Fácil',
            highest: 'Muito bom | Muito Fácil'
          }
        }
      },
      tooltips: {
        usedAccount: 'Esta conta já tem uma pesquisa ativa',
        spamTimeout:
          'Tempo mínimo que deve decorrer entre o envio de uma pesquisa e a próxima ao mesmo usuário. Inserir 0, desabilitará o limite de spam.'
      },
      buttons: {
        save: 'Salvar pesquisa',
        cancel: 'Cancelar',
        goToResults: 'Ir a resultados de encuestas',
        createSurvey: 'Criar pesquisa',
        testSurvey: 'Probar encuesta',
        removeSurvey: 'Apagar encuesta',
        contactExecutive: 'Contato'
      },
      confirm: {
        surveyChanged: {
          title: 'Ver pesquisa',
          description:
            'Para ver outra pesquisa, salve as mudanças na pesquisa atual.',
          onConfirm: 'Entendido'
        },
        activeSurvey: {
          title: 'Atenção',
          desc: {
            message:
              'A pesquisa está habilitada, ao salvar as alterações, elas serão refletidas imediatamente nas pesquisas que serão enviadas a partir de agora.',
            confirm: 'Tem certeza de que deseja salvar as alterações?'
          },
          onConfirm: 'Sim salvar',
          onCancel: 'Não, retornar à pesquisa'
        }
      }
    },
    en: {
      accounts: 'Account | Accounts',
      toggleSurvey: 'Enabled | To disable',
      withoutName: 'Without name',
      toast: {
        safe: {
          create: 'Survey created successfully!',
          updated: 'Survey updatede successfully!',
          remove: 'Survey deleted successfully!'
        },
        errors: {
          name: 'This survey cannot be activated without a name',
          create: 'An error occurred while creating a survey',
          active: 'An error occurred while activating this survey',
          remove: 'An error occurred while deleting this survey'
        },
        warnings: {
          channels:
            'This survey cannot be activated without at least selecting a channel'
        }
      },
      survey: {
        res: {
          title: 'Resolution',
          desc: "This question initiates different processes according to the user's answer.",
          question: 'Sets the resolution question',
          text: 'Did we solve your problem?'
        },
        csat: {
          title: 'CSAT | Customer satisfaction score',
          desc: 'This question is to know the perception your customers have about the service you have provided them.',
          question: 'Sets the attention qualification question.',
          text: 'How good was the attention of our agents?'
        },
        fcr: {
          title: 'FCR | First contact resolution',
          desc: 'This question is used to obtain the percentage of customer requests that were resolved on the first contact.',
          question: 'Sets the first contact resolution question.',
          text: 'Was your request answered in the first contact?'
        },
        ces: {
          title: 'CES | Customer effort score',
          desc: 'This question is to find out how hard our customers need to work to solve the request for which they are contacting us.',
          question: "Set the customer's effort question",
          text: 'How difficult was it to resolve your request with our customer service?'
        },
        open: {
          title: 'Open Question',
          question: 'Sets an extra question for open-ended answers',
          text: 'Add your suggestions or comments'
        },
        goodbye_message: 'Thanks for your reply, See you later',
        answer_tags: {
          option: 'Yes | No',
          options: {
            lowest: 'Very bad | Very difficult',
            low: 'Bad | Difficult',
            middle: 'Neutral | Neither easy or difficult',
            high: 'Good | Easy',
            highest: 'Very good | Very easy'
          }
        }
      },
      tooltips: {
        usedAccount: 'This account already has an active survey',
        spamTimeout:
          'Minimum time that must elapse between sending a survey and following a user. Entering 0 will disable the spam limit.'
      },
      buttons: {
        save: 'Save survey',
        cancel: 'Cancel',
        goToResults: 'Go to survey results',
        createSurvey: 'Create survey',
        testSurvey: 'Test survey',
        removeSurvey: 'Remove survey',
        contactExecutive: 'Contact'
      },
      confirm: {
        surveyChanged: {
          title: 'View survey',
          description:
            'To view another survey, save changes to the current survey.',
          onConfirm: 'Ok'
        },
        activeSurvey: {
          title: 'Attention',
          desc: {
            message:
              'The survey is enabled, when saving the changes they will be immediately reflected in the surveys that are sent from this moment.',
            confirm: 'Are you sure you want to save the survey?'
          },
          onConfirm: 'Yes save',
          onCancel: 'No, back to the survey'
        }
      }
    }
  }
});
