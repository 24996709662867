const AvatarList = Vue.component('AvatarList', {
  props: {
    socialAccounts: {type: Array, default: () => []},
    highlightedUid: {type: String, default: '', required: false}
  },
  template: `
    <ul v-if="socialAccounts" class="list-unstyled d-flex scrollable m-0">
      <li v-for="account in socialAccounts"
          @click="$emit('onAccountClicked', account)">
        <Avatar :channel="account.sn"
                :title="account.name"
                :avatar="account.avatar"
                :bordered="account.uid === highlightedUid"
                :is-influencer="false"
                size="small"
                class="clickable mr-1" />
      </li>
    </ul>
  `
});
const AvatarGroup = Vue.component('AvatarGroup', {
  props: {
    avatars: {
      type: Array,
      default: () => []
    },
    stopNumber: {
      type: Number,
      default: 5
    },
    size: {
      type: [Number, String],
      default: 22
    },
    clickHandler: {
      type: Function,
      default: null
    }
  },
  template: `<ul class="ads-avatar ads-avatar--group">
    <li v-for="(avatar, index) in avatars" v-if="avatars.length > 0 && index < stopNumber">
      <slot v-if="clickHandler">
        <button
          @click="clickHandler(avatar)"
          :content="avatar.visual_name"
          v-tippy='{ arrow : true}'>
          <SimpleAvatar
            :src="avatar.avatar_url"
            :alt="avatar.visual_name"
            :size="size" />
        </button>
      </slot>
      <slot v-else>
        <SimpleAvatar
          :src="avatar.avatar_url"
          :alt="avatar.visual_name"
          :size="size" />
      </slot>
    </li>
    <span class="ml-1" v-if="avatars.length > 10">...{{avatars.length - stopNumber}} más</span>
  </ul>`
});
