/** @directive
 * @name InfiniteScroll
 * @description Run a callback once an element scroll reaches its bottom.
 * Event listener removes itself once the element is destroyed.
 * @usage <element v-infinite-scroll="callback" /> */
Vue.directive('infinite-scroll', {
  inserted(element, binding) {
    const onScroll = ({target}) => {
      const maxScrollPoint = target.scrollHeight - target.clientHeight;
      if (target.scrollTop >= maxScrollPoint) {
        binding.value();
      }
    };

    element.addEventListener('scroll', onScroll);
  }
});
