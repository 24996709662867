const FirstStep = Vue.component('FirstStep', {
  data() {
    return {
      terms: false
    };
  },
  computed: {
    ...Vuex.mapGetters([
      'counterSteps',
      'totalSteps',
      'newAccountValues',
      'inputs',
      'recaptcha'
    ]),
    validForm() {
      const userRequiredInfo = this.inputs.every(
        input => input.isValid === true
      );
      return userRequiredInfo;
    },
    isChecked() {
      return this.validForm && this.terms;
    }
  },
  methods: {
    ...Vuex.mapActions(['handleStep', 'updateNewAccount']),
    checkStep() {
      this.inputs.forEach(input => {
        this.updateNewAccount({
          [input.name]: input.value
        });
      });

      this.handleStep(2);
    }
  },
  template: `
    <section class="container-first-step container ">
      <div class="row">
        <div class="col-xs-12 col-sm-6">
          <form class="form">
            <div class="header">
              <h1 class="header__title">{{ $t('signUp.titles.firstStep') }}</h1>
              <span class="mt-2">
                <counter-steps
                  :counter-steps="counterSteps"
                  :total-steps="totalSteps"
                />
              </span>
            </div>
            <hr />
            <div class="my-2">
              <InputsForm :inputs="inputs" />
              <label class="ads-checkbox">
                <input type="checkbox" v-model="terms" />
                <span class="mx-1" v-html="$t('signUp.form.terms')"></span>
              </label>
              <button
                type="button"
                class="btn ads-button ads-button--primary my-1 w-100"
                @click="checkStep()"
                :disabled="!isChecked">
                {{ $t('signUp.btn.next') }}
              </button>
            </div>
            <p class="text-center my-1">
              {{ $t('signUp.haveAccount') }}
              <a :href="$t('signUp.links.login')">{{ $t('signUp.login') }}</a>
            </p>
          </form>
        </div>
        <div class="col-xs-12 col-sm-6">
          <div class="quote p-3">
            <p class="quote__simbols mt-5"><i class="fa fa-quote-left"></i></p>
            <p class="quote__paragraph px-5 py-1">
              {{$t('signUp.quote.paragraph')}}
            </p>
            <p class="quote__simbols text-right">
              <i class="fa fa-quote-right"></i>
            </p>
            <p class="quote__author" v-html="$t('signUp.quote.author')"></p>
          </div>
        </div>
      </div>
    </section>
  `
});

const InputsForm = Vue.component('InputsForm', {
  props: {
    inputs: {
      type: Array
    }
  },
  computed: {
    checkingInputs() {
      return this.inputs;
    }
  },
  methods: {
    ...Vuex.mapActions(['checkInput', 'updateInputs']),
    checking(input, index) {
      const {name, value} = input;
      this.checkInput(input).then(response => {
        const [valid, message] = response;
        this.updateInputs([
          index,
          {
            isValid: valid,
            errorNumber: message,
            name,
            value
          }
        ]);
      });
    }
  },
  template: `<div class="my-2">
              <InputText
                v-for="(input, index) in inputs"
                :key="input.name"
                :name="input.name"
                :type="input.type"
                :label="$t(input.label)"
                :input-error="input.isValid === false"
                :error-message="$tc(input.errorMessage, input.errorNumber)"
                autocomplete="new-password"
                :inputPassword="input.isPassword"
                v-model="input.value"
                @blur="checking(input, index)"
              />
            </div>`
});
