const getDefaultState = () =>({});
const store = new Vuex.Store({
  state: getDefaultState(),
  getters: {},
  mutations: {
    MUTATE(state, {property, value}) {
      state[property] = value;
    }
  },
  actions: {
    notify(_context, {message, type}) {
      this.AdNotification.notify(message, type);
    }
  }
});
