'use strict';

angular.module('postCenterWebClientApp')
  .controller('sendSurveyCtrl', ['$scope', 'CaseService', 'AdNotification', '$filter',
    function ($scope, CaseService, AdNotification, $filter) {

      $scope.loading = false;

      function processAction(action, caseObject) {
        $scope.caseObject.survey = caseObject.survey;
        if ($scope.callbackOnChange) {
          $scope.callbackOnChange(action, caseObject);
        }
      }

      $scope.sendSurvey = function () {

        var ticketId = $scope.caseObject.resource_id;

        if (!$scope.caseObject.survey.sent) {
          $scope.loading = true;
          var action = 'send_survey';

          CaseService.sendSurvey(ticketId).then(function (caseObject) {
            processAction(action, caseObject)
          }, function (data) {
            AdNotification.error(data, action)
          }).finally(function () {
            $scope.loading = false;
          })
        }
      };

      $scope.getTooltipText = function () {
        if ($scope.loading) {
          return $filter('translate')('CASECONTROL_SENDING_SURVEY');
        }
        var caseObj = $scope.caseObject;
        if (caseObj.survey && caseObj.survey.sent) {
          return $filter('translate')('CASECONTROL_SURVEY_SENT')
        }
        return $filter('translate')('CASECONTROL_SEND_SURVEY')
      }

    }
  ]);
