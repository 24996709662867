(function(module) {
  'use strict';
  AdMobile.$inject = ['$window'];

  function AdMobile($window) {
    return {
      isMobile() {
        const size = 768;
        return $window.innerWidth <= size;
      }
    };
  }
  module.factory('AdMobile', AdMobile);
})(angular.module('postCenterWebClientApp'));
