'use strict';
angular
  .module('postCenterWebClientApp')
  .directive('newCase', postCenterNewCaseDirective);
function postCenterNewCaseDirective() {
  return {
    templateUrl: 'blocks/crm/views/new_case.html',
    restrict: 'E',
    scope: {
      accountUid: '=',
      userUid: '=',
      massive: '='
    },
    controller: postCenterNewCaseController
  };
}
postCenterNewCaseController.$inject = [
  '$scope',
  '$filter',
  '$q',
  'CaseService',
  'adDataValidator',
  'AdModal',
  'CaseDetailManager',
  'SocialAccountService',
  'DepartmentService',
  'UserService'
];
function postCenterNewCaseController(
  $scope,
  $filter,
  $q,
  _case,
  adDataValidator,
  AdModal,
  _casedetailmanager,
  SocialAccountService: SocialAccountService,
  DepartmentService: DepartmentService,
  UserService: UserService,
) {
    const translate = key => $filter('translate')(key);
    $scope.loading = false;
    $scope.selectedAccountId = null;
    $scope.selectedDepartmentId = null;
    $scope.chooseAccountTitle = 'CRM_NEW_CASE_MESSAGE_CHOOSE_ACCOUNT';
    $scope.helpTemplate = 'blocks/crm/views/new_case_message_help.html';
    $scope.chooseDepartmentTitle = 'CRM_NEW_CASE_MESSAGE_CHOOSE_DEPARTMENT';

    $scope.submitTitle = 'CRM_NEW_CASE_SEND_MESSAGE';
    if ($scope.massive) {
      $scope.submitTitle = 'CRM_NEW_CASE_CREATE_CAMPAIGN';
    };

    $q.all([
      UserService.getProfile(),
      DepartmentService.getDepartments()
    ]).then(([user, departments]) => {
      $scope.departments = departments;
      $scope.userDepartments = user.profile.departments;
      $scope.userIsAdmin = user.isAdmin;
      $scope.departmentsList = $scope.availableDepartments();
    });

    SocialAccountService.getSocialAccounts().then(accounts => {
      const wspsAccounts = accounts.filter(
        account => account.sn === 'whatsapp' && account.config.proactive
      )
      const emailAccounts = accounts.filter(
          account => account.sn === 'email'
      )
      if ($scope.massive) {
        $scope.availableAccounts = wspsAccounts;
      } else {
        $scope.availableAccounts = [...wspsAccounts, ...emailAccounts]
      }
      
      if ($scope.accountUid) {
        $scope.availableAccounts.forEach(e => {
          if (e.uid == $scope.accountUid) {
            $scope.selectedAccountId = e.id;
            $scope.ticket.account = e;
            $scope.$apply();
          }
        });
      }
    })

    $scope.ticket = {
    account: null,
    department: null,
    };
    $scope.errors = {
    noAccount: false,
    noDepartment: false
    };
    const closeBlockListenerOff = $scope.$on('closeBlock', () => {
      $scope.ticket = {
          account: null,
          department: null,
      };
      $scope.errors = {
          noAccount: false,
          noDepartment: false
      };
    });

    $scope.$on('$destroy', () => {
      closeBlockListenerOff();
    });

    $scope.showHelp = function() {
    var modalConfig = {
        template: $scope.helpTemplate,
        controller: function() {},
        callbackOnSuccess: function() {},
        size: 'lg',
        resolve: function() {}
    };
    AdModal.build(modalConfig, function() {});
    };
    $scope.validateAccount = function() {
      $scope.availableAccounts.forEach(element => {
        if ($scope.selectedAccountId == element.id) {
          $scope.ticket.account = element;
        }
      });

      if (!adDataValidator.hasData($scope.ticket.account)) {
          $scope.errors.noAccount = true;
          return false;
      }
      $scope.errors.noAccount = false;
      return true;
    };
    $scope.availableDepartments = function() {
      const noDepartment = { id: -1, name: translate('GENERAL_NO_DEPARTMENTS') };
      
      if ($scope.userDepartments && !$scope.userIsAdmin) {
        let filteredDepartments = $scope.departments.filter(department => {
          return $scope.userDepartments.includes(department.id);
        });
        return [noDepartment, ...filteredDepartments];
      } else if ($scope.userIsAdmin) {
        return [noDepartment, ...$scope.departments];
      }
      return [noDepartment];
    };
    $scope.validateDepartment = function() {
      $scope.availableDepartments().forEach(element => {
        if ($scope.selectedDepartmentId == element.id) {
          $scope.ticket.department = element;
        }
      });

      if (!$scope.ticket.department) {
        $scope.errors.noDepartment = true;
        return false;
      }
      $scope.errors.noDepartment = false;
      return true;
    };
}