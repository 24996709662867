'use strict';

var app = angular.module('postCenterWebClientApp');
app.directive('emailReplyMessage', function () {
  return {
    restrict: 'E',
    scope: {
      replyToMessage: '=replyTo',
      isSending: '=isSending',
      publishCallback: '=publishCallback',
      caseId: '=',
      discardCallback: '&',
      compactView: '&',
      fallbackTargetUser: '<?'
    },
    templateUrl: 'blocks/message/views/email_reply_message.html',
    controller: 'EmailReplyMessageCtrl',
    controllerAs: '$ctrl',
    link: function(scope, element, attrs) {
      scope.summernote = $('#summernoteReply');
      scope.summernote.summernote({
        height: 200,
        minHeight: null,
        maxHeight: null,
        dialogsInBody: true,
        toolbar: [
          ['style', ['bold', 'italic', 'underline']],
          ['fontsize', ['fontsize']],
          ['color', ['color']],
          ['insert', ['link', 'table']],
          ['placeholders_group', ['placeholder_button']],
          ['para', ['ul', 'ol', 'paragraph']],
          ['height', ['height']],
          ['fontname', ['fontname']],
          ['view', ['codeview', 'help']],
        ],
        callbacks: {
          onChange: function(contents, $editable) {
            scope.text = contents;
            scope.$applyAsync();
          },
          onImageUpload: function(file) {
            scope.fileUpload(file);
          },
          onKeydown: function (event) {
            scope.keyDown(event);
          }
        }
      });
    }
  };
});
