(module => {
  'use strict';
  ProfileCtrl.$inject = [
    '$scope',
    '$rootScope',
    '$timeout',
    'UserService',
    'AdNotification',
    'AuthService',
    'AdUtils',
    'EstablishmentService',
    'DepartmentService'
  ];
  function ProfileCtrl(
    $scope,
    $rootScope,
    $timeout,
    UserService: UserService,
    AdNotification,
    AuthService,
    AdUtils,
    EstablishmentService: EstablishmentService,
    DepartmentService: DepartmentService
  ) {
    $rootScope.settingsTab.selected = 'profile';
    $scope.refreshDataCount = 0;
    $scope.uploadingAvatar = false;

    Promise.all([
      EstablishmentService.getEstablishment(),
      DepartmentService.getDepartments(),
      UserService.getProfile(),
      UserService.getGroups()
    ]).then(([establishment, departments, user, groups]) => {
      $scope.establishment = establishment
      $scope.departments = departments
      $scope.user = user
      $scope.group = groups
      $scope.user.autoassign = false
    })
    $scope.checkPassword = false;
    $scope.resentVerificationEmail = false;
    $scope.promiseCheckEmail;

    const updateAvatarAction = 'update_avatar';

    $scope.updateAvatar = (url = '') => {
      if (!url) {
        $scope.onUploadError(404, updateAvatarAction);
      }
      UserService.updateAvatar(url)
        .then(() => {
          $scope.user.profile.avatar_url = url;
          AdNotification.success(200, updateAvatarAction);
        })
        .catch(() => $scope.onUploadError())
        .finally(() => ($scope.uploadingAvatar = false));
    };

    $scope.onUploadError = function (code = 500) {
      AdNotification.error(code, updateAvatarAction);
    };

    $scope.validateImage = function (fileData, onSuccess, onError) {
      const MAX_IMAGE_SIZE = 400;
      const MIN_IMAGE_SIZE = 180;

      $scope.uploadingAvatar = true;

      AdUtils.loadImage(fileData)
        .then(image => {
          let code = '';
          let isValid = true;
          if (image.width !== image.height) {
            code = 'not_square';
            isValid = false;
          } else if (image.width > MAX_IMAGE_SIZE) {
            code = 'too_big';
            isValid = false;
          } else if (image.width < MIN_IMAGE_SIZE) {
            code = 'too_small';
            isValid = false;
          }
          if (isValid) {
            onSuccess(fileData);
          } else {
            onError();
            $scope.onUploadError(code);
          }
        })
        .catch(() => {
          onError();
        });
    };

    $scope.selectedLanguageChanged = function () {
      $rootScope.$emit('selectedLanguageChanged');
    };

    $scope.checkEmailAvailability = function () {
      const {email} = $scope.user;
      if ($scope.promiseCheckEmail) {
        $timeout.cancel($scope.promiseCheckEmail);
      }

      if (email) {
        $scope.promiseCheckEmail = UserService.checkEmailAvailability(email);
        $scope.promiseCheckEmail.then(
          data => {
            if (data['is_available']) {
              $scope.myform.email.$setValidity('availability', true);
            } else {
              $scope.myform.email.$setValidity('availability', false);
            }
          },
          () => {
            $scope.myform.email.$setValidity('availability', false);
          }
        );
      }
    };

    angular.forEach($scope.user.groups, userGroup => {
      angular.forEach($scope.groups, group => {
        if (userGroup.id === group.id) {
          group.selected = true;
          $scope.atLeastOneGroupCheck = true;
        }
      });
    });

    if ($scope.user.profile.department) {
      angular.forEach($scope.departments, department => {
        if (department.id === $scope.user.profile.department.id) {
          $scope.user.profile.department = department;
        }
      });
    }

    $scope.checkCheckbox = function () {
      $scope.atLeastOneGroupCheck = false;
      $scope.checkboxGroup = {dirty: true};
      angular.forEach($scope.groups, group => {
        if (group.selected) {
          $scope.atLeastOneGroupCheck = true;
        }
      });
    };

    $scope.confirmEditUser = function () {
      const groupList = [];
      angular.forEach($scope.groups, group => {
        if (group.selected) {
          groupList.push(group);
        }
      });
      $scope.user.groups = groupList;

      const action = 'edit_user';
      UserService.editUser($scope.user).then(
        () => {
          if ($scope.user.password) {
            delete $scope.user.repassword;
            delete $scope.user.password;
          }
          return UserService.getProfile()
        },
        error => {
          AdNotification.error(error, action);
        }
      ).then((user: ExtendedUser) => {
        $scope.selectedLanguageChanged()
        AdNotification.success(201, action)
      })
    };

    $scope.resendVerificationEmail = function () {
      AuthService.resendVerificationEmail($scope.user.id)
        .then(() => {
          $scope.resentVerificationEmail = true;
          AdNotification.success(202, 'resend_verification_email');
        })
        .catch(() => {
          AdNotification.error(400, 'resend_verification_email');
        });
    };
  }
  module.controller('ProfileCtrl', ProfileCtrl);
})(angular.module('postCenterWebClientApp'));
