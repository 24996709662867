'use strict';

class InstallCtrl {

  $scope: any
  $state: any
  $stateParams: any
  $filter: any
  UserService: UserService
  CrmService: CrmService
  AdNotification: AdNotification

  $onInit() {
    Promise.all([
      this.UserService.getProfile(),
    ]).then(([user]) => {
      if (!user.isAdmin) {
        window.location.href = '/';
      }
      const install = this.$stateParams.install;
      const code = this.$stateParams.code;

      if (!install || !code) {
        this.$state.go('crm.inbox');
      }

      if (install === 'hubspot') {
        this.$scope.appName = 'HubSpot';
        this.CrmService.installCrm(install, {code})
          .then(() => {
            this.AdNotification.notify(
              this.$filter('translate')('ADMIN_INTEGRATION_INSTALL_SUCCESS'),
              'success'
            );
          })
          .catch(() => {
            this.AdNotification.notify(
              this.$filter('translate')('ADMIN_INTEGRATION_INSTALL_ERROR'),
              'error'
            );
            this.$scope.error = true;
          })
          .finally(() => {
            this.$scope.loading = false;
            this.$scope.$applyAsync();
            // Delay the redirect to allow notifications to be displayed
            setTimeout(() => {
              window.location.href = '/';
            }, 5000);
          });
      } else {
        this.AdNotification.notify(
          this.$filter('translate')('ADMIN_INTEGRATION_INSTALL_ERROR'),
          'error'
        );
        this.$scope.error = true;
        this.$scope.loading = false;
        this.$scope.$applyAsync();
        setTimeout(() => {
          window.location.href = '/';
        }, 5000);
      }
    });
  }

  constructor(
    $scope,
    $state,
    $stateParams,
    $filter,
    UserService: UserService,
    CrmService: CrmService,
    AdNotification: AdNotification
  ) {
    this.$scope = $scope;
    this.$state = $state;
    this.$filter = $filter;
    this.$stateParams = $stateParams;
    this.UserService = UserService;
    this.$scope.loading = true;
    this.$scope.appName = '';
    this.$scope.error = false;
    this.CrmService = CrmService;
    this.AdNotification = AdNotification;
  };
};

angular
  .module('postCenterWebClientApp')
  .controller('InstallCtrl', InstallCtrl)
