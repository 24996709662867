const AssignmentDetailAccount = Vue.component('AssignmentDetailAccount', {
  inject: ['findAssignmentMode', 'getAssignmentCount'],
  props: {
    account: {
      type: Object,
      required: true
    },
    executives: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      defaultAssignment: null,
      title: '',
      agents: []
    };
  },
  computed: {
    ...Vuex.mapGetters(['assignmentModes', 'editMode', 'lastDetails']),
    assignmentCount() {
      const {executives} = this.lastDetails;
      return this.getAssignmentCount(this.assignmentModes, executives);
    },
    autoAssignation() {
      const {
        channel: {
          config: {enable_autoassignation}
        }
      } = this.lastDetails;
      return enable_autoassignation ? 1 : 2;
    }
  },
  watch: {
    executives() {
      if (!this.editMode) {
        this.setAgents();
      }
    },
    account() {
      this.getTitle();
    }
  },
  mounted() {
    this.setAgents();
    this.getTitle();
  },
  methods: {
    ...Vuex.mapActions([
      'goBack',
      'updateAccount',
      'getSnTitle',
      'editingMode',
      'updateAutoassign',
      'updateAgents'
    ]),
    getTitle() {
      const {
        channel: {sn}
      } = this.lastDetails;
      this.getSnTitle(sn).then(title => {
        this.title = title;
      });
    },
    setAgents() {
      const {executives} = this.lastDetails;
      this.clones = JSON.parse(JSON.stringify(executives));
    },
    assignHandler(agent, {id, name}) {
      const {
        mode: {id: currentMode}
      } = agent;
      if (currentMode !== id) {
        this.editingMode(true);
        const {channel} = this.lastDetails;
        const agentIndex = this.lastDetails.executives.indexOf(agent);
        agent.accounts.map(account => {
          if (channel.id === account.id) {
            account.mode = {id, name};
          }
          return account;
        });
        this.lastDetails.executives.splice(agentIndex, 1, {
          ...agent,
          mode: {id, name}
        });
      }
    },
    toggleAccountStatus(account) {
      this.editingMode(true);
      const {
        channel: {
          config: {enable_autoassignation}
        }
      } = this.lastDetails;
      this.updateAutoassign(!enable_autoassignation);
    },
    setAllAssignmentsTo({name, id}) {
      this.editingMode(true);
      const newMode = {name, id};
      const {executives, channel} = this.lastDetails;
      const agents = executives.map(executive => {
        executive.accounts.map(account => {
          if (channel.id === account.id) {
            account.mode = newMode;
          }
          return account;
        });
        return {...executive, ...{isActive: newMode.id > 0, mode: newMode}};
      });
      this.updateAgents(agents);
    },
    saveAccount() {
      this.updateAccount().then(() => {
        this.editingMode(false);
      });
    },
    cancel() {
      this.editingMode(false);
      this.updateAgents(this.clones);
    }
  },
  template: `
    <article>
      <AssignmentHeaderCard
        :title="title"
        :account="account">
        <template v-slot:cardContent>
          <p class="d-flex space-between">
            {{ $tc('enableAutoAssign', autoAssignation) }}
            <ToggleSwitch
              :active="account.config.enable_autoassignation"
              @switched="() => toggleAccountStatus(account)"
            />
          </p>
          <p class="d-flex space-between" v-for="mode in assignmentModes">
            {{ mode.makeAllAgents }}
            <ToggleSwitch
              :active="assignmentCount[mode.id] === lastDetails.executives.length"
              @switched="setAllAssignmentsTo(mode)"
            />
          </p>
        </template>

      </AssignmentHeaderCard>
      <SaveConfigAssign
            :onSave="saveAccount"
            :onCancel="cancel"
            :toggle="editMode" />
      <section class="my-1">
        <hr />
        <h3>{{ $t('agentsSummary') }}</h3>
        <p v-for="mode in assignmentModes" class="d-flex space-between">
          <span>{{ mode.summary }}</span>
          <strong>{{ assignmentCount[mode.id] }}</strong>
        </p>
        <hr />
      </section>
      <AssignmentList
        :title="$t('agents')"
        :total="lastDetails.executives.length"
        :list="lastDetails.executives"
        :onAssignChange="assignHandler"
      />
    </article>
  `
});
