(function (module) {
  'use strict';
  caseSideViewCommentsCtrl.$inject = [
    '$scope',
    '$rootScope',
    'CaseService',
    'UserService',
    'AdNotification',
    '$element'
  ];
  function caseSideViewCommentsCtrl(
    $scope,
    $rootScope,
    CaseService,
    UserService,
    AdNotification,
    $element
  ) {

    const vm = this;
    vm.openUserSelector = false;
    vm.userFinder = '';
    vm.userTagged = [];
    $scope.newComment = '';

    vm.$onInit = () => {
      getUser();
      getCms();
      _scrollDown();
    };

    $scope.getUsers = ({key}) => {
      if (key === '@') {
        vm.openUserSelector = true;
      } else {
        vm.userFinder = '';
        vm.openUserSelector = false;
      }
    };
    $scope.agentSelected = ({name, cmid}) => {
      const [username] = name.split(' ');
      vm.newComment = vm.newComment.concat(name);
      const tagname = `@${username}`;
      if (!vm.userTagged.find(user => user.cmid === cmid)) {
        vm.userTagged.push({tagname, cmid, name});
      }
      vm.openUserSelector = false;
    };
    const caseUpdateListenerOff = $scope.$on('case:hasBeenUpdated', (_event, caseObject) => {
      vm.caseObject = caseObject;
      $scope.newComment = '';
    });
    $scope.$on('$destroy', () => {
      caseUpdateListenerOff();
    });
    const _scrollDown = () => {
      $element.find('.case-side-view-comments__list').scrollTo('max', 500);
    };

    const getUser = () => {
      UserService.getProfile().then(
        (user) => {
          const {first_name: firstName, last_name: lastName, id} = user;
          vm.author = {
            cmid: id,
            name: `${firstName} ${lastName}`
          };
        }
      );
    };

    const getCms = () => {
      UserService.getCms().then(
        (cms) => {
          vm.cms = cms;
        }
      );
    };
    const verifyTaggedUsers = () => {
      const regex = /@(\w{0,30})\b/gi;
      const message = vm.newComment.replaceAll(/(<([^>]+)>)/gi, ' ');
      if (vm.userTagged.length === 0) {
        return [];
      }
      const users = message.match(regex).map(hash => hash.trim());
      const notifyUsers = vm.userTagged
        .filter(({tagname}) => users.includes(tagname))
        .map(({cmid, name}) => ({cmid, name}));
      return notifyUsers;
    };
    vm.addComment = function () {

      vm.newComment = vm.newComment.replace(/(<([^>]+)>)/ig,"").replace(/\r\n|\r|\n/g,"<br />");

      const {resource_id: resourceId} = vm.caseObject;

      const tagged = verifyTaggedUsers();
      const newComment = {
        text: vm.newComment,
        author: vm.author,
        tagged: tagged
      };
      const comments = vm.caseObject.comments.slice();

      comments.forEach(comment => {
        delete comment.isLast;
      });
      comments.push(newComment);
      const data = {
        commented: true,
        comments
      };

      vm.commenting = true;

      CaseService.addComment(resourceId, data).then(
        caseObject => {
          vm.newComment = undefined;
          vm.caseObject.comments = caseObject.comments;
          vm.caseObject.commented = true;
          vm.userTagged = [];
          vm.commenting = false;
          $rootScope.$emit('column:actionOnCase', {
            action: 'updateCase',
            element: vm.caseObject
          });
        },
        data => {
          vm.commenting = false;
          AdNotification.error(data, 'comment_case');
        }
      );
    };
  }
  module.controller('caseSideViewCommentsCtrl', caseSideViewCommentsCtrl);
})(angular.module('postCenterWebClientApp'));
