const EpaDetailTable = Vue.component('EpaDetailTable', {
  data() {
    return {
      labels: {
        more: this.$t('expandText.more'),
        less: this.$t('expandText.less')
      }
    };
  },
  computed: {
    ...Vuex.mapGetters('epaAnalytics', ['table', 'surveys', 'detailTableLoading']),
    getTable() {
      if (this.table){
        const {data_results = {}} = this.table;
        return data_results;
      }
      return {}
    },
    headers() {
      const {table_headings = []} = this.getTable;
      return table_headings.map(header => header.label);
    },
    tooltips() {
      const {table_headings = []} = this.getTable;
      return table_headings.map(header => header.tooltip);
    },
    results() {
      const {table_data = []} = this.getTable;
      return table_data;
    }
  },
  methods: {
    ...Vuex.mapActions('epaAnalytics', ['exportData']),
    ...Vuex.mapMutations('epaAnalytics', {
      sortTable: 'SORT_TABLE'
    }),
    getUrl(id) {
      return `#/case/${id}/`;
    },
    formatDate(date) {
      return moment.unix(date).format('DD/MM/YY');
    },
    withoutFormat(num) {
      return [1, 2, 3, 4, 5, 7, 8, 9, 10].includes(num);
    },
    sortedBy({order}) {
      const sortable = this.results.sort((elemA, elemB) => {
        const [sortA] = elemA;
        const [sortB] = elemB;
        if (order === 'asc') {
          return sortA > sortB ? -1 : 1;
        }
        return sortB > sortA ? -1 : 1;
      });
      this.sortTable(sortable);
    }
  },
  template: `
    <SkeletonTable v-if="detailTableLoading"
      class="history--kpi-chart card"
    />
    <div class="ranking-table mb-1" v-else>
      <BasicTable
        :headings="headers"
        :tooltips="tooltips"
        :custom="true"
      > 
      <tr v-for="row in results">
        <td v-for="(elem, index) in row">
          <div class="d-flex align-center">
            <span v-if="index === 0">
              <strong>{{ formatDate(elem) }}</strong>
            </span>
            <span v-if="withoutFormat(index)">{{ elem }}</span>
            <span v-if="index === 6">
              <Tag :text="elem" />
            </span>
            <div class="comment" v-if="index === 11">
              <ExpandableText
                v-if="elem"
                :paragraph="elem"
                :labels={...labels}
                :maxHeight="100"
                :maxLength="80" />
            </div>
            <div class="d-flex center" v-if="index === 12">
              <a :href="getUrl(elem)" target="_blank">{{ elem }}</a>
            </div>
          </div>
        </td>
    </tr>
    </BasicTable>
  </div>`
});
