class BusinessRulesService {
  constructor(AdRequest) {
    this.AdRequest = AdRequest;
  }
  get() {
    return this.AdRequest.get('/api/v1/rules').then(response => {
      return response.map(rule => {
        return rules.BusinessRule.parse(rule);
      });
    });
  }

  save(businessRule) {
    if (!businessRule.rule_id) {
      return this.create(businessRule);
    } else {
      return this.update(businessRule);
    }
  }
  create(businessRule) {
    return this.AdRequest.post('/api/v1/rules', businessRule.serialize());
  }
  update(businessRule) {
    return this.AdRequest.put(
      `/api/v1/rule/${businessRule.rule_id}`,
      businessRule.serialize()
    );
  }
  delete(businessRule) {
    return this.AdRequest.delete(`/api/v1/rule/${businessRule.rule_id}`);
  }
}
angular
  .module('postCenterWebClientApp')
  .service('BusinessRulesService', BusinessRulesService);
