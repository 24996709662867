/* global DateFilterMixin DateTimePopoverMixin */
const EpaAddFilters = Vue.component('EpaAddFilters', {
  props: {
    toggle: Boolean
  },
  data() {
    return {
      toggleList: false,
      isChecked: true,
      selected: [],
      copyDepartments: []
    }
  },
  computed: {
    ...Vuex.mapGetters('epaAnalytics', ['departmentsSelector', 'filters']),
    isActive() {
      return this.toggleList ? 'active' : '';
    }
  },
  methods: {
    ...Vuex.mapActions('epaAnalytics', ['setFilters']),
    toggleSelector(event) {
      if (
        event.target.id !== 'department-search-input' &&
        event.target.id !== 'department-checkbox'
      ) {
        this.toggleList = !this.toggleList;
      }
    },
    allSelector() {
      if (this.isChecked) {
        this.selected = [];
      } else {
        this.selected = this.copyDepartments.map(dep => dep.id);
      }
      this.updateFilters();
    },
    updateFilters() {
      const isAllSelected = this.isChecked;
      if (isAllSelected && this.selected.length > 0) {
        this.isChecked = false;
      }

      this.filters.departments = this.selected;
      this.setFilters(this.filters);
    }
  },
  watch: {
    selected: {
      handler(newValue) {
        if (newValue.length === 0) {
          this.isChecked = true;
        }
      },
      deep: true, 
    },
  },
  created() {
    this.copyDepartments = JSON.parse(JSON.stringify(this.departmentsSelector));
    this.selected = [];
    this.isChecked = true;
    this.updateFilters();
  },
  template: `
  <div class="d-flex position-relative">
    <span class="align-self-center mr-1">Departamento:</span>
    <div class="ads-selector-wrapper">
      <div class="ads-selector">
        <div class="ads-selector__header d-flex" :class="isActive" @click="toggleSelector">
          <h5 class="bold d-inline">{{ isChecked ? 'Todo' : 'Seleccionado: ' + selected.length }}</h5>
          <span class="fa fa-chevron-down" />
        </div>
        <div v-show="toggleList">
          <label>
            <input 
              type="checkbox" 
              id="department-checkbox" 
              v-model="isChecked" 
              @change="allSelector" 
            /> 
            <span class="mx-1">Todo</span>
          </label>
          <div v-for="(dep, index) in copyDepartments" :key="dep.id">
            <label>
              <input 
                type="checkbox" 
                :id="dep.id" 
                :value="dep.id" 
                v-model="selected" 
                @change="updateFilters()"
                >
              <span class="mx-1">{{ dep.name }}</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>`
});
const EpaHeaderFilters = Vue.component('EpaHeaderFilters', {
  computed: {
    ...Vuex.mapGetters('epaAnalytics', ['activedFilter', 'dataFilters']),
    hasActiveFilter() {
      return this.dataFilters.find(filter => filter.active) ? true : false;
    }
  },
  methods: {
    isActive(filter) {
      const {active = false} = this.activedFilter(filter) || {};
      return active;
    },
    clearFilters() {
    }
  },
  template: `<div class="my-1 mx-1">
  <div class="d-flex">
      <PeriodSelector />
      <RangeTimeFilter />
      <EpaAddFilters v-if="true" />
  </div>
</div>`
});

const PeriodSelector = Vue.component('PeriodSelector', {
  mixins: [DateFilterMixin],
  data() {
    return {
      periods: [
        {
          label: 'today',
          value: 0,
          checked: false
        },
        {
          label: 'yesterday',
          value: 1,
          checked: false
        },
        {
          label: 'last-days',
          value: 3,
          checked: false
        },
        {
          label: 'last-days',
          value: 7,
          checked: true
        },
        {
          label: 'last-days',
          value: 15,
          checked: false
        },
        {
          label: 'last-days',
          value: 30,
          checked: false
        },
        {
          label: 'last-days',
          value: 90,
          checked: false
        },
        {
          label: 'custom',
          value: 'custom',
          checked: false
        }
      ],
      header: {label: 'last-days', value: 7}
    };
  },
  computed: {
    ...Vuex.mapGetters('epaAnalytics', ['filters']),
    periodsByName() {
      return this.periods.filter(period =>
        new RegExp(this.input, 'ig').test(
          this.$t(period.label, {number: period.value})
        )
      );
    }
  },
  methods: {
    ...Vuex.mapActions('epaAnalytics', ['setFilters']),
    updateFilters(value) {
      this.selected = value;
      this.header = this.periods.find(period => period.value === this.selected);
      if (this.selected === 'custom') {
        this.custom = true;
      } else {
        this.lastSelected = this.selected;
        if (this.selected === 0) {
          // today
          this.filters.range_from = moment().startOf('day').unix();
          this.filters.range_to = moment().unix();
        } else if (this.selected === 1) {
          // yesterday
          this.filters.range_from = moment()
            .subtract(1, 'days')
            .startOf('day')
            .unix();
          this.filters.range_to = moment()
            .subtract(1, 'days')
            .endOf('day')
            .unix();
        } else {
          this.filters.range_to = moment().unix();
          this.filters.range_from = this.fromDaysAgo(this.selected);
        }
        // this.setLabels(this.filters);
        this.setFilters(this.filters);
        this.custom = false;
      }
      this.closeSelector();
    },
    updateCustomFilters({from, to}) {
      this.filters.range_from = from;
      this.filters.range_to = to;
      this.setFilters(this.filters);
      this.closeSelector();
      this.custom = false;
    }
  },
  template: `<div class="d-flex position-relative">
  <span class="align-self-center mr-1">Periodo:</span>
  <div class="ads-selector-wrapper">
            <div
              class="ads-selector radio-button mx-1 ml-1"
              @click="toggleSelector"
              v-click-outside="closeSelector"
            >
              <div class="ads-selector__header d-flex" :class="isActive">
                <h5 class="bold d-inline">{{ $t(header.label, {number: header.value}) }}</h5>
                <span class="fa fa-chevron-down" />
              </div>
              <div v-show="toggleList">
                <div class="p-1">
                  <input
                    type="text"
                    id="date-search-input"
                    class="form-control"
                    :placeholder="$t('filterDates')"
                    v-model="input"
                    v-on:keypress="isLetter($event)"
                  />
                </div>
                <p class="pl-1" v-if="!periodsByName.length">{{ $t('issues.not_found_period') }}</p>
                <ul v-else class="list-unstyled">
                  <li v-for="(period, index) in periodsByName" :key="index" :name="period.value">
                    <label>
                      <input
                        type="radio"
                        @click.stop="updateFilters(period.value)"
                        name="date-radio"
                        id="date-radio"
                        :checked="period.checked"
                       />
                      <span class="mx-1">{{ $t(period.label, {number: period.value}) }}</span>
                    </label>
                  </li>
                </ul>
              </div>
            </div>
            </div>
            <CustomDateEpa
            v-click-outside="cancelCustom"
            v-if="custom"
            :update-filters-handler="updateCustomFilters"
            />
  </div>`,
  i18n: {
    messages: {
      es: {
        today: 'Hoy',
        yesterday: 'Ayer',
        'last-days': 'Últimos {number} días',
        custom: 'Rango Personalizado',
        filterDates: 'Busca por fecha'
      },
      pt: {
        today: 'Hoje',
        yesterday: 'Ontem',
        'last-days': 'Últimos {number} días',
        custom: 'Personalizado',
        filterDates: 'Pesquisar por data'
      },
      en: {
        today: 'Today',
        yesterday: 'Yesterday',
        'last-days': 'Last {number} days',
        custom: 'Custom date',
        filterDates: 'Search by date'
      }
    }
  }
});
const CustomDateEpa = Vue.component('CustomDateEpa', {
  mixins: [DateTimePopoverMixin]
});
const RangeTimeFilter = Vue.component('RangeTimeFilter', {
  data() {
    return {
      locale: 'es',
      range: {
        from: {
          date: new Date(),
          time: '00:00'
        },
        to: {
          date: new Date(),
          time: '00:00'
        }
      },
      masks: {
        input: 'D/MMM/YYYY',
        title: 'MMMM',
        weekdays: 'WWW'
      }
    };
  },
  computed: {
    ...Vuex.mapGetters('epaAnalytics', ['filters']),
    updateDate() {
      this.setTime();
      this.setDate();
      return this.range;
    },
    maxDateTo() {
      return this.range.to.date ? this.range.to.date : new Date();
    }
  },
  watch: {
    filters() {
      this.setTime();
      this.setDate();
    }
  },
  mounted() {
    this.setTime();
    this.setDate();
  },

  methods: {
    ...Vuex.mapActions('epaAnalytics', ['setFilters']),
    setDate() {
      const {range_from, range_to} = this.filters;

      this.range.from.date = new Date(range_from * 1000);
      this.range.to.date = new Date(range_to * 1000);
    },
    setTime() {
      const {range_from, range_to} = this.filters;

      this.range.from.time = this.getTime(range_from);
      this.range.to.time = this.getTime(range_to);
    },
    getTime(date) {
      const getDate = time => new Date(time * 1000);

      const getTime = time => (time < 10 ? `0${time}` : time);
      const getHours = time => getDate(time).getHours();
      const getMinutes = time => getDate(time).getMinutes();
      return `${getTime(getHours(date))}:${getTime(getMinutes(date))}`;
    },
    handleChangeFilters() {
      const {from, to} = this.range;
      const {range_to, range_from} = this.filters;
      if (!from.time) {
        this.range.from.time = this.getTime(range_from);
        return;
      }
      if (!to.time) {
        this.range.to.time = this.getTime(range_to);
        return;
      }
      this.filters.range_from = moment(this.getDateFormat({...from})).unix();
      this.filters.range_to = moment(this.getDateFormat({...to})).unix();

      this.setFilters(this.filters);
    },
    getDateFormat({date, time}) {
      const [hours, minutes] = time.split(':');
      const day = date.getDate();
      const month = date.getMonth();
      const year = date.getFullYear();
      return new Date(year, month, day, Number(hours), Number(minutes));
    }
  },
  template: `<div class="d-flex align-center ml-3">
      <span>{{ $t('filters.from') }}:</span>
      <div class="d-flex flex-direction-row mx-1 epa-time-picker">
        <v-date-picker
          mode="single"
          :locale="locale"
          :masks="masks"
          :max-date="maxDateTo"
          :popover="{ placement: 'bottom', visibility: 'click' }"
          v-model="range.from.date"
          color="teal"
          @input="handleChangeFilters"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <input
              class="picker-input picker-input--active bold date-input"
              :value="inputValue"
              v-on="inputEvents"
              readonly
            />
          </template>
        </v-date-picker>
        <input
          type="time"
          v-model="range.from.time"
          class="time-input ml-1 picker-input picker-input--active bold"
          @change="handleChangeFilters"
        />
      </div>
      <span class="ml-1">{{ $t('filters.to') }}:</span>
      <div class="d-flex flex-direction-row mx-1 epa-time-picker">
        <v-date-picker
          mode="single"
          :locale="locale"
          :masks="masks"
          :min-date="range.from.date"
          :max-date="new Date()"
          :popover="{ placement: 'bottom', visibility: 'click' }"
          v-model="range.to.date"
          color="teal"
          @input="handleChangeFilters"
        >
        <template v-slot="{ inputValue, inputEvents }">
            <input
              class="picker-input picker-input--active bold date-input"
              :value="inputValue"
              v-on="inputEvents"
              readonly
            />
          </template>
        </v-date-picker>
        <input type="time"
          v-model="range.to.time"
          class="time-input ml-1 picker-input picker-input--active bold"
          @change="handleChangeFilters"
        />
      </div>
  </div>`
});
