'use strict';

angular.module('postCenterWebClientApp').factory('CustomDashboardService', [
  '$filter',
  'AnalyticsDashboard',
  '$rootScope',
  'AnalyticsService',
  function($filter, AnalyticsDashboard, $rootScope, AnalyticsService) {
    var CDS = {
      getTipifiedTicketsDistribution: function(query) {
        var types = ['radiobutton', 'boolean', 'select', 'checkbox'];
        var caseCDatas = _.filter($rootScope.customData, function(cdata) {
          return cdata.entity == 'C' && types.indexOf(cdata.type) > -1;
        });
        return Promise.all(
          caseCDatas.map(function(customData) {
            return getCustomDataAnalytics(customData, query);
          })
        );
      },

      getTableAssignedCM: function(config) {
        return AnalyticsDashboard.executiveKPI(
          'assigned_table',
          config.selectedPeriod.now,
          config.selectedPeriod.until,
          config.selectedAccount,
          config.onlyBusinessTimeOn,
          config.filterByCreated
        ).then(function(rawTable) {
          return buildTable(rawTable);
        });
      },

      getNewTicketsChart: function(query) {
        const {now} = query;
        const config = queryTransformation(query);
        const {granularity, delta} = config;
        return AnalyticsService.getStats({
          metric: 'OpenedCases',
          granularity,
          delta,
          now
        });
      },

      getIdentifiedClients: function(query) {
        return Promise.all([
          userKPIGenerator('updated', query),
          userKPIGenerator('country_id', query)
        ]);
      },

      getWaitingTickets: function(query) {
        //Tasa de tipificacion (pie) Estan los endpoints
        return ticketKPIGenerator('not_answered', query);
      },

      getProgressVsClosed: function(query) {
        var workflow = $rootScope.establishment.config['workflow_enabled'];
        if (workflow) {
          return Promise.all([
            ticketKPIGenerator('closed', query),
            ticketKPIGenerator('opened', query),
            ticketKPIGenerator('ignored', query),
            ticketKPIGenerator('in_progress', query)
          ]);
        } else {
          return Promise.all([
            ticketKPIGenerator('closed', query),
            ticketKPIGenerator('opened', query),
            ticketKPIGenerator('ignored', query)
          ]);
        }
      },

      getAllTipifiedTickets: function(query) {
        return ticketKPIGenerator('classified', query);
      },
      getSolvedTipifiedTickets: function(query) {
        return ticketKPIGenerator('closed_classified', query);
      },

      getCreatedTickets: function(query) {
        return ticketKPIGenerator('created', query);
      },

      getUpdatedTickets: function(query) {
        return ticketKPIGenerator('updated', query);
      }
    };

    function queryTransformation(query) {
      var delta = 6;
      var granularity = 'mo';
      var days = query.duration.asDays();

      if (days < 0.5) {
        delta = Math.round(days * 24 * 60);
        granularity = 'm';
      } else if (days < 1) {
        delta = Math.round(days * 24);
        granularity = 'h';
      } else if (days < 7) {
        delta = Math.round(days * 24);
        granularity = 'h';
      } else if (days < 32) {
        delta = Math.round(days) + 1;
        granularity = 'd';
      } else {
        delta = Math.round(days / 30);
        granularity = 'mo';
      }
      var config = {
        delta: delta,
        granularity: granularity
      };
      return config;
    }

    function getCustomDataAnalytics(customData, query) {
      const {now} = query;
      const metric = 'CustomData::' + customData.key;
      const config = queryTransformation(query);
      const {granularity, delta} = config;
      return AnalyticsService.getStats({metric, granularity, delta, now}).then(
        result => {
          return {
            result,
            customData
          };
        }
      );
    }

    function ticketKPIGenerator(name, query) {
      return AnalyticsDashboard.ticketKPI(
        name,
        query.now,
        query.until,
        query.account,
        query.inBusinessTime,
        query.filterByCreated
      );
    }

    function userKPIGenerator(name, query) {
      return AnalyticsDashboard.userKPI(
        name,
        query.now,
        query.until,
        query.account,
        query.inBusinessTime,
        query.filterByCreated
      );
    }

    function buildTable(rawTable) {
      var cms = _.indexBy(angular.copy($rootScope.communityManagers), 'cmid');
      var table = {};
      _.each(cms, function(cm) {
        var name = cm.name;
        var maxChars = 15;
        if (name.length > maxChars) {
          name = name.substring(0, maxChars) + '...';
        }
        table[name] = rawTable[cm.cmid];
      });
      var unassignedLabel = $filter('translate')(
        'ANALYTICS_CUSTOM_DASHBOARD_UNASSIGNED'
      );
      table[unassignedLabel] = rawTable['0'];
      table['total'] = rawTable['total'];
      return table;
    }

    return CDS;
  }
]);
