'use strict';

angular
  .module('postCenterWebClientApp')
  .directive('cannedResponseInput', function($filter, $document) {
    return {
      require: ['^^cannedResponseContext', 'cannedResponseInput'],
      controller: function() {},
      controllerAs: 'cannedResponseInputCtrl',
      bindToController: {
        text: '=ngModel',
        isRichText: '=richText'
      },
      link: function(scope, elem, attr, controllers) {
        var contextCtrl = controllers[0],
          inputCtrl = controllers[1];

        $document.on('keydown', function (e){
          switch(e.key) {
            case "ArrowUp":
                contextCtrl.receiveUpEvent(e, inputCtrl);
                break;
            case "ArrowDown":
                contextCtrl.receiveDownEvent(e, inputCtrl);
                break;
            case "Enter":
                contextCtrl.receiveEnterEvent(e);
                break;
            case "Escape":
                contextCtrl.receiveEscapeEvent(e, inputCtrl);
                break;
            }
        });

        scope.$watch(
          function() {
            return inputCtrl.text;
          },
          function(text) {
            contextCtrl.updateWithInputCtrl(inputCtrl);
          }
        );
        elem.attr(
          'placeholder',
          $filter('translate')(
            'MESSAGECONTROL_REPLY_CANNED_RESPONSE_PLACEHOLDER'
          )
        );
      }
    };
  });
