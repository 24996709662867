'use strict';

var app = angular.module('postCenterWebClientApp');

app.directive('massiveActionsCloseTicketsUnassigned', function() {
  return {
    templateUrl:
      'blocks/admin/views/massive_actions/close_tickets/close_tickets_unassigned.html',
    restrict: 'E',
    scope: {refreshParentHistory: '<'},
    controller: CloseTicketsUnassignedDirective
  };
});

CloseTicketsUnassignedDirective.$inject = [
  '$scope',
  'CloseTicketsService',
  'AdNotification',
  '$filter'
];

function CloseTicketsUnassignedDirective(
  $scope,
  CloseTicketsService,
  AdNotification,
  $filter
) {
  $scope.formLoading = false;
  $scope.optionalMessage = "";

  $scope.closeTickets = function() {
    $scope.formLoading = true;
    var data = {
      message: $scope.optionalMessage
    }

    CloseTicketsService.closeTicketUnassigned(data).then(
      function(result) {
        var message = $filter('translate')('MASSIVE_ACTION_CLOSE_SUCCESS');
        AdNotification.notify(message, 'success');
        $scope.formLoading = false;
        $scope.refreshParentHistory();
      },
      function(error) {
        var message = $filter('translate')('MASSIVE_ACTION_CLOSE_ERROR');
        $scope.formLoading = false;
        AdNotification.notify(message, 'error');
      }
    );
  };
}
