'use strict';

angular.module('postCenterWebClientApp')
  .directive('adVerticalSeparatorRight', function(){
    return {
      restrict: 'E',
      transclude: true,
      require: ['adVerticalSeparatorRight', '^^adVerticalSeparator'],
      bindToController: {
        'min': '&'
      },
      controllerAs: 'adVerticalSeparatorRightCtrl',
      controller: ['$transclude', function($transclude){
        this.transcludeFn = $transclude;
      }],
      link: function(scope, element, attrs, controllers){
        var thisCtrl = controllers[0], parentCtrl = controllers[1];
        parentCtrl.setupRightPane(thisCtrl);
      }
    };
  });