/* eslint-disable angular/controller-name */
'use strict';

angular
  .module('postCenterWebClientApp')
  .controller('ActivityLogController', ActivityLogController);

ActivityLogController.$inject = [
  '$rootScope',
  '$scope',
  '$filter',
  '$window',
  '$timeout',
  'AdRequest',
  'UserFilter',
  'UserService',
  'UsersService',
  'adExportToCsv',
  'FireTagService'
];

function ActivityLogController(
  $rootScope,
  $scope,
  $filter,
  $window,
  $timeout,
  AdRequest,
  UserFilter,
  UserService: UserService,
  UsersService: UsersService,
  adExportToCsv,
  FireTagService
) {
  //moment.duration(time, "seconds").humanize();

  FireTagService.setPageView({
    title: 'Actividad del equipo',
    path: `/${window.location.hash}`
  });
  $rootScope.analyticTab.selected = 'activitylog';
  $scope.activityLog = [];
  $scope.pagination = {pageNumber: 1};

  let users = {};

  $scope.$on('$destroy', () => {
    $scope.pageNumberWatcherOff();
    $scope.filterParamsWatcherOff();
  });

  Promise.all([
    UserService.getProfile(),
    UsersService.getUsers()
  ]).then(([loggedUser, fetchedUsers]) => {
    users = _.indexBy(fetchedUsers, 'id');

    getPage($scope.pagination.pageNumber, {});

    $timeout(() => {
      $scope.pageNumberWatcherOff = $scope.$watch('pagination.pageNumber', () => {
        if (!$scope.isPeriodValid()) {
          $scope.activityLog = [];
          $scope.pagination.totalItems = 0;
          $scope.pagination = {pageNumber: 1};
          return;
        }
        let filteredUsers = UserFilter.getFilteredUsers(
          $scope.filterParams.name,
          undefined, // Mode undefined
          $scope.filterParams.department
        );
        filteredUsers = _.map(filteredUsers, user => {
          return user.id;
        });
        const filters = {
          users: filteredUsers,
          cos: $scope.filterParams.cos,
          end:
            $scope.filterParams.date && $scope.isPeriodValid()
              ? $scope.filterParams.end
              : '',
          start:
            $scope.filterParams.date && $scope.isPeriodValid()
              ? $scope.filterParams.start
              : '',
          entries: $scope.filterParams.entries.value
        };
        if (loggedUser.profile.is_bot) {
          filters.users.push(loggedUser.id);
        }
        getPage($scope.pagination.pageNumber, filters);
      });
    }, 1000);
  });

  UserFilter.updateDepartmentsOptions($scope).then(results => {
    let user = results[0]
    let departments = results[1]
    $scope.filterParams = {
      name: '',
      department: UserFilter.getDefaultDepartment(
        user,
        departments
      ),
      cos: {
        resource_id: 0,
        label: $filter('translate')('ANALYTICS_ACTIVITY_LOG_ALL')
      },
      start: '',
      end: '',
      entries: {label: 30, value: 30},
      date: false
    }
  })
  
  $scope.pageOptions = [
    {label: 10, value: 10},
    {label: 30, value: 30},
    {label: 50, value: 50},
    {label: 150, value: 150},
    {label: 300, value: 300}
  ]
  $scope.cosOptions = [
    {resource_id: 0, label: $filter('translate')('ANALYTICS_ACTIVITY_LOG_ALL')}
  ]
  AdRequest.get('/api/v1/custom_online_statuses/').then(statuses => {
    _.map(statuses, status => {
      if (!status.deleted) {
        $scope.cosOptions.push(status);
      }
    })
  })

  $scope.filterParamsWatcherOff = $scope.$watchCollection('filterParams', () => {
    if (!$scope.isPeriodValid()) {
      $scope.activityLog = [];
      $scope.pagination.totalItems = 0;
      $scope.pagination = {pageNumber: 1};
      return;
    }
    let filteredUsers = UserFilter.getFilteredUsers(
      $scope.filterParams.name,
      undefined, // Mode undefined
      $scope.filterParams.department
    );
    filteredUsers = _.map(filteredUsers, user => {
      return user.id;
    });
    const filters = {
      users: filteredUsers,
      cos: $scope.filterParams.cos,
      end:
        $scope.filterParams.date && $scope.isPeriodValid()
          ? $scope.filterParams.end
          : '',
      start:
        $scope.filterParams.date && $scope.isPeriodValid()
          ? $scope.filterParams.start
          : '',
      entries: $scope.filterParams.entries.value
    };
    getPage(1, filters);
  });

  $scope.expanded = false;
  let ignoreOutsideClick = false;

  $scope.toggleExpanded = function (event) {
    $scope.expanded = !$scope.expanded;
    event.stopPropagation();
    $window.onclick = null;
    if ($scope.expanded) {
      $window.onclick = function (e) {
        if (!ignoreOutsideClick) {
          $scope.expanded = false;
          $scope.$apply();
        }
        ignoreOutsideClick = false;
      };
    }
  };

  $scope.ignoreClicks = function () {
    ignoreOutsideClick = true;
  };

  $scope.timeFilterTitle = function () {
    if ($scope.filterParams.date) {
      return 'ANALYTICS_ACTIVITY_LOG_CUSTOM_DATES_TITLE';
    }
    return 'ANALYTICS_ACTIVITY_LOG_ALL_DATES_TITLE';
  };

  function formatCalendarDate(date) {
    return moment(date).calendar();
  }

  function formatDate(date) {
    return moment(date).format('DD/MM/YYYY HH:mm:ss');
  }

  function parseLogEvent(event) {
    const msStart = event.start * 1000;
    const msEnd = event.end ? event.end * 1000 : new Date().getTime();

    let user = users[event['user_id']];
    let someClass = 'btn-success';

    const timeStart = moment(msStart);
    const timeEnd = moment(msEnd);
    const diff = moment(timeEnd).diff(timeStart);
    const d = moment.duration(diff);
    const months =
      d.months() > 0
        ? d.months() > 1
          ? `${d.months()} ${$filter('translate')('MONTH')}`
          : `1 ${$filter('translate')('MONTH')}`
        : '';
    const days =
      d.days() > 0
        ? d.days() > 1
          ? `${d.days()} ${$filter('translate')('DAYS')}`
          : `1 ${$filter('translate')('DAY')}`
        : '';
    const hours =
      d.hours() > 0
        ? d.hours() > 1
          ? `${d.hours()} ${$filter('translate')('HOURS')}`
          : `${d.hours()} ${$filter('translate')('HOUR')}`
        : '';
    const minutes =
      d.minutes() > 0
        ? d.minutes() > 1
          ? `${d.minutes()} ${$filter('translate')('MINUTES')}`
          : `${d.minutes()} ${$filter('translate')('MINUTE')}`
        : '';
    const seconds =
      d.seconds() > 0
        ? d.seconds() > 1
          ? `${d.seconds()} ${$filter('translate')('SECONDS')}`
          : `${d.seconds()} ${$filter('translate')('SECOND')}`
        : '';
    const reason =
      event.reason === 0
        ? $filter('translate')('ANALYTICS_ACTIVITY_LOG_AUTOMATIC')
        : $filter('translate')('ANALYTICS_ACTIVITY_LOG_MANUAL');

    if (event['online_status'] === 0) {
      someClass = 'btn-default';
    } else if (event['online_status'] === 1) {
      someClass = 'btn-warning';
    }
    if (!user) {
      user = {first_name: 'Postcenter', last_name: 'Bot'};
    }
    const arrayTime = [months, days, hours, minutes, seconds];
    const getDuration = () => {
      const time = arrayTime.filter(_date => _date);
      return time.length > 0
        ? time.join(', ')
        : $filter('translate')('LESS_THAN_A_SECOND');
    };
    return {
      user: `${user['first_name']} ${user['last_name']}`,
      label: event.cos.label,
      end: formatDate(msEnd),
      start: formatDate(msStart),
      startTooltip: formatCalendarDate(msStart),
      endTooltip: formatCalendarDate(msEnd),
      current: !event.end,
      duration: getDuration(),
      duration_in_seconds: diff / 1000,
      reason,
      class: someClass
    };
  }

  function getPage(page, filters) {
    $scope.loading = true;
    UsersService.getOnlineStatus(page - 1, filters).then(
      response => {
        $scope.loading = false;
        $scope.activityLog = _.chain(response.list).map(parseLogEvent).value();
        $scope.pagination.totalItems = response.elements;
        $scope.pagination.pageSize = response['page_size'];
      },
      () => {
        $scope.loading = false;
      }
    );
  }
  $scope.exportToCsv = function () {
    const title = $filter('translate')('ANALYTICS_ACTIVITY_LOG');
    const headers = {
      user: $filter('translate')('GENERAL_USERNAME'),
      label: $filter('translate')('GENERAL_STATUS'),
      reason: $filter('translate')('ANALYTICS_ACTIVITY_LOG_REASON'),
      start: $filter('translate')('BUSINESS_RULES_START_TIME'),
      end: $filter('translate')('BUSINESS_RULES_END_TIME'),
      duration: $filter('translate')('ANALYTICS_ACTIVITY_LOG_DURATION'),
      duration_in_seconds: $filter('translate')(
        'ANALYTICS_ACTIVITY_LOG_DURATION_IN_SECONDS'
      )
    };
    const data = [];
    if ($scope.activityLog || $scope.activityLog.length > 0) {
      $scope.activityLog.map(log => {
        data.push({
          [headers.user]: log.user,
          [headers.label]: log.label,
          [headers.reason]: log.reason,
          [headers.start]: log.start,
          [headers.end]: log.end,
          [headers.duration]: log.duration.replace(/,/g, ' '),
          [headers.duration_in_seconds]: log.duration_in_seconds
        });
      });
      adExportToCsv.exportToCsv(title, data);
      FireTagService.setEvent({
        name: 'exportacion_general',
        params: {
          export_name: title
        }
      });
    }
  };
  $scope.isPeriodValid = function () {
    if (!$scope.filterParams.date) {
      return true;
    }
    if ($scope.filterParams.start && $scope.filterParams.start > moment()._d) {
      return false;
    }
    if (!$scope.filterParams.start || !$scope.filterParams.end) {
      return true;
    }
    return $scope.filterParams.start <= $scope.filterParams.end;
  };
}
