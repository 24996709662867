const EpaBotForm = Vue.component('EpaBotForm', {
  data() {
    return {
      notifyShow: {
        nameIssue: true,
        channelsIssue: true
      }
    };
  },
  computed: {
    ...Vuex.mapGetters('epaConfig', [
      'editingSurvey',
      'warningActiveSurvey',
      'surveyChanged',
      'checkForChanges'
    ]),
    notReady() {
      const {accounts} = this.editingSurvey;

      const notReady = accounts.length === 0;
      if (notReady) {
        this.showToast();
      }
      return notReady;
    }
  },
  methods: {
    ...Vuex.mapActions('epaConfig', [
      'notify',
      'updateSurvey',
      'cancelEdition',
      'isActiveSurvey',
      'toggleModal'
    ]),
    showToast() {
      const {accounts} = this.editingSurvey;
      const {channelsIssue} = this.notifyShow;

      if (accounts.length === 0 && channelsIssue) {
        this.notify({
          message: this.$t('toast.warnings.channels'),
          type: 'warning'
        });
        this.notifyShow.channelsIssue = false;
      }
    }
  },
  template: `<div>
    <h4 class="m-2">{{ editingSurvey.name }}</h4>
    <Collapse
      :title="$t('step', {number:1})"
      :detail="$t('detail[0]')"
      icon="check-circle"
      :warning="notReady">
      <EpaFirstStep />
    </Collapse>
    <Collapse
      :title="$t('step', {number:2})"
      :detail="$t('detail[1]')"
      icon="check-circle">
      <EpaSecondStep />
      </Collapse>
      <Collapse
      :title="$t('step',{number:3})"
        :detail="$t('detail[2]')"
        :customPadding="{yAxis:0, xAxis:0}"
        icon="check-circle">
        <EpaThirdStep />
      </Collapse>
      <Collapse
        :title="$t('step',{number:4})"
        :detail="$t('detail[3]')"
        icon="check-circle">
        <EpaFourStep />
      </Collapse>
    <div class="survey-footer">
      <button class="btn ads-button ads-button--primary mr-1"
        :disabled="!checkForChanges"
        @click="isActiveSurvey">
        {{ $t('buttons.save') }}
      </button>
      <button class="btn ads-button" @click="cancelEdition">
        {{ $t('buttons.cancel') }}
      </button>
      <span>{{ $t('config') }}</span>
    </div>
    <ConfirmModal
      :open="surveyChanged"
      :onConfirm="() => toggleModal({name:'surveyChanged'})"
      :title="$t('confirm.surveyChanged.title')"
      :description="$t('confirm.surveyChanged.description')"
      :labels="{
        confirm: $t('confirm.surveyChanged.onConfirm')
      }"
    />
    <ConfirmModal
      :open="warningActiveSurvey"
      :onConfirm="updateSurvey"
      :onCancel="() => toggleModal({name:'warningActiveSurvey'})"
      :title="$t('confirm.activeSurvey.title')"
      :labels="{
        confirm: $t('confirm.activeSurvey.onConfirm'),
        cancel: $t('confirm.activeSurvey.onCancel')
      }">
      <div>
        <p>{{ $t('confirm.activeSurvey.desc.message')}}</p>
        <p class="mt-2">{{ $t('confirm.activeSurvey.desc.confirm')}}</p>
      </div>
    </ConfirmModal>
  </div>`,
  i18n: {
    messages: {
      es: {
        step: 'Paso {number}',
        config: 'Configura tu encuesta en 4 pasos',
        detail: [
          'Define nombre y elige las cuentas en las que desees enviar la encuesta',
          'Establece la forma en la que se enviará la encuesta',
          'Activa las preguntas que desees agregar a la encuesta',
          'Agrega un mensaje para agradecer a tus clientes por sus respuestas'
        ]
      },
      pt: {
        step: 'Passo {number}',
        config: 'Montar sua pesquisa em 4 etapas',
        detail: [
          'Defina o nome e escolha as contas para as quais você deseja enviar a pesquisa',
          'Definir a forma como a pesquisa será enviada',
          'Ative as perguntas que você deseja acrescentar à pesquisa',
          'Adicione uma mensagem para agradecer seus clientes por suas respostas'
        ]
      },
      en: {
        step: 'Step {number}',
        config: 'Set up your survey in 4 steps',
        detail: [
          'Define name and choose the accounts on which you want to send the survey',
          'Set the way in which the survey will be sent',
          'Activate the questions you want to add to the survey',
          'Add a message to thank your customers for their answers'
        ]
      }
    }
  }
});
