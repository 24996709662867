class CrmService {

  private AdRequest

  constructor(AdRequest: AdRequestService) {
    this.AdRequest = AdRequest;
  }
  searchMetadataCrm({crmName, entityType}, data) {
    return this.AdRequest.post(
      `/api/v1/crm/${crmName}/search/${entityType}/`,
      data
    );
  }
  linkMetadataCrm({crmName, entityType}, data) {
    return this.AdRequest.post(
      `/api/v1/crm/${crmName}/link/${entityType}`,
      data
    );
  }
  createEntity({crmName, entityType}, data) {
    return this.AdRequest.post(
      `/api/v1/crm/${crmName}/create-entity/${entityType}`,
      data
    );
  }
  getMetadataCrm({crmName, entityType, entityId}, data) {
    return this.AdRequest.post(
      `/api/v1/crm/${crmName}/get-metadata/${entityType}/${entityId}`,
      data
    );
  }
  setMetadataCrm({crmName, entityType, entityId}, data) {
    return this.AdRequest.post(
      `/api/v1/crm/${crmName}/set-metadata/${entityType}/${entityId}`,
      data
    );
  }
  configCrm(crmName, data) {
    return this.AdRequest.post(`/api/v1/crm/${crmName}/config`, data);
  }
  editMetadataCrm(resourceId, data) {
    return this.AdRequest.put(`/api/v1/custom_data/${resourceId}/`, data);
  }
  getEntity(crmName: string, entityType: string, entityId: string) {
    return this.AdRequest.get(`/api/v1/crm/${crmName}/${entityType}/${entityId}`);
  }
  saveEntity(crmName: string, entityType: string, entityId: string, properties: Object) {
    return this.AdRequest.put(`/api/v1/crm/${crmName}/${entityType}/${entityId}`, {properties: properties});
  }
  unlinkEntity(crmName: string, entityType: string, resourceId:string) {
    return this.AdRequest.post(`/api/v1/crm/${crmName}/unlink/${entityType}/`, {adereso_id: resourceId});
  }
  installCrm(crmName, data) {
    return this.AdRequest.post(`/api/v1/crm/${crmName}/install`, data);
  }
}

angular.module('postCenterWebClientApp').service('CrmService', CrmService);
