(function (module) {
  'use strict';
  AdDataFormatter.$inject = [];

  function AdDataFormatter() {
    return {
      formatPhoneNumber(number) {
        // Check if value is a valid number
        if (number !== null && !isNaN(number)) {
          const cleanNumber = `+${number.replace(/[\W_]/g, "")}`;
          const numberFormatter = new libphonenumber.AsYouType();
          return numberFormatter.input(cleanNumber);
        } else {
          return ''
        }
      }
    }
  }
  module.factory('AdDataFormatter', AdDataFormatter)
})(angular.module('postCenterWebClientApp'));
