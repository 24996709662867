const Checkbox = Vue.component('Checkbox', {
  inheritAttrs: false,
  model: {
    prop: 'checked',
    event: 'change'
  },
  props: {
    checked: Boolean,
    label: {
      type: String,
      default: ''
    }
  },
  template: `
        <label class="ads-checkbox">
          <input
            v-bind="$attrs"
            type="checkbox"
            v-on:change="$emit('change', $event.target.checked)"
            v-bind:checked="checked"
          />
            <slot>
              <span class="mx-1" v-if="label">{{ label }}</span>
            </slot>
        </label>`
});
