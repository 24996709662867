'use strict';
var app = angular.module('postCenterWebClientApp');

app.controller('WhatsappRequestCodeCtrl', function(
  $scope, $uibModalInstance, $filter, AdNotification,
  SocialAccount, whatsapp, whatsappState
) {

  $scope.data = whatsapp;
  $scope.state = whatsappState;

  function whatsappDataNormalize(whatsappData) {
    var data = angular.copy(whatsappData);
    data.cc = data.cc && data.cc.value ? data.cc.value : data.cc;
    data.mnc = data.mnc && data.mnc.value ? data.mnc.value : data.mnc;
    return data;
  }

  function whatsappRequestCode(method, callback) {

    var state = $scope.state;
    if (!state.pendingRequest) {

      state.pendingRequest = true;
      var data = whatsappDataNormalize($scope.data);
      var phone = data['cc'] + data['phone'];
      var connectAccount = SocialAccount.requestCode;

      $scope.data.voice = method === 'voice';
      $scope.data.sms = method === 'sms';
      $scope.state.method = method;

      connectAccount('whatsapp', phone, method).then(function(response) {
        typeof callback === 'function' && callback(false, response);
        state.pendingRequest = false;
      }, function(exception) {
        typeof callback === 'function' && callback(true, exception);
        state.pendingRequest = false;
      });

    }
  }

  function getModalCallbackParams() {
    return {
      whatsapp: $scope.data,
      whatsappState: $scope.state
    }
  }

  $scope.skipCodeRequest = function() {
    $uibModalInstance.close(getModalCallbackParams());
  }

  $scope.canRequestCode = function(method) {

    var canRequestCode = true;
    var state = $scope.state;
    var pendingRequest = state.pendingRequest;
    var tooRecent = state.method === method && state.reason === 'too_recent';

    if (pendingRequest || tooRecent) {
      canRequestCode = false;
    }

    return canRequestCode;

  }

  $scope.intentResultStatus = '';
  $scope.requestCode = function(method) {

    var state = $scope.state;

    function setState(stateData) {

      state.lastAttempt = stateData['registration_last_attempt'];
      state.method = stateData['registration_method'];
      state.result = stateData['registration_status'];
      state.retryAfter = stateData['retry_after'];
      state.reason = stateData['registration_reason'];

    }

    whatsappRequestCode(method, function(operationError, socialAccount) {

      if (!operationError) {
        $uibModalInstance.close(getModalCallbackParams());
      } else {
        var message = $filter('translate')('WHATSAPP_ERROR_REQUESTING_CODE');
        AdNotification.notify(message, 'error');
      }

    });

  };

  $scope.cancel = function () {
    $uibModalInstance.dismiss('cancel');
  };

});
