const CsatSurvey = Vue.component('CsatSurvey', {
  computed: {
    ...Vuex.mapGetters('epaConfig', ['getSurveyAlternatives']),
    survey() {
      return this.getSurveyAlternatives('csat');
    }
  },
  template: `<div class="survey-form">
    <CustomerQuestionForm
      survey-type="csat" />
    <EpaRanking :ranking-type="survey.ranking_type" class="mt-2" />
    <EpaAnswersTags
      v-if="survey.ranking_type === 'tags'"
      survey-type="csat"
      option-type="tags"
      :inverted="survey.inverted"
      class="w-50 pb-2"
    />
    <div class="invert-order-toggle">
      <strong>{{$t('invert_tag_order')}}:</strong>
      <ToggleSwitchModel
        class="invert-order-toggle"
        v-model="survey.inverted"
      />
    </div>
  </div>`,
  i18n: {
    messages: {
      es: {
        invert_tag_order: 'Invertir orden de las etiquetas'
      },
      pt: {
        invert_tag_order: 'Inverter ordem das etiquetas'
      },
      en: {
        invert_tag_order:'Invert tag order'
      }
    }
  }
});
const CesSurvey = Vue.component('CesSurvey', {
  template: `<div class="survey-form">
    <CustomerQuestionForm
      survey-type="ces"
    />
    <div class="d-flex">
      <EpaAnswersTags
        survey-type="ces" option-type="tags"
        class="w-50"
      />
      <div class="arrow d-flex flex-column align-center">
        <div class="arrow__info">
          <p>{{$t('arrow.difficulty')}}</p>
        </div>
        <div class="triangle-up"></div>
        <div class="body-line"></div>
        <div class="triangle-down"></div>
        <div class="arrow__info">
          <p>{{$t('arrow.ease')}}</p>
        </div>
      </div>
    </div>
  </div>`,
  i18n: {
    messages: {
      es: {
        arrow: {
          ease: 'Facilidad',
          difficulty: 'Dificultad'
        }
      },
      pt: {
        arrow: {
          ease: 'Facilidade',
          difficulty: 'Dificuldade'
        }
      },
      en: {
        arrow: {
          ease: 'Ease',
          difficulty: 'Difficulty'
        }
      }
    }
  }
});
const ChoiceSurvey = Vue.component('ChoiceSurvey', {
  props: {
    isResolution: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...Vuex.mapGetters('epaConfig', ['getSurveyAlternatives']),
    survey() {
      return this.getSurveyAlternatives(this.getType);
    },
    getType() {
      return this.isResolution ? 'res' : 'fcr';
    }
  },
  template: `<div class="survey-form">
    <CustomerQuestionForm :survey-type="getType" />
    <div class="d-flex flex-column">
      <EpaAnswersTags
        :survey-type="getType"
        option-type="choices"
        class="w-35"
      />
      <div v-if="isResolution"
        class="container-description d-flex flex-row space-between align-center"
        style="padding-left:5px;">
        <ToggleSwitchModel
          class="d-flex"
          v-model="survey.should_refer_to_agent"
        />
        <div class="description d-flex align-center">
          <p><strong>{{$tc('answers', 2)}}</strong></p>
        </div>        
      </div>
    </div>
  </div>`,
  i18n: {
    messages: {
      es: {
        answers:
          'Si el usuario responde afirmativamente la encuesta continúa | Si el usuario responde negativamente, el ticket regresa a la cola de atención'
      },
      pt: {
        answers:
          'Se o usuário responder afirmativamente, a pesquisa continua | Se o usuário responder negativamente, o tíquete retorna para a fila de atendimento'
      },
      en: {
        answers:
          'If the user answers affirmatively, the survey continues | If the user answers negatively, the ticket returns to the service queue'
      }
    }
  }
});
