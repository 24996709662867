function getGroups(user: ExtendedUser): object {
  const groups = [
    'tickets',
    'agents',
    'departments',
    'messages',
    'accounts',
    'clients'
  ]
  const customGroups = {
    activeGroup: 'tickets',
    groups
  }
  if (user.isSupervisor && !user.isAnalystOrAdmin) {
    customGroups.activeGroup = 'agents'
    customGroups.groups = groups.slice(1, 3)
  }
  return customGroups
}

/* global HistoryView */
(module => {
  AnalyticsHistoryCtrl.$inject = [
    '$rootScope',
    '$element',
    'Analytics',
    'AnalyticsService',
    'UserService',
    'UsersService',
    'EstablishmentService',
    'DepartmentService',
    'adExportToCsv',
    'FireTagService',
    'AuthService'
  ];

  function AnalyticsHistoryCtrl(
    $rootScope,
    $element,
    Analytics,
    AnalyticsService,
    UserService,
    UsersService,
    EstablishmentService: EstablishmentService,
    DepartmentService,
    adExportToCsv,
    FireTagService,
    AuthService: AuthService
  ) {
    FireTagService.setPageView({
      title: 'Historial - Beta',
      path: `/${window.location.hash}`
    });
    $rootScope.analyticTab.selected = 'history';

    const vm = this;
    const getI18n = (user: ExtendedUser) => new VueI18n({
      locale: user.profile.language,
      fallbackLocale: ['es', 'en', 'pt'],
      silentTranslationWarn: true
    })

    const defaultState = (establishment: Establishment, user: ExtendedUser) => {
      return {
        ...getGroups(user),
        ready: true,
        locale: user.profile.language || 'es',
        groupReady: false,
        agents: [],
        departments: [],
        channels: [],
        counters: [],
        charts: [],
        tabs: {},
        filters: {
          from: moment().startOf('day').unix(),
          to: moment().unix(),
          updateTime: new Date().getTime()
        },
        limits: establishment.plan.limits.find(
          limit => limit.limit_id === 'ANA'
        ),
        limitDate: '',
        limitDays: 0,
        user
      }
    }

    const getStore = (establishment: Establishment, user: ExtendedUser) => new Vuex.Store({
      state: defaultState(establishment, user),
      getters: {
        locale: state => state.locale,
        isReady: state => state.ready,
        groupReady: state => state.groupReady,
        filters: state => state.filters,
        getGroups: state => state.groups,
        getAgents: state => state.agents,
        getDepartments: state => state.departments,
        getCharts: state =>
          state.charts.sort((a, b) => a.serialized.index - b.serialized.index),
        getCounters: state => state.counters.sort((a, b) => a.index - b.index),
        getFilters: state => state.filters,
        getTabs: state => state.tabs,
        activeGroup: state => state.activeGroup,
        getTabGroup: state => group => state.tabs[group],
        getCountersByGroup: state => group =>
          state.counters.filter(
            counter => counter.serialized.extra_data.group === group
          ),
        getChartsByGroup: state => group =>
          state.charts.filter(
            chart => chart.serialized.extra_data.group === group
          ),
        getLimits: state => state.limits,
        getLimitDate: state => state.limitDate,
        getLimitDays: state => state.limitDays,
        user: state => state.user,
        userDepartments: state => state.user.profile.departments,
        isAnalystOrAdmin: state => state.user.isAnalystOrAdmin
      },
      mutations: {
        MUTATE(state, {property, value}) {
          state[property] = value;
        },
        MUTATE_OBJECT(state, {key, payload}) {
          state[key] = {...state[key], ...payload};
        },
        MUTATE_ARRAY(state, {name, payload}) {
          state[name] = [...state[name], payload];
        },
        UPDATE_LIST(state, {list, key, payload}) {
          const index = state[list].findIndex(
            value => value[key] === payload[key]
          );
          if (index !== -1) {
            state[list].splice(index, 1, payload);
          } else {
            state[list].push(payload);
          }
        },
        CLEAR_DATA(state) {
          state.charts = [];
          state.counters = [];
          state.ready = true;
        },
        UPDATE_CHARTS(state, payload) {
          const index = state.charts.findIndex(
            chart =>
              chart.serialized.identifier === payload.serialized.identifier
          );
          if (index !== -1) {
            state.charts.splice(index, 1, payload);
          } else {
            state.charts.push(payload);
          }
        }
      },
      actions: {
        savePreferences() {
          // saving user preferences on browser DB
        },
        setGroup({commit}, payload) {
          commit('MUTATE', {property: 'activeGroup', value: payload});
        },
        async getAgents({commit, state, getters}) {
          const {userDepartments} = getters;
          if (state.agents.length === 0) {
            await UsersService.getUsers().then(agents => {
              agents.push({
                first_name: 'PostCenter',
                id: null,
                last_name: 'Bot',
                is_bot: true,
                profile: {departments: userDepartments}
              });
              const filterAgents = agents
                .filter(agent => {
                  const {
                    profile: {departments}
                  } = agent;
                  if (
                    user.isSupervisor &&
                    userDepartments.length > 0 &&
                    !user.isAnalystOrAdmin
                  ) {
                    if (departments && departments.length > 0) {
                      return departments.find(dep => {
                        return userDepartments.includes(dep);
                      });
                    }
                  } else {
                    return agent;
                  }
                })
                .map(agent => {
                  const {
                    id,
                    is_bot: isBot,
                    first_name: firstName,
                    last_name: lastName
                  } = agent;
                  return {
                    name: `${firstName} ${lastName}`,
                    id,
                    lastName,
                    isBot,
                    active: true
                  };
                })
                .sort((sortA, sortB) => {
                  return sortA.lastName.toLowerCase() >
                    sortB.lastName.toLowerCase()
                    ? 1
                    : -1;
                });
              commit('MUTATE', {
                property: 'agents',
                value: filterAgents
              });
            });
          }
        },
        async getDepartments({commit, state, getters}) {
          const {userDepartments} = getters;
          if (state.departments.length === 0) {
            await DepartmentService.getDepartments().then(department => {
              department.push({
                name: 'no_departments',
                id: null
              });
              const filterDepartments = department
                .map(_department => {
                  const {id, name} = _department;
                  return {
                    name,
                    id,
                    active: true
                  };
                })
                .filter(_department => {
                  if (
                    user.isSupervisor &&
                    userDepartments.length > 0 &&
                    !user.isAnalystOrAdmin
                  ) {
                    return userDepartments.includes(_department.id);
                  }
                  return _department;
                })
                .sort((sortA, sortB) => {
                  return sortA.name.toLowerCase() > sortB.name.toLowerCase()
                    ? 1
                    : -1;
                });
              commit('MUTATE', {
                property: 'departments',
                value: filterDepartments
              });
            });
          }
        },
        async getUser() {
          return await UserService.getProfile();
        },
        clearData({commit}) {
          commit('CLEAR_DATA');
          return true;
        },
        async getKpiList({commit, state}, group) {
          const kpis = await AnalyticsService.getIndicators(group);
          const {list} = kpis;

          const kpiFiltered = list.map(kpi => {
            const updateKpi = {
              enabled: true,
              ...kpi
            };
            return updateKpi;
          });
          if (!state.tabs[state.activeGroup]) {
            commit('MUTATE_OBJECT', {
              key: 'tabs',
              payload: {[group]: kpiFiltered}
            });
          }
          commit('MUTATE', {
            property: 'groupReady',
            value: true
          });
          return kpiFiltered;
        },
        async getChart({state, dispatch}, {identifier = ''}) {
          const {
            filters: {from, to}
          } = state;
          const chart = await AnalyticsService.getChartData({
            identifier,
            from,
            to
          });
          const {
            serialized: {visualization_type: type}
          } = chart;
          if (type === 'counter') {
            dispatch('setCounters', chart);
          }
          if (type === 'table') {
            dispatch('setTable', chart);
          }
          if (type === 'lineal_time') {
            dispatch('setChart', chart);
          }
        },
        async getFile(_context, path) {
          return await AnalyticsService.getFileData(path);
        },
        setFilters({commit, dispatch, state}, filters = {}) {
          filters.updateTime = new Date().getTime();

          commit('MUTATE', {property: 'filters', value: filters});
          state.tabs[state.activeGroup].map(({identifier}) => {
            dispatch('getChart', {identifier});
          });
        },
        setCounters({commit}, chart) {
          const {
            serialized: {label, identifier, index},
            value_range: {result = '-'}
          } = chart;
          const newCounter = {label, identifier, result, index};
          commit('UPDATE_LIST', {
            list: 'counters',
            key: 'identifier',
            payload: newCounter
          });
        },
        setTable({commit}, chart) {
          const {
            serialized: {metrics},
            value_range: {result, success}
          } = chart;
          const getRows = () => {
            let metricsWithData = {};
            metrics.map((metric, index) => {
              const metricResult = result[index];
              metricResult.map(res => {
                const [name, id, value] = res;
                let isTotal = name === 'total' || name === 'average';
                const objID = isTotal ? 'totals' : !id ? -1 : id;
                if (user.isSupervisor && !user.isAnalystOrAdmin) {
                  isTotal = false;
                }
                const resultValues = {
                  0: isTotal ? '' : name || 'no_assignment',
                  [index + 1]: isTotal ? `${name}: ${Math.round(value)}` : value
                };
                metricsWithData[objID] = {
                  ...metricsWithData[objID],
                  ...resultValues
                };
              });
            });
            const keyUser = Object.getOwnPropertyNames(metricsWithData);
            metrics.map((_metric, index) => {
              keyUser.map(key => {
                const userObj = metricsWithData[key];
                if (!userObj[index + 1]) {
                  metricsWithData[key][index + 1] = '-';
                }
              });
            });
            return metricsWithData;
          };
          const getHeaders = () => {
            return metrics.map(metric => {
              const {
                extra_data: {tooltip}
              } = metric;
              return {label: metric.label, tooltip};
            });
          };
          const rows = success ? getRows() : [];
          const rowsToArray = Object.values(rows);
          const table = {
            headings: [{label: 'agents'}, ...getHeaders()],
            rows: rowsToArray
          };
          const payload = {
            serialized: chart.serialized,
            table,
            results: rowsToArray.length > 0
          };
          commit('UPDATE_CHARTS', payload);
        },
        setChart({commit}, chart) {
          const {
            serialized: {label, metrics},
            value_range: {result, query}
          } = chart;

          let sum = 0;
          if (result.length > 0) {
            result.forEach(res => {
              res.forEach((values, index, obj) => {
                values[0] = values[0] * 1000;
                sum = sum + values[1];
              });
            });
          }

          const getSeriesData = () => {
            return metrics.map((metric, index) => {
              const {label: name, type, yAxis} = metric;
              return {
                name,
                type,
                yAxis,
                data: result[index]
              };
            });
          };

          const [{granularity}] = query;
          const config = {
            type: 'line',
            label,
            granularity,
            yAxisTitle: '',
            xAxisTitle: '',
            yAxis1Title: ''
          };
          const payload = {
            serialized: chart.serialized,
            config,
            results: sum !== 0,
            series: getSeriesData()
          };
          commit('UPDATE_CHARTS', payload);
        },
        setLimitDate({state, commit}) {
          const {limits} = state;
          const {limit_max, duration} = limits;

          if (limit_max < 0 || !limits) {
            commit('MUTATE', {
              property: 'limitDate',
              value: new Date(2020, 7, 1)
            });
          } else if (duration === 'W' || duration === 'M') {
            const substract = duration === 'W' ? 'weeks' : 'months';
            commit('MUTATE', {
              property: 'limitDate',
              value: new Date(moment().subtract(limit_max, substract))
            });
          } else {
            commit('MUTATE', {
              property: 'limitDate',
              value: new Date(limit_max)
            });
          }

          const days = duration === 'W' ? 7 : 30;
          const limitDays = days * limit_max;
          commit('MUTATE', {property: 'limitDays', value: limitDays});
        },
        exportToCsv(_context, {title, data}) {
          return adExportToCsv.exportToCsv(title, data);
        }
      }
    });

    const initVueInstance = (establishment: Establishment, user: ExtendedUser) => {
      const i18n = getI18n(user)
      vm.vueInstance = new Vue({
        el: $element[0].querySelector('.ng-non-bindable'),
        components: {
          'history-view': HistoryView
        },
        store: getStore(establishment, user),
        i18n,
        data: {
          translateReady: false
        },
        computed: {...Vuex.mapGetters(['getTabList', 'activeGroup'])},
        created() {
          this.getKpiList(this.activeGroup);
          Analytics.getTranslations('history', i18n.locale).then(
            translations => {
              i18n.setLocaleMessage(i18n.locale, translations);
              this.translateReady = true;
            }
          );
        },
        methods: {
          ...Vuex.mapActions(['getKpiList']),
          getKpis() {
            // get all kpi list (not work cause endpoint not support simultaneous queries 🔥)
            const groups = async group => await this.getKpiList(group);
            Promise.all(this.getTabList.map(groups));
          }
        }
      });
    };
    EstablishmentService.getEstablishment().then(establishment => {
      UserService.getProfile().then(user => {
        initVueInstance(establishment, user)
      })
    })
  }

  module.controller('AnalyticsHistoryCtrl', AnalyticsHistoryCtrl);
})(angular.module('postCenterWebClientApp'));
