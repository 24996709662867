const KpiCard = Vue.component('KpiCard', {
  props: {
    title: {
      type: String,
      default: 'Kpi title'
    },
    modalTitle: {
      type: String,
      default: 'Kpi modal Title'
    },
    hasModal: {
      type: Boolean,
      default: false
    },
    uniqueKey: {
      type: String,
      default: 'uniqueKeyModal'
    }
  },
  template: `<div class="basic-card mb-2">
    <div class="d-flex space-between">
      <div class="d-flex">
        <h4>{{ title }}</h4>
        <Modal 
          v-if="hasModal"
          :title="modalTitle" 
          :uniqueKey='uniqueKey'>
          <slot name="modal_body"/>
        </Modal>
      </div>
      <div class="filters m-1 d-flex">
        <slot name="filters"/>
      </div>
    </div>
    <slot />
  </div>`
});
