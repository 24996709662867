'use strict';

var app = angular.module('postCenterWebClientApp');
app.component('adAgendaEmailInput', {
  bindings: {
    selectedContacts: '=ngModel',
    placeholder: '@',
    contactList: '<?',
    disabled: '=ngDisabled'
  },
  controller: adAgendaEmailInputCtrl,
  templateUrl: 'blocks/global/views/ad_agenda_email_input.html'
});

adAgendaEmailInputCtrl.$inject = ['$rootScope', 'fuzzySearch'];

function adAgendaEmailInputCtrl($rootScope, fuzzySearch) {
  var self = this;

  self.readContactList = function(query) {
    var contactList = self.contactList || $rootScope.notificationEntitySearch;
    return _.filter(contactList, function(element) {
      return fuzzySearch.find(query, element.text);
    });
  };

  self.onTagAdding = function(tag) {
    if ('kind' in tag) {
      return true;
    } else {
      return validateEmail(tag.text);
    }
  };

  function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }
}
