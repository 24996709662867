const HistoryChartItem = Vue.component('history-chart-item', {
  props: {
    chart: {
      type: Object,
      required: true
    },
    chartType: {
      type: String,
      required: true
    },
    expandedChart: Boolean,
    expandedTable: Boolean,
    toggleExpanded: {
      type: Function,
      default: null
    },
    isOpen: {
      type: Function,
      default: null
    },
    opened: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      scroll: 0,
      cardHeight: 175,
      table: {},
      chartExpand: false,
      timeTransformed: '',
      loading: false
    };
  },
  computed: {
    ...Vuex.mapGetters([
      'filters',
      'getKpiTitle',
      'getAgents',
      'getDepartments',
      'getChannels',
      'activeGroup',
      'isAnalystOrAdmin'
    ]),
    hasResults() {
      return this.chart.results;
    },
    filterTable() {
      if (this.table?.rows) {
        if (
          this.activeGroup === 'agents' ||
          this.activeGroup === 'departments'
        ) {
          return this.table?.rows.filter(row => {
            const {[0]: nameRow} = row;
            if (nameRow === 'no_assignment') {
              row[0] = this.$t(
                `no_${
                  this.activeGroup === 'agents'
                    ? 'assignment'
                    : this.activeGroup
                }`
              );
              if (this.setTableByFilter(row[0], this.activeGroup)) {
                return row;
              }
            } else if (nameRow === '') {
              Object.values(row).map((val, index) => {
                if (val) {
                  const [name, value] = val.split(':');
                  row[index] = this.$t(name, {total: value});
                }
              });
              return row;
            } else if (this.setTableByFilter(nameRow, this.activeGroup)) {
              return row;
            }
          });
        } else {
          return this.table.rows;
        }
      }
      return [];
    },
    getTables() {
      const {table} = this.chart;

      table.headings[0].label = this.$t(
        `tabs.${this.activeGroup === 'clients' ? 'accounts' : this.activeGroup}`
      );
      this.setTable({table});
      return table;
    },
    exports() {
      return this.chart.serialized.extra_data.exports !== undefined;
    },
    tooltip() {
      const {
        extra_data: {tooltip}
      } = this.chart.serialized;
      return tooltip;
    }
  },
  methods: {
    ...Vuex.mapActions(['getFile', 'exportToCsv']),
    setTable({table}) {
      this.table = table;
    },
    setTableByFilter(nameRow, group) {
      const getGroup =
        group === 'departments'
          ? this.getDepartments
          : group === 'agents'
          ? this.getAgents
          : this.getChannels;
      return getGroup.find(({name, active}) => {
        if (nameRow === this.$t(name)) {
          if (active) {
            return nameRow;
          }
        }
      });
    },
    getTitleIdentifier(identifier) {
      const translate = this.getKpiTitle[identifier];
      return this.$t(translate);
    },
    exportData() {
      if (this.exports) {
        this.loading = true;
        const [{type, url}] = this.chart.serialized.extra_data.exports;
        const {pathname, search} = new URL(url);
        const file = this.chart.serialized.label
          .split(' ')
          .join('_')
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '');
        const date = moment().format('-DD_MM_YYYY_HH[h]mm[m]');
        const fileName = `${file}${date}.${type}`;

        this.getFile(`${pathname}${search}`)
          .then(response => {
            const blob = new Blob([response], {type});
            const link = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = link;
            anchor.download = fileName;
            document.body.appendChild(anchor);
            anchor.click();
            anchor.remove();
          })
          .finally(() => (this.loading = false));
      }
    },
    expandTable() {
      const {
        serialized: {identifier}
      } = this.chart;
      this.toggleExpanded(identifier, 'table');
      // add style to main content to block scroll
      const sticky = document.getElementsByTagName('sticky-scroll');
      const toggleClass = toggle => {
        Array.from(sticky).forEach(elem => {
          if (toggle === 'add') {
            elem.classList.add('block-scroll');
          } else {
            elem.classList.remove('block-scroll');
          }
        });
      };
      if (!this.expandedTable) {
        this.scroll = sticky[0].scrollTop;
        sticky[0].scrollTo({top: 0, behavior: 'smooth'});
        toggleClass('add');
        this.cardHeight = sticky[0].offsetHeight - 100;
      } else {
        sticky[0].scrollTo({top: this.scroll, behavior: 'smooth'});
        toggleClass('remove');
        this.cardHeight = 175;
      }
    },
    expandChart() {
      const {
        serialized: {identifier}
      } = this.chart;
      this.toggleExpanded(identifier, 'chart');
      this.isOpen(identifier);
      this.chartExpand = this.opened.includes(identifier);
    },
    xlsxExport() {
      const {
        serialized: {label: title}
      } = this.chart;
      const data = [];
      const {headings} = this.getTables;

      this.filterTable.map(row => {
        const rowArray = Object.values(row);
        const newRow = rowArray.map((_row, _index) => {
          const {label} = headings[_index];
          return [label, _row];
        });
        data.push(Object.fromEntries(newRow));
      });
      this.exportToCsv({title, data});
    }
  },
  template: `
        <div class="history--kpi-chart">
          <div class="d-flex space-between kpi-chart__header">
            <h4>
              <KpiTooltip :tooltip="chart.serialized.extra_data" v-if="tooltip" />
              {{ chart.serialized.label }}
              <small class="ml-1">{{chart.serialized.extra_data.subtitle}}</small>
            </h4>
            <div class="chart-menu">
              <button
                  v-if="chartType === 'table'"
                  @click="xlsxExport()"
                  :content="$t('tooltips.exportXlsx')"
                  v-tippy="{
                    arrow: true,
                    interactive: true
                  }"
                  class="btn ads-button ads-button--link"
                  :disabled="!hasResults"
                >
                  <span class="fa fa-file-excel"></span>
              </button>
              <div class="bottom text-center">
                <Loader v-if="loading" size="1x" />
                <button
                  v-if="exports && !loading && isAnalystOrAdmin"
                  @click="exportData()"
                  :content="$t('tooltips.exportData')"
                  v-tippy="{
                    arrow: true,
                    interactive: true
                  }"
                  class="btn ads-button ads-button--link"
                  :disabled="!hasResults"
                >
                  <span class="fa fa-cloud-download"></span>
                </button>
              </div>
              <button
                type="button"
                class="btn ads-button ads-button--link"
                :content="$t('tooltips.expandTable')"
                  v-tippy="{
                    arrow: true,
                    interactive: true
                  }"
                @click="expandTable()"
                v-if="chartType === 'table' && hasResults">
                  <span v-if="!expandedTable" class="fa fa-expand-fas">
                  </span>
                  <div v-else><span class="fa fa-arrow-left"></span>
                  {{ $t('back') }}
                  </div>
              </button>
              <button
                type="button"
                class="btn ads-button ads-button--link"
                @click="expandChart()"
                v-if="chartType === 'lineal_time'">
                  <div v-if="!chartExpand">
                    <span class="fa fa-expand-fas"></span>
                  </div>
                  <div v-else>
                    <span class="fa fa-compress-fas"></span>
                  </div>
              </button>
            </div>
          </div>
          <div class="kpi-chart__content">
            <AderesoChart
              v-if="chartType !== 'table'"
              :chart-data="chart"
              :height="cardHeight"
              :resize="expandedChart"
            />
            <BasicTable
              v-if="chartType === 'table'"
              v-show="hasResults"
              :headings="getTables.headings"
              :rows="filterTable"
              :noResults="$t('without_results')"
              :height="cardHeight"
            />
            <without-results
              :title="$t('without_results')"
              v-if="chartType === 'table' && !hasResults" />
          </div>
        </div>
      `
});
const KpiTooltip = Vue.component('KpiTooltip', {
  props: {
    tooltip: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      config: {
        animateFill: false,
        arrow: true,
        placement: 'right',
        interactive: !!this.tooltip.link
      }
    };
  },
  computed: {
    disabled() {
      return {outline: !this.tooltip.link ? 'none' : 'inherit'};
    }
  },
  template: `
    <a
      :href="tooltip.link"
      target="_blank"
      :style="disabled"
      v-tippy="config"
      :title="tooltip.tooltip">
      <i class="fa fa-question-circle-fas tooltip-icon"></i>
    </a>`
});
