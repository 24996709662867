(module => {
  ContactAgendaService.$inject = [
    'AdRequest',
    'AdNotification',
    'AdAvailableSn',
    'snErrorMessage',
    '$rootScope',
    '$filter',
    'UserService'
  ];

  function ContactAgendaService(
    AdRequest,
    AdNotification,
    AdAvailableSn,
    snErrorMessage,
    $rootScope,
    $filter,
    UserService: UserService
  ) {
    const baseURL = '/api/v1';
    const action = 'contact_agenda';
    const self = this

    UserService.getProfile().then(user => {
      self.user = user
    })

    const notify = {
      success: message => AdNotification.success(message, action),
      info: message => AdNotification.info(message, action),
      error: (message, error) => {
        AdNotification.error(message, action);
        throw new Error(error);
      }
    };

    const buildQueryString = (expressions = {}) => {
      return Object.entries(expressions)
        .map(expression => {
          const [key, value] = expression;
          return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
        })
        .join('&');
    };

    const defaultColumns = [
      {field: 'favorite', label: null, component: 'favorite-heart', required: true, enabled: true, index: -99},
      {field: 'name', label: null, component: 'span', index: -98, required: true, enabled: true},
      {field: 'uname', label: null, component: 'span', index: -97},
      {field: 'email', component: 'span', index: -96},
      {field: 'sn', label: null, component: 'span', index: -95},
      {field: 'influencer', label: null, component: 'span', index: -94, type: 'boolean'},
      {field: 'contactedAccounts', label: null, component: 'AvatarList', index: -93},
      {field: 'contact', label: null, component: 'contact-button', required: true, enabled: true, index: 999}
    ];

    const sortColumns = columns => {
      return columns.sort((prev, next) => prev.index - next.index);
    };

    const prettifyContact = (contacts = []) => {
      const genders = {1: 'F', 2: 'M', 0: ''};
      return contacts.map(contact => ({
        ...contact,
        gender: genders[contact.gender],
        sn: AdAvailableSn.getTitle(contact.sn),
        favorite: Boolean(contact.favorite)
      }));
    };

    return {
      async getColumns() {
        try {
          const {
            selected_columns,
            selected_columns_labels: labels,
            selected_columns_details: details
          } = await AdRequest.get(`${baseURL}/contact_book/profile_config/`);

          const response = Object.entries(selected_columns).map(entry => {
            const [key, enabled] = entry;
            const value = details[key] || {};
            const isAlreadyDefined =
              defaultColumns.find(col => col.field === key) || {};
            return {
              ...value,
              field: key,
              component: 'span',
              enabled: Boolean(enabled),
              required: false,
              label: $filter('translate')(labels[key]),
              index: defaultColumns.length + value.index,
              type: value.type || 'text',
              ...isAlreadyDefined
            };
          });

          return sortColumns(response);
        } catch (error) {
          notify.error(500, error);
          return [];
        }
      },
      async getContacts(filters = {}) {
        if (filters.expression === '') {
          delete filters.expression;
        }
        const queryString = buildQueryString(filters);
        try {
          const response = await AdRequest.get(
            `${baseURL}/contact_book/search/?${queryString}`
          );
          const data = prettifyContact(response.user_list);
          return {...response, data, queryString};
        } catch (error) {
          notify.error(500, error);
          return [];
        }
      },
      async toggleFavorite(id) {
        try {
          await AdRequest.post(`${baseURL}/contact_book/toggle_favorite/`, {
            id
          });
          notify.success(200);
        } catch (error) {
          notify.error(500, error);
        }
      },
      async getTicketsById(id = '') {
        try {
          const response = await AdRequest.get(
            `${baseURL}/contact_book/author/${id}/cases/`
          );

          return response.cases_list;
        } catch (error) {
          notify.error(500, error);
          return [];
        }
      },
      async addNote(note = {}) {
        try {
          await AdRequest.post(`${baseURL}/contact_book/notes/`, note);
          notify.success(200);
          return true;
        } catch (error) {
          notify.error(500, error);
          return false;
        }
      },
      async editNote(note = {}) {
        try {
          await AdRequest.put(
            `${baseURL}/contact_book/notes/${note.note_id}/`,
            note
          );
          notify.success('note_updated');
          return true;
        } catch (error) {
          notify.error(500, error);
          return false;
        }
      },
      async deleteNote(noteId = '') {
        try {
          await AdRequest.delete(`${baseURL}/contact_book/notes/${noteId}/`);
          notify.success('note_deleted');
          return true;
        } catch (error) {
          notify.error(500, error);
          return false;
        }
      },
      async getNotesById(contact_id = '', page = 1) {
        try {
          const response = await AdRequest.get(
            `${baseURL}/contact_book/notes/?contact_id=${contact_id}&page=${page}`
          );
          return response.notes_list;
        } catch (error) {
          notify.error(500, error);
          return [];
        }
      },
      async editColumns(columns = []) {
        // Transform column array into column dictionary
        // [{column: true}] -> {column: 1}
        const newColumns = columns.reduce(
          (dictionary, {field, enabled}) => ({
            ...dictionary,
            [field]: Number(enabled || false)
          }),
          {}
        );

        return AdRequest.post(`${baseURL}/contact_book/profile_config/`, {
          selected_columns: newColumns
        });
      },
      async getTranslations(locale = 'es') {
        const response = await fetch(
          './languages/sections/contact_agenda.json'
        );
        const translations = await response.json();
        return translations[locale];
      },
      async getSingleContact(id = '') {
        try {
          const accounts = await AdRequest.get(
            `${baseURL}/contact_book/author/${id}/`
          );

          return accounts;
        } catch (error) {
          notify.error(500, error);
          return {};
        }
      },
      async getMessagingInfo(id = '') {
        try {
          const {info} = await AdRequest.get(
            `${baseURL}/messages/proactive/?id=${id}`
          );

          if (!info) {
            throw new Error(204);
          }

          return info;
        } catch (error) {
          if (error.message === '204') {
            return [];
          }

          notify.error(500, error);
        }
      },
      parseContactForm(form = {}, sn = '') {
        let payload = {};

        switch (sn) {
          case 'whatsapp':
            payload = {
              content: form.message.key,
              hsm_parameters: form.hsm_parameters,
              account: {id: form.from.id, sn},
              user: {
                phone_number: form.to,
                name: form.name
              }
            };
            break;
          default:
            payload = {
              reply_to_msg: 'proactive',
              subject: form.subject,
              raw_text: form.message,
              text: form.message,
              sending_to_users: [form.to],
              social_accounts: [{uid: form.from.uid, sn}]
            };
        }

        payload.sn = sn;
        return payload;
      },
      handleMessagingError(error = {}, sn = '') {
        if (error.status !== 403) {
          notify.error(500, error);
          return;
        }

        if (sn === 'twitter') {
          const {response: {detail, code} = {}} = error;
          notify.error(`${detail}_${code}`, error);
          return;
        }
        notify.error(403, error);
      },
      async sendContactForm(form) {
        const {sn} = form.from;
        const data = this.parseContactForm(form, sn);

        try {
          const response = await AdRequest.post(
            `${baseURL}/messages/proactive/`,
            data
          );
          notify.success(201);
          return response;
        } catch (error) {
          const {response} = error;
          if (
            response && typeof response === 'object' && (
              sn === 'facebook' || sn === 'twitter' || sn === 'instagram'
            )
          ){
            const {detail} = error.response
            const {sn: respSn, code, subcode} = detail;
            const {language: locale} = self.user.profile;
            const errorMessages = snErrorMessage.getMessage({
              sn: respSn,
              code,
              subcode,
              locale
            });
            AdNotification.notify(errorMessages, 'error');
          } else {
            this.handleMessagingError(error, sn);
          }
        }
      },
      async editContact(contact) {
        try {
          const response = await AdRequest.put(
            `${baseURL}/author/${contact._id}/`,
            contact
          );

          notify.success(200);
          return response;
        } catch (error) {
          notify.error(500, error);
          return false;
        }
      }
    };
  }

  module.service('ContactAgendaService', ContactAgendaService);
})(angular.module('postCenterWebClientApp'));
