const EpaFourStep = Vue.component('EpaFourStep', {
  computed: {
    ...Vuex.mapGetters('epaConfig', ['editingSurvey'])
  },
  template: `<div>
      <div class="my-2">
        <strong>{{ $t('goodbye.title') }}</strong>
        <InputText
          class="w-70 mt-1"
          v-model="editingSurvey.goodbye_message"
        />
      </div>
  </div>`,
  i18n: {
    messages: {
      es: {
        goodbye: {
          title: 'Mensaje despedida'
        }
      },
      en: {
        goodbye: {
          title: 'Farewell message'
        }
      },
      pt: {
        goodbye: {
          title: 'Mensagem de despedida'
        }
      }
    }
  }
});
