'use strict';

angular
  .module('postCenterWebClientApp')
  .controller('AssignmentTableCtrl', AssignmentTableCtrl);

function AssignmentTableCtrl(
  $scope,
  $rootScope,
  AdNotification,
  EstablishmentService,
  AssignmentService,
  $filter,
  DepartmentService: DepartmentService,
  SocialAccountService: SocialAccountService,
  $timeout,
  AdAvailableSn,
  UsersService: UsersService,
  UserService: UserService,
  UserFilter,
  ColumnsService,
  FireTagService
) {
  const vm = this;
  const translate = value => $filter('translate')(value);
  
  Promise.all([
    UserService.getProfile(),
    SocialAccountService.getSocialAccounts(),
    UsersService.getCms(),
    DepartmentService.getAllDepartments()
  ]).then(([user, accounts, communityManagers, departmentsList]) => {
    const {
      isSupervisorNotAdmin,
      profile: {departments}
    } = user
    $scope.isSupervisorNotAdmin = isSupervisorNotAdmin
    $scope.userDepartments = departments
    $scope.accounts = accounts
    $scope.accounts.forEach(
      account => (socialAccounts[account.id] = account)
    )
    vm.communityManagers = communityManagers
    $scope.communityManagers = communityManagers

    const defaultDeparmentOptions = [
      // Departments for filters
      {id: 0, name: translate('GENERAL_ALL_DEPARTMENTS')},
      {id: -1, name: translate('GENERAL_NO_DEPARTMENTS')}
    ]

    const supervisorTeams = () => {
      if ($scope.userDepartments.length && $scope.isSupervisorNotAdmin) {
        return departmentsList.filter(({id}) =>
          $scope.userDepartments.includes(id)
        )
      }
      return departmentsList
    }
    $scope.departmentOptions = [...defaultDeparmentOptions, ...supervisorTeams()]

    getExecutives().then(() => {
      vm.refreshHistory();
    });
    createConfigurations();
    getAssignmentSummary();
    resetFilters();
  })

  vm.$onDestroy = () => {
    vm.paginationListener();
  };
  $rootScope.adminTab.selected = 'assignments';

  $scope.openConfig = false;
  $scope.openHelp = false;
  $scope.establishment = {...$rootScope.establishment};
  $scope.enableAssignmentQueueing =
    $scope.establishment.config.enable_queued_assignment;
  $scope.loadingCms = true;
  $scope.showTicketQueue = false;

  $scope.details = {template: null};
  $scope.assignableUsers = 0;
  $scope.users = [];

  $scope.filteredUsers = [];
  $scope.filteredSn = [];
  $scope.accountsOptions = [
    // Social accounts for filters
    {id: 'all', name: translate('GENERAL_ALL_SN')}
  ];
  $scope.assignationModeOptions = [
    {id: 0, name: translate('ESTABLISHMENT_MODE.NOT_CONSIDER_ASSIGNMENT')},
    {
      id: 1,
      name: translate('ESTABLISHMENT_MODE.ESTABLISHMENT_DEFAULT_SETTING')
    },
    {id: 2, name: translate('ESTABLISHMENT_MODE.FIFO_ASSIGNMENT')},
    {id: 3, name: translate('ESTABLISHMENT_MODE.FIFO_ASSIGNMENT_EXCLUSIVE')},
    {id: 4, name: translate('ESTABLISHMENT_MODE.LIFO_ASSIGNMENT')},
    {id: 5, name: translate('ESTABLISHMENT_MODE.LIFO_ASSIGNMENT_EXCLUSIVE')}
  ];
  $scope.assignOptions = [
    {id: 0, name: translate('ASSIGNMENTS_ASSIGNATION_MODE_NO')},
    {id: 1, name: translate('ASSIGNMENTS_ASSIGNATION_MODE_MANUAL')},
    {id: 2, name: translate('ASSIGNMENTS_ASSIGNATION_MODE_AUTO')}
  ];

  $scope.activityLog = [];
  $scope.pagination = {pageNumber: 1};
  $scope.assignmentSummary = null;
  $scope.maxQueuedAssignments =
    $scope.establishment.config.assignation_workload_default || 0;

  // Assignation History
  let users = [];
  let socialAccounts = [];

  $scope.ticketQueue = ColumnsService.getPendingTicketsColumn();

  const toggleTicketQueue = () => {
    $scope.showTicketQueue = !$scope.showTicketQueue;
  };

  $scope.viewDetails = (template = 'wrapper', data = {}, executives = []) => {
    const newDetails = {template, executives, [template]: {...data}};

    $scope.showTicketQueue = false;
    $scope.details = newDetails;
  };

  if ($scope.isSupervisorNotAdmin) {
    const generalNoDepartments = -1;
    $scope.departmentOptions = $scope.departmentOptions.filter(
      ({id}) => id !== generalNoDepartments
    );
  }

  function resetFilters() {
    $scope.filters = {
      username: '',
      assignation_mode: $scope.assignationModeOptions[0],
      account: $scope.accountsOptions[0],
      department: $scope.departmentOptions[0]
    };
  }

  function updateAssignableUsers() {
    $scope.assignableUsers = 0;
    $scope.filteredUsers.forEach(user => {
      if (user.isAssignable) {
        $scope.assignableUsers++;
      }
    });
  }

  $scope.filterUsers = function (filters) {
    const {username, assignation_mode, department} = filters;

    const filteredIDs = UserFilter.getFilteredUsers(
      username,
      assignation_mode,
      department
    ).map(user => user.id);
    const filteredUsers = $scope.users.filter(user =>
      filteredIDs.includes(user.cmid)
    );

    $scope.filteredUsers = filteredUsers;
  };

  $scope.areAllUsersAssignable = function () {
    return $scope.filteredUsers.every(user => user.isAssignable);
  };

  $scope.toggleAllUsers = function () {
    const assignable = !$scope.areAllUsersAssignable();
    $scope.filteredUsers.forEach(user => (user.isAssignable = assignable));
    $scope.save();
  };

  $scope.colorSelector = function (option) {
    if (option.id === 0) {
      return 'select-no';
    } else if (option.id === 1) {
      return 'select-manual';
    }
    return 'select-auto';
  };

  $scope.save = function () {
    const action = 'edit_user_account_assignment';
    const userData = $scope.users.map(user =>
      AssignmentService.prepareExecutiveData(user)
    );

    AssignmentService.saveAssignment(userData)
      .then(() => {
        AdNotification.success(201, action);
      })
      .catch(exception => {
        AdNotification.error(exception, action);
      })
      .finally(() => vm.refreshHistory());
  };

  $scope.goToAssignmentModeConfig = function () {
    if ($scope.isSupervisorNotAdmin) return;

    $scope.openConfig = true;
    $timeout(function () {
      const configElement = document.querySelector('.right-content');
      configElement.scrollIntoView({smooth: true});
    }, 1);
  };

  function User(dataObj, accountDataObjs) {
    let self = this;
    const defaultAvatar = 'https://assets.postcenter.io/img/generic_avatar.png';
    // instance vars
    self.cmid = dataObj.cmid;
    self.isAssignable = dataObj.is_assignable;
    self.name = dataObj.name;
    self.assignations = dataObj.assignations;
    self.unansweredCount = dataObj.unanswered_count;
    self.lastAssignation = dataObj.last_assignation;
    self.accounts = [];
    self.activeAccounts = 0;
    self.avatar = dataObj.avatar || defaultAvatar;
    self.assignation_workload = dataObj.assignation_workload || {};
    self.assignation_limit = dataObj.assignation_limit || {};
    self.assignation_config = dataObj.assignation_config;

    self.accounts = accountDataObjs.map(accountDataObj => {
      if (accountDataObj.active) {
        self.activeAccounts++;
      }
      return new Account(self, accountDataObj);
    });

    self.activeAccounts = self.accounts.reduce(
      (total, account) => total + (account.isActive ? 1 : 0),
      0
    );

    if (self.lastAssignation === '0001-01-01T00:00:00Z') {
      self.lastAssignation = null;
    }
  }

  function Account(user, data) {
    let self = this;
    const initialMode = $scope.assignOptions[getAssignMode(user, data)];

    // instance vars
    self.id = data.id;
    self.config = data.config;
    self.avatar = data.avatar;
    self.name = data.name;
    self.uname = data.uname;
    self.sn = data.sn;
    self.uid = data.uid;
    self.username = data.username;
    self.mode = initialMode;
    self.isActive = self.mode.id > 0;
  }

  function updateForceOnlyOnline() {
    if ($scope.establishment.config.enable_queued_assignment === true) {
      $scope.establishment.config.force_only_online = true;
    }
  }

  function createConfigurations() {
    const {config: establishmentConfig} = $scope.establishment;
    $scope.configurationBlocks = [];
    $scope.assignmentMode = 'assignmentMode';
    assignmentEstablishment(establishmentConfig);
    const assignmentModes = {
      fifo: '1',
      lifo: '2'
    };

    let workloadActive = {
      selected: 'based_on_workload'
    };
    if ($scope.establishment.config.based_only_online) {
      workloadActive.selected = 'based_only_online';
    }
    let assignmentMode = {
      selected: establishmentConfig.assignment_config?.fifo
        ? assignmentModes.fifo
        : assignmentModes.lifo
    };
    if ($scope.establishment.config.assignation_config) {
      assignmentMode.selected = $scope.establishment.config.assignation_config;
    }

    let assignOptionsBlock = {
      title: translate('ASSIGNATION_OPTIONS'),
      domId: 'assignation-options',
      configurations: []
    };
    let assignModesBlock = {
      title: translate('ASSIGNATION_MODES'),
      domId: 'assignation-modes',
      configurations: []
    };
    let queuedAssignmentBlock = {
      title: translate('ASSIGNATION_QUEUE'),
      domId: 'assignation-ceiling',
      configurations: []
    };
    const metricsOptionsBlock = {
      title: translate('ASSIGNATION_METRICS_OPTIONS'),
      domId: 'assignation-metrics',
      configurations: []
    };

    assignOptionsBlock.configurations.push({
      key: 'based_on_previous',
      label: translate('ASSIGNATION_BASED_ON_PREVIOUS'),
      help: translate('ASSIGNATION_BASED_ON_PREVIOUS_HELP'),
      disclaimer: $filter('translate')(
        'ASSIGNATION_BASED_ON_PREVIOUS_DISCLAIMER'
      ),
      value: $scope.establishment.config.based_on_previous,
      editable: !$scope.establishment.config.enable_queued_assignment,
      kind: 'boolean',
      shouldHide: () => {
        return $scope.establishment.config.enable_queued_assignment;
      }
    });

    assignOptionsBlock.configurations.push({
      key: 'keep_manually_assigned',
      label: translate('ASSIGNATION_KEEP_MANUALLY_ASSIGNED'),
      help: translate('ASSIGNATION_KEEP_MANUALLY_ASSIGNED_HELP'),
      value: $scope.establishment.config.keep_manually_assigned,
      editable: true,
      kind: 'boolean'
    });

    assignOptionsBlock.configurations.push({
      key: 'force_only_online',
      label: translate('ASSIGNATION_FORCE_ONLY_ONLINE'),
      help: translate('ASSIGNATION_FORCE_ONLY_ONLINE_HELP'),
      value: !$scope.establishment.config.force_only_online,
      editable: true,
      kind: 'boolean',
      inverse: true,
      shouldHide: () => {
        return $scope.establishment.config.enable_queued_assignment;
      }
    });

    queuedAssignmentBlock.configurations.push({
      key: 'enable_queued_assignment',
      label: translate('ASSIGNATION_QUEUE_TITLE'),
      help: translate('ASSIGNATION_QUEUE_HELP'),
      disclaimer: $filter('translate')('ASSIGNATION_QUEUE_DISCLAIMER'),
      value: $scope.establishment.config.enable_queued_assignment,
      editable: true,
      kind: 'boolean'
    });

    queuedAssignmentBlock.configurations.push({
      key: 'assignation_workload_default',
      label: translate('ASSIGNATION_QUEUE_MAX_OPEN'),
      help: translate('ASSIGNATION_QUEUE_HELP_MAX_OPEN'),
      value: $scope.establishment.config.assignation_workload_default || 0,
      editable: true,
      kind: 'queue_inputs',
      onChange: (maxAmount = 0) => {
        $scope.maxQueuedAssignments = maxAmount;
      },
      onBlur: () => {
        $scope.saveConfigurations();
      }
    });

    queuedAssignmentBlock.configurations.push({
      key: 'assignation_unanswered_default',
      label: translate('ASSIGNATION_QUEUE_MAX_UNANSWERED'),
      help: translate('ASSIGNATION_QUEUE_HELP_MAX_UNANSWERED'),
      value: $scope.establishment.config.assignation_unanswered_default || 0,
      editable: true,
      kind: 'queue_inputs',
      onChange: () => {},
      onBlur: () => {
        $scope.saveConfigurations();
      }
    });

    queuedAssignmentBlock.configurations.push({
      key: 'assignation_mode',
      label: translate('ASSIGNMENTS_MODE_TITLE'),
      help: translate('ASSIGNMENTS_MODE_HELP'),
      helpLink: [
        'https://help.adere.so/administrador/topes-de-asignaci-n#m_todos_de_asignaci_n',
        translate('ASSIGNMENTS_MODE_HELP_LINK')
      ],
      value: $scope.establishment.config.assignation_mode,
      editable: true,
      kind: 'radio',
      group: $scope.assignmentMode,
      options: [
        {
          key: assignmentModes.fifo,
          name: 'fifoAssignment',
          label: translate('ASSIGNMENTS_MODE_FIFO_HELP'),
          radio_value: assignmentMode
        },
        {
          key: assignmentModes.lifo,
          name: 'lifoAssignment',
          label: translate('ASSIGNMENTS_MODE_LIFO_HELP'),
          radio_value: assignmentMode
        }
      ],
      shouldHide: () => {
        return !$scope.establishment.config.enable_queued_assignment;
      }
    });

    queuedAssignmentBlock.configurations.push({
      key: 'assignation_free_tickets_on_disconnect',
      label: translate('ASSIGNATION_FREE_TICKETS_ON_DISCONNECT'),
      help: translate('ASSIGNATION_FREE_TICKETS_ON_DISCONNECT_HELP'),
      value: $scope.establishment.config.assignation_free_tickets_on_disconnect,
      editable: true,
      kind: 'boolean'
    });

    assignModesBlock.configurations.push({
      key: 'based_only_online',
      label: translate('ASSIGNATION_BASED_ONLY_ONLINE'),
      help: translate('ASSIGNATION_BASED_ONLY_ONLINE_HELP'),
      value: $scope.establishment.config.based_only_online,
      editable: true,
      kind: 'radio',
      group: 'workload',
      radio_value: workloadActive
    });

    assignModesBlock.configurations.push({
      key: 'based_on_workload',
      label: translate('ASSIGNATION_BASED_ON_WORKLOAD'),
      help: translate('ASSIGNATION_BASED_ON_WORKLOAD_HELP'),
      value: !$scope.establishment.config.based_only_online,
      editable: true,
      kind: 'radio',
      group: 'workload',
      radio_value: workloadActive
    });

    metricsOptionsBlock.configurations.push({
      key: 'sla_desk_metrics',
      label: translate('ASSIGNATION_METRICS_OPTIONS_AGENT'),
      help: translate('ASSIGNATION_METRICS_OPTIONS_AGENT_HELP'),
      helpLink: [
        'https://help.adere.so/meson-de-ayuda/m-tricas-de-rendimiento-del-agente',
        translate('AGENT_METRICS_HELP')
      ],
      value: $scope.establishment.config.sla_desk_metrics,
      editable: true,
      kind: 'boolean'
    });

    $scope.configurationBlocks.push(assignModesBlock);
    $scope.configurationBlocks.push(assignOptionsBlock);

    $scope.configurationBlocks.push(queuedAssignmentBlock);
    if ($scope.establishment.features.agents_sla) {
      $scope.configurationBlocks.push(metricsOptionsBlock);
    }

    updateForceOnlyOnline();
  }

  function assignmentEstablishment({assignment_config}) {
    $scope.assignationEstablishment = null;
    if ($scope.establishment.config.enable_queued_assignment) {
      $scope.assignationEstablishment = assignment_config.fifo
        ? 'FIFO'
        : 'LIFO';
    }
  }

  $scope.getMaxQueuedAmount = (key = '') => {
    return key.includes('unanswered') ? $scope.maxQueuedAssignments : 100;
  };

  /**
   * Refreshes the result, emitted from AdAssignmentDetailWrapper component.
   * @param {String} category Is it an executive or an account? This will allow the component to be updated accordingly
   */
  $scope.saveDetails = function (category) {
    if (category === 'executive') {
      UserService.refreshCms().then(() => {
        UserService.getCms().then(cms => {
          vm.communityManagers = cms;
          $scope.communityManagers = cms;
        }).finally(() => {
          getExecutives();
          resetFilters();
        })
      })
    } else if (category === 'account') {
      refreshGlobalAccounts()
        // Recursively reload executives
        .then(() => $scope.saveDetails('executive'));
    } else if (category === 'reset') {
      $scope.viewDetails(null, null, null);
      return;
    }

    vm.refreshHistory();
  };
  $rootScope.$watch('assignmentOpenConfig', (newval, oldval) => {
    if (newval) {
      $scope.openConfig = newval;
    }
  });
  $scope.toggleConfig = () => {
    $scope.openConfig = !$scope.openConfig;
    $rootScope.assignmentOpenConfig = false;
  };

  $scope.toggleHelp = function () {
    $scope.openHelp = !$scope.openHelp;
  };

  $scope.selectConfig = function (parentIndex, index) {
    const group = $scope.configurationBlocks[parentIndex];
    const currentConfig = group.configurations[index];

    if (currentConfig.group) {
      group.configurations.forEach(config => {
        if (currentConfig.group === config.group) {
          config.value = false;
        }
      });
      currentConfig.value = true;
    } else {
      currentConfig.value = !currentConfig.value;
    }
    $scope.saveConfigurations();
  };

  $scope.selectConfigAssignment = function (optionSelected, config) {
    config.options.forEach(option => {
      if (option.name === optionSelected.name) {
        config.value = optionSelected.radio_value.selected;
      }
    });
    $scope.saveConfigurations();
  };

  $scope.filterEnabledAccounts = function (accounts) {
    return accounts.filter(account => account.mode.id > 0);
  };

  $scope.saveConfigurations = function () {
    let configurations = {};
    const action = 'edit_social_account_config';
    $scope.loading = true;

    function parseConfiguration(config) {
      if (config.editable) {
        configurations[config.key] = {};
        configurations[config.key].inverse = config.inverse;
        if (angular.isArray(config.value) || angular.isObject(config.value)) {
          var value = JSON.stringify(config.value);
          configurations[config.key].value = value;
          $scope.establishment.config[config.key] = value;
        } else {
          if (config.inverse) {
            configurations[config.key].value = !config.value;
            $scope.establishment.config[config.key] = !config.value;
          } else {
            configurations[config.key].value = config.value;
            $scope.establishment.config[config.key] = config.value;
          }
        }
        configurations[config.key].kind = config.kind;
        if (config.child !== undefined) {
          parseConfiguration(config.child);
        }
      }
    }
    updateForceOnlyOnline();

    $scope.configurationBlocks.forEach(block =>
      block.configurations.forEach(parseConfiguration)
    );

    EstablishmentService.editEstablishmentAssign(configurations)
      .then(establishment => {
        assignmentEstablishment(establishment.config);
        AdNotification.success(201, action);
        vm.refreshHistory();
      })
      .catch(error => {
        $scope.loading = false;
        AdNotification.error(error, action);
      });
  };

  function findUser(id, where) {
    where = where || vm.communityManagers;
    return _.findWhere(where, {
      cmid: id
    });
  }

  function filterHasAssignablesField(users) {
    return _.filter(users, function (user) {
      var userMetadata = findUser(user.cmid);
      var filterResult = false;

      if (userMetadata) {
        filterResult = angular.isDefined(userMetadata.is_assignable);
      }
      return filterResult;
    });
  }

  function getAssignMode(userDataObj, accountDataObj) {
    if (angular.isUndefined(userDataObj.assignations)) {
      return 0;
    }
    const assignMode = userDataObj.assignations.find(
      ({id}) => id === accountDataObj.id
    );
    if (!assignMode) {
      return 0;
    }
    return assignMode.mode;
  }

  async function getAssignmentSummary() {
    const translations = {
      assigned: translate('ASSIGNATION_QUEUE_ASSIGNED'),
      unassigned: translate('ASSIGNATION_QUEUE_UNASSIGNED'),
      unassigned_unanswered: translate(
        'ASSIGNATION_QUEUE_UNASSIGNED_UNANSWERED'
      ),
      unassigned_answered: translate('ASSIGNATION_QUEUE_NOT_UNANSWERED')
    };

    try {
      let summaries = [];
      let response = await AssignmentService.getAssignmentSummary();
      const unassigned_answered =
        response.unassigned - response.unassigned_unanswered;

      response = {...response, unassigned_answered};
      summaries = Object.keys(response).map(label => ({
        label: translations[label],
        value: response[label]
      }));
      summaries = summaries.map(summary => {
        if (summary.label === translations.unassigned) {
          summary.action = toggleTicketQueue;
        }
        return summary;
      });

      $scope.assignmentSummary = summaries;
    } catch (e) {
      $scope.assignmentSummary = [];
    }
  }

  async function getExecutives() {
    const profiles = await UsersService.getUsers();
    users = _.indexBy(profiles, 'id');
    UsersService.getCms().then(cms => {
      $scope.assignableUsers = 0;
      cms = filterHasAssignablesField(cms);
      $scope.users = cms.map(user => {
        const {profile} = profiles.find(profile => profile.id === user.cmid);
        const assignedAccounts = $scope.accounts.map(account => {
          const isActive = user.assignable_accounts.includes(account.id);
          return {
            active: isActive,
            id: account.id,
            avatar: account.avatar,
            name: account.name,
            uname: account.uname,
            sn: account.sn,
            uid: account.uid,
            username: account.username
          };
        });

        (user.assignation_workload = profile.assignation_workload),
          (user.assignation_limit = profile.assignation_limit);
        const _user = new User(user, assignedAccounts);
        return _user;
      });

      angular.forEach($scope.accountObjects, account => {
        if (
          !$scope.accountsOptions.includes(AdAvailableSn.getTitle(account.sn))
        ) {
          $scope.accountsOptions = $scope.accountsOptions.concat(
            AdAvailableSn.getTitle(account.sn)
          );
        }
      });

      $scope.users = filterUsersBasedOnTeams();
      updateAssignableUsers();

      $scope.accountObjects = $scope.accounts.map(
        account => new Account(self, account)
      );
      $scope.accountsOptions = [$scope.accountsOptions[0]];
      $scope.filteredUsers = [...$scope.users];
      $scope.filteredSn = [...$scope.accountObjects];
      $scope.loadingCms = false;
    });
    return users;
  }

  const filterUsersBasedOnTeams = () => {
    const {users: usersList = []} = UsersService;
    if ($scope.isSupervisorNotAdmin && $scope.userDepartments.length) {
      const userIdsFiltered = usersList.filter(
        ({id}) => !!$scope.users.find(({cmid}) => id === cmid)
      );

      const filteredUsersId = userIdsFiltered
        .filter(({profile: {departments: departmentsProfile}}) =>
          departmentsProfile.find(department =>
            $scope.userDepartments.includes(department)
          )
        )
        .map(({id}) => id);
      return $scope.users.filter(({cmid}) => filteredUsersId.includes(cmid));
    }
    return $scope.users;
  };

  vm.paginationListener = $scope.$watch(
    'pagination.pageNumber',
    (newValue, oldValue) => {
      if (oldValue !== newValue) {
        newValue = newValue - 1;
        vm.refreshHistory(newValue);
      }
    }
  );
  vm.refreshHistory = (page = 0) => {
    $scope.loading = true;
    AssignmentService.getHistory(page).then(response => {
      const {list = [], elements, page_size: pageSize} = response;
      $scope.activityLog = list.map(elem => {
        return parseLogEvent(elem);
      });
      $scope.pagination.totalItems = elements;
      $scope.pagination.pageSize = pageSize;
      $scope.pagination.pageNumber = page + 1;
      $scope.loading = false;
    });
  };
  $scope.refreshHistory = vm.refreshHistory;
  function parseLogEvent(event) {
    const {
      created: start,
      extra_data: extraData,
      author: {name: author}
    } = event;
    const {
      to,
      channel,
      config,
      what: reasonText,
      new_value: assignMode
    } = extraData;
    const created = formatDate(start * 1000);
    const startTooltip = formatCalendarDate(start * 1000);
    const params = {
      author
    };
    if (to) {
      let toUser = users[to];
      params.to = toUser
        ? `${toUser.first_name} ${toUser.last_name}`
        : translate('ASSIGNMENTS_ACTIVITY_LOG_DELETED_USER');
    }
    if (channel) {
      const {sn = '', name} = socialAccounts[channel] || {};
      params.channel =
        name || translate('ASSIGNMENTS_ACTIVITY_LOG_DELETED_CHANNEL');
      params.channel_sn = sn;
    }
    if (assignMode) {
      params.assignMode = assignMode;
    }
    if (config) {
      const upperConfig = config.toUpperCase();
      params.config = translate(`ASSIGNATION_${upperConfig}`);
    }

    return {
      created,
      startTooltip,
      reasonText,
      params
    };
  }

  function formatCalendarDate(date) {
    return moment(date).calendar();
  }

  function formatDate(date) {
    return moment(date).format('DD/MM/YYYY, h:mm:ss a');
  }

  function refreshGlobalAccounts() {
    return SocialAccountService.refreshSocialAccounts()
      .then(socialAccounts => {
        $scope.accounts = socialAccounts;
        $scope.filteredSn = socialAccounts;
        return socialAccounts
      })
  }
}
