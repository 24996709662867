'use strict';

angular
  .module('postCenterWebClientApp')
  .directive('helpdeskSearch', helpdeskSearchDirective);
function helpdeskSearchDirective() {
  return {
    templateUrl: 'blocks/crm/views/searchOld.html',
    restrict: 'E',
    scope: {
      search: '=',
    },
    controller: helpdeskSearchController
  }
}

helpdeskSearchController.$inject = [
  '$scope', '$rootScope', 'CaseDetailManager', 'UserData', 'MetadataHelper'
];

function helpdeskSearchController(
  $scope, $rootScope, CaseDetailManager: CaseDetailManager,
  UserData, MetadataHelper
) {
  var destroyables = {
    cleanEstablishmentReadyEvent: undefined
  };
  $scope.selectedTab = 'tickets';
  $scope.selectedUserSearch = 'name';

  if (angular.isDefined($rootScope.establishment)) {
    setCountryId();
  }

  $rootScope.$on('searchCasebyIdEnterPressed', function (e, data) {
    if (data.caseId) {
      $scope.searchCase(data.caseId);
    }
  })

  $scope.$on('$destroy', function () {
    for (var key in destroyables) {
      if (destroyables[key] !== undefined) {
        destroyables[key]();
      }
    }
  })
  destroyables.cleanEstablishmentReadyEvent = $rootScope.$on(
    'initialData.establishmentReady', setCountryId
  );

  $scope.searchCase = function (caseId: string) {
    $scope.loadingContentSearchTicket = true;
    $scope.showErrorTicket = false;
    CaseDetailManager.searchCase(caseId)
      .then(() => CaseDetailManager.openCaseState(caseId))
      .then(() => {
        $scope.loadingContentSearchTicket = false;
        setDefaultsAutocomplete();
        $scope.search.open = false;
    }, (error) => {
      $scope.loadingContentSearchTicket = false;
      $scope.showErrorTicket = true;
      $scope.$applyAsync();
    })
  };

  function setCountryId() {
    $scope.countryIdName = $rootScope.establishment.country['resident_id'];
  }
  
  function searchUser(method, searchData) {
    $scope.loadingContentSearchUser = true;
    $scope.showErrorUser = false;
    method(searchData).then(function (userObject) {
      $scope.loadingContentSearchUser = false;
      $scope.search.open = false;
    }, function (error) {
      $scope.loadingContentSearchUser = false;
      $scope.showErrorUser = true;
    });
    setDefaultsAutocomplete();
  }
  
  $scope.searchUserById = function () {
    var searchData = $scope.selectedUser.resource_id;
    var method = CaseDetailManager.searchUserById;
    searchUser(method, searchData);
  };
  
  $scope.searchUserByCountryId = function (userCountryId) {
    var searchData = MetadataHelper.countryIdFormatter(userCountryId);
    var method = CaseDetailManager.searchUserByCountryId;
    searchUser(method, searchData);
  }
  
  function setDefaultsAutocomplete() {
    $scope.selectedUser = null;
    $scope.contentToSearchTicket = '';
    $scope.contentToSearchUser = '';
    $scope.userCountryId = '';
    $scope.autocompletedUsers = [];
    $scope.userSelected = false;
  }
  
  $scope.loadItems = function (query) {
    if (angular.isUndefined(query) || query.length < 2 || (query[0] === '@' && query.length < 3)) {
      $scope.loadingUsers = false;
      return;
    }
    $scope.loadingUsers = true;
    var promise = UserData.loadAutocomplete(query);
    promise.then(function (data) {
      $scope.autocompleteFullUsers = data;
      $scope.autocompleteFullUsers.sort(
        function (a, b) {
          if (a.text > b.text) {
            return 1;
          } else if (a.text == b.text) {
            return 0
          }
          return -1;
        }
      );
      $scope.autocompletedUsers = _.map($scope.autocompleteFullUsers, function (user, index) {
        index = index + 1;
        if (query[0] === '@') {
          return index + ': ' + '@' + user.text + ' (' + user.sn + ')';
        } else {
          return index + ': ' + user.text + ' (' + user.sn + ')';
        }
      });
      $scope.autocompletedUsers = $scope.autocompletedUsers.splice(0, 9)
      $scope.loadingUsers = false;
    }, function (error) {
      $scope.loadingUsers = false;
      if (error.status === 404) {
        $scope.autocompletedUsers = [];
      }
    });
  }
  
  $scope.selectItem = function (selected) {
    var colonIndex = selected.indexOf(':')
    var position = parseInt(selected.substring(0, colonIndex))
    $scope.selectedUser = $scope.autocompleteFullUsers[position - 1]
    $scope.selectedUser.text += ' (' + $scope.selectedUser.sn + ')';
    $scope.contentToSearchUser = $scope.selectedUser.text;
    $scope.userSelected = true;
  }
  setDefaultsAutocomplete();
}