const EpaTab = Vue.component('EpaTab', {
  props: {
    name: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      chartTypes: ['date', 'agent']
    };
  },
  computed: {
    ...Vuex.mapGetters(
      'epaAnalytics', [
        'getTabCharts', 'getCountersTab', 'countersLoading', 'tabs', 'chartsLoading'
      ]
    )
  },
  methods: {
    getTitle(pos = 0) {
      return this.$tc(`${this.name}.charts[${pos}]`);
    },
    getChartModalInfo(pos = 0) {
      try {
        return this.$t(`${this.name}.chartsModalInfo[${pos}]`);
      } catch {
        return null;
      }
    },
    chartHasResults(chart) {
      return !!this.getTabCharts[chart].results;
    },
    getSkeletonSize(){
      return this.tabs.find(tab => tab.active).counters
    }
  },
  template: `<div>
    <SkeletonCounters v-if="countersLoading" :size="getSkeletonSize()"/>
    <CountersHeader v-else :counters="getCountersTab" />
    <slot v-for="(chart, index) in chartTypes">
      <KpiCard 
        :title="getTitle(index)" 
        v-if="chartHasResults(chart)" 
        :modalTitle="getTitle(index)"
        :hasModal="getChartModalInfo(index) != null"
        :uniqueKey="'' + index">
        <template slot="filters">
          <EpaKpiOptions
            :filters="getTabCharts[chart].filters"
            :title="getTitle(index)"
          />
        </template>
        <template slot="modal_body">
          <EpaGraphLegendList 
            :graphLegendsList="getChartModalInfo(index)"></EpaGraphLegendList>
        </template>
        <EpaResultsOrderBy
          v-if="chart === 'agent'"
          :order="getTabCharts[chart].filters.order_by" />
        <EpaRankingChart
          :chart="getTabCharts[chart].results"
          v-if="getTabCharts[chart].filters.metric.render === 'graph'"
        />
        <BasicTable
          :headings="getTabCharts[chart].results.data_results.table_headings"
          :rows="getTabCharts[chart].results.data_results.table_data"
          v-if="getTabCharts.date &&
      getTabCharts[chart].filters.metric.render === 'table'"
        />
      </KpiCard>
      <SkeletonChart v-if="chartsLoading"
        class="history--kpi-chart card"
      />
    </slot>
  </div>`
});

const EpaGraphLegendList = Vue.component('EpaGraphLegendList', {
  props: {
    graphLegendsList: {
      type: Array,
      default: () => []
    }
  },

  template: `<div>
    <div v-for="(legend, index) in graphLegendsList" :key="'leyend ' + index">      
      <EpaGraphTableLegend
        v-if="legend.type == 'table'" 
        :tableData="legend"> </EpaGraphTableLegend>
      <EpaGraphLegend 
        v-else
        :title="legend.title" 
        :body="legend.body"
        :iconClass="legend.iconClass"/>
    </div>
  </div>`
});

const EpaGraphLegend = Vue.component('EpaGraphLegend', {
  props: {
    title: {
      type: String,
      default: 'Epa Graph Legend Title'
    },
    body: {
      type: String,
      default: 'Body for Epa Graph legend'
    },
    iconClass: {
      type: String,
      default: 'epa-graph-icon-default'
    }
  },
  template: `<div>
    <div style="
        display: flex;
        align-items: center;
        column-gap: 20px;">
      <div :class="iconClass"> </div>
      <h3 style="
          margin-top: 10px;
          margin-bottom: 10px;">
          {{title}}
      </h3>
    </div>
    <p>{{body}}</p>
  </div>`
});

const EpaGraphTableLegend = Vue.component('EpaGraphTableLegend', {
  props: {
    tableData: {
      type: Object,
      default: () => {}
    }
  },

  template: `<div>
    <table class="epa-graph-modal-table">
      <tr>
        <th v-for="header in tableData.headers" :key="header">{{header}}</th>
      </tr>
      <tr v-for="(row, row_index) in tableData.rows" :key="'row ' + row_index">
        <td v-for="(cell, cell_index) in row" :key="row_index * 100 + cell_index">{{cell}}</td>
      </tr>
    </table>
  </div>`
});
