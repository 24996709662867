(module => {
  'use strict';
  function cmSelectorInput() {
    return {
      bindToController: {
        parent: '=cmSelectorInput'
      },
      controllerAs: 'inputCtrl',
      controller() {},
      link(scope, elem, attr, controller) {
        controller.parent.setInputElem(elem);
      }
    };
  }

  module.directive('cmSelectorInput', cmSelectorInput);
})(angular.module('postCenterWebClientApp'));
