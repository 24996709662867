'use strict';

(module => {
  Analytics.$inject = ['AdNotification'];

  function Analytics(AdNotification) {
    this.notify = function(method = '', message = '', error = null) {
      AdNotification[method](message, 'analytics');

      if (error) {
        throw new Error(error);
      }
    };

    this.getTranslations = async function(section = '', language = 'es') {
      try {
        const data = await fetch(`./languages/sections/${section}.json`);
        const response = await data.json();
        return response[language];
      } catch (error) {
        this.notify('error', 500, error);
        return false;
      }
    };
  }

  module.service('Analytics', Analytics);
})(angular.module('postCenterWebClientApp'));
