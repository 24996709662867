Vue.component('crmWizard', {
  template: `<section class="wizard-column">
    <crm-wiz-header />
    <div class="content d-flex">
      <div class="image">
        <img src="images/nueva_distribucion.svg" :alt="$t('title')" />
      </div>
      <div class="text">
        <h3>{{ $t('invitation') }}</h3>
        <p>{{ $t('distribution') }}</p>
        <h4>{{ $t('benefits') }}</h4>
        <benefits />
      </div>
    </div>
    <footer class="footer">
      <active-column-btn />
    </footer>
  </section>`
});
Vue.component('crmWizHeader', {
  inject: ['modalInstance'],
  template: `<header class="header">
              <h2>{{  $t('title') }}</h2>
              <button class="btn-close" @click="closeModal()">
                <span class="fa fa-times" />
              </button>
            </header>`,
  methods: {
    closeModal() {
      this.$store.dispatch('closeModal', this.modalInstance);
    }
  }
});
Vue.component('benefits', {
  data() {
    return {
      benefits: [1, 2, 3, 4]
    };
  },
  template: `<ul>
              <li v-for="(benefit, index) in benefits">
                <span class="fa fa-check-circle" v-if="" />
                <p v-html="$t('benefitsList['+ index +']')"></p>
              </li>
            </ul>`
});
Vue.component('activeColumnBtn', {
  inject: ['modalInstance'],
  template: `<button class="btn btn-wizard" @click="tryColumnFlow(modalInstance)">
              <column-icon type="new"/>
              {{ $t('tryIt') }}
            </button>`,
  methods: {
    ...Vuex.mapActions(['tryColumnFlow'])
  }
});
Vue.component('column-icon', {
  props: {
    flow: String
  },
  template: `<span class="column-icon" :class="updateIcon">
              <span />
              <span />
              <span v-if="flow === 'classic'" />
            </span>`,
  computed: {
    updateIcon() {
      return this.flow === 'classic'
        ? 'column-icon__classic'
        : 'column-icon__new';
    }
  }
});
