'use strict';

angular.module('postCenterWebClientApp')
  .directive('newColumnMessageTab', function () {
    return {
      transclude: true,
      templateUrl: 'blocks/column/views/column_message_tab.html',
      restrict: 'E'
    };
  });
