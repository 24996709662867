const DepartmentsFilter = Vue.component('DepartmentsFilter', {
  props: {
    departments: {
      type: Array,
      default: () => []
    },
    isRadio: {
      type: Boolean,
      default: false
    },
    changeData: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      filteredDepartments: []
    };
  },
  mounted() {
    const noDepartment = {
      name: this.$t('no_department'),
      id: 0,
      active: true
    };
    this.filteredDepartments = this.departments;
    this.filteredDepartments.push(noDepartment);
  },

  methods: {
    filterDepartments() {
      const filteredDepartments = this.departments
        .filter(dep => !dep.active)
        .map(_dep => _dep.id);
      //this.setFilters({filter: 'department', payload: filteredDepartments});
      this.changeData()
      return filteredDepartments;
    }
  },
  i18n: {
    messages: {
      es: {
        department: 'Departamento | Departamentos',
        no_department: 'Sin departamento'
      },
      pt: {
        department: 'Departamento | Departamentos',
        no_department: 'Sem departamento'
      },
      en: {
        department: 'Department | Departments',
        no_department: 'No department'
      }
    }
  },
  template: `
    <CustomSelector
        :filter-data="filteredDepartments"
        :change-data="filterDepartments"
        :title="$tc('department', 2)"
        :finder="$tc('department', 1)"
        :isRadio="isRadio"
    />`
});
