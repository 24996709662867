'use strict';
angular
  .module('postCenterWebClientApp')
  .service('BannedKeywordsService', function BannedKeywords(
    BusinessRulesService
  ) {
    this.bannedKeyword = {};
    this.had_duplicates = false;

    var self = this;
    var FORBIDDEN_CODE = 403;

    function parseKeywords(bannedKeyword) {
      var condition = bannedKeyword.condition.serialize();
      var keywords = condition[1];
      var parsedKeywordList = [];

      if (keywords.length === 1 && keywords[0] === '') {
        return parsedKeywordList;
      }

      for (var index in keywords) {
        parsedKeywordList.push({id: index, text: keywords[index]});
      }
      return parsedKeywordList;
    }

    function serializeKeywords(keywordList) {
      var keywords = keywordList.map(function(keyword) {
        return keyword.text;
      });

      var uniqueKeywords = keywords.filter(function(value, index, array) {
        return array.indexOf(value) === index && value.length > 0;
      });

      var answer = {
        words: uniqueKeywords.join(', '),
        had_duplicates: uniqueKeywords.length < keywords.length
      };

      return answer;
    }

    this.getBannedKeywords = function() {
      return new Promise(function(resolve, reject) {
        BusinessRulesService.get().then(
          function(serverRules) {
            var rule;
            var set = false;

            for (var ruleIndex in serverRules) {
              rule = serverRules[ruleIndex];

              if (
                rule.trigger === 'OUTBOUND_MESSAGE' &&
                rule.name === 'BANNED_KEYWORDS'
              ) {
                self.bannedKeyword = rule;
                set = true;
                break;
              }
            }

            if (!set) {
              self.bannedKeyword = rules.BusinessRule.createEmptyRule();
              self.bannedKeyword.name = 'BANNED_KEYWORDS';
              self.bannedKeyword.trigger = 'OUTBOUND_MESSAGE';
              self.bannedKeyword.condition = new conditions.MessageoOutboundKeywordsCondition(
                ''
              );
              self.bannedKeyword.actions[0] = new actions.RaiseStatusCodeAction(
                FORBIDDEN_CODE
              );
            }

            resolve(parseKeywords(self.bannedKeyword));
          },
          function(error) {
            reject(error);
          }
        );
      });
    };

    this.deleteBannedKeyword = function(keywordId) {
      var keywords = parseKeywords(this.bannedKeyword).filter(function(
        keyword
      ) {
        return keyword.id !== keywordId;
      });

      var postSerialization = serializeKeywords(keywords);
      this.bannedKeyword.condition.keywords = postSerialization.words;
      this.had_duplicates = postSerialization.had_duplicates;

      return new Promise(function(resolve, reject) {
        BusinessRulesService.save(self.bannedKeyword).then(
          function(rules) {
            var rule;

            for (var ruleIndex in rules) {
              rule = rules[ruleIndex];

              if (
                rule.trigger === 'OUTBOUND_MESSAGE' &&
                rule.name === 'BANNED_KEYWORDS'
              ) {
                self.bannedKeyword = rule;
                break;
              }
            }

            resolve({
              words: parseKeywords(self.bannedKeyword),
              had_duplicates: self.had_duplicates
            });
          },
          function(error) {
            reject(error);
          }
        );
      });
    };

    this.editBannedKeyword = function(keywordId, newWord) {
      var newWords = newWord.text.split(',');

      var keywords = parseKeywords(this.bannedKeyword).map(function(keyword) {
        if (keyword.id === keywordId) {
          keyword.text = newWords[0].trim().toLowerCase();
        }
        return keyword;
      });

      if (newWords.length > 1) {
        newWords.shift();

        for (var wordIndex in newWords) {
          var newKeyword = {
            id: keywords.length,
            text: newWords[wordIndex].trim().toLowerCase()
          };

          keywords.push(newKeyword);
        }
      }

      var postSerialization = serializeKeywords(keywords);
      this.bannedKeyword.condition.keywords = postSerialization.words;
      this.had_duplicates = postSerialization.had_duplicates;

      return new Promise(function(resolve, reject) {
        BusinessRulesService.save(self.bannedKeyword).then(
          function(rules) {
            var rule;

            for (var ruleIndex in rules) {
              rule = rules[ruleIndex];

              if (
                rule.trigger === 'OUTBOUND_MESSAGE' &&
                rule.name === 'BANNED_KEYWORDS'
              ) {
                self.bannedKeyword = rule;
                break;
              }
            }

            resolve({
              words: parseKeywords(self.bannedKeyword),
              had_duplicates: self.had_duplicates
            });
          },
          function(error) {
            reject(error);
          }
        );
      });
    };

    this.addBannedKeyword = function(newWord) {
      var keywords = parseKeywords(this.bannedKeyword);

      var newWords = newWord.text.split(',');

      for (var wordIndex in newWords) {
        var newKeyword = {
          id: keywords.length,
          text: newWords[wordIndex].trim().toLowerCase()
        };

        keywords.push(newKeyword);
      }

      var postSerialization = serializeKeywords(keywords);
      this.bannedKeyword.condition.keywords = postSerialization.words;
      this.had_duplicates = postSerialization.had_duplicates;

      return new Promise(function(resolve, reject) {
        BusinessRulesService.save(self.bannedKeyword).then(
          function(rules) {
            var rule;

            for (var ruleIndex in rules) {
              rule = rules[ruleIndex];

              if (
                rule.trigger === 'OUTBOUND_MESSAGE' &&
                rule.name === 'BANNED_KEYWORDS'
              ) {
                self.bannedKeyword = rule;
                break;
              }
            }

            resolve({
              words: parseKeywords(self.bannedKeyword),
              had_duplicates: self.had_duplicates
            });
          },
          function(error) {
            reject(error);
          }
        );
      });
    };
  });
