const SurveyList = Vue.component('SurveyList', {
  computed: {
    ...Vuex.mapGetters('epaConfig', ['surveys'])
  },
  methods: {
    ...Vuex.mapActions('epaConfig', ['saveSurvey'])
  },
  template: `<div class="flex-col-5">
    <survey-card
      v-for="survey in surveys"
      :survey="survey" :key="survey.id" class="mb-1" />
    <button
      class="btn ads-button ads-button--primary"
      @click="saveSurvey()">{{ $t('buttons.createSurvey') }}
    </button>
  </div>`
});
