'use strict';

angular.module('postCenterWebClientApp')
  .directive('messageImage', function () {
    return {
      scope: {
        src: '@'
      },
      templateUrl: 'blocks/message/views/message_image.html',
      restrict: 'E',
      controller: ['$scope',
      function ($scope) {
        jQuery('.magnific-popup-image').magnificPopup({
          type:'image',
          image: {
            cursor: null,
          }
        });
      }]
    };
  });
