(module => {
  'use strict';

  function DepartmentsHandler($rootScope, Users) {
    this.lastRequest = null;
    this.TIME_LIMIT = 15 * 1000;

    this._parseDepartments = function() {
      let self = this;
      this.departments = {};
      for (let index = 0; index < $rootScope.departments.length; index++) {
        let department = $rootScope.departments[index];
        this.departments[department.id] = department;
      }
      return self.departments;
    };

    this._requestDepartments = function() {
      let self = this;
      const now = new Date();

      if (
        this.lastRequest == null ||
        now - this.lastRequest >= this.TIME_LIMIT
      ) {
        this.lastRequest = now;
        return self._parseDepartments();
      } else {
        return new Promise(resolve => {
          resolve();
        });
      }
    };

    this.getDepartments = function() {
      let self = this;
      return self._requestDepartments();
    };
  }

  module.service('DepartmentsHandler', DepartmentsHandler);
})(angular.module('postCenterWebClientApp'));
