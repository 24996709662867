class AdModelFetcher<T> {
  /**
   * Hello, I am AdModelFetcher and my purpose is to provide an efficient
   * mechanism to fetch and reuse objects from the API.
   * 
   * My instances are constructed by passing a fetchFunction which returns a
   * promise of an object of type T.
   * 
   * My method get() returns a promise of an object of type T. If I already
   * have loaded the object from the API, the promise will resolve immediately,
   * if not, the fetch() function will be called via my refresh() method. The
   * nice thing is that if get() is invoked concurrently by multiple callers,
   * only one fetch() will be performed and all of the promises will block waiting
   * for the same result, which means only one API call (thank you, rxjs).
   * 
   * My refresh() method can be used to force a fetch of the object even if I
   * have already loaded it.
   * 
   * My clear() method discards the object that I had cached, which is useful on
   * cases such as logout.
   * 
   * I expose an observable() method which returns an rxjs.Observable<T> that emits
   * when the model is refreshed and the new value is not new. This is useful
   * for listening to model updates from controllers in an ordered, portable, and
   * typesafe way without relying on AngularJS' rootScope to pass update events.
   * 
   * TODO: add support for caching objects via Web Storage API with TTL.
   */

  private fetching = false
  private modelSubject: rxjs.BehaviorSubject<T> = new rxjs.BehaviorSubject()
  private modelObservable: rxjs.Observable<T> = this.modelSubject.pipe(
    rxjs.filter(model => model != null)
  )
  private fetch: () => Promise<T>

  constructor(fetchFunction: () => Promise<T>) {
    this.fetch = fetchFunction
  }

  public clear() {
    this.modelSubject.next(null)
  }

  public get(): Promise<T> {
    /**
     * Returns the current current model if it is already loaded.
     * If not, it fetches it using the fetch function and then returns.
     * 
     * If this method is called concurrently, only one fetch is performed
     * and all of the promises are blocked waiting for this result.
     */
    let cmsPromise = rxjs.firstValueFrom(this.modelObservable)
      const shouldFetch = !(this.modelSubject.value || this.fetching)
      if (shouldFetch) {
        this.refresh()
      }
      return cmsPromise
  }

  public refresh(): Promise<T> {
    this.fetching = true
    return this.fetch().then(model => {
      this.modelSubject.next(model)
      return model
    }).finally(() => {
      this.fetching = false
    })
  }

  public observable(): rxjs.Observable<T> {
    return this.modelObservable
  }
}