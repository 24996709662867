'use strict';

angular
  .module('postCenterWebClientApp')
  .directive('adReplyAccountSelector', function () {
    return {
      bindToController: {
        selectedAccount: '=',
        accountFilter: '&',
        replyToMessage: '=',
        compact: '<?'
      },
      scope: {},
      controllerAs: 'ctrl',
      templateUrl: 'blocks/global/views/ad_reply_account_selector.html',
      restrict: 'E',
      replace: true,
      controller: [
        '$scope',
        '$q',
        '$timeout',
        'SocialAccountService',
        function (
          $scope,
          $q,
          $timeout,
          SocialAccountService: SocialAccountService
        ) {

          var self = this

          this.$onInit = function () {
            self.filter = self.accountFilter() || truePredicate
            self.inputElementDeferred = $q.defer()
            self.inputElementPromise = self.inputElementDeferred.promise;

            self.activeIndex = -1;
            self.popoverOpen = false;
            self.typeaheadText = '';
            self.resultElements = [];
            self.filteredAccounts = [];
          }


          SocialAccountService.getSocialAccounts().then(accounts => {
            this.allAccounts = accounts;
            self.availableAccounts = getAvailableAccounts(accounts, self.filter);
          });

          $scope.$watchCollection(
            'ctrl.filteredAccounts',
            function (filteredAccounts) {
              self.activeIndex =
                filteredAccounts && filteredAccounts.length > 0 ? 0 : -1;
            }
          );

          $scope.$watch('ctrl.popoverOpen', function (popoverOpen) {
            if (popoverOpen) {
              if (self.selectedAccount) {
                var selectedIndex = self.availableAccounts.indexOf(
                  self.selectedAccount
                );
                self.availableAccounts.splice(selectedIndex, 1);
                self.availableAccounts.sort(compareByName);
                self.availableAccounts.splice(0, 0, self.selectedAccount);
              } else {
                self.availableAccounts.sort(compareByName);
              }
              self.inputElementPromise.then(function (inputElement) {
                $scope.$applyAsync(function () {
                  $timeout(function () {
                    inputElement.focus();
                  }, 10);
                });
              });
            } else {
              $scope.$applyAsync(function () {
                self.typeaheadText = '';
                self.filteredAccounts = [];
              });
              self.inputElementDeferred = $q.defer();
              self.inputElementPromise = self.inputElementDeferred.promise;
            }

            function compareByName(account1, account2) {
              if (account1.name === account2.name) {
                return 0;
              } else if (account1.name > account2.name) {
                return 1;
              } else {
                return -1;
              }
            }
          });

          $scope.$watch('ctrl.replyToMessage', function (replyToMessage, old) {
            var isInit = replyToMessage === old;
            if (isInit) {
              return;
            }
            self.popoverOpen = false;
            self.availableAccounts = getAvailableAccounts(
              self.allAccounts,
              self.filter
            );
          });

          self.selectMatch = function (index) {
            if (index === -1) {
              return;
            }
            self.selectedAccount = self.filteredAccounts[index];
            self.popoverOpen = false;
          };

          self.handleInput = function (e) {
            var DOWN = 40,
              UP = 38,
              ENTER = 13,
              ESC = 27;
            switch (e.which) {
              case DOWN:
                self.activeIndex = keepInBoundaries(self.activeIndex + 1);
                handleScroll();
                e.preventDefault();
                break;
              case UP:
                self.activeIndex = keepInBoundaries(self.activeIndex - 1);
                handleScroll();
                e.preventDefault();
                break;
              case ENTER:
                self.selectMatch(self.activeIndex);
                e.preventDefault();
                e.stopPropagation();
                break;
              case ESC:
                self.popoverOpen = false;
                e.preventDefault();
                e.stopPropagation();
                break;
            }
            function keepInBoundaries(index) {
              var filteredAccounts = self.filteredAccounts || [];
              if (filteredAccounts.length === 0) {
                return -1;
              }
              if (index < 0) {
                return 0;
              }
              if (index >= filteredAccounts.length) {
                return filteredAccounts.length - 1;
              }
              return index;
            }
          };

          self.setInputElem = function (element) {
            self.inputElementDeferred.resolve(element);
          };

          self.setIthResultElem = function (i, element) {
            self.resultElements[i] = element;
          };

          self.setContainerElem = function (element) {
            self.containerElement = element;
          };

          function getAvailableAccounts(allAccounts, filter) {
            var accounts = allAccounts.filter(filter),
              selectedAccount =
                self.selectedAccount && _.find(accounts, hasTheSelectedId);
            if (selectedAccount) {
              self.selectedAccount = selectedAccount;
            } else {
              var mentionedUids = {};
              angular.forEach(self.replyToMessage.mentions, function (mention) {
                mentionedUids[mention.uid] = true;
              });
              self.selectedAccount = accounts[0];
              for (var i = 0; i < accounts.length; i++) {
                var account = accounts[i];
                if (mentionedUids[accounts[i].uid]) {
                  self.selectedAccount = account;
                  break;
                }
              }
            }
            return accounts;
          }

          function truePredicate(x) {
            return true;
          }

          function hasTheSelectedId(account) {
            return account.id == self.selectedAccount.id;
          }

          function getIthResultDOMElement(i) {
            return self.resultElements[i];
          }

          function getResultContainerDOMElement() {
            return self.containerElement;
          }

          function handleScroll() {
            var container = getResultContainerDOMElement(),
              containerScrollPos = container.scrollTop(),
              containerHeight = container.height(),
              result = getIthResultDOMElement(self.activeIndex),
              resultHeight = result.outerHeight(),
              resultOffset = result.offset().top - container.offset().top,
              scrollUpwardsDelta = -resultOffset,
              scrollDownwardsDelta =
                resultOffset + resultHeight - containerHeight;

            if (scrollUpwardsDelta > 0) {
              container.scrollTop(container.scrollTop() - scrollUpwardsDelta);
            }

            if (scrollDownwardsDelta > 0) {
              container.scrollTop(container.scrollTop() + scrollDownwardsDelta);
            }
          }
        }
      ]
    };
  });
