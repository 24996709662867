(module => {
  'use strict';
  module.directive('assignmentMonitoring', assignmentMonitoring);
  function assignmentMonitoring() {
    return {
      templateUrl: 'blocks/admin/views/assignment/assignment_monitoring.html',
      restrict: 'E',
      scope: {},
      controller: 'AssignmentMonitoringCtrl'
    };
  }
})(angular.module('postCenterWebClientApp'));
