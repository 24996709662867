(module => {
  'use strict';
  COSAdminCtrl.$inject = [
    '$scope',
    '$filter',
    'UsersService',
    'CustomOnlineStatuses',
    'AdNotification'
  ];
  function COSAdminCtrl(
    $scope,
    $filter,
    UsersService,
    CustomOnlineStatuses,
    AdNotification
  ) {
    const vm = this;
    vm.saving = false;
    vm.changeColumnName = true;
    vm.statuses = [];
    vm.labels = [];
    vm.statusesToDelete = [];
    vm.displayHelp = false;
    vm.activityLog = [];
    vm.pagination = {pageNumber: 1};
    vm.statusName = {};
    vm.toggleHelp = function () {
      vm.displayHelp = !vm.displayHelp;
    };
    vm.$onDestroy = () => {
      vm.watchPagination();
    };
    vm.onlineStatusOptions = buildOnlineStatusOptions();

    vm.isMovableUp = function (idx) {
      return isSwappable(idx, idx - 1);
    };

    vm.isMovableDown = function (idx) {
      return isSwappable(idx, idx + 1);
    };

    vm.moveUp = function (idx) {
      return swapStatus(idx, idx - 1);
    };

    vm.moveDown = function (idx) {
      return swapStatus(idx, idx + 1);
    };

    vm.updateAssignable = function (idx) {
      const {assignable} = vm.statuses[idx];
      const status = vm.statuses[idx];
      updateCos(status, 'assignable', assignable);
    };

    vm.updatePersists = function (idx) {
      const {persists} = vm.statuses[idx];
      const status = vm.statuses[idx];
      updateCos(status, 'persists', persists);
    };

    vm.updateBlocks = function (idx) {
      const {blocks} = vm.statuses[idx];
      const status = vm.statuses[idx];
      updateCos(status, 'blocks', blocks);
    };

    vm.updateKeepAssignation = function (idx) {
      const {keep_assignation} = vm.statuses[idx];
      const status = vm.statuses[idx];
      updateCos(status, 'keep_assignation', keep_assignation);
    };

    vm.updateNewName = function (idx) {
      vm.statuses[idx].enableChangeName = false;
      const label = vm.statuses[idx];
      const status = vm.statuses[idx];
      updateCos(status, 'label', label);
    };

    vm.disableChangeStatusName = function (idx) {
      vm.statuses[idx].enableChangeName = false;
    };

    vm.enableChangeStatusName = function (idx) {
      vm.statuses[idx].enableChangeName = true;
    };

    vm.reallocate = function (idx) {
      const {online_status} = vm.statuses[idx];
      const status = vm.statuses[idx];
      updateCos(status, 'online_status', online_status);
    };

    vm.isDeletable = function (idx) {
      return !vm.statuses[idx].core;
    };

    vm.delete = function (idx) {
      const status = vm.statuses[idx];
      if (!status.core) {
        CustomOnlineStatuses.remove(status).then(() => {
          updateStatuses();
        });
      }
    };

    updateStatuses();

    const onCosCreateListenerOff = $scope.$on('cos:create_cos', () => {
      updateStatuses();
    });

    $scope.$on('$destroy', function() {
      onCosCreateListenerOff();
    })

    function updateStatuses() {
      CustomOnlineStatuses.fetch().then(() => {
        const statuses = CustomOnlineStatuses.list();
        vm.statuses = [];
        _.each(statuses, (status, idx) => {
          vm.statuses[idx] = status;
          vm.statuses[idx].enableChangeName = false;
          vm.labels.push(status.label.toLowerCase());
        });
        const completeStatuses = CustomOnlineStatuses.completeList();
        _.each(completeStatuses, status => {
          vm.statusName[status.resource_id] = status.label;
        });
        vm.saving = false;
      });
      refreshHistory(0);
    }
    vm.watchPagination = $scope.$watch(
      'vm.pagination.pageNumber',
      (newVal, oldVal) => {
        if (newVal !== oldVal) {
          refreshHistory(vm.pagination.pageNumber - 1);
        }
      }
    );
    function updateCos(cos, config, new_value) {
      vm.saving = true;
      const data = {};
      data.resource_id = cos.resource_id;
      data.config = config;
      data.new_value = new_value;
      CustomOnlineStatuses.update(data).then(() => {
        updateStatuses();
        AdNotification.success(200, 'cos_updated');
      });
    }

    function isSwappable(idx1, idx2) {
      const s1 = vm.statuses[idx1];
      const s2 = vm.statuses[idx2];
      return s1 && s2 && s1.online_status === s2.online_status;
    }

    function swapStatus(idx1, idx2) {
      const s1 = vm.statuses[idx1];
      const s2 = vm.statuses[idx2];
      if (s1 && s2) {
        vm.statuses[idx1] = s2;
        vm.statuses[idx2] = s1;
        s1.moved = (s1.moved || 0) + 1;
        s2.moved = (s2.moved || 0) + 1;
      }
      const data = [
        {
          resource_id: s1.resource_id,
          index: s2.index
        },
        {
          resource_id: s2.resource_id,
          index: s1.index
        }
      ];
      CustomOnlineStatuses.updateIndex(data).then(() => {
        updateStatuses();
      });
    }

    function buildOnlineStatusOptions() {
      const options = {};
      options[$filter('onlineStatusFilter')(2)] = 2;
      options[$filter('onlineStatusFilter')(1)] = 1;
      options[$filter('onlineStatusFilter')(0)] = 0;
      return options;
    }
    function refreshHistory(page) {
      vm.loading = true;
      CustomOnlineStatuses.getHistory(page).then(response => {
        vm.activityLog = _.chain(response.list).map(parseLogEvent).value();
        vm.pagination.totalItems = response.elements;
        vm.pagination.pageSize = response.page_size;
        vm.pagination.pageNumber = page + 1;
        vm.loading = false;
      });
    }

    function parseLogEvent(event) {
      const {
        created: start,
        author: {cmid},
        extra_data: {value, status, config, what: reasonText}
      } = event;
      const created = formatDate(start * 1000);
      const startTooltip = formatCalendarDate(start * 1000);
      const userAuthor = UsersService.users.find(user => user.id === cmid);
      const {first_name, last_name} = userAuthor || {};
      const author = !userAuthor
        ? $filter('translate')('ASSIGNMENTS_ACTIVITY_LOG_DELETED_USER')
        : `${first_name} ${last_name}`;

      const params = {
        author
      };
      if (status) {
        const statusName = vm.statusName[status];
        params.status = statusName;
      }
      if (config) {
        const original_config = config.toUpperCase();
        params.config = $filter('translate')(`COS_${original_config}`);
        if (typeof value === 'boolean') {
          params.value = $filter('translate')(String(value).toUpperCase());
        } else if (original_config === 'ONLINE_STATUS') {
          params.value = $filter('onlineStatusFilter')(value);
        } else {
          params.value = value;
        }
      }

      return {
        created,
        startTooltip,
        reasonText,
        params
      };
    }

    function formatCalendarDate(date) {
      return moment(date).calendar();
    }

    function formatDate(date) {
      return moment(date).format('DD/MM/YYYY, h:mm:ss a');
    }
  }
  module.controller('COSAdminCtrl', COSAdminCtrl);
})(angular.module('postCenterWebClientApp'));
