'use strict';

angular
  .module('postCenterWebClientApp')
  .directive('metricChartTable', MetricChartTable);

MetricChartTable.$inject = ['AnalyticsHelper', 'AnalyticsConfigs'];

function MetricChartTable(AnalyticsHelper, AnalyticsConfigs) {
  return {
    scope: {
      sample: '=',
      widget: '=',
      parentMetric: '='
    },
    templateUrl: 'blocks/analytics/views/metric_chart_table.html',
    restrict: 'AE',
    controller: [
      '$scope',
      '$rootScope',
      '$filter',
      'amMoment',
      'moment',
      'adExportToCsv',
      'FireTagService',
      function(
        $scope,
        $rootScope,
        $filter,
        amMoment,
        moment,
        adExportToCsv,
        FireTagService
      ) {
        $scope.exportTable = () => {
          const columns = $scope.table.columns;
          const rows = $scope.table.rows;

          const title = $filter('translate')($scope.widget.title);
          const name = $filter('translate')('COS_KPI_TABLE_COL_NAME');
          const headers = ['-'];
          const data = [];

          columns.map(col => {
            headers.push(col);
          });
          rows.map((row, i) => {
            let newRow = {};
            newRow[name] = row.name;
            row.points.map((point, index) => {
              newRow[headers[index + 1]] = point[1];
            });
            data.push(newRow);
          });
          adExportToCsv.exportToCsv(title, data);
          FireTagService.setEvent({
            name: 'exportacion_general',
            params: {
              export_name: title
            }
          });
        };
        var getMetricScheme = function() {
          var totalKey = $filter('translate')('ANALYTICS_TOTAL');
          var averageKey = $filter('translate')('ANALYTICS_AVERAGE');
          var currentKey = $scope.parentMetric.data.key;
          var searchKey = {name: 'total'};

          if (currentKey !== totalKey && currentKey !== averageKey) {
            searchKey = {name: 'detail'};
          }

          var metricScheme = _.findWhere(
            $scope.widget.dataScheme.metrics,
            searchKey
          );

          return metricScheme;
        };
        var Chart = {
          create: function() {
            return {
              api: null,
              state: {}
            };
          },

          getConfigObj: function(chartReference) {
            var widget = $scope.widget;
            var widgetChartConfig = widget.charts.detail;
            var config = AnalyticsConfigs.getChartConfig(
              widget,
              widgetChartConfig,
              chartReference
            );
            return config;
          },

          update: function() {
            var metricScheme = getMetricScheme();
            var series = AnalyticsHelper.getSeries(
              $scope.sample,
              metricScheme,
              $scope.parentMetric
            );

            if (series && series.length) {
              addSeries(series);
              $scope.state.content.chartBuilt = true;
            } else {
              $scope.state.flags.invalidContent = true;
            }

            function addSeries(series) {
              var newSeries = [];
              angular.forEach(series, function(serie, index) {
                newSeries[index] = serie;
              });
              $scope.chart.config.series = newSeries;
            }
          },

          clearSerie: function(serie) {
            serie.data = [];
          },

          addToSerie: function(serie, data) {
            if (data.y > 0) {
              serie.data.push(data);
            }
          }
        };

        /***********************************
         *
         *  TABLE HELPER
         *
         ***********************************/
        var Table = {
          create: function() {
            return {
              rows: [],
              columns: [],
              state: {},
              getId: function() {
                var name = ($scope.widget.name || '').replace(/:/g, '-');
                return 'js-table-' + name;
              }
            };
          },

          update: function() {
            var metricScheme = getMetricScheme();
            var series = AnalyticsHelper.getSeries(
              $scope.sample,
              metricScheme,
              $scope.parentMetric
            );

            $scope.table.rows = [];
            $scope.table.columns = [];

            if (series && series.length) {
              addToTable(series);
              $scope.state.content.tableBuilt = true;
            } else {
              $scope.state.flags.invalidContent = true;
            }

            function addToTable(series) {
              angular.forEach(series, function(serie, index) {
                if (angular.isArray(serie.data[0])) {
                  $scope.table.rows.push({
                    name: serie.name,
                    points: serie.data
                  });
                } else if (angular.isObject(serie.data[0])) {
                  var points = serie.data.map(function(serieData) {
                    return [serieData.name, serieData.y];
                  });
                  $scope.table.rows.push({
                    name: AnalyticsConfigs.getTitle($scope.widget),
                    points: points
                  });
                }
              });

              angular.forEach($scope.table.rows[0].points, function(
                point,
                index
              ) {
                if (angular.isNumber(point[0])) {
                  var value = amMoment.preprocessDate(point[0]);
                  var date = moment(value);
                  $scope.table.columns.push(pointTimeFormatStr(moment(value)));
                } else {
                  $scope.table.columns.push(String(point[0]));
                }
              });

              function pointTimeFormatStr(date) {
                var period = $scope.widget.period;
                var format = 'DD/MM/YYYY';
                if (
                  period &&
                  (period.granularity === 'm' || period.granularity === 'h')
                ) {
                  format = 'DD/MM/YYYY - HH:mm';
                }
                return date.format(format);
              }
            }
          }
        };

        function updateWidget() {
          $scope.state.flags.invalidContent = false;
          if ($scope.state.flags.availableContent()) {
            switch ($scope.state.content.selected) {
              case 'chart':
                Chart.update();
                break;
              case 'table':
                if (
                  !$scope.widget.realtime ||
                  !$scope.state.content.tableBuilt
                ) {
                  Table.update();
                }
                break;
            }
          }
        }

        /***********************************
         *
         *  WIDGET DATA HOLDERS
         *
         ***********************************/

        $scope.table = Table.create();
        $scope.chart = Chart.create();
        $scope.chart.config = Chart.getConfigObj($scope.chart);

        /***********************************
         *
         *  WIDGET STATES
         *
         ***********************************/

        $scope.state = {
          flags: {
            availableContent: function() {
              return $scope.parentMetric;
            },
            invalidContent: false
          },
          content: {
            active: true,
            selected: 'chart',
            tableBuilt: false,
            chartBuilt: false,
            toggle: function() {
              var state = $scope.state.content;
              state.selected = state.selected === 'chart' ? 'table' : 'chart';
              updateWidget();
              $scope.oppositeLabel = getOppositeContentLabel();
            }
          }
        };

        $scope.oppositeLabel = 'ANALYTICS_VIEW_TABLE';
        function getOppositeContentLabel() {
          var result = 'ANALYTICS_VIEW_UNKNOWN';
          switch ($scope.state.content.selected) {
            case 'chart':
              result = 'ANALYTICS_VIEW_TABLE';
              break;
            case 'table':
              result = 'ANALYTICS_VIEW_CHART';
              break;
            default:
              result = 'ANALYTICS_VIEW_UNKNOWN';
              break;
          }
          return result;
        }

        const parentMetricWatcherOff = $scope.$watch('parentMetric', function(parentMetric) {
          if (parentMetric) {
            updateWidget();
          }
        });

        const sampleWatcherOff = $scope.$watchCollection('sample', function(oldSample, newSample) {
          if ($scope.parentMetric != null) {
            updateWidget();
          }
        });

        $scope.$on('$destroy', function () {
          parentMetricWatcherOff();
          sampleWatcherOff();
        });
      }
    ],
    link: function($scope, $element) {}
  };
}
