interface StateRoute {
  name: string;
  url: string;
}

class AppState {
  public static CASE_STATE = 'crm.inbox.case';
  public static INBOX_STATE = 'crm.inbox';
  public static CONTACTS_STATE = 'crm.contact_agenda';
  public static BILLING_STATE = 'admin.dashboard';
  public static LOGIN_STATE = 'login';
  public static INSTALL_STATE = 'install';

  stateRoute: StateRoute;
  params: object;

  constructor(stateRoute: StateRoute, params: object) {
    this.stateRoute = stateRoute;
    this.params = params;
  }

  public isCaseState(): boolean {
    return this.stateRoute.name === AppState.CASE_STATE;
  }

  public isContactsState(): boolean {
    return this.stateRoute.name === AppState.CONTACTS_STATE;
  }

  public isInboxState(): boolean {
    return this.stateRoute.name === AppState.INBOX_STATE;
  }

  public isBillingState(): boolean {
    return this.stateRoute.name === AppState.BILLING_STATE;
  }
}

class StateTransition {
  fromState: AppState;
  toState: AppState;

  constructor(toState: AppState, fromState: AppState) {
    this.fromState = fromState;
    this.toState = toState;
  }
}

class AppStateService {
  private $state;
  private $rootScope;
  private $transitions;
  private stateSubject = new rxjs.BehaviorSubject<StateTransition>(null);
  private stateObservable = this.stateSubject.pipe(
    rxjs.filter(transition => transition != null)
  );
  public caseStateObservable = this.observeStateName(AppState.CASE_STATE);
  public inboxStateObservable = this.observeStateName(AppState.INBOX_STATE);
  public contactsStateObservable = this.observeStateName(
    AppState.CONTACTS_STATE
  );

  private observeStateName(stateName: string): rxjs.Observable {
    return this.stateObservable.pipe(
      rxjs.filter(
        (t: StateTransition) => t.toState.stateRoute.name == stateName
      )
    );
  }

  public currentState(): AppState {
    let state = this.$state.$current;
    let params = this.$state.params;
    return new AppState({name: state.name, url: state.url}, params);
  }

  public goToCase(
    caseId: string | number,
    sideViewMenuOption?: string
  ): Promise<void> {
    return this.$state.transitionTo(
      AppState.CASE_STATE,
      {id: caseId, sideviewmenuoption: sideViewMenuOption},
      {location: true}
    );
  }

  public goToInbox(): Promise<void> {
    return this.$state.go(AppState.INBOX_STATE);
  }

  public goToLogin(): Promise<void> {
    return this.$state.go(AppState.LOGIN_STATE);
  }

  public goToInstall(install: string, code: string): Promise<void> {
    return this.$state.go(AppState.INSTALL_STATE, { install: install, code: code });
  }

  public goToChannel(): Promise<void> {
    return this.$state.go(`admin.channels`);
  }

  private listenToStateChange() {
    this.$transitions.onSuccess({}, $transition => {
      var toState: StateRoute = {
        name: $transition.$to().name,
        url: $transition.$to().url.pattern
      };
      var toParams: object = $transition.params();
      var fromState: StateRoute = {
        name: $transition.$from().name,
        url: $transition.$from().url.pattern
      };
      var fromParams: object = {};
      let transition = new StateTransition(
        new AppState(toState, toParams),
        new AppState(fromState, fromParams)
      );
      this.stateSubject.next(transition);
    });
  }

  constructor($rootScope, $state, $transitions) {
    this.$state = $state;
    this.$rootScope = $rootScope;
    this.$transitions = $transitions;
    this.listenToStateChange();
  }
}

angular
  .module('postCenterWebClientApp')
  .service('AppStateService', AppStateService);
