const AgentsSelector = Vue.component('AgentsSelector', {
  data() {
    return {
      name: '',
      toggleList: false,
      isChecked: true
    };
  },
  computed: {
    ...Vuex.mapGetters(['getAgents']),
    getAgentsByFilter() {
      return this.getAgents.filter(agent =>
        new RegExp(this.name, 'ig').test(agent.name)
      );
    },
    isActive() {
      return this.toggleList ? 'active' : '';
    }
  },
  methods: {
    updateTableByAgent() {
      this.checkList();
    },
    checkList() {
      const inactive = this.getAgents.find(agent => !agent.active);
      this.isChecked = inactive ? false : true;
    },
    toggleSelector(event) {
      if (
        event.target.id !== 'agent-search-input' &&
        event.target.id !== 'agent-checkbox'
      ) {
        this.toggleList = !this.toggleList;
      }
    },
    closeSelector() {
      this.toggleList = false;
    },
    allSelector() {
      this.getAgents.map(agent => {
        agent.active = !this.isChecked;
      });
    }
  },
  template: `
    <div class="ads-selector-wrapper">
      <div
        class="ads-selector agents-selector"
        @click="toggleSelector"
        v-click-outside="closeSelector"
      >
        <div class="ads-selector__header d-flex" :class="isActive">
          <h5 class="bold d-inline">{{ $t('all_agents') }}</h5>
          <span class="fa fa-chevron-down" />
        </div>
        <div v-show="toggleList">
          <label>
            <input
              type="checkbox"
              key="kpi-header"
              v-model="isChecked"
              @click="allSelector"
            />
            <span class="mx-1">{{ $t('all_agents') }}</span>
          </label>
          <div class="p-1">
            <input
              type="text"
              id="agent-search-input"
              class="form-control mb-1"
              :placeholder="$t('inputs.agent_finder')"
              v-model="name"
            />
          </div>
          <p class="p-1" v-if="!getAgentsByFilter.length">
            {{ $t('issues.not_found_agents') }}
          </p>
          <ul v-else class="list-unstyled">
            <li v-for="agent in getAgentsByFilter" :key="agent.id">
              <label>
                <input
                  type="checkbox"
                  @change="updateTableByAgent(agent)"
                  :name="agent.id"
                  v-model="agent.active"
                />
                <span class="mx-1">{{ agent.name }}</span>
              </label>
            </li>
          </ul>
        </div>
      </div>
    </div>
  `
});
