'use strict';

var app = angular.module('postCenterWebClientApp');

app.directive('socialAccountList', function() {
  return {
    templateUrl: 'blocks/admin/views/social_accounts/social_account_list.html',
    restrict: 'E',
    scope: {
      socialAccounts: '=',
      currentTab: '=',
      refreshCallback: '='
    },
    controller: [
      '$scope',
      '$rootScope',
      '$filter',
      'AdNotification',
      'SocialAccount',
      'SocialAccountService',
      '$uibModal',
      'SocialAccountWhatsappModals',
      '$timeout',
      'BASE_URL',
      'FACEBOOK_PERMISSIONS',
      'AdAvailableSn',
      'FireTagService',
      '$window',
      function(
        $scope,
        $rootScope,
        $filter,
        AdNotification,
        SocialAccount,
        SocialAccountService,
        $uibModal,
        SocialAccountWhatsappModals,
        $timeout,
        BASE_URL,
        FACEBOOK_PERMISSIONS,
        AdAvailableSn,
        FireTagService,
        $window
      ) {
        var destroyables = {
          openWhatsAppModal: undefined
        };

        $scope.$on('$destroy', function() {
          if (destroyables.openWhatsAppModal) {
            destroyables.openWhatsAppModal();
          }
        });

        $scope.establishment = $rootScope.establishment;
        var pid = $scope.establishment.public_id;
        var oauthUrl = BASE_URL + '/api/v1/oauth/sign/?auth=' + pid;
        const twitterToken = $scope.establishment.config['twitter_api_key'];

        hello.init(
          {
            twitter: twitterToken
          },
          {redirect_uri: '/', oauth_proxy: oauthUrl}
        );

        function checkTwitterAccounts() {
          var twitterCountAccounts = 0;

          SocialAccountService.checkSnAccounts('twitter').then(function(data) {
            twitterCountAccounts = data.count;
            if (twitterCountAccounts == 0) {
              $scope.maxAccounts = 0;
              $scope.status = 'twitter_required';
            }
          });
        }

        if ($scope.currentTab !== 'undefined') {
          $scope.title = $scope.currentTab.title;
          $scope.socialNetwork = $scope.currentTab.socialNetwork;
          $scope.type = $scope.currentTab.type;
          $scope.maxAccounts = $scope.currentTab.maxAccounts;
          $scope.globalOptions = $scope.currentTab.globalOptions;

          const maxAccounts = $scope.maxAccounts;

          if ($scope.socialAccounts) {
            const accountsLength = $scope.socialAccounts.length;
            if (maxAccounts <= 0) {
              $scope.status = maxAccounts === -1 ? 'unlimited' : 'not_allowed';
            } else if (maxAccounts === accountsLength) {
              $scope.status = 'limit_reach';
            } else {
              $scope.status = 'allowed';
            }
          }

          if ($scope.title === 'Twitter Search') {
            $scope.maxQuota =
              $rootScope.establishment['max_twitter_search_quota'];
            $scope.quotaUsed = $rootScope.establishment['twitter_search_quota'];
            $scope.quotaUsedPercent =
              ($scope.quotaUsed * 100) / $scope.maxQuota;
            checkTwitterAccounts();
          }
        }

        if ($scope.currentTab.socialNetwork === 'whatsapp') {
          var event = 'AddSocialAccount:backToRequestCode';
          destroyables.openWhatsAppModal = $rootScope.$on(event, function(
            event,
            data
          ) {
            var connectData = {
              phone_number: data['whatsapp']['phone'],
              client_status: 'LOGIN_REQUIRED',
              login_code: '',
              name: '',
              avatar: ''
            };

            SocialAccountWhatsappModals.clientStatus(
              connectData
            ).result.finally(function() {
              $scope.refreshCallback();
            });
          });
        }

        $scope.fbPermissions = function() {
            window.open('https://www.facebook.com/settings?tab=business_tools&ref=business_login_reentry_mixed_state', '_blank');
        }

        $scope.addAccount = function(socialNetwork, type) {
          $scope.loading = true;
          FireTagService.setEvent({
            name: 'button_connect_account',
            params: {account: this.socialNetwork}
          });
          if (socialNetwork === 'facebook') {
            connectFacebook();
          } else if (socialNetwork === 'whatsapp') {
            $scope.loading = false;
            connectWhatsappSmooch();
            // add easter egg fatality web!
            // connectWhatsappWeb();
          } else if (socialNetwork === 'email') {
            $scope.loading = false;
            connectEmail();
          } else if (socialNetwork === 'twitter' && type === 'user') {
            connectTwitter();
          } else if (socialNetwork === 'twitter' && type === 'search') {
            $scope.loading = false;
            connectTwitterSearch();
          } else if (socialNetwork === 'chat') {
            $scope.loading = false;
            connectChat();
          } else if (socialNetwork === 'play_store') {
            $scope.loading = false;
            connectPlayStore();
          } else if (socialNetwork === 'app_store') {
            $scope.loading = false;
            connectAppStore();
          } else if (socialNetwork === 'instagram') {
            connectInstagram();
          } else {
            $scope.loading = false;
            // show friendly message to user...
          }
        };
        $scope.contactSeller = channel => {
          const channel_requested = AdAvailableSn.getTitle(channel);
          const body = $filter('translate')(
            'SOCIALACCOUNTS_CONTACT_BODY_MESSAGE',
            {
              channel: channel_requested
            }
          );
          window.open('https://adere.page.link/ventas', '_blank')
        };
        function connectWhatsappSmooch() {
          SocialAccountWhatsappModals.addSmoochAccount().result.then(r => {
            $scope.refreshCallback();
          });
        }
        function connectWhatsappWeb() {
          // Whatsapp Connection Step 1
          SocialAccountWhatsappModals.addAccount()
            .result.then(function(response) {
              $scope.refreshCallback();
              return SocialAccountWhatsappModals.clientStatus(response).result;
            })
            .then(function(status) {
              $scope.refreshCallback();
              SocialAccountService.accessToken({
                provider: 'whatsapp',
                phone_number: status['phone_number'],
                name: status['name']
              });
            });
        }

        function connectEmail() {
          newEmailModal();
        }

        function connectTwitter() {
          hello('twitter').login(function(response) {
            $timeout(function() {
              $scope.loading = false;
              if (response.error) {
                FireTagService.setEvent({
                  name: 'conecta_cuenta',
                  params: {
                    channel_name: 'Twitter'
                  }
                });
                accessTokenSuccess();
              }
            }, 0);
          });
        }

        function connectInstagram() {
          newInstagramModal();
        }

        function connectChat() {
          newChatModal();
        }

        function connectPlayStore() {
          newPlayStoreModal();
        }

        function connectAppStore() {
          newAppStoreModal();
        }

        function connectTwitterSearch() {
          newTwitterSearchModal();
        }
        function accessTokenSuccess() {
          $scope.refreshCallback();
          SocialAccount.onChannelConnected();
        }

        function accessTokenFailed(data) {
          if ($scope.updateAccountsPromise) {
            $timeout.cancel($scope.updateAccountsPromise);
          }
          AdNotification.error(data, 'login_social_account');
        }

        function connectFacebook() {
          var permissions = FACEBOOK_PERMISSIONS.split('::');

          try {
            FB.login(
              function(response) {
                if (response.authResponse) {
                  openFBModal(response.authResponse.accessToken);
                } else {
                  $timeout(function() {
                    $scope.loading = false;
                    // User cancelled login or did not fully authorize.
                  }, 100);
                }
              },
              {
                enable_profile_selector: true,
                response_type: 'token',
                scope: permissions.join(',')
              }
            );
          } catch (error) {
            AdNotification.error(500, 'fb_lib_not_loaded');
            $scope.loading = false;
          }
        }

        function openFBModal(token) {
          var modalInstance = $uibModal.open({
            templateUrl: 'newAccountsContent.html',
            controller: 'NewFacebookAccountController',
            controllerAs: 'vm',
            size: 'small',
            resolve: {
              token: function() {
                return token;
              }
            }
          });

          modalInstance.opened.then(function() {
            $scope.loading = false;
          });

          modalInstance.result.then(function(selectedAccounts) {
            const connectPayload = {
              account_list: selectedAccounts,
              provider: $scope.socialNetwork
            };

            SocialAccountService.connect(connectPayload).then(
              function() {
                accessTokenSuccess();
                FireTagService.setEvent({
                  name: 'conecta_cuenta',
                  params: {
                    channel_name: 'Facebook'
                  }
                });
              },
              function(data) {
                accessTokenFailed(data);
              }
            );
          });
        }

        function newInstagramModal() {
          var modalInstance = $uibModal.open({
            templateUrl:
              'blocks/admin/views/social_accounts/new_instagram_account.html',
            controller: 'NewInstagramAccountController',
            size: 'small'
          });

          modalInstance.result
            .then(function(data) {
              SocialAccountService.accessToken(data).then(
                function() {
                  $scope.refreshCallback();
                  SocialAccount.onChannelConnected();
                  FireTagService.setEvent({
                    name: 'conecta_cuenta',
                    params: {
                      channel_name: 'Instagram'
                    }
                  });
                },
                function(data) {
                  AdNotification.error(data, 'login_social_account');
                }
              );
            })
            .catch(function(data) {
              if (data !== 'cancel') {
                AdNotification.error(data, 'login_social_account');
              }
            })
            .finally(function() {
              $scope.loading = false;
            });
        }

        function newEmailModal() {
          var modalInstance = $uibModal.open({
            templateUrl:
              'blocks/admin/views/social_accounts/new_email_account.html',
            controller: 'NewEmailAccountCtrl',
            controllerAs: 'vm',
            size: 'small',
            resolve: {
              establishment: function() {
                return $scope.establishment;
              }
            }
          });

          modalInstance.result
            .then(function() {
              $scope.refreshCallback();
            })
            .finally(function() {
              $scope.loading = false;
            });
        }

        function newAppStoreModal() {
          var modalInstance = $uibModal.open({
            templateUrl:
              'blocks/admin/views/social_accounts/new_app_store_account.html',
            controller: 'NewAppStoreCtrl',
            size: 'small'
          });
          modalInstance.result.then(function(data) {
            SocialAccountService.accessToken(data).then(
              function() {
                $scope.loading = false;
                $scope.refreshCallback();
                SocialAccount.onChannelConnected();
                FireTagService.setEvent({
                  name: 'conecta_cuenta',
                  params: {
                    channel_name: 'App Store'
                  }
                });
              },
              function(data) {
                $scope.loading = false;
                AdNotification.error(data, 'login_social_account');
              }
            );
          });
        }

        function newPlayStoreModal() {
          var modalInstance = $uibModal.open({
            templateUrl:
              'blocks/admin/views/social_accounts/new_play_store_account.html',
            controller: 'NewPlayStoreCtrl',
            size: 'small'
          });
          modalInstance.result.then(function(data) {
            SocialAccountService.accessToken(data).then(
              function() {
                $scope.loading = false;
                $scope.refreshCallback();
                SocialAccount.onChannelConnected();
                FireTagService.setEvent({
                  name: 'conecta_cuenta',
                  params: {
                    channel_name: 'Play Store'
                  }
                });
              },
              function(data) {
                $scope.loading = false;
                AdNotification.error(data, 'login_social_account');
              }
            );
          });
        }

        function newChatModal() {
          var modalInstance = $uibModal.open({
            templateUrl:
              'blocks/admin/views/social_accounts/new_chat_account.html',
            controller: 'NewChatAccountCtrl',
            size: 'small'
          });

          modalInstance.result.then(function(data) {
            SocialAccountService.accessToken(data).then(
              function() {
                $scope.loading = false;
                $scope.refreshCallback();
                SocialAccount.onChannelConnected();
                FireTagService.setEvent({
                  name: 'conecta_cuenta',
                  params: {
                    channel_name: 'Chat'
                  }
                });
              },
              function(data) {
                $scope.loading = false;
                AdNotification.error(data, 'login_social_account');
              }
            );
          });
        }

        function newTwitterSearchModal() {
          var modalInstance = $uibModal.open({
            templateUrl:
              'blocks/admin/views/social_accounts/new_twitter_search_account.html',
            controller: 'NewTwitterSearchAccountCtrl',
            size: 'small'
          });

          modalInstance.result.then(function(data) {
            SocialAccountService.accessToken(data).then(
              function() {
                $scope.loading = false;
                $scope.refreshCallback();
                SocialAccount.onChannelConnected();
                FireTagService.setEvent({
                  name: 'conecta_cuenta',
                  params: {
                    channel_name: 'Twitter Search'
                  }
                });
              },
              function(data) {
                $scope.loading = false;
                AdNotification.error(data, 'login_social_account');
              }
            );
          });
        }
      }
    ]
  };
});
