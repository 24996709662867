'use strict';

var app = angular.module('postCenterWebClientApp');
app.service('Conversation', function Columns(AdRequest) {

  this.getConversation = function(messageResourceId){
    var base = '/api/v1/messages/conversation/';
    var path = base + messageResourceId +'/';
    return AdRequest.get(path);
  };

});
