//import Vue from 'vue'
//import Vuex from 'vuex'
//import VueI18n from 'vue-i18n'

//Vue.use(VueI18n)

const UpdateBrowser = Vue.component('UpdateBrowser', {
  computed: {
    ...Vuex.mapGetters(['notify', 'update']),
    btnStyles() {
      return {
        cursor: 'pointer',
        textDecoration: 'underline'
      };
    }
  },
  methods: {
    ...Vuex.mapActions(['updateVersion', 'setNotify'])
  },
  template: `<div class="browser-notify" v-if="notify && update">
  <span
    class="fa fa-times-circle mr-1"
    style="cursor:pointer"
    @click="setNotify(false)" />
  <span>
    <strong>{{ $t('text') }}</strong>
  </span>
  <span class="ml-2"
    :style="btnStyles"
    @click="updateVersion">
    <strong>{{ $t('button') }}</strong>
  </span>
</div>`,
  i18n: {
    messages: {
      es: {
        text: 'Hemos publicado nuevas actualizaciones en Adereso',
        button: 'Actualizar'
      },
      pt: {
        text: 'Lançamos novas atualizações no Adereso',
        button: 'Atualizar'
      },
      en: {
        text: 'We have released new updates in Adereso',
        button: 'Update'
      }
    }
  }
});

const locale =
  localStorage.getItem('NG_TRANSLATE_LANG_KEY') ||
  window.navigator.language.slice(0, 2) ||
  'es';
const i18nBrowser = new VueI18n({
  locale,
  fallbackLocale: ['es', 'en', 'pt'],
  silentTranslationWarn: true
});

store.registerModule('browserNotification', {
  state: () => ({
    pcVersion: '',
    userVersion: localStorage.getItem('pcVersion'),
    shouldUpdate: false,
    shouldNotify: null
  }),
  getters: {
    notify: state => state.shouldNotify,
    update: state => state.shouldUpdate
  },
  mutations: {
    UPDATE_VERSION(state, payload) {
      state.pcVersion = payload;
    },
    UPDATE_BROWSER(state, payload) {
      state.shouldUpdate = payload;
    },
    SEND_NOTIFY(state, payload) {
      state.shouldNotify = payload;
    }
  },
  actions: {
    async getPostcenterVersion({commit, dispatch}) {
      const response = await fetch('./version.json');
      const myResp = await response.json();
      const updatedVersion = myResp.version.split('-');
      const pcVersion =
        updatedVersion.length > 1
          ? `${updatedVersion[0]}.${updatedVersion[1]}`
          : updatedVersion[0];
      commit('UPDATE_VERSION', pcVersion);
      dispatch('checkVersion', pcVersion);
    },
    checkVersion({state, commit}, latestVersion) {
      const {userVersion} = state;
      if (!userVersion) {
        localStorage.setItem('pcVersion', latestVersion);
        commit('UPDATE_BROWSER', false);
      } else if (userVersion !== latestVersion) {
        commit('UPDATE_BROWSER', true);
        commit('SEND_NOTIFY', true);
      }
    },
    setNotify({commit}, shouldNotify) {
      commit('SEND_NOTIFY', shouldNotify);
    },
    updateVersion({state, commit}) {
      const {pcVersion} = state;
      const localVersion = localStorage.getItem('pcVersion');
      if (localVersion !== pcVersion) {
        localStorage.setItem('pcVersion', pcVersion);
        window.location.reload();
      } else {
        commit('SEND_NOTIFY', false);
      }
    }
  }
});
new Vue({
  el: '#update-browser',
  store,
  i18n: i18nBrowser,
  data: {
    canShow: window.location.hash !== '#/signup/'
  },
  mounted() {
    this.$store.dispatch('getPostcenterVersion');
  }
});
