'use strict';

const DB_NAME = 'adereso'
const STORE_NAME = 'ad-store'

class AdStore {
  store

  constructor() {
    this.store = new idbKeyval.Store(DB_NAME, STORE_NAME)
  }

  public set(key, value): Promise<void> {
    return idbKeyval.set(key, value, this.store)
  }

  public get(key): Promise<any> {
    return idbKeyval.get(key, this.store)
  }

  public clear(): Promise<void> {
    return idbKeyval.clear(this.store)
  }

  public async include(key): Promise<string[]> {
    return await idbKeyval.keys(this.store).then(resp => resp.includes(key))
  }

}

angular
  .module('postCenterWebClientApp')
  .service('AdStore', AdStore)