const WorkloadResume = Vue.component('WorkloadResume', {
  computed: {
    ...Vuex.mapGetters(['limit', 'totals', 'warningRange'])
  },
  template: `<div class="workload-resume">
  <div class="workload-card arrow">
    <slot v-if="limit">
      <h4><span class="fa fa-circle mr-1" />{{ $t('openedAwait.title') }}</h4>
      <p>{{ $t('openedAwait.desc') }}</p>
    </slot>
    <slot v-else>
      <h4><span class="fa fa-circle mr-1" />{{ $t('awaitResponse.title') }}</h4>
      <p>{{ $t('awaitResponse.desc') }}</p>
      <ul>
        <li>
          <span class="fa fa-exclamation-circle-fas mr-1" />
          <span v-html="$t('info.lowPercentage', {low: warningRange.low, high: warningRange.high})" />
        </li>
        <li>
          <span class="fa fa-exclamation-triangle mr-1" />
          <span v-html="$t('info.criticalPercentage', {high: warningRange.high})" />
        </li>
    </ul>
    </slot>

    <slot v-if="limit">
      <h4><span class="fa fa-circle mr-1" />{{ $t('withoutAddressing.title') }}</h4>
      <p>{{ $t('withoutAddressing.desc') }}</p>
      <ul>
        <li>
          <span class="fa fa-exclamation-circle-fas mr-1" />
          <span v-html="$t('withoutAddressing.percent.low', {low: warningRange.low, high: warningRange.high})" />
        </li>
        <li>
          <span class="fa fa-exclamation-triangle mr-1" />
          <span v-html="$t('withoutAddressing.percent.high', {high: warningRange.high})" />

        </li>
    </ul>
    </slot>
  </div>
  <AgentsCounters
    v-if="!limit"
    :title="$t('awaitResponse.title')"
    :totals="totals.nonAnswered" />
  <AgentsCounters
    :title="$t('withoutAddressing.title')"
    :totals="totals.nonAddressed" />

</div>`
});

const AgentsCounters = Vue.component('AgentsCounters', {
  props: {
    title: String,
    totals: Array
  },
  template: `<div>
  <h4>{{ title }}</h4>
  <div class="workload-card">
    <div class="d-flex">
      <span class="fa fa-exclamation-circle-fas mr-1" />
      <strong>{{totals[0]}} {{ $t('table.agents') }}</strong>
      <span class="fa fa-exclamation-triangle mr-1 ml-4" />
      <strong>{{totals[1]}} {{ $t('table.agents') }}</strong>
    </div>
  </div>
  </div>`
});
