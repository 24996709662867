const AccountsFilter = Vue.component('AccountsFilter', {
  i18n: {
    messages: {
      es: {
        accounts: 'Cuenta | Cuentas',
        select: 'Filtrar por cuenta'
      },
      pt: {
        accounts: 'Conta | Contas',
        select: 'filtro por conta'
      },
      en: {
        accounts: 'Account | Accounts',
        select: 'Filter by account'
      }
    }
  },
  computed: {
    ...Vuex.mapGetters('socialAccounts', ['accounts', 'accountsBySn']),
    ...Vuex.mapState('epaAnalytics', ['availableAccounts']),
    selectorLabels() {
      return {
        select: this.$t('select'),
        title: this.$t('title')
      };
    },
    filteredAccounts() {
      return this.accounts
        .filter(({social_network}) =>
          this.availableAccounts.includes(social_network)
        )
        .map(({visual_name, id}) => ({
          name: visual_name,
          active: false,
          id
        }));
    }
  },
  template: `<div class="">
    <CustomSelector
      :labels="selectorLabels"
      :filter-data="filteredAccounts"
    />
  </div>`
});
