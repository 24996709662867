Vue.component('client-not-contactable', {
  props: {reasons: {type: Array, default: () => []}},
  computed: {
    reason() {
      const [reason] = this.reasons;
      if (!reason) {
        return {};
      }

      return reason.code;
    }
  },
  template: `
    <p v-show="reasons.length" class="text-center">
      {{ $t('contactErrors.' + reason) }}.
      <a target="_blank" href="https://help.adere.so/article/504wycissh-clientes#por-que-no-puedo-contactar-a-un-cliente">
        {{ $t('contactErrors.viewMore') }}
      </a>
    </p>
  `
});
