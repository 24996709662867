(function (module) {
  'use strict';

  function AdminCtrl(
    $scope,
    $rootScope,
    AdNotification,
    InitialData,
    UserService: UserService,
    EstablishmentService: EstablishmentService,
    $translate,
    $window,
    localStorageService,
    AdModal,
    wootricFactory,
    StylesService: StylesService,
    AppStateService: AppStateService,
    VersionService: VersionService,
    SocialAccountService: SocialAccountService
  ) {
    const vm = this;
    $scope.loader = true;

    let toDestroy: {[key: string]: (() => {}) | undefined } = {}

    vm.$onInit = () => {
      $scope.trialEnding = $rootScope.trialEnding;
      InitialData.refreshAdmin().then(initAdmin, data => {
        AdNotification.error(data, 'loading_manage_account')
      })

      Canny('initChangelog', {
        appID: '5ef55f330172f5179f92acc5',
        position: 'top',
        align: 'left'
      });

      StylesService.getStyles().then(styles => {
        vm.styles = styles
      })
    };

    $scope.adminTab = $rootScope.adminTab;

    $scope.navbarExpanded = localStorageService.get('navbarExpanded');
    if ($scope.navbarExpanded === null) {
      $scope.navbarExpanded = true;
    }

    $scope.navbarToggle = function () {
      $scope.navbarExpanded = !$scope.navbarExpanded;
      localStorageService.set('navbarExpanded', $scope.navbarExpanded);
    };

    $scope.openShortcutModal = function () {
      const modalConfig = {
        template: 'blocks/crm/views/shortcut_modal.html'
      };

      AdModal.build(modalConfig);
    };

    function handleIframeMessage({data}){

      if (data.type !== 'billingEvent') {
          return;
      }

      const {message} = data;

      if (message.action === 'redirect') {
        deskRedirect(message.url)
      }
    }

    function deskRedirect(url) {
      window.location.href = url;
    }

    function redirectIfRequired(trialStatus: TrialStatus) {
      const isEmpty = trialStatus.days_remaining === null || trialStatus.days_remaining === undefined;
      const isExpired = !isEmpty && trialStatus.days_remaining <= 0;
      const inBilling = AppStateService.currentState().isBillingState();
      if (isExpired && !inBilling) {
        AppStateService.goToInbox()
      }
    }

    function initAdmin() {
      Promise.all([
        UserService.getProfile(),
        EstablishmentService.getEstablishment(),
        VersionService.getVersion(),
        EstablishmentService.getTrialStatus()
      ]).then(([user, establishment, postcenterVersion, trialStatus]) => {
        const {
          first_name, last_name, isAnalyst, isAdmin, isSupervisor,
          profile: {language, is_bot}
        } = user

        if (!isAdmin && !isSupervisor && !isAnalyst) {
          AppStateService.goToInbox()
        }
        $translate.use(language)

        wootricFactory.run(user.email, user.date_joined)
        $scope.isAdmin = isAdmin
        $scope.isSupervisor = isSupervisor
        $scope.isAnalyst = isAnalyst
        $scope.isBot = is_bot
        $scope.version = postcenterVersion
        $scope.establishment = establishment.name
        $scope.username = `${first_name} ${last_name}`
        $scope.loader = false
        $scope.showEPA = establishment.features.satisfaction_module

        redirectIfRequired(trialStatus);
        
      })

      /*Lets send location change to intercom*/
      if (typeof $window.Intercom !== 'undefined') {
        $window.Intercom('update');
      }
      $scope.notifications = {};
      vm.accountsSub = SocialAccountService.accountsObservable.subscribe(accounts => {
        $scope.notifications.social_accounts = false
        $scope.notifications.nonAccounts = false
        if (accounts.length > 0) {
          accounts.forEach(account => {
            if (!account.isHealthy) {
              $scope.notifications.social_accounts = true
            }
          })
        } else {
          $scope.notifications.nonAccounts = true
        }
      })
      window.addEventListener('message', handleIframeMessage)
    }

    toDestroy.onChildReady = $rootScope.$on('SIDEBAR_READY', () => {
      const data = {
        features: $rootScope.establishment.features,
        adminTab: $scope.adminTab,
        notifications: $scope.notifications,
        trialEnding: $scope.trialEnding,
      }
      $rootScope.$emit('ADMIN_GOT_INITIAL_DATA', data);
    });

    $scope.$on('$destroy', () => {

      if (toDestroy.onChildReady) {
        toDestroy.onChildReady();
      }

      locationChangeListenerOff();

    });

    const locationChangeListenerOff = $scope.$on('$locationChangeSuccess', function(event, current, previous) {
      EstablishmentService.getTrialStatus().then(trialStatus => {
        redirectIfRequired(trialStatus);
      })
    });
  }

  module.controller('AdminCtrl', AdminCtrl);
})(angular.module('postCenterWebClientApp'));
