'use strict';

angular.module('postCenterWebClientApp')
  .directive('adAccountSelector', function () {
    return {
      scope: {
        twitterSelectedAccounts: '=',
        selectedAccounts: '=',
        accountFilter: '=',
        accountsSelectedByDefault: '=',
      },
      templateUrl: 'blocks/global/views/adereso_account_selector.html',
      restrict: 'E',
      controller: [
        '$scope', '$rootScope', 'UserService',
        function ($scope, $rootScope, UserService: UserService) {

        $scope.twitterSelectedAccounts = 0;
        $scope.selectedAccounts = [];

        // This is way more performant than using filter on ng-repeat.
        // Given that our availableAccounts will not change on real time.
        $scope.allAccounts = angular.copy($rootScope.accounts)
        $scope.availableAccounts = getAvailableAccounts($scope.allAccounts)
        let userSub = UserService.userObservable.subscribe(user => {
          $scope.allAccounts = angular.copy($rootScope.accounts);
          $scope.availableAccounts = getAvailableAccounts(
            $scope.allAccounts
          )
        })

        $scope.$destroy(() => {
          userSub.unsubscribe()
        })

        function getAvailableAccounts(allAccounts) {
          var accounts = allAccounts;
          if ($scope.accountFilter) {
            accounts = allAccounts.filter($scope.accountFilter)
          }
          if ($scope.accountsSelectedByDefault) {
            $scope.accountsSelectedByDefault(accounts);
            accounts.forEach(function(account) {
              if (account.selected) {
                $scope.selectedAccounts.push(account);
              }
              if (account.sn === 'twitter') {
                $scope.twitterSelectedAccounts += 1;
              }
            })
          }
          return accounts;
        }

        $scope.setAccountSelection = function(account) {
          var twitterAdd = 1;
          if (account.selected) {
            $scope.selectedAccounts.push(account);
          } else {
            var index = $scope.selectedAccounts.indexOf(account);
            $scope.selectedAccounts.splice(index, 1);
            twitterAdd = -1;
          }
          if (account.sn === 'twitter') {
            $scope.twitterSelectedAccounts += twitterAdd;
          }
        }

      }]
    };
  });
