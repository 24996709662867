(module => {
  'use strict';
  MessageContentCtrl.$inject = ['$scope', '$sce', 'AdNotification', 'UserData'];
  function MessageContentCtrl($scope, $sce, AdNotification, UserData) {
    const isRich = $scope.message.is_rich;
    const sn = $scope.message.sn;
    const hasMedia = $scope.message.media;
    const ours = $scope.message.ours;
    let mediaType = undefined;

    if (hasMedia) {
      mediaType = $scope.message.media.type;
    }

    var text = $scope.message.text || '';
    if (text === 'MEDIA_MESSAGE') {
      text = ''; // MEDIA MESSAGE TEXT IS JUST TO SET CASE LAST CONTENT
    }

    var mentions = {};
    angular.forEach($scope.message.mentions, function (mention) {
      mentions[mention['screen_name']] = mention.uid;
    });

    function replaceText(match) {
      switch (match.getType()) {
        case 'url':
          return true; // let Autolinker perform its normal anchor tag replacement
        case 'email':
          return true;
        case 'phone':
          return false;
        case 'twitter':
          var name = match.getTwitterHandle();
          if (mentions.hasOwnProperty(name)) {
            var uid = mentions[name];
            var onClick =
              'createUserColumnFromID("' + name + '","' + uid + '")';
            return '<a ng-click="' + onClick + '">@' + name + '</a>';
          } else {
            return false;
          }
        case 'hashtag':
          return true;
      }
    }

    if ($scope.message.sn === 'twitter') {
      text = Autolinker.link(text, {
        stripPrefix: false,
        hashtag: 'twitter',
        replaceFn: replaceText
      });
    } else if ($scope.message.sn === 'facebook') {
      text = Autolinker.link(text, {
        stripPrefix: false,
        hashtag: 'facebook',
        replaceFn: replaceText
      });
    } else {
      text = Autolinker.link(text, {
        stripPrefix: false,
        replaceFn: replaceText
      });
    }

    function whatsappFormatter(text: string) {
      return text.replace(/(?:^|\s)(_)(?:(?!\s)([\s\S]*?))(_)(?=\s|$)/g, ' <i>$2</i> ')
        .replace(/(?:^|\s)(\*)(?:(?!\s)([\s\S]*?))(\*)(?=\s|$)/g, ' <b>$2</b> ')
        .replace(/(?:^|\s)(~)(?:(?!\s)([\s\S]*?))(~)(?=\s|$)/g, ' <s>$2</s> ')
        .replace(/(?:^|\s)(--)(?:(?!\s)([\s\S]*?))(--)(?=\s|$)/g, ' <u>$2</u> ')
        .replace(/(?:^|\s)(```)(?:(?!\s)([\s\S]*?))(```)(?=\s|$)/g, ' <tt>$2</tt> ')
        .replaceAll('\n', '<br>');
    }

    if ($scope.message.sn === 'whatsapp') {
      text = whatsappFormatter(text);
    }

    $scope.renderedText = text;

    function isStoryResponse(){
      if (!isRich || ours || sn !== "instagram") return false
      const component = $scope.message.component
      if (!component) return false;
      if (component.type !== "buttons" || !component.buttons || component.buttons.length !== 1) return false;
      const button = component.buttons[0];

      if (button.type !== "button" || button.action.type !== "url" ) return false;
      return true
    }

    function showAsText() {
      let validMediaType = true

      if(hasMedia && mediaType) validMediaType = ["file", "document", "image"].includes(mediaType)
      return sn !== "email" && validMediaType && (!isRich || isStoryResponse());
    }

    function setAsStoryResponse(){
      const component = $scope.message.component
      if (!component) return;
      const button = component.buttons[0];
      const media = {
        name: button.title,
        viewer_url: button.action.url,
        type: "link"
      };
      $scope.message.media = media;
    }

    if (isStoryResponse()) {
      $scope.isStoryResponse = true;
      setAsStoryResponse();
    }

    $scope.isText = showAsText()

    $scope.trustUrl = function (url) {
      return $sce.trustAsResourceUrl(url);
    };

    $scope.createUserColumnFromID = function (screenName, uid) {
      UserData.loadAutocomplete(screenName).then(
        function (users) {
          var user;
          for (var i in users) {
            if (users[i].uid === uid && users[i].sn === $scope.message.sn) {
              user = users[i];
              break;
            }
          }
          if (user !== undefined) {
            createUserColumn(formatColumnData(user));
          } else {
            AdNotification.info(users, 'new_column_from_user');
          }
        },
        function (data) {
          AdNotification.info(data, 'new_column_from_user');
        }
      );
    };
  }
  module.controller('MessageContentCtrl', MessageContentCtrl);
})(angular.module('postCenterWebClientApp'));
