'use strict';

angular.module('postCenterWebClientApp').service('AnalyticsCharts', function() {
  this.createPie = function(seriesData) {
    return {
      credits: false,
      title: null,
      chart: {
        spacingTop: 10,
        spacingBottom: 10,
        spacingLeft: 10,
        spacingRight: 10,
        backgroundColor: 'transparent',
        type: 'pie',
        height: 220
      },
      plotOptions: {
        pie: {
          borderColor: 'rgba(255,255,255,0.5)',
          slicedOffset: 0,
          allowPointSelect: true,
          size: '100%',
          borderWidth: 0,
          cursor: 'pointer',
          showInLegend: true,
          dataLabels: {enabled: false}
        }
      },
      xAxis: {
        pie: {}
      },
      yAxis: {
        pie: {}
      },
      series: seriesData
    };
  };
});
