const Table = Vue.component('Table', {
  props: {
    headings: {
      type: Array,
      default: () => [],
      validator: headings => {
        // Model: {label: 'foo', field: 'bar', component: 'baz'}
        if (!headings.length) {
          return true;
        }

        return headings.some(
          heading => !!heading.label && !!heading.field && !!heading.component
        );
      }
    },
    rows: {type: Array, default: () => []},
    sortBy: {type: String, default: ''},
    allowSorting: {type: Boolean, default: true}
  },
  data() {
    return {sorting: {reverse: false, field: ''}};
  },
  computed: {
    chevronClass() {
      const direction = this.sorting.reverse ? 'up' : 'down';
      return `fa fa-chevron-${direction}`;
    },
    sortedRows() {
      const {field, reverse} = this.sorting;
      const rows = [...this.rows];

      if (!field) {
        return this.rows;
      }

      const sorted = rows.sort((prev, next) => {
        if (prev[field] === next[field]) {
          return 0;
        }
        return prev[field] > next[field] ? 1 : -1;
      });

      return reverse ? sorted.reverse() : sorted;
    }
  },
  mounted() {
    this.sortBy && this.sort(this.sortBy);
  },
  methods: {
    sort(field = '') {
      if (!this.allowSorting) {
        return;
      }

      const {field: currentField} = this.sorting;
      const invalidField = this.headings.every(heading => !!heading[field]);

      if (invalidField) {
        throw new Error('Invalid sorting field');
      }

      this.sorting = {
        // Update sorting field and reverse (if necessary)
        field,
        reverse: currentField === field ? !this.sorting.reverse : false
      };
    }
  },
  template: `
    <div class="table-responsive">
      <section class="table adereso-table clickable">
        <header>
          <ul class="adereso-table--header flex-row">
            <li class="adereso-table--cell text-overflow"
                v-for="heading in headings"
                :key="heading.field"
                @click="sort(heading.field)">
              <strong>{{ heading.label }}</strong>
              <span v-if="sorting.field === heading.field" :class="chevronClass"></span>
            </li>
          </ul>
        </header>
        <ol class="adereso-table--body d-flex flex-col">
          <li class="adereso-table--row d-flex flex-row"
              v-for="(row, index) in sortedRows"
              :key="index"
              @click="$emit('onRowSelected', $event, row)">
            <div class="adereso-table--cell text-overflow"
                 v-for="heading in headings"
                 @click="$emit('onCellSelected', $event, heading.field, row)">
                <component :is="heading.component" v-bind="row">{{ row[heading.field] }}</component>
            </div>
          </li>
        </ol>
      </section>
    </div>
  `
});
