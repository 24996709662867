(module => {
  'use strict';
  AssignmentDirectCtrl.$inject = [
    '$scope',
    '$rootScope',
    '$sce',
    'FRONTEND3_URL',
    'AdRequest',
    'VersionService'
  ];
  module.controller('AssignmentDirectCtrl', AssignmentDirectCtrl);

  function AssignmentDirectCtrl(
    $scope, $rootScope, $sce, FRONTEND3_URL, AdRequest, VersionService
  ) {
    addEventListener('blur', () => {
      if (
        document.activeElement ===
        document.getElementById('assignmentDirectIframe')
      ) {
        const topBar = angular.element(
          document.getElementsByTagName('ad-top-bar')
        );
        topBar.trigger('click');
      }
    });

    VersionService.getVersion().then(pcVersion => {
      $scope.iFrameUrl = function() {
        const token = AdRequest.getToken();
  
        const url =
          FRONTEND3_URL +
          'admin/assignation/direct?auth=' +
          token +
          '&pc-ver=' +
          pcVersion;
        return $sce.trustAsResourceUrl(url);
      }
    })
  }
})(angular.module('postCenterWebClientApp'));
