(module => {
  'use strict';

  function UserFilter(
    $rootScope, $filter, UserService: UserService, DepartmentService: DepartmentService
  ) {
    const self = this;
    self.users = [];
    self.filtered = [];

    function updateUsers() {
      self.users = angular.copy($rootScope.users);
    }

    function resetFilter() {
      self.filtered = angular.copy(self.users);
    }

    function accentRemover(name) {
      return name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    }

    function filterByName(name) {
      self.filtered = $filter('filter')(
        self.filtered,
        (value, index, array) => {
          if (!name) {
            return true;
          }
          let full_name = value.first_name + ' ' + value.last_name;
          name = name.toLowerCase();
          name = accentRemover(name);
          full_name = full_name.toLowerCase();
          full_name = accentRemover(full_name);
          return full_name.indexOf(name) !== -1;
        }
      );
    }

    function filterByMode(mode) {
      // 0 = All users / -1 = With no department
      if (!mode || mode.id === 0) {
        return;
      }
      self.filtered = $filter('filter')(self.filtered, value => {
        const {
          assignation_config: {establishment, fifo}
        } = value.profile;
        switch (mode.id) {
          case 1:
            return establishment;
          case 2:
            return fifo;
          case 3:
            return establishment === false && fifo;
          case 4:
            return !fifo;
          case 5:
            return establishment === false && fifo === false;
        }
      });
    }

    function filterByDepartment(department) {
      // 0 = All users / -1 = With no department
      if (!department || department.id === 0) {
        return;
      }
      if (department.id === -1) {
        self.filtered = $filter('filter')(
          self.filtered,
          (value, index, array) => {
            if (
              !value.profile.departments ||
              value.profile.departments.length === 0
            ) {
              return true;
            }
            return false;
          }
        );
      } else {
        self.filtered = $filter('filter')(
          self.filtered,
          (value, index, array) => {
            if (value.profile.departments.includes(department.id)) {
              return true;
            }
            return false;
          }
        );
      }
    }

    function sortFilteredUsers() {
      self.filtered = $filter('orderBy')(self.filtered, 'id');
    }

    this.getDefaultDepartment = function (currentUser, departments) {
      if (currentUser.isAdmin)
        return {id: 0, name: $filter('translate')('GENERAL_ALL_DEPARTMENTS')};
      return departments.find(
        department => department.id === currentUser.profile.departments[0]
      );
    };

    this.updateDepartmentsOptions = function (scope): Promise<[ExtendedUser, Department[]]> {
      return Promise.all([
        UserService.getProfile(), DepartmentService.getDepartments()
      ]).then(results => {
        let currentUser = results[0]
        let departments = results[1]
        scope.departmentOptions = []
        scope.departments = departments
        if (currentUser.isAdmin) {
          scope.departmentOptions = [
            {id: 0, name: $filter('translate')('GENERAL_ALL_DEPARTMENTS')},
            {id: -1, name: $filter('translate')('GENERAL_NO_DEPARTMENTS')}
          ]
        } else {
          scope.departments = scope.departments.filter(
            department => currentUser.profile.departments.includes(department.id)
          )
        }
        const options = angular.copy(scope.departments)
        scope.departmentOptions = scope.departmentOptions.concat(options)
        return [currentUser, departments]
      })
    }

    this.getFilteredUsers = function (name, mode, department) {
      updateUsers();
      resetFilter();
      filterByMode(mode);
      filterByName(name);
      filterByDepartment(department);
      sortFilteredUsers();
      return self.filtered;
    };
  }
  module.service('UserFilter', UserFilter);
})(angular.module('postCenterWebClientApp'));
