'use strict';

var app = angular.module('postCenterWebClientApp')
app.directive('newCustomData', function () {
  return {
    scope: {
      customData: '=',
      tab: '=',
      entity: '=',
    },
    link: function (scope, element, attrs) {
      scope.fixed = attrs.fixed;
    },
    templateUrl: 'blocks/custom_data/views/new_custom_data.html',
    restrict: 'E',
    controller: ['$scope', '$rootScope', 'Message', '$timeout', '$filter',
      function ($scope, $rootScope, Message, $timeout, $filter) {
      var translate = $filter('translate');
      var indexes = _.indexBy($scope.customData.options, 'index');
      var temporalKeyValue = 1;
      var temporalKeyString = "temp_opt";
      $scope.types = {};
      $scope.types[translate('CUSTOMDATATYPE_TEXT')] = 'text';
      $scope.types[translate('CUSTOMDATATYPE_TEXTAREA')] = 'textarea';
      $scope.types[translate('CUSTOMDATATYPE_CHECKBOX')] = 'checkbox';
      $scope.types[translate('CUSTOMDATATYPE_RADIOBUTTON')] = 'radiobutton';
      $scope.types[translate('CUSTOMDATATYPE_SELECT')] = 'select';
      $scope.types[translate('CUSTOMDATATYPE_BOOLEAN')] = 'boolean';
      $scope.types[translate('CUSTOMDATATYPE_DATETIME')] = 'datetime';

      $scope.hasIntegration = $rootScope.establishment.config.custom_data_integration;

      if($scope.customData.options.length > 0){
        $scope.hasCustomDataOptions = true;
      }

      $scope.establishment = $rootScope.establishment;

      function watchExpression(scope){
        return {
          index: scope.customData.index,
          label: scope.customData.label,
          type: scope.customData.type,
          options: scope.customData.options,
          visible: scope.customData.visible,
          deleted: scope.customData.deleted,
          placeholder: scope.customData.placeholder,
          required: scope.customData.required,
          required_to_close: scope.customData.required_to_close,
          key_crm: scope.customData.key_crm,
          help: scope.customData.help,
          reset: scope.customData.reset,
          is_integrable: scope.customData.is_integrable,
          integration_key: scope.customData.integration_key,
          only_read: scope.customData.only_read
        };
      }

      $scope.$watch(watchExpression, function(newValue, oldValue) {

        if(newValue !== oldValue){

          if (newValue.only_read != oldValue.only_read) {
            if (newValue.only_read == true) {
              $scope.customData.required = false;
              $scope.customData.required_to_close = false;
            }
          }

          if (newValue.is_integrable != oldValue.is_integrable) {
            if (newValue.is_integrable == false) {
              $scope.customData.only_read = false;
            }
          }

          if(newValue.reset || oldValue.reset) {
            $scope.customData.reset = false;
          } else {
            $scope.customData.changed = true;
            $scope.tab.extra.changed = true;
          }

        }

        $scope.checkTagRequired();

      }, true);

      $scope.checkTagRequired = function(){

        if (
          $scope.customData.type !== 'text' &&
          $scope.customData.type !== 'textarea' &&
          $scope.customData.type !== 'list' &&
          $scope.customData.type !== 'boolean' &&
          $scope.customData.type !== 'datetime' &&
          $scope.customData.type !== undefined
        ){

          $scope.showOptions = true;

          $scope.hasCustomDataOptions = _.some(
            $scope.customData.options,
            function(option){
              return !option.deleted;
            }
          );
        } else {
          $scope.showOptions = false;
        }
      };

      $scope.up = function(index){
        var tmp = indexes[index-1];
        var actual = indexes[index];

        tmp.index = tmp.index + 1;
        actual.index = actual.index - 1;

        indexes[index - 1] = indexes[index];
        indexes[index] = tmp;
      };

      $scope.down = function(index){
        var tmp = indexes[index+1];
        var actual = indexes[index];

        tmp.index = tmp.index - 1;
        actual.index = actual.index + 1;

        indexes[index + 1] = indexes[index];
        indexes[index] = tmp;
      };

      $scope.addOption = function(){
        var newOption = {
          index:$scope.customData.options.length+1,
          deleted: false,
          custom_data_id: null,
        };
        indexes[newOption.index] = newOption;
        $scope.customData.options.push(newOption);
        refreshOptionExtras();
      };

      $scope.deleteOption = function(index){
        var option = null;
        for(var i=0; i < $scope.customData.options.length; i++){
          if($scope.customData.options[i].index === index){
            option = $scope.customData.options.splice(i, 1)[0];
            break;
          }
        }
        angular.forEach($scope.customData.options, function(option){
          if(option.index > index){
            delete index[option.index];
            option.index -= 1;
            indexes[option.index] = option;
          }
        });
        $scope.removeNesting(option);
        refreshOptionExtras();
      };

      $scope.handleEnterOnOptionInput = function($event){
        if($event.keyCode === 13){
          $scope.addOption();
          $timeout(function(){
            angular.element("new-custom-data input.input-metadata-option")
              .last()
              .focus();
          });
          $event.stopPropagation();
          $event.preventDefault();
        }
      }

      // NESTED CUSTOM DATA

      var activeCDataById = _.indexBy($scope.tab.activeCustomData, 'resource_id');

      function refreshOptionExtras() {
        $scope.optionExtras = {};
        _.each($scope.customData.options, function (option) {
          if (option.key === undefined || option.key === null) {
            option.key = getTemporalKey();
          }
          if (option.custom_data_id === undefined ||
            option.custom_data_id === null) {
            $scope.optionExtras[option.key] = {
              editMode: false,
              nestedCustomData: undefined,
              selectedNestable: undefined,
            }
          } else {
            $scope.optionExtras[option.key] = {
              editMode: false,
              nestedCustomData: activeCDataById[option.custom_data_id],
              selectedNestable: option.custom_data_id,
            }
          }
        });
      }

      function findAncestry(zeroLevelCustomDataList) {

        var ancestry = new Set();
        _.each(zeroLevelCustomDataList, collectAncestry);
        return ancestry;

        function collectAncestry(parent) {
          if (parent.resource_id === $scope.customData.resource_id) {
            ancestry.add(parent.resource_id);
            return true;
          }
          if (parent.type === 'select') {
            var isAncestor = false;
            (parent.options || []).forEach(function (option) {
              var child = activeCDataById[option.custom_data_id];
              if (child != null && collectAncestry(child)) {
                ancestry.add(parent.resource_id);
                isAncestor = true;
              }
            });
            return isAncestor;
          }
          return false;
        };
      }

      function getTemporalKey() {
        /*
        The string option is hardcode in backend for check keys.
        Is a fix, must to change the solution with the new implementation.
        this is only for now, please not mantain RG.

        the problem is we use the options in the view based in the option is 
        created but that is not true XD, we must assign an unique key to 
        every option so in the backend we check and assing the true key value
        */
        temporalKeyValue = temporalKeyValue +1;
        return temporalKeyString + temporalKeyValue
      }

      function refreshNestableCustomDataList() {
        var activeList = $scope.tab.activeCustomData;
        var nestableList = activeList.filter(function (customData) {
          return customData.level <= 0
            && customData.resource_id !== $scope.customData.resource_id;
        });
        if ($scope.customData.level > 0) {
          var ancestry = findAncestry(nestableList);
          nestableList = nestableList.filter(function (customData) {
            return !ancestry.has(customData.resource_id);
          });
        }
        $scope.nestableCustomDataList = nestableList;
      }

      refreshOptionExtras();
      refreshNestableCustomDataList();

      $scope.toggleNestingEditMode = function (option) {
        var optionExtra = $scope.optionExtras[option.key];
        optionExtra.editMode = !optionExtra.editMode;
      }

      function setCustomDataLevel(customData, level) {
        customData.level = level;
        customData.changed = true;
      }

      function distributeCustomDataLevel(customData, level) {
        if (customData != null) {
          setCustomDataLevel(customData, level);
          _.each(customData.options, function (option) {
            if (option.custom_data_id !== undefined){
              var child = activeCDataById[option.custom_data_id]
              distributeCustomDataLevel(child, level +1)
            }
          })
        }
      }

      $scope.acceptNesting = function (option) {
        var extra = $scope.optionExtras[option.key];
        var selected = activeCDataById[extra.selectedNestable];
        if (selected != null) {
          option.custom_data_id = selected.resource_id;
          distributeCustomDataLevel(extra.nestedCustomData, 0)
          extra.nestedCustomData = selected;
          distributeCustomDataLevel(selected, $scope.customData.level + 1)
        }
        $scope.toggleNestingEditMode(option);
        refreshNestableCustomDataList();
        $scope.$emit('customData:acceptNesting', $scope.customData, option);
      }

      $scope.removeNesting = function (option) {
        var childId = option.custom_data_id;
        var optionExtra = $scope.optionExtras[option.key];
        if (optionExtra.nestedCustomData !== null &&
          optionExtra.nestedCustomData !== undefined ) {
          setCustomDataLevel(optionExtra.nestedCustomData, 0);
        }
        optionExtra.nestedCustomData = undefined;
        optionExtra.selectedNestable = undefined;
        option.custom_data_id = undefined;
        optionExtra.editMode = !optionExtra.editMode;
        refreshNestableCustomDataList();
        $scope.$emit('customData:removeNesting', $scope.customData, childId);
      }

    }]
  };
}).directive('newCustomDataForm', function(){
  return {
    require: 'form',
    link: function(scope, elem, attrs, formCtrl){
      formCtrl.getElement = function(){
        return elem;
      };
      formCtrl.customData = scope.customData;
    },
  };
});
