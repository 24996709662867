(function (module) {
  'use strict';

  function caseOldCaseCtrl(
    $scope,
    CaseService,
    ColumnsService,
    AppStateService: AppStateService,
    CaseDetailsService,
    UserService: UserService,
    AdNotification
  ) {
    const vm = this;
    vm.loading = true;
    vm.cases = [];
    $scope.users = [];
    vm.$onInit = () => {
      init();
    };
    const init = () => {
      $scope.hasPerson = false;
      $scope.selection = {
        selectedUser: undefined
      };
      const localUser = CaseDetailsService.get('userObject');
      const {name, sn, resource_id: id} = vm.userObject || localUser;
      const user = {
        name,
        sn,
        id
      };
      $scope.users = [user];
      $scope.selection.selectedUser = user;
      setOldCases(id);
      UserService.getProfile().then(user => {
        $scope.canEditColumns = user.profile.enable_edit_columns
      })
    };
    const caseUpdateListenerOff = $scope.$on('case:hasBeenUpdated', (_event, caseObject) => {
      vm.caseObject = caseObject;
      init();
    });
    const userUpdateListenerOff =$scope.$on('userCase:hasBeenUpdated', (_event, userCase) => {
      vm.userObject = userCase;
      init();
    });

    $scope.$on('$destroy', () => {
      caseUpdateListenerOff();
      userUpdateListenerOff();
    });

    $scope.changeSelectedUser = function () {
      setOldCases($scope.selection.selectedUser.id);
    };

    function setOldCases(userId) {
      vm.loading = true;
      vm.cases = [];

      CaseService.getCaseResume(userId).then(
        casesObject => {
          vm.cases = casesObject;
          vm.loading = false;
        },
        () => {
          vm.loading = false;
          // TODO: Should remove the case from the
          // user because the case doesn't not exist
        }
      );
    }
    function formatColumnData(user) {
      const {sn, id, uname, text: _text = undefined} = user;
      let name = sn === 'twitter' ? '@' : '';
      name = !_text ? `${name}${uname}` : `${name}${_text}`;

      const data = {
        author: {
          id,
          sn
        },
        name,
        column_type: 'case'
      };
      return data;
    }

    $scope.createUserColumn = function () {
      const data = formatColumnData($scope.selection.selectedUser);

      ColumnsService.postColumn(data).then(
        column => {
          $scope.$emit('addColumn', column)
          AppStateService.goToInbox()
        },
        data => {
          AdNotification.error(data, 'post_column');
        }
      );
    };

    $scope.isCurrentCase = function (ticket) {
      if (!ticket || !$scope.currentCase) {
        return false;
      }
      return ticket['resource_id'] === $scope.currentCase['resource_id'];
    };
  }
  module.controller('caseOldCaseCtrl', caseOldCaseCtrl);
})(angular.module('postCenterWebClientApp'));
