const EpaAvailableAccounts = Vue.component('EpaAvailableAccounts', {
  props: {
    activeAccounts: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...Vuex.mapGetters('epaConfig', [
      'enabledAccounts',
      'surveys',
      'editingSurvey',
      'usedAccounts'
    ]),
    ...Vuex.mapGetters('socialAccounts', ['accounts']),
    checkAccounts() {
      const accountList = this.accounts
        .filter(account =>
          this.enabledAccounts.includes(account.social_network)
        )
        .sort((a, b) => {
          if (a.social_network > b.social_network) {
            return 1;
          } else if (a.social_network < b.social_network) {
            return -1;
          }
          return 0;
        })
        .map(account => {
          const {
            avatar_url: avatar, visual_name: name, id, social_network
          } = account;
          const active = this.activeAccounts.includes(id);
          const disabled = this.usedAccounts.includes(id) && !active;
          return {
            avatar,
            name,
            id,
            active,
            disabled,
            social_network
          };
        });
      return accountList;
    }
  },
  methods: {
    ...Vuex.mapActions('epaConfig', ['updateAccounts']),
    disabledClass(disabled) {
      return disabled ? 'disabled' : '';
    },
    snIconClass(sn: string): string {
      let social_network = sn.toLowerCase();
      switch(social_network) {
        case 'chat':
          social_network = 'postcenter';
          break;
        case 'external':
          social_network = 'external-account';
          break;
        default:
          social_network = sn.toLowerCase();
          break;
      }
      return `fa-${social_network}`;
    }
  },
  template: `<ul class="accounts-list d-flex flex-wrap">
  <li
    v-for="account in checkAccounts"
    :key="account.id"
    :class="disabledClass(account.disabled)" >
    <tippy :to="account.name" placement="top" v-if="account.disabled" arrow>
        {{ $t('tooltips.usedAccount') }}
    </tippy>
    <Checkbox
      v-model="account.active"
      @change="updateAccounts(account.id)"
      :disabled="account.disabled">
      <span :name="account.name">
        <img class="ads-circle" :src="account.avatar" :alt="account.name" width="20" height="20" />
        {{ account.name }} <span :class="['icon', 'fa', snIconClass(account.social_network)]" />
      </span>
    </Checkbox>
  </li>
</ul>`
});
