'use strict';

var app = angular.module('postCenterWebClientApp');
app.component('adSnTitle', {
  bindings: {
    adSn: '@',
    adClass: '@',
  },
  controller: adSnTitleCtrl,
  templateUrl: 'blocks/global/views/ad_sn_title.html'
});

adSnTitleCtrl.$inject = ['AdAvailableSn', '$scope'];

function adSnTitleCtrl(AdAvailableSn, $scope) {

  var self = this;
  $scope.adSn = this.adSn;
  $scope.adClass = this.adClass;
  $scope.adTitle = AdAvailableSn.getTitle($scope.adSn);

}
