class NotificationsService {
  constructor(AdRequest) {
    this.AdRequest = AdRequest;
    // to fix polling scope
    this.important = () =>
      this.AdRequest.get('/api/v1/notifications/important/');
    this.unread = () => this.AdRequest.get('/api/v1/notifications/unread/');
  }
  get() {
    return this.AdRequest.get('/api/v1/notifications/');
  }
  new(limit) {
    return this.AdRequest.get(`/api/v1/notifications/new/${limit}/`);
  }
  getDelayed() {
    return this.AdRequest.get('/api/v1/user/notifications/delayed/');
  }
  setDelayed(config) {
    return this.AdRequest.post('/api/v1/user/notifications/delayed/', config);
  }
  readSelected(data) {
    return this.AdRequest.patch('/api/v1/notifications/', data);
  }
}
angular
  .module('postCenterWebClientApp')
  .service('NotificationsService', NotificationsService);
