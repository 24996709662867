'use strict';

angular.module('postCenterWebClientApp').factory('AnalyticsConfigs', [
  '$filter',
  'AnalyticsHelper',
  function($filter, AnalyticsHelper) {
    function toTitleCase(str) {
      return str.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    }

    var AC = {
      simplePieConfig: function(widget, widgetChartConfig, chartReference) {
        return {
          credits: false,
          title: null,
          chart: {
            spacingTop: 10,
            spacingBottom: 10,
            spacingLeft: 10,
            spacingRight: 10,
            events: {
              load: function() {
                chartReference.api = this;
              }
            },
            backgroundColor: 'transparent',
            type: 'pie',
            height: 220
          },
          tooltip: {
            pie: {
              enabled: true,
              formatter: function() {
                return (
                  '<b>' +
                  toTitleCase(this.point.name) +
                  '</b>: ' +
                  this.percentage.toFixed(2) +
                  ' %'
                );
              }
            }
          },
          plotOptions: {
            pie: {
              borderColor: 'rgba(255,255,255,0.5)',
              slicedOffset: 0,
              allowPointSelect: true,
              size: '100%',
              borderWidth: 0,
              cursor: 'pointer',
              showInLegend: true,
              dataLabels: {enabled: false}
            }
          },
          xAxis: {
            pie: {}
          },
          yAxis: {
            pie: {}
          },
          series: [{data: []}]
        };
      },

      simpleColumnConfig: function(widget, widgetChartConfig, chartReference) {
        return {
          credits: false,
          title: null,
          chart: {
            spacingTop: 10,
            spacingBottom: 10,
            spacingLeft: 10,
            spacingRight: 10,
            events: {
              load: function() {
                chartReference.api = this;
              }
            },
            backgroundColor: 'transparent',
            type: 'column',
            height: 220
          },
          tooltip: {
            column: {
              enabled: true,
              formatter: function() {
                var valueType = widget.dataScheme.type;
                var value = AnalyticsHelper.formatValue(
                  valueType,
                  this.point.y
                );
                return (
                  '<b>' +
                  toTitleCase(this.series.name) +
                  '</b><br><br><b>' +
                  value +
                  '</b>'
                );
              }
            }
          },
          plotOptions: {
            column: {}
          },
          xAxis: {
            column: {
              labels: {enabled: false}
            }
          },
          yAxis: {
            column: {
              labels: {
                formatter: function() {
                  var valueType = widget.dataScheme.type;
                  return AnalyticsHelper.formatValue(valueType, this.value);
                }
              }
            }
          },
          series: [{data: []}]
        };
      },

      simpleAreasplineConfig: function(
        widget,
        widgetChartConfig,
        chartReference
      ) {
        var height = widget.type === 'bubble-chart' ? 253 : 200;
        var spacingTop = widget.type === 'bubble-chart' ? 30 : 10;
        var spacingRight = widget.type === 'bubble-chart' ? 20 : 10;
        var config = {
          credits: false,
          title: null,
          chart: {
            spacingTop: spacingTop,
            spacingBottom: 20,
            spacingLeft: 10,
            spacingRight: spacingRight,
            events: {},
            backgroundColor: 'transparent',
            type: 'areaspline',
            height: height
          },
          tooltip: {
            enabled: true,
            formatter: function() {
              var valueType = widget.dataScheme.type;
              var value = AnalyticsHelper.formatValue(valueType, this.point.y);
              var dateFormat = 'DD/MM/YYYY - HH:mm';
              if (widget.period && widget.period.granularity === 'd') {
                dateFormat = 'DD/MM/YYYY';
              } else if (widget.period && widget.period.granularity === 'mo') {
                dateFormat = 'MM/YYYY';
              }
              var date = $filter('amDateFormat')(this.point.x, dateFormat);
              return (
                '<b>' +
                toTitleCase(this.series.name) +
                '</b><br>' +
                date +
                '<br><b>' +
                value +
                '</b>'
              );
            }
          },
          plotOptions: {
            series: {
              lineWidth: 2,
              states: {
                hover: {
                  lineWidth: 1
                }
              },
              marker: {
                enabled: false,
                symbol: 'circle',
                radius: 0.3
              }
            },
            column: {
              negativeColor: 'transparent',
              borderColor: 'transparent'
            }
          },
          xAxis: [
            {
              title: {text: ''},
              type: 'datetime',
              id: 'x-axis'
            }
          ],
          yAxis: [
            {
              id: 'y-axis',
              min: 0,
              minRange: 0,
              title: {text: AC.getTitle(widget)},
              labels: {
                formatter: function() {
                  var valueType = widget.dataScheme.type;
                  return AnalyticsHelper.formatValue(valueType, this.value);
                }
              }
            }
          ],
          legend: {
            enabled: true,
            maxHeight: 48
          },
          series: []
        };

        if (widgetChartConfig.stacked) {
          var areaspline = {stacking: 'normal'};
          config.plotOptions.areaspline = areaspline;
        }
        return config;
      },

      customdataColumnConfig: function(
        widget,
        widgetChartConfig,
        chartReference
      ) {
        return {
          credits: false,
          title: null,
          chart: {
            spacingTop: 10,
            spacingBottom: 20,
            spacingLeft: 10,
            spacingRight: 10,
            events: {
              load: function() {
                chartReference.api = this;
              }
            },
            backgroundColor: 'transparent',
            type: 'column',
            height: 200
          },
          tooltip: {
            enabled: true,
            formatter: function() {
              var valueType = widget.dataScheme.type;
              var value = AnalyticsHelper.formatValue(valueType, this.point.y);
              return (
                '<b>' +
                toTitleCase(this.series.name) +
                '</b><br> ' +
                this.point.name +
                ' <br><b>' +
                value +
                '</b>'
              );
            }
          },
          legend: {
            enabled: false
          },
          plotOptions: {
            series: {
              borderWidth: 0
            }
          },
          yAxis: {
            min: 0,
            minRange: 0,
            title: {text: AC.getTitle(widget)},
            labels: {
              formatter: function() {
                var valueType = widget.dataScheme.type;
                return AnalyticsHelper.formatValue(valueType, this.value);
              }
            }
          },
          xAxis: {
            type: 'category'
          },
          series: [{data: []}]
        };
      },

      customdataColumnOnlineStateConfig: function(
        widget,
        widgetChartConfig,
        chartReference
      ) {
        return {
          credits: false,
          title: null,
          chart: {
            spacingTop: 10,
            spacingBottom: 20,
            spacingLeft: 10,
            spacingRight: 10,
            events: {
              load: function() {
                chartReference.api = this;
              }
            },
            backgroundColor: 'transparent',
            type: 'column',
            height: 200
          },
          tooltip: {
            enabled: true,
            formatter: function() {
              var valueType = widget.dataScheme.type;
              var value = AnalyticsHelper.formatValue(valueType, this.point.y);
              return (
                '<b>' +
                toTitleCase(this.series.name) +
                '</b><br><b>' +
                value +
                '</b>'
              );
            }
          },
          legend: {
            enabled: true,
            maxHeight: 48
          },
          plotOptions: {
            series: {
              borderWidth: 0
            }
          },
          xAxis: {
            labels: {
              formatter: function() {
                return '';
              }
            }
          },
          yAxis: {
            min: 0,
            minRange: 0,
            title: {text: AC.getTitle(widget)},
            labels: {
              formatter: function() {
                var valueType = widget.dataScheme.type;
                return AnalyticsHelper.formatValue(valueType, this.value);
              }
            }
          },
          series: [{data: []}]
        };
      },

      getChartConfig: function(widget, widgetChartConfig, chartReference) {
        switch (widgetChartConfig.type) {
          case 'pie':
            return AC.simplePieConfig(
              widget,
              widgetChartConfig,
              chartReference
            );
          case 'column':
            return AC.simpleColumnConfig(
              widget,
              widgetChartConfig,
              chartReference
            );
          case 'customdata-column':
            return AC.customdataColumnConfig(
              widget,
              widgetChartConfig,
              chartReference
            );
          case 'areaspline':
            return AC.simpleAreasplineConfig(
              widget,
              widgetChartConfig,
              chartReference
            );
          case 'onlinestate-column':
            return AC.customdataColumnOnlineStateConfig(
              widget,
              widgetChartConfig,
              chartReference
            );
          default:
            return {};
        }
      },

      getTitle: function(widget) {
        return $filter('translate')(widget.title);
      }
    };

    return AC;
  }
]);
