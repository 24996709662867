function isPendingConnection(networks){
  return networks.length > 0 &&
      !networks.every(network => network.connected === 'connected');
}

function isTrialNearExpiration({plan}){
  const THRESHOLD_TRIAL_SPLASH_DAYS = 7;

  if(plan.trial_days_remaining == null)
      return false;

  if(plan.trial_days_remaining <= THRESHOLD_TRIAL_SPLASH_DAYS)
      return true;

  return false;
}

function establishmentHasPartner(establishmentConfig) {
  if (!establishmentConfig) return false;
  if (!establishmentConfig.partner) return false;
  return true;
}

function partnerHasOnboardingActive(establishmentConfig) {
  if (establishmentHasPartner(establishmentConfig))
    return establishmentConfig.partner.onboarding_active;
  return true; // onboarding is active by default for clients without partner
}

class AuthApplicationCtrl {
  $rootScope
  $http
  $state
  AdRequest: AdRequestService
  WorkflowStatuses
  adChatService
  AuthService: AuthService
  UserService: UserService
  EstablishmentService: EstablishmentService

  private setRootScopeBaseConfig() {
    this.$rootScope.chatServiceInit = false;
    this.$rootScope.adminTab = {
      selected: ''
    };
    this.$rootScope.analyticTab = {
      selected: ''
    };
    this.$rootScope.settingsTab = {
      selected: ''
    };
    this.$rootScope.columnTypes = [
      {
        type: 'case',
        name: 'Cases'
      },
      {
        type: 'message',
        name: 'Messages'
      }
    ];
    this.$rootScope.alerts = [];
    this.$rootScope.loadedCustomData = false;
    this.$rootScope.loadedNotificationGroups = false;
    this.$rootScope.loadedNotificationSearch = false;
    this.$rootScope.loadedNotificationUsers = false;
  }

  private resetRootScope() {
    const propsToReset = [
      'user',
      'users',
      'accounts',
      'establishment',
      'communityManagers',
      'alerts',
      'groups',
      'adminTab',
      'analyticTab',
      'settingsTab',
      'columnTypes',
      'communityManagersAndBot',
      'communityManagersDisabled',
      'communityManagersForChart',
      'bots',
      'cannedResponse',
      'customData',
      'departments',
      'loadedCustomData',
      'loadedNotificationGroups',
      'loadedNotificationSearch',
      'loadedNotificationUsers',
      'notificationEntitySearch',
      'notificationGroups',
      'notificationUsers',
    ];
    propsToReset.forEach(prop => delete this.$rootScope[prop]);
    this.setRootScopeBaseConfig();
  }

  private updateStatus() {
    firebase.auth().signOut()
    this.$rootScope.$emit('stopTracking')
    this.$state.go('login')
  }

  private onLogout() {
    this.adChatService.shutDown()
    this.WorkflowStatuses.clean()
    this.$rootScope.$emit('updateSla', 'cancel')
    this.resetRootScope()
    this.updateStatus()
  }

  constructor(
    $rootScope,
    $http,
    $state,
    AdRequest: AdRequestService,
    WorkflowStatuses,
    adChatService,
    AuthService: AuthService,
    UserService,
    EstablishmentService
  ) {
    this.$rootScope = $rootScope
    this.$http = $http
    this.$state = $state
    this.AdRequest = AdRequest
    this.WorkflowStatuses = WorkflowStatuses
    this.adChatService = adChatService
    this.UserService = UserService
    this.AuthService = AuthService
    this.EstablishmentService = EstablishmentService
    this.AdRequest.loggedOut.subscribe(() => this.onLogout())
  }

}

(module => {
  'use strict';
  module.directive('authApplication', authApplication);
  function authApplication(
    $rootScope,
    $http,
    $state,
    AdRequest,
    WorkflowStatuses,
    adChatService,
    AuthService,
    UserService,
    EstablishmentService
  ) {
    return {
      restrict: 'A',
      controller: $scope => new AuthApplicationCtrl(
        $rootScope,
        $http,
        $state,
        AdRequest,
        WorkflowStatuses,
        adChatService,
        AuthService,
        UserService,
        EstablishmentService
      )
    };
  }
})(angular.module('postCenterWebClientApp'));
