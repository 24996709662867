store.registerModule('teams', {
  namespaced: true,
  state: () => ({
    allTeams: []
  }),
  getters: {
    getAllTeams: state => state.allTeams
  },
  mutations: {
    SET_TEAMS(state, payload) {
      state.allTeams = payload;
    }
  },
  actions: {
    async getAllTeams({commit}) {
      try {
        const teams = await this.DepartmentService.getAllDepartments();
        commit('SET_TEAMS', teams);
        return teams;
      } catch (error) {
        throw new Error('error with our api');
      }
    }
  }
});
