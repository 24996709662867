(function(module) {
  'use strict';
  caseHistoryCtrl.$inject = ['$scope', 'CaseService', 'adDateFormatter', '$uibModal'];
  function caseHistoryCtrl($scope, CaseService, adDateFormatter, $uibModal) {
    const vm = this;

    vm.$onInit = () => {
      vm.events = [];
      vm.filtered = [];
      $scope.filterHistory = 'all';
      $scope.filterOptions = [
        {
          label: 'CASE_HISTORY_FILTER_ALL',
          value: 'all'
        },
        {
          label: 'CASE_HISTORY_FILTER_ASSIGNATION',
          value: 'assignation'
        },
        {
          label: 'CASE_HISTORY_FILTER_ESCALATION',
          value: 'escalation'
        },
        {
          label: 'CASE_HISTORY_FILTER_TYPIFIED',
          value: 'typified'
        },
        {
          label: 'CASE_HISTORY_FILTER_STATE',
          value: 'state'
        }
      ];
      _getCaseHistory(vm.caseObject.resource_id);
    }; //end onInit

    $scope.parseToLocale = date => adDateFormatter.parseToLocale(date);
    const caseUpdateListenerOff = $scope.$on('case:hasBeenUpdated', (_event, caseObject) => {
      vm.caseObject = caseObject;
      vm.events = [];
      vm.filtered = [];
      const {resource_id} = caseObject;
      _getCaseHistory(resource_id);
    });

    $scope.$on('$destroy', () => {
      caseUpdateListenerOff();
    });

    const _getCaseHistory = caseId => {
      vm.loading = true;
      CaseService.getCaseHistory(caseId).then(
        events => {
          events.forEach(event => {
            event.created = adDateFormatter.publicationDateLong(event.created);
            event.view_detail = false;
            event.has_detail = false;
            if (
              event.kind === 'escalation' ||
              event.kind === 'no-auto' ||
              event.kind === 'no-auto-department' ||
              event.kind === 'ESCALATION_EVENT'
            ) {
              // hackish but I've got to finish this!
              event.has_detail = !(event.extra_info || {}).disabled_account_id;
            }
            vm.events.push(event);
            vm.filtered.push(event);
          });
          vm.loading = false;
        },
        () => {
          vm.loading = false;
        }
      );
    };

    $scope.toggleExtraData = function toggleExtraData(index) {
      const event = vm.events[index];
      vm.events[index].view_detail = !event.view_detail;
    };

    vm.toggleShowCos = function(cm) {
      cm.show_cos = !cm.show_cos;
    };

    $scope.filterEvents = () => {
      if ($scope.filterHistory === 'all') {
        return (vm.filtered = vm.events);
      } else {
        vm.filtered = vm.events.filter(event =>
          event.kind.toLowerCase().includes($scope.filterHistory)
        );
      }
    };

    vm.openHelpModal = () => {
      $uibModal.open({
        templateUrl: 'blocks/case/views/case_info_modal.html',
        size: 'md',
        controller: ['$scope', '$uibModalInstance', '$filter', modalController]
      });

      function modalController($scope, $uibModalInstance, $filter) {
        $scope.title = 'CASE_HISTORY_HELP_MODAL_TITLE';
        $scope.helpText = $filter('translate')(
          'CASE_HISTORY_HELP_MODAL_CONTENT'
        );
        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };
      }
    };
  }
  module.controller('caseHistoryCtrl', caseHistoryCtrl);
})(angular.module('postCenterWebClientApp'));
