'use strict';

angular
  .module('postCenterWebClientApp')
  .controller('AnalyticsCustomDashboardCtrl', AnalyticsCustomDashboardCtrl);

AnalyticsCustomDashboardCtrl.$inject = [
  '$rootScope',
  '$scope',
  '$filter',
  '$interval',
  '$timeout',
  'ChartService',
  'CustomDashboardService',
  'AdAvailableSn',
  'FireTagService'
];

function AnalyticsCustomDashboardCtrl(
  $rootScope,
  $scope,
  $filter,
  $interval,
  $timeout,
  ChartService,
  CustomDashboardService,
  AdAvailableSn,
  FireTagService
) {
  FireTagService.setPageView({
    title: 'Monitoreo',
    path: `/${window.location.hash}`
  });
  $rootScope.analyticTab.selected = 'custom-dashboard';
  let translate = $filter('translate');

  setBoxes();

  $scope.$on('$destroy', function() {
    $interval.cancel($scope.polling);
  });

  $scope.onPeriodUpdate = function(period) {
    $scope.selectedPeriod = {
      account: null,
      inBusinessTime: false,
      now: period.endGetter(),
      until: period.startGetter(),
      filterByCreated: true
    };
    const diff = $scope.selectedPeriod.now - $scope.selectedPeriod.until;
    $scope.selectedPeriod.duration = moment.duration(diff);
    refreshKPIs();
  };

  const secondsToStartPolling = 10;
  $timeout(function() {
    $scope.polling = true;
    turnOnPolling();
  }, secondsToStartPolling * 1000);

  // Realtime Functions
  function turnOnPolling() {
    const secondsToRefresh = 45;
    if ($scope.polling !== null) {
      $scope.polling = $interval(function() {
        refreshKPIs();
      }, secondsToRefresh * 1000);
    }
  }

  function refreshKPIs() {
    const query = $scope.selectedPeriod;
    const dashboardContext = {
      total: 0,
      notClosed: 0
    };

    let callBack = CustomDashboardService.getCreatedTickets(query);
    if (!$scope.selectedPeriod.filterByCreated) {
      callBack = CustomDashboardService.getUpdatedTickets(query);
    }

    callBack.then(function(response) {
      dashboardContext.total = response;
      loadBoxesData(query, dashboardContext);
    });
  }

  function statusGenerator(value, total) {
    const percent = value / total;
    if (percent > 0.5) {
      return 0;
    } else if (percent > 0.25) {
      return 1;
    } else {
      return 2;
    }
  }

  function themeSelector(status) {
    let theme = 'blue';
    if (status === 1) {
      theme = 'yellow';
    } else if (status === 2) {
      theme = 'red';
    }
    return theme;
  }

  function renderBarChart(values, name, box) {
    const labels = [];
    const data = [];
    Object.keys(values).forEach(function(key) {
      labels.push(key);
      data.push(values[key]);
    });
    let method = ChartService.setBarChart;
    let config = renderChart(name, labels, data, 'blue', method);
    setBoxStates(box, config, 0, '');
  }

  function renderTimeSeries(values, name, box) {
    const labels = values.labels;
    const points = values.points;
    let method = ChartService.setTimeSeriesChart;
    let config = renderChart(name, labels, points, 'blue', method);
    setBoxStates(box, config, 0, '');
  }

  function renderDonut(values, name, box, status, keyMetric) {
    const labels = [];
    const dataValues = [];
    Object.keys(values).forEach(function(key) {
      labels.push(key);
      dataValues.push(values[key]);
    });
    let method = ChartService.setDonutChart;
    let theme = themeSelector(status);
    theme = theme + '_' + labels.length;
    let config = renderChart(name, labels, dataValues, theme, method);
    setBoxStates(box, config, status, keyMetric);
  }

  function drawChart(box, retries = 0) {
    // Hack: this helps to render the chart correctly using container width
    setTimeout(() => {
      try {
        const chart = box.chartConfig.getChartObj();
        chart.reflow();
      } catch {
        if(retries > 5)
          throw new Error(`Chart couldn't be initialized`)
        // Try again if the chart isn't available yet
        drawChart(box, retries++)
      }
    }, 100)
  }

  function setBoxStates(box, config, status, keyMetric, retries = 0) {
    box.state = status;
    box.loading = false;
    box.keyMetric = keyMetric;
    box.chartConfig = config;
    drawChart(box)
  }

  function renderChart(name, labels, data, theme, method) {
    return method(name, labels, data, theme);
  }

  function setBoxes() {
    // Configuration aproach:
    // ----------------------
    // Boxes try to follow the configuration approach. This is to define
    // configurable objects for general purposes and self contained.

    // loadData method:
    // ----------------
    // Here I want to build independant boxes, each one representing one KPI,
    // each metric with it's own configuraion.
    // To do so, each box has an "loadData" method that calls to:
    // let self = this
    // and then I use self to modify the object.

    // Optimizing requests:
    // --------------------
    // They also have data dependendant on one another. To overcome this,
    // I pass the dashboardContext so we can share data among boxes.
    // The last part is important because we want to optimize requests,
    // and make them completley stand alone will need to load two or three
    // times the same data from the backend, and at the moment I prefer to avoid
    // that.
    const headers = [];
    headers.push({
      text: translate('GENERAL_AGENT')
    });
    angular.forEach(AdAvailableSn.availableIcons(), function(icon) {
      headers.push({
        icon: icon
      });
    });

    headers.push({
      text: translate('GENERAL_TOTAL')
    });

    const boxes = [];
    const assignationBox = {
      title: translate('ANALYTICS_CUSTOM_DASHBOARD_TICKET_ASSIGNATION'),
      subtitle: translate('ANALYTICS_CUSTOM_DASHBOARD_TICKET_ASSIGNATION_SUB'),
      kind: 'table',
      table: {
        headers: headers
      },
      class: 'simple',
      loading: true,
      loadData: function() {
        const self = this;

        function toTitleCase(str) {
          return str.replace(/\w\S*/g, function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          });
        }

        function setTable(result) {
          self.table.rows = [];
          const rows = self.table.rows;
          let rowOrder = AdAvailableSn.availableSn();
          angular.forEach(result, function(element, key) {
            let total = 0;
            const row = {
              values: [
                {
                  value: toTitleCase(key)
                }
              ]
            };
            for (let i = 0; i < rowOrder.length; i++) {
              let rowValue = element[rowOrder[i]];
              if (rowValue == undefined) {
                rowValue = 0;
              }
              row.values.push({
                value: rowValue
              });
              total += rowValue;
            }
            row.values.push({
              value: total
            });
            rows.push(row);
          });
          self.loading = false;
        }

        CustomDashboardService.getTableAssignedCM($scope).then(setTable);
      }
    };
    boxes.push(assignationBox);

    const ticketStatusBox = {
      title: translate('ANALYTICS_CUSTOM_DASHBOARD_TICKET_STATUS'),
      subtitle: translate('ANALYTICS_CUSTOM_DASHBOARD_TICKET_STATUS_SUB'),
      kind: 'chart',
      chartId: 'closeopenchart',
      loading: true,
      class: 'simple',
      loadData: function() {
        const self = this;
        const query = this.params.query;
        const dashboardContext = this.params.dashboardContext;
        CustomDashboardService.getProgressVsClosed(query).then(function(
          result
        ) {
          const values = {};
          let total = 0;
          const closedKey = translate('closed');
          const openedKey = translate('opened');
          const ignoredKey = translate('ignored');
          const progressKey = translate('in-progress');
          const key = closedKey + ' + ' + ignoredKey;

          if ($rootScope.establishment.config['workflow_enabled']) {
            values[openedKey] = result[1];
            values[progressKey] = result[3];
            values[ignoredKey] = result[2];
            values[closedKey] = result[0];
            dashboardContext.notClosed =
              values[openedKey] + values[progressKey];
            total = result[0] + result[1] + result[2] + result[3];
          } else {
            values[openedKey] = result[1];
            values[ignoredKey] = result[2];
            values[closedKey] = result[0];
            dashboardContext.notClosed = values[openedKey];
            total = result[0] + result[1] + result[2];
          }

          const status = statusGenerator(
            total - dashboardContext.notClosed,
            total
          );
          renderDonut(values, 'closeopenchart', self, status, key);
        });
      }
    };
    boxes.push(ticketStatusBox);

    const queueBox = {
      title: translate('ANALYTICS_CUSTOM_DASHBOARD_TICKET_QUEUE'),
      subtitle: translate('ANALYTICS_CUSTOM_DASHBOARD_TICKET_QUEUE_SUB'),
      kind: 'chart',
      chartId: 'notansweredchart',
      loading: true,
      class: 'simple',
      loadData: function() {
        const self = this;
        const query = this.params.query;
        const dashboardContext = this.params.dashboardContext;
        CustomDashboardService.getWaitingTickets(query).then(function(result) {
          const values = {};
          const answeredKey = translate('answered');
          const waitingKey = translate('waiting');
          values[waitingKey] = result;
          values[answeredKey] = dashboardContext.notClosed - result;
          if (values[answeredKey] < 0) {
            values[answeredKey] = 0;
          }
          let status = statusGenerator(
            values[answeredKey],
            dashboardContext.notClosed
          );
          renderDonut(values, 'notansweredchart', self, status, answeredKey);
        });
      }
    };
    boxes.push(queueBox);

    const tipifiedBox = {
      title: translate(
        'ANALYTICS_CUSTOM_DASHBOARD_TICKET_TIPIFICATION_PERCENT'
      ),
      subtitle: translate(
        'ANALYTICS_CUSTOM_DASHBOARD_TICKET_TIPIFICATION_PERCENT_SUB'
      ),
      kind: 'chart',
      chartId: 'tipifiedchart',
      loading: true,
      class: 'simple',
      many: true,
      options: [
        {
          name: translate('GENERAL_ALL'),
          value: 0
        },
        {
          name: translate('GENERAL_ONLY_SOLVED'),
          value: 1
        }
      ],
      updateOption: function() {
        this.loadData();
      },
      loadData: function() {
        const self = this;
        const query = this.params.query;
        const dashboardContext = this.params.dashboardContext;
        let method = CustomDashboardService.getAllTipifiedTickets;
        if (this.selectedOption.value == 1) {
          method = CustomDashboardService.getSolvedTipifiedTickets;
        }
        method(query).then(function(result) {
          let total = dashboardContext.total;
          if (self.selectedOption.value == 1) {
            total = dashboardContext.total - dashboardContext.notClosed;
          }
          const values = {};
          const tipifiedKey = translate('tipified');
          const notTipifiedKey = translate('not-tipified');
          values[notTipifiedKey] = total - result;
          values[tipifiedKey] = result;
          const status = statusGenerator(result, dashboardContext.total);
          renderDonut(values, 'tipifiedchart', self, status, tipifiedKey);
        });
      }
    };
    tipifiedBox.selectedOption = tipifiedBox.options[0];
    boxes.push(tipifiedBox);

    const countryIdName = $rootScope.establishment.country['resident_id'];
    const countryIdBox = {
      title: translate('ANALYTICS_CUSTOM_DASHBOARD_USERS_COUNTRY_ID', {
        countryIdName: countryIdName
      }),
      subtitle: translate('ANALYTICS_CUSTOM_DASHBOARD_USERS_COUNTRY_ID_SUB', {
        countryIdName: countryIdName
      }),
      kind: 'chart',
      chartId: 'countryidchart',
      loading: true,
      class: 'simple',
      loadData: function() {
        let self = this;
        let query = this.params.query;
        CustomDashboardService.getIdentifiedClients(query).then(function(
          result
        ) {
          const values = {};
          const total = result[0];
          const withCountryId = result[1];
          const params = {
            countryId: countryIdName
          };
          const countryIdKey = $filter('translate')('with-country-id', params);
          const noCountryIdKey = $filter('translate')('no-country-id', params);
          values[noCountryIdKey] = total - withCountryId;
          values[countryIdKey] = withCountryId;
          const status = statusGenerator(withCountryId, total);
          renderDonut(values, 'countryidchart', self, status, countryIdKey);
        });
      }
    };
    boxes.push(countryIdBox);

    const newTicketsBox = {
      title: translate('ANALYTICS_CUSTOM_DASHBOARD_NEW_TICKETS'),
      subtitle: translate('ANALYTICS_CUSTOM_DASHBOARD_NEW_TICKETS_SUB'),
      kind: 'chart',
      chartId: 'newticketschart',
      loading: true,
      class: 'simple',
      loadData: function() {
        let self = this;
        let query = this.params.query;
        CustomDashboardService.getNewTicketsChart(query).then(function(result) {
          let values = result.data;
          renderTimeSeries(values, 'newticketschart', self);
        });
      }
    };
    boxes.push(newTicketsBox);

    const tipificationBox = {
      title: translate(
        'ANALYTICS_CUSTOM_DASHBOARD_TICKET_TIPIFICATION_DISTRIBUTION'
      ),
      subtitle: translate(
        'ANALYTICS_CUSTOM_DASHBOARD_TICKET_TIPIFICATION_DISTRIBUTION_SUB'
      ),
      kind: 'chart',
      chartId: 'tipifiedchart',
      loading: true,
      class: 'double',
      many: true,
      options: [],
      updateOption: function() {
        let values = {};
        let selectedOption = this.selectedOption;
        if (selectedOption) {
          renderBarChart(selectedOption.values, 'tipifiedsecondchart', this);
        }
      },
      loadData: function() {
        let self = this;
        let query = this.params.query;
        CustomDashboardService.getTipifiedTicketsDistribution(query).then(
          function(result) {
            function parseCustomDataSet(customDataStats) {
              let data = customDataStats.result.data.detail.typ;
              let options = customDataStats.customData.options;
              let values = {};
              angular.forEach(data, function(customDataStats, key) {
                for (let i = 0; i < options.length; i++) {
                  if (options[i].key === key && !options[i].deleted) {
                    key = options[i].value;
                    values[key] = customDataStats.overall;
                  }
                }
                if (options.length === 0){
                  values[key] = customDataStats.overall;
                }
              });
              return values;
            }

            let values = {};
            result = _.filter(result, function(element) {
              return !element.customData.deleted;
            });
            angular.forEach(result, function(customDataStats) {
              let key = customDataStats.customData.label;
              customDataStats.customData.options.forEach(obj => {
                if (obj.deleted){
                  if (customDataStats.result.data.detail.typ[obj['key']] !== undefined) {
                    let totalDeleted = customDataStats.result.data.detail.typ[obj['key']].overall;
                    customDataStats.result.data.overall -= totalDeleted;
                  }
                }
              });
              values[key] = customDataStats.result.data.overall
              let chart = _.findWhere(self.options, {
                name: key
              });
              if (chart === undefined) {
                chart = {
                  name: key,
                  values: parseCustomDataSet(customDataStats)
                };
                self.options.push(chart);
              } else {
                chart.values = parseCustomDataSet(customDataStats);
              }
            });

            let name = translate('GENERAL_GENERAL');
            let firstChart = _.findWhere(self.options, {
              name: name
            });
            if (firstChart === undefined) {
              firstChart = {
                name: name,
                values: values
              };
              self.options.unshift(firstChart);
            } else {
              firstChart.values = values;
            }

            if (
              self.selectedOption === undefined ||
              self.selectedOption === null
            ) {
              self.selectedOption = self.options[0];
            }
            self.updateOption();
          }
        );
      }
    };
    boxes.push(tipificationBox);
    angular.forEach(boxes, function(box) {
      box.toggleSize = function() {
        if (this.class === 'double') {
          this.class = 'simple';
        } else {
          this.class = 'double';
        }
        drawChart(box)
      };
    });
    $scope.boxes = boxes;
  }

  function loadBoxesData(query, dashboardContext) {
    angular.forEach($scope.boxes, function(box) {
      // This makes dependency injection before loading.
      // Usefull to have calling params from inside the object.
      // And calling self.loadData.
      box.params = {
        query: query,
        dashboardContext: dashboardContext
      };
      box.loadData();
    });
  }
}
