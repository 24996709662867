const EpaBotScreen = Vue.component('EpaBotScreen', {
  components: {
    draggable: vuedraggable
  },
  computed: {
    ...Vuex.mapGetters('epaConfig', ['creatingSurvey']),
    ...Vuex.mapGetters('socialAccounts', ['accountsLength']),

    isReady() {
      return this.accountsLength > 0;
    },
  },
  data() {
    return {
      satisfactionModuleEnabled: false
    };
  },
  async created() {
    try {
      const isEnabled = await this.isSatisfactionModuleEnabled();
      this.satisfactionModuleEnabled = isEnabled;
    } catch (error) {
      console.error('Error checking satisfaction module:', error);
      this.satisfactionModuleEnabled = false;
    }
  },
  methods: {
    ...Vuex.mapActions('epaConfig', ['isSatisfactionModuleEnabled'])
  },
  i18n: {
    messages: {
      es: {
        title: 'Satisfacción'
      },
      pt: {
        title: 'Satisfação'
      },
      en: {
        title: 'Satisfaction'
      }
    }
  },
  template: `<section class="epa-main">
              <SectionHeader
                :title="$t('title')"
                icon="grin-stars"
                support-section="administrador-/8eb5QjUUPugzFCKxNAwGQB/satisfacci%C3%B3n-epa-/ePUu955xxJDrEgr6avLK2m" />
              </section-header>
              <div v-if="satisfactionModuleEnabled" class="d-flex m-1">
                <SurveyList v-if="isReady" />
                <div class="flex-col-8 ml-1 survey-layout">
                  <epa-bot-form v-if="creatingSurvey" />
                  <EmptyPoll v-else />
                </div>
              </div>
              <div v-else>
                <EmptyPoll :isSatisfactionModuleEnabled="false" />
              </div>
            </section>`
});
