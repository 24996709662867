(function (module) {
    'use strict';
    caseConversationCtrl.$inject = [
      '$scope',
      'Conversation',
      '$rootScope',
      '$timeout',
      'AdNotification',
      'UserService',
      'Message',
      '$element',
      'CaseDetailsService'
    ];
  
    function caseConversationCtrl(
      $scope,
      Conversation,
      $rootScope,
      $timeout,
      AdNotification,
      UserService: UserService,
      Message: MessageService,
      $element,
      CaseDetailsService
    ) {
      const vm = this;
      let closed = false;
  
      UserService.getProfile().then(user => {
        vm.canReply = user.isAdminOrCm
      })
  
      vm.$onInit = () => {
        init();
      };
  
      const init = () => {
        vm.targetOnView = true;
        vm.loading = true;
        // exposes service function to scope
        vm.getMessageDomId = Message.getDomId;
        const newMessage = CaseDetailsService.get('caseMessage');
        if (newMessage) {
          _getConversation(newMessage);
        }
      };
  
      let messageSelectedSub = Message.onMessageSelected.subscribe((msg: Message) => {
        // TODO: is this necessary?
        if (vm.replyMessage) {
          vm.replyMessage.beingReplied = false;
        }
        vm.replyMessage = msg;
        msg.beingReplied = true;
        $element.find('.case-conversation__footer form textarea').focus();
      })
  
      const caseUpdateListener = $scope.$on('case:hasBeenUpdated', (_event, _caseObject) => {
        init();
        _getConversation();
      });
  
      vm.$onDestroy = () => {
        newMessageListener();
        caseUpdateListener();
        messageSelectedSub.unsubscribe()
      };
  
      const newMessageListener = $rootScope.$watch('newCaseMessage', newValue => {
        if (newValue) {
          const newMessage = CaseDetailsService.get('caseMessage');
          _getConversation(newMessage);
          $rootScope.newCaseMessage = false;
        }
      });
  
      vm.FocusMessage = message =>
        vm.getMessageDomId(message) === vm.focusMessageDomId;
  
      vm.isAnswerable = () => vm.message.sn !== 'app_store';
  
      const _getConversation = (message=null) => {
        if (message) {
          vm.message = message;
          vm.sn = vm.message.sn;
          vm.loading = true;
          updateConversation(message);
        } else {
          vm.message = '';
          vm.sn = '';
          vm.loading = true;
        }
      };
  
      vm.calculateTargetOnView = function (onView, isTarget) {
        if (isTarget) {
          vm.targetOnView = onView;
        }
      };
      vm.onPublish = function (messageArray) {
        var message = messageArray[0];
  
        updateConversation(message, vm.replyMessage);
      };
  
      vm.scrollToMsg = function (message) {
        $element
          .find('.case-conversation__body')
          .scrollTo('#' + Message.getDomId(message), 300);
      };
  
      $scope.$on('$destroy', function () {
        closed = true;
      });
  
  
      function updateConversation(mainMessage, replyToMessage=null) {
        const {resource_id} = mainMessage;
        replyToMessage = replyToMessage || mainMessage;
        const action = 'get_conversation';
        Conversation.getConversation(resource_id)
          .then(
            function (conversation) {
              vm.loading = false;
              return reloadConversation(conversation);
            },
            function (data) {
              vm.loading = false;
              AdNotification.error(data, action);
            }
          )
          .then(function (wasReloaded) {
            if (closed) {
              return;
            }
            if (vm.conversation.kind != 'chat') {
              // TODO: is this necessary?
              Message.selectMessage(findMessageInScope(replyToMessage))
            }
            vm.scrollToMsg(mainMessage);
          });
        // return donePromise;
      }
  
      function reloadConversation(conversation) {
        var mustReload = true;
        if (conversation.kind === 'hierarchical') {
          vm.conversation = conversation;
          vm.comments = conversation['thread_root'].replies;
        } else if (conversation.kind === 'linear') {
          vm.conversation = conversation;
          vm.comments = conversation.messages;
        } else if (conversation.kind === 'chat') {
          var noMessages = oldMessages === undefined || oldMessages === null;
          var oldMessages = (vm.conversation || {}).messages;
  
          // [Warning] Check if this condition is ok
          mustReload =
            noMessages ||
            lastOldMessage['resource_id'] !== lastNewMessage['resource_id'];
          // || oldMessages.length < newMessages.length; << ?
  
          if (mustReload) {
            vm.conversation = conversation;
          }
          var length = vm.conversation.messages.length;
          vm.focusMessageDomId = vm.getMessageDomId(vm.message);
          vm.message = vm.conversation.messages[length - 1];
          vm.replyMessage = vm.message;
        }
        return $timeout(angular.noop, 1).then(function () {
          return mustReload;
        });
      }
  
      function findMessageInScope(message) {
        var conversation = vm.conversation;
        if (conversation.kind === 'chat') {
          return conversation.messages.find(function (curMsg) {
            curMsg.mid === message.mid;
          });
        }
        if (message.mid === conversation.thread_root.mid) {
          return conversation.thread_root;
        }
        var result;
        angular.forEach(vm.comments, function (curMsg) {
          result = result || (curMsg.mid === message.mid ? curMsg : undefined);
          angular.forEach(curMsg.replies, function (curMsg) {
            result = result || (curMsg.mid === message.mid ? curMsg : undefined);
          });
        });
        return result;
      }
    }
  
    module.controller('caseConversationCtrl', caseConversationCtrl);
  })(angular.module('postCenterWebClientApp'));