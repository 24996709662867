(module => {
  'use strict';

  module.controller('caseDetailViewsCtrl', caseDetailViewsCtrl);
  function caseDetailViewsCtrl(
    $scope,
    $rootScope,
    CaseDetailManager,
    ColumnsService: ColumnsService,
    UserService: UserService,
    EstablishmentService: EstablishmentService
  ) {
    const vm = this;
    vm.caseDetailManager = CaseDetailManager
    $scope.toDestroy = {};
    vm.ticketDividedView = false;

    const viewDivisionSub = ColumnsService.dividedView.subscribe(dividedView => {
      vm.ticketDividedView = dividedView
    })

    $scope.toDestroy.onInitialData = $rootScope.$on('CRM_GOT_INITIAL_DATA', () => {
      EstablishmentService.getEstablishment().then(establishment => {
        UserService.getProfile().then(user => {
          const {
            config: {sla_desk_metrics}
          } = establishment
  
          vm.slaMetrics = user.isCm && sla_desk_metrics
  
          const onboardingNetworks = establishment.onboarding_networks
          vm.pendingOnboarding =
            onboardingNetworks.length > 0 &&
            !onboardingNetworks.every(
              network => network.connected === 'connected'
            )
          //vm.pendingOnboarding = true;
        })
      })
    })

    $scope.$on('$destroy', () => {
      viewDivisionSub.unsubscribe();
      for (const [key, destroyMethod] of Object.entries($scope.toDestroy)) {
          if (destroyMethod) {
              destroyMethod();
          }
      }
    });

  }

})(angular.module('postCenterWebClientApp'));
