'use strict';

angular
  .module('postCenterWebClientApp')
  .directive('newCaseWhatsapp', newCaseWhatsappDirective);

function newCaseWhatsappDirective() {
  return {
    templateUrl: 'blocks/crm/views/new_cases/whatsapp_case.html',
    restrict: 'E',
    scope: {
      account: '=',
      department: '=',
      form: '=',
      userUid: '=',
      massive: '='
    },
    controller: newCaseWhatsappCtrl
  };
}

newCaseWhatsappCtrl.$inject = [
  '$scope',
  '$filter',
  '$rootScope',
  'AdNotification',
  'CaseService',
  'UserService',
  'adDataValidator',
  'MessageTemplateService',
  'adExportToCsv'
]; 

function newCaseWhatsappCtrl(
  $scope,
  $filter,
  $rootScope,
  AdNotification,
  CaseService,
  UserService: UserService,
  adDataValidator,
  MessageTemplateService: MessageTemplateService,
  adExportToCsv
) {

  const translate = (property, params = {}) => $filter('translate')(property, params);

  $scope.contentTitle = 'CRM_NEW_CASE_MESSAGE_LABEL';
  $scope.contentPlaceholder = 'CRM_NEW_CASE_MESSAGE_CONTENT';
  $scope.contentError = 'CRM_NEW_CASE_ERROR_MESSAGE_CONTENT';
  $scope.submitTitle = 'CRM_NEW_CASE_SEND_MESSAGE';
  if ($scope.massive) {
    $scope.submitTitle = 'CRM_NEW_CASE_CREATE_CAMPAIGN';
  };

  $scope.loading = true;
  $scope.uploadingFile = false;
  $scope.campaignId = null;
  $scope.validFile = false;
  $scope.fileError = false;
  $scope.fileErrorText = '';
  $scope.fileName = '';
  $scope.records = [];
  $scope.previewRow = 0;
  $scope.totalRows = 0;
  UserService.getProfile().then(user => ($scope.user = user));

  $scope.hsm = $scope.account.config.is_official_wsp;

  $scope.hsmPreview = '';

  const accountWatcherOff = $scope.$watch('account', (newValue, oldValue) => {
    if (newValue !== oldValue) {
      $scope.hsm = $scope.account.config.is_official_wsp;
      const {
        account: {id, sn}
      } = $scope;
      $scope.ticket.account = {id, sn};
      getOfficialHsmResponse();
      initForm();
    }
  });

  const departmentWatcherOff = $scope.$watch('department', (newValue, oldValue) => {
    if (newValue !== oldValue) {
      const { id: departmentId } = $scope.department;
      $scope.ticket.department = {id: departmentId};
      getOfficialHsmResponse();
      initForm();
    }
  }

  const ticketContentWatcherOff = $scope.$watch('ticket.content', (newValue, oldValue) => {
    if ($scope.massive) {
      resetFileUpload();
    };
  });

  $scope.$on('$destroy', function () {
    accountWatcherOff();
    ticketContentWatcherOff();
    departmentWatcherOff();
  });

  const getOfficialHsmResponse = () => {
    const {uid} = $scope.account;
    const { id: departmentId } = $scope.department;
  
    MessageTemplateService.getHsms().then(hsms => {
      $scope.officialHsmResponse = hsms
        .filter(hsm => {
          const isValidAccount = !hsm.account_uid || hsm.account_uid === uid;
          const isValidDepartment = departmentId === -1 
            ? (hsm.department_ids && hsm.department_ids.length === 0)
            : (hsm.department_ids && hsm.department_ids.includes(departmentId));
  
          return isValidAccount && isValidDepartment;
        })
        .map(hsm => {
          return {
            ...hsm,
            hsm_count: hsm.canned_response.match(/{{/g)?.length || 0
          };
        });
      $scope.$applyAsync();
    });
  }

  getOfficialHsmResponse()
  initForm();

  function sendSingleHsm() {
    $scope.form.$setSubmitted();
    if (validateFields()) {
      $scope.loading = true;
      const newCasePayload = {
        ...$scope.ticket,
        ...(
          $scope.department && $scope.department.id !== -1 ?
          { department: { id: $scope.department.id } } : {}
        )
      };
      CaseService.newCase(newCasePayload).then(
        response => {
          const {case_id: caseId} = response;
          $scope.loading = false;
          $rootScope.$emit('modalNewCaseClose');
          AdNotification.success({status: 201, response: {caseId: caseId}}, 'whatsapp_template_sent');
          initForm();
        },
        error => {
          AdNotification.error(error, 'error_sending_whatsapp_template');
          $scope.loading = false;
        }
      );
    }
  };

  function createMassiveCampaign() {
    MessageTemplateService.createCampaign(
      $scope.ticket.content, $scope.account.uid, $scope.fileName, $scope.records
    ).then(
      response => {
        $scope.campaignId = response.campaign_id;
        AdNotification.success({response: {campaignId: response.campaign_id}}, 'whatsapp_campaign_created_successfully');
        initForm();
        $rootScope.$emit('campaignCreatedSuccessfully');
      },
      error => {
        AdNotification.error(error, 'error_creating_whatsapp_campaign');
        resetFileUpload();
      }
    );
  }

  $scope.createTicket = () => {
    $scope.loading = true;
    if ($scope.massive) {
      createMassiveCampaign();
    } else {
      sendSingleHsm();
    }
  };


  $scope.formatHsm = function(hsm) {
    return `${hsm.template_id}`;
  }

  $scope.getNumber = num => {
    return new Array(num);
  };

  $scope.changeRow = (num: number) => {
    $scope.previewRow = num;
  };

  function isValidCSVFile(file: any): boolean {
    return file.name.endsWith(".csv");
  }

  function resetFileUpload() {
    $scope.uploadingFile = false;
    $scope.validFile = false;
    $scope.fileError = false;
    $scope.fileErrorText = '';
    $scope.fileName = '';
    $scope.records = [];
    $scope.previewRow = 0;
    $scope.totalRows = 0;
  }

  function getDataRecordsArrayFromCSVFile(csvRecordsArray: any) {
    let csvArr = [];
    let splitSymbol = ","
    let headers = <string>csvRecordsArray[0].split(splitSymbol);
    if (headers.length <= 1) {
      splitSymbol = ";"
      headers = <string>csvRecordsArray[0].split(splitSymbol);
    }
    for (let i = 1; i < csvRecordsArray.length; i++) {
      let currentRecord = (<string>csvRecordsArray[i]).split(splitSymbol);
      let csvRecord = []
      if (currentRecord.length == headers.length) {
        for (let j = 0; j < headers.length; j++) {
          csvRecord.push(currentRecord[j].trim());
        }
        csvArr.push(csvRecord);
      }
    }
    return csvArr;
  };

  function validWhatsAppUserIdentifier(userIdentifier: string): boolean {
    if (userIdentifier && userIdentifier.match(/^[0-9]*$/)){
      return true;
    }
  };

  function onFileLoad(csvData: string | ArrayBuffer) {
    let csvRecordsArray = (<string>csvData).split(/\r\n|\n/);
    let records = getDataRecordsArrayFromCSVFile(csvRecordsArray);

    if (records.length < 1) {
      showError('WHATSAPP_CAMPAIGN_ERROR_EMPTY_FILE')
      return;
    }

    let UniqueUsers = new Set();

    let expectedColumns = 1 + $scope.ticket.hsm_count;

    let okRecords = [];
    let validRecords = records.every((row, index) => {

      if (row.length == 1 && row[0] == '') {
        return true;
      }

      if (row.length != expectedColumns) {
        showError('WHATSAPP_CAMPAIGN_COLUMNS_MISMATCH', {expectedColumns, uploadedColumns: row.length});
        return false;
      }

      let userId = row[0];

      if (!validWhatsAppUserIdentifier(userId)) {
        showError('WHATSAPP_CAMPAIGN_INVALID_USER_ID', {userId, index});
        return false;
      };

      if (UniqueUsers.has(userId)) {
        showError('WHATSAPP_CAMPAIGN_REPEATED_USERS', {userId, index});
        return false;
      }

      UniqueUsers.add(userId)

      for (let i = 1; i < expectedColumns; i++) {
        let expectedCellValue = row[i];
        if (!expectedCellValue) {
          showError('WHATSAPP_CAMPAIGN_EMPTY_CELL', {rowIndex: index, columnIndex: i});
          return false;
        }
      }

      okRecords.push(row)
      return true;
    });

    if (!validRecords) {
      // do nothing (error raised previously)
      return
    }

    $scope.totalRows = okRecords.length;
    $scope.records = okRecords;
    $scope.validFile = true;
    $scope.$applyAsync();
  };

  $scope.onFileSelect = (files) => {
    resetFileUpload();
    $scope.uploadingFile = true;
    if (files.length !== 1) {
      showError('WHATSAPP_CAMPAIGN_INVALID_MULTIPLE_FILES', {uploaded: files.length});
      return;
    }
    let updaloadedFile = files[0];
    if (!isValidCSVFile(updaloadedFile)) {
      showError('WHATSAPP_CAMPAIGN_INVALID_EXTENTION', {fileName: updaloadedFile.name});
      return;
    }

    $scope.fileName = updaloadedFile.name;

    let reader = new FileReader();
    reader.readAsText(updaloadedFile);

    reader.onload = () => {
      onFileLoad(reader.result);
    };

    reader.onerror = function () {
      showError('WHATSAPP_CAMPAIGN_ERROR_READING_FILE');
    };
  }

  function showError(errorKey: string, interpolations: Object = {}) {
    $scope.validFile = false;
    $scope.uploadingFile = false;
    $scope.fileError = true;
    $scope.fileErrorText = translate(errorKey, interpolations);
  }

  $scope.downloadCsvTemplate = () => {
    let headers = ['_', 'Número de teléfono']
    for (let i =1; i <= $scope.ticket.hsm_count; i++) {
      headers.push(`parámetro ${i}`)
    }
    adExportToCsv._exportCsv(
      `${$scope.ticket.content}_template`,
      headers,
      []
    );
  }

  function hasValidPhoneNumber() {
    return (
      adDataValidator.hasData($scope.ticket.user.phone_number)
      && adDataValidator.validatePhoneNumber($scope.ticket.user.phone_number)
    )
  }

  function hasValidContent() {
    return $scope.ticket.content;
  }

  function hasValidHsmParameters() {
    if (!$scope.ticket.hsm_parameters.every((x) => x !== null && x !== undefined && x !== '')) {
      return false;
    }
    return $scope.ticket.hsm_parameters.filter(n => n).length == $scope.ticket.hsm_count;
  }

  $scope.validatePhoneNumber = function() {
    $scope.ticket.user.name = $scope.ticket.user.phone_number;
    if (!hasValidPhoneNumber()){
      $scope.errors.noValidClientPhoneNumber = true;
      return false;
    }
    $scope.errors.noValidClientPhoneNumber = false;
    return true;
  };

  $scope.validateContent = function() {
    if (!hasValidContent()){
      $scope.errors.noTicketContent = true;
      return false;
    }
    $scope.errors.noTicketContent = false;
    const index_hsm = $scope.officialHsmResponse
      .map(item => item.template_id)
      .indexOf($scope.ticket.content);
    
    $scope.hsmPreview = $scope.officialHsmResponse[index_hsm]?.canned_response;

    $scope.ticket.hsm_count = $scope.officialHsmResponse[index_hsm]?.hsm_count;
    return true;
  };

  $scope.hourNow = function() {
    let date = new Date();
    let hours = ("0" + date.getHours()).slice(-2);
    let minutes = ("0" + date.getMinutes()).slice(-2);
    return `${hours}:${minutes}`
  }

  function whatsappFormatter(text: string) {
    return text.replace(/(?:^|\s)(_)(?:(?!\s)([\s\S]*?))(_)(?=\s|$)/g, ' <i>$2</i> ')
      .replace(/(?:^|\s)(\*)(?:(?!\s)([\s\S]*?))(\*)(?=\s|$)/g, ' <b>$2</b> ')
      .replace(/(?:^|\s)(~)(?:(?!\s)([\s\S]*?))(~)(?=\s|$)/g, ' <s>$2</s> ')
      .replace(/(?:^|\s)(--)(?:(?!\s)([\s\S]*?))(--)(?=\s|$)/g, ' <u>$2</u> ')
      .replace(/(?:^|\s)(```)(?:(?!\s)([\s\S]*?))(```)(?=\s|$)/g, ' <tt>$2</tt> ')
      .replaceAll('\n', '<br>');
  }

  function setColor(text) {
    return `<span class="template-param">${text}</span>`
  }

  $scope.getHsmPreview = function() {
    let result = $scope.hsmPreview

    for (let i =0; i < $scope.ticket.hsm_count; i++) {
      let parameter = $scope.ticket.hsm_parameters[i]

      if ($scope.massive && $scope.validFile) {
        parameter = $scope.records[$scope.previewRow][i+1]
      };

      const reg = `{{${i + 1}}}`
      let toReplace = ''
      if (parameter) {
        toReplace = parameter;
      } else {
        toReplace = setColor(`${i+1}`);
      }
      result = result.replace(reg, toReplace);
    }
    return whatsappFormatter(result);
  }

  $scope.validateHsmParameters = function() {
    if (!hasValidHsmParameters()) {
      $scope.errors.noHsmParameters = true;
      return false;
    }
    $scope.errors.noHsmParameters = false;
    return true;
  };

  $scope.closeBlock = function() {
    initForm();
    $scope.$emit('closeBlock');
  };

  $scope.canSubmit = function() {
    if ($scope.massive) {
      return $scope.validFile;
    }
    return hasValidPhoneNumber() && hasValidContent() && hasValidHsmParameters();
  };

  function initForm() {
    $scope.loading = false

    const {
      account: {id, sn},
      hsm: hsmFlag
    } = $scope;

    $scope.hsmPreview = '';

    $scope.ticket = {
      hsmFlag,
      sn,
      content: null,
      hsm_parameters: [],
      hsm_count: null,
      account: {id, sn},
      user: {name: null, phone_number: null}
    };

    if ($scope.userUid) {
      // open pre-filled user
      $scope.ticket.user.name = $scope.userUid
      $scope.ticket.user.phone_number = $scope.userUid;
    };

    $scope.errors = {
      noAccount: false,
      noHsmParameters: false,
      noValidClientPhoneNumber: false,
      noTicketContent: false
    };

    resetFileUpload();

    $scope.$applyAsync();
  }

  function validateFields() {
    return [
      $scope.validatePhoneNumber(),
      $scope.validateContent(),
      $scope.validateHsmParameters()
    ].every(boolean => {
      return boolean;
    });
  }
}
