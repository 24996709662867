(function(module) {
  'use strict';
  addWhatsappSmoochCtrl.$inject = [
    '$scope',
    '$uibModalInstance',
    'SocialAccountService',
    'FireTagService'
  ];
  function addWhatsappSmoochCtrl(
    $scope,
    $uibModalInstance,
    SocialAccountService,
    FireTagService
  ) {
    const vm = this;

    vm.$onInit = () => {
      vm.phoneName = '';
      vm.phoneNumber = '';
      vm.phoneNumberLabel = 'Número de teléfono que desea utilizar';
      vm.phoneNameLabel = 'Nombre con el cual se identificará la cuenta';
      vm.errorNumber = false;
    };
    vm.$onDestroy = () => {
      vm.errorNumber = false;
      vm.phoneNumber = '';
    };
    vm.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };
    vm.addAccount = () => {
      vm.errorNumber = false;
      if (validNumber(vm.phoneNumber)) {
        SocialAccountService.connectSmooch({
          phone: vm.phoneNumber,
          name: vm.phoneName
        }).then(resp => {
          FireTagService.setEvent({
            name: 'conecta_cuenta',
            params: {
              channel_name: 'WhatsApp'
            }
          });
          $uibModalInstance.close(resp);
        });
      } else {
        vm.errorNumber = true;
      }
    };
    const validNumber = num => {
      const number = num.includes('+') ? num.substring(1) : num;
      if (/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g.test(number)) {
        vm.phoneNumber = number;
        return true;
      } else {
        return false;
      }
    };
  }
  module.controller('addWhatsappSmoochCtrl', addWhatsappSmoochCtrl);
})(angular.module('postCenterWebClientApp'));
