(function(module) {
  'use strict';
  adExportToCsv.$inject = [];
  function adExportToCsv() {
    return {
      exportToCsv(name, array) {
        //this._exportCsv(name, headers, array);
        this._exportExcel(name, array);
      },
      _exportExcel(name, array) {
        const exportCsv =
          `${name} - ${moment().format()}.xlsx` || 'adereso-export.xlsx';
        const data = XLSX.utils.json_to_sheet(array);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, data, name);

        XLSX.writeFile(workBook, exportCsv);
      },
      _exportCsv(name, headers, array) {
        if (headers) {
          array.unshift(headers);
        }
        const data = this._convertCsv(array);

        this._exportCsvData(name, data)
      },
      _exportCsvData(filename, data) {
        const exportCsv =
          `${filename}-${moment().format()}.csv` || 'adereso-export.csv';
        //const blob = new Blob([data], {type: 'text/csv;charset=uft-8;'});
        const link = document.createElement('a');
        if (!link.download) {
          //const url = URL.createObjectURL(blob);
          const latinUrl = `data:text/csv;charset=utf-8;%EF%BB%BF${encodeURI(
            data
          )}`;
          link.setAttribute('href', latinUrl);
          link.setAttribute('download', exportCsv);
          document.body.appendChild(link);
          link.click();
        }
      },
      _convertCsv(arr) {
        const array = typeof arr != 'object' ? JSON.parse(arr) : arr;
        let str = '';
        for (let i = 0; i < array.length; i++) {
          let line = '';
          for (let index in array[i]) {
            if (line != '') {
              line += ',';
            }
            line += array[i][index];
          }
          str += `${line} \r\n`;
        }
        return str;
      }
    };
  }
  module.factory('adExportToCsv', adExportToCsv);
})(angular.module('postCenterWebClientApp'));
