store.registerModule('establishment', {
  namespaced: true,
  state: () => ({
    establishment: {}
  }),
  getters: {
    establishment: state => state.establishment,
    name: state => state.establishment.name,
    config: state => state.establishment.config
  },
  mutations: {
    SET_ESTABLISHMENT(state, payload) {
      state.establishment = payload;
    }
  },
  actions: {
    async getEstablishment({commit, state}) {
      return await this.EstablishmentService.getEstablishment().then(
        establishment => {
          commit('SET_ESTABLISHMENT', establishment);
          return establishment;
        }
      );
    },
    async updateEstablishment({commit, state}) {
      const {establishment} = state;
      const updatedEstablishment =
        await this.EstablishmentService.editEstablishment(establishment);
      commit('SET_ESTABLISHMENT', updatedEstablishment);
      return updatedEstablishment;
    }
  }
});
