'use strict';

var app = angular.module('postCenterWebClientApp');
app.directive('customDataBlock', () => {
  return {
    scope: {
      object: '=',
      preview: '=',
      entity: '@',
      showActions: '=?',
      callback: '=',
      fromCaseId: '=?'
    },
    templateUrl: 'blocks/custom_data/views/custom_data_block.html',
    restrict: 'E',
    link: (scope, element, attrs) => {
      if (scope.showActions === null) {
        scope.showActions = true;
      }
    },
    controller: [
      '$scope',
      '$rootScope',
      'AdNotification',
      'UserService',
      'CrmService',
      'CaseDetailManager',
      '$uibModal',
      'CaseService',
      'InitialData',
      '$filter',
      'MetadataHelper',
      'crmErrorMessage',
      '$element',
      'FireTagService',
      'EstablishmentService',
      'CustomData',
      function (
        $scope,
        $rootScope,
        AdNotification,
        UserService: UserService,
        CrmService,
        CaseDetailManager,
        $uibModal,
        CaseService,
        InitialData,
        $filter,
        MetadataHelper,
        crmErrorMessage,
        $element,
        FireTagService,
        EstablishmentService: EstablishmentService,
        CustomData: CustomDataService
      ) {
        Promise.all([
          UserService.getProfile(),
          EstablishmentService.getEstablishment(),
          CustomData.getCustomData()
        ]).then(([user, establishment, customData]) => {
          $scope.canSetCustomData = user.isAdminOrCm
          $scope.establishment = establishment
          $scope.countryIdName = establishment.country.resident_id
          $scope.customData = customData
          const {
            integrations: {integration_configs: integrationConfigs = []}
          } = establishment
          $scope.crmActive =integrationConfigs.find(
            ({config: {active}, name}) => active && crmNames.includes(name)
          ) || null
          reloadCustomDatas()
        })
        $scope.notValid = {};
        $scope.filledFormCustomData = {};
        $scope.customDataOrder = ['-fixed', 'index'];
        $scope.customDataVisibleList = {};
        $scope.noDeletedCustomDataLength = 0;
        
        $scope.canInteract = true;
        $scope.saving = false;
        $scope.showSaveError = false;
        $scope.showCountryIdError = false;
        $scope.crmActive = null;
        $scope.crmEntityActive = null;
        $scope.crmValues = {};
        const crmNames = ['SALESFORCE', 'HUBSPOT', 'SUGARCRM'];
        const lastUser = $scope.object;
        let departmentId = null;

        $scope.$onDestroy = () => {
          $scope.watcherObject();
          $scope.previewCustomDataWatcherOff();
          $scope.objectKeyValuesWatcherOff();
          $scope.resourceIdWatcherOff();
          $scope.previewWatcherOff();
          customDataCaseListenerOff();
          customDataSaveListenerOff();
        };

        const customDataCaseListenerOff = $scope.$on('customDataBlock:caseObject', (_event, {department_id}) => {
          departmentId = department_id;
          reloadCustomDatas();
        });

        $scope.watcherObject = $scope.$watch('object', newValue => {
          const crmIntegration = {
            crm_integration: {
              hubspot_id: null,
              salesforce_id: null,
              sugarcrm_id: null
            }
          };
          $scope.crmEntityActive = {
            ...crmIntegration,
            ...newValue
          };
        });

        $scope.canShowCustomData = function (customData) {
          let conditions = (
            $scope.customDataVisibleList[customData.resource_id] &&
            !customData.deleted &&
            customData.visible &&
            (!customData.only_read ||
              $scope.filledFormCustomData[customData.key])
          );
          return conditions
        };

        function previewCDataChange(newValue, oldValue) {
          if (newValue !== oldValue) {
            $scope.customDataList = $scope.previewCustomData.fixedData.concat(
              $scope.preview.activeCustomData
            );
            $scope.noDeletedCustomDataLength = 0;
            angular.forEach($scope.customDataList, function (customData) {
              if (!customData.deleted) {
                $scope.noDeletedCustomDataLength += 1;
              }
            });
          }
        }

        function setCustomDataList() {
          if ($scope.preview) {
            $scope.previewCustomData = {
              fixedData: $scope.preview.fixedData,
              activeCustomData: $scope.preview.activeCustomData
            };
            $scope.customDataList = $scope.previewCustomData.fixedData.concat(
              $scope.preview.activeCustomData
            );
            $scope.previewCustomDataWatcherOff = $scope.$watch('previewCustomData', previewCDataChange, true);
          } else {
            $scope.customDataList = $scope.customData;
          }
        }

        function getCustomDataToValidate() {
          var toValidate = [];
          angular.forEach($scope.customDataList, function (customData) {
            if (!customData.deleted && customData.required) {
              if (
                true === !!$scope.customDataVisibleList[customData.resource_id]
              ) {
                toValidate.push(customData.key);
              }
            }
          });
          return toValidate;
        }

        function filterCustomDatasByEntity() {
          $scope.customDataList = _.filter(
            $scope.customDataList,
            function (customData) {
              var entityCheck = customData.entity === $scope.entity;
              var deleted = customData.deleted;
              var visible = customData.visible;
              return entityCheck && !deleted && visible;
            }
          );
        }

        $scope.filterCustomDataOption = function (customData) {
          return function (option) {
            const selectedOptionKey =
              $scope.filledFormCustomData[customData.key];
            if (option.key === selectedOptionKey || option.deleted === false) {
              return true;
            }
            return false;
          };
        };

        function refreshCustomData() {
          angular.forEach($scope.customDataList, function (customData) {
            if (!customData.deleted) {
              $scope.noDeletedCustomDataLength += 1;
            }
            if (
              $scope.object.custom_fields &&
              $scope.object.custom_fields.indexOf(customData.key) >= 0
            ) {
              var value = $scope.object[customData.key];
              if (
                (value === [] || value === null) &&
                customData.type === 'list'
              ) {
                // This fixes missing list field.
                value === [''];
              }
              $scope.filledFormCustomData[customData.key] = angular.copy(value);
            } else if (customData.type === 'checkbox') {
              $scope.filledFormCustomData[customData.key] = {};
            } else if (customData.type === 'list') {
              $scope.filledFormCustomData[customData.key] = [''];
            }
          });
        }

        function isBlank(str) {
          return !str || /^\s*$/.test(str);
        }

        function isValid() {
          var toReturn = true;
          var toValidate = getCustomDataToValidate();
          for (var i in toValidate) {
            var key = toValidate[i];
            var valueToValidate = $scope.filledFormCustomData[key];
            var is_valid = valueIsValid(valueToValidate);
            $scope.notValid[key] = !is_valid;
            toReturn = toReturn && !$scope.notValid[key];
          }
          return toReturn;
        }

        function valueIsValid(value) {
          var return_value = false;
          if (value === null || isBlank(value)) {
            return_value = false;
          } else if (angular.isDate(value)) {
            return_value = true;
          } else if (angular.isObject(value)) {
            return_value = false;
            for (var keyValue in value) {
              if (value[keyValue]) {
                return_value = true;
                break;
              }
            }
          } else {
            return_value = true;
          }
          return return_value;
        }

        function resfreshCustomDataTree() {
          $scope.customDataVisibleList = {};
          $scope.treeOrderCustomDataList = [];

          const customDataById = _.indexBy(
            $scope.customDataList,
            'resource_id'
          );
          const zeroLevel = _.filter($scope.customDataList, zeroLevelFilter);
          let sortedZeroLevel = _.sortBy(zeroLevel, 'index');

          if (departmentId) {
            sortedZeroLevel = sortedZeroLevel.filter(
              ({department_ids}) =>
                department_ids.includes(departmentId) ||
                department_ids.length === 0
            );
          } else {
            sortedZeroLevel = sortedZeroLevel.filter(
              ({department_ids}) => department_ids.length === 0
            );
          }

          sortedZeroLevel.forEach(function (customData) {
            $scope.customDataVisibleList[customData.resource_id] = true;
            $scope.treeOrderCustomDataList.push(customData);
            addChildrenCustomDataToView(customData);
          });

          function zeroLevelFilter(customData) {
            return customData.level <= 0;
          }

          function addChildrenCustomDataToView(parent, fixed_false) {
            fixed_false = angular.isDefined(typeof fixed_false)
              ? fixed_false
              : false;
            (parent.options || []).forEach(function (option) {
              var child = customDataById[option.custom_data_id];
              if (child != null && parent.type === 'select') {
                var selectedOptionKey = $scope.filledFormCustomData[parent.key];
                if (
                  selectedOptionKey != null &&
                  selectedOptionKey === option.key &&
                  !fixed_false
                ) {
                  enableChildrenCustomDataInView(option, child);
                } else {
                  disabledChildrenCustomDataInView(option, child);
                }
              }
            });
          }

          function enableChildrenCustomDataInView(option, child) {
            $scope.customDataVisibleList[option.custom_data_id] = true;
            $scope.treeOrderCustomDataList.push(child);
            addChildrenCustomDataToView(child);
          }

          function disabledChildrenCustomDataInView(option, child) {
            $scope.customDataVisibleList[option.custom_data_id] = false;
            $scope.filledFormCustomData[child.key] = '';
            $scope.treeOrderCustomDataList.push(child);
            addChildrenCustomDataToView(child, true);
          }
        }

        function finallyStatus() {
          $scope.loading = false;
          if (angular.isFunction($scope.callback)) {
            $scope.callback();
          }
          $scope.$emit('customDataBlock:saveDone');
        }

        function getCaseAddCDPromise() {
          return CaseService.addCustomData(
            $scope.object.resource_id,
            $scope.filledFormCustomData
          ).then(function () {});
        }

        function getUserAddCDPromise() {
          return UserService.addCustomData(
            $scope.object.resource_id,
            $scope.filledFormCustomData,
            null,
            $scope.fromCaseId
          ).then(function () {
            $rootScope.$emit(
              'integration:window:refresh_user',
              angular.fromJson($scope.object)
            );
          });
        }

        function reloadCustomDatas() {
          $scope.notValid = {};
          $scope.filledFormCustomData = {};
          $scope.customDataVisibleList = {};
          $scope.treeOrderCustomDataList = [];
          $scope.noDeletedCustomDataLength = 0;
          setCustomDataAndFilterByEntity();
          refreshCustomData();
          resfreshCustomDataTree();
        }

        function checkSaveByEntity() {
          if ($scope.entity === 'U') {
            if (!checkCountryId()) {
              $scope.showCountryIdError = true;
              return false;
            }
            $scope.showCountryIdError = false;
            if (!checkEmail()) {
              $scope.showEmailError = true;
              return false;
            }
            $scope.showEmailError = false;
          }
          if (!checkRequiredFieldsOnCustomData()) {
            $scope.showSaveError = true;
            return false;
          }
          return true;
        }
        $scope.isValid = isValid();

        $scope.sendCustomData = function () {
          $scope.saving = true;

          if (!checkSaveByEntity()) {
            $scope.saving = false;
            return;
          }
          var valid = isValid();

          if (valid) {
            $scope.showSaveError = false;
            var action;
            $scope.sendingInvalid = false;
            $scope.loading = true;
            $scope.filledFormCustomData.has_custom_data = hasCustomData();
            let tagEventName = '';
            var promise;
            if ($scope.entity === 'U') {
              action = 'add_custom_data_user';
              tagEventName = 'tipifica_usuario';
              promise = getUserAddCDPromise();
            } else if ($scope.entity === 'C') {
              action = 'add_custom_data_case';
              tagEventName = 'tipifica_ticket';
              promise = getCaseAddCDPromise();
            }

            if (promise) {
              promise.then(
                function () {
                  delete $scope.filledFormCustomData.has_custom_data;
                  for (var key in $scope.filledFormCustomData) {
                    $scope.object[key] = angular.copy(
                      $scope.filledFormCustomData[key]
                    );
                    if (!$scope.object.custom_fields) {
                      $scope.object.custom_fields = [];
                    }
                    if ($scope.object.custom_fields.indexOf(key) < 0) {
                      $scope.object.custom_fields.push(key);
                    }
                  }

                  if ($scope.entity === 'C') {
                    $rootScope.$emit(
                      'integration:window:refresh_case',
                      $scope.object
                    );
                  } else if ($scope.entity === 'U') {
                    $rootScope.$emit(
                      'integration:window:refresh_user',
                      $scope.object
                    );
                  }
                  AdNotification.success(201, action);
                  FireTagService.setEvent({
                    name: tagEventName
                  });
                  finallyStatus();
                  $scope.saving = false;
                },
                function (data) {
                  AdNotification.error(data, action);
                  finallyStatus();
                  $scope.saving = false;
                }
              );
            } else {
              $scope.loading = false;
              AdNotification.error('error', action);
              if (angular.isFunction($scope.callback)) {
                $scope.callback();
              }
              $scope.$emit('customDataBlock:saveDone');
              $scope.saving = false;
            }
          } else {
            $scope.sendingInvalid = true;
            AdNotification.notify(
              $filter('translate')('CUSTOMDATABLOCK_MISSING_FIELDS'),
              'error'
            );
            $scope.$emit('customDataBlock:saveInvalid');
            $scope.saving = false;
          }
        };

        const errorCrmManager = ({error, entity, crmName}) => {
          const {response: {error_code: code} = {}} = error || {};
          UserService.getProfile().then(user => {
            let locale = user.profile.language
            const errorMessages = crmErrorMessage.getMessage({
              code,
              locale,
              entity,
              crmName
            })
            AdNotification.notify(errorMessages, 'error');
          })
        }

        $scope.getCrmData = async customData => {
          customData.loadingCrm = true;
          const {crm_integration: crmIntegration, entity, key} = customData;
          const data = {
            metadata_key: crmIntegration[`${getCrmName()}_id`],
            metadata_type: customData.type,
            current_metadata_value: $scope.filledFormCustomData[key]
          };
          const crmName = getCrmName();
          const entityId = getEntityId();
          const entityType = entity === 'U' ? 'user' : 'case';
          try {
            const metadataCrm = await CrmService.getMetadataCrm(
              {
                crmName,
                entityType,
                entityId
              },
              data
            );
            setCrmValues(metadataCrm.metadata_value, key, 'METADATA_CRM_GET');
          } catch (error) {
            errorCrmManager({error, entity, crmName});
          } finally {
            customData.loadingCrm = false;
          }
        };

        $scope.validateHasCrmKey = customData => {
          const {crm_integration: crmIntegration} = customData;
          return !crmIntegration[`${getCrmName()}_id`];
        };

        $scope.saveCrmData = async customData => {
          customData.savingCrm = true;
          const {crm_integration: crmIntegration, entity, key} = customData;
          const finalData = $scope.filledFormCustomData[key];
          const data = {
            metadata_key: crmIntegration[`${getCrmName()}_id`],
            metadata_value: finalData
          };
          const crmName = getCrmName();
          const entityId = getEntityId();
          const entityType = entity === 'U' ? 'user' : 'case';
          try {
            await CrmService.setMetadataCrm(
              {
                crmName,
                entityType,
                entityId
              },
              data
            );
            setCrmValues(finalData, key, 'METADATA_CRM_SAVE');
          } catch (error) {
            errorCrmManager({error, entity, crmName});
          } finally {
            customData.savingCrm = false;
          }
        };

        const setCrmValues = (finalData, key, messageKey) => {
          displaySuccessMessageCrm(messageKey);
          $scope.crmValues[key] = finalData;
          $scope.filledFormCustomData[key] = finalData;
        };

        const getCrmName = () => {
          const {name} = $scope.crmActive || {};
          return name.toLowerCase();
        };

        const getEntityId = () => {
          const {crm_integration} = $scope.crmEntityActive;
          return crm_integration[`${getCrmName()}_id`];
        };

        $scope.hasCrmActive = customData => {
          const {
            is_crm_integrable: integrable,
            crm_integration: crmIntegration
          } = customData;
          const {crm_integration: userCrmIntegration} = $scope.crmEntityActive;
          return (
            $scope.crmActive &&
            integrable &&
            crmIntegration &&
            userCrmIntegration &&
            userCrmIntegration[`${getCrmName()}_id`]
          );
        };

        $scope.displayLinkableCrmModal = (customData) => {

          let possibleProperties = []
          for (let i = 0; i < $scope.treeOrderCustomDataList.length; i++) {
            let customData = $scope.treeOrderCustomDataList[i]
            const crmIntegration = customData.crm_integration
            if (crmIntegration) {
              const integrationKey = crmIntegration[`${getCrmName()}_id`]
              let StoredValue = $scope.filledFormCustomData[customData.key]
              if (customData.type === 'list') {
                StoredValue = StoredValue[0] || ''
              } else if (customData.type === 'select') {
                for (let j = 0; j < customData.options.length; j++) {
                  const option = customData.options[j]
                  if (option.key === StoredValue) {
                    StoredValue = option.value
                    break
                  }
                }
              };

              if (integrationKey && StoredValue) {
                possibleProperties.push({
                  adereso_key: customData.key,
                  adereso_label: customData.label,
                  metadata_key: integrationKey,
                  metadata_value: StoredValue,
                  enabled: true
                })
              }
            }
          }

          const {crm_integration: crmIntegration, entity, key} = customData;
          const {resource_id} = $scope.crmEntityActive;
          const linkableDataCrm = {
            metadata_key: crmIntegration[`${getCrmName()}_id`],
            metadata_value: $scope.filledFormCustomData[key] || '',
            entityType: entity === 'U' ? 'user' : 'case',
            crmName: getCrmName(),
            resource_id,
            possibleProperties
          };
          $uibModal
            .open({
              templateUrl: 'blocks/message/views/linkable_crm_modal.html',
              controller: 'LinkableCrmCtrl',
              resolve: {
                linkableDataCrm: () => {
                  return linkableDataCrm;
                }
              },
              windowClass: 'modal'
            })
            .result.then(linked => {
              if (linked) {
                updateCustomData();
              }
            });
        };

        const updateCustomData = () => {
          CaseDetailManager.findAndDisplayCase($scope.fromCaseId);
        };

        $scope.hasSearchableCrm = customData => {
          const {
            is_crm_searchable: searchable,
            crm_integration: crmIntegration
          } = customData;
          const {crm_integration: userCrmIntegration} = $scope.crmEntityActive;
          return (
            $scope.crmActive &&
            searchable &&
            crmIntegration &&
            (!userCrmIntegration || !userCrmIntegration[`${getCrmName()}_id`])
          );
        };

        $scope.validateHasCrm = customData => {
          return (
            $scope.hasCrmActive(customData) ||
            $scope.hasSearchableCrm(customData)
          );
        };

        const displaySuccessMessageCrm = messageKey => {
          AdNotification.notify($filter('translate')(messageKey), 'success');
        };

        function hasCustomData() {
          if ($scope.filledFormCustomData != null) {
            for (var key in $scope.filledFormCustomData) {
              if (!!$scope.filledFormCustomData[key]) {
                return true;
              }
            }
          }
          return false;
        }

        $scope.checkValue = function (value) {
          return valueIsValid(value);
        };

        $scope.checkCrmValue = key => {
          return $scope.crmValues[key] === $scope.filledFormCustomData[key];
        };

        $scope.valid = function () {
          $scope.isValid = isValid();
        };

        $scope.validSelect = function () {
          $scope.valid();
          resfreshCustomDataTree();
        };

        $scope.cancel = function () {
          $scope.$emit('customDataBlock:cancel');
        };

        $scope.checkDefined = function (value) {
          if (angular.isUndefined(value)) {
            return false;
          }
          return true;
        };

        function setCustomDataAndFilterByEntity() {
          setCustomDataList();
          if ($scope.entity) {
            filterCustomDatasByEntity();
          }
        }

        setCustomDataAndFilterByEntity();

        function objectKeyWithValues() {
          var custom_data_values = {};
          angular.forEach($scope.customDataList, function (customData) {
            var key = customData.key;
            if (!$scope.object) {
              $scope.object = lastUser;
            }
            if (angular.isDefined($scope.object[key])) {
              var value = angular.copy($scope.object[key]);
              if (
                value !== '' &&
                value !== {} &&
                value !== [] &&
                value !== null
              ) {
                custom_data_values[key] = value;
              }
            }
          });
          return custom_data_values;
        }

        $scope.objectKeyValuesWatcherOff = $scope.$watch(
          objectKeyWithValues,
          function (newValue, oldValue) {
            if (!$scope.loading) {
              if (newValue !== oldValue) {
                refreshCustomData();
                resfreshCustomDataTree();
              }
            }
          },
          true
        );
        $scope.resourceIdWatcherOff = $scope.$watch(
          function () {
            if (
              angular.isDefined($scope.object) &&
              'resource_id' in $scope.object
            ) {
              return $scope.object.resource_id;
            }
            return 0;
          },
          function (newValue, oldValue) {
            reloadCustomDatas();
          }
        );

        $scope.previewWatcherOff = $scope.$watch(
          'preview',
          function (newValue, oldValue) {
            resfreshCustomDataTree();
          },
          true
        );

        const customDataSaveListenerOff = $scope.$on('customDataBlock:save', function () {
          $scope.sendCustomData();
        });

        function checkRequiredFieldsOnCustomData() {
          var customData = getVisibleCd();
          var key = 'showSaveError';
          var method = MetadataHelper.checkRequiredFields;
          var source = $scope.filledFormCustomData;
          return checkRequiredFields(customData, source, key, method);
        }

        function checkCountryId() {
          var customData = $scope.customDataList;
          var key = 'showCountryIdError';
          var method = MetadataHelper.validCountryId;
          var source = $scope.filledFormCustomData;
          return checkRequiredFields(customData, source, key, method);
        }

        function checkEmail() {
          var customData = $scope.customDataList;
          var key = 'showEmailError';
          var method = MetadataHelper.validEmail;
          var source = $scope.filledFormCustomData;
          return checkRequiredFields(customData, source, key, method);
        }

        function checkRequiredFields(customData, source, key, method) {
          var view = $element.find('.custom-data-elements-container');
          var wasDataValid = method(customData, source, self.form, view);
          return wasDataValid;
        }

        function getVisibleCd() {
          return _.filter($scope.treeOrderCustomDataList, function (datum) {
            return (
              $scope.customDataVisibleList[datum.resource_id] &&
              datum.deleted !== true &&
              datum.visible
            );
          });
        }
      }
    ]
  };
});
