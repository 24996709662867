(module => {
    'use strict';
    AderesoAICtrl.$inject = [
        "$scope",
        '$rootScope',
        "FRONTEND_VUE_URL",
        "$sce",
        "AdNotification",
        "AdRequest"
    ]
    module.controller('AderesoAICtrl', AderesoAICtrl);
    function AderesoAICtrl(
        $scope, $rootScope, FRONTEND_VUE_URL, $sce, AdNotification,
        AdRequest: AdRequestService
    ) {
        $rootScope.adminTab.selected = 'aderesoAI';
        $scope.iFrameUrl = function () {
            const token = AdRequest.getToken();
            const url = `${FRONTEND_VUE_URL}/chatbots/?authToken=${token}`
            return $sce.trustAsResourceUrl(url);
        }
    }
})(angular.module('postCenterWebClientApp'))

