'use strict';

var app = angular.module('postCenterWebClientApp');

app.directive('webhookNotificationConfiguration', function () {
  return {
    templateUrl: 'blocks/admin/views/integration_config/webhook_notification.html',
    restrict: 'E',
    scope: {
      config: '='
    },
    controller: ['$scope', '$rootScope', 'AdRequest', '$filter', 'BusinessRulesService',
      async function ($scope, $rootScope, AdRequest, $filter, BusinessRulesService) {

        $scope.availableTriggers = [
            rules.triggers.CaseClosedTrigger,
            rules.triggers.MessageTrigger,
            rules.triggers.CaseCustomDataSavedTrigger,
            rules.triggers.UserCustomDataSavedTrigger,
            rules.triggers.OutboundMessageTrigger,
            rules.triggers.CaseCreatedTrigger
        ];

        $scope.availableConditions = [
            rules.conditions.SocialAccountCondition.identifier,
            rules.conditions.CaseTypificationCondition.identifier,
            rules.conditions.MessageKeywordsCondition.identifier,
            rules.conditions.MessageMatchesRegexCondition.identifier,
            rules.conditions.InBusinessTimeCondition.identifier,
            rules.conditions.NotInBusinessTimeCondition.identifier,
            rules.conditions.InWeekdayCondition.identifier,
            rules.conditions.NotAssignedToCMCondition.identifier,
            rules.conditions.InDateTimeRangeCondition.identifier,
            rules.conditions.InTimeRangeCondition.identifier,
            rules.conditions.TicketNotAddressedCondition.identifier  
        ];

        $scope.actionTypes = [
          rules.actions.WebhookNotificationAction.identifier,
          rules.actions.WebhookNotificationActionV2.identifier,
        ];
        $scope.rules = [];

        BusinessRulesService.get().then(function (rules) {
          $scope.rules = rules.filter(function (rule) {
            return $scope.actionTypes.includes(rule.actions[0].identifier)
          });
        });

        $scope.addRule = function () {
          $scope.rules.push(rules.BusinessRule.createEmptyRule())
        };

        $scope.endpoints = [];
        $scope.newEndpoint = {
          url: ""
        };

        $scope.newTarget = {
          'version': "1.0.0",
          'delivery_type': "http",
          'endpoint': "",
          'authorization_active': false,
          'authorization_token': "",
          'topic': ""
        }

        async function getTargets() {
          return AdRequest.get('/api/v1/webhook_notification/targets')
        };

        async function createTarget(newTarget) {
          return AdRequest.post('/api/v1/webhook_notification/targets', newTarget);
        };

        async function deleteTarget(targetId) {
          return AdRequest.delete(`/api/v1/webhook_notification/targets/${targetId}`);
        }

        $scope.targets = [];
        $scope.targets = await getTargets();
        $scope.$applyAsync();

        if ($scope.config.endpoint) {
          $scope.endpoints = $scope.config.endpoint.split(',').map(function (endpoint) {
            return {url: endpoint}
          });
        };

        $scope.getFormattedEndpoints = function () {
          return $scope.endpoints.map(function (endpoint) {
            return endpoint.url
          }).join(',')
        };

        $scope.removeEndpoint = function (index) {
          $scope.endpoints.splice(index, 1)
        };

        $scope.removeTarget = async function (index) {
          await deleteTarget($scope.targets[index].id).then(
            function (result) {
              $scope.targets.splice(index, 1);
              $rootScope.$emit(
                'successMessage',
                $filter('translate')('ADMIN_INTEGRATION_DELETE_SUCCESS')
              );
            }
          ).catch(function (error) {
            $rootScope.$emit(
              'errorMessage',
              `${$filter('translate')('ADMIN_INTEGRATION_DELETE_ERROR')}: status: ${error.status} response: ${JSON.stringify(error.response).slice(0,100)}`
            );
          }).finally(function () {
            $scope.$applyAsync()
          });
        };

        $scope.addTarget = async function (newTarget) {
          AdRequest.post('/api/v1/webhook_notification/targets/test', newTarget)
            .then(async function (result) {
              await handleSuccess(newTarget);
            })
            .catch(function (error) {
              handleError(error);
            })
            .finally(function () {
              // This code will run regardless of success or error
              $scope.$applyAsync()
            });

          async function handleSuccess(newTarget) {

            await createTarget(newTarget).then(
              async function (result) {
                $scope.targets = await getTargets();
                $scope.newTarget = {
                  'version': "1.0.0",
                  'delivery_type': "http",
                  'endpoint': "",
                  'authorization_active': false,
                  'authorization_token': "",
                  'topic': ""
                };
                $rootScope.$emit(
                  'successMessage',
                  $filter('translate')('ADMIN_INTEGRATION_TEST_SUCCESS')
                );
              }
            ).catch(function (error) {
              handleError(error);
            });
          }

          function handleError(error) {
            console.log(error)
            $rootScope.$emit(
              'errorMessage',
              `${$filter('translate')('ADMIN_INTEGRATION_TEST_ERROR')}: status: ${error.status} response: ${JSON.stringify(error.response).slice(0,100)}`
            );
          }
        };


        $scope.addEndpoint = function (newEndpoint) {

          AdRequest.post('/api/v1/webhook_notification/test', newEndpoint)
            .then(function (result) {
              handleSuccess(newEndpoint);
            })
            .catch(function (error) {
              handleError(error);
            })
            .finally(function () {
              // This code will run regardless of success or error
              $scope.$applyAsync()
            });

          function handleSuccess(newEndpoint) {
            $rootScope.$emit(
              'successMessage',
              $filter('translate')('ADMIN_INTEGRATION_TEST_SUCCESS')
            );
            $scope.endpoints.push(JSON.parse(JSON.stringify(newEndpoint)));
            $scope.newEndpoint = {
              url: ""
            };
          }

          function handleError(error) {
            console.log(error)
            $rootScope.$emit(
              'errorMessage',
              `${$filter('translate')('ADMIN_INTEGRATION_TEST_ERROR')}: status: ${error.status} response: ${JSON.stringify(error.response).slice(0,100)}`
            );
          }
        }

        $scope.saveNotificationWebhookConfig = function () {
          var data = {
            active: $scope.config.active,
            endpoint: $scope.getFormattedEndpoints(),
            authorization_active: $scope.config.authorization_active,
            json_token_active: $scope.config.json_token_active,
            authorization_token: $scope.config.authorization_token,
            json_token: $scope.config.json_token,
            version: $scope.config.version
          };
          AdRequest.post('/api/v1/webhook_notification/config', data).then(
            function (result) {
              $rootScope.$emit(
                'successMessage',
                $filter('translate')('ADMIN_INTEGRATION_SAVE_SUCCESS')
              );
            },
            function (error) {
              $rootScope.$emit(
                'errorMessage',
                $filter('translate')('ADMIN_INTEGRATION_SAVE_ERROR')
              )
            }
          )
        }

      }]
  };
});
