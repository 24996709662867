'use strict';

angular
  .module('postCenterWebClientApp')
  .directive('viewChangeLog', viewChangeLog);

viewChangeLog.$inject=['$uibModal'];

function viewChangeLog($uibModal) {
  return {
    scope : {
      loading: '=',
      activityLog: '=',
      pagination: '='
    },
    templateUrl: 'blocks/admin/views/view_change_log.html',
    restrict: 'E',
    controller: ['$scope', function ($scope) {

      $scope.openHelpModal = function(title, helpText, size) {
        $uibModal.open({
          templateUrl: 'blocks/analytics/views/dashboard_help_modal.html',
          size: size,
          controller: ['$scope', '$uibModalInstance', '$filter', modalController]
        });
        function modalController($scope, $uibModalInstance, $filter) {
          $scope.title = title;
          $scope.helpText = $filter('translate')(
            helpText
          );
          $scope.cancel = function() {
            $uibModalInstance.dismiss('cancel');
          };
        }
      };


    }]
  };
}
