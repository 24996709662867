'use strict';

angular.module('postCenterWebClientApp')
  .directive('cannedResponseButton', function (){
    return {
      templateUrl: 'blocks/canned_response/views/canned_response_button.html',
      require: '^^cannedResponseContext',
      scope: {},
      controller: function(){},
      bindToController: {compact: '<?'},
      controllerAs: '$ctrl',
      replace: true,
      link: function(scope, elem, attrs, contextCtrl){
        scope.contextCtrl = contextCtrl;
      }
    };
  });
