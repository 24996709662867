'use strict';

interface WorkflowStatus {
  category: number
  kind: number
  label: string
  resource_id: string
}

angular
  .module('postCenterWebClientApp')
  .factory('WorkflowStatuses', WorkflowStatuses);

WorkflowStatuses.$inject = ['$q', 'AdRequest'];

function WorkflowStatuses($q, AdRequest) {

  var statuses = null;
  var service = {};

  service.getAll = function () {
    if (statuses) {
      return $q(function (resolve) {
        resolve(angular.copy(statuses));
      });
    }
    return service.fetchAll();
  };

  service.fetchAll = function () {
    return AdRequest
      .get('/api/v1/workflow_statuses/')
      .then(function (fetchedStatuses) {
        statuses = angular.copy(fetchedStatuses);
        return fetchedStatuses;
      });
  }

  service.clean = function() {
    statuses = null;
  };

  return service;
};
