const EpaResultsOrderBy = Vue.component('EpaResultsOrderBy', {
  props: {
    order: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      orderBy: null,
      filters: {
        resume: [
          {
            label: this.$tc('moreLess', 1, {name: this.$t('sended')}),
            order_by: {field: 'sent', sort: 'DESC'}
          },
          {
            label: this.$tc('moreLess', 2, {name: this.$t('sended')}),
            order_by: {field: 'sent', sort: 'ASC'}
          },
          {
            label: this.$tc('moreLess', 1, {name: this.$t('answered')}),
            order_by: {field: 'answered', sort: 'DESC'}
          },
          {
            label: this.$tc('moreLess', 2, {name: this.$t('answered')}),
            order_by: {field: 'answered', sort: 'ASC'}
          },
          {
            label: this.$tc('betterWorst', 1, {name: this.$t('responseRate')}),
            order_by: {field: 'percentage', sort: 'DESC'}
          },
          {
            label: this.$tc('betterWorst', 2, {name: this.$t('responseRate')}),
            order_by: {field: 'percentage', sort: 'ASC'}
          }
        ],
        res: [
          {
            label: this.$tc('betterWorst', 1, {name: this.$t('capacity')}),
            order_by: {field: 'capacity', sort: 'DESC'}
          },
          {
            label: this.$tc('betterWorst', 2, {name: this.$t('capacity')}),
            order_by: {field: 'capacity', sort: 'ASC'}
          }
        ],
        fcr: [
          {
            label: this.$tc('betterWorst', 1, {name: 'FCR'}),
            order_by: {field: 'fcr', sort: 'DESC'}
          },
          {
            label: this.$tc('betterWorst', 2, {name: 'FCR'}),
            order_by: {field: 'fcr', sort: 'ASC'}
          }
        ],
        csat: [
          {
            label: this.$tc('betterWorst', 1, {name: 'CSAT'}),
            order_by: {field: 'percentage', sort: 'DESC'}
          },
          {
            label: this.$tc('betterWorst', 2, {name: 'CSAT'}),
            order_by: {field: 'percentage', sort: 'ASC'}
          },
          {
            label: this.$tc('betterWorstNet', 1, {name: 'CSAT'}),
            order_by: {field: 'net', sort: 'DESC'}
          },
          {
            label: this.$tc('betterWorstNet', 2, {name: 'CSAT'}),
            order_by: {field: 'net', sort: 'ASC'}
          },
          {
            label: this.$tc('betterWorstAverage', 1, {name: 'CSAT'}),
            order_by: {field: 'average', sort: 'DESC'}
          },
          {
            label: this.$tc('betterWorstAverage', 2, {name: 'CSAT'}),
            order_by: {field: 'average', sort: 'ASC'}
          }
        ],
        ces: [
          {
            label: this.$tc('betterWorstNet', 1, {name: 'CES'}),
            order_by: {field: 'net', sort: 'DESC'}
          },
          {
            label: this.$tc('betterWorstNet', 2, {name: 'CES'}),
            order_by: {field: 'net', sort: 'ASC'}
          },
          {
            label: this.$tc('betterWorstAverage', 1, {name: 'CES'}),
            order_by: {field: 'average', sort: 'DESC'}
          },
          {
            label: this.$tc('betterWorstAverage', 2, {name: 'CES'}),
            order_by: {field: 'average', sort: 'ASC'}
          }
        ]
      }
    };
  },
  computed: {
    ...Vuex.mapGetters('epaAnalytics', ['activeTab']),
    selectedTab() {
      return this.activeTab.group;
    }
  },
  created() {
    const [selectedOrder] = this.order;
    this.orderBy = selectedOrder;
  },
  methods: {
    ...Vuex.mapActions('epaAnalytics', ['getChartByAgents']),
    updateGraph() {
      this.getChartByAgents({order_by: this.orderBy});
    }
  },
  template: `<div class="d-flex mb-1">
        <label class="mb-0">{{ $t('orderBy') }}:</label>
        <select @change="updateGraph()" v-model="orderBy" class="ml-1">
          <option
            v-for="(option, index) in filters[selectedTab]"
            :value="option.order_by"
            :key="index">
            {{ option.label }}
          </option>
        </select>
      </div>`,
  i18n: {
    messages: {
      es: {
        orderBy: 'Ordenado por',
        moreLess: 'Mas {name} | Menos {name}',
        betterWorst: 'Mejor {name} | Peor {name}',
        betterWorstNet: 'Mejor {name} neto | Peor {name} neto',
        betterWorstAverage: 'Mejor promedio {name} | Peor promedio {name}',
        sended: 'enviadas',
        answered: 'respondidas',
        responseRate: 'tasa de respuesta',
        capacity: 'capacidad'
      },
      pt: {
        orderBy: 'Ordenado por',
        moreLess: 'Mais {name} | Menos {name}',
        betterWorst: 'Melhor {name} | Pior {name}',
        betterWorstNet: 'Melhor {name} neto | Pior {name} neto',
        betterWorstAverage: 'Mejor promedio {name} | Pior promedio {name}',
        sended: 'enviado',
        answered: 'respondeu',
        responseRate: 'taxa de resposta',
        capacity: 'capacidade'
      },
      en: {
        orderBy: 'Order by',
        moreLess: 'Most {name} | Least {name}',
        betterWorst: 'Best {name} | Worst {name}',
        betterWorstNet: 'Best net {name} | Worst net {name}',
        betterWorstAverage: 'Best average {name} | Worst average {name}',
        sended: 'sent',
        answered: 'responded',
        responseRate: 'response rate',
        capacity: 'capacity'
      }
    }
  }
});
