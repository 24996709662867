/** @directive
 * @name AdClickOutside
 * @usage Runs a callback when user clicks outside an element
 * @usage <element v-click-outside="callback" /> */
Vue.directive('on-outer-click', {
  bind(element, binding, vnode) {
    window.onClickOutside = event => {
      if (element !== event.target || !element.contains(event.target)) {
        vnode.context[binding.expression](null);
      }
    };
    setTimeout(() =>
      document.body.addEventListener('click', window.onClickOutside)
    );
  },
  unbind(element) {
    document.body.removeEventListener('click', window.onClickOutside);
  }
});

Vue.directive('click-outside', {
  bind(el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unbind(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  }
});
