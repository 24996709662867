(function (module) {
  'use strict';
  adSignup.$inject = [
    'SignupService',
    'TIEMZONE_DB_KEY',
    'AdRequest',
    'RECAPTCHA_INVISIBLE_TOKEN'
  ];
  function adSignup(
    SignupService,
    TIEMZONE_DB_KEY,
    AdRequest,
    RECAPTCHA_INVISIBLE_TOKEN
  ) {
    return {
      restrict: 'E',
      link(scope, elem) {
        gtag('event', 'signup_start');

        const recaptchaToken = RECAPTCHA_INVISIBLE_TOKEN;
        Vue.directive('focus', {
          inserted(el) {
            el.focus();
          }
        });
        const locale = window.navigator.language.slice(0, 2) || 'es';
        const i18nsignup = new VueI18n({
          messages: signupMessages,
          locale,
          fallbackLocale: ['es', 'en', 'pt'],
          silentTranslationWarn: true
        });
        const setDefaultState = () => {
          return {
            counterSteps: 1,
            totalSteps: 2,
            terms: false,
            showPricing: false,
            timezone: {
              address: 'Chile',
              countryName: 'Chile',
              timezone: 'America/Santiago',
              country: {name: 'Chile', code: 'CL'}
            },
            newAccount: {
              first_name: '',
              last_name: '',
              enterprise: '',
              email: '',
              password: '',
              issues: '',
              industry: '',
              phone: '0',
              plan: 'adereso-professional-monthly-plan'
            },
            steps: [],
            solutions: [
              'omnichannel',
              'whatsapp_business_api',
              'automated_responses',
              'ticket_management_system',
              'performance_metrics',
              'other'
            ],
            industries: [
              'food',
              'real_estate',
              'construction',
              'energy',
              'finance',
              'hospitality',
              'media',
              'consumer_retail',
              'health',
              'transport',
              'other'
            ],
            prefix: [],
            accountStatus: {
              tryAgain: false
            },
            recaptcha: null,
            inputs: [
              {
                name: 'first_name',
                value: '',
                type: 'text',
                isValid: null,
                label: 'signUp.firstName.label',
                errorNumber: 0,
                errorMessage: 'signUp.firstName.errors',
                maxLength: 100,
                minLength: 3,
                autocomplete: 'new-name'
              },
              {
                name: 'last_name',
                value: '',
                type: 'text',
                isValid: null,
                label: 'signUp.lastName.label',
                errorNumber: 0,
                errorMessage: 'signUp.lastName.errors',
                maxLength: 100,
                minLength: 3,
                autocomplete: 'new-lastname'
              },
              {
                name: 'enterprise',
                value: '',
                type: 'text',
                isValid: null,
                label: 'signUp.establishment.label',
                errorNumber: 0,
                errorMessage: 'signUp.establishment.errors',
                maxLength: 50,
                minLength: 3,
                autocomplete: 'new-enterprise'
              },
              {
                name: 'email',
                value: '',
                type: 'email',
                isValid: null,
                label: 'signUp.email.label',
                errorNumber: 0,
                errorMessage: 'signUp.email.errors',
                maxLength: 70,
                autocomplete: 'new-email'
              },
              {
                name: 'password',
                value: '',
                type: 'password',
                isValid: null,
                label: 'signUp.password.label',
                errorNumber: 0,
                errorMessage: 'signUp.password.errors',
                maxLength: 50,
                minLength: 4,
                autocomplete: 'new-password'
              }
            ]
          };
        };
        const state = setDefaultState();
        const store = new Vuex.Store({
          state,
          getters: {
            terms: state => state.terms,
            newAccount: state => state.newAccount,
            newAccountValues: state => Object.values(state.newAccount),
            enterprise: state => state.newAccount.enterprise,
            steps: state => state.steps,
            prefix: state => state.prefix,
            counterSteps: state => state.counterSteps,
            totalSteps: state => state.totalSteps,
            accountStatus: state => state.accountStatus,
            recaptcha: state => state.recaptcha,
            inputs: state => state.inputs,
            industries: state => state.industries,
            solutions: state => state.solutions
          },
          mutations: {
            MUTATE(state, {key, value}) {
              state[key] = value;
            },
            SET_STEP(state, payload) {
              state.counterSteps = payload;
            },
            RESET_STEPS(state) {
              state.counterSteps = 1;
            },
            RESET_STORE(state, _state) {
              state = _state;
            },
            SET_STEPS(state, payload) {
              state.steps = payload;
            },
            UPDATE_STEPS(state, step) {
              state.steps.map(_step => {
                if (_step.name === step.name) {
                  _step.value = step.value;
                }
              });
            },
            SET_PREFIX(state, payload) {
              state.prefix = payload;
            },
            UPDATE_USER(state, payload) {
              state.newAccount = Object.assign(state.newAccount, payload);
            },
            UPDATE_TERMS(state, payload) {
              state.terms = payload;
            },
            SET_TIMEZONE(state, payload) {
              state.timezone = payload;
            },
            SET_STATUS(state, payload) {
              state.accountStatus = payload;
            },
            SET_RECAPTCHA(state, payload) {
              state.recaptcha = payload;
            }
          },
          actions: {
            handleStep({commit}, step) {
              commit('SET_STEP', step);
            },
            handleTerms({commit}, terms) {
              commit('UPDATE_TERMS', terms);
            },
            resetSteps({commit}) {
              commit('RESET_STEPS');
            },
            updateUser({commit}, user) {
              commit('UPDATE_USER', user);
            },
            updateStep({commit}, params) {
              commit('UPDATE_STEPS', params);
            },
            getSteps({commit}) {
              return SignupService.getSteps().then(resp => {
                commit('SET_STEPS', resp.data);
              });
            },
            async getPrefix({commit}) {
              await SignupService.getPrefix()
                .then(response => {
                  const {
                    zone: {
                      america: {north, caribean, central, south},
                      europe
                    }
                  } = response;
                  const prefixNumbers = [
                    ...north,
                    ...caribean,
                    ...central,
                    ...south,
                    ...europe
                  ];
                  commit('SET_PREFIX', prefixNumbers);
                })
                .catch(error => {
                  console.log(error);
                });
            },
            async register(_context, params) {
              return await SignupService.createAccount(params);
            },
            async getTimezone({commit}) {
              const url = new URL(
                'https://api.timezonedb.com/v2.1/get-time-zone'
              );

              const params = {
                key: TIEMZONE_DB_KEY,
                format: 'json',
                by: 'zone',
                zone: Intl.DateTimeFormat().resolvedOptions().timeZone
              };

              Object.keys(params).map(q =>
                url.searchParams.append(q, params[q])
              );
              fetch(url.href)
                .then(response => response.json())
                .then(response => {
                  const {countryName, countryCode} = response;
                  const {zone: timezone} = params;
                  const timezoneResponse = {
                    address: countryName,
                    countryName,
                    timezone,
                    country: {name: countryName, code: countryCode}
                  };
                  commit('SET_TIMEZONE', timezoneResponse);
                });
            },
            async createAccount({state, commit, dispatch}) {
              if (state.accountStatus.tryAgain) {
                commit('SET_STATUS', {
                  tryAgain: false
                });
              }
              const {
                first_name,
                last_name,
                password,
                email,
                phone,
                plan,
                enterprise,
                issues,
                industry
              } = state.newAccount;

              const {address, country, timezone} = state.timezone;
              const user = {
                first_name,
                last_name,
                password,
                phone,
                email: email.toLowerCase()
              };
              const establishment = {
                name: enterprise.toLowerCase(),
                phone,
                email: email.toLowerCase(),
                address,
                country,
                timezone,
                plan,
                issues,
                industry
              };
              const {recaptcha: response_user} = state;
              const params = {user, establishment, response_user};

              SignupService.createAccount(params)
                .then(({token}) => {
                  if (token) {
                    commit('SET_STATUS', {
                      tryAgain: false
                    });

                    gtag('event', 'sign_up', {method: 'Email'});
                    gtag('event', 'signup_success');

                    AdRequest.setToken(token);
                    dispatch('redirect');
                  }
                })
                .catch(_err => {
                  commit('SET_STATUS', {
                    tryAgain: true
                  });
                  gtag('event', 'signup_error');
                  dispatch('loadRecaptcha');
                });
            },
            accountStatus({commit}, status) {
              commit('SET_STATUS', status);
            },
            redirect({commit}, path = '#/onboarding') {
              commit('RESET_STORE', setDefaultState());
              setTimeout(() => {
                window.location.assign(path);
              }, 2000);
            },
            loadRecaptcha({dispatch}) {
              const promise = new Promise(resolve => {
                resolve(window['grecaptcha'].execute());
              });
              promise
                .then(() => {})
                .catch(() => {
                  // getRecaptcha again
                  dispatch('getRecaptcha');
                });
            },
            getRecaptcha({commit}) {
              window['grecaptcha'].ready(() => {
                window['grecaptcha'].render('signupRecaptcha', {
                  sitekey: recaptchaToken,
                  size: 'invisible',
                  badge: 'bottomright',
                  callback: token => {
                    commit('SET_RECAPTCHA', token);
                  }
                });
              });
            },
            registerEvents(_context, event) {
              const {name, params} = event;
              gtag('event', name, params);
            },
            updateNewAccount({commit, state}, payload) {
              const {newAccount} = state;
              commit('MUTATE', {
                key: 'newAccount',
                value: {...newAccount, ...payload}
              });
            },
            async checkInput({dispatch}, input) {
              try {
                const {name, value} = input;
                if (value) {
                  const asyncSwitch = async () => {
                    switch (name) {
                      case 'first_name':
                      case 'last_name':
                        return dispatch('checkName', value);
                      case 'enterprise':
                        return dispatch('checkEstablishment', value);
                      case 'email':
                        return dispatch('checkEmail', value);
                      case 'password':
                        return dispatch('checkPass', value);
                      default:
                        return [false, 1];
                    }
                  };
                  return await asyncSwitch();
                } else {
                  return [false, 1];
                }
              } catch (error) {
                return [false, 1];
              }
            },
            checkName(_context, value) {
              const regName =
                /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/g;
              const nameTest = value.trim();
              const checkName = regName.test(nameTest);
              const checkLength =
                nameTest.length >= 3 && nameTest.length <= 100;

              if (!checkLength) {
                return [false, 1];
              } else if (!checkName) {
                return [false, 2];
              } else {
                return [true, 0];
              }
            },
            checkEstablishment(_context, establishment) {
              const establishmentTest = !establishment
                ? establishment
                : establishment.trim();
              const checkLength =
                establishmentTest.length >= 3 && establishmentTest.length <= 50;
              if (!establishmentTest) {
                return [false, 0];
              } else if (!checkLength) {
                return [false, 1];
              } else {
                return SignupService.checkName(establishmentTest)
                  .then(() => {
                    return [true, 0];
                  })
                  .catch(error => {
                    const {status} = error;
                    if (status === 403) {
                      return [false, 2];
                    }
                  });
              }
            },
            checkEmail(_context, email) {
              const regexEmail =
                /^(([^ñá-úÁ-Úà-ùÀ-Ù`´'<>()\[\]\\.,;:\s@"]+(\.[^ñá-úÁ-Úà-ùÀ-Ù`´'<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              const emailTest = email.trim();
              if (regexEmail.test(emailTest)) {
                return SignupService.checkEmail(email)
                  .then(() => {
                    return [true, 0];
                  })
                  .catch(error => {
                    const {status} = error;
                    if (status === 403) {
                      return [false, 2];
                    } else if (status === 400) {
                      return [false, 1];
                    } else {
                      return [false, 3];
                    }
                  });
              } else {
                return [false, 0];
              }
            },
            checkPass(_context, pass) {
              // const passRegex = /^[A-Za-z0-9]+$/g;
              const passTest = pass?.trim();
              const passLength = passTest.length >= 4 && passTest.length <= 50;

              return passLength ? [true, 0] : [false, 1];
            },
            updateInputs({commit, state}, payload) {
              const {inputs} = state;
              const input = inputs[payload[0]];

              commit('MUTATE', {
                key: input,
                value: Object.assign(input, payload[1])
              });
            }
          }
        });
        scope.vue = new Vue({
          el: elem[0].querySelector('.ng-non-bindable'),
          i18n: i18nsignup,
          store,
          mounted() {
            this.$store.dispatch('getSteps');
            this.$store.dispatch('getTimezone');
            this.$store.dispatch('getPrefix');
            this.$store.dispatch('getRecaptcha');
          }
        });
      }
    };
  }
  module.directive('adSignup', adSignup);
})(angular.module('postCenterWebClientApp'));
