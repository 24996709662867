'use strict';
(function (module) {
  'use strict';
  userOrdersView.$inject = [];

  function userOrdersView() {
    return {
      templateUrl: 'blocks/case/views/user_orders_view.html',
      restrict: 'E',
      scope: {
        userObject: '='
      },
      controller: 'userOrdersCtrl',
      controllerAs: 'vm',
      bindToController: true
    };
  }
  module.directive('userOrdersView', userOrdersView);
})(angular.module('postCenterWebClientApp'));
