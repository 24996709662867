'use strict';

class CmSelectorCtrl {

  selectCallback: Function
  activeIndex = -1
  popoverOpen = false
  typeaheadText = ''
  resultElements = []
  filteredCms = []
  cmChoices = []
  loading = false

  departmentNames
  waitingAgent = true
  agentInitials = null
  selectedCm
  currentCase
  containerElement
  $q

  private inputElementDeferred
  private inputElementPromise

  private CosHandler: CosHandler
  private $filter
  private $timeout
  private $scope
  private AdDataConnector

  private filterCmsWatcher
  private waitingAgentWatcher
  private popoverOpenWatcher
  private selectedCmWatcher

  constructor (
    $scope,
    $rootScope,
    $q,
    $filter,
    $timeout,
    CaseService,
    AdDataConnector,
    CosHandler: CosHandler,
    EstablishmentService: EstablishmentService,
    SocialAccountService: SocialAccountService,
    DepartmentService: DepartmentService
  ) {

    this.departmentNames = {}
    DepartmentService.getDepartments().then(departments => {
      departments.forEach(d => {
        this.departmentNames[d.id] = d.name;
      });
    })

    this.$q = $q
    this.$scope = $scope
    this.$filter = $filter
    this.$timeout = $timeout
    this.AdDataConnector = AdDataConnector
    this.CosHandler = CosHandler
    this.inputElementDeferred = $q.defer();
    this.inputElementPromise = this.inputElementDeferred.promise;
    this.filterCmsWatcher = $scope.$watchCollection('ctrl.filteredCms', filteredCms => {
      this.activeIndex = filteredCms && filteredCms.length > 0 ? 0 : -1;
    })

    this.waitingAgentWatcher = $rootScope.$watch('waitingAgent', (newValue, _oldValue) => {
      if (newValue) {
        if (newValue.resource_id === this.currentCase.resource_id) {
          this.waitingAgent = true;
          this.selectedCm = null;
          this.agentInitials = null;
        }
      }
    })
    
    this.popoverOpenWatcher = $scope.$watch('ctrl.popoverOpen', popoverOpen => {
      if (popoverOpen) {
        SocialAccountService.getSocialAccounts().then(accounts => {
          let socialAccounts = this.currentCase.establishment_users.map(account => {
            return AdDataConnector.get_social_account_id_from_uid(
              account.uid,
              accounts
            );
          });

          if (this.cmChoices.length === 0) {
            this.loading = true;
          }

          this.syncCms(socialAccounts)
        })
      } else {
        this.$scope.$applyAsync(() => {
          this.typeaheadText = '';
          this.filteredCms = [];
        });
        this.inputElementDeferred = $q.defer();
        this.inputElementPromise = this.inputElementDeferred.promise;
      }
    });

    $scope.$on('$destroy', () => {
      this.filterCmsWatcher();
      this.waitingAgentWatcher();
      this.popoverOpenWatcher();
      this.selectedCmWatcher();
    })

    const getInitials = agent => {
      return agent ? CaseService.getInitials(agent.name) : '';
    };
    $rootScope.waitingAgent = null;
    EstablishmentService.getEstablishment().then(establishment => {
      const {
        config: {enable_queued_assignment: hasLimits}
      } = establishment
      this.waitingAgent = hasLimits && !this.selectedCm;
      this.agentInitials = getInitials(this.selectedCm);

      this.selectedCmWatcher = $scope.$watch('ctrl.selectedCm', (newValue, oldValue) => {
        if (!_.isEqual(newValue, oldValue)) {
          this.agentInitials = getInitials(this.selectedCm);
          if (newValue && $rootScope.waitingAgent) {
            $rootScope.waitingAgent = null;
          }
          this.waitingAgent =
            (!newValue && $rootScope.waitingAgent) ||
            (!newValue && hasLimits);
        }
      })
    })
  }

  private getDepartmentName(department_id: number) {
    let name = this.departmentNames[department_id]
    if (name) return name
    return '-'
  }

  private compareByName(cm1, cm2) {
    if (cm1.name === cm2.name) {
      return 0;
    } else if (cm1.name > cm2.name) {
      return 1;
    } else {
      return -1;
    }
  }

  private syncCms(socialAccounts: SocialNetworkUser[]) {
    this.CosHandler.getCms().then(
      cms => {
        this.cmChoices = [];
        for (let index = 0; index < cms.length; index++) {
          if (!cms[index].is_assignable) {
            continue;
          }
          let assignation = cms[index].assignations;

          for (
            let jindex = 0;
            jindex < socialAccounts.length;
            jindex++
          ) {
            if (
              this.AdDataConnector.get_assignation_mode(
                assignation,
                socialAccounts[jindex]
              ) > 0 &&
              !this.cmChoices.includes(cms[index])
            ) {
              this.cmChoices.push(cms[index]);
            }
          }
        }
        if (this.selectedCm) {
          let selectedIndex = _.findIndex(
            this.cmChoices,
            cm => cm.cmid === this.selectedCm.cmid
          )
          if (selectedIndex > -1) {
            let selectedCmOnlineStatus =
              this.cmChoices[selectedIndex].online_status;
            let selectedCmDepartments =
              this.cmChoices[selectedIndex].departments;
            this.cmChoices.splice(selectedIndex, 1);
            this.cmChoices.sort((cm1, cm2) => this.compareByName(cm1, cm2));

            let cmToInsert = angular.copy(this.selectedCm);
            cmToInsert.online_status = selectedCmOnlineStatus;
            cmToInsert.departments = selectedCmDepartments;
            this.cmChoices.splice(0, 0, cmToInsert);
          } else {
            this.cmChoices.sort((cm1, cm2) => this.compareByName(cm1, cm2));
          }
        } else {
          this.cmChoices.sort((cm1, cm2) => this.compareByName(cm1, cm2));
        }

        this.cmChoices.push({
          $nobody: true,
          name: this.$filter('translate')('CASECONTROL_CM_NO_ONE')
        });

        this.inputElementPromise.then(inputElement => {
          this.$scope.$applyAsync(() => {
            this.$timeout(() => {
              inputElement.focus();
            }, 10);
          });
        });
        this.loading = false;
      },
      error => {
        this.loading = false;
      }
    )
  }

  private getIthResultDOMElement(index) {
    return this.resultElements[index];
  }

  private getResultContainerElement() {
    return this.containerElement;
  }

  private handleScroll() {
    const container = this.getResultContainerElement();
    const containerHeight = container.height();
    const result = this.getIthResultDOMElement(this.activeIndex);
    const resultHeight = result.outerHeight();
    const resultOffset = result.offset().top - container.offset().top;
    const scrollUpwardsDelta = -resultOffset;
    const scrollDownwardsDelta =
      resultOffset + resultHeight - containerHeight;

    if (scrollUpwardsDelta > 0) {
      container.scrollTop(container.scrollTop() - scrollUpwardsDelta);
    }

    if (scrollDownwardsDelta > 0) {
      container.scrollTop(container.scrollTop() + scrollDownwardsDelta);
    }
  }

  handleInput(e) {
    let DOWN = 40,
      UP = 38,
      ENTER = 13,
      ESC = 27;
    switch (e.which) {
      case DOWN:
        this.activeIndex = keepInBoundaries(this.activeIndex + 1);
        this.handleScroll();
        e.preventDefault();
        break;
      case UP:
        this.activeIndex = keepInBoundaries(this.activeIndex - 1);
        this.handleScroll();
        e.preventDefault();
        break;
      case ENTER:
        this.selectMatch(this.activeIndex);
        e.preventDefault();
        e.stopPropagation();
        break;
      case ESC:
        this.popoverOpen = false;
        e.preventDefault();
        e.stopPropagation();
        break;
    }

    function keepInBoundaries(index) {
      let filteredCms = this.filteredCms || [];
      if (filteredCms.length === 0) {
        return -1;
      }
      if (index < 0) {
        return 0;
      }
      if (index >= filteredCms.length) {
        return filteredCms.length - 1;
      }
      return index;
    }
  }

  setInputElem(element) {
    this.inputElementDeferred.resolve(element);
  };

  setIthResultElem(index, element) {
    this.resultElements[index] = element;
  };

  setContainerElem(element) {
    this.containerElement = element;
  };

  selectMatch(index) {
    if (index === -1) {
      return;
    }
    let selectedCm = this.filteredCms[index];

    if (index === -2) {
      selectedCm = this.filteredCms.pop();
    }

    if (selectedCm.$nobody) {
      selectedCm = null;
    }
    this.popoverOpen = false;
    this.loading = true;
    this.$q.when(this.selectCallback({selectedCm})).finally(() => {
      this.loading = false;
    })
  }
}

angular
  .module('postCenterWebClientApp')
  .controller('CmSelectorCtrl', CmSelectorCtrl);


function cmSelector() {
  return {
    bindToController: {
      selectedCm: '=',
      fromColumn: '=',
      selectCallback: '&',
      currentCase: '='
    },
    scope: {},
    controllerAs: 'ctrl',
    templateUrl: 'blocks/case/views/cm_selector.html',
    restrict: 'E',
    replace: true,
    controller: 'CmSelectorCtrl'
  };
}

angular
  .module('postCenterWebClientApp')
  .directive('cmSelector', cmSelector);
