(module => {
  'use strict';
  module.directive('closeTicketsMassive', closeTicketsMassive);
  function closeTicketsMassive() {
    return {
      templateUrl:
        'blocks/admin/views/massive_actions/close_tickets/close_tickets.html',
      restrict: 'E',
      controller: 'massCloseTicketsCtrl',
      controllerAs: 'vm'
    };
  }
})(angular.module('postCenterWebClientApp'));
