(function (module){
    'use strict';
    autoFocus.$inject = ['$timeout'];
    function autoFocus(timeout) {
        return {
            scope: {
                trigger: '@autoFocus'
            },
            link(scope, element) {
                scope.$watch('trigger', value => {
                    if(value === "true") {
                        timeout(function() {
                            element[0].focus();
                        });
                    }
                });
            }
        };
    }
    module.directive('autoFocus', autoFocus)
})(angular.module('postCenterWebClientApp'));
