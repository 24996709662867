conditions.MessageoOutboundKeywordsCondition = conditions.Condition.createConditionType(
  'MESSAGE_OUTBOUND_KEYWORDS',
  {
    isMessageKeywords: function () {
      return true;
    },
    serialize: function () {
      var keywords = this.keywords.split(',').map(function (v) {
        return v.trim()
      });
      return [this.identifier, keywords]
    }
  },
  function (keywords) {
    this.keywords = keywords;
  }
);

conditions.MessageoOutboundKeywordsCondition.parse = function (conditionList) {
  var commaSeparated = conditionList[1].join(', ');
  return new conditions.MessageoOutboundKeywordsCondition(commaSeparated)
};


conditions.MessageKeywordsCondition = conditions.Condition.createConditionType(
  'MESSAGE_KEYWORDS',
  {
    isMessageKeywords: function () {
      return true;
    },
    serialize: function () {
      var keywords = this.keywords.split(',').map(function (v) {
        return v.trim()
      });
      return [this.identifier, keywords]
    }
  },
  function (keywords) {
    this.keywords = keywords;
  }
);
conditions.Condition.isMessageKeywords = function () {
  return false;
};
conditions.MessageKeywordsCondition.parse = function (conditionList) {
  var commaSeparated = conditionList[1].join(', ');
  return new conditions.MessageKeywordsCondition(commaSeparated)
};


conditions.MessageMatchesRegexCondition = conditions.Condition.createConditionType(
  'MESSAGE_MATCHES',
  {
    isMessageMatchesRegex: function () {
      return true;
    },
    serialize: function () {
      return [this.identifier, this.regex]
    }
  },
  function (regex) {
    this.regex = regex;
  }
);
conditions.Condition.isMessageMatchesRegex = function () {
  return false;
};
conditions.MessageMatchesRegexCondition.parse = function (conditionList) {
  return new conditions.MessageMatchesRegexCondition(conditionList[1])
};
