interface Department {
  id: number
  name: string
}

interface DepartmentWithUsers extends Department {
  users: UserSummaryForDepartments
}

class DepartmentService {
  private AdRequest: AdRequestService
  private deptsModel: AdModelFetcher<DepartmentWithUsers[]>
  private $rootScope

  constructor(AdRequest: AdRequestService, $rootScope) {
    this.AdRequest = AdRequest
    this.$rootScope = $rootScope
    this.deptsModel = new AdModelFetcher(() => {
      let depts = this.AdRequest.get<DepartmentWithUsers[]>('/api/v1/departments/')
      $rootScope.departments = depts
      return depts
    })
    AdRequest.loggedOut.subscribe(() => this.deptsModel.clear())
  }

  getDepartments(): Promise<DepartmentWithUsers[]> {
    return this.deptsModel.get()
  }

  fetchDepartments(): Promise<DepartmentWithUsers[]> {
    return this.deptsModel.fetch()
  }

  getAllDepartments(): Promise<Department[]> {
    // Returns all departments, including deleted ones
    return this.AdRequest.get('/api/v1/all_departments/')
  }

  addDepartment(data, config) {
    return this.AdRequest.post('/api/v1/departments/create/', data, config);
  }

  editDepartment(data, config) {
    return this.AdRequest.put(`/api/v1/department/${data.id}/`, data, config);
  }

  deleteDepartment(department_id) {
    return this.AdRequest.delete(`/api/v1/department/${department_id}/`);
  }

  getHistory(page = 0) {
    return this.AdRequest.get(`/api/v1/department/history/${page}/`);
  }
}

angular
  .module('postCenterWebClientApp')
  .service('DepartmentService', DepartmentService);
