const SurveyCard = Vue.component('SurveyCard', {
  props: {
    survey: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      active: false,
      toggleLabel: 0
    };
  },
  computed: {
    ...Vuex.mapGetters('epaConfig', ['editingSurvey']),
    ...Vuex.mapGetters('socialAccounts', ['accounts']),
    activeAccounts() {
      const {accounts} = this.survey;
      return accounts.length > 0 ? this.getAccounts() : [];
    },
    switchLabel() {
      return this.survey.is_active ? 0 : 1;
    },
    isValid() {
      const {name, accounts} = this.survey;

      return name && accounts.length > 0;
    },
    accountsNumber() {
      return this.activeAccounts.length > 1 ? 2 : 1;
    },
    formatDate() {
      return moment.unix(this.survey.created_at).format('DD MMM YYYY');
    },
    toggleActive() {
      return this.survey.id === this.editingSurvey.id;
    }
  },
  methods: {
    ...Vuex.mapActions(['notify']),
    ...Vuex.mapActions('epaConfig', [
      'toggleSurvey',
      'openSurvey',
      'getSurveys',
      'toggleModal'
    ]),
    getAccounts() {
      const {accounts} = this.survey;

      const surveyAccounts = this.accounts.filter(idAccount => {
        const usedAccount = accounts.find(account => account === idAccount.id);
        if (usedAccount) {
          return usedAccount;
        }
      });
      return surveyAccounts;
    },
    toggleSwitch() {
      const {is_active: isActive} = this.survey;
      this.toggleLabel = isActive ? 0 : 1;
      this.toggleSurvey(this.survey)
        .then(() => {
          this.getSurveys();
        })
        .catch(({status}) => {
          if (status === 400) {
            this.notify({
              message: this.$t('toast.errors.active'),
              type: 'error'
            });
            this.getSurveys();
          }
        });
    }
  },
  template: `<div class="survey-widget"
      :class="{'active': toggleActive}">
      <button @click="openSurvey(survey)" class="open-survey" />
              <div class="header">
                <h6 v-if="survey.name">{{ survey.name }}</h6>
                <h6 v-else>{{ $t('withoutName') }}</h6>
                <span>{{ formatDate }}</span>
                <p class="d-flex space-between toggle-survey text-center">
                  {{ $tc('toggleSurvey', switchLabel) }}
                  <ToggleSwitchModel
                    class="ml-1"
                    v-model="survey.is_active"
                    :disabled="!isValid"
                    @change="toggleSwitch()" />

                  <SurveyOptions :survey="survey.id" />
                </p>
              </div>
              <div class="accounts" v-if="activeAccounts.length > 0">
                <h6>{{activeAccounts.length}} {{ $tc('accounts', accountsNumber) }}</h6>
                <AvatarGroup :avatars="activeAccounts" :stop-number="10" />
              </div>
              </div>`
});
const SurveyOptions = Vue.component('SurveyOptions', {
  props: {
    survey: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      show: false
    };
  },
  methods: {
    ...Vuex.mapActions('epaConfig', ['removeSurvey']),
    hideToggle() {
      this.show = false;
    },
    toggleList(event) {
      event.stopPropagation();
      this.show = !this.show;
    }
  },
  template: `<button class="btn btn-link survey-options" @click="toggleList($event)" v-click-outside="hideToggle">
    <span class="fa fa-ellipsis-v-fas" />
    <ul class="list" v-if="show">
      <li>
        <button class="btn btn-link p-0 d-flex align-center" @click="removeSurvey(survey)">
          <span class="fa fa-trash mr-1" />
          {{ $t('buttons.removeSurvey') }}
        </button>
      </li>
    </ul>
  </button>`
});
