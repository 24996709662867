'use strict';

angular.module('postCenterWebClientApp').directive('userStatus', () => {
  return {
    restrict: 'E',
    templateUrl: 'blocks/top_bar/views/user_status.html',
    controller: [
      '$scope',
      '$window',
      'AdPolling',
      'UserService',
      'UserData',
      'CustomOnlineStatuses',
      'AdNotification',
      userStatusController
    ],
    controllerAs: 'vm',
    replace: true
  };
});

function userStatusController(
  $scope,
  $window,
  AdPolling,
  UserService,
  UserData,
  CustomOnlineStatuses,
  AdNotification
) {
  const SECONDS_TO_POLLING = 30;
  const vm = this;

  vm.isScreenLocked = !navigator.onLine;
  vm.screenLockReason = (!navigator.onLine && 'offline') || null;

  vm.statusCollapsed = true;

  vm.userStatus = UserService.currentUserStatus;
  vm.currentCOS = vm.userStatus.custom_online_status;
  vm.setCOS = function (cos) {
    const previousCOS = vm.currentCOS;
    vm.currentCOS = cos;
    UserService.updateUserStatus(cos)
      .then(refreshUserStatus)
      .catch(error => {
        AdNotification.error(error, 'cos_updated');
        vm.currentCOS = previousCOS;
      });
  };

  vm.cosList = [];
  CustomOnlineStatuses.fetch().then(function () {
    vm.cosList = CustomOnlineStatuses.list();
  });

  $scope.$watch(
    function () {
      return CustomOnlineStatuses.list();
    },
    function (newCOSes) {
      vm.cosList = newCOSes;
    },
    true
  );

  listWindowConnectionEvents();
  doPolling();

  function refreshUserStatus() {
    const {currentUserStatus} = UserService;
    vm.userStatus = currentUserStatus;
    vm.currentCOS = currentUserStatus.custom_online_status;
    if (vm.currentCOS.blocks === true) {
      vm.isScreenLocked = true;
      vm.screenLockReason = 'custom_online_status';
    } else if (navigator.onLine) {
      vm.isScreenLocked = false;
    }
  }

  function listWindowConnectionEvents() {
    $window.addEventListener('online', function () {
      $scope.$apply(function () {
        vm.isScreenLocked = false;
        vm.screenLockReason = null;
      });
    });

    $window.addEventListener('offline', function () {
      $scope.$apply(function () {
        vm.isScreenLocked = true;
        vm.screenLockReason = 'offline';
      });
    });
  }

  function doPolling() {
    var statusPoll = AdPolling.start(
      UserData.fetchUserStatus,
      SECONDS_TO_POLLING,
      refreshUserStatus,
      () => {}
    );
    $scope.$on('$destroy', function () {
      statusPoll.stop();
    });
  }
}
