Vue.component('ChannelNotificationMessage', {
  props: {
    text: {type: String, required: true}
  },
  template: '<p v-html="text"></p>'
});
Vue.component('ChannelNotificationIcon', {
  template: '<span class="fa"></span>'
});

angular.module('postCenterWebClientApp').directive('notifyWrapper', function() {
  return {
    restrict: 'E',
    link: function(scope, elem, attrs) {
      // vue component
      let icon = attrs.icon;
      if (icon === 'email') {
        icon = 'envelope';
      }
      if (icon === 'chat' || icon === 'app_store' || icon === 'play_store') {
        icon = 'postcenter';
      }
      scope.vue = new Vue({
        el: elem[0].querySelector('.ng-non-bindable'),
        data: {
          text: attrs.text,
          icon: `fa-${icon}`
        }
      });
    }
  };
});
