class ColumnExportService {
  constructor(AdRequest, BASE_URL) {
    this.AdRequest = AdRequest;
    this.BASE_URL = BASE_URL;
  }

  getExportedColumns(page = 1) {
    return this.AdRequest.get(`/api/v1/columns/export/${page}`);
  }

  getColumn(idColumn) {
    return this.AdRequest.get(`/api/v1/column/export/${idColumn}/`);
  }

  getExport(column) {
    if (column.column_type === 'campaign' || column.column_type === 'contact_book') {
      return this.AdRequest.get(`/api/v1/custom/export/${column.id}/`);
    } else {
      return this.AdRequest.get(`/api/v1/column/export/${column.resource_id}/`);
    }
  }

  deleteColumn(column) {
    if (column.column_type === 'campaign' || column.column_type === 'contact_book') {
      return this.AdRequest.delete(`/api/v1/custom/export/${column.id}/`);
    } else {
      return this.AdRequest.delete(`/api/v1/column/export/${column.resource_id}/`);
    }
  }
  updateColumns(columns) {
    const promises = [];
    columns.forEach(column => {
      promises.push(this.updateColumn(column));
    });
    return Promise.all(promises);
  }
  getRealPath(exportedColumn) {
    if (exportedColumn['full_path']) {
      return exportedColumn['full_path'];
    } else {
      const {status, filename} = exportedColumn;

      if (
        status === 'WAITING_FOR_PUBLICATION' ||
        status === 'ERROR_UPLOADING_BUCKET' ||
        status === 'FILE_ON_MACHINE'
      ) {
        return `${this.BASE_URL}/api/media/${filename}`;
      } else {
        return `https://storage.googleapis.com/${exportedColumn.bucket}/${filename}`;
      }
    }
  }
  updateColumn(column) {
    return this.AdRequest.get(
      `/api/v1/column/export/${column.resource_id}/`
    ).then(updatedColumn => {
      $.extend(column, updatedColumn);
    });
  }
}
angular
  .module('postCenterWebClientApp')
  .service('ColumnExportService', ColumnExportService);
