'use strict';

var app = angular.module('postCenterWebClientApp');

app.directive('slackConfiguration', function () {
  return {
    templateUrl: 'blocks/admin/views/integration_config/slack.html',
    restrict: 'E',
    scope: {
      config: '='
    },
    controller: [
      '$scope', '$rootScope', 'AdRequest', '$filter', 'BusinessRulesService',
      '$q', 'SLACK_CLIENT_ID', 'BASE_URL', function ($scope, $rootScope,
      AdRequest, $filter, BusinessRulesService, $q, SLACK_CLIENT_ID, BASE_URL
    ) {

        $scope.availableTriggers = [rules.triggers.MessageTrigger];

        $scope.availableConditions = [
          rules.conditions.SocialAccountCondition.identifier,
          rules.conditions.CaseTypificationCondition.identifier,
          rules.conditions.MessageKeywordsCondition.identifier,
          rules.conditions.MessageMatchesRegexCondition.identifier,
          rules.conditions.InBusinessTimeCondition.identifier,
          rules.conditions.NotInBusinessTimeCondition.identifier,
          rules.conditions.InWeekdayCondition.identifier,
          rules.conditions.NotAssignedToCMCondition.identifier,
          rules.conditions.InDateTimeRangeCondition.identifier,
          rules.conditions.InTimeRangeCondition.identifier,
          rules.conditions.TicketNotAddressedCondition.identifier
        ];

        $scope.actionTypes = [
          rules.actions.SlackSendMessageAction.identifier,
        ];

        BusinessRulesService.get().then(function (rules) {
          $scope.rules = rules.filter(function (rule) {
            return $scope.actionTypes.includes(rule.actions[0].identifier)
          });
        });

        $scope.addRule = function () {
          $scope.rules.push(rules.BusinessRule.createEmptyRule())
        };

        // https://api.slack.com/docs/oauth
        var slack_base = 'https://slack.com/oauth/authorize';
        // var postcenter_base = BASE_URL;
        var postcenter_base = 'https://app.postcenter.io';
        var client_id = SLACK_CLIENT_ID;
        var scope = 'chat:write:bot';
        var redirect_uri = postcenter_base + '/#/admin/integrations/';
        var state = Math.floor(Math.random() * 1000) + 100;
        // Slack team ID of a workspace to attempt to restrict to (optional)
        var team;
        var slackUrl = slack_base + "?client_id=" + client_id;
        slackUrl = slackUrl + "&scope=" + scope;
        slackUrl = slackUrl + "&redirect_uri=" + redirect_uri;
        slackUrl = slackUrl + "&state=" + state;

        $scope.slackUrl = slackUrl;

        $scope.saveSlackConfig = function () {
          var data = {
            active: $scope.config.active,
            token: $scope.config.token
          };
          AdRequest.post('/api/v1/slack/config', data).then().then(
            function (result) {
              $rootScope.$emit(
                'successMessage',
                $filter('translate')('ADMIN_INTEGRATION_SAVE_SUCCESS')
              );
              // $scope.config.showing = false;
            },
            function (error) {
              $rootScope.$emit(
                'errorMessage',
                $filter('translate')('ADMIN_INTEGRATION_SAVE_ERROR')
              )
            }
          )
        }
      }

    ]
  };
});
