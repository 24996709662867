rules.conditions.Condition = {

  subtypes: {},

  isGroup: function () {
    return false;
  },

  isBusinessRule: function () {
    return false;
  },

  createConditionType: rules.createClassFunction,

  serialize: function () {
    return [this.identifier]
  },

  parse: function (conditionList) {
    var conditionId = conditionList[0];
    if (conditionId === 'AND' || conditionId === 'OR' || conditionId === 'NOT') {
      return rules.conditions.GroupCondition.parse(conditionList)
    }
    else {
      var conditionClass = this.subtypes[conditionList[0]];
      if (conditionClass === undefined) {
        return new rules.conditions.UnknownCondition(conditionList)
      }
      return conditionClass.parse(conditionList)
    }
  }
};
var conditions = rules.conditions;

conditions.UnknownCondition = function (conditionList) {
  this.identifier = conditionList[0];
  this.condition = conditionList
};
conditions.UnknownCondition.prototype = conditions.Condition;

conditions.EmptyCondition = (function () {
  var emptyConstructor = function () {};
  emptyConstructor.prototype = conditions.Condition;
  return emptyConstructor
})();

conditions.GroupCondition = conditions.Condition.createConditionType(
  'GROUP',
  {
    isGroup: function () {
      return true;
    },
    options: ['AND', 'OR', 'NOT'],
    canAddChild: true,

    refreshCanAddChild: function() {
      this.canAddChild = !(this.type === 'NOT' && this.conditions.length > 0);
      if (!this.canAddChild && this.conditions.length > 1) {
        this.conditions.splice(1, this.conditions.length - 1)
      }
    },

    removeChild: function (child) {
      var index = this.conditions.indexOf(child);
      this.conditions.splice(index, 1);
    },

    replaceChild: function (oldChild, newChild) {
      var index = this.conditions.indexOf(oldChild);
      this.conditions[index] = newChild;
    },

    serialize: function () {
      return [this.type].concat(
        this.conditions.map(function (expr) {
          return expr.serialize()
        })
      )
    }
  },
  function (type, conditions) {
    this.type = type;
    this.conditions = conditions;
    if (conditions === undefined) {
      this.conditions = [new rules.conditions.EmptyCondition()];
    }
    this.refreshCanAddChild()
  }
);
conditions.GroupCondition.parse = function (conditionList) {
  var operation = conditionList[0];
  var children = conditionList.splice(1);
  return new conditions.GroupCondition(
    operation,
    children.map(function (childList) {
      return conditions.Condition.parse(childList)
    })
  )
};
