(function(module) {
  'use strict';
  DepartmentCtrl.$inject = [
    'DepartmentService',
    'AdNotification',
    '$scope',
    '$rootScope',
    'UsersService',
    '$filter',
    'FireTagService',
    "UserService"
  ];
  function DepartmentCtrl(
    DepartmentService,
    AdNotification,
    $scope,
    $rootScope,
    UsersService,
    $filter,
    FireTagService,
    UserService: UserService
  ) {
    const vm = this;
    const translate = key => $filter('translate')(key);

    vm.$onInit = () => {
      $scope.CtrlReady = true;

      FireTagService.setPageView({
        title: 'Departamentos',
        path: `/${window.location.hash}`
      });
      $rootScope.adminTab.selected = 'departments';
      $scope.activityLog = [];
      $scope.teamsEnabled = $rootScope.establishment.features["supervisor_role"];

      $scope.departmentById = {};
      $scope.allDepartmentsById = {};
      $scope.pagination = {
        pageNumber: 1
      };
      $scope.users = {};
      $scope.departments = [];
      $scope.formatedUsers = {};
      $scope.multiSelectTranslations = {
        selectAll: translate('MULTISELECT_SELECT_ALL'),
        selectNone: translate('MULTISELECT_SELECT_NONE'),
        reset: translate('MULTISELECT_RESET'),
        search: translate('MULTISELECT_SEARCH'),
        nothingSelected: translate('MULTISELECT_NOTHING_SELECTED')
      };
      _getData(true);

      vm.watchPagination = $scope.$watch('pagination.pageNumber', function(
        newValue,
        oldValue
      ) {
        if (oldValue !== newValue) {
          newValue = newValue - 1;
          _refreshHistory(newValue);
        }
      });
      $scope.loading = false;
    };

    vm.$onDestroy = () => {
      vm.watchPagination();
      vm.createDepartmentListener();
    };
    vm.createDepartmentListener = $rootScope.$on(
      'admin:createDepartment',
      function() {
        if ($scope.CtrlReady) {
          _getData(true);
        }
      }
    );
    const _getData = (force = false) => {
      Promise.all([_getDepartments(force), _getResumeUsers()]).then(resp => {
        const [departments, resumeUsers] = resp;
        _setDepartments(departments);
        _updateUsers(resumeUsers);
        _getUsersDepartments();
        _getAllDepartments().then(() => {
          _refreshHistory();
        });
        vm.UserService.getProfile().then(user => ($scope.user = user));
      });
    };

    const _getDepartments = async (forced = false) => {
      const {departments} = $rootScope;
      if (departments && !forced) {
        return departments;
      } else {
        return await DepartmentService.fetchDepartments().then(r => {
          return r;
        });
      }
    };
    const _setDepartments = departments => {
      $scope.departments = departments;
    };
    const _getAllDepartments = async () => {
      await DepartmentService.getAllDepartments().then(departments => {
        for (const department of departments) {
          const {id, name} = department;
          $scope.allDepartmentsById[id] = name;
        }
      });
    };
    const _getResumeUsers = async () => {
      let resp;
      try {
        const _resp = await UsersService.getResumeUsers();
        resp = _resp;
      } catch (err) {
        resp = [];
        throw new Error(err);
      }
      return resp;
    };
    const _updateUsers = users => {
      $scope.users = {}; // for changes!
      for (const user of users) {
        const {id, full_name: fullName} = user;
        $scope.users[id] = {
          name: fullName,
          label: fullName,
          id: id,
          ticked: false,
          originalTicket: false
        };
      }
      return $scope.users;
    };
    const _getUsersDepartments = () => {
      for (var i = 0; i < $scope.departments.length; i++) {
        const department = $scope.departments[i];
        const {users, id} = department;
        const departmentUsers = angular.copy($scope.users);
        for (const user in departmentUsers) {
          departmentUsers[user].department = id;
          departmentUsers[user].departmentPosition = i;
        }

        for (let user in users) {
          const userId = department.users[user].id;
          if (departmentUsers[userId]) {
            departmentUsers[userId].ticked = true;
            departmentUsers[userId].originalTicket = true;
          }
        }

        $scope.departments[i].users = _.values(departmentUsers);
        $scope.departments[i].enableChangeName = false;
        $scope.departments[i].is_active = true;
        $scope.departmentById[department.id] = department.name;
      }
    };

    $scope.deleteDepartment = function(departmentPosition) {
      const department = $scope.departments[departmentPosition];
      DepartmentService.deleteDepartment(department.id).then(function() {
        const msg = translate('DEPARTMENT_DELETED');
        department.is_active = false;
        AdNotification.notify(msg, 'success');
        _refreshHistory();
      });
    };

    $scope.activeEveryUser = function(departmentPosition) {
      updateEveryUser(departmentPosition, true);
    };

    $scope.disableEveryUser = function(departmentPosition) {
      updateEveryUser(departmentPosition, false);
    };

    function updateEveryUser(departmentPosition, available) {
      const department = $scope.departments[departmentPosition];
      const {users, id} = department;
      const values = [];

      for (const userDepartment of users) {
        const {originalTicket, ticked, id} = userDepartment;

        if (originalTicket !== ticked) {
          values.push({
            cm: id,
            available: available
          });
          userDepartment.originalTicket = available;
        }
      }

      if (values.length > 0) {
        const changes = {
          key: 'update_users',
          value: values,
          id
        };
        DepartmentService.editDepartment(changes).then(function() {
          _refreshHistory();
        });
      }
    }

    $scope.enableChangeStatusName = function(idx) {
      $scope.departments[idx].enableChangeName = true;
    };

    $scope.disableChangeStatusName = function(idx) {
      $scope.departments[idx].enableChangeName = false;
    };

    $scope.updateNewName = function(idx) {
      $scope.departments[idx].enableChangeName = false;
      const department = $scope.departments[idx];
      const {name: newName, id} = department;
      const update = {
        key: 'name',
        value: newName,
        id
      };
      if (newName === '') {
        department.name = $scope.departmentById[department.id];
        const msg = translate('DEPARTMENT_NAME_CANT_BE_EMPTY');
        AdNotification.notify(msg, 'error');
      } else {
        DepartmentService.editDepartment(update).then(
          function() {
            _refreshHistory();
          },
          function(data) {
            const msg = translate(data.response);
            AdNotification.notify(msg, 'error');
            department.name = $scope.departmentById[department.id];
          }
        );
      }
    };

    

    $scope.editUserDepartment = function(data) {
      const changes = {
        key: 'update_user',
        value: [
          {
            cm: data.id,
            available: data.ticked
          }
        ],
        id: data.department
      };
      const department = $scope.departments[data.departmentPosition];
      const {users} = department;
      for (const user of users) {
        if (user.id == data.id) {
          user.originalTicket = data.ticked;
        }
      }
      DepartmentService.editDepartment(changes).then(function() {
        _refreshHistory();
      });
    };

    const _refreshHistory = (page = 0) => {
      $scope.loading = true;
      DepartmentService.getHistory(page).then(response => {
        const {list = [], pages, page_size: pageSize} = response;
        $scope.activityLog = list.map(elem => {
          return _parseLogEvent(elem);
        });
        const totalList = pageSize * pages;
        $scope.pagination.totalItems = totalList;
        $scope.pagination.pageSize = pageSize;
        $scope.pagination.pageNumber = page + 1;
        $scope.loading = false;
      });
    };
    const _parseLogEvent = elem => {
      const {extra_data: extraData, created: _created, author} = elem;
      const created = formatDate(_created * 1000);
      const startTooltip = formatCalendarDate(_created * 1000);
      const departmentID = extraData.department;
      const params = {
        author: author.name,
        department: $scope.allDepartmentsById[departmentID]
      };
      if ('config' in extraData) {
        const config = `DEPARTMENT_${extraData.config.toUpperCase()}`;
        params.config = translate(config);
      }
      if ('value' in extraData) {
        params.value = extraData.value;
      }
      if (extraData.what.includes('DEPARTMENT_LOG_USER')) {
        const detail = extraData.value;
        const change = detail[0];
        if (change.available) {
          params.change = translate('DEPARTMENT_ACTIVE_USER');
        } else {
          params.change = translate('DEPARTMENT_DISABLE_USER');
        }
        if (detail.length === 1) {
          params.value = '-';
          if ($scope.users[detail[0].cm]) {
            params.value = $scope.users[detail[0].cm].name;
          }
        } else {
          const names = [];
          for (const user of detail) {
            const _user = $scope.users[user.cm];
            if (_user) {
              names.push(_user.name);
            }
          }
          params.value = names.join(', ');
        }
      }
      return {
        created,
        startTooltip,
        reasonText: extraData.what,
        params
      };
    };

    const formatDate = date => moment(date).format('DD/MM/YYYY, h:mm:ss a');
    const formatCalendarDate = date => moment(date).calendar();
  }
  module.controller('DepartmentCtrl', DepartmentCtrl);
})(angular.module('postCenterWebClientApp'));
