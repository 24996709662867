(function (module) {
  'use strict';
  userOrdersCtrl.$inject = [
    '$scope',
    'FRONTEND_VUE_URL',
    'AdNotification',
    'EstablishmentService'
  ];

  function userOrdersCtrl(
    $scope,
    FRONTEND_VUE_URL,
    AdNotification,
    EstablishmentService: EstablishmentService
  ) {
    const vm = this;
    EstablishmentService.getEstablishment().then(establishment => {
      const configs = establishment.integrations.integration_configs
      const shopsConfig = configs.find(c => c.name == 'SHOPIFY')
      vm.isShopsIntegrationActive = shopsConfig?.config.active
    })

    vm.$onInit = () => {
      init();
      window.addEventListener('message', handleIframeMessage);
    };

    vm.$onDestroy = () => {
      window.removeEventListener('message', handleIframeMessage);
    }
    
    const init = () => {
      vm.urlFront = FRONTEND_VUE_URL;
    };

    const caseUpdateListenerOff = $scope.$on('case:hasBeenUpdated', (_event, userObject) => {
      vm.userObject = userObject;
      init();
    });

    $scope.$on('$destroy', () => {
      caseUpdateListenerOff();
    });

    function handleIframeMessage ({data}) {
      if (data.type !== 'ordersEvent') {
          return;
      }
      const {message} = data;
      const actionMap = {
        notify: notifyMessage
      };
      const fun = actionMap[message.action];
      if (fun) fun(message);
    };

    function notifyMessage(message) {
      const {text: messageText, type} = message;
      if(type === 'success') {
        AdNotification.success(200, messageText);
      } else if (type === 'error') {
        AdNotification.error(500, messageText);
      }
    }
  }
  module.controller('userOrdersCtrl', userOrdersCtrl);
})(angular.module('postCenterWebClientApp'));
