'use strict';

angular.module('postCenterWebClientApp')
  .directive('messageDetails', function () {
    return {
      scope: {
        message: '=',
      },
      templateUrl: 'blocks/message/views/message_details.html',
      restrict: 'E',
    };
  });
