const Workload = Vue.component('Workload', {
  computed: {
    ...Vuex.mapGetters(['workloadData', 'isSupervisorNotAdmin'])
  },
  template: `<div class="workload">
        <WorkloadFilters class="filters" />
        <AssignConfig v-if="!isSupervisorNotAdmin" />
        <div class="d-flex">
          <WorkloadTable :table-data="workloadData" class="w-70" />
          <WorkloadResume class="w-30 ml-1" />
        </div>
    </div>`
});
