'use strict';


class SearchModalController {

    $scope
    $rootScope
    $timeout
    $document
    $location
    AuthService
    FRONTEND_VUE_URL
    $sce
    modalSearchOff
  
    private addEventListeners() {
      this.modalSearchOff = this.$rootScope.$on(
        'modalSearch',
        () => {
          this.$scope.show = true
          this.$document.on('keydown', this.hotkey_handler)
        }
      );
      this.$scope.$on('$destroy', () => this.modalSearchOff())
      window.addEventListener("message", (event) => {
        if (event.data.type !== 'searchEvent') {
          return;
        }
        const {message} = event.data;
        if (message.action === 'close_window') {
          this.close()
        }
      }, false)
    }
  
    private hotkeys(event) {
      switch (event.keyCode) {
        case 27: // escape
          this.$scope.$apply(this.close());
          break;
      }
    }
  
    private close () {
      this.$scope.show = false;
      this.$document.off('keydown', this.hotkey_handler);
      this.$timeout(() => {
        this.$scope.data = {};
      }, 500);
    };

  
    constructor(
      $scope,
      $rootScope,
      $timeout,
      $document,
      $location,
      $sce,
      AdRequest: AdRequestService,
      FRONTEND_VUE_URL
    ) {
        this.$scope = $scope
        this.$rootScope = $rootScope
        this.$document = $document
        this.$timeout = $timeout
        this.hotkey_handler = (event) => this.hotkeys(event)
        $scope.show = false;
        this.addEventListeners()
      
        $scope.close = () => this.close()
        $scope.ignoreHotkeys = function ($event) {
          $event.stopPropagation();
        };
        $scope.iFrameUrl = function () {
          const token = AdRequest.getToken()
          let section = $location.search().section;
          let url = `${FRONTEND_VUE_URL}/frames/search?authToken=${token}`
          if (section) {url += `&section=${section}`}
          return $sce.trustAsResourceUrl(url);
        }
    }
  
}
  
angular
  .module('postCenterWebClientApp')
  .controller(
    'SearchModalCtrl',
    SearchModalController
  );
