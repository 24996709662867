const AssignmentDetailAgent = Vue.component('AssignmentDetailAgent', {
  inject: ['findAssignmentMode', 'getAssignmentCount', 'setAssignment'],
  props: {
    executive: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      agent: null,
      clone: {}
    };
  },
  computed: {
    ...Vuex.mapGetters([
      'assignmentModes',
      'editMode',
      'establishmentConfig',
      'isSupervisorNotAdmin'
    ]),
    assignmentCount() {
      return this.getAssignmentCount(this.assignmentModes, this.agent.accounts);
    },
    workload() {
      const {
        lastAssignation,
        assignation_workload: {current_workload, current_unanswered_workload}
      } = this.agent;

      return {
        last: this.$t('lastAssignation', {
          total: moment(lastAssignation).format('DD/MM/YYYY')
        }),
        total: this.$t('openCases', {
          total: current_workload
        }),
        unanswered: this.$t('unansweredCases', {
          total: current_unanswered_workload
        })
      };
    },
    assignableNumber() {
      return this.agent.isAssignable ? 1 : 2;
    }
  },
  watch: {
    executive(agent) {
      this.updateAgent(agent);
      this.editingMode(false);
    }
  },
  mounted() {
    this.updateAgent(this.executive);
  },
  methods: {
    ...Vuex.mapActions(['goBack', 'saveUserAssignment', 'editingMode']),
    updateAgent(agent) {
      this.agent = agent;
      this.setAssignmentModePerUser();
      this.clone = JSON.parse(JSON.stringify(agent));
    },
    saveAgent() {
      this.saveUserAssignment(this.agent).then(() => {
        this.editingMode(false);
      });
    },
    cancel() {
      this.editingMode(false);
      this.agent = JSON.parse(JSON.stringify(this.clone));
    },
    assignHandler(agent, {id, name}) {
      const {
        mode: {id: currentMode}
      } = agent;
      if (currentMode !== id) {
        this.editingMode(true);
        const agentIndex = this.agent.accounts.indexOf(agent);
        this.agent.accounts.splice(agentIndex, 1, {
          ...agent,
          mode: {id, name}
        });
      }
    },
    toggleAssignability() {
      this.editingMode(true);
      this.agent.isAssignable = !this.agent.isAssignable;
    },
    activeMaxAssignments() {
      this.editingMode(true);
      this.agent.assignation_limit.using_establishment_default =
        !this.agent.assignation_limit.using_establishment_default;
    },
    setAllAssignmentsTo({id, name}) {
      this.editingMode(true);
      const newMode = {id, name};
      this.agent.accounts = this.agent.accounts.map(account => {
        return {...account, ...{isActive: id > 0, mode: newMode}};
      });
    },
    onChangeAssignment(mode) {
      this.editingMode(true);
      this.agent.assignation_config = {
        establishment: mode === 0,
        fifo: mode === 1
      };
      this.updateAgent(this.agent);
    },
    setAssignmentModePerUser() {
      const {
        assignation_config: {establishment, fifo}
      } = this.agent;
      this.agent.assignation_mode = establishment
        ? 0
        : !establishment && fifo
        ? 1
        : 2;
    }
  },
  template: `
    <article v-if="agent">
      <AssignmentHeaderCard
      :title="workload.last"
      :account="agent">
        <template v-slot:cardContent>
          <div class="position-relative mx-auto w-75">
            <MeteringBar
              v-if="establishmentConfig.enable_queued_assignment"
              meter-type="assignment"
              :max="agent.assignation_limit.maximum_total_workload"
              :value="agent.assignation_workload.current_workload"
            />
          </div>
          <span class="d-block text-center">{{ workload.total }}</span>
          <span class="d-block text-center">{{ workload.unanswered }}</span>
        </template>
      </AssignmentHeaderCard>
      <section class="my-1">
        <hr />
        <p class="d-flex space-between">
          {{ $tc('enableAgent', assignableNumber) }}
          <ToggleSwitch
            :active="agent.isAssignable"
            @switched="toggleAssignability"
          />
        </p>
        <p class="d-flex space-between" v-for="mode in assignmentModes">
          {{ mode.makeAllAccounts }}
          <ToggleSwitch
            :active="assignmentCount[mode.id] === agent.accounts.length"
            @switched="setAllAssignmentsTo(mode)"
          />
        </p>

        <hr />
      </section>
      <section class="my-1" v-if="establishmentConfig.enable_queued_assignment">
        <h3 class="d-flex space-between">
          {{ $t('defaultLimits') }}
            <ToggleSwitch
              :active="agent.assignation_limit.using_establishment_default"
              @switched="activeMaxAssignments"
            />
        </h3>
        <small>{{ $t('thisAgentOnly') }}</small>
        <AssignmentDetailMode :assignation-mode="agent.assignation_mode" :update-mode="onChangeAssignment"/>
        <div v-if="!agent.assignation_limit.using_establishment_default">
          <p class="d-flex space-between" >
            {{ $t('openTickets') }}
            <Counter class="w-25"
              v-model="agent.assignation_limit.maximum_total_workload"
              :max="100"
            />
          </p>
          <p class="d-flex space-between">
            {{ $t('waitingAnswer') }}
            <Counter
              class="w-25"
              v-model="agent.assignation_limit.maximum_unanswered_workload"
              :max="agent.assignation_limit.maximum_total_workload"
            />
          </p>
        </div>
      </section>
      <SaveConfigAssign
        :onSave="saveAgent"
        :onCancel="cancel"
        :toggle="editMode" />
      <AssignmentList
        :title="$t('accounts')"
        :total="agent.accounts.length"
        :list="agent.accounts"
        :onAssignChange="assignHandler"
      />

    </article>
  `
});
