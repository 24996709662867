const InputText = Vue.component('InputText', {
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: null
    },
    inputError: {
      type: Boolean,
      default: false
    },
    searchHandler: {
      type: Function,
      default: () => {}
    },
    errorMessage: {
      type: String,
      default: 'an error occurred'
    }
  },
  data() {
    return {
      type: this.$attrs.type,
      toggleClass: 'fa fa-eye',
      inputPassword: this.$attrs.type === 'password'
    };
  },
  computed: {
    inputListeners() {
      const vm = this;
      return Object.assign({}, this.$listeners, {
        input(event) {
          vm.$emit('input', event.target.value);
        }
      });
    },
    hasData() {
      return !this.value ? '' : 'ads-input--active';
    },
    hasError() {
      return this.inputError ? 'ads-input--error' : '';
    },
    isDisabled() {
      return this.$attrs.disabled ? 'ads-input--disabled' : '';
    }
  },
  methods: {
    togglePassword() {
      this.type = this.type === 'password' ? 'text' : 'password';
      this.toggleClass =
        this.toggleClass === 'fa fa-eye' ? 'fa fa-eye-slash' : 'fa fa-eye';
    }
  },
  template: `
    <label class="ads-input" :class="[hasData, hasError, isDisabled]">
        <input
          v-bind="$attrs"
          v-on="inputListeners"
          :type="type"
          :value="value"
        />
        <span class="icon-input" v-if="inputPassword">
          <span @click="togglePassword()" :class="toggleClass" />
        </span>
        <span class="icon-input" v-if="type === 'search'">
          <span class="fa fa-search" @click="searchHandler" />
        </span>
        <span class="ads-input__label">{{ label }}</span>
        <p class="error-label"
        v-if="inputError">
          <span class="fa fa-exclamation-triangle pr-1" />
            {{ errorMessage }}</p>
    </label>`
});
