'use strict';
var app = angular.module('postCenterWebClientApp');

app.directive('whatsappWidgetLivePreview', function() {
  return {
    templateUrl:
      'blocks/admin/views/social_accounts/whatsapp_widget_live_preview.html',
    restrict: 'E',
    scope: {
      option: '='
    },
    controller: [
      '$scope',
      function($scope) {
        // to prevent child scope assignment hell
      }
    ]
  };
});
