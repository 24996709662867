'use strict';

class MessagesTesterCtrl {

  $scope: any
  $state: any
  UserService: UserService
  SocialAccountService: SocialAccountService
  messageService: MessageService

  $onInit() {
    Promise.all([
      this.UserService.getProfile(),
      this.SocialAccountService.getSocialAccounts()
    ]).then(([user, accounts]) => {
      if (!user.isAdmin) {
          this.$state.go('crm.inbox')
      }
      this.$scope.socialAccounts = accounts;
      this.$scope.loading = false;
      this.$scope.$applyAsync();
    });
  }

  checkUser() {
  };

  goToTicket(ticket_id: string) {
    window.open(`#/case/${ticket_id}`);
  }

  simulateMessage() {
    this.$scope.sendingMessage = true;
    this.messageService.simulateMessage(
      this.$scope.selectedAccount.uid,
      this.$scope.selectedAccount.sn,
      this.$scope.user.uid,
      this.$scope.message.text,
      this.$scope.attachments,
      this.$scope.config.isInbound
    ).then((response) => {
      this.$scope.messageSent = true;
      this.$scope.backendResponse = response;
    }).catch((error) => {
      this.$scope.backendResponse = error;
      this.$scope.errorSendingMessage = true;
    }).finally(() => {
      this.$scope.$applyAsync();
    });
  };

  resetInterface() {
    this.$scope.errorSendingMessage = false;
    this.$scope.backendResponse = null;
    this.$scope.messageSent = false;
    this.$scope.sendingMessage = false;
    this.resetText();
    this.$scope.$applyAsync();
  };

  selectAccount(account) {
    this.$scope.selectedAccount = account;
    this.$scope.$applyAsync();
  }

  resetText() {
    this.$scope.message.text = this.getRandomMessage();
    this.$scope.$applyAsync();
  }

  getRandomMessage() {
    return `${this.generateRandomLoremIpsum()} ${this.getRandomEmoji()} ${Date.now()}`;
  }

  generateRandomLoremIpsum() {
    const loremIpsumWords = [
      'Lorem', 'ipsum', 'dolor', 'sit', 'amet', 'consectetur',
      'adipiscing', 'elit', 'sed', 'do', 'eiusmod', 'tempor',
      'incididunt', 'ut', 'labore', 'et', 'dolore', 'magna',
      'aliqua', 'Ut', 'enim', 'ad', 'minim', 'veniam', 'quis',
      'nostrud', 'exercitation', 'ullamco', 'laboris', 'nisi',
      'ut', 'aliquip', 'ex', 'ea', 'commodo', 'consequat'
    ];
  
    const randomSentenceLength = Math.floor(Math.random() * 2) + 5; // Random sentence length between 5 and 10 words
    const randomSentence = Array.from({ length: randomSentenceLength }, () => {
      const randomWordIndex = Math.floor(Math.random() * loremIpsumWords.length);
      return loremIpsumWords[randomWordIndex];
    }).join(' ');
  
    const randomParagraphLength = Math.floor(Math.random() * 2) + 1; // Random paragraph length between 1 and 5 sentences
    const randomParagraph = Array.from({ length: randomParagraphLength }, () => randomSentence).join(' ');
  
    return randomParagraph;
  }

  getRandomEmoji() {
    var emojis = [
      '😀', '😁', '😂', '😃', '😄', '😅', '😆', '😉', '😊', '😋',
      '😎', '😍', '😘', '😗', '😙', '😚', '☺️', '😇', '😐', '😑',
      '😶', '😏', '😣', '😥', '😮', '😯', '😪', '😫', '😴', '😌',
      '😛', '😜', '😝', '😒', '😓', '😔', '😕', '😲', '😷', '😖',
      '😞', '😟', '😤', '😢', '😭', '😦', '😧', '😨', '😬', '😰',
      '😱', '😳', '😵', '😡', '😠', '😈', '👿', '👹', '👺', '💀',
      '☠️', '👻', '👽', '👾', '👋', '👍', '👎', '👊', '✊', '👌',
      '✋', '👐', '💪', '🙏', '☝️', '👆', '👇', '👈', '👉', '🖕',
      '🖐', '🤘', '🖖', '✍️', '💅', '👄', '👅', '👂', '👃', '👁',
      '👀', '👤', '👥', '🗣', '👶', '👦', '👧', '👨', '👩', '👱',
      '👴', '👵', '👲', '👳', '👷', '👮', '👼', '🎅', '👸'
    ];
    return emojis[Math.floor(Math.random() * emojis.length)];
  }

  onFileSelect(files) {
    files.forEach(file => {
      this.$scope.attachments.push(file);
    });

    this.$scope.$applyAsync();
  }

  removeAttachment(index) {
    this.$scope.attachments.splice(index, 1);
    this.$scope.$applyAsync();
  }

  constructor(
    $scope,
    $state,
    UserService: UserService,
    SocialAccountService: SocialAccountService,
    Message: MessageService
  ) {
    this.$scope = $scope;
    this.$state = $state
    this.UserService = UserService;
    this.SocialAccountService = SocialAccountService;
    this.messageService = Message;
    this.$scope.loading = true;
    this.$scope.errorSendingMessage = false;
    this.$scope.sendingMessage = false;
    this.$scope.messageSent = false;
    this.$scope.backendResponse = null;
    this.$scope.selectedAccount = null;
    this.$scope.config = {isInbound: true};
    this.$scope.user = {uid: ''};
    this.$scope.attachments = [];
    this.$scope.attachmentInput = null;
    this.$scope.onFileSelect = (files) => this.onFileSelect(files);
    this.$scope.removeAttachment = (index) => this.removeAttachment(index);
    this.$scope.resetText = () => this.resetText();
    this.$scope.message = {text: this.getRandomMessage()};
    this.$scope.checkUser = () => this.checkUser();
    this.$scope.simulateMessage = () => this.simulateMessage();
    this.$scope.resetInterface = () => this.resetInterface();
    this.$scope.resetInterface = () => this.resetInterface();
    this.$scope.selectAccount = (account) => this.selectAccount(account);
    this.$scope.goToTicket = (ticket_id: string) => this.goToTicket(ticket_id);
  };
};

angular
  .module('postCenterWebClientApp')
  .controller('MessagesTesterCtrl', MessagesTesterCtrl)
