'use strict';

var app = angular.module('postCenterWebClientApp');
app.component('adSnIcon', {
  bindings: {
    adSn: '@',
    adClass: '@',
    adEnableTooltip: '=',
    adTooltipPosition: '@',
    adColorfull: '=',
  },
  controller: adSnIconCtrl,
  templateUrl: 'blocks/global/views/ad_sn_icon.html'
});

adSnIconCtrl.$inject = ['AdAvailableSn', '$scope'];

function adSnIconCtrl(AdAvailableSn, $scope) {

  var ctrl = this; // captura el contexto de "this"

  ctrl.$onChanges = function(changes) {
    if (changes.adSn) {
      $scope.adSn = ctrl.adSn;
      var icon = AdAvailableSn.getIcon($scope.adSn);
      $scope.adTooltip = AdAvailableSn.getTitle($scope.adSn);
      if ($scope.adClass === undefined) {
        $scope.adClass = icon;
      } else {
        $scope.adClass = $scope.adClass + " " + icon;
      }
    }
  };

  $scope.adSn = this.adSn;
  $scope.adClass = this.adClass;
  $scope.adTooltipPosition = this.adTooltipPosition;
  $scope.adEnableTooltip = this.adEnableTooltip;
  $scope.adColorfull = this.adColorfull;


  if ($scope.adColorfull === undefined) {
    $scope.adColorfull = false;
  }

  if ($scope.adEnableTooltip === undefined) {
    $scope.adEnableTooltip = false;
  }

  if ($scope.adTooltipPosition === undefined) {
    $scope.adTooltipPosition = 'top';
  }

  var icon = AdAvailableSn.getIcon($scope.adSn);
  $scope.adTooltip = AdAvailableSn.getTitle($scope.adSn);

  if ($scope.adColorfull) {
    $scope.color = AdAvailableSn.getColor($scope.adSn);
  }

  if ($scope.adClass === undefined) {
    $scope.adClass = icon;
  } else {
    $scope.adClass = $scope.adClass + " " + icon;
  }
}
