class UsersService {
  UserService: UserService
  users
  cms
  lastUpdateUsers
  lastUpdateCms
  timeLimit

  constructor(UserService: UserService) {
    this.UserService = UserService
    this.users = [];
    this.cms = [];
    this.lastUpdateUsers = null;
    this.lastUpdateCms = null;
    this.timeLimit = 10 * 1000;
  }
  async getUsers(): Promise<User[]> {
    const now = new Date();
    try {
      if (this.lastUpdateUsers) {
        return Promise.resolve(this.users);
      }
      this.lastUpdateUsers = now;
      const users = await this.UserService.getUsers()
      this.users = users;
      return users;
    } finally {
      if (now - this.lastUpdateUsers > this.timeLimit) {
        this._refreshUsers();
        this.lastUpdateUsers = now;
      }
    }
  }
  async getCms(): Promise<Cm[]> {
    const now = new Date();
    try {
      if (this.lastUpdateCms) {
        return Promise.resolve(this.cms);
      }
      this.lastUpdateCms = now;
      const cms = await this.UserService.getCms()
      this.cms = cms;
      return cms;
    } finally {
      if (now - this.lastUpdateCms > this.timeLimit) {
        this._refreshCms();
        this.lastUpdateCms = now;
      }
    }
  }
  _refreshUsers() {
    this.UserService.getUsers().then(users => this.users = users)
  }
  _refreshCms() {
    this.UserService.getCms().then(cms => this.cms = cms)
  }
  getGroups() {
    return this.UserService.getGroups()
  }
  getResumeUsers() {
    return this.UserService.getResumeUsers()
  }
  getBots() {
    return this.UserService.getBotUsers()
  }
  getChartTeamCMs() {
    return this.UserService.getChartTeamCMs()
  }
  getOnlineStatus(page = 0, filters) {
    return this.UserService.getOnlineStatus(page, filters)
  }
}

angular.module('postCenterWebClientApp').service('UsersService', UsersService);
