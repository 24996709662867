(module => {
  'use strict';
  MessageCtrl.$inject = [
    '$scope',
    '$filter',
    'ColumnsService',
    'AdNotification',
    'adDateFormatter',
    '$rootScope',
    '$uibModal',
    'Message',
    '$window',
    'UserService',
    'CaseService',
    'CaseDetailManager'
  ];
  function MessageCtrl(
    $scope,
    $filter,
    ColumnsService,
    AdNotification,
    adDateFormatter,
    $rootScope,
    $uibModal,
    Message: MessageService,
    $window,
    UserService: UserService,
    CaseService,
    CaseDetailManager: CaseDetailManager
  ) {
    if (!$scope.message) {
      return;
    }

    $scope.showText =
      $scope.message?.sn !== 'email' || $scope.message.beingReplied;
    if ($scope.message.sn === 'email') {
      const trustHtml = _.unescape($scope.message?.text);
      $scope.message.text = trustHtml;
    }
    $scope.getMessageDomId = Message.getDomId;
    const messageDeletedListenerOff = $rootScope.$on('message:isDeleted', (event, messageData) => {
      if (
        angular.isDefined(messageData.reply_to_msg) &&
        $scope.message.mid === messageData.reply_to_msg
      ) {
        $scope.message.active = false;
      }
    });
    $scope.showUserMetadata = false;
    function isOurMessage(message) {
      return _.some(message.recipients, function (recipient) {
        return recipient.uid === message.author.uid;
      });
    }

    $scope.tooltipSideA = "left";
    $scope.tooltipSideB = "right";
    $scope.isOurs = isOurMessage($scope.message);
    if ($scope.isOurs) {
      $scope.tooltipSideA = "right";
      $scope.tooltipSideB = "left";
    }
    
    UserService.getProfile().then(user => {
      // check if you're a bot 🤖
      $scope.isBot = user.profile.is_bot
    })

    const {sentiment} = $scope.message;

    switch (sentiment) {
      case -1:
        $scope.sentiment = 'Negativo 😠';
        break;
      case 0:
        $scope.sentiment = 'Neutral 😐';
        break;
      case 1:
        $scope.sentiment = 'Positivo 😌';
        break;
      default:
        $scope.sentiment = 'No expresado 😶';
        break;
    }
    $scope.$watchCollection(
      () => {
        if ($scope.message) {
          return {
            responseTime: $scope.message.response_time,
            message: $scope.message
          };
        } else {
          return {};
        }
      },
      obj => {
        const seconds = obj.responseTime;
        if (seconds) {
          const formatted = adDateFormatter.responseTime(seconds);
          $scope.message.response_time_formatted = formatted;
        }
      }
    );

    const published = $scope.message.created;
    $scope.publishedFormatted = adDateFormatter.publicationDate(published);
    $scope.publishedFormattedL = adDateFormatter.publicationDateLong(published);

    const attachments = [];
    angular.forEach($scope.message.attachments, attachment => {
      attachments.push(attachment);
    });
    $scope.openAttachment = function (url) {
      if (url) {
        $window.open(url, '_blank');
      }
    };
    $scope.attachments = attachments;
    $scope.isImage = ext => {
      const arr = ['jpg', 'png', 'gif', 'bmp'];
      return arr.includes(ext.toLowerCase());
    };
    $scope.toogleShowText = function () {
      $scope.showText = !$scope.showText || $scope.message.beingReplied;
    };
    $scope.createHashtagColumn = function (hashtag) {
      createUserColumn(formatColumnDataKeyword(`#${hashtag}`));
    };

    $scope.showCase = event => {
      if (!$scope.caseObject) {
        CaseService.get($scope.message['case_id']).then(function (caseObject) {
          $scope.caseObject = caseObject;
          CaseDetailManager.openCaseState($scope.caseObject.identifier);
        });
      } else {
        CaseDetailManager.openCaseState($scope.caseObject.identifier);
      }
    }

    $scope.selectQuotedMessage = function (message) {
      $scope.$emit('selectQuotedMessage', message);
    }

    $scope.highlightQuotedMessage = function (message) {
      $scope.$emit('highlightQuotedMessage', message);
    }

    $scope.showMessageOnModal = function (message) {
      const modalScope = $scope.$new(true);
      modalScope.message = message;

      $scope.messageModal = $uibModal.open({
        templateUrl: 'blocks/message/views/message_modal.html',
        controller: 'MessageCtrl',
        scope: modalScope,
        size: 'lg'
      });

      $scope.messageModal.result.finally(() => {
        modalScope.$destroy();
      });
    };

    function createUserColumn(data) {
      ColumnsService.postColumn(data).then(
        column => {
          $scope.$emit('addColumn', column);
          AdNotification.success(201, 'post_column');
        },
        data => {
          AdNotification.error(data, 'post_column');
        }
      );
    }

    function formatColumnDataKeyword(keyword) {
      const data = {
        name: `Filter: ${keyword}`,
        keyword,
        column_type: 'message'
      };
      return data;
    }

    function formatColumnData(author) {
      let name = '';
      if (author.sn === 'twitter') {
        name = '@';
      }
      if (author.text === undefined) {
        name = name + author.uname;
      } else {
        name = name + author.text;
      }
      const data = {
        author: {
          id: author.resource_id,
          sn: author.sn
        },
        name,
        column_type: 'message'
      };
      return data;
    }

    UserService.getProfile().then(user => {
      $scope.canEditColumns = user.profile.enable_edit_columns
    })
    
    $scope.createAuthorColumn = function () {
      const {author} = $scope.message;
      createUserColumn(formatColumnData(author));
    };

    $scope.closeMessageModal = function () {
      $rootScope.$broadcast('messageModal:close');
    };

    const social_networks = ['instagram', 'facebook', 'twitter', 'linkedin'];

    $scope.isPublicThread = function (message) {
      return !$scope.isPrivateMessage(message) && social_networks.includes(message.sn);
    };

    $scope.isPrivateThread = function (message) {
      return $scope.isPrivateMessage(message) && !$scope.isOurs && social_networks.includes(message.sn);
    };

    $scope.isPrivateMessage = function (message) {
      return message.kind === 'private';
    };

    $scope.getReferenceText = function (message) {
      const ref = message.reference;
      if (ref.type === 'mention') {
        return $filter('translate')('MESSAGE_REFERENCE_MENTION')
      } else if (ref.type === 'shared_external_post_with_mention') {
        return $filter('translate')('MESSAGE_REFERENCE_SHARED_EXTERNAL_POST_WITH_MENTION')
      } else if (ref.type === 'comment') {
        return $filter('translate')('MESSAGE_REFERENCE_COMMENT')
      }
    };

    $scope.shouldDisplayIcon = function (message, icon) {
      const is_ours = message.ours;
      const is_fb = message.sn === 'facebook';
      const is_private = $scope.isPrivateMessage(message);
      const support_sent_received_read =
        is_ours && ['chat', 'whatsapp', 'instagram'].includes(message.sn);

      function facebook_visibility() {
        if (!is_fb) return false;

        if (icon === 'date') {
          const is_public = is_private === false;
          const is_not_ours = is_ours === false;
          return is_public && is_not_ours;
        }
        if (icon === 'sent' || icon === 'received') {
          return is_ours;
        }
        if (icon === 'read') {
          return is_ours && is_private;
        }
        if (icon === 'not-received-chat') {
          return is_ours;
        }
        return false;
      }

      switch (icon) {
        case 'date': // blue dot
          return (
            !['chat', 'whatsapp', 'instagram', 'facebook'].includes(
              message.sn
            ) ||
            !is_ours ||
            facebook_visibility()
          );
        case 'sent':
          return (
            message.status === 0 &&
            (support_sent_received_read || facebook_visibility())
          );
        case 'received':
          return (
            message.status === 1 &&
            (support_sent_received_read || facebook_visibility())
          );
        case 'read':
          return (
            message.status === 2 &&
            (support_sent_received_read || facebook_visibility())
          );
        case 'not-received-chat':
          return (
            message.status === 3 &&
            ('chat' === message.sn && is_ours)
          );
        case 'not-received-generic':
            return (
              message.status === 3 &&
              ('chat' !== message.sn && is_ours)
            );
        case 'retry':
          return message.status === 4 && message.sn === 'chat' && is_ours;
        case 'not-received-whatsapp':
          return (
            message.status === 9 &&
            ['whatsapp', 'instagram'].includes(message.sn) &&
            is_ours
          );
      }
      return false;
    };
    $scope.getMessageTypeStrId = function (message) {
      if ($scope.isPrivateMessage(message)) {
        return 'PRIVATE_MESSAGE';
      }
      switch (message.sn) {
        case 'twitter':
          return 'TWITTER_MESSAGE';
        case 'facebook':
          switch (message.level) {
            case 0:
              return 'FACEBOOK_POST_MESSAGE';
            case 1:
              return 'FACEBOOK_COMMENT_MESSAGE';
            case 2:
              return 'FACEBOOK_SUBCOMMENT_MESSAGE';
            default:
              return 'FACEBOOK_PUBLIC_MESSAGE';
          }
        case 'email':
          return 'EMAIL_MESSAGE';
        case 'whatsapp':
          return 'WHATSAPP_MESSAGE';
      }
      return 'GENERIC_MESSAGE';
    };

    $scope.replyToAction = function () {
      Message.selectMessage($scope.message)
    }

    var CMInitials;
    if ($scope.message.current_assignment) {
      CMInitials = CaseService.getInitials($scope.message.current_assignment.name);
      $scope.message.current_assignment.initials = CMInitials;
    }
    if ($scope.message.first_assignment) {
      CMInitials = CaseService.getInitials($scope.message.first_assignment.name);
      $scope.message.first_assignment.initials = CMInitials;
    }

    var publisher = $scope.message['publisher'];
    if (publisher) {
      if (publisher.name === "PostCenter Bot") {
        publisher.initials = "🤖";
        publisher.name = $filter('translate')('MESSAGE_AUTOMATIC_ACTIONS');
      } else {
        var PublisherInitials = CaseService.getInitials(publisher.name);
        publisher.initials = PublisherInitials;
      }
    }
    $scope.message['publisher'] = publisher;

    $scope.getAssignmentName = function (assignment) {
      if (!assignment) {
        return $filter('translate')('MESSAGECONTROL_NOONE');
      } else {
        return assignment.name;
      }
    };

    const messageModalCloseListenerOff = $scope.$on('messageModal:close', () => {
      if (angular.isDefined($scope.messageModal)) {
        $scope.messageModal.close();
      }
    });

    const customDataCancelListenerOff = $scope.$on('customDataBlock:cancel', () => {
      $scope.showUserMetadata = false;
    });

    $scope.$on('$destroy', () => {
      messageDeletedListenerOff();
      messageModalCloseListenerOff();
      customDataCancelListenerOff();
    })

    this.message = $scope.message;
  }
  module.controller('MessageCtrl', MessageCtrl);
})(angular.module('postCenterWebClientApp'));
