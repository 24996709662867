/* eslint-disable id-length */
const WorkloadTable = Vue.component('WorkloadTable', {
  props: {
    tableData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      tableHeight: '57vh',
      spinner: false,
      headings: [
        this.$t('table.agents'),
        this.$t('table.opened'),
        this.$t('table.awaitingResponse'),
        this.$t('table.unanswered')
      ]
    };
  },
  computed: {
    ...Vuex.mapGetters(['limit', 'filters', 'pages', 'loadingData']),
    firstLoad() {
      return this.tableData.length === 0 && this.loadingData;
    },
    emptyList() {
      return this.tableData.length === 0 && !this.loadingData;
    },
    filterRows() {
      this.stopSpinner();
      return this.getRows();
    }
  },
  methods: {
    ...Vuex.mapActions(['setFilters']),
    stopSpinner() {
      this.spinner = false;
    },
    userStatus(status = 0) {
      // 0 off, 1 away, 2 connected
      return status === 1 ? 'away' : status === 2 ? 'active' : '';
    },
    scrollPagination() {
      const {page} = this.filters;
      if (page < this.pages) {
        this.spinner = true;
        this.setFilters({filter: 'page', payload: page + 1});
      }
    },
    getRows() {
      const agentsRows = this.tableData.map(agent => {
        const {
          name,
          online_status = 0,
          opened_tickets = 0,
          opened_and_not_answered_tickets = 0,
          opened_and_not_addressed_tickets = 0,
          not_answered_tickets_limit = 0,
          opened_tickets_limit = 0,
          assignment_config = null
        } = agent;
        const opened = this.limit
          ? `${opened_tickets}/${opened_tickets_limit}`
          : opened_tickets;
        const awaitingResponse = this.limit
          ? `${opened_and_not_answered_tickets}/${not_answered_tickets_limit}`
          : opened_and_not_answered_tickets;
        const awaitingPercent = Math.round(
          (opened_and_not_answered_tickets / opened_tickets) * 100
        );
        const unanswered = opened_and_not_addressed_tickets;
        const unansweredPercent =
          opened_and_not_addressed_tickets > 0
            ? Math.round(
                (opened_and_not_addressed_tickets / opened_tickets) * 100
              )
            : 0;

        return [
          {name, online_status, assignment_config},
          {opened},
          {awaitingResponse, awaitingPercent},
          {unanswered, unansweredPercent}
        ];
      });
      return agentsRows;
    }
  },

  template: `<div class="workload-table">
    <SkeletonWorkload v-if="firstLoad" class="skeleton" />
    <BasicTable
      v-else-if="!firstLoad && !emptyList"
      :headings="headings"
      :height="tableHeight"
      :custom="true"
      :striped="true"
      v-infinite-scroll="scrollPagination">
      <tr v-for="row in filterRows">
        <td v-for="elem in row">
          <div v-if="elem.hasOwnProperty('name')" class="d-flex">
            <span
              class="fa fa-circle user-status mr-1"
              :class="userStatus(elem.online_status)"
              />
            <strong >{{elem.name}}</strong>
            <Tag v-if="elem.assignment_config"
              class="ml-1"
              :text="$tc('assignMode', elem.assignment_config.fifo ? 1 : 0)"
              is-shadow
            >
            </Tag>
          </div>
          <slot v-if="!limit">
            <span v-if="elem.hasOwnProperty('opened')">{{elem.opened}}</span>
            <CustomTd
            v-if="elem.hasOwnProperty('awaitingResponse')"
            :value="elem.awaitingResponse"
            :percent="elem.awaitingPercent"
            />
          </slot>
          <slot v-else>
          <LimitProgress
            v-if="elem.hasOwnProperty('opened')"
            :diff="elem.opened" />
          <LimitProgress
            v-if="elem.hasOwnProperty('awaitingResponse')"
            :diff="elem.awaitingResponse" />

          </slot>
          <CustomTd
            v-if="elem.hasOwnProperty('unanswered')"
            :value="elem.unanswered"
            :percent="elem.unansweredPercent"
          />
        </td>
      </tr>
    </BasicTable>
    <EmptyResults v-if="emptyList"/>
    <span v-if="spinner" class="fa fa-spin fa-spinner-clock mt-2 float-left"></span>
  </div>`
});
const LimitProgress = Vue.component('LimitProgress', {
  props: {
    diff: {
      type: [String, Number],
      default: null
    }
  },

  computed: {
    splitDiff() {
      const split = this.diff.split('/');
      return split;
    }
  },
  template: `<div>
    <ProgressBarWorkload :now="splitDiff[0]" :limit="splitDiff[1]" />
    <strong>{{ diff }}</strong>
  </div>`
});
const ProgressBarWorkload = Vue.component('ProgressBarWorkload', {
  props: {
    now: {
      type: [Number, String],
      default: null
    },
    limit: {
      type: [Number, String],
      default: null
    }
  },
  data() {
    return {
      redGrana: '#f44336',
      yellowCurcuma: '#ffc107',
      percent: 0
    };
  },
  computed: {
    progress() {
      const percent = Math.round((this.now / this.limit) * 100);
      this.setPercent(percent);
      return {
        width: `${this.percent}%`,
        backgroundColor:
          this.percent >= 80 && this.percent < 100
            ? this.yellowCurcuma
            : this.percent >= 100
            ? this.redGrana
            : ''
      };
    }
  },
  methods: {
    setPercent(percent) {
      this.percent = percent > 100 || percent === Infinity ? 100 : percent;
    }
  },
  template: `<div class="progress" style="height: 8px;">
  <div class="progress-bar" role="progressbar" :style="progress"></div>
</div>`
});
const CustomTd = Vue.component('CustomTd', {
  props: {
    percent: {
      type: Number,
      default: null
    },
    value: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
      darkRojoGrana: '#9D2B23'
    };
  },
  computed: {
    ...Vuex.mapGetters(['warningRange']),
    getValue() {
      return !this.value ? 0 : this.value;
    },
    getPercent() {
      return this.percent ? `(${this.percent}%)` : '';
    },
    warningType() {
      const circleWarning = 'fa-exclamation-circle-fas';
      const triangleWarning = 'fa-exclamation-triangle';
      return this.percent > this.warningRange.high
        ? triangleWarning
        : circleWarning;
    },
    warningColor() {
      return {
        color: this.percent > this.warningRange.high ? this.darkRojoGrana : ''
      };
    }
  },
  template: `<span>
  <span :style="warningColor">{{ getValue }} {{ getPercent }}</span>
  <span class="fa fright" v-if="percent >= warningRange.low" :class="warningType" />
</span>`
});
const SkeletonWorkload = Vue.component('SkeletonWorkload', {
  components: {
    ContentLoader: contentLoaders.ContentLoader
  },
  props: {
    size: {
      type: String,
      default: '0 0 100 100'
    }
  },
  data() {
    return {
      width: 100,
      height: 100
    };
  },
  computed: {
    getSize() {
      return `0 0 ${this.width} ${this.height}`;
    }
  },
  mounted() {
    this.width = this.$el?.parentElement?.clientWidth;
    this.height = this.$el?.parentElement?.clientHeight;
  },
  template: `<content-loader
  :width="width"
  :height="height"
    :viewBox="getSize"
    :speed="2"
    primaryColor="#d3e3ec"
    secondaryColor="#a8c7d9"
>
<rect x="0" y="10" rx="4" ry="4" width="604" height="10" />
<rect x="0" y="41" rx="4" ry="4" width="205" height="18" />
<rect x="0" y="68" rx="4" ry="4" width="205" height="18" />
<rect x="0" y="95" rx="4" ry="4" width="205" height="18" />
<rect x="0" y="122" rx="4" ry="4" width="205" height="18" />
<rect x="0" y="149" rx="4" ry="4" width="205" height="18" />
<rect x="0" y="176" rx="4" ry="4" width="205" height="18" />
<rect x="0" y="203" rx="4" ry="4" width="205" height="18" />
<rect x="0" y="230" rx="4" ry="4" width="205" height="18" />
<rect x="0" y="257" rx="4" ry="4" width="205" height="18" />
<rect x="0" y="284" rx="4" ry="4" width="205" height="18" />
<rect x="0" y="311" rx="4" ry="4" width="205" height="18" />
<rect x="209" y="41" rx="4" ry="4" width="620" height="18" />
<rect x="209" y="68" rx="4" ry="4" width="620" height="18" />
<rect x="209" y="95" rx="4" ry="4" width="620" height="18" />
<rect x="209" y="122" rx="4" ry="4" width="620" height="18" />
<rect x="209" y="149" rx="4" ry="4" width="620" height="18" />
<rect x="209" y="176" rx="4" ry="4" width="620" height="18" />
<rect x="209" y="203" rx="4" ry="4" width="620" height="18" />
<rect x="209" y="230" rx="4" ry="4" width="620" height="18" />
<rect x="209" y="257" rx="4" ry="4" width="620" height="18" />
<rect x="209" y="284" rx="4" ry="4" width="620" height="18" />
<rect x="209" y="311" rx="4" ry="4" width="620" height="18" />

</content-loader>`
});
