'use strict';

var app = angular.module('postCenterWebClientApp');
app.directive('adSubmitButton', function() {
  return {
    restrict: 'E',
    scope: {
      text: '@',
      parentClass: '@class',
      callback: '&',
      loading: '=',
      disabledButton: '=',
      icon: '@',
    },
    link: function(scope, element) {
      $(element).removeClass(scope.parentClass);
    },
    template :  '<button class="{{parentClass}}" ng-click="callback()" ng-disabled="disabledButton">' +
                  '<div class="button-loading-container">' +
                  '<div class="button-loading-container">' +
                    '<div class="spin-container" ng-if="loading">' +
                      '<i class="fa fa-spin fa-spinner"></i>'+
                    '</div>' +
                    '<span ng-if = "!loading">' +
                      '<i ng-if="icon" class="fa" ng-class="icon"></i>' +
                      '{{text}}' +
                    '</span>' +
                  '</div>' +
                '</button>'
  };
});
