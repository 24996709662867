(module => {
  'use strict';
  PersonData.$inject = ['$q', '$rootScope', 'AdRequest'];
  function PersonData($q, $rootScope, AdRequest) {
    return {
      getUsersByPerson(personId) {
        return AdRequest.get(`/api/v1/person_users/${personId}/`);
      },
      mergeUsers(userA, userB) {
        return AdRequest.post('/api/v1/merge_users/', {userA, userB});
      },
      removePerson(userId) {
        return AdRequest.delete(`/api/v1/user/${userId}/person/`);
      }
    };
  }
  module.factory('PersonData', PersonData);
})(angular.module('postCenterWebClientApp'));
