/* eslint-disable prefer-arrow-callback */
'use strict';

angular.module('postCenterWebClientApp').directive('caseOpenClose', function() {
  return {
    scope: {
      caseObject: '=',
      editable: '=',
      callbackOnChange: '='
    },
    templateUrl: 'blocks/case/views/case_open_close.html',
    restrict: 'E',
    replace: true,
    controller: [
      '$scope',
      '$rootScope',
      'CaseService',
      'AdNotification',
      '$filter',
      'WorkflowStatuses',
      'adConstants',
      'FireTagService',
      'EstablishmentService',
      function(
        $scope,
        $rootScope,
        CaseService,
        AdNotification,
        $filter,
        WorkflowStatuses,
        adConstants,
        FireTagService,
        EstablishmentService: EstablishmentService
      ) {
        EstablishmentService.getEstablishment().then(establishment => {
          $scope.isWorkflowEnabled = establishment.config.workflow_enabled
        })
        $scope.workflowStatuses = [];

        var workflowStatusIndex = {};

        WorkflowStatuses.getAll().then(function(statuses) {
          statuses.forEach(function(status) {
            workflowStatusIndex[status.category] = status;
          });
          $scope.workflowStatuses = statuses;
        });

        $scope.loading = false;
        $scope.popover = {
          isOpen: false
        };

        function processAction(action, caseObject) {
          $scope.caseObject = caseObject;
          if ($scope.callbackOnChange) {
            $scope.callbackOnChange(action, caseObject);
          }
        }

        function reopenCase(caseId) {
          var action = 'reopen_case';
          $scope.loading = true;

          CaseService.reopen(caseId)
            .then(
              function(caseObject) {
                processAction('open', caseObject);
              },
              function(data) {
                AdNotification.error(data, action);
                $scope.loading = false;
              }
            )
            .finally(function() {
              $scope.tooltipText = $scope.getTooltipText();
              $scope.loading = false;
            });
        }

        function closeCase(caseId) {
          var action = 'close_case';
          $scope.loading = true;

          CaseService.close(caseId)
            .then(
              function(caseObject) {
                processAction('close', caseObject);
              },
              function(data) {
                AdNotification.error(data, action);
                $scope.loading = false;
              }
            )
            .finally(function() {
              $scope.tooltipText = $scope.getTooltipText();
              $scope.loading = false;
            });
        }

        const updateCaseWatcher = $rootScope.$on('column:actionOnCase', function(event, data) {
          if (data) {
            if (data.action === 'updateCase') {
              if (data.element.resource_id === $scope.caseObject.resource_id) {
                $scope.caseObject = data.element;
                initOpenClose();
              }
            }
          }
        });

        const editableWatcherOff = $scope.$watch('editable', newValue => {
          $rootScope.editableId = !newValue
            ? $scope.caseObject.resource_id
            : null;
        });
        $scope.isDisabled = false;
        const editableIdOff = $rootScope.$watch('editableId', newValue => {
          if (newValue) {
            if (newValue === $scope.caseObject.resource_id) {
              $scope.isDisabled = true;
            }
          } else {
            $scope.isDisabled = false;
          }
        });

        $scope.$on('$destroy', function () {
          editableWatcherOff();
          editableIdOff();
          updateCaseWatcher();
        });

        // The flags system is maintained:
        // if status is ignored or closed, it sets is_closed = true
        // if status is in progress or opened, it sets is_closed = false
        $scope.updateWorkflowStatus = function(workflowStatus) {
          if ($scope.editable) {
            const action = 'update_ticket_workflow_state';
            const {
              caseObject: {resource_id: caseId}
            } = $scope;

            $scope.loading = true;
            $scope.popover.isOpen = false;

            CaseService.updateWorkflowStatus(caseId, workflowStatus)
              .then(function(caseObject) {
                processAction(action, caseObject);
                $scope.loading = false;
                $scope.tooltipText = $scope.getTooltipText();
                const {
                  sn,
                  answered,
                  assigned,
                  createdFormatted: created,
                  is_closed
                } = caseObject;
                if (is_closed) {
                  FireTagService.setEvent({
                    name: 'cerrar_ticket',
                    params: {
                      sn,
                      answered,
                      assigned,
                      created
                    }
                  });
                }
              })
              .catch(function(data) {
                AdNotification.error(data, action);
                $scope.loading = false;
                $scope.tooltipText = $scope.getTooltipText();
              });
          }
        };

        if ($scope.caseObject) {
          initOpenClose();
        }

        function initOpenClose() {
          let theCase = $scope.caseObject;
          const isOurs = theCase.users && theCase.users.length === 0;
          const isAddressed = theCase.is_addressed;
          $scope.canIgnore = isOurs || !isAddressed;
        }

        // Update ticket status for clients with workflow statuses disabled
        $scope.toggleOpenClose = function() {
          if (!$scope.editable || $scope.loading) {
            return;
          }

          $scope.loading = true;
          var new_status = null;

          if ($scope.caseObject['is_closed']) {
            if ($scope.caseObject['no_manual_reopen'] === true) {
              $scope.loading = false;
              AdNotification.error({}, 'no_manual_reopen_on_ticket');
              return;
            } else {
              new_status = workflowStatusIndex[adConstants.WFS_CATEGORY_OPENED];
            }
          } else if ($scope.canIgnore) {
            new_status = workflowStatusIndex[adConstants.WFS_CATEGORY_IGNORED];
          } else {
            new_status = workflowStatusIndex[adConstants.WFS_CATEGORY_CLOSED];
          }

          if (new_status) {
            // the old behavior is maintained, see updateWorkflowStatus
            return $scope.updateWorkflowStatus(new_status);
          }

          // fallback to plain old behavior
          var caseId = $scope.caseObject['resource_id'];
          if ($scope.caseObject['is_closed'] === true) {
            if ($scope.caseObject['no_manual_reopen'] === false) {
              reopenCase(caseId);
            } else {
              $scope.loading = false;
              AdNotification.error({}, 'no_manual_reopen_on_ticket');
              return;
            }
          } else {
            closeCase(caseId);
          }
        };

        $scope.getTooltipText = function() {
          if ($scope.loading) {
            return '';
          }
          if ($scope.caseObject.is_closed) {
            var result =
              $filter('translate')('CASECONTROL_RESOLVED_ON') +
              ' ' +
              $filter('translate')($scope.caseObject.closedFormattedL) +
              '. ' +
              $filter('translate')('CASE_TOOK') +
              ' ' +
              $scope.caseObject.close_time;
            return result;
          } else {
            return $filter('translate')('CASECONTROL_CASE_IN_PROGRESS');
          }
        };

        $scope.tooltipText = $scope.getTooltipText();
      }
    ]
  };
});
