(module => {
  'use strict';
  module.controller('ColumnsCtrl', ColumnsCtrl);
  function ColumnsCtrl(
    $rootScope,
    $scope,
    ColumnsService: ColumnsService,
    $window,
    AdNotification,
    $timeout,
    ColumnsOrder,
    $filter,
    UserService: UserService,
    EstablishmentService: EstablishmentService
  ) {

    $scope.ticketDividedView = false;

    $scope.crm = {
      show: false,
      hasColumns: true
    }

    $scope.loadingColumns = false;
    $scope.columns = [];
    $scope.caseIsOpen = false;
    $scope.selectedColumnId = null;
    $scope.toDestroy = {};

    const viewDivisionSub = ColumnsService.dividedView.subscribe(dividedView => {
      $scope.ticketDividedView = dividedView
    })

    $scope.$on('$destroy', () => {
      viewDivisionSub.unsubscribe()
      for (const [key, destroyMethod] of Object.entries<() => {}>($scope.toDestroy)) {
          if (destroyMethod) {
              destroyMethod();
          }
      }
    })

    EstablishmentService.getEstablishment().then(establishment => {
      UserService.getProfile().then(user => {
        const {
          isCm, isAdminOrCm, isAdmin, isAnalystOrAdmin, isSupervisor,
          profile: {enable_edit_columns}
        } = user
        $scope.isAdminOrCM = isAdminOrCm
        $scope.isAdmin = isAdmin
        const onboardingNetworks = establishment.onboarding_networks
        $scope.pendingOnboarding = onboardingNetworks.length > 0 && !onboardingNetworks.every(network => network.connected === 'connected')
        $scope.canExport = isAnalystOrAdmin || isSupervisor
        $scope.canEditColumn = enable_edit_columns
        $scope.config = {
          isAdminOrCM: $scope.isAdminOrCM,
          isAdmin: $scope.isAdmin,
          canExport: $scope.canExport,
          canEditColumn: $scope.canEditColumn
        }
  
        const slaAgentActivated = establishment.config.sla_desk_metrics && isCm
        $scope.slaAgentActivated = slaAgentActivated ? 'ready' : null
  
        $scope.refreshColumns()
        const limitId = 'EXP'
        EstablishmentService.getLimits(limitId).then(count => {
          const {
            plan: {limits}
          } = establishment
          const limitsData = limits.find(limit => limit.limit_id === limitId)
          const {limit_max: limit} = limitsData
          $scope.config.limitReached = limit === count
        })
      })
    })

    function setSelectedColumnId(ticket?: Element) {
      if (ticket) {
        let selectedColumnId: string = ticket.getAttribute('parent-column');
        $scope.selectedColumnId = selectedColumnId;
      } else {
        ColumnsService.getFirstColumn().then(column => {
          $scope.selectedColumnId = column.resource_id
        })
      }
    }

    $scope.toDestroy.onSidebarColumnSelect = $rootScope.$on('column:select', (event, columnId) =>  {
      $scope.selectedColumnId = columnId;
    });

    $scope.toDestroy.onCaseOpened = $rootScope.$on('caseView:opened', (event, ticket) => {
      $scope.caseIsOpen = true;
      setSelectedColumnId(ticket);
    });

    $scope.toDestroy.onCaseClosed = $rootScope.$on('caseView:closed', () => {
      $scope.caseIsOpen = false;
      $scope.selectedColumnId = null;
    });

    $scope.ctrl = 'ColumnCtrl';

    $scope.newColumnModal = function () {
      $rootScope.$emit('modalNewColumn', 'Add', {});
    };

    const emitColumn = (column, columnIndex) => {
      const secondsToFadeIn = 0.1;
      $timeout(() => {
        $scope.$emit('addColumn', column);
      }, 1000 * secondsToFadeIn + 1000 * columnIndex);
    };
    
    function createRecommendedColumns(data) {
      $scope.loadingColumns = true;
      ColumnsService.createRecommendedColumn(data)
        .then(
          columns => {
            for (let index = 0; index < columns.length; index++) {
              emitColumn(columns[index], index);
            }
          },
          data => {
            AdNotification.error(data, 'Lack of permisses');
          }
        )
        .then(() => {
          setTimeout(() => {
            $scope.loadingColumns = false;
          }, 2000);
        });
    }
    $scope.createRecommendedColumns = target => {
      createRecommendedColumns({target});
    };

    $scope.refreshColumns = function () {
      // This will avoid the "no columns" column from blinking.
      $scope.crm.hasColumns = undefined;
      $scope.crm.show = false;
      ColumnsService.refreshColumns().then(
        columnsData => {
          $scope.columns = columnsData.sort(function(a,b) {return a.order - b.order});
          if ($scope.columns.length > 0) {
            $scope.crm.hasColumns = true;
          } else {
            $scope.crm.hasColumns = false;
          }
          $scope.crm.show = true;
          $rootScope.$emit('columnsLoaded')
          $scope.$apply() // this triggers a $digest
          updateColumnsDetails()
        },
        error => {
          $scope.crm.show = true;
          $scope.crm.hasColumns = false;
          AdNotification.error(error, 'get_columns');
        }
      );
    };

    $scope.toDestroy.refreshColumnsOff = $rootScope.$on('refreshColumns', () => {
      $scope.refreshColumns();
    });

    $scope.toDestroy.deleteColumnOff = $rootScope.$on('deleteColumn', (event, column) => {
      for (let i in $scope.columns) {
        if (column.resource_id === $scope.columns[i].resource_id) {
          $scope.columns.splice(i, 1);
        }
      }
      if ($scope.columns.length === 0) {
        $scope.crm.hasColumns = false;
      }
      let domColumn = document.getElementById(column.resource_id);
      domColumn.remove();
      $scope.refreshColumns();
    });

    $scope.toDestroy.addColumnOff = $rootScope.$on('addColumn', (event, column) => {
      $scope.columns.push(column);
      $scope.crm.hasColumns = true;
      $scope.refreshColumns();
    });

    $scope.toDestroy.editColumnOff = $rootScope.$on('editColumn', (event, column) => {
      $scope.refreshColumns();
    });

    //Column CRM

    function vw(percent) {
      var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
      return (percent * w) / 100;
    }

    function getColumnDetail(column) {
      const {
        resource_id,
        order,
        name: columnName,
        social_accounts,
        social_networks,
        column_type: type
      } = column;

      const name = columnName
        ? columnName
        : $filter('translate')('NEWCOLUMN_COLUMN');
      const getSnFromColumn = () => {
        if (social_networks.length > 0 && social_accounts.length === 0) {
          return social_networks[0];
        } else if (social_accounts.length > 0) {
          return social_accounts[0].sn;
        } else {
          return '';
        }
      };
      return {
        resource_id,
        name,
        order,
        sn: getSnFromColumn(),
        type,
        count: 0
      };
    }

    // GSAP Draggable related
    gsap.registerPlugin(Draggable);
    Draggable.zIndex = 500;
    let columnWidth = Math.min(410, vw(87));

    interface Sortable {
      resource_id: string,
      updated: boolean,
      cell:     Cell,
      dragger:  Draggable,
      element:  HTMLElement,
      index:    number,
      setIndex: Function
    }

    interface OrderUpdate {
      resource_id: string
      order: number
    }

    interface Cell {
      col: number
      x: number
      y: number
    }

    function updateScopeColumnsOrder(orderUpdates: Array<OrderUpdate>) {
    
      let scopeColumnsOrderUpdate = {}

      orderUpdates.forEach(
        (orderUpdate) => scopeColumnsOrderUpdate[orderUpdate.resource_id] = orderUpdate.order
      );

      for (let index in $scope.columns) {
        const column = $scope.columns[index];
        column.order = scopeColumnsOrderUpdate[column.resource_id];
      }
    }

    function formatOrderUpdate(sortable: Sortable): OrderUpdate {
      return {
        resource_id: sortable.resource_id,
        order: sortable.index,
      }
    }

    // Changes an elements's position in array
    function arrayMove(array: Array<Sortable>, from: number, to: number): void {
      array.splice(to, 0, array.splice(from, 1)[0]);
    }

    function updateGsapDraggables(): void {

      let container: HTMLElement = document.getElementById('columnContainer');
      let clampCol: number = gsap.utils.clamp(0, $scope.columns.length -1);
      let domColumns: Array<HTMLElement> = gsap.utils.toArray(".gsap-column");
      let cells: Array<Cell> = [];

      // Map cell locations to array
      for (let col = 0; col < $scope.columns.length; col++) {
        cells.push({col, x: col * columnWidth, y: 0});
      };

      let sortables = domColumns.map(Sortable);

      gsap.to(container, { autoAlpha: 1, duration: 0.5 });

      function changeIndex(item: Sortable, to: number): void {

        item.updated = true;

        // Change position in array
        arrayMove(sortables, item.index, to)
 
        // Set index for each sortable
        sortables.forEach((sortable, index) => sortable.setIndex(index));
      }

      function Sortable(element: HTMLElement, index: number) {

        let content: HTMLElement = element.querySelector(".panel");

        let animation = gsap.to(content, {
          duration: 0.3,
          boxShadow: "rgba(0,0,0,0.2) 0px 16px 32px 0px",
          force3D: true,
          scale: 1.05,
          y: 25,
          paused: true
        });

        let dragger = new Draggable(element, {        
          onDragStart: upAction,
          onRelease: downAction,
          onDrag: dragAction,
          minimumMovement: 6,
          trigger: content.firstChild
        });

        // let position = element._gsTransform;
        let getProp = gsap.getProperty(element);
        
        // Public properties and methods
        let sortable = {
          resource_id: element.id,
          updated: false,
          cell:     cells[index],
          dragger:  dragger,
          element:  element,
          index:    index,
          setIndex: setIndex
        }; 

        gsap.set(element, {
          x: sortable.cell.x, 
          y: sortable.cell.y, 
        });

        function setIndex(index: number) {
          let cell: Cell  = cells[index];

          // var dirty = position.x !== cell.x || position.y !== cell.y;
          let dirty = getProp("x") !== cell.x || getProp("y") !== cell.y;

          sortable.cell = cell;
          sortable.index = index;

          // Don't layout if you're dragging
          if (!dragger.isDragging && dirty) dropToCellPosition();
        }

        function upAction() {
          if ($scope.selectedColumnId !== element.id) {
            animation.play();
            this.update();
          }
        }

        function dragAction() {

          let col: number = clampCol(Math.round(this.x / columnWidth));

          // Check if position has changed
          if (col !== sortable.cell.col) {
            // Update the model
            changeIndex(sortable, col);
          }
        }

        function downAction() {
          setTimeout(() => {element.style.zIndex = 500;}, 500);
          animation.reverse();
          dropToCellPosition();

          if (sortable.updated) {

            let orderUpdates = sortables.map(formatOrderUpdate);
            ColumnsOrder.updateOrders(orderUpdates);

            updateScopeColumnsOrder(orderUpdates);

            sortable.updated = false;
          }
        }

        function dropToCellPosition() {
          gsap.to(element, { 
            duration: 0.3,
            x: sortable.cell.x, 
            y: sortable.cell.y
          });  
        }

        return sortable;
      }
    }

    function updateColumnsDetails() {

      updateGsapDraggables()

      const columnsDetails = {};

      for (let index in $scope.columns) {
        const column = $scope.columns[index];
        const {resource_id} = column;
        columnsDetails[resource_id] = getColumnDetail(column);
      }
      $rootScope.$emit('columnsUpdate', angular.copy(columnsDetails));
    }
  }
})(angular.module('postCenterWebClientApp'));
