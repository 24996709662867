(function(module) {
  'use strict';
  caseHistory.$inject = [];
  function caseHistory() {
    return {
      templateUrl: 'blocks/case/views/case_history.html',
      restrict: 'E',
      scope: {
        caseObject: '='
      },
      controller: 'caseHistoryCtrl',
      controllerAs: 'vm',
      bindToController: true
    };
  }
  module.directive('caseHistory', caseHistory);
})(angular.module('postCenterWebClientApp'));
