(module => {
  'use strict';
  OnboardingCtrl.$inject = [
    '$scope',
    'FRONTEND_VUE_URL',
    '$sce',
    '$transition$',
    'AdNotification',
    'InitialData',
    'UserService',
    '$translate',
    'FireTagService',
    'AdRequest',
    'AppStateService'
  ];
  module.controller('OnboardingCtrl', OnboardingCtrl);

  function OnboardingCtrl(
    $scope,
    FRONTEND_VUE_URL,
    $sce,
    $transition$,
    AdNotification,
    InitialData,
    UserService: UserService,
    $translate,
    FireTagService,
    AdRequest: AdRequestService,
    AppStateService: AppStateService
  ) {
    FireTagService.setPageView({
      title: 'Listo para empezar',
      path: `/${window.location.hash}`
    });

    const {vue_frame_name} = $transition$.params();

    function authFn() {
      UserService.getProfile().then(user => {
        $scope.loader = false;
        $translate.use(user.profile.language);
        $scope.iFrameUrl = function () {
          const url = `${FRONTEND_VUE_URL}/frames/${vue_frame_name}?authToken=${AdRequest.getToken()}`;
          return $sce.trustAsResourceUrl(url);
        };
      });
    }

    function refreshOnboarding() {
      InitialData.refreshOnboarding()
        .then(authFn)
        .catch(function (exception) {
          AdNotification.error(exception, 'loading_manage_account');
        });
    }

    function handleIframeMessage({data}) {
      if (
        data.type !== 'onboardingEvent' &&
        data.type !== 'trialExpirationEvent'
      ) {
        return;
      }
      const {message} = data;
      const actionMap = {
        account_connection: onboardingConnectAccount,
        redirect: deskRedirect
      };
      const fun = actionMap[message.action];
      if (fun) fun(message);
    }

    function onboardingConnectAccount() {
      AppStateService.goToChannel();
    }
    function deskRedirect({url}) {
      window.location.href = url;
    }

    function init() {
      $scope.loader = true;
      window.addEventListener('message', handleIframeMessage);
      refreshOnboarding();
    }

    init();
  }
})(angular.module('postCenterWebClientApp'));
