interface BusinessHourBlock {
  start: string
  end: string
}

interface BusinessHours {
  monday: BusinessHourBlock[]
  tuesday: BusinessHourBlock[]
  wednesday: BusinessHourBlock[]
  thursday: BusinessHourBlock[]
  friday: BusinessHourBlock[]
  saturday: BusinessHourBlock[]
  sunday: BusinessHourBlock[]
}

interface SupportChatConfig {
  use_postcenter_chat: boolean
  intercom_token?: string
  talkcenter_token?: string
}

interface EstablishmentConfig {
  accounts_blocking_outbound_media?: boolean
  assignation_free_tickets_on_disconnect: boolean
  assignation_unanswered_default: number
  assignation_workload_default: number
  assignment_config: {fifo: boolean}
  based_on_previous: boolean
  based_on_workload: boolean
  based_only_online: boolean
  block_outbound_media?: boolean
  big_export?: boolean
  business_hours: BusinessHours
  chat: SupportChatConfig
  custom_data_integration: boolean
  email_domain: string
  enable_queued_assignment: boolean
  force_only_online: boolean
  inbound_allowed_extensions: string[]
  integration_name: string
  integration_window_request: {method: string, url: string}
  keep_manually_assigned: boolean
  notification_report_time_window: number
  obfuscate_credit_card_image: boolean
  obfuscate_credit_card_number: boolean
  outbound_allowed_extensions: string[]
  secure_files: boolean
  outbound_audio: boolean
  sla_desk_metrics: boolean
  twitter_api_key: string
  workflow_enabled: boolean
  workload_range: number
}

interface EstablishmentCountry {
  key: string
  lang: string
  name: string
  phone_prefix?: string
  resident_id: string
}

interface IntegrationConfig {
  active: boolean
}

interface EstablishmentIntegrations {
  integration_configs: {config: IntegrationConfig, name: string}[]
  integration_descriptions: {name: string, image_url: string}[]
  surveys_active: boolean
}

interface AderesoPlan {
  currency: string
  limits: {duration?: number, limit_id: string, limit_max: number}[]
  price: number
  started_paying_date?: string
  trial_expiration_date?: string
  trial_days_remaining?: number
}

interface FeatureFlags {
  block_outbound_media?: boolean
  supervisor_role?: boolean
  shop_integration?: boolean
  show_epa?: boolean
  adereso_ai?: boolean
  ads_details?: boolean
  campaigns_module?: boolean
  satisfaction_module?: boolean
  contact_book_new?: boolean
  case_data_vue?: boolean
}

interface Establishment {
  address: string
  case_counter: number
  config: EstablishmentConfig
  country: EstablishmentCountry
  created_at: string
  email: string
  features: FeatureFlags
  id: number
  integrations: EstablishmentIntegrations
  is_active: boolean
  is_corporate: boolean
  max_app_store_accounts: number
  max_chat_accounts: number
  max_email_accounts: number
  max_facebook_accounts: number
  max_instagram_accounts: number
  max_play_store_accounts: number
  max_retweets_before_alert: number
  max_retweets_before_critical: number
  max_retweets_before_warning: number
  max_twitter_accounts: number
  max_twitter_search_accounts: number
  max_twitter_search_quota: number
  max_user_accounts: number
  max_whatsapp_accounts: number
  name: string
  onboarding_networks: string[]
  phone: string
  plan: AderesoPlan
  public_id: string
  slug: string
  timezone: string
  token: string
}

interface TrialStatus {
  days_remaining: number | null
}

class EstablishmentService {

  private AdRequest: AdRequestService
  private establishmentModel: AdModelFetcher<Establishment>
  public estObservable: rxjs.Observable<Establishment>

  constructor(AdRequest: AdRequestService, $rootScope) {
    this.AdRequest = AdRequest
    AdRequest.loggedOut.subscribe(() => this.establishmentModel.clear())
    this.establishmentModel = new AdModelFetcher(
      () => this.AdRequest.get<Establishment>('/api/v1/establishment/')
    )
    this.estObservable = this.establishmentModel.observable()
    this.estObservable.subscribe(establishment => {
      $rootScope.establishment = establishment;
    })
  }

  getBusinessStatus() {
    return this.AdRequest.get('/api/v1/establishment/business_status/');
  }

  refreshEstablishment(): Promise<Establishment> {
    return this.establishmentModel.refresh()
  }
  
  getEstablishment(): Promise<Establishment> {
    return this.establishmentModel.get()
  }

  editEstablishment(data, config) {
    return this.AdRequest.put('/api/v1/establishment/', data, config)
  }
  
  setKeywordsHashtags(data, config) {
    return this.AdRequest.post('/api/v1/establishment/keywords/', data, config)
  }
  
  editEstablishmentAssign(data, config) {
    return this.AdRequest.put(
      '/api/v1/establishment/assignation/',
      data,
      config
    );
  }
  
  getTrialStatus(): Promise<TrialStatus> {
    return this.getEstablishment().then(est => {
      let days = est.plan.trial_days_remaining
      return {
        days_remaining: days
      }
    })
  }
  
  async getLimits(limitId) {
    try {
      const limits = await this.AdRequest.get(
        `/api/v1/establishment/limit_count/`
      )
      const limit = limits.find(({limit_id}) => limit_id === limitId)
      if (!limit) {
        throw new Error()
      }
      return limit
    } catch (error) {
      return -1
    }
  }
}

angular
  .module('postCenterWebClientApp')
  .service('EstablishmentService', EstablishmentService);
