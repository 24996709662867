Vue.component('contact-agenda-tables', {
  data() {
    return {
      debounceViewContact: debounce(this.viewContact, 450),
      loading: false,
      page: 1,
      highlightClass: 'contact-field--row__highlight'
    };
  },
  computed: {
    ...Vuex.mapState(['totalContacts', 'filters', 'contacts']),
    ...Vuex.mapGetters([
      'tableColumns',
      'favoriteContacts',
      'getSocialAccountById'
    ]),
    tables() {
      const {tableColumns, favoriteContacts, contacts, totalContacts} = this;
      const {getReadableClient, getReadableColumn, loadMoreContacts} = this;
      return {
        columns: tableColumns.enabled.map(getReadableColumn),
        data: [
          {
            title: this.$t('favorites'),
            total: favoriteContacts.length,
            rows: favoriteContacts.map(getReadableClient)
          },
          {
            title: this.$t('contacts'),
            total: totalContacts,
            rows: contacts.map(getReadableClient),
            action: loadMoreContacts
          }
        ]
      };
    }
  },
  methods: {
    ...Vuex.mapActions([
      'setVisibleForm',
      'viewContact',
      'toggleFavorite',
      'getContacts'
    ]),
    getReadableClient(contact = {}) {
      const _joinLists = (list = []) => list.join(', ');
      const alreadyTranslated = ['gender', 'sn', 'contactedAccounts'];

      Object.values(this.tableColumns.enabled).forEach(column => {
        const {field, type, options} = column;
        const value = {current: contact[field], new: undefined};

        if (value.current === undefined) {
          return;
        } else if (alreadyTranslated.includes(column.field)) {
          return;
        } else {
          switch (type) {
            case 'select':
              const selected = options.find(
                option => option.key === value.current
              );
              value.new = selected?.value;
              break;
            case 'checkbox':
              if (!value.current) {
                return;
              }
              const enabled = options
                .filter(option => value.current[option.key])
                .map(option => option.value);

              value.new = _joinLists(enabled);
              break;
            case 'list':
              if (Array.isArray(value.current)) {
                value.new = _joinLists(value.current);
              }
              break;
            case 'boolean':
              value.new = this.$tc('columns.boolean', Number(value.current));
              break;
            default:
              value.new = value.current;
          }
        }

        contact = {...contact, [field]: value.new};
      });

      contact.socialAccounts = contact.social_accounts
        .map(this.getSocialAccountById)
        .filter(value => !!value);

      return contact;
    },
    getReadableColumn(column = {}) {
      return {
        ...column,
        label: column.label || this.$t(`columns.${column.field}`)
      };
    },
    onRowSelected(event = {}) {
      this.clearHighlighted();
      this.highlightRow(event.currentTarget);
    },
    onFieldSelected(event = {}, field = '', contact = {}) {
      event.preventDefault();

      if (field === 'favorite') {
        this.toggleFavorite(contact);
        this.clearHighlighted();
      } else if (field === 'contact') {
        this.viewContact(contact).finally(() => {
          this.setVisibleForm('contact');
        });
      } else {
        this.debounceViewContact(contact);
      }
    },
    clearHighlighted() {
      const elements = this.$el.getElementsByClassName(this.highlightClass);

      Array.from(elements).forEach(element =>
        element.classList.remove(this.highlightClass)
      );
    },
    highlightRow(target) {
      target.classList.add(this.highlightClass);
    },
    scrollToTop() {
      const tables = this.$el.querySelectorAll('header');

      Array.from(tables).forEach(table => table.scrollIntoView());
    },
    loadMoreContacts() {
      this.loading = true;
      // Avoid loading a new page when not needed
      if (this.page) {
        this.page++;
        this.getContacts(this.page)
          .catch(() => (this.page = 0))
          .finally(() => (this.loading = false));
      }
    }
  },
  watch: {
    filters() {
      this.page = 1;
    }
  },
  template: `
    <section class="contact-agenda--tables">
      <contact-agenda-none v-if="!contacts.length" />
      <template v-else>
        <div v-for="table in tables.data"
             v-show="table.rows.length"
             class="card card__shadow text-overflow contact-agenda--table">
          <h4 class="mt-0 bold text-info">
            {{ table.title }}
            <small>
              {{$tc('viewing', table.total, {count: table.rows.length, total: table.total })}}
            </small>
            <small v-if="filters.new && table.action" class="pull-right">
              {{ $t('filters.whatIsNew') }}
            </small>
          </h4>
          <Table class="contact-agenda--table d-block scrollable"
                 :headings="tables.columns"
                 :rows="table.rows"
                 :allow-sorting="false"
                 v-infinite-scroll="table.action"
                 @onCellSelected="($event, field, contact) => onFieldSelected($event, field, contact)"
                 @onRowSelected="($event, row) => onRowSelected($event, row)" />
          <Loader v-show="page && loading && table.action" size="2x"/>
        </div>
      </template>
    </section>
  `
});
