'use strict';

var app = angular.module('postCenterWebClientApp');
app.service('AdUtils', function ($q) {

  this.loadImage = function(file) {
    var deferred = $q.defer();
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function (fileOnLoadEvent) {
      var img = new Image();
      img.src = fileOnLoadEvent.target.result;
      img.onload = function(imageOnLoadEvent) {
        deferred.resolve(imageOnLoadEvent.target);
      };
      img.onerror = function(data) {
        deferred.reject(data);
      };
      img.onabort = function(data) {
        deferred.reject(data);
      };
    };
    reader.onerror = function(data) {
      deferred.reject(data);
    };
    reader.onabort = function(data) {
      deferred.reject(data);
    };
    return deferred.promise;
  };

});
