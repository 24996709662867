Vue.component('MeteringBar', {
  props: {
    meterType: {type: String, required: true},
    value: {type: Number, required: true},
    max: {type: Number, default: 100},
    min: {type: Number, default: 0}
  },
  computed: {
    range() {
      const rangeType = {
        default: {
          low: this.max * 0.2,
          optimum: this.max * 0.6,
          high: this.max * 0.9
        },
        assignment: {
          low: this.max * 0.6,
          optimum: this.max * 0.4,
          high: this.max * 0.85
        }
      }[this.meterType];
      return rangeType;
    }
  },
  methods: {
    notifyHighValue(value) {
      this.$emit('onHighValue', value);
    }
  },
  watchers: {
    value(value) {
      if (value >= this.range.high) {
        this.notifyHighValue(value);
      }
    }
  },
  template: `
    <meter class="ad-metering"
           :class="meterType"
           :value="value"
           :min="min"
           :max="max"
           :low="range.low"
           :high="range.high"
           :optimum="range.optimum">{{ value}}/{{ max }}</meter>`
});
