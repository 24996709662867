const HistoryTabs = Vue.component('HistoryTabs', {
  data() {
    return {
      selected: '',
      debounceSelected: '',
      tabs: []
    };
  },
  computed: {
    ...Vuex.mapGetters(['getGroups', 'activeGroup'])
  },
  watch: {
    selected(newValue) {
      this.debouncer(newValue);
    }
  },
  mounted() {
    this.buildTabs();
    // this.setTab(this.tabs[0]);
  },
  methods: {
    ...Vuex.mapActions(['setGroup']),
    buildTabs() {
      const mapTabs = this.getGroups.map(group => {
        return {
          group,
          name: this.$t(`tabs.${group}`),
          active: group === this.activeGroup
        };
      });
      this.selected = this.activeGroup;
      this.tabs = mapTabs;
    },
    setTab(tab) {
      const {active, group} = tab;
      if (active) {
        this.setGroup(group);
        this.selected = group;
      }
    },
    debouncer: _.debounce(
      function () {
        this.debounceSelected = this.selected;
      },
      100,
      false
    )
  },

  template: `
    <Tabs :tabs="tabs" :padding-tabs="2">
      <HistoryFilters :group="selected" />
      <Tab v-for="tab in tabs"
           :key="tab.group"
           :name="tab.name"
           :active="tab.active"
           :get-tab="setTab(tab)">
          <history-tab-wrapper
            v-if="selected === tab.group"
            :group="tab.group" class="px-1" />
      </Tab>
    </Tabs>
  `
});
