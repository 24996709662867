'use strict';

var app = angular.module('postCenterWebClientApp');
/*
don´t rely on $index when custom sorting on ng-repeats.
use this filter instead
http://stackoverflow.com/questions/16118762/angularjs-wrong-index-after-orderby
*/
app.filter('index', function () {
    return function (array, index) {
        if (!index)
            index = 'index';
        for (var i = 0; i < array.length; ++i) {
            array[i][index] = i;
        }
        return array;
    };
});