'use strict';

class NewCaseModalController {

  adConstants
  AdAvailableSn
  $document
  $filter
  $scope
  $timeout
  $rootScope
  UserData
  ColumnsService: ColumnsService
  AdNotification: AdNotification
  FireTagService
  adDataValidator
  AdModal
  UserService: UserService
  workflowStatusIndex = {};

  modalNewCaseOpen
  modalNewCaseClose

  private hotkeys(event) {
    switch (event.keyCode) {
      case 13: // enter
        if (!this.$scope.isColumnAddFormInvalid) {
          this.$scope.$apply(this.$scope.submitColumnAddForm);
        }
        break;
      case 27: // escape
        this.$scope.$apply(this.close());
        break;
    }
  }

  public handleModalEvent(accountUid: string, userUid: string) {
    this.$scope.show = true;
    this.$scope.accountUid = accountUid;
    this.$scope.userUid = userUid;
    if (userUid) {this.setIndividualMode();}
    this.$document.on('keydown', this.hotkey_handler);
  }

  private addEventListeners() {
    this.modalNewCaseOpen = this.$rootScope.$on(
      'modalNewCaseOpen',
      (event, accountUid: string, userUid: string) => this.handleModalEvent(accountUid, userUid)
    );

    this.modalNewCaseClose = this.$rootScope.$on(
      'modalNewCaseClose',
      (event) => this.close()
    );
    let campaignCreatedSuccessfully = this.$rootScope.$on(
      'campaignCreatedSuccessfully',
      () => this.$scope.setCampaignsMode()
    );
  
    this.$scope.$on('$destroy', () => {
      this.modalNewCaseOpen();
      this.modalNewCaseClose();
      campaignCreatedSuccessfully();
    });
  }

  private close () {
    this.$document.off('keydown', this.hotkey_handler);
    this.$scope.show = false;
  };

  private setIndividualMode() {
    this.$scope.mode = 'individual';
  }

  private setCampaignsMode() {
    this.$scope.mode = 'campaigns';
  }

  private isIndividualMode(): boolean {
    return this.$scope.mode == 'individual';
  }

  private setMasiveMode() {
    this.$scope.mode = 'masive';
  }

  private isMasiveMode(): boolean {
    return this.$scope.mode == 'masive';
  }

  private isCampaignsMode(): boolean {
    return this.$scope.mode == 'campaigns';
  }

  private getModalDescription(): string {
    if (this.isIndividualMode()) {
      return 'GENERAL_NEW_TICKET_DESCRIPTION'
    } else if (this.isMasiveMode()) {
      return 'GENERAL_NEW_CAMPAIGN_DESCRIPTION'
    } else if (this.isCampaignsMode()) {
      return 'GENERAL_MY_CAMPAIGNS_DESCRIPTION'
    }
  }

  constructor(
    $scope,
    $rootScope,
    $filter,
    $timeout,
    UserData,
    AdNotification: AdNotification,
    ColumnsService: ColumnsService,
    $document,
    adConstants,
    AdAvailableSn,
    FireTagService,
    UserService: UserService,
    StylesService: StylesService
  ) {
    this.$filter = $filter
    this.$scope = $scope
    this.$rootScope = $rootScope
    this.$document = $document
    this.$timeout = $timeout
    this.AdAvailableSn = AdAvailableSn
    this.AdNotification = AdNotification
    this.adConstants = adConstants
    this.UserData = UserData
    this.ColumnsService = ColumnsService
    this.FireTagService = FireTagService
    this.hotkey_handler = (event) => this.hotkeys(event)
    this.$scope.show = false;
    this.$scope.mode = 'individual';
    this.$scope.massiveEnabled = false;

    Promise.all([
      UserService.getProfile(),
      StylesService.getStyles()
    ]).then(([user, styles]) => {
      let hasRole = user.isAdmin || user.isSupervisor
      this.$scope.massiveEnabled = hasRole && styles.campaignsDisabled !== 'true'
    })

    this.addEventListeners();
    this.setIndividualMode();
    $scope.close = () => this.close()
    $scope.setIndividualMode = () => this.setIndividualMode()
    $scope.isIndividualMode = () => this.isIndividualMode()
    $scope.setMasiveMode = () => this.setMasiveMode()
    $scope.isMasiveMode = () => this.isMasiveMode()
    $scope.setCampaignsMode = () => this.setCampaignsMode()
    $scope.isCampaignsMode = () => this.isCampaignsMode()
    $scope.getModalDescription = () => this.getModalDescription()

  }
}
angular
  .module('postCenterWebClientApp')
  .controller(
    'NewCaseModalCtrl',
    NewCaseModalController
  );
