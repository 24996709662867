(module => {
  'use strict';
  ForgottenPasswordCtrl.$inject = [
    '$scope',
    'StylesService',
    'AuthService',
    'AdNotification',
    '$rootScope',
    'RECAPTCHA_CHECKBOX_TOKEN',
    '$filter',
    'VersionService'
  ];
  function ForgottenPasswordCtrl(
    $scope,
    StylesService,
    AuthService,
    AdNotification,
    $rootScope,
    RECAPTCHA_CHECKBOX_TOKEN,
    $filter,
    VersionService
  ) {
    $scope.forgotten_password_email = '';
    VersionService.getVersion().then(version => {
      $scope.version = version
    })

    StylesService.getStyles().then(styles => {
      $scope.styles = styles
    })

    $scope.sendForgottenPasswordEmail = function () {
      $scope.loading = true;
      AuthService.sendForgottenPasswordEmail(
        $scope.forgotten_password_email,
        $scope.captchaToken
      ).then(
        () => {
          $scope.sent = true;
          $scope.loading = false;
        },
        data => {
          $scope.loading = false;
          AdNotification.error(data, 'forgot_password');
        }
      );
    };
    if (grecaptcha) {
      grecaptcha.ready(() => {
        grecaptcha.render(document.getElementById('forgottenRecaptcha'), {
          sitekey: RECAPTCHA_CHECKBOX_TOKEN,
          callback: token => {
            setCaptcha(token);
          },
          'expired-callback': () => {
            setCaptcha(null);
          },
          'error-callback': () => {
            AdNotification.notify(
              $filter('translate')('LOGIN_NETWORK_ISSUE'),
              'error'
            );
          }
        });
      });
    }
    const setCaptcha = token => {
      $scope.captchaToken = token;
      $scope.$apply();
    };
  }
  module.controller('ForgottenPasswordCtrl', ForgottenPasswordCtrl);
})(angular.module('postCenterWebClientApp'));
