'use strict';
(function(module) {
  'use strict';
  integrationView.$inject = [];
  function integrationView() {
    return {
      templateUrl: 'blocks/case/views/integration_view.html',
      restrict: 'E',
      scope: {
        caseObject: '=',
        userObject: '='
      },
      controller: 'caseIntegrationCtrl',
      controllerAs: 'vm',
      bindToController: true
    };
  }
  module.directive('integrationView', integrationView);
})(angular.module('postCenterWebClientApp'));
