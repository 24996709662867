'use strict';

angular
  .module('postCenterWebClientApp')
  .directive('newCaseEmail', postCenterNewCaseEmailDirective);

function postCenterNewCaseEmailDirective() {
  return {
    templateUrl: 'blocks/crm/views/new_cases/email_case.html',
    restrict: 'E',
    scope: {
      account: '=',
      form: '='
    },
    controller: postCenterNewCaseEmailController
  };
}

postCenterNewCaseEmailController.$inject = [
  '$scope',
  '$rootScope',
  '$filter',
  'AdNotification',
  'CaseService',
  'adDataValidator',
  'UserService'
];

function postCenterNewCaseEmailController(
  $scope,
  $rootScope,
  $filter,
  AdNotification,
  CaseService,
  adDataValidator,
  UserService: UserService
) {
  $scope.loading = true;

  UserService.getProfile().then(user => {
    const {
      profile: {language}
    } = user

    const lang = language === 'es' ? 'es-ES' : language === 'pt' ? 'pt-BR' : ''
    const placeholder = $filter('translate')(
      'GENERAL_NEW_TICKET_EMAIL_PLACEHOLDER'
    )

    $scope.options = {
      height: 120,
      focus: true,
      dialogsInBody: true,
      placeholder,
      toolbar: [
        ['style', ['bold', 'italic', 'underline']],
        ['insert', ['link', 'table']],
        ['fontsize', ['fontsize']],
        ['para', ['ul', 'ol', 'paragraph']],
        ['height', ['height']],
        ['fontname', ['fontname']]
      ],
      shortcuts: {},
      lang
    }

    initForm()
  })

  $scope.createTicket = function() {
    $scope.form.$setSubmitted();
    if (validateFields()) {
      $scope.loading = true;
      CaseService.newCase($scope.ticket).then(
        function(response) {
          var caseId = response['case_id'];
          $rootScope.$emit('modalNewCaseClose');
          AdNotification.success({status: 201, response: {caseId: caseId}}, 'email_ticket_created');
          initForm();
        },
        function(error) {
          AdNotification.error(error, 'error_creating_email_ticket');
          $scope.loading = false;
        }
      );
    }
  };

  function hasValidName() {
    return adDataValidator.hasData($scope.ticket.user.name);
  }

  function hasValidEmail() {
    return (
      adDataValidator.hasData($scope.ticket.user.email)
      && adDataValidator.checkEmail($scope.ticket.user.email)
    )
  }

  function hasValidSubject() {
    return adDataValidator.hasData($scope.ticket.subject);
  }

  function hasValidContent() {
    return adDataValidator.hasData($scope.ticket.content);
  }

  $scope.validateName = function () {
    if (!hasValidName()) {
      $scope.errors.noClientName = true;
      return false;
    }
    $scope.errors.noClientName = false;
    return true;
  };

  $scope.validateEmail = function () {
    if (!hasValidEmail()) {
      $scope.errors.noValidClientEmail = true;
      return false;
    }
    $scope.errors.noValidClientEmail = false;
    return true;
  };

  $scope.validateSubject = function() {
    if (!hasValidSubject()) {
      $scope.errors.noTicketSubject = true;
      return false;
    }
    $scope.errors.noTicketSubject = false;
    return true;
  };

  $scope.validateContent = function() {
    if (!hasValidContent()) {
      $scope.errors.noTicketContent = true;
      return false;
    }

    $scope.errors.noTicketContent = false;
    return true;
  };

  $scope.closeBlock = function() {
    initForm();
    $scope.$emit('closeBlock');
  };

  function initForm() {
    $scope.ticket = {
      account: $scope.account,
      user: {name: null, email: null},
      subject: null,
      content: null
    };
    $scope.errors = {
      noAccount: false,
      noClientName: false,
      noValidClientEmail: false,
      noTicketSubject: false,
      noTicketContent: false
    };
    $scope.loading = false
  }

  $scope.canSubmit = function() {
    return hasValidName() && hasValidEmail() && hasValidSubject() && hasValidContent();
  };

  function validateFields() {
    return [
      $scope.validateName(),
      $scope.validateEmail(),
      $scope.validateSubject(),
      $scope.validateContent()
    ].every(function(boolean) {
      return boolean;
    });
  }
}
