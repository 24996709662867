'use strict';

angular.module('postCenterWebClientApp').directive('adSelector', () => {
  return {
    scope: {
      leftList: '=',
      rightList: '='
    },
    templateUrl: 'blocks/global/views/ad_selector.html',
    restrict: 'E',
    controller: [
      '$scope',
      'AssignmentService',
      '$q',
      '$timeout',
      'AdNotification',
      'InitialData',
      function(
        $scope,
        AssignmentService,
        $q,
        $timeout,
        AdNotification,
        InitialData
      ) {
        let oldLeftList = angular.copy($scope.leftList);
        let oldRightList = angular.copy($scope.rightList);

        setSelected($scope.leftList, false);
        setSelected($scope.rightList, false);

        $scope.allToLeft = function() {
          $scope.leftList = $scope.leftList.concat($scope.rightList);
          moveElements($scope.rightList, 'left');
          setSelected($scope.leftList, false);

          $scope.rightList = [];
          $scope.hasChanged = hasChanged();
        };

        $scope.allToRight = function() {
          $scope.rightList = $scope.rightList.concat($scope.leftList);
          moveElements($scope.leftList, 'right');
          setSelected($scope.rightList, false);
          $scope.leftList = [];
          $scope.hasChanged = hasChanged();
        };

        $scope.toLeft = function() {
          var toLeftList = getSelected($scope.rightList, true);
          $scope.rightList = getSelected($scope.rightList, false);

          setSelected(toLeftList, false);
          moveElements(toLeftList, 'left');

          $scope.leftList = $scope.leftList.concat(toLeftList);
          $scope.hasChanged = hasChanged();
        };

        $scope.toRight = function() {
          var toRightList = getSelected($scope.leftList, true);
          $scope.leftList = getSelected($scope.leftList, false);

          setSelected(toRightList, false);
          moveElements(toRightList, 'right');

          $scope.rightList = $scope.rightList.concat(toRightList);
          $scope.hasChanged = hasChanged();
        };

        $scope.discardChanges = function() {
          $scope.leftList = oldLeftList;
          $scope.rightList = oldRightList;
          setSelected($scope.leftList, false);
          setSelected($scope.rightList, false);
          movedLeft = {};
          movedRight = {};
          $scope.hasChanged = hasChanged();
        };

        $scope.saveChanges = function() {
          var dataNoAssignable = getCMID($scope.leftList);
          var dataAssignable = getCMID($scope.rightList);

          var action = 'update_assignments';

          $q.all([
            AssignmentService.setAssignable(dataAssignable),
            AssignmentService.setNoAssignable(dataNoAssignable)
          ]).then(
            something => {
              movedLeft = {};
              movedRight = {};
              oldLeftList = angular.copy($scope.leftList);
              oldRightList = angular.copy($scope.rightList);
              setSelected($scope.leftList, false);
              setSelected($scope.rightList, false);
              $scope.hasChanged = hasChanged();
              InitialData.getCms();
              AdNotification.success(202, action);
            },
            data => {
              AdNotification.error(data, action);
            }
          );
        };

        var movedLeft = {};
        var movedRight = {};

        function getCMID(list) {
          return _.map(list, element => {
            return element.cmid;
          });
        }

        function getSelected(list, val) {
          return _.filter(list, element => {
            return element.selected === val;
          });
        }

        function setSelected(list, val) {
          _.map(list, element => {
            element.selected = val;
          });
        }

        function moveElements(list, dir) {
          _.map(list, element => {
            moveElement(element, dir);
          });
        }

        function moveElement(element, dir) {
          if (dir === 'left') {
            if (movedRight[element.cmid]) {
              movedRight[element.cmid] = false;
            } else {
              movedLeft[element.cmid] = true;
            }
          }
          if (dir === 'right') {
            if (movedLeft[element.cmid]) {
              movedLeft[element.cmid] = false;
            } else {
              movedRight[element.cmid] = true;
            }
          }
        }

        function hasChanged() {
          for (var key in movedLeft) {
            if (movedLeft.hasOwnProperty(key) && movedLeft[key]) {
              return true;
            }
          }
          for (key in movedRight) {
            if (movedRight.hasOwnProperty(key) && movedRight[key]) {
              return true;
            }
          }
          return false;
        }
      }
    ]
  };
});
