(function(module) {
  'use strict';
  CaseCtrl.$inject = [
    '$scope',
    'CaseService',
    'CaseDetailManager',
    'SlaHandlerService'
  ];

  function CaseCtrl(
    $scope,
    CaseService,
    CaseDetailManager: CaseDetailManager,
    SlaHandlerService
  ) {

    $scope.last_author = "";
    this.$onInit = () => {
      CaseService.setFormattedDates($scope.case);
      const {last_content} = $scope.case;
      _setLastContent(last_content);
      _setMainRelatedUser();

      $scope.hasAnswerTimeSLA = () => {
        return SlaHandlerService.hasAnswerTimeSLA($scope.case);
      };

      $scope.isAnswerTimeSLAExpired = () => {
        return SlaHandlerService.isAnswerTimeSLAExpired($scope.case);
      }

      $scope.getWaitingTime = () => {
        return SlaHandlerService.getWaitingTime($scope.case);
      }

      $scope.getSlaConfig = () => {
        return SlaHandlerService.getSlaConfig($scope.case);
      }

      if ($scope.case['created_at'] < 1000000000000) {
        $scope.case['created_at'] *= 1000;
      }
      $scope.showUserMetadata = false;

    };

    const _setLastContent = lastContent => {
      const maxChars = 150;

      if ($scope.case.sn === 'email') {
        const formatHtml = _.unescape(lastContent);
        const temp = document.createElement('div');
        temp.innerHTML = formatHtml;
        lastContent = temp.textContent || temp.innerText;
        lastContent.replace(/^\s*\n/gm, '');
      }
      if ($scope.case['is_media'] === undefined) {
        if (lastContent === 'MEDIA_MESSAGE') {
          lastContent = '';
          $scope.case['is_media'] = true;
        } else {
          $scope.case['is_media'] = false;
        }
      }
      if (lastContent.length > maxChars) {
        lastContent = `${lastContent.substring(0, maxChars)}...`;
      }
      $scope.case.last_content = lastContent;
      if ($scope.case.last_author) {
        $scope.last_author = $scope.case.last_author.replace(/ .*/,'');
      }
    };

    $scope.toggleShowUserMetadata = () => {
      $scope.showUserMetadata = !$scope.showUserMetadata;
    };

    $scope.clickColumnCase = event => {
      CaseDetailManager.openCaseState(
        $scope.case.identifier, event.target.closest('.case')
      )
    }

    const customDataBlockCancelOff = $scope.$on('customDataBlock:cancel', () => {
      $scope.showUserMetadata = false;
    });

    $scope.$on('$destroy', () => {
      customDataBlockCancelOff();
    });

    const _setMainRelatedUser = () => {
      const {
        users: _users = [],
        establishment_users: eusers = []
      } = $scope.case;

      if ($scope.case) {
        if (_users.length > 0) {
          $scope.mainRelatedUser = _users[0];
        } else if (eusers.length > 0) {
          $scope.mainRelatedUser = eusers[0];
        } else {
          $scope.mainRelatedUser = {};
        }
      }
    };
  }

  module.controller('CaseCtrl', CaseCtrl);
})(angular.module('postCenterWebClientApp'));
