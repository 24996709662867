const Monitoring = Vue.component('monitoring', {
  data() {
    return {
      autoreload: true,
      interval: null,
      loading: false,
      dates: {
        from: '',
        to: ''
      },
      selected: '30',
      periods: [
        {label: 'period-selector.today', value: 'day'},
        {label: 'period-selector.this-week', value: 'week'},
        {
          label: 'period-selector.this-month',
          value: 'month'
        },
        {
          label: 'period-selector.last-30-days',
          value: '30'
        }
      ]
    };
  },
  methods: {
    ...Vuex.mapActions([
      'setTicketsDetail',
      'getTicketsDetail',
      'createColumn',
      'setModal',
      'updateRange',
      'updateMonitoring'
    ]),
    filterRange(range) {
      if (range !== '30') {
        this.dates.from = moment().startOf(range).unix();
        this.dates.to = moment().unix();
        return this.dates;
      }
      this.dates.from = moment().subtract(30, 'days').unix();
      this.dates.to = moment().unix();

      return this.dates;
    },
    updateFilters() {
      const updateRange = this.filterRange(this.selected);
      this.updateRange(updateRange);
      this.updateMonitoring();
    },
    /**
     * @method livereload
     * @description If `this.autoreload` is enabled, update `state.lastUpdateTime`
     * so that metrics (`monitoring-ticket-metric`) are updated once a minute
     * @returns Interval to be cleared once this section is destroyed
     */
    livereload() {
      this.interval = setInterval(() => {
        if (this.autoreload) {
          this.updateFilters();
        }
      }, 60000);
    },
    columnPagination() {
      this.loading = true;
      this.getTicketsDetail(this.accountDetail.account).finally(
        () => (this.loading = false)
      );
    }
  },
  computed: {
    ...Vuex.mapState(['accountDetail']),
    ...Vuex.mapGetters(['ticketStates', 'getRange', 'locale']),
    states() {
      const translateKey = key => ({key, label: this.$t(key)});
      return {
        all: this.ticketStates.all.map(translateKey),
        rows: this.ticketStates.rows.map(translateKey)
      };
    },
    fromDate() {
      const {from} = this.getRange;
      return moment.unix(from).format('lll');
    }
  },
  created() {
    this.livereload();
  },
  beforeDestroy() {
    // Stop auto refreshing
    clearInterval(this.interval);
  },
  template: `
    <div class="monitoring">
      <SectionHeader
        :title="$t('title')"
        icon="desktop"
        support-section="anal%C3%ADtica/89RzusWYBEWNTBJg2WFu5G/monitoreo-20-/sJM6bdGzUmTa5dnPh7FKTn">
        <template v-slot:buttons>
          <label for="autoreload" class="mr-1" @click="livereload()">
            <input type="checkbox"
                   id="autoreload"
                   name="autoreload"
                   v-model="autoreload" /> {{ $t('header.livereload') }}
          </label>
        </template>
        <template v-slot:subheader>
          <p class="my-1">{{ $t('header.description') }}. {{ $t('timerange', {from: fromDate}) }}</p>
        </template>
      </SectionHeader>
      <div class="d-flex my-2 mx-1">
        <select
          v-model="selected"
          @change="updateFilters()"
          class="select__info form-control w-20 ml-1">
          <option v-for="(period, index) in periods"
                  :key="index"
                  :value="period.value">{{ $t(period.label) }}</option>
        </select>
      </div>

      <div class="px-2">
        <monitoring-summary :states="states.all" />
        <monitoring-rows-container :states="states.rows" />
        <monitoring-kpi />
        <monitoring-modals />
      </div>

      <div v-if="accountDetail?.account" class="monitoring--tickets">
        <TicketColumn :title="accountDetail.account.title"
                      :tickets="accountDetail.results"
                      @onDestroy="setTicketsDetail(null)"
                      @onBottomScroll="columnPagination">
          <div class="p-1 d-flex align-center space-between">
            <span class="d-block w-50">
              <Loader v-if="loading" />
              {{
                $tc('columns.viewingTickets', accountDetail.elements, {
                  count: accountDetail.results.length,
                  total: accountDetail.elements
                })
              }}
            </span>
            <button type="button"
                    class="btn btn-link"
                    @click="setModal('createColumn')">
              <strong class="text-info">{{$t('modals.new.title')}}</strong>
            </button>
            <hr class="m-0" />
          </div>
        </TicketColumn>
      </div>
    </div>
  `
});

const MonitoringTicketMetric = Vue.component('monitoring-ticket-metric', {
  props: {
    title: {type: String, required: true},
    state: {type: String, required: true},
    channel: {type: String, default: ''},
    account: {type: String, default: ''},
    active: {type: Boolean, default: false},
    forceOpenOnly: {type: Boolean, required: false}
  },
  data() {
    return {metric: null, unit: '', ticketId: null};
  },
  methods: {
    ...Vuex.mapActions(['getMetric']),
    async load() {
      let {state} = this;
      const {channel, account} = this;

      if (this.forceOpenOnly) {
        state = `${state}_and_open`;
      }

      const response = await this.getMetric({state, channel, account});

      switch (state) {
        case 'oldest_open':
          this.metric = this.parseOldestTicket(response);
          break;
        case 'avg_time_opened':
          this.metric = this.parseAverageTime(response);
          break;
        default:
          this.metric = response;
      }
    },
    parseOldestTicket({id, date}) {
      this.ticketId = id;
      return moment.unix(date).format('DD/MM/YYYY');
    },
    parseAverageTime({value = 0, type = ''}) {
      return `${value.toFixed(2)}${type}`;
    },
    goToTicket() {
      if (this.ticketId) {
        window.open(`#/case/${this.ticketId}`);
      }
    }
  },
  computed: {
    ...Vuex.mapGetters(['lastUpdateTime']),
    isActive() {
      return this.active ? 'ticket-amount__active' : '';
    },
    hasDarkerColor() {
      if (!this.channel && !this.account) {
        return 'ticket-amount__dark';
      }

      return '';
    }
  },
  mounted() {
    this.load();
  },
  watch: {
    lastUpdateTime() {
      this.load();
    }
  },
  template: `
    <div @click="goToTicket()" :class="{'clickable': ticketId}">
      <h5 class="mt-0 text-overflow text-uppercase">
        {{ title }}
        <span v-if="ticketId" class="text-info fa fa-external-link pull-right"></span>
      </h5>
      <span v-if="metric === null" class="ticket-amount">—</span>
      <p v-else class="ticket-amount" :class="[hasDarkerColor, isActive]">
        {{ metric }} <span v-if="unit">{{ unit }}</span>
      </p>
    </div>
  `
});

const MonitoringViewColumn = Vue.component('monitoring-view-column', {
  data() {
    return {loading: false};
  },
  methods: {
    ...Vuex.mapActions(['getTicketsDetail', 'setTicketsDetail']),
    getTickets() {
      this.loading = true;
      // Reset state when viewing a new account
      this.setTicketsDetail({}).then(() => {
        this.getTicketsDetail(this.$attrs).finally(() => {
          this.loading = false;
        });
      });
    }
  },
  template: `
    <button type="button"
            class="btn btn-link"
            @click="getTickets()">
      <Loader v-if="loading" />
      <span v-else class="fa fa-arrow-right text-info" />
      {{ $t('CTA.viewTickets') }}
    </button>
  `
});
