'use strict';

angular.module('postCenterWebClientApp')
  .directive('messageVcard', function () {
    return {
      scope: {
        data: '@'
      },
      templateUrl: 'blocks/message/views/message_vcard.html',
      restrict: 'E',
      controller: ['$scope',
      function ($scope) {
        var vcard = vCard.parse($scope.data);
        var labels = {};
        if (vcard['X-ABLabel'] !== undefined) {
          angular.forEach(vcard['X-ABLabel'], function(label) {
            labels[label.namespace] = label.value;
          });
        }
        $scope.fn = "";
        if (vcard.fn) {
          $scope.fn = vcard.fn[0].value;
        }
        $scope.image = "https://assets.postcenter.io/img/generic_avatar.png";
        if (vcard.photo !== undefined) {
          $scope.image = "data:image/jpeg;base64,"+vcard.photo[0].value;
        }
        $scope.phones = [];
        if (vcard.tel !== undefined) {
          angular.forEach(vcard.tel, function(tel) {
            $scope.phones.push({
              label: labels[tel.namespace],
              value: tel.value
            });
          });
        }
        $scope.emails = [];
        if (vcard.email !== undefined) {
          angular.forEach(vcard.email, function(email) {
            $scope.emails.push({
              label: labels[email.namespace],
              value: email.value
            });
          });
        }
      }]
    };
  });
