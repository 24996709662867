'use strict';

var app = angular.module('postCenterWebClientApp');
app.controller('DepartmentAddCtrl', function(
  $scope,
  $rootScope,
  DepartmentService,
  AdNotification,
  $filter
) {
  $scope.action = 'Add';
  $scope.department = {};

  $scope.confirmAddDepartment = function() {
    var action = 'add_department';
    DepartmentService.addDepartment($scope.department).then(
      function() {
        $rootScope.$emit('admin:createDepartment');
        AdNotification.success(201, action);
        $rootScope.goToParentState();
      },
      function(data) {
        var msg = $filter('translate')(data.response);
        AdNotification.notify(msg, 'error');
      }
    );
  };
});
