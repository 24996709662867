'use strict';

var app = angular.module('postCenterWebClientApp');
app.directive('message', function () {
  return {
    scope: {
      message: '=',
      emitReplyTo: '&',
      fromConversation: '&',
    },
    templateUrl: 'blocks/message/views/message.html',
    restrict: 'E',
    controller: 'MessageCtrl'
  };
});
