(module => {
  'use strict';
  AdminIntegrationConfCtrl.$inject = [
    '$scope',
    '$rootScope',
    'AdNotification',
    'IntegrationService',
    'InitialData',
    'FireTagService',
    'EstablishmentService',
    'ThirdPartyService'
  ];
  function AdminIntegrationConfCtrl(
    $scope,
    $rootScope,
    AdNotification,
    IntegrationService,
    InitialData,
    FireTagService,
    EstablishmentService: EstablishmentService,
    ThirdPartyService
  ) {
    FireTagService.setPageView({
      title: 'Integraciones',
      path: `/${window.location.hash}`
    });
    $rootScope.adminTab.selected = 'integrations';
    const crmNames = ['SALESFORCE', 'HUBSPOT', 'SUGARCRM'];

    function init() {
      EstablishmentService.refreshEstablishment().then(establishment => {

        const configs = {};
        establishment.integrations.integration_configs.forEach(config => {
          configs[config.name] = config.config;
        });

        const excludeCrm = ['SUGARCRM'];
        const integrations = establishment.integrations.integration_descriptions
          .filter(({name}) => !excludeCrm.includes(name))
          .map(description => {
            return new Integration(description, configs[description.name]);
          });
        $scope.enabledIntegrations = integrations.filter(integration => {
          return integration.enabled;
        });
        const hasLimits = establishment.plan.limits.find(
          ({limit_max}) => limit_max !== -1
        );
        $scope.apiLimits = {};
        $scope.apiLimits.showLimits = hasLimits;
        hasLimits && getLimits();

        $scope.disabledIntegrations = integrations.filter(integration => {
          return (
            !integration.enabled &&
            integration.name !== 'WEBHOOKS' &&
            integration.name !== 'TIMEPOINTS'
          );
        });
      })
    }

    $scope.crmDisabled = ({name: crmName}) => {
      const crmActive = $scope.enabledIntegrations.find(
        ({enabled, name}) => enabled && crmNames.includes(name)
      );
      if (!crmActive || $scope.disabledIntegrations.length === 0) {
        return;
      }
      const crmDisabled = $scope.disabledIntegrations
        .filter(({name, enabled}) => crmNames.includes(name) && !enabled)
        .map(({name}) => name);
      return crmDisabled.includes(crmName);
    };

    init()
    function getLimits() {
      const limitId = 'API'
      return EstablishmentService.getLimits(limitId).then(({count}) => {
        return EstablishmentService.getEstablishment().then(establishment => {
          const {
            plan: {limits}
          } = establishment
  
          const limitsData = limits.find(({limit_id}) => limit_id === limitId)
  
          const {limit_max: limit} = limitsData
  
          $scope.apiLimits = {
            count,
            limit
          }
  
          $scope.apiLimits.pending = limit - count
          $scope.apiLimits.progressValue = (count / limit) * 100
          $scope.apiLimits.showLimits = limit !== -1
          $scope.apiLimits.limitReached = limit === count
        })
      })
    }

    $scope.showBilling = function () {
      return ThirdPartyService.showBilling()
    }

    $scope.toggleConfiguration = function (integration) {
      integration.config.showing = !integration.config.showing
    }

    $scope.disableIntegration = function (integration) {
      const event = 'disable_integration'
      integration.loading = true
      IntegrationService.delete(integration.name).then(
        () => {
          integration.loading = false
          refreshConfigs()
          AdNotification.success(200, event)
        },
        error => {
          integration.loading = false
          AdNotification.error(error, event)
        }
      )
    }

    $scope.integrationName = function (integration): string {
      return integration.name;
    }

    $scope.enableIntegration = function (integration) {
      const event = 'enable_integration';
      integration.loading = true;
      IntegrationService.post(integration.name).then(
        () => {
          integration.loading = false;
          refreshConfigs();
          AdNotification.success(200, event);
        },
        error => {
          integration.loading = false;
          AdNotification.error(error, event);
        }
      );
    };

    function refreshConfigs() {
      init();
    }
  }
  module.controller('AdminIntegrationConfCtrl', AdminIntegrationConfCtrl);
})(angular.module('postCenterWebClientApp'));

const Integration = function (description, config) {
  this.name = description.name;
  this.imageUrl = description.image_url;
  this.enabled = angular.isDefined(config);
  this.config = config;
  if (this.config) {
    this.config.showing = false;
  }
};
