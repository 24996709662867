'use strict';

angular
  .module('postCenterWebClientApp')
  .factory('AnalyticsDashboard', analyticsDashboard);

analyticsDashboard.$inject = ['AdRequest'];

function analyticsDashboard(AdRequest) {
  function getExecutiveDetailTable(
    name,
    now,
    until,
    account,
    in_business_time,
    filter_by_created,
    specific_kpi
  ) {
    var params = {
      name,
      now,
      until,
      account,
      in_business_time,
      specific_kpi,
      filter_by_created
    };
    return AdRequest.post('/api/v1/stat/dashboard/executives/', params);
  }

  function getExecutiveKPI(
    name,
    now,
    until,
    account,
    in_business_time,
    filter_by_created
  ) {
    var params = {
      name,
      now,
      until,
      account,
      in_business_time,
      filter_by_created
    };
    return AdRequest.post('/api/v1/stat/dashboard/executives/', params);
  }

  function getTicketKPI(
    name,
    now,
    until,
    account,
    in_business_time,
    filter_by_created
  ) {
    var params = {
      name,
      now,
      until,
      account,
      in_business_time,
      filter_by_created
    };
    return AdRequest.post('/api/v1/stat/dashboard/tickets/', params);
  }

  function getMessageKPI(name, now, until, account, in_business_time) {
    var params = {
      name,
      now,
      until,
      account,
      in_business_time
    };
    return AdRequest.post('/api/v1/stat/dashboard/messages/', params);
  }

  function getUserKPI(name, now, until, account, in_business_time) {
    var params = {
      name,
      now,
      until,
      account,
      in_business_time
    };
    return AdRequest.post('/api/v1/stat/dashboard/users/', params);
  }

  function normalizeSentiments(sentiments) {
    var normalizedSentiments = [
      [0, 0, 0],
      [0, 0, 0],
      [0, 0, 0]
    ];
    if (Array.isArray(sentiments)) {
      for (var i = 0; i < 3; i++) {
        if (Array.isArray(sentiments[i])) {
          for (var j = 0; j < 3; j++) {
            normalizedSentiments[i][j] = sentiments[i][j];
          }
        } else {
          shouldWarn = true;
        }
      }
    }
    return normalizedSentiments;
  }

  function getTicketKPIWrapper(
    name,
    now,
    until,
    account,
    inBusinessTime,
    filter_by_created
  ) {
    var value = getTicketKPI(
      name,
      now,
      until,
      account,
      inBusinessTime,
      filter_by_created
    );
    if (name === 'sentiments') {
      value = value.then(sentiments => {
        return normalizeSentiments(sentiments);
      });
    }
    return value;
  }

  return {
    messageKPI: getMessageKPI,
    executiveKPI: getExecutiveKPI,
    executiveDetailTable: getExecutiveDetailTable,
    ticketKPI: getTicketKPIWrapper,
    userKPI: getUserKPI
  };
}
