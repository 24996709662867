'use strict';

(function () {
  angular
    .module('postCenterWebClientApp')
    .component('casePriority', {
      bindings: {
        priority: '<',
      },
      controller: casePriorityCtrl,
      templateUrl: 'blocks/case/views/case_priority.html'
    })

  function casePriorityCtrl($scope) {
    const ctrl = this;

    ctrl.$onChanges = function(changes) {
      const priority = changes.priority.currentValue;
      ctrl.classes = getPriorityClasses(priority);
      ctrl.name = getPriorityName(priority);
      ctrl.icon = getSVGPath(priority);
    }
  }

  const PRIORITIES = {
    MAX: 2,
    HIGH: 1,
    MEDIUM: 0,
    LOW: -1,
    MIN: -2
  };

  function isMaxPriority(priority){
    return priority >= PRIORITIES.MAX
  }

  function isHighPriority(priority){
    return priority === PRIORITIES.HIGH
  }

  function isMediumPriority(priority){
    return priority == undefined || priority === null || priority === PRIORITIES.MEDIUM
  }

  function isLowPriority(priority){
    return priority === PRIORITIES.LOW
  }

  function isMinPriority(priority){
    return priority <= PRIORITIES.MIN
  }

  function getPriorityClasses(priority) {
    const classes = ['_priority']
    if (isMediumPriority(priority)){
        return ["_priority", "_warning", "_secondary"]
    }
    classes.push(priority > PRIORITIES.MEDIUM ? '_error' : '_info')
    if (isHighPriority(priority) || isLowPriority(priority)) {
        classes.push('_secondary')
    }
    return classes
  }

  function getPriorityName(priority) {
    if (isMaxPriority(priority)) return "CASE_PRIORITY_MAX"
    if (isMinPriority(priority)) return "CASE_PRIORITY_MIN"
    
    if (isHighPriority(priority)) return "CASE_PRIORITY_HIGH"
    if (isLowPriority(priority)) return "CASE_PRIORITY_LOW"
    return "CASE_PRIORITY_MEDIUM"
  }

  function getSVGPath(priority) {
    if (isMaxPriority(priority)) return "M3.5 5.5L6 3L8.5 5.5M3.5 8.5L6 6L8.5 8.5"
    if (isMinPriority(priority)) return "M3.5 3.5L6 6L8.5 3.5M3.5 6.5L6 9L8.5 6.5"
    
    if (isHighPriority(priority)) return "M3 7.5L6 4.5L9 7.5"
    if (isLowPriority(priority)) return "M3 5L6 8L9 5"
    return "M0.617676 1H7.61768"
  }
})();
