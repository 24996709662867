interface Styles {
    logo?: string;
    logoLight?: string;
    logoDark?: string;
    icon?: string;
    iconLight?: string;
    iconDark?: string;
    name?: string;
    favIcon?: string;
    campaignsDisabled?: string;
}

class StylesService {
    AdRequest: AdRequestService;
    defaultStyles = {
        logoLight: 'images/logoaderesocolor.png',
        logoDark: 'images/logoaderesocolordark.png',
        iconLight: 'images/desk_icon_blue.svg',
        iconDark: 'images/desk_icon_white.svg',
        favIcon: 'favicon-32x32.png',
        name: 'Adereso desk'
    }
    private stylesModel: AdModelFetcher<Styles>

    constructor(AdRequest) {
        this.AdRequest = AdRequest;
        this.stylesModel = new AdModelFetcher(() =>  {
            const hostname = window.location.hostname;
            return this.AdRequest.post<Styles>(
                '/api/v1/partner/style',
                {'domain': hostname},
                {Authorization: ''},
                0,
                true
            ).then(style => {
                let styles = this.processStyles(style)
                this.setFavicon(styles)
                this.setTitle(styles)
                return styles
            })
        })
    }

    public getStyles(): Promise<Styles> {
        return this.stylesModel.get()
    }

    private processStyles(styles: Styles): Styles {
        styles.logoLight = styles.logoLight ?? this.defaultStyles.logoLight;
        styles.logoDark = styles.logoDark ?? this.defaultStyles.logoDark;
        styles.iconLight = styles.icon ?? this.defaultStyles.iconLight;
        styles.iconDark = styles.icon ?? this.defaultStyles.iconDark;
        styles.name = styles.name ?? this.defaultStyles.name;
        styles.favIcon = styles.favIcon ?? this.defaultStyles.favIcon;
        return styles;
    }

    private setFavicon(styles: Styles) {
        const favIcon = styles.favIcon;
        let links: NodeListOf<HTMLLinkElement> = document.querySelectorAll('link[rel="icon"]');
        links.forEach(link => {
            link.href = favIcon;
        });
    }

    private setTitle(styles: Styles) {
        const title = styles.name;
        document.title = title;
    }
}
angular.module('postCenterWebClientApp').service('StylesService', StylesService);