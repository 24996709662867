const RadioButton = Vue.component('RadioButton', {
  inheritAttrs: false,
  model: {
    prop: 'name',
    event: 'change'
  },
  props: {
    checked: Boolean,
    label: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    }
  },
  template: `<input
      type="radio"
      :value="value"
      :name="name"
      @change="$emit('change', $event.target.value)"
      class="ads-radio"
    />`
});
