/**
 * admin states config
 */
app.config([
  '$stateProvider',
  function ($stateProvider) {
    // AngularTranslate resolver => avoid Flash of Untranslated Content
    const resolve = {
      translateReady: ['$translate', $translate => $translate.onReady()]
    };
    var states = {};

    /********************************
      ADMIN
    ********************************/

    /*
     * /admin/
     */
    states.admin = {
      name: 'admin',
      abstract: true,
      url: '/admin/',
      templateUrl: 'blocks/admin/views/admin.html',
      controller: 'AdminCtrl',
      controllerAs: 'vm',
      resolve: {
        translateReady: ['$translate', $translate => $translate.onReady()]
      }
    };

    $stateProvider.state(states.admin);

    /********************************
      DEPARTMENTS
    ********************************/

    /*
     * admin/departments
     */
    states.departments = {
      name: 'admin.departments',
      url: 'departments/',
      views: {
        content: {
          templateUrl: 'blocks/admin/views/departments.html',
          controller: 'DepartmentCtrl',
          controllerAs: 'vm'
        }
      },
      resolve: {
        userAllowed: function (
          $q,
          UserService: UserService,
          AppStateService: AppStateService
        ) {
          return UserService.getProfile().then(user => {
            if (user.isAdmin || user.isSupervisor) {
              return $q.resolve();
            } else {
              AppStateService.goToInbox();
              return $q.reject();
            }
          });
        }
      }
    };

    /*
     * admin/departments/add
     */
    states.departmentsAdd = {
      name: 'admin.departments.add',
      url: 'add/',
      views: {
        'sidebar@admin': {
          templateUrl: 'blocks/admin/views/department_add.html',
          controller: 'DepartmentAddCtrl'
        }
      },
      data: {
        sidebarActive: true
      }
    };

    $stateProvider.state(states.departments);
    $stateProvider.state(states.departmentsAdd);

    /********************************
      USERS
    ********************************/

    /*
     * admin/users
     */
    states.users = {
      name: 'admin.users',
      url: 'users/',
      views: {
        content: {
          templateUrl: 'blocks/admin/views/users.html',
          controller: 'UsersCtrl'
        }
      },
      resolve: {
        userAllowed: function (
          $q,
          UserService: UserService,
          AppStateService: AppStateService
        ) {
          return UserService.getProfile().then(user => {
            if (user.isAdmin || user.isSupervisor) {
              return $q.resolve();
            } else {
              AppStateService.goToInbox();
              return $q.reject();
            }
          });
        }
      }
    };

    /*
     * admin/users/add
     */
    states.usersAdd = {
      name: 'admin.users.add',
      url: 'add/',
      views: {
        'sidebar@admin': {
          templateUrl: 'blocks/admin/views/user_edit.html',
          controller: 'UserEditCtrl'
        }
      }
    };

    /*
     * admin/users/edit
     */
    states.usersEdit = {
      name: 'admin.users.edit',
      url: 'edit/:id/',
      views: {
        'sidebar@admin': {
          templateUrl: 'blocks/admin/views/user_edit.html',
          controller: 'UserEditCtrl'
        }
      }
    };

    $stateProvider.state(states.users);
    $stateProvider.state(states.usersAdd);
    $stateProvider.state(states.usersEdit);

    /********************************
      CHANNELS
    ********************************/

    /*
     * admin/channels
     */
    states.channels = {
      name: 'admin.channels',
      url: 'channels/',
      views: {
        content: {
          templateUrl:
            'blocks/admin/views/social_accounts/social_accounts.html',
          controller: 'SocialAccountsCtrl'
        }
      },
      resolve: {
        userAllowed: function (
          $q,
          UserService: UserService,
          AppStateService: AppStateService
        ) {
          return UserService.getProfile().then(user => {
            if (user.isAdmin) {
              return $q.resolve();
            } else {
              AppStateService.goToInbox();
              return $q.reject();
            }
          });
        }
      }
    };

    states.channelsTwitter = {
      name: 'admin.channels.twitter',
      url: 'twitter/'
    };
    states.channelsFacebook = {
      name: 'admin.channels.facebook',
      url: 'facebook/'
    };
    states.channelsInstagram = {
      name: 'admin.channels.instagram',
      url: 'instagram/'
    };
    states.channelsEmail = {
      name: 'admin.channels.email',
      url: 'email/'
    };
    states.channelsChat = {
      name: 'admin.channels.chat',
      url: 'chat/'
    };
    states.channelsAppStore = {
      name: 'admin.channels.app_store',
      url: 'app_store/'
    };
    states.channelsPlayStore = {
      name: 'admin.channels.play_store',
      url: 'play_store/'
    };
    states.channelsTwitterSearch = {
      name: 'admin.channels.twitter_search',
      url: 'twitter_search/'
    };
    states.channelsWhatsApp = {
      name: 'admin.channels.whatsapp',
      url: 'whatsapp/'
    };
    states.channelsLinkedIn = {
      name: 'admin.channels.linkedin',
      url: 'linkedin/'
    };
    states.channelsExternal = {
      name: 'admin.channels.external',
      url: 'external/'
    };

    $stateProvider.state(states.channels);
    $stateProvider.state(states.channelsTwitter);
    $stateProvider.state(states.channelsFacebook);
    $stateProvider.state(states.channelsInstagram);
    $stateProvider.state(states.channelsEmail);
    $stateProvider.state(states.channelsChat);
    $stateProvider.state(states.channelsAppStore);
    $stateProvider.state(states.channelsPlayStore);
    $stateProvider.state(states.channelsTwitterSearch);

    $stateProvider.state(states.channelsWhatsApp);
    $stateProvider.state(states.channelsExternal);

    /********************************
      ASSIGNMENTS
    ********************************/

    /*
     * admin/assignments
     */
    states.assignments = {
      name: 'admin.assignments',
      url: 'assignments/',
      views: {
        content: {
          templateUrl: 'blocks/admin/views/assignments.html',
          controller: 'AssignmentsCtrl'
        }
      },
      resolve: {
        userAllowed: function (
          $q,
          UserService: UserService,
          AppStateService: AppStateService
        ) {
          return UserService.getProfile().then(user => {
            if (user.isAdmin || user.isSupervisor) {
              return $q.resolve();
            } else {
              AppStateService.goToInbox();
              return $q.reject();
            }
          });
        }
      }
    };

    $stateProvider.state(states.assignments);

    states.customOnlineStatusAdd = {
      name: 'admin.assignments.add-cos',
      url: 'add-cos/',
      views: {
        'sidebar@admin': {
          templateUrl:
            'blocks/admin/views/assignment/custom_online_status_add.html',
          controller: 'CustomOnlinestatusAddCtrl'
        }
      },
      data: {
        sidebarActive: true
      }
    };

    $stateProvider.state(states.customOnlineStatusAdd);
    /********************************
      METADATA
    ********************************/

    /*
     * admin/metadata
     */
    states.metadata = {
      name: 'admin.metadata',
      url: 'metadata/',
      views: {
        content: {
          templateUrl: 'blocks/admin/views/metadata.html',
          controller: 'MetadataCtrl'
        }
      },
      resolve: {
        userAllowed: function (
          $q,
          UserService: UserService,
          AppStateService: AppStateService
        ) {
          return UserService.getProfile().then(user => {
            if (user.isAdmin || user.isSupervisor) {
              return $q.resolve();
            } else {
              AppStateService.goToInbox();
              return $q.reject();
            }
          });
        }
      }
    };

    $stateProvider.state(states.metadata);

    states.messageTemplates = {
      name: 'admin.message-templates',
      url: 'message-templates/',
      views: {
        content: {
          templateUrl: 'blocks/admin/message-templates/message-templates.html',
          controller: 'messageTemplatesCtrl',
          controllerAs: 'vm'
        }
      },
      resolve: {
        userAllowed: function (
          $q,
          UserService: UserService,
          AppStateService: AppStateService
        ) {
          return UserService.getProfile().then(user => {
            if (user.isAdmin || user.isSupervisor) {
              return $q.resolve();
            } else {
              AppStateService.goToInbox();
              return $q.reject();
            }
          });
        }
      }
    };
    $stateProvider.state(states.messageTemplates);

    /********************************
      KEYWORDS
    ********************************/
    states.keywords = {
      name: 'admin.keywords',
      url: 'keywords/',
      views: {
        content: {
          templateUrl: 'blocks/admin/views/keywords.html',
          controller: 'KeywordsCtrl'
        }
      },
      resolve: {
        userAllowed: function (
          $q,
          UserService: UserService,
          AppStateService: AppStateService
        ) {
          return UserService.getProfile().then(user => {
            if (user.isAdmin) {
              return $q.resolve();
            } else {
              AppStateService.goToInbox();
              return $q.reject();
            }
          });
        }
      }
    };

    $stateProvider.state(states.keywords);

    /********************************
      CAMPAIGNS
    ********************************/
      states.campaigns = {
        name: 'admin.campaigns',
        url: 'campaigns/',
        views: {
          content: {
            templateUrl: 'blocks/admin/views/campaigns.html',
            controller: 'CampaignsCtrl'
          }
        },
        resolve: {
          userAllowed: function (
            $q,
            UserService: UserService,
            AppStateService: AppStateService
          ) {
            return UserService.getProfile().then(user => {
              if (user.isAdmin) {
                return $q.resolve();
              } else {
                AppStateService.goToInbox();
                return $q.reject();
              }
            });
          }
        }
      };
  
      $stateProvider.state(states.campaigns);

    /********************************
      EXPORT
    ********************************/
    states.export = {
      name: 'admin.export',
      url: 'export/',
      views: {
        content: {
          templateUrl: 'blocks/admin/views/export.html',
          controller: 'ExportCtrl'
        }
      },
      resolve: {
        userAllowed: function (
          $q,
          UserService: UserService,
          AppStateService: AppStateService
        ) {
          return UserService.getProfile().then(user => {
            if (user.isAdmin || user.isSupervisor || user.isAnalyst) {
              return $q.resolve();
            } else {
              AppStateService.goToInbox();
              return $q.reject();
            }
          });
        }
      }
    };

    $stateProvider.state(states.export);

    /********************************
      CAMPAIGNS ANALYTICS
    ********************************/
      states.campaigns_analytics = {
        name: 'admin.campaigns_analytics',
        url: 'campaigns/analytics/',
        views: {
          content: {
            templateUrl: 'blocks/admin/views/campaigns_analytics.html',
            controller: 'CampaignsAnalytcsCtrl'
          }
        },
        resolve: {
          userAllowed: function (
            $q,
            UserService: UserService,
            AppStateService: AppStateService,
            EstablishmentService: EstablishmentService
          ) {
            return UserService.getProfile().then(user => {
              return EstablishmentService.getEstablishment().then(establishment => {
                if (user.isAnalystOrAdmin && establishment.features.campaigns_module) {
                  return $q.resolve();
                } else {
                  AppStateService.goToInbox();
                  return $q.reject();
                }
              });
            });
          }
        }
      };
  
      $stateProvider.state(states.campaigns_analytics);

    /********************************
      CREATE CAMPAIGN
    ********************************/
      states.create_campaign = {
        name: 'admin.create_campaign',
        url: 'campaigns/create_campaign/',
        views: {
          content: {
            templateUrl: 'blocks/admin/views/create_campaign.html',
            controller: 'CreateCampaignCtrl'
          }
        },
        resolve: {
          userAllowed: function (
            $q,
            UserService: UserService,
            AppStateService: AppStateService,
            EstablishmentService: EstablishmentService
          ) {
            return UserService.getProfile().then(user => {
              return EstablishmentService.getEstablishment().then(establishment => {
                if (user.isAdmin && establishment.features.campaigns_module) {
                  return $q.resolve();
                } else {
                  AppStateService.goToInbox();
                  return $q.reject();
                }
              });
            });
          }
        }
      };
  
      $stateProvider.state(states.create_campaign);

    /********************************
      SCHEDULE SETTINGS
    ********************************/
      states.schedule_settings = {
        name: 'admin.schedule_settings',
        url: 'schedule_settings/',
        views: {
          content: {
            templateUrl: 'blocks/admin/views/schedule_settings.html',
            controller: 'ScheduleSettingsCtrl'
          }
        },
        resolve: {
          userAllowed: function (
            $q,
            UserService: UserService,
            AppStateService: AppStateService
          ) {
            return UserService.getProfile().then(user => {
              if (user.isAdmin) {
                return $q.resolve();
              } else {
                AppStateService.goToInbox();
                return $q.reject();
              }
            });
          }
        }
      };
   
      $stateProvider.state(states.schedule_settings);

    /********************************
     NOTIFICATION AGENDA
    ********************************/
    states.notificationAgenda = {
      name: 'admin.notification-agenda',
      abstract: true,
      url: 'notification-agenda/',
      views: {
        content: {
          templateUrl: 'blocks/admin/views/notification_agenda/agenda.html',
          controller: 'NotificationAgendaCtrl'
        }
      },
      resolve: {
        userAllowed: function (
          $q,
          UserService: UserService,
          AppStateService: AppStateService
        ) {
          return UserService.getProfile().then(user => {
            if (user.isAdmin) {
              return $q.resolve();
            } else {
              AppStateService.goToInbox();
              return $q.reject();
            }
          });
        }
      }
    };

    states.notificationAgendaUsers = {
      name: 'admin.notification-agenda.users',
      url: 'user/',
      templateUrl: 'blocks/admin/views/notification_agenda/users_tab.html'
    };

    states.notificationAgendaGroups = {
      name: 'admin.notification-agenda.groups',
      url: 'groups/',
      templateUrl: 'blocks/admin/views/notification_agenda/groups_tab.html'
    };

    /*
     * Notification Agenda Users Add
     */
    states.notificationAgendaUsersAdd = {
      name: 'admin.notification-agenda.useradd',
      url: 'add/',
      views: {
        'sidebar@admin': {
          templateUrl:
            'blocks/admin/views/notification_agenda/handle_user.html',
          controller: 'NotificationAgendaUserHandleCtrl'
        }
      },
      data: {
        sidebarActive: true
      }
    };

    /*
     * Notification Agenda Users Edit
     */
    states.notificationAgendaUsersEdit = {
      name: 'admin.notification-agenda.useredit',
      url: 'edit/:resource_id/',
      views: {
        'sidebar@admin': {
          templateUrl:
            'blocks/admin/views/notification_agenda/handle_user.html',
          controller: 'NotificationAgendaUserHandleCtrl'
        }
      },
      data: {
        sidebarActive: true
      }
    };

    /*
     * Notification Agenda Group Add
     */
    states.notificationAgendaGroupsAdd = {
      name: 'admin.notification-agenda.groupadd',
      url: 'add/',
      views: {
        'sidebar@admin': {
          templateUrl:
            'blocks/admin/views/notification_agenda/handle_group.html',
          controller: 'NotificationAgendaGroupHandleCtrl'
        }
      },
      data: {
        sidebarActive: true
      }
    };

    /*
     * Notification Agenda Group Edit
     */
    states.notificationAgendaGroupsEdit = {
      name: 'admin.notification-agenda.groupedit',
      url: 'edit/:resource_id/',
      views: {
        'sidebar@admin': {
          templateUrl:
            'blocks/admin/views/notification_agenda/handle_group.html',
          controller: 'NotificationAgendaGroupHandleCtrl'
        }
      },
      data: {
        sidebarActive: true
      }
    };

    $stateProvider.state(states.notificationAgenda);

    $stateProvider.state(states.notificationAgendaUsers);
    $stateProvider.state(states.notificationAgendaUsersAdd);
    $stateProvider.state(states.notificationAgendaUsersEdit);

    $stateProvider.state(states.notificationAgendaGroups);
    $stateProvider.state(states.notificationAgendaGroupsAdd);
    $stateProvider.state(states.notificationAgendaGroupsEdit);

    /********************************
     BAN LIST
    ********************************/
    states.bannedKeywords = {
      name: 'admin.banned-keywords',
      url: 'banned-keywords/',
      views: {
        content: {
          templateUrl: 'blocks/admin/views/banned_keywords.html',
          controller: 'BannedKeywordsCtrl'
        }
      },
      resolve: {
        userAllowed: function (
          $q,
          UserService: UserService,
          AppStateService: AppStateService
        ) {
          return UserService.getProfile().then(user => {
            if (user.isAdmin) {
              return $q.resolve();
            } else {
              AppStateService.goToInbox();
              return $q.reject();
            }
          });
        }
      }
    };

    states.bannedKeywordsAdd = {
      name: 'admin.banned-keywords.add',
      url: 'add/',
      views: {
        'sidebar@admin': {
          templateUrl: 'blocks/admin/views/banned_keywords_edit.html',
          controller: 'BannedKeywordsEditCtrl'
        }
      },
      data: {
        sidebarActive: true
      }
    };

    states.bannedKeywordsEdit = {
      name: 'admin.banned-keywords.edit',
      url: 'edit/:id/',
      views: states.bannedKeywordsAdd.views,
      data: {
        sidebarActive: true
      }
    };

    $stateProvider.state(states.bannedKeywords);
    $stateProvider.state(states.bannedKeywordsAdd);
    $stateProvider.state(states.bannedKeywordsEdit);

    /********************************
     MASSIVE ACTIONS
    ********************************/
    states.massiveActions = {
      name: 'admin.massive-actions',
      url: 'massive-actions/',
      views: {
        content: {
          templateUrl:
            'blocks/admin/views/massive_actions/massive_actions.html',
          controller: 'MassiveActionsCtrl',
          controllerAs: 'vm'
        }
      },
      resolve: {
        userAllowed: function (
          $q,
          UserService: UserService,
          AppStateService: AppStateService
        ) {
          return UserService.getProfile().then(user => {
            if (user.isAdmin) {
              return $q.resolve();
            } else {
              AppStateService.goToInbox();
              return $q.reject();
            }
          });
        }
      }
    };

    $stateProvider.state(states.massiveActions);

    /********************************
     INTEGRATIONS
    ********************************/
    states.integrations = {
      name: 'admin.integrations',
      url: 'integrations/',
      views: {
        content: {
          templateUrl: 'blocks/admin/views/integrations.html',
          controller: 'AdminIntegrationConfCtrl'
        }
      },
      resolve: {
        userAllowed: function (
          $q,
          UserService: UserService,
          AppStateService: AppStateService
        ) {
          return UserService.getProfile().then(user => {
            if (user.isAdmin) {
              return $q.resolve();
            } else {
              AppStateService.goToInbox();
              return $q.reject();
            }
          });
        }
      }
    };

    $stateProvider.state(states.integrations);

    /********************************
     ADERESO AI
    ********************************/
    states.aderesoAI = {
      name: 'admin.adereso-AI',
      url: 'adereso-ai/',
      views: {
        content: {
          templateUrl: 'blocks/admin/views/adereso_ai.html',
          controller: 'AderesoAICtrl'
        }
      },
      resolve: {
        userAllowed: function (
          $q,
          UserService: UserService,
          AppStateService: AppStateService
        ) {
          return UserService.getProfile().then(user => {
            if (user.isAdmin) {
              return $q.resolve();
            } else {
              AppStateService.goToInbox();
              return $q.reject();
            }
          });
        }
      }
    };
    $stateProvider.state(states.aderesoAI);
    
    /********************************
     API USERS
    ********************************/
    states.apiUsers = {
      name: 'admin.api-users',
      url: 'api-users/',
      views: {
        content: {
          templateUrl: 'blocks/admin/views/api_users.html',
          controller: 'AdminApiUsersCtrl'
        }
      },
      resolve: {
        userAllowed: function (
          $q,
          UserService: UserService,
          AppStateService: AppStateService
        ) {
          return UserService.getProfile().then(user => {
            if (user.isAdmin) {
              return $q.resolve();
            } else {
              AppStateService.goToInbox();
              return $q.reject();
            }
          });
        }
      }
    };

    $stateProvider.state(states.apiUsers);

    /********************************
     ASSIGNMENT DEPARTMENTS
    ********************************/
    states.departmentConfigs = {
      name: 'admin.department-assignment-config',
      url: 'assignments/departments/:departmentId/',
      views: {
        content: {
          templateUrl: 'blocks/admin/views/department_config.html',
          controller: 'DepartmentConfigsCtrl'
        }
      },
      resolve: {
        userAllowed: function (
          $q,
          UserService: UserService,
          AppStateService: AppStateService
        ) {
          return UserService.getProfile().then(user => {
            if (user.isAdmin || user.isSupervisor) {
              return $q.resolve();
            } else {
              AppStateService.goToInbox();
              return $q.reject();
            }
          });
        }
      }
    };

    $stateProvider.state(states.departmentConfigs);

    /********************************
     SKILL BOT
    ********************************/
    states.skillBot = {
      name: 'admin.skill_bot',
      url: 'skill_bot/',
      views: {
        content: {
          templateUrl: 'blocks/admin/views/skill_bot.html',
          controller: 'SkillBotCtrl'
        }
      },
      resolve: {
        userAllowed: function (
          $q,
          UserService: UserService,
          AppStateService: AppStateService
        ) {
          return UserService.getProfile().then(user => {
            if (user.isAdmin) {
              return $q.resolve();
            } else {
              AppStateService.goToInbox();
              return $q.reject();
            }
          });
        }
      }
    };

    $stateProvider.state(states.skillBot);
    /********************************
      DASHBOARD
    ********************************/

    /*
     * admin/dashboard/
     */
    states.dashboard = {
      name: 'admin.dashboard',
      url: 'dashboard/',
      views: {
        content: {
          templateUrl: 'blocks/admin/views/dashboard.html',
          controller: 'DashboardCtrl'
        }
      },
      resolve: {
        userAllowed: function (
          $q,
          UserService: UserService,
          AppStateService: AppStateService
        ) {
          return UserService.getProfile().then(user => {
            if (user.isAdmin) {
              return $q.resolve();
            } else {
              AppStateService.goToInbox();
              return $q.reject();
            }
          });
        }
      }
    };

    /*
     * admin/dashboard/users
     */
    states.dashboardUsers = {
      name: 'admin.dashboard.users',
      url: 'users/'
    };

    /*
     * admin/dashboard/users/add
     */
    states.dashboardUsersAdd = {
      name: 'admin.dashboard.users.add',
      url: 'add/',
      views: states.usersAdd.views
    };

    /*
     * admin/dashboard/users/edit
     */
    states.dashboardUsersEdit = {
      name: 'admin.dashboard.users.edit',
      url: 'edit/:id/',
      views: states.usersEdit.views
    };

    /*
     * admin/dashboard/departments/
     */
    states.dashboardDepartments = {
      name: 'admin.dashboard.departments',
      url: 'departments/'
    };

    /*
     * admin/dashboard/departments/add/
     */
    states.dashboardDepartmentsAdd = {
      name: 'admin.dashboard.departments.add',
      url: 'add/',
      views: states.departmentsAdd.views,
      data: {
        sidebarActive: true
      }
    };

    /*
     * admin/dashboard/establishment/
     */
    states.dashboardEstablishment = {
      name: 'admin.dashboard.establishment',
      url: 'establishment/'
    };

    /*
     * admin/dashboard/establishment/edit
     */
    states.dashboardEstablishmentEdit = {
      name: 'admin.dashboard.establishment.edit',
      url: 'edit/',
      views: {
        'sidebar@admin': {
          templateUrl: 'blocks/admin/views/establishment.html',
          controller: 'EstablishmentCtrl'
        }
      }
    };

    $stateProvider.state(states.dashboard);
    $stateProvider.state(states.dashboardUsers);
    $stateProvider.state(states.dashboardUsersAdd);
    $stateProvider.state(states.dashboardUsersEdit);
    $stateProvider.state(states.dashboardDepartments);
    $stateProvider.state(states.dashboardDepartmentsAdd);
    $stateProvider.state(states.dashboardEstablishment);
    $stateProvider.state(states.dashboardEstablishmentEdit);

    /*
     * SECURIY
     */

    states.security = {
      name: 'admin.security',
      url: 'security/',
      views: {
        content: {
          templateUrl: 'blocks/admin/views/security.html',
          controller: 'SecurityCtrl'
        }
      },
      resolve: {
        userAllowed: function (
          $q,
          UserService: UserService,
          AppStateService: AppStateService
        ) {
          return UserService.getProfile().then(user => {
            if (user.isAdmin) {
              return $q.resolve();
            } else {
              AppStateService.goToInbox();
              return $q.reject();
            }
          });
        }
      }
    };
    $stateProvider.state(states.security);
    states.epaBot = {
      name: 'admin.satisfaction',
      url: 'satisfaction/',
      views: {
        content: {
          templateUrl: 'blocks/admin/epa-bot/epa-bot.html',
          controller: 'EpaBotCtrl',
          controllerAs: 'vm'
        }
      },
      resolve: {
        userAllowed: function (
          $q,
          UserService: UserService,
          AppStateService: AppStateService
        ) {
          return UserService.getProfile().then(user => {
            if (user.isAdmin) {
              return $q.resolve();
            } else {
              AppStateService.goToInbox();
              return $q.reject();
            }
          });
        }
      }
    };
    $stateProvider.state(states.epaBot);
  }
]);
