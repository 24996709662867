const playground = Vue.component('playground-view', {
  data() {
    return {
      inputModel: '',
      picked: '',
      list: [],
      isReady: false
    };
  },
  methods: {
    inputChange(event) {
      this.inputModel = event;
    }
  },
  template: `<div class="">
  <SectionHeader
    :title="'ADS Playground'"
    icon="code">
  <template v-slot:subheader>
  <span />
  </template>
  </SectionHeader>
    <div class="px-2">
      <Collapse class="" :open="false" :title="'Input text'" :icon="'code'">
        <p>here would be the input component text with its states</p>
        <div class="d-flex card">
          <div class="flex-col-4">
            <InputText label="Name" />
          </div>
          <div class="flex-col-4">
            <InputText label="Name" v-model="inputModel" />
            <span>your name is: {{ inputModel }} </span>
          </div>
          <div class="flex-col-4">
            <InputText label="Name" :input-error="!inputModel" :error-message="'type your name in the left input!'"  />
          </div>
          <div class="flex-col-4">
            <InputText label="Name" :disabled="true"  />
          </div>
        </div>
        <div class="d-flex card">
          <div class="flex-col-4">
            <RadioButton label="Name" id="abc" value="abc" v-model="picked" />
            <RadioButton label="Name" id="dfg" value="dfg" v-model="picked" />
          </div>
          <div class="flex-col-4">
            {{ picked }}
          </div>
      </div>
      <div class="d-flex card">
          <div class="flex-col-4">
            <Checkbox label="Is ready?" v-model="isReady" />
          </div>
          <div class="flex-col-4">
            <Checkbox v-model="isReady">
            <span>
              <img class="ads-circle" src="https://es.vuejs.org/images/logo.png" width="20" height="20" />
              lala
            </span>
            </Checkbox>
          </div>
          <div class="flex-col-4">
            {{ isReady }}
          </div>
      </div>
      </Collapse>
    </div>

  </div>`
});
