(function(module) {
  'use strict';
  AdSession.Inject = [];
  function AdSession() {
    return {
      set(key, value) {
        this._sessionStore('set', key, value);
      },
      get(key) {
        return Promise.resolve(this._sessionStore('get', key));
      },
      clear() {
        sessionStorage.clear();
      },
      _sessionStore(action, key, value) {
        try {
          if (window.Storage) {
            if (action === 'set') {
              sessionStorage.setItem(key, JSON.stringify(value));
              return true;
            }
            if (action === 'get') {
              return JSON.parse(sessionStorage.getItem(key));
            }
          } else {
            return false;
          }
        } catch (e) {
          return e.message;
        }
      }
    };
  }
  module.factory('AdSession', AdSession);
})(angular.module('postCenterWebClientApp'));
