'use strict';

(function () {
  angular
    .module('postCenterWebClientApp')
    .component('adAvatar', {
      bindings: {
        klout: '<?',
        influencer: '<?',
        showSocialNetwork: '<?',
        socialNetwork: '@',
        source: '@',
        name: '@',
        color: '@',
        crmIntegration: '@',
        showIcon: '<?'
      },
      controller: adAvatarCtrl,
      templateUrl: 'blocks/global/views/ad_avatar.html'
    })
    .directive('onErrorSrc', onErrorSrc);

  adAvatarCtrl.$inject = ['$filter'];

  function adAvatarCtrl($filter) {
    const self = this;
    const {crmIntegration, showIcon} = self;
    self.crmIntegration = crmIntegration;
    self.getIcon = `fa-${crmIntegration}`;
    self.showIcon = showIcon !== undefined ? showIcon : true;

    self.getNameCrm = () => {
      const crmName = self.crmIntegration
        ? self.crmIntegration.charAt(0).toUpperCase() +
          self.crmIntegration.slice(1)
        : '';
      return $filter('translate')('CRM_ICON_AVATAR', {crmName});
    };
  }
  function onErrorSrc() {
    return {
      link: (scope, element, attrs) => {
        element.bind('error', () => {
          if (attrs.src !== attrs.onErrorSrc) {
            attrs.$set('src', attrs.onErrorSrc);
          }
        });
      }
    };
  }
})();
