(module => {
  'use strict';

  function departmentSelectorResults() {
    return {
      bindToController: {
        parent: '=departmentSelectorResults'
      },
      controllerAs: 'containerCtrl',
      controller() {},
      link(scope, elem, attr, controller) {
        controller.parent.setContainerElem(elem);
      }
    };
  }
  module.directive('departmentSelectorResults', departmentSelectorResults);
})(angular.module('postCenterWebClientApp'));
