// partially based on http://stackoverflow.com/a/22253161
(module => {
  'use strict';
  adVerticalSeparator.$inject = [];
  module.directive('adVerticalSeparator', adVerticalSeparator);

  function adVerticalSeparator() {
    return {
      restrict: 'E',
      transclude: true,
      templateUrl: 'blocks/global/views/ad_vertical_separator.html',
      controller: [
        'ColumnsService',
        '$element',
        '$scope',
        '$document',
        'AdMobile',
        function(ColumnsService, $element, $scope, $document, AdMobile) {
          const self = this;
          let leftPane = $element.find('.ad-vertical-separator-left-pane');
          let rightPane = $element.find('.ad-vertical-separator-right-pane');
          let separator = $element.find('.ad-vertical-separator-separator');
          let container = $element.find('.ad-vertical-separator-container');
          let leftPaneMinWidth;
          let rightPaneMinWidth;
          let leftPaneScope;
          let rightPaneScope;
          let resizeSensor;

          let ticketDividedView = false;
          $scope.toDestroy = {};

          const viewDivisionSub = ColumnsService.dividedView.subscribe(dividedView => {
            ticketDividedView = dividedView;
          })

          $scope.$on('$destroy', () => {
            viewDivisionSub.unsubscribe()
            for (const [key, destroyMethod] of Object.entries($scope.toDestroy)) {
                if (destroyMethod) {
                    destroyMethod()
                }
            }
          });

          self.setupLeftPane = function(verticalDivisorLeft) {
            leftPaneMinWidth = verticalDivisorLeft.min;
            verticalDivisorLeft.transcludeFn((clone, scope) => {
              leftPane.append(clone);
              leftPaneScope = scope;
            });
          };

          self.setupRightPane = function(verticalDivisorRight) {
            if (AdMobile.isMobile()) {
              rightPaneMinWidth = 0;
            } else {
              rightPaneMinWidth = verticalDivisorRight.min;
            }
            verticalDivisorRight.transcludeFn((clone, scope) => {
              rightPane.append(clone);
              rightPaneScope = scope;
            });
          };

          self.setMiddleSize = function() {
            const middleWidth = (container.width() - separator.width()) / 2;
            if (rightPane.width() <= middleWidth) {
              updateSizes(middleWidth);
            }
          };

          resizeSensor = new ResizeSensor(container, () => {
            const rightPaneSize = rightPane.width();
            if (rightPaneSize < rightPaneMinWidth()) {
              updateSizes(getLeftPaneMinWidth());
            }
          });

          separator.dblclick(event => {
            if (rightPane.width() <= rightPaneMinWidth()) {
              updateSizes(leftPaneMinWidth());
            } else if (leftPane.width() <= leftPaneMinWidth()) {
              const separatorOffset = separator.offset();
              if (
                !(
                  event.pageX >= separatorOffset.left &&
                  event.pageX <= separator.outerWidth() &&
                  event.pageY >= separatorOffset.top &&
                  event.pageY <= separator.outerHeight()
                )
              ) {
                updateSizes((container.width() - separator.width()) / 2);
              }
            } else {
              updateSizes(getLeftPaneMinWidth());
            }
          });

          separator.mousedown(() => {
            $document.on('mousemove', mousemove);
            $document.on('mouseup', mouseup);
            disableSelectstart();

            function mousemove(event) {
              const leftPaneSize = event.pageX - $element.offset().left;
              updateSizes(leftPaneSize);
            }

            function mouseup() {
              $document.unbind('mousemove', mousemove);
              $document.unbind('mouseup', mouseup);
              enableSelectstart();
            }
          });

          function updateSizes(leftPaneSize) {
            const rightPaneSize =
              container.outerWidth() - leftPaneSize - separator.outerWidth();
            if (rightPaneMinWidth && rightPaneSize < rightPaneMinWidth()) {
              leftPaneSize = getLeftPaneMinWidth();
            }
            if (leftPaneMinWidth && leftPaneSize < leftPaneMinWidth()) {
              leftPaneSize = leftPaneMinWidth;
            }
            separator.css({
              left: leftPaneSize + 'px'
            });
            leftPane.css({
              width: leftPaneSize + 'px'
            });
            rightPane.css({
              left: leftPaneSize + separator.outerWidth() + 'px'
            });
          }

          function getLeftPaneMinWidth() {
            return (
              container.width() - rightPaneMinWidth() - separator.outerWidth()
            );
          }

          function nonSelectOrDrag(event) {
            event.preventDefault();
            return false;
          }

          function disableSelectstart() {
            $document.on('selectstart dragstart', nonSelectOrDrag);
          }

          function enableSelectstart() {
            $document.off('selectstart dragstart', nonSelectOrDrag);
          }

          if (AdMobile.isMobile() || ticketDividedView) {
            updateSizes(container.width());
          } else {
            updateSizes((container.width() - separator.width()) / 2);
          }
          $scope.$on('$destroy', () => {
            resizeSensor.detach();
            rightPane.remove();
            leftPane.remove();
            rightPaneScope.$destroy();
            leftPaneScope.$destroy();
          });
        }
      ]
    };
  }
})(angular.module('postCenterWebClientApp'));
