'use strict';

angular.module('postCenterWebClientApp')
  .directive('adReplyAccountSelectorResults', function(){
    return {
      bindToController: {
        'parent': '=adReplyAccountSelectorResults'
      },
      controllerAs: 'containerCtrl',
      controller: function(){},
      link: function(scope, elem, attr, controller){
        controller.parent.setContainerElem(elem);
      }
    }
  });
