'use strict';

angular
  .module('postCenterWebClientApp')
  .directive('emojiPopoverButton', getEmojiPopoverButtonCtrl);

function getEmojiPopoverButtonCtrl() {
  return {
    templateUrl: 'blocks/message/views/emoji_popover_button.html',
    restrict: 'E',
    scope: {},
    controller: EmojiPopoverButtonCtrl
  }
}

EmojiPopoverButtonCtrl.$inject = ['$scope'];

function EmojiPopoverButtonCtrl($scope) {
  // TODO: Deprecate this unused controller
  $scope.isDarkMode = localStorage.getItem('colorScheme') === 'dark';
}
