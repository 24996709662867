(function(module) {
  'use strict';
  adFrameService.$inject = ['$sce', 'FRONTEND3_URL'];

  function adFrameService($sce, FrontEnd3) {
    return {
      loadIframe(iFrameurl) {
        const url = `${FrontEnd3}${iFrameurl}`;
        // replace url with folder on project
        return $sce.trustAsResourceUrl(url);
      }
    };
  }
  module.factory('adFrameService', adFrameService);
})(angular.module('postCenterWebClientApp'));
