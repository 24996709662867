'use strict';
angular
  .module('postCenterWebClientApp')
  .directive('campaigns', postCenterCampaignsDirective);
function postCenterCampaignsDirective() {
  return {
    templateUrl: 'blocks/crm/views/campaigns/campaigns.html',
    restrict: 'E',
    scope: {},
    controller: postCenterCampaignsController
  };
}
postCenterCampaignsController.$inject = [
  '$scope',
  '$filter',
  '$interval',
  'CaseService',
  'AdNotification',
  'CaseDetailManager',
  'MessageTemplateService',
  'adExportToCsv'
];
function postCenterCampaignsController(
  $scope,
  _filter,
  $interval,
  _case,
  AdNotification,
  _casedetailmanager,
  MessageTemplateService: MessageTemplateService,
  adExportToCsv
) {
  $scope.page = 1;
  $scope.campaigns = [];
  $scope.selectedCampaign = null;
  $scope.totalPages = 1;
  $scope.loading = true;

  function getCampaigns () {
    $scope.loading = true;
    MessageTemplateService.getCampaigns($scope.page).then(
      response => {
        $scope.campaigns = response.result;
        $scope.totalPages = response.pagination.total_pages;
        $scope.loading = false;
        $scope.$applyAsync();
      },
      error => {
        AdNotification.error(error, 'error_getting_whatsapp_campaign');
        $scope.loading = false;
        $scope.$applyAsync();
      }
    );
  }

  getCampaigns();

  $scope.refreshCampaigns = $interval(getCampaigns, 1000 * 10);

  $scope.$on('$destroy', () => $interval.cancel($scope.refreshCampaigns))

  $scope.changePage = (page: number) => {
    $scope.page = page;
    getCampaigns();
  }

  $scope.selectCampaign = (campaign) => {
    $scope.selectedCampaign = campaign;
  }

  $scope.unselectCampaign = () => {
    $scope.selectedCampaign = null;
  }

  $scope.readyToSend = (campaign): boolean => {
    if (campaign.status == 'loaded') {return true}
    return false;
  }

  $scope.alreadySent = (campaign): boolean => {
    if (['started', 'completed'].includes(campaign.status)) {return true}
    return false;
  }

  $scope.getCampaignDescription = (campaign) => {
    const translations = {
      'created': 'GENERAL_CAMPAIGN_CREATED_STATE_DESCRIPTION',
      'loaded': 'GENERAL_CAMPAIGN_LOADED_STATE_DESCRIPTION',
      'started': 'GENERAL_CAMPAIGN_STARTED_STATE_DESCRIPTION',
      'completed': 'GENERAL_CAMPAIGN_COMPLETED_STATE_DESCRIPTION',
      'validated': 'GENERAL_CAMPAIGN_VALIDATED_STATE_DESCRIPTION',
      'invalid': 'GENERAL_CAMPAIGN_INVALID_STATE_DESCRIPTION',
    }
    return translations[campaign.status] || 'GENERAL_CAMPAIGN_PENDING_STATE_DESCRIPTION'
  }

  $scope.getCampaignStateTranslation = (campaign) => {
    const translations = {
      'created': 'GENERAL_CAMPAIGN_CREATED_STATE',
      'loaded': 'GENERAL_CAMPAIGN_LOADED_STATE',
      'started': 'GENERAL_CAMPAIGN_STARTED_STATE',
      'completed': 'GENERAL_CAMPAIGN_COMPLETED_STATE',
      'validated': 'GENERAL_CAMPAIGN_VALIDATED_STATE',
      'invalid': 'GENERAL_CAMPAIGN_INVALID_STATE',
    }
    return translations[campaign.status] || 'GENERAL_CAMPAIGN_PENDING_STATE'
  }

  $scope.sendCampaign = (campaign) => {
    MessageTemplateService.sendCampaign(campaign.id).then(
      response => {
        AdNotification.success({}, 'whatsapp_campaign_sent_successfully');
        $scope.unselectCampaign();
        getCampaigns();
      },
      error => {
        AdNotification.error(error, 'error_sending_whatsapp_campaign');
      }
    );
  }

  $scope.downloadCampaignDetails = (campaign) => {
    MessageTemplateService.getCampaignDetails(campaign.id).then(
      response => {
        adExportToCsv._exportCsvData('detail', response)
      },
      error => {
        AdNotification.error(error, 'error_getting_whatsapp_campaign_details');
      }
    );
  };

}