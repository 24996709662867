'use strict';


class ThirdPartyService {
  $rootScope
  ENV
  adChatService: AdChatService

  constructor ($rootScope, ENV, adChatService, EstablishmentService, UserService) {
    this.$rootScope = $rootScope
    this.ENV = ENV
    this.adChatService = adChatService
    EstablishmentService.estObservable.subscribe(establishment => {
      UserService.getProfile().then(user => {
        this.initServices(user, establishment)
      })
    })
  }

  public initServices(user: ExtendedUser, establishment: Establishment) {
    const {postcenterVersion} = this.$rootScope;
    this.$rootScope.$emit('changeLanguage', user.profile.language);

    if (typeof window.Intercom !== 'undefined') {
      // required...
      if (!this.$rootScope.chatServiceInit) {
        this.adChatService.init({
          user: user,
          establishment: establishment,
          postcenterVersion: postcenterVersion
        });
        this.$rootScope.chatServiceInit = true;
      }
    }

    this.sentry({
      username: `${user.first_name} ${user.last_name}`,
      email: user.email
    });

    this.hotjar({
      id: user.id, 
      email: user.email, 
      companyName: establishment.name, 
      date_joined: user.date_joined
    });
    this.canny({
      username: `${user.first_name} ${user.last_name}`,
      email: user.email,
      id: user.id,
      date_joined: user.date_joined,
      created_at: establishment.created_at,
      companyName: establishment.name,
      companyID: establishment.id,
      ssoToken: user.canny_token
    });
  }

  private dateToString(date) {
     return new Date(parseInt(date) * 1000).toISOString()
  }

  private sentry({email, username}) {
    if (this.ENV === '') {
      return;
    }
    if (typeof Sentry  !== "undefined") {
      Sentry.configureScope(scope => {
        scope.setUser({
          email,
          username
        });
      });
    }
  }

  private hotjar({id, email, companyName, date_joined}) {
    if (this.ENV === '') {
      return;
    }
    if (window.hotjar) {
      window.hj('identify', id, {
        email,
        company: companyName,
        created: this.dateToString(date_joined)
      });
    }
  }

  private canny({
    username,
    email,
    companyName,
    id,
    companyID,
    date_joined,
    created_at,
    ssoToken
  }) {
    if (this.ENV === '') {
      return;
    }
    if (window.Canny) {
      Canny('identify', {
        appID: '5ef55f330172f5179f92acc5',
        user: {
          email,
          id,
          name: username,
          created: this.dateToString(date_joined),
          companies: [
            {
              created: this.dateToString(created_at),
              id: companyID,
              name: companyName
            }
          ]
        },
        ssoToken
      });
    }
  }

}

angular
  .module('postCenterWebClientApp')
  .service('ThirdPartyService', ThirdPartyService)