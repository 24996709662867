angular
  .module('postCenterWebClientApp')
  .directive('ngSmoochProgress', function($filter) {
    return {
      restrict: 'E',
      scope: {
        account: '='
      },
      link: function(scope, elem) {
        new Vue({
          el: elem[0].querySelector('.ng-non-bindable'),
          data: {
            translate: $filter('translate'),
            smoochProcess: scope.account
          }
        });
      }
    };
  });
Vue.component('smooch-progress', {
  data() {
    return {
      smoochProcess: this.$root.smoochProcess,
      title: this.$root.translate('SOCIALACCOUNTS_SMOOCH_PROCESS_TITLE')
    };
  },
  template: `<div class="smooch-progress">
              <h4>{{this.title}}</h4>
              <message-step :message="smoochProcess.nextStep" :class="warningMode" />
              <smooch-steps :step="smoochProcess" />
            </div>`,
  computed: {
    warningMode() {
      return this.smoochProcess.warning ? 'text-warning-smooch' : '';
    }
  }
});
Vue.component('messageStep', {
  props: ['message'],
  template: `<div>
              <p v-html="message"></p>
            </div>`
});
Vue.component('smooch-steps', {
  props: ['step'],
  data() {
    return {
      smoochSteps: [
        {
          icon: 'whatsapp',
          title: this.$root.translate('SOCIALACCOUNTS_SMOOCH_PROCESS_ADDED')
        },
        {
          icon: 'list-alt',
          title: this.$root.translate('SOCIALACCOUNTS_SMOOCH_PROCESS_SENDED')
        },
        {
          icon: 'check',
          title: this.$root.translate('SOCIALACCOUNTS_SMOOCH_PROCESS_APPROVED')
        },
        {
          icon: 'comments',
          title: this.$root.translate('SOCIALACCOUNTS_SMOOCH_PROCESS_ACTIVATED')
        }
      ]
    };
  },
  template: `<ul class="smooch-steps">
              <progress-bar :progress="step.numberStep" />
              <li v-for="smoochStep, index in smoochSteps" :class="isActive(index)">
                <span class="fa" :class="icons(smoochStep.icon)" />
                <span>{{smoochStep.title}}</span>
              </li>
          </ul>`,
  methods: {
    isActive(index) {
      const pos = index + 1;
      return pos <= this.step.numberStep ? 'active' : '';
    },
    icons(i) {
      return `fa-${i}`;
    }
  }
});
Vue.component('progressBar', {
  props: ['progress'],
  template: `<div class="smooch-progress-bar">
    <div :style="getProgress"></div>
  </div>`,
  computed: {
    getProgress() {
      switch (this.progress) {
        case 1:
          return {width: '25%'};
        case 2:
          return {width: '50%'};
        case 3:
          return {width: '75%'};
        case 4:
          return {width: '100%'};
      }
    }
  }
});
Vue.config.devtools = true;
