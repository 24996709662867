'use strict';

var app = angular.module('postCenterWebClientApp');

app.directive('businessRulesEditor', function () {
  return {
    templateUrl: 'blocks/admin/views/custom_business_rules/business_rules_editor.html',
    restrict: 'E',
    scope: {
      availableTriggers: '=',
      availableConditions: '=',
      availableActions: '=',
      rules: '='
    },
    controller: ['$scope', '$rootScope', '$filter',  function ($scope, $rootScope, $filter) {

      $scope.deleteRule = function (rule) {
        var index = $scope.rules.indexOf(rule);
        $scope.rules.splice(index, 1)
      };
    }]
  }
});

app.directive('businessRuleDefinition', function () {
  return {
    templateUrl: 'blocks/admin/views/custom_business_rules/business_rule_definition.html',
    restrict: 'E',
    scope: {
      availableTriggers: '=',
      availableConditions: '=',
      availableActions: '=',
      rule:'=',
      deleteRule:'='
    },
    controller: ['$scope', '$rootScope', 'AdRequest', '$filter', '$timeout', 'BusinessRulesService', 'localStorageService',
      function ($scope, $rootScope, AdRequest, $filter, $timeout, BusinessRulesService, localStorageService) {

        $scope.getMaximizedSettingKey = function () {
          return 'business_rule_maximized_' + $scope.rule.rule_id;
        };

        var storedMaximizedValue = localStorageService.get($scope.getMaximizedSettingKey());

        if (storedMaximizedValue === null) {
          $scope.maximized = true
        }
        else {
          $scope.maximized = storedMaximizedValue
        }

        $scope.saving = false;

        $scope.maximize = function () {
          $scope.maximized = true;
          $scope.saveMaximizedSetting();
        };

        $scope.minimize = function () {
          $scope.maximized = false;
          $scope.saveMaximizedSetting();
        };

        $scope.saveMaximizedSetting = function () {
          if ($scope.rule.rule_id) {
            localStorageService.set($scope.getMaximizedSettingKey(), $scope.maximized)
          }
        };

        $scope.delete = function () {
          if ($scope.rule.rule_id) {
            BusinessRulesService.delete($scope.rule).then(function () {
              $scope.deleteRule($scope.rule)
              $scope.$applyAsync();
            })
          }
          else {
            $scope.deleteRule($scope.rule)
          }
        };

        $scope.save = function () {
          $scope.saving = true;
          BusinessRulesService.save($scope.rule).then(function (rule) {
            $scope.saving = false;
            $scope.rule.rule_id = rule.rule_id;
            $rootScope.$emit(
              'successMessage',
              $filter('translate')('BUSINESS_RULES_SAVE_SUCCESS')
            )
            $scope.$applyAsync();
          }, function (err) {
            $scope.saving = false;
          })
        };

        $scope.addCondition = function () {
          if (!$scope.rule.condition) {
            $scope.rule.condition = new rules.conditions.GroupCondition('AND');
          }
        };

      }
    ]
  };
});

app.directive('businessRuleCondition', function () {
  return {
    templateUrl: 'blocks/admin/views/custom_business_rules/business_rule_condition.html',
    restrict: 'E',
    scope: {
      parent: '=',
      condition: '=',
      availableConditions: '='
    },
    controller: ['$scope', '$rootScope', 'AdRequest', '$filter',
      function ($scope, $rootScope, AdRequest, $filter) {

        $scope.translate = function (label) {
          return $filter('translate')(label)
        };

        $scope.translateCondition = function (label) {
          return $scope.translate('BUSINESS_RULES_' + label)
        };

        $scope.accounts = $rootScope.accounts;
        $scope.bots = $rootScope.bots;
        $scope.conditionType = $scope.condition.identifier;

        if ($scope.condition.isCaseTypification()) {
          $scope.caseCustomDatas = $rootScope.customData.filter(function (v) {
            if ($scope.condition.customDataKey === v.key) {
              $scope.selectedCustomData = v;
            }
            return v.entity === 'C' && v.type !== 'text' && !v.deleted
          });
        }

        $scope.formatAccountName = function (account) {
          return account.name + ' - ' + $filter('capitalize')(account.sn)
        };

        $scope.conditionTypeChanged = function (conditionType) {
          var newCondition = new rules.conditions.Condition.subtypes[conditionType]();
          $scope.parent.replaceChild($scope.condition, newCondition);
          $scope.condition = newCondition;
        };

        $scope.customDataChanged = function (customData) {
          if (customData.type === 'boolean') {
            customData.options = [
              {key: true, value: 'True'},
              {key: false, value: 'False'}
            ]
          }
          $scope.condition.customDataKey = customData.key
        };

        $scope.addCondition = function () {
          $scope.condition.conditions.push(new rules.conditions.EmptyCondition());
        };

        $scope.addGroup = function () {
          $scope.condition.conditions.push(new rules.conditions.GroupCondition('AND'))
        };

        $scope.removeMe = function () {
          if ($scope.parent.isBusinessRule()) {
            $scope.parent.condition = null;
          }
          else {
            $scope.parent.removeChild($scope.condition);
          }
        };

      }
    ]
  };
});


app.directive('businessRuleAction', function () {
  return {
    templateUrl: 'blocks/admin/views/custom_business_rules/business_rule_action.html',
    restrict: 'E',
    scope: {
      availableActions: '=',
      action: '=',
      rule: '='
    },
    controller: ['$scope', '$rootScope', 'AdRequest', '$filter',
      async function ($scope, $rootScope, AdRequest, $filter) {

        $scope.bots = $rootScope.bots;
  
        async function getTargets() {
          return AdRequest.get('/api/v1/webhook_notification/targets')
        };

        $scope.targets = [];
        $scope.targets = await getTargets();
        $scope.$applyAsync();

        $scope.getTargetName = function (target) {
          if (target.delivery_type === 'http') {
            return `${target.id} - HTTP: ${target.endpoint}`;
          } else if (target.delivery_type === 'pubsub') {
            return `${target.id} - PubSub: ${target.topic}`;
          } else {
            return `${target.id} - Unknown target: ${target.delivery_type}`;
          };
        };

        $scope.changeAction = function (prevAction, newActionId) {
          var newAction = prevAction.replaceWith(rules.actions.Action.subtypes[newActionId]);
          var index = $scope.rule.actions.indexOf(prevAction);
          $scope.rule.actions[index] = newAction;
        };

        $scope.onActionChange = function (actionId) {
          $scope.changeAction($scope.action, actionId)
        }

      }
    ]
  };
});
