(module => {
  'use strict';
  crmErrorMessage.$inject = [];
  module.factory('crmErrorMessage', crmErrorMessage);
  function crmErrorMessage() {
    return {
      entity: null,
      crmName: 'CRM',
      codes: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      getMessage({code = '0', locale = 'es', entity = '', crmName = 'CRM'}) {
        if (!code && !this.codes.includes(code) && !entity) {
          return this.messages[locale].default;
        }
        this.entity = this.getEntity(entity, locale);
        this.crmName = crmName.charAt(0).toUpperCase() + crmName.slice(1);
        const {
          [locale]: {
            ['default']: defaultMessage,
            [code]: message = defaultMessage
          }
        } = this.messages();
        return !message ? defaultMessage : message;
      },
      getEntity(entity, locale) {
        return entity === 'user' ? this.messages()[locale].user : 'Ticket';
      },
      messages() {
        return {
          en: {
            default: 'An unexpected error has occurred, please try again',
            user: 'User',
            1: `Invalid auth with ${this.crmName}`,
            2: `Failed to store auth with ${this.crmName}`,
            3: `Search failed in ${this.crmName}`,
            4: `${this.entity} not found in ${this.crmName}`,
            5: `Field does not exist in ${this.crmName}`,
            6: `Empty field in ${this.crmName}`,
            7: `Failed to updated in ${this.crmName}`,
            8: `${this.entity} already exists on ${this.crmName}`,
            9: `Failed to create ${this.entity} in ${this.crmName}`
          },
          es: {
            default: 'Se ha producido un error inesperado, vuelve a intentar',
            user: 'Usuario',
            1: `Autenticación inválida con ${this.crmName}`,
            2: `Falló almacenar la autenticación con ${this.crmName}`,
            3: `Falló la búsqueda en ${this.crmName}`,
            4: `${this.entity} no encontrado en ${this.crmName}`,
            5: `Campo no existe en ${this.crmName}`,
            6: `Campo vacío en ${this.crmName}`,
            7: `Falló al actualizar en ${this.crmName}`,
            8: `El ${this.entity} ya existe en ${this.crmName}`,
            9: `Hubo un error al crear el ${this.entity} en ${this.crmName}`
          },
          pt: {
            default: 'Ocorreu um erro inesperado, tente novamente',
            user: 'Usuário',
            1: `Autenticação inválida com ${this.crmName}`,
            2: `Falha ao armazenar autenticação com ${this.crmName}`,
            3: `Pesquisa falhou em ${this.crmName}`,
            4: `${this.entity} não encontrado em ${this.crmName}`,
            5: `Campo não existe em ${this.crmName}`,
            6: `Campo vazio em ${this.crmName}`,
            7: `Falha ao atualizar em ${this.crmName}`,
            8: `O ${this.entity} já existe em ${this.crmName}`,
            9: `Ocorreu um erro ao criar o ${this.entity} em ${this.crmName}`
          }
        };
      }
    };
  }
})(angular.module('postCenterWebClientApp'));
