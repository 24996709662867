'use strict';

angular
  .module('postCenterWebClientApp')
  .directive('kpiSentiments', kpiSentiments);

kpiSentiments.$inject = [];

function kpiSentiments() {
  return {
    scope: {
      kpi: '='
    },
    templateUrl: 'blocks/analytics/views/kpi_sentiments.html',
    restrict: 'E',
    controller: ['$scope', '$uibModal', function ($scope, $uibModal) {
      $scope.openHelpModal = function () {
        $uibModal.open({
          templateUrl: 'blocks/analytics/views/dashboard_help_modal.html',
          size: 'sm',
          controller: ['$scope', '$uibModalInstance', '$filter', function ($scope, $uibModalInstance, $filter) {
            $scope.title = 'ANALYTICS_DASHBOARD_TICKETS_SENTIMENTS_TITLE';
            $scope.helpText = $filter('translate')('ANALYTICS_DASHBOARD_TICKETS_SENTIMENTS_HELP');
            $scope.cancel = function() {
              $uibModalInstance.dismiss('cancel');
            };
          }],
        });
      };
    }]
  };
}
