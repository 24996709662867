'use strict';

angular
  .module('postCenterWebClientApp')
  .directive('kpiSentimentsChart', function () {
    return {
      scope : {
        kpi: '='
      },
      templateUrl: 'blocks/analytics/views/kpi_sentiments_chart.html',
      restrict: 'E',
      controller: kpiSentimentsChartController
    };
  });

kpiSentimentsChartController.$inject=['$scope', '$filter', 'AnalyticsCharts'];

function kpiSentimentsChartController($scope, $filter, AnalyticsCharts) {
  function getSeriesData(valueList) {
    return [{
        data: [{
            name: $filter('translate')('CASECONTROL_SENTIMENT_NEGATIVE'),
            dataLabels: 'asdasdasd',
            color: '#d32f2f',
            y: valueList[0]
        }, {
            name: $filter('translate')('CASECONTROL_SENTIMENT_NEUTRAL'),
            color: '#607d8b',
            y: valueList[1]
        }, {
            name: $filter('translate')('CASECONTROL_SENTIMENT_POSITIVE'),
            color: '#4caf50',
            y: valueList[2]
        }]
    }];
  }

  const shouldDisplayChart = (valueList = []) => valueList?.[0] + valueList?.[1] + valueList?.[2] > 0;


  $scope.chartList = [
    {
      id: 1,
      name: $filter('translate')('CASECONTROL_SENTIMENT_NEUTRAL'),
      index: 1
    }, {
      id: 2,
      name: $filter('translate')('CASECONTROL_SENTIMENT_NEGATIVE'),
      index: 0
    }, {
      id: 3,
      name: $filter('translate')('CASECONTROL_SENTIMENT_POSITIVE'),
      index: 2
    }
  ]

  $scope.refreshChart = function () {
    if ($scope.selectedChart === undefined) {
      $scope.selectedChart = $scope.chartList[0];
    }
    $scope.displayChart = shouldDisplayChart($scope.kpi.value?.[$scope.selectedChart.index]);
    if ($scope.displayChart) {
      $scope.chartConfig = AnalyticsCharts.createPie(
        getSeriesData($scope.kpi.value[$scope.selectedChart.index])
      );
    }
  }

  $scope.refreshChart();
  const kpiWatcherOff = $scope.$watch('kpi', $scope.refreshChart, true);

  $scope.$on('$destroy', () => {
    kpiWatcherOff();
  });
};

