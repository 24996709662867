/* global DateFilterMixin */
/* eslint-disable object-shorthand */
const HistoryFilters = Vue.component('HistoryFilters', {
  mixins: [DateFilterMixin],
  props: {
    group: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      labels: {
        time: {
          from: '',
          to: ''
        },
        from: '',
        to: ''
      },
      rangeStart: '',
      rangeEnd: '',
      periods: [
        {
          label: 'period-selector.today',
          value: 0,
          checked: true
        },
        {
          label: 'period-selector.yesterday',
          value: 1,
          checked: false
        },
        {
          label: 'period-selector.last-days',
          value: 3,
          checked: false
        },
        {
          label: 'period-selector.last-days',
          value: 7,
          checked: false
        },
        {
          label: 'period-selector.last-days',
          value: 14,
          checked: false
        },
        {
          label: 'period-selector.last-days',
          value: 30,
          checked: false
        },
        {
          label: 'period-selector.last-days',
          value: 90,
          checked: false
        },
        {
          label: 'period-selector.custom',
          value: 'custom',
          checked: false
        }
      ],
      header: {label: 'period-selector.today', value: 0},
      masks: {
        input: 'D/MMM/YYYY',
        title: 'MMMM',
        weekdays: 'WWW'
      },
      oldLabels: {
        from: '',
        to: ''
      }
    };
  },
  computed: {
    ...Vuex.mapGetters({
      locale: 'locale',
      filters: 'getFilters',
      limits: 'getLimits',
      limitDate: 'getLimitDate',
      limitDays: 'getLimitDays'
    }),
    maxDateTo() {
      return this.labels.to ? this.labels.to : new Date();
    },
    periodsLimited() {
      if (this.limits.limit_max >= 0) {
        return this.periods.filter(
          period => period.value <= this.limitDays || period.value === 'custom'
        );
      } else {
        return this.periods;
      }
    },
    periodsByName() {
      return this.periodsLimited.filter(period =>
        new RegExp(this.input, 'ig').test(
          this.$t(period.label, {number: period.value})
        )
      );
    }
  },
  mounted() {
    const {from} = this.filters;
    const given = moment(moment.unix(from).format('YYYY-MM-DD'), 'YYYY-MM-DD');
    const current = moment().startOf('day');
    const days = Math.round(
      Math.abs(moment.duration(given.diff(current)).asDays())
    );
    const existedDates = [0, 1, 3, 7, 15, 30, 90];
    if (existedDates.includes(days)) {
      this.selected = days;
    } else {
      this.selected = -1;
    }

    this.setLimitDate();
    this.setLabels(this.filters);
  },
  methods: {
    ...Vuex.mapActions(['setFilters', 'setLimitDate']),
    updateCustomFilters(customDate, updateLabels = true) {
      this.setFilters({...this.filters, ...customDate});
      if (updateLabels) {
        this.setLabels(customDate);
      }

      this.custom = false;
      this.selected = -1;
      this.lastSelected = -1;
      const periods = this.periods.map(period => {
        if (period.value === 'custom') {
          period.checked = true;
          return period;
        }
        period.checked = false;
        return period;
      });
      this.periods = periods;
      this.header = this.periods.find(period => period.checked);
    },
    handleChangeFilters() {
      const {
        from: rangeStart,
        to: rangeEnd,
        time: {from: hourFrom, to: hourTo}
      } = this.labels;

      if (!hourFrom) {
        this.labels.time.from = this.oldLabels.from;
      }

      if (!hourTo) {
        this.labels.time.to = this.oldLabels.to;
      }

      if (hourFrom && rangeStart !== null && rangeEnd !== null && hourTo) {
        const rangeFrom = this.getDateFormat({
          date: rangeStart,
          time: hourFrom
        });

        const rangeTo = this.getDateFormat({
          date: rangeEnd,
          time: hourTo
        });
        const converToLocalTs = date => moment(date).format('X');

        this.updateCustomFilters(
          {
            from: converToLocalTs(rangeFrom),
            to: converToLocalTs(rangeTo)
          },
          false
        );
      }
    },
    getDateFormat({date, time}) {
      const [hours, minutes] = time.split(':');
      const day = date.getDate();
      const month = date.getMonth();
      const year = date.getFullYear();
      return new Date(year, month, day, Number(hours), Number(minutes));
    },
    updateFilters(value) {
      this.selected = value;
      this.header = this.periods.find(period => period.value === this.selected);
      if (this.selected === 'custom') {
        this.custom = true;
      } else {
        this.lastSelected = this.selected;
        if (this.selected === 0) {
          // today
          this.filters.from = moment().startOf('day').unix();
          this.filters.to = moment().unix();
        } else if (this.selected === 1) {
          // yesterday
          this.filters.from = moment()
            .subtract(1, 'days')
            .startOf('day')
            .unix();
          this.filters.to = moment().subtract(1, 'days').endOf('day').unix();
        } else {
          this.filters.to = moment().unix();
          this.filters.from = this.fromDaysAgo(this.selected);
        }
        this.setLabels(this.filters);
        this.setFilters(this.filters);
        this.custom = false;
      }
      this.closeSelector();
    },
    setLabels(time) {
      const {to, from} = time;
      const getDate = ts => new Date(ts * 1000);

      this.labels.from = getDate(from);
      this.labels.to = getDate(to);

      this.oldLabels.from = moment.unix(from).format('HH:mm');
      this.oldLabels.to = moment.unix(to).format('HH:mm');

      this.labels.time.from = moment.unix(from).format('HH:mm');
      this.labels.time.to = moment.unix(to).format('HH:mm');
    }
  },
  template: `
    <div class="my-2 mx-1 filters">
      <div class="d-flex">
        <div class="d-flex position-relative">
          <span class="align-self-center mr-1">
            {{ $t('period') }}:
          </span>
          <div class="ads-selector-wrapper">
            <div
              class="ads-selector radio-button mx-1 w-70 ml-1"
              @click="toggleSelector"
              v-click-outside="closeSelector"
            >
              <div class="ads-selector__header d-flex" :class="isActive">
                <h5 class="bold d-inline">
                  {{ $t(header.label, {number: header.value}) }}
                </h5>
                <span class="fa fa-chevron-down" />
              </div>
              <div v-show="toggleList">
                <div class="p-1">
                  <input
                    type="text"
                    id="date-search-input"
                    class="form-control"
                    :placeholder="$t('inputs.filter_dates')"
                    v-model="input"
                    v-on:keypress="isLetter($event)"
                  />
                </div>
                <p class="pl-1" v-if="!periodsByName.length">
                  {{ $t('issues.not_found_period') }}
                </p>
                <ul v-else class="list-unstyled">
                  <li
                    v-for="(period, index) in periodsByName"
                    :key="index"
                    :name="period.value"
                  >
                    <label>
                      <input
                        type="radio"
                        @click.stop="updateFilters(period.value)"
                        name="date-radio"
                        id="date-radio"
                        :checked="period.checked"
                      />
                      <span class="mx-1"
                        >{{ $t(period.label, {number: period.value}) }}</span
                      >
                    </label>
                  </li>
                </ul>
                <div calendar-limits-dropdown v-if="limits.limit_max >= 0">
                  <i
                    class="fa fa-exclamation-circle-fas calendar-limits-dropdown-icon"
                  ></i>
                  <p>
                    <span
                      v-html="$t('calendar-limits', {limit: limitDays})"
                    ></span>
                    <a :href="$t('calendar-limits-link')" target="_blank"
                      >{{$t('calendar-limits-btn')}}</a
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
          <datetime-popover
            v-click-outside="cancelCustom"
            v-if="custom"
            :update-filters-handler="updateCustomFilters"
          />
        </div>
        <div class="flex-col-4 d-flex align-center datetime-picker pr-1">
          <span>{{ $t('from') }}:</span>
          <div class=" d-flex flex-direction-row mx-1">
            <v-date-picker
              mode="single"
              :locale="locale"
              :masks="masks"
              :min-date="limitDate"
              :max-date="maxDateTo"
              v-model="labels.from"
              color="teal"
              @input="handleChangeFilters"
            >
              <template v-slot="{ inputValue, inputEvents}">
                <input
                  class="picker-input picker-input--active bold"
                  :value="inputValue"
                  v-on="inputEvents"
                  readonly
                />
              </template>
              <template slot="footer" v-if="limits.limit_max >= 0">
                <i
                  class="fa fa-exclamation-circle-fas calendar-limits-icon"
                ></i>
                <p class="calendar-limits">
                  <span
                    v-html="$t('calendar-limits', {limit: limitDays})"
                  ></span>
                  <a :href="$t('calendar-limits-link')" target="_blank"
                    >{{$t('calendar-limits-btn')}}</a
                  >
                </p>
              </template>
            </v-date-picker>
            <input
              type="time"
              v-model="labels.time.from"
              class="time-input ml-1 picker-input picker-input--active bold"
              @change="handleChangeFilters"
            />
          </div>
          <span>{{ $t('to') }}:</span>
          <div class="d-flex flex-direction-row mx-1">
            <v-date-picker
              mode="single"
              :locale="locale"
              :masks="masks"
              :min-date="labels.from"
              :max-date="new Date()"
              v-model="labels.to"
              color="teal"
              @input="handleChangeFilters"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  class="picker-input picker-input--active bold"
                  :value="inputValue"
                  v-on:change="inputEvents"
                  v-on="inputEvents"
                  readonly
                />
              </template>
              <template slot="footer" v-if="limits.limit_max >= 0">
                <i
                  class="fa fa-exclamation-circle-fas calendar-limits-icon"
                ></i>
                <p class="calendar-limits">
                  <span
                    v-html="$t('calendar-limits', {limit: limitDays})"
                  ></span>
                  <a :href="$t('calendar-limits-link')" target="_blank"
                    >{{$t('calendar-limits-btn')}}</a
                  >
                </p>
              </template>
            </v-date-picker>
            <input
              type="time"
              v-model="labels.time.to"
              class="time-input ml-1 picker-input picker-input--active bold"
              @change="handleChangeFilters"
            />
          </div>
        </div>
        <div class="flex-col-2 d-flex pos-relative">
          <span class="align-self-center">{{ $t('view') }}:</span>
          <div class="w-70 ml-1">
            <KpiSelector :open="false" :group="group" />
          </div>
        </div>
      </div>
      <div class="d-flex mt-1" v-if="group === 'agents'">
        <div class="flex-col-3 d-flex pos-relative">
          <span class="align-self-center">{{ $t('agents') }}:</span>
          <div class="w-75 ml-1">
            <AgentsSelector :open="false" />
          </div>
        </div>
      </div>
      <div class="d-flex mt-1" v-if="group === 'departments'">
        <div class="flex-col-3 d-flex pos-relative">
          <span class="align-self-center">{{ $t('tabs.departments') }}:</span>
          <div class="w-75 ml-1">
            <DepartmentsSelector :open="false" />
          </div>
        </div>
      </div>
    </div>
  `
});
