class SlaAgentController {

  AnalyticsService: AnalyticsService
  user?: ExtendedUser
  reload
  vueInstance

  updateData(action) {
    this.reload = action;
    this.vueInstance.reload = action;
  }

  getI18n(user: User) {
    return new VueI18n({
      messages: {
        es: {
          myStats: 'Mi rendimiento',
          of: 'de',
          sla: {
            slaAddressed: 'En SLA',
            addressed: 'Abordados',
            assigned: 'Abiertos',
            hold: 'En espera'
          },
          tooltips: {
            totalTicketsSla:
              'Total de tickets que he respondido hoy dentro del SLA',
            totalAssigned: 'Total de tickets asignados a mí el día de hoy',
            totalAnswered: 'Total de tickets que he respondido hoy',
            totalOpened: 'Total de tickets abiertos asignados a mí',
            limitTickets: 'Límite de tickets que puedes tener en espera',
            limitAssigned: 'Límite de tickets que puedes tener asignados'
          },
          totalTickets: 'Total de tickets abiertos'
        },
        en: {
          myStats: 'My performance',
          of: 'of',
          sla: {
            slaAddressed: 'In SLA',
            addressed: 'Addressed',
            assigned: 'Assigned',
            hold: 'On hold'
          },
          tooltips: {
            totalTicketsSla:
              'Total tickets I have answered today within SLA',
            totalAssigned: 'Total of tickets assigned to me today',
            totalAnswered: 'Total tickets I have answered today',
            totalOpened: 'Total opened tickets assigned to me',
            limitTickets: 'Limit of tickets that you can have on hold',
            limitAssigned: 'Limit of tickets you can have assigned to you'
          },
          totalTickets: 'Total open tickets'
        },
        pt: {
          myStats: 'Meu desempenho',
          of: 'de',
          sla: {
            slaAddressed: 'Em SLA',
            addressed: 'Endereçado',
            assigned: 'Atribuído',
            hold: 'Em espera'
          },
          tooltips: {
            totalTicketsSla:
              'Total de tickets aos quais respondi hoje dentro do SLA',
            totalAssigned: 'Total de tickets atribuídos a mim hoje',
            totalAnswered: 'Total de tickets que respondi hoje',
            totalOpened: 'Total de tickets abertos atribuídos a mim',
            limitTickets: 'Limite de tickets que você pode ter em espera',
            limitAssigned: 'Limite de tickets que você pode ter atribuído'
          },
          totalTickets: 'Total de tickets abertos'
        }
      },
      fallbackLocale: ['es', 'en', 'pt'],
      locale: user.profile.language || 'es',
      silentTranslationWarn: true
    })
  }

  getStore(user: User) {
    return new Vuex.Store({
      state: {
        timer: 30, // seconds
        interval: null,
        sla: {},
        filters: {
          dashboard: false,
          name: 'executive_help_desk_kpis',
          range: {
            from: moment().startOf('day').unix(),
            to: moment().unix()
          },
          extra_filters: {cmid: user.id}
        }
      },
      getters: {
        sla: state => state.sla,
        filters: state => state.filters
      },
      mutations: {
        MUTATE(state, {key, value}) {
          state[key] = value;
        },
        UPDATE_NOW(state) {
          state.filters.range.to = moment().unix();
        },
        SET_INTERVAL(state, interval) {
          state.interval = interval;
        },
        CLEAR_INTERVAL(state) {
          clearInterval(state.interval);
          state.interval = null;
        }
      },
      actions: {
        reset({commit, dispatch}, action) {
          commit('CLEAR_INTERVAL');
          if (action === 'update') {
            dispatch('getSla', true);
          }
        },
        startTimer({commit, dispatch, state}) {
          const interval = setInterval(
            () => dispatch('getSla'),
            state.timer * 1000
          );
          commit('SET_INTERVAL', interval);
        },
        reloadTimer({commit, dispatch}) {
          commit('CLEAR_INTERVAL');
          dispatch('startTimer');
        },
        getSla({commit, getters, dispatch}, reload = false) {
          commit('UPDATE_NOW');
          return this.AnalyticsService.getExecutivesKPI({
            ...getters.filters
          })
            .then(sla => {
              commit('MUTATE', {
                key: 'sla',
                value: sla
              });
              return sla;
            })
            .catch(() => {
              commit('CLEAR_INTERVAL');
            })
            .finally(() => {
              if (reload) {
                dispatch('reloadTimer');
              }
            });
        }
      }
    })
  }

  getVueInstance(user: User) {
    const slaAgentI18n = this.getI18n(user)
    const slaAgentStore = this.getStore(user)
    slaAgentStore.AnalyticsService = this.AnalyticsService
    return new Vue({
      el: document.getElementById('sla-agent'),
      store: slaAgentStore,
      i18n: slaAgentI18n,
      data: {ready: false, reload: this.reload},
      computed: {
        ...Vuex.mapGetters(['sla'])
      },
      created() {
        this.getSla(true).then(() => {
          this.ready = true;
        });
      },
      methods: {
        ...Vuex.mapActions(['getSla'])
      }
    });
  }

  $onChanges({reload = {}}) {
    if (this.vueInstance) {
      this.reload = reload.currentValue;
      this.vueInstance.reload = reload.currentValue;
    }
  }

  constructor($rootScope, UserService: UserService, AnalyticsService) {
    this.AnalyticsService = AnalyticsService
    UserService.getProfile().then(user => {
      this.vueInstance = this.getVueInstance(user)
    })
    $rootScope.$on('updateSla', (_event, action = 'update') => {
      this.updateData(action);
    })
  }
}

(module => {
  'use strict';
  module.component('slaAgentComponent', {
    bindings: {
      reload: '<'
    },
    controller: SlaAgentController
  });
})(angular.module('postCenterWebClientApp'));
