angular
  .module('postCenterWebClientApp')
  .component('adAssignmentDetailWrapper', {
    bindings: {
      details: '<',
      goBack: '&'
    },
    controller(
      $element,
      $filter,
      $rootScope,
      SocialAccountService,
      AdNotification,
      AdAvailableSn,
      UserService: UserService,
      AssignmentService
    ) {
      const vm = this;
      const translate = (property, params = {}) =>
        $filter('translate')(property, params);
      const {config: establishmentConfig} = $rootScope.establishment;
      const assignmentModes = [
        {
          id: 2,
          name: translate('ASSIGNMENTS_ASSIGNATION_MODE_AUTO'),
          makeAllAgents: translate('ASSIGNMENTS_MAKE_ALL_AUTO'),
          makeAllAccounts: translate('ASSIGNMENTS_SELECT_ALL_CHANNELS'),
          summary: translate('AGENT_AUTO_COUNT')
        },
        {
          id: 1,
          name: translate('ASSIGNMENTS_ASSIGNATION_MODE_MANUAL'),
          makeAllAgents: translate('ASSIGNMENTS_MAKE_ALL_ENABLED'),
          makeAllAccounts: translate('ASSIGNMENTS_SELECT_MANUAL_ALL_CHANNELS'),
          summary: translate('AGENT_MANUAL_COUNT')
        },
        {
          id: 0,
          name: translate('ASSIGNMENTS_ASSIGNATION_MODE_NO'),
          makeAllAgents: translate('ASSIGNMENTS_MAKE_ALL_NOT_ASSIGNABLE'),
          makeAllAccounts: translate('ASSIGNMENTS_DESELECT_ALL_CHANNELS'),
          summary: translate('AGENT_DISABLED_COUNT')
        }
      ];

      vm.$onInit = () => {
        UserService.getProfile().then(user => {
          vm.isSupervisorNotAdmin = user.isSupervisorNotAdmin;
          vm.details = {template: null};
          vm.updateVueInstance();
        })
      };

      vm.$onChanges = changes => {
        if (vm.vueInstance) {
          vm.details = changes.details.currentValue;
          vm.vueInstance.details = vm.details;
        }
      };
      const assignMessages = {
        en: {
          buttons: {
            save: 'Save',
            cancel: 'Cancel',
            legacy: 'Go back to previous design',
            saveAccountConfig: 'Save configuration',
            saveAgents: 'Update agents',
            continuesWithoutSave: 'Continue without saving',
            saveAndContinues: 'Save and continue'
          },
          agents: 'Agents',
          accounts: 'Accounts',
          agentsSummary: 'Agents assignment summary',
          enableAutoAssign: 'Enable account',
          enableAgent: 'Activated agent | Agent deactivated',
          defaultLimits: 'Use establishment default assignments limits',
          thisAgentOnly: '(for this agent only)',
          assignmentMode: 'Assignment mode',
          assignmentOptionMode: {
            defaultSetting: 'Use establishment setting',
            onlyFIFO: 'Only FIFO',
            onlyLIFO: 'Only LIFO'
          },
          openTickets: 'Open tickets',
          waitingAnswer: 'Tickets waiting for answer',
          lastAssignation: 'Last assignation: {total}',
          openCases: 'Open cases: {total}',
          unansweredCases: 'Unanswered cases: {total}',
          warningTitle: 'Configuration without saving',
          warningMessage:
            'You have made changes that have not been saved, you can Save and continue or continue without saving your changes.',
          release: {
            title: 'Manage accounts and agents',
            instructions: 'Choose an account or agent to see more information.',
            legacyCta:
              "Tap the button if you'd like to see the previous design."
          }
        },
        es: {
          buttons: {
            save: 'Guardar',
            cancel: 'Cancelar',
            legacy: 'Volver al diseño anterior',
            saveAccountConfig: 'Guardar configuración',
            saveAgents: 'Actualizar ejecutivos',
            continuesWithoutSave: 'Continuar sin guardar',
            saveAndContinues: 'Guardar y continuar'
          },
          agents: 'Ejecutivos',
          accounts: 'Cuentas',
          agentsSummary: 'Asignación de Ejecutivos en Cuenta',
          enableAutoAssign:
            'Autoasignación habilitada | Autoasignación deshabilitada',
          enableAgent: 'Ejecutivo activado | Ejecutivo desactivado',
          defaultLimits: 'Utilizar asignaciones por defecto',
          thisAgentOnly: '(sólo afectará a este Ejecutivo)',
          assignmentMode: 'Método de asignación',
          assignmentOptionMode: {
            defaultSetting: 'Usa config de la empresa',
            onlyFIFO: 'FIFO exclusivo',
            onlyLIFO: 'LIFO exclusivo'
          },
          openTickets: 'Tickets abiertos',
          waitingAnswer: 'Tickets que esperan respuesta',
          lastAssignation: 'Última asignación: {total}',
          openCases: 'Tickets abiertos: {total}',
          unansweredCases: 'Esperando respuesta: {total}',
          warningTitle: 'Configuracion sin guardar',
          warningMessage:
            'Has realizado cambios que no han sido guardados, puedes Guardar y continuar o continuar sin guardar tus cambios.',
          release: {
            title: 'Gestiona por cuenta y ejecutivo',
            instructions:
              'Selecciona un ejecutivo o una cuenta para ver más información.',
            legacyCta:
              'Da clic en el botón para regresar al antiguo diseño de asignaciones.'
          }
        },
        pt: {
          buttons: {
            save: 'Salvar',
            cancel: 'Cancelar',
            legacy: 'Retornar ao design anterior',
            saveAccountConfig: 'Salvar configurações',
            saveAgents: 'Salvar executivos',
            continuesWithoutSave: 'Continuar sem salvar',
            saveAndContinues: 'Salvar e continuar'
          },
          agents: 'Executivo',
          accounts: 'Contas',
          agentsSummary: 'Atribuição de Executivos de Contas',
          enableAutoAssign: 'Ativar conta | Desativar conta',
          enableAgent: 'Agente ativado | Agente desativado',
          defaultLimits: 'Editar atribuições máximas',
          thisAgentOnly: '(somente afetará a este Executivo)',
          assignmentMode: 'Método de atribuição',
          assignmentOptionMode: {
            defaultSetting: 'Use a configuração do estabelecimento',
            onlyFIFO: 'FIFO exclusivo',
            onlyLIFO: 'LIFO exclusivo'
          },
          openTickets: 'Tickets abertos',
          waitingAnswer: 'Tickets esperando por uma resposta',
          lastAssignation: 'Última tarefa: {total}',
          openCases: 'Tickets abertos: {total}',
          unansweredCases: 'Esperam resposta: {total}',
          warningMessage:
            'Você fez mudanças que não foram salvas, você pode Salvar e continuar ou continuar sem salvar suas mudanças.',
          warningTitle: 'Configuração sem salvar',
          release: {
            title: 'Gerenciar por conta e por executivo',
            instructions:
              'Selecione um executivo ou uma conta para ver mais informações.',
            legacyCta:
              'Clique no botão para retornar ao antigo layout de atribuição.'
          }
        }
      };

      const {
        profile: {language}
      } = $rootScope.user;
      vm.updateVueInstance = () => {
        // Init Vue instance
        vm.vueInstance = new Vue({
          el: $element[0].querySelector('.ng-non-bindable'),
          i18n: new VueI18n({
            locale: language || 'es',
            messages: assignMessages,
            fallbackLocale: ['es', 'en', 'pt'],
            silentTranslationWarn: true
          }),
          store: new Vuex.Store({
            state: {
              assignmentModes,
              lastDetails: {
                executive: null,
                channel: null,
                template: null
              },
              editMode: false,
              establishmentConfig,
              isSupervisorNotAdmin: vm.isSupervisorNotAdmin
            },
            getters: {
              editMode: state => state.editMode,
              lastDetails: state => state.lastDetails,
              assignmentModes: state => state.assignmentModes,
              establishmentConfig: state => state.establishmentConfig,
              isSupervisorNotAdmin: state => state.isSupervisorNotAdmin
            },
            mutations: {
              MUTATE(state, {property, value}) {
                state[property] = value;
              },
              UPDATE_AUTOASSIGN(state, payload) {
                state.lastDetails.channel.config.enable_autoassignation =
                  payload;
              },
              UPDATE_AGENTS(state, payload) {
                state.lastDetails.executives = payload;
              }
            },
            actions: {
              editingMode({commit}, payload) {
                commit('MUTATE', {property: 'editMode', value: payload});
              },
              updateAutoassign({commit}, payload) {
                commit('UPDATE_AUTOASSIGN', payload);
              },
              updateAgents({commit}, payload) {
                commit('UPDATE_AGENTS', payload);
              },
              goBack(_content, payload) {
                return vm.goBack(payload);
              },
              saveDetails({commit}, payload) {
                commit('MUTATE', {
                  property: 'lastDetails',
                  value: payload
                });
              },
              getSnTitle(_content, payload) {
                return AdAvailableSn.getTitle(payload);
              },
              saveConfig(_context, {id, config}) {
                return SocialAccountService.saveConfig(id, config);
              },
              async updateAccount({dispatch, commit, state}) {
                const action = 'edit_user_account_assignment';
                const {
                  executives,
                  channel: {id, config}
                } = state.lastDetails;
                try {
                  const preparedExecutives = executives.map(agent =>
                    AssignmentService.prepareExecutiveData(agent)
                  );

                  const updatedAccount = await SocialAccountService.saveConfig(
                    id,
                    config
                  );
                  const updatedExecutives =
                    await AssignmentService.saveAssignment(preparedExecutives);

                  if (updatedAccount && updatedExecutives) {
                    AdNotification.success(201, action);
                    commit('MUTATE', {property: 'editMode', value: false});
                    return action;
                  }
                } catch {
                  AdNotification.error(500, action);
                  throw new Error('Assignation account error');
                } finally {
                  dispatch('goBack', {category: 'account'});
                }
              },
              async saveUserAssignment({dispatch, commit}, agent) {
                const action = 'edit_user_account_assignment';
                try {
                  const {maximum_total_workload, maximum_unanswered_workload} =
                    agent.assignation_limit;
                  const newLimits = {
                    total_workload: maximum_total_workload,
                    unanswered_workload: maximum_unanswered_workload
                  };
                  await AssignmentService.saveExecutive(agent);
                  await AssignmentService.updateExecutiveLimits(
                    agent,
                    newLimits
                  );

                  AdNotification.success(201, action);
                  commit('MUTATE', {property: 'editMode', value: false});
                  return action;
                } catch (err) {
                  AdNotification.error(500, action);
                  throw new Error('Assignation agent error');
                } finally {
                  dispatch('goBack', {category: 'executive'});
                }
              }
            }
          }),
          data: {
            details: vm.details
          }
        });
      };
    }
  });
