(function(module) {
  'use strict';
  MassiveActionsCtrl.$inject = [
    '$scope',
    '$rootScope',
    '$filter',
    'FireTagService'
  ];
  function MassiveActionsCtrl($scope, $rootScope, $filter, FireTagService) {
    const vm = this;
    vm.$onInit = () => {
      FireTagService.setPageView({
        title: 'Administrar acciones masivas',
        path: `/${window.location.hash}`
      });
      $rootScope.adminTab.selected = 'massive-actions';
      $scope.availableOptions = [
        {
          name: vm.translate('MASSIVE_ACTION_RE_ASSIGN_TICKETS'),
          id: 'RE_ASSIGN_TICKETS'
        },
        {
          name: vm.translate('MASSIVE_ACTION_CLOSE_TICKETS'),
          id: 'CLOSE_TICKETS'
        }
      ];

      $scope.currentAction = {
        selection: undefined
      };
    };
    vm.translate = key => $filter('translate')(key);
  }
  module.controller('MassiveActionsCtrl', MassiveActionsCtrl);
})(angular.module('postCenterWebClientApp'));
