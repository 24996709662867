const TagsTeam = Vue.component('TagsTeam', {
  props: {
    isList: {
      type: Boolean
    },
    tags: {
      type: Array,
      required: true
    },
    tagsLimit: {
      type: Number,
      default: 3
    },
    textLimit: {
      type: Number,
      default: 10
    },
    separator: {
      type: String,
      default: ',',
      required: false
    }
  },
  methods: {
    truncateText(text, index) {
      const addComa =
        this.isList && index !== this.tags.length - 1 ? this.separator : '';
      if (text.length > this.textLimit) {
        return `${text.substring(0, this.textLimit)}...${addComa}`;
      } else {
        return `${text}${addComa}`;
      }
    }
  },
  template: `<div class="ads-tags-team" :class="isList ? 'as-list' : ''">
  <span
    v-for="(tag, index) in tags"
    v-if="tags.length > 0 && index < tagsLimit">
      {{ truncateText(tag, index) }}
  </span>
  <span v-if="tags.length > tagsLimit">
    +{{ tags.length - tagsLimit }}
  </span>
</div>`
});
