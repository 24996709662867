/* eslint-disable vue/no-mutating-props */
const CustomSelector = Vue.component('CustomSelector', {
  props: {
    optionsSelect: {
      type: Object,
      default: () => ({
        theme: 'interactive',
        tags: false,
        data: () => [],
        updateData: null,
        radio: false,
        allOption: false
      })
    },
    labels: {
      type: Object,
      default: () => ({
        select: 'Select',
        all: 'All selected',
        none: 'Nothing selected',
        some: 'Some items selected'
      })
    },
    title: {
      type: String,
      default: ''
    },
    tags: {
      type: Boolean
    },
    finder: {
      type: String,
      default: ''
    },
    toggleAll: {
      type: Boolean,
      default: true
    },
    filterData: {
      type: Array,
      default: () => []
    },
    changeData: {
      type: Function,
      default: () => {}
    },
    isRadio: {
      type: Boolean,
      default: false,
      required: false
    },
    isSimple: {
      type: Boolean,
      default: false,
      required: false
    },
    isList: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      name: '',
      toggleList: false,
      isChecked: this.toggleAll
    };
  },
  computed: {
    getSelectName() {
      return this.title
        ? this.$tc('select', 2, {title: this.title})
        : this.$tc('select', 1);
    },
    filterByInput() {
      return this.filterData.filter(value =>
        new RegExp(this.name, 'ig').test(value.name)
      );
    },
    activeTags() {
      return this.filterData.filter(value => value.active).map(tag => tag.name);
    },
    updateSelector() {
      const option = this.isChecked ? 1 : 0;
      return this.$tc('all', option);
    },
    isActive() {
      return this.toggleList ? 'active' : '';
    }
  },
  watch: {
    toggleAll(newval) {
      this.isChecked = newval;
    }
  },
  methods: {
    filterDataBy(elem) {
      if (this.isRadio) {
        this.filterData.map(data => {
          data.active = false;
        });
        this.filterData.find(data => data.id === elem.id).active = true;
        this.closeSelector();
      } else {
        const index = this.filterData.findIndex(data => data.id === elem.id);
        if (index !== -1) {
          this.filterData.splice(index, 1, elem);
        }
        this.checkList();
      }
      this.changeData();
    },
    checkList() {
      const inactive = this.filterData.find(elem => !elem.active);
      this.isChecked = inactive ? false : true;
    },
    toggleSelector(event) {
      if (event.target.id !== 'search-input') {
        this.toggleList = !this.toggleList;
      }
    },
    closeSelector() {
      this.toggleList = false;
    },
    allSelector() {
      this.filterData.map(elem => {
        elem.active = !this.isChecked;
      });
      this.changeData();
    }
  },
  i18n: {
    messages: {
      es: {
        all: 'Deseleccionar todo | Seleccionar todo',
        notFound: 'No Encontrado',
        select: 'Seleccionar | Seleccionar {title}',
        findBy: 'Buscar por {title}'
      },
      en: {
        all: 'Unselect all | Select all',
        notFound: 'Not Found',
        select: 'Select | Select {title}',
        findBy: 'Find by {title}'
      },
      pt: {
        all: 'Rejeitar tudo | Selecionar tudo',
        notFound: 'Nao encontrado',
        select: 'Selecionar | Selecionar {title}',
        findBy: 'Procurar {title}'
      }
    }
  },
  template: `<div class="ads-selector-wrapper" :class="isSimple ? 'simple' : ''">
    <div
      :class="[isRadio ? 'ads-selector radio-button': 'ads-selector']"
      @click="toggleSelector"
      v-click-outside="closeSelector">
      <div class="ads-selector__header d-flex" :class="[tags ? 'tags' : '', isActive]">
        <TagsTeam v-if="tags && activeTags.length > 0 && !isChecked" :tags="activeTags" :is-list="isList"/>
        <h5 class="bold d-inline" v-else>{{ getSelectName }}</h5>
        <span class="fa fa-chevron-down" />
      </div>
      <div v-show="toggleList" class="toggle-list">
        <label v-show="!isRadio">
          <input
          type="checkbox"
          v-model="isChecked"
          @click="allSelector"
          />
          <span class="mx-1" :class="!isChecked && activeTags.length > 0 ? 'middle-check' : '' ">{{ updateSelector }}</span>
        </label>
        <div class="p-1" v-if="filterData.length > 10 && finder">
          <input type="text"
          id="search-input"
          class="form-control mb-1"
          :placeholder="$t('findBy', {title:finder})"
          v-model="name" />
        </div>
        <p class="p-1" v-if="!filterByInput.length">{{ $t('notFound') }}</p>
        <ul v-else class="list-unstyled">
          <li v-for="(elem, index) in filterByInput" :key="elem.id">
            <label>
              <input
                :type="isRadio ? 'radio' : 'checkbox'"
                @change="filterDataBy(elem)"
                :name="isRadio? 'radio' : elem.id"
                v-model="elem.active"
              />
              <span class="mx-1">{{ elem.name }}</span>
            </label>
          </li>
        </ul>
      </div>
    </div>
  </div>
    `
});
