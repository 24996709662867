'use strict';

var app = angular.module('postCenterWebClientApp');
app.directive('businessTimeSelector', function () {
  return {
    templateUrl: 'blocks/analytics/views/directives/business_time_selector.html',
    restrict: 'E',
    controller: 'BusinessTimeSelectorCtrl',
    scope: {}
  }
});

app.controller('BusinessTimeSelectorCtrl', BusinessTimeSelectorCtrl);

BusinessTimeSelectorCtrl.$inject = [
  '$scope', '$rootScope', 'localStorageService'
];
function BusinessTimeSelectorCtrl($scope, $rootScope, localStorageService) {

  var storageBTKey = 'analytics:onlyBusinessTimeOn'
  $scope.onlyBusinessTimeOn = localStorageService.get(storageBTKey) || false;

  const onlyBusinessTimeOnWatcherOff = $scope.$watch('onlyBusinessTimeOn', function() {
    var emitKey = 'analytics:dashboard:changeBusinessTimeOn';
    $rootScope.$emit(emitKey, $scope.onlyBusinessTimeOn);
    localStorageService.set(storageBTKey, $scope.onlyBusinessTimeOn);
  });

  $scope.$on('$destroy', function () {
    onlyBusinessTimeOnWatcherOff();
  });

}

