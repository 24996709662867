store.registerModule('agents', {
  namespaced: true,
  state: () => ({
    cms: [],
    all: []
  }),
  getters: {
    cms: state => state.cms,
    all: state => state.all
  },
  mutations: {
    SET_AGENTS(state, payload) {
      state.cms = payload;
    },
    SET_USERS(state, payload) {
      state.all = payload;
    }
  },
  actions: {
    async getUsers({commit}) {
      try {
        const users = await this.UsersService.getUsers();
        commit('SET_USERS', users);
        return users;
      } catch (error) {
        return [];
      }
    },
    async getAgents({commit}) {
      try {
        const agents = await this.UsersService.getCms();
        commit('SET_AGENTS', agents);
        return agents;
      } catch (error) {
        return [];
      }
    }
  }
});
