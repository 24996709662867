'use strict';

angular
  .module('postCenterWebClientApp')
  .controller(
    'WhatsappInputCodeCtrl',
    (
      $scope,
      $rootScope,
      $uibModalInstance,
      AdNotification,
      whatsapp,
      whatsappState,
      SocialAccountService
    ) => {
      $scope.whatsappData = whatsapp || {};
      $scope.whatsappData.code = [];

      $scope.whatsappVerifyState = whatsappState || {};
      $scope.whatsappVerifyState.pendingRequest = false;

      function whatsappDataNormalize(whatsappData) {
        var data = angular.copy(whatsappData);
        data.cc = data.cc && data.cc.value ? data.cc.value : data.cc;
        data.mnc = data.mnc && data.mnc.value ? data.mnc.value : data.mnc;
        data.code = angular.isArray(data.code) ? data.code.join('') : data.code;

        return data;
      }

      function whatsappVerify(callback) {
        var data = angular.copy($scope.whatsappData);
        var state = $scope.whatsappVerifyState;

        if (!state.pendingRequest) {
          state.pendingRequest = true;
          data = whatsappDataNormalize(data);

          SocialAccountService.connect(data).then(
            response => {
              typeof callback === 'function' && callback(false, response);
              state.pendingRequest = false;
            },
            exception => {
              typeof callback === 'function' && callback(true, exception);
              state.pendingRequest = false;
            }
          );
        }
      }

      $scope.verify = function() {
        whatsappVerify((operationError, data) => {
          if (!operationError) {
            if (data.statuses['registration_status'] === 'ok') {
              AdNotification.success(201, 'whatsapp_account');
              $uibModalInstance.close();
            } else {
              var statuses = data.statuses;
              switch (statuses['registration_reason']) {
                case 'mismatch':
                  $scope.verifyResultStatus = 'MISMATCH';
                  break;
                case 'stale':
                  $scope.verifyResultStatus = 'STALE';
                  break;
                case 'guessed_too_fast':
                  $scope.verifyResultStatus = 'TOO_FAST';
                  break;
                default:
                  $scope.verifyResultStatus = '';
                  AdNotification.error(400, 'whatsapp_account');
                  break;
              }
            }
          } else {
            AdNotification.error(data, 'whatsapp_account');
          }
        });
      };

      $scope.retryIntent = function() {
        var state = angular.copy($scope.whatsappVerifyState);
        var eventData = {};
        state.result = 'fail';
        eventData.whatsapp = $scope.whatsappData;
        eventData.whatsappState = state;
        $rootScope.$emit('AddSocialAccount:backToRequestCode', eventData);
        $uibModalInstance.dismiss('cancel');
      };

      $scope.cancel = function() {
        $uibModalInstance.dismiss('cancel');
      };
    }
  );
