'use strict';


class TrialExpirationController {

    $scope
    $rootScope
    $timeout
    $document
    $location
    AuthService
    FRONTEND_VUE_URL
    $sce
    trialExpirationOff
  
    private addEventListeners() {
      this.trialExpirationOff = this.$rootScope.$on(
        'trialExpiration',
        () => {
          this.$scope.show = true
          this.$document.on('keydown', this.hotkey_handler)
        }
      );

      this.handleIframeMessage = this.handleIframeMessage.bind(this);
      window.addEventListener('message', this.handleIframeMessage);

      this.$scope.$on('$destroy', () => {
        this.trialExpirationOff();
        window.removeEventListener('message', this.handleIframeMessage);
      });
    }
  
    private hotkeys(event) {
      switch (event.keyCode) {
        case 27: // escape
          if (!this.$scope.expired) {
            this.$scope.$apply(this.close());
          }
          break;
      }
    }
  
    private close () {
      this.$scope.show = false;
      this.$document.off('keydown', this.hotkey_handler);
      this.$timeout(() => {
        this.$scope.data = {};
      }, 500);
    };

    private redirect(url: string) {
      window.location.href = url;
    }

    handleIframeMessage = ({data}: MessageEvent) => {
      if (data.type !== 'trialExpirationEvent') {
        return;
      }
      const {message} = data;
      if(message.action === 'close_window') {
        this.$scope.$apply(this.close());
      } else if (message.action === 'redirect') {
        this.redirect(message.url);
      }
    };

    private isTrialExpired(trialStatus: TrialStatus): boolean {
      const isNull = trialStatus.days_remaining === undefined || trialStatus.days_remaining === null;
      const isExpired = !isNull && trialStatus.days_remaining <= 0;
      return isExpired;
    }

  
    constructor(
      $scope,
      $rootScope,
      $timeout,
      $document,
      $location,
      $sce,
      AdRequest: AdRequestService,
      FRONTEND_VUE_URL,
      EstablishmentService: EstablishmentService
    ) {
        this.$scope = $scope
        this.$rootScope = $rootScope
        this.$document = $document
        this.$timeout = $timeout
        this.hotkey_handler = (event) => this.hotkeys(event)
        $scope.show = false;
        this.addEventListeners()
      
        $scope.close = () => this.close()
        $scope.ignoreHotkeys = function ($event) {
          $event.stopPropagation();
        };
        $scope.iFrameUrl = function () {
          const token = AdRequest.getToken()
          let section = $location.search().section;
          let url = `${FRONTEND_VUE_URL}/frames/trialexpirationsplash?authToken=${token}`
          if (section) {url += `&section=${section}`}
          return $sce.trustAsResourceUrl(url);
        }
        EstablishmentService.getTrialStatus().then(trialStatus => {
          this.$scope.expired = this.isTrialExpired(trialStatus);
        })
    }
  
}
  
angular
  .module('postCenterWebClientApp')
  .controller(
    'TrialExpirationModalCtrl',
    TrialExpirationController
  );
