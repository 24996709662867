const MonitoringKpi = Vue.component('monitoring-kpi', {
  data() {
    return {
      page: 1,
      loading: false,
      result: [],
      filters: {expression: ''},
      debounceSearch: debounce(this.search, 1000)
    };
  },
  methods: {
    ...Vuex.mapActions(['getExecutives', 'getKPI', 'setFilters']),
    filterExecutives() {
      const {filters, executives} = this;

      this.result = executives.filter(executive => {
        const nameIsOk = new RegExp(filters.name, 'ig').test(executive.name);
        return nameIsOk;
      });
    },
    resetFilters() {
      this.setFilters({filter: 'department', payload: []});
      this.departments.map(_dep => {
        _dep.active = true;
      });
      this.filters = {name: ''};
    },
    search() {
      const {filters, page} = this;
      this.loading = true;
      this.getKPI({...filters, page})
        .then(newPage => (this.page = newPage + 1))
        .catch(error => (this.page = null))
        .finally(() => (this.loading = false));
    },
    loadMoreExecutives() {
      if (!this.page) {
        return;
      }
      this.debounceSearch();
    }
  },
  computed: {
    ...Vuex.mapState(['kpiPerExecutive', 'executives']),
    ...Vuex.mapGetters({depaFilters: 'filters', departments: 'departments'}),
    departmentFilter() {
      this.filters = {...this.filters, ...this.depaFilters};
      this.debounceSearch();
    },
    headings() {
      return [
        'name',
        'opened_tickets',
        'assigned_tickets',
        'ignored_tickets',
        'closed_tickets',
        'addressed_tickets',
        'answered_tickets',
        'classified_tickets',
        'classified_tickets_opened',
        'classified_tickets_closed'
      ].map(heading => ({
        label: this.$t(`kpi.${heading}`),
        field: heading,
        component: 'span'
      }));
    },
    filteredExecutives: {
      get() {
        const noKPIs = !Object.keys(this.kpiPerExecutive).length;

        if (noKPIs) {
          return null;
        }

        return this.result
          .filter(({cmid}) => this.kpiPerExecutive[cmid])
          .map(executive => {
            // Match executives with their KPIs
            const withKPIs = this.kpiPerExecutive[executive.cmid];
            return {...executive, ...withKPIs};
          });
      },
      set(executives = []) {
        this.result = executives;
      }
    }
  },
  watch: {
    kpiPerExecutive() {
      this.filterExecutives();
    },
    filters: {
      deep: true,
      handler() {
        this.page = 1;
        this.debounceSearch();
      }
    }
  },
  created() {
    this.getExecutives().then(() => this.debounceSearch());
  },
  template: `
    <section class="card">
      <header>
        <h4 class="bold">
          {{ $t('kpi.header') }}
          <small v-if="filteredExecutives?.length">
            {{
              $tc('kpi.viewing', filteredExecutives.length, {
                count: filteredExecutives.length,
                total: executives.length
              })
            }}
          </small>
        </h4>
      </header>

      <template v-if="filteredExecutives">
        <form class="row">
          <div class="col-md-3">
            <div class="input-group">
              <input
                type="text"
                :placeholder="$t('kpi.searchByName')"
                v-model="filters.expression"
                maxlength="40"
                class="form-control"
              />
              <div class="input-group-btn">
                <button type="button" class="btn btn-default">
                  <span class="fa fa-search"></span>
                </button>
              </div>
            </div>
          </div>
          <div class="col-md-2">
            <DepartmentMonitoring :onChange="departmentFilter"  />
          </div>
        </form>

        <Table v-show="filteredExecutives.length"
               class="monitoring--kpi-table text-center"
               :headings="headings"
               :rows="filteredExecutives"
               v-infinite-scroll="loadMoreExecutives" />

        <p v-show="!filteredExecutives.length" class="pt-2">
          {{ $tc('kpi.viewing', 0) }}.
          <strong class="text-info clickable"
                  @click="resetFilters()">{{ $t('kpi.clear') }}</strong>
        </p>
      </template>

      <div v-if="!filteredExecutives || loading"
           class="text-center">
        <Loader size="2x" />
      </div>
    </section>
  `
});
