'use strict';

angular.module('postCenterWebClientApp')
  .directive('adReplyAccountSelectorInput', function(){
    return {
      bindToController: {
        'parent': '=adReplyAccountSelectorInput'
      },
      controllerAs: 'inputCtrl',
      controller: function(){},
      link: function(scope, elem, attr, controller){
        controller.parent.setInputElem(elem);
      }
    }
  });
