'use strict';

var app = angular.module('postCenterWebClientApp');
app.controller(
  'UserEditCtrl',
  function (
    $scope,
    $rootScope,
    $timeout,
    UserService: UserService,
    EstablishmentService: EstablishmentService,
    AdNotification,
    InitialData,
    $transition$
  ) {
    $scope.refreshDataCount = 0;
    $scope.users = angular.copy($rootScope.users);
    $scope.departments = angular.copy($rootScope.departments);
    $scope.groups = angular.copy($rootScope.groups);
    $scope.checkPassword = false;
    UserService.getProfile().then(user => ($scope.myself = user));
    EstablishmentService.getEstablishment().then(
      e => ($scope.establishment = e)
    );
    $scope.user.autoassign = false;
    $scope.promiseCheckEmail = undefined;
    $scope.userRestoreWarning = false;

    $scope.checkEmailAvailability = function () {
      if ($scope.promiseCheckEmail) {
        $timeout.cancel($scope.promiseCheckEmail);
      }

      if ($scope.user.email) {
        $scope.promiseCheckEmail = UserService.checkEmailAvailability(
          $scope.user.email
        );
        $scope.promiseCheckEmail.then(
          function (data) {
            var available = data['is_available'];
            var exists = data['exists'];
            $scope.myform.userRestoreWarning = false;
            if (available) {
              $scope.myform.email.$setValidity('availability', true);
              if (exists) {
                $scope.myform.userRestoreWarning = true;
              }
            } else {
              $scope.myform.email.$setValidity('availability', false);
            }
          },
          function () {
            $scope.myform.email.$setValidity('availability', false);
          }
        );
      }
    };

    $scope.addUser = function () {
      $scope.action = 'Add';
      $scope.user = {};
      $scope.checkPassword = true;
      angular.forEach($scope.groups, function (group) {
        group.selected = false;
      });
      $scope.user.profile = {
        integration_data: {}
      };
    };

    var initializeIntegrationData = function (user) {
      if (user.profile.integration_data == undefined) {
        user.profile.integration_data = {};
      }

      var data = user.profile.integration_data;
      $scope.establishment.config.user_integration_data.forEach(function (v) {
        if (data[v.key] == undefined) {
          data[v.key] = '';
        }
      });

      user.profile.integration_data = data;
    };

    $scope.editUser = function (user) {
      $scope.action = 'Edit';
      $scope.user = angular.copy(user);
      initializeIntegrationData($scope.user);
      angular.forEach($scope.user.groups, function (userGroup) {
        angular.forEach($scope.groups, function (group) {
          if (userGroup.id === group.id) {
            group.selected = true;
            $scope.atLeastOneGroupCheck = true;
          }
        });
      });
      if ($scope.user.profile.department) {
        angular.forEach($scope.departments, function (department) {
          if (department.id === $scope.user.profile.department.id) {
            $scope.user.profile.department = department;
          }
        });
      }
    };

    $scope.confirmAddEditUser = function () {
      if ($scope.action === 'Add') {
        $scope.confirmAddUser();
      } else if ($scope.action === 'Edit') {
        $scope.confirmEditUser();
      }
    };

    $scope.confirmAddUser = function () {
      var groupList = [];
      angular.forEach($scope.groups, function (group) {
        if (group.selected) {
          groupList.push(group);
        }
      });
      if ($scope.user.password) {
        delete $scope.user.repassword;
      }
      $scope.user.groups = groupList;

      var nUsers;

      if ($scope.user.profile.department) {
        nUsers = $scope.user.profile.department['n_users'];
        delete $scope.user.profile.department['n_users'];
      }

      if ($scope.user.autoassign === undefined) {
        $scope.user.autoassign = false;
      }

      var action = 'add_user';
      UserService.addUser($scope.user).then(
        function () {
          refreshData();
          AdNotification.success(201, action);
          $rootScope.goToParentState();
        },
        function (data) {
          if (nUsers !== undefined) {
            $scope.user.profile.department['n_users'] = nUsers;
          }
          AdNotification.error(data, action);
        }
      );
    };

    $scope.checkCheckbox = function () {
      $scope.atLeastOneGroupCheck = false;
      $scope.checkboxGroup = {dirty: true};
      angular.forEach($scope.groups, function (group) {
        if (group.selected) {
          $scope.atLeastOneGroupCheck = true;
        }
      });
    };

    $scope.confirmEditUser = function () {
      var groupList = [];
      angular.forEach($scope.groups, function (group) {
        if (group.selected) {
          groupList.push(group);
        }
      });
      $scope.user.groups = groupList;
      if ($scope.user.password) {
        delete $scope.user.repassword;
      }
      var nUsers;
      if ($scope.user.profile.department) {
        nUsers = $scope.user.profile.department['n_users'];
        delete $scope.user.profile.department['n_users'];
      }
      var action = 'edit_user';
      UserService.editUser($scope.user).then(
        function () {
          refreshData();
          AdNotification.success(201, action);
          $rootScope.goToParentState();
        },
        function (data) {
          if (nUsers !== undefined) {
            $scope.user.profile.department['n_users'] = nUsers;
          }
          AdNotification.error(data, action);
        }
      );
    };

    function refreshData() {
      InitialData.refreshUsersDepartments().then(function () {
        $scope.departments = angular.copy($rootScope.departments);
        $scope.users = angular.copy($rootScope.communityManagers);
      });
    }

    if ($transition$.params().id) {
      var userToEdit = _.find($scope.users, function (user) {
        return user.id.toString() === $transition$.params().id;
      });
      if (userToEdit) {
        $scope.editUser(userToEdit);
      }
    } else {
      $scope.addUser();
    }
  }
);
