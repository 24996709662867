const HistoryView = Vue.component('HistoryView', {
  computed: {
    ...Vuex.mapGetters(['locale'])
  },
  template: `
    <div class="history">
      <SectionHeader
        :title="$t('title')"
        icon="clock"
        support-section="anal%C3%ADtica/89RzusWYBEWNTBJg2WFu5G/historial-parte-1/hwVux8dFJZhKQngZtBdtsP">
      </SectionHeader>
      <history-tabs />
    </div>
  `
});
