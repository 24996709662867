const EmptyResults = Vue.component('EmptyResults', {
  props: {
    image: {
      type: String,
      default: 'images/epa_empty.svg'
    }
  },
  i18n: {
    messages: {
      es: {
        message:
          '<strong>Sin datos.</strong> no hay datos registrados con los parámetros seleccionados.'
      },
      pt: {
        message:
          '<strong>Sem dados.</strong> Não há dados registrados com os parâmetros selecionados.'
      },
      en: {
        message:
          '<strong>No data.</strong> There is no data recorded with the selected parameters.'
      }
    }
  },
  template: `<div class="grid-flex basic-card ads-empty-results">
      <div class="flex-col-3"></div>
      <div class="flex-col-4 empty-content">
        <span v-html="$t('message')"></span>
        <img :src="image" class="mt-2" width="70%" />
      </div>
      <div class="flex-col-3"></div>
    </div>`
});
