'use strict';

angular.module('postCenterWebClientApp')
.directive('metricChartColumn', ['$timeout', '$log', 'AnalyticsHelper', 'AnalyticsConfigs',
  function($timeout, $log, AnalyticsHelper, AnalyticsConfigs) {
    return {
      scope : {
        sample: '=',
        widget: '='
      },
      templateUrl: 'blocks/analytics/views/metric_chart_column.html',
      restrict: 'AE',
      controller: ['$scope', '$rootScope', '$filter',
        function ($scope, $rootScope, $filter) {

          $scope.bubbleMetric = null;

          $scope.state = {
            flags: {
              availableContent: function() {
                var chartReady = $scope.chart && $scope.chart.config;
                var columnReady = $scope.column && $scope.column.config;
                return chartReady && columnReady;
              },
              invalidContent: false
            }
          };

          function updateBubbleMetric() {

            var metricScheme = _.findWhere(
              $scope.widget.dataScheme.metrics,
              { name: 'detail' }
            );

            var metricDataList = AnalyticsHelper.getBubbleMetricDataList(
              $scope.sample, metricScheme, null
            );
            if (metricDataList.length <= 0) {
              $log.warn("bubble chart widget, no metrics found");
              return;
            }

            var metricData = metricDataList[0];
            var valueType  = $scope.widget.dataScheme.type;
            $scope.bubbleMetric = {
              title: metricData.name,
              value: AnalyticsHelper.formatValue(valueType, metricData.value),
              variation: metricData.variation,
              data: {
                key: metricData.id,
                value: metricData.value,
                index: metricData.index
              }
            };
          }

          var Column = {

            create: function() {
              return {
                api: null,
                state: {}
              };
            },

            getConfigObj: function(columnReference) {
              var widget = $scope.widget;
              var widgetColumnConfig = $scope.widget.charts.main;
              return AnalyticsConfigs.getChartConfig(
                widget, widgetColumnConfig, columnReference
              );
            },

            update: function() {
              var waitIntents = 0;

              //wait max 320ms to get Highcharts API (20 times * 16ms timeout);
              //some weird bug displaying the graph? try increasing time waiting for,
              //but remember, 400ms and above makes user to loose the sensation of "instantability"
              var maxWaitIntents = 40;

              waitForColumnAPI();

              function waitForColumnAPI() {
                $timeout(function() {
                  if ($scope.column.api !== null) {
                    updateColumn();
                  } else {
                    waitIntents++;
                    if (waitIntents < maxWaitIntents) {
                      waitForColumnAPI();
                    } else {
                      $log.warn('max waiting time for chart API reached. Aborting chart update');
                    }
                  }
                }, 16);
              }

              function updateColumn() {
                var metricScheme = _.findWhere(
                  $scope.widget.dataScheme.metrics,
                  { name: 'total' }
                );
                var series = AnalyticsHelper.getSeries($scope.sample, metricScheme, $scope.parentMetric);
                if (series && series.length) {
                  addSeries(series);
                } else {
                  $scope.state.flags.invalidContent = true;
                }

                function addSeries(series) {
                  angular.forEach(series, function(serie, index) {
                    var serieObj = {};

                    //add new serie if needed
                    if (index >= $scope.column.api.series.length) {
                      $scope.column.config.series[index] = {};
                    }
                    serieObj = $scope.column.config.series[index];
                    for (var attrname in serie) {
                      serieObj[attrname] = serie[attrname];
                    }
                  });

                  angular.forEach(series, function(serie, index) {
                    var serieObj = {};
                    //add new serie if needed
                    if (index >= $scope.column.api.series.length) {
                      $scope.column.config.series[index] = {};
                    }
                    serieObj = $scope.column.config.series[index];
                    for (var attrname in serie) {
                      serieObj[attrname] = serie[attrname];
                    }
                  });
                  //remove series that we dont need
                  if ($scope.column.api.series.length > series.length) {
                    var seriesToRemove = $scope.column.api.series.length - series.length;
                    $scope.column.config.series.splice(series.length, seriesToRemove);
                  }
                }
              }
            },

            clearSerie: function(serie) {
              serie.data = [];
            },

            addToSerie: function(serie, data) {
              if (data.y > 0) {
                serie.data.push(data);
              }
            }
          };

          var Chart = {

            create: function() {
              return {
                api: null,
                state: {}
              };
            },

            getConfigObj: function(chartReference) {
              var widget = $scope.widget;
              var widgetChartConfig = widget.charts.detail;
              return AnalyticsConfigs.getChartConfig(widget, widgetChartConfig, chartReference);
            },

            update: function() {
              var waitIntents = 0;

              //wait max 320ms to get Highcharts API (20 times * 16ms timeout);
              //some weird bug displaying the graph? try increasing time waiting for,
              //but remember, 400ms and above makes user to loose the sensation of "instantability"
              var maxWaitIntents = 40;

              waitForChartAPI();

              function waitForChartAPI() {
                $timeout(function() {
                  if ($scope.chart.api !== null) {
                    updateChart();
                  } else {
                    waitIntents++;
                    if (waitIntents < maxWaitIntents) {
                      waitForChartAPI();
                    } else {
                      $log.warn('max waiting time for chart API reached. Aborting chart update');
                    }
                  }
                }, 16);
              }

              function updateChart() {
                var metricScheme = _.findWhere(
                  $scope.widget.dataScheme.metrics,
                  { name: 'detail' }
                );
                var series = AnalyticsHelper.getSeries($scope.sample, metricScheme, $scope.parentMetric);
                if (series && series.length) {
                  addSeries(series);
                } else {
                  $scope.state.flags.invalidContent = true;
                }

                function addSeries(series) {
                  angular.forEach(series, function(serie, index) {
                    var serieObj = {};

                    //add new serie if needed
                    if (index >= $scope.chart.api.series.length) {
                      $scope.chart.config.series[index] = {};
                    }
                    serieObj = $scope.chart.config.series[index];
                    for (var attrname in serie) {
                      serieObj[attrname] = serie[attrname];
                    }
                  });

                  //remove series that we dont need
                  if ($scope.chart.api.series.length > series.length) {
                    var seriesToRemove = $scope.chart.api.series.length - series.length;
                    $scope.chart.config.series.splice(series.length, seriesToRemove);
                  }
                }
              }
            },

            clearSerie: function(serie) {
              serie.data = [];
            },

            addToSerie: function(serie, data) {
              if (data.y > 0) {
                serie.data.push(data);
              }
            }
          };

          $scope.chart = Chart.create();
          $scope.chart.config = Chart.getConfigObj($scope.chart);
          Chart.update();
          $scope.column = Column.create();
          $scope.column.config = Column.getConfigObj($scope.column);
          Column.update();

          const watchBubbleMetricOff = $scope.$watch('bubbleMetric', function(bubbleMetric) {
            Chart.update();
            Column.update();
          });

          const watchSampleCollectionOff = $scope.$watchCollection('sample', function(oldSample, newSample) {
              updateBubbleMetric();
          });

          const analyticsForceUpdateOff = $scope.$on('analytics:forceUpdate', function(){
            updateBubbleMetric();
          });

          $scope.$on('$destroy', function () {
            analyticsForceUpdateOff();
            watchSampleCollectionOff();
            watchBubbleMetricOff();
          });
        }
      ],
      link: function($scope, $element) {}
    };
  }
]);
