const AgentsFilter = Vue.component('AgentsFilter', {
  data() {
    return {
      hiddenAgents: []
    };
  },
  computed: {
    ...Vuex.mapGetters(['agents', 'filterBy', 'supervisorWithTeam'])
  },
  created() {
    this.hiddenAgents = this.filterBy('cmid');
  },
  methods: {
    ...Vuex.mapActions(['setFilters']),
    filterAgents() {
      let filteredAgents = this.agents
        .filter(agent => !agent.active)
        .map(_agent => _agent.id);

      if (this.supervisorWithTeam) {
        filteredAgents = Array.from(
          new Set([...this.hiddenAgents, ...filteredAgents])
        );
      }
      this.setFilters({
        filter: 'cmid',
        payload: filteredAgents
      });
    }
  },
  i18n: {
    messages: {
      es: {
        agents: 'Ejecutivo | Ejecutivos'
      },
      pt: {
        agents: 'Executivo | Executivos'
      },
      en: {
        agents: 'Agent | Agents'
      }
    }
  },
  template: `
    <CustomSelector
      :filter-data="agents"
      :change-data="filterAgents"
      :title="$tc('agents', 2)"
      :finder="$tc('agents', 1)"
    />`
});
