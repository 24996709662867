'use strict';

angular
  .module('postCenterWebClientApp')
  .directive('kpiOnlineStatus', function () {
    return {
      scope: {
        period: '=',
        icon: '@'
      },
      templateUrl: 'blocks/analytics/views/kpi_online_status.html',
      restrict: 'E',
      controller: [
        '$rootScope',
        '$scope',
        '$q',
        '$filter',
        '$uibModal',
        'InitialData',
        'AnalyticsDashboard',
        'CustomOnlineStatuses',
        'UserService',
        'UserFilter',
        'adExportToCsv',
        'FireTagService',
        kpiOnlineStatusController
      ],
      controllerAs: 'vm'
    };
  });

function kpiOnlineStatusController(
  $rootScope,
  $scope,
  $q,
  $filter,
  $uibModal,
  InitialData,
  AnalyticsDashboard,
  CustomOnlineStatuses,
  UserService: UserService,
  UserFilter,
  adExportToCsv,
  FireTagService
) {
  var cms = {};
  var loadingLock = false;

  var vm = this;

  vm.executiveCOSOpen = true;
  vm.loading = true;
  vm.rows = [];
  vm.statuses = [];
  vm.openHelpModal = openHelpModal;

  vm.exportOnlineStatus = () => {
    const title = $filter('translate')('COS_KPI_TABLE_TITLE');
    const name = $filter('translate')('COS_KPI_TABLE_COL_NAME');
    const status = $filter('translate')('COS_KPI_TABLE_COL_CURRENT');
    const headers = [];
    headers.push(name);
    headers.push(status);

    vm.statuses.map(status => {
      headers.push(status.label);
    });
    const data = [];
    vm.rows.map(row => {
      let newUser = {};
      row.map((userData, i) => {
        if (i === 0) {
          newUser[headers[0]] = userData;
        }
        if (i === 1) {
          newUser[headers[1]] = userData ? userData.label : '-';
        }
        if (i === 2) {
          newUser[headers[1]] = userData
            ? `${newUser[headers[1]]} ${$filter('formatSeconds')(
                userData.seconds,
                true
              )}`
            : '-';
        }
        if (i === 3) {
          newUser[headers[2]] = userData.seconds;
        }
        if (i > 3 && userData) {
          newUser[headers[i - 1]] = userData.seconds;
        }
      });
      data.push(newUser);
    });
    adExportToCsv.exportToCsv(title, data);
    FireTagService.setEvent({
      name: 'exportacion_general',
      params: {
        export_name: title
      }
    });
  };

  $scope.$on('$destroy', function () {
    $scope.watchCollectionOff();
    $scope.watchPeriodOff();
    $scope.currentUserStatusOff();
    $scope.tabSelectedOff();
  });

  UserFilter.updateDepartmentsOptions(vm).then(results => {
    const currentUser = results[0]
    const departments = results[1]
    vm.filterParams = {
      name: '',
      department: UserFilter.getDefaultDepartment(currentUser, departments)
    }
    $scope.watchCollectionOff = $scope.$watchCollection('vm.filterParams', function () {
      refreshStatuses(true)
    })
  })

  $q.all([InitialData.getCms(), CustomOnlineStatuses.fetch()]).then(
    function () {
      cms = _.indexBy(angular.copy($rootScope.communityManagers), 'cmid');
      vm.statuses = _.sortBy(CustomOnlineStatuses.list(), 'index');

      $scope.watchPeriodOff = $scope.$watch('period', function () {
        refreshStatuses(true);
      });
      $scope.currentUserStatusOff = $scope.$watch(
        function () {
          return UserService.currentUserStatus.custom_online_status;
        },
        function () {
          refreshStatuses(true);
        },
        true
      );

      $scope.tabSelectedOff = $scope.$on('analytic:dashboard:tabSelected', function (e, selectedTab) {
        if (selectedTab === 'executives') {
          refreshStatuses(false);
        }
      });
    }
  );

  function refreshStatuses(softLoading) {
    if (loadingLock === true) {
      return; // avoid multiple refresh actions
    }

    loadingLock = true;

    if (softLoading) {
      vm.softLoading = true;
    } else {
      vm.loading = true;
    }

    // period could change after banckend call,
    // we want to use the same period until the end.
    var period = $scope.period;

    var cosPromises = AnalyticsDashboard.executiveKPI(
      'online_statuses',
      period.now,
      period.until
    );
    var currentCOSPromises = AnalyticsDashboard.executiveKPI(
      'current_online_status',
      period.now,
      period.until
    );

    return $q
      .all([cosPromises, currentCOSPromises])
      .then(function (results) {
        var coses = results[0];
        var currentCOSes = results[1];
        vm.rows = buildTableRows(coses, currentCOSes, period);
        vm.loading = false;
        vm.softLoading = false;
        loadingLock = false;
      })
      .catch(function (error) {
        vm.loading = false;
        vm.softLoading = false;
        loadingLock = false;
      });
  }

  function truncate(word, max_length) {
    if (word.length <= max_length) {
      return word;
    }
    var subString = word.substr(0, max_length - 1);
    return subString + '...';
  }

  function buildTableRows(onlineStatuses, currentOnlineStatuses, period) {
    var statusesIndex = _.indexBy(vm.statuses, 'resource_id');

    var preFilteredUsers = UserFilter.getFilteredUsers(
      vm.filterParams.name,
      undefined,
      vm.filterParams.department
    );
    var filteredCms = _.filter(cms, function (user) {
      var userId = user.cmid;
      for (var index = 0; index < preFilteredUsers.length; index++) {
        if (preFilteredUsers[index].id === userId) {
          return true;
        }
      }
      return false;
    });

    var tableRows = filteredCms.map(cmRow).filter(r => r != null);
    tableRows.push(totalRow(tableRows));

    return decorateRows(tableRows);

    function cmRow(cm) {
      function fillCurrentStatus(row, cm) {
        var currentStatus = currentOnlineStatuses[cm.cmid];
        if (!currentStatus) {
          return null;
        }
        // Get minimum Online status, this fixes issue with backend where
        // Backend sends Many "last status" (which is wrong)
        // So we pick the lower value.
        var key = Object.keys(currentStatus).reduce(function (key, v) {
          return currentStatus[v] < currentStatus[key] ? v : key;
        });
        row[1] = key ? statusesIndex[key] : null;
        row[2] = key ? currentStatus[key] : null;
        return row;
      }

      function fillStatuses(row, cm) {
        var cmStatuses = onlineStatuses[cm.cmid] || {};
        _.each(vm.statuses, function (status, idx) {
          row[3 + idx] = cmStatuses[status.resource_id] || 0;
        });
        return row;
      }

      var name = truncate(cm.name, 15);
      var currentStatus = fillCurrentStatus([name], cm);
      if (currentStatus) {
        return fillStatuses(currentStatus, cm);
      } else {
        return null;
      }
    }

    function totalRow(tableRows) {
      var totalRow = ['Total', null, null];
      _.each(tableRows, function (row) {
        for (var i = 3; i < row.length; i++) {
          totalRow[i] = (totalRow[i] || 0) + row[i];
        }
      });
      return totalRow;
    }

    function decorateRows(rows) {
      var deltaDuration = moment.duration(period.now - period.until);
      var deltaSeconds = parseInt(deltaDuration.asSeconds());
      for (var i = 0; i < rows.length; i++) {
        for (var j = 2; j < rows[i].length; j++) {
          var seconds = rows[i][j];
          if (angular.isNumber(seconds)) {
            seconds = Math.round(seconds);
            var percentage = (seconds / deltaSeconds) * 100.0;
            var isTotalRow = i === rows.length - 1;
            if (isTotalRow) {
              var numberOfCms = i;
              if (numberOfCms > 0) {
                percentage = percentage / numberOfCms;
              }
            }
            rows[i][j] = {
              seconds: seconds,
              percentage: percentage
            };
          } else {
            rows[i][j] = null;
          }
        }
      }
      return rows;
    }
  }

  function openHelpModal() {
    $uibModal.open({
      templateUrl: 'blocks/analytics/views/dashboard_help_modal.html',
      size: 'sm',
      controller: ['$scope', '$uibModalInstance', '$filter', modalController]
    });

    function modalController($scope, $uibModalInstance, $filter) {
      $scope.title = 'COS_KPI_TABLE_TITLE';
      $scope.helpText = $filter('translate')('COS_KPI_TABLE_HELP');
      $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
      };
    }
  }
}
