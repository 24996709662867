'use strict';

const VU3_LOGIN_PATH = '/login';

class LoginCtrl {
  username = '';
  password = '';
  loginReady = false;
  loaded = false;
  styles: Styles;
  hasPartnerStyle: boolean;
  ssoConfigs: any = {};
  captchaSessionToken;
  captchaSiteToken;
  ssoLoginFlag;
  emailPasswordLogin: boolean = true;
  ssoLogin: boolean = false;

  AdNotification: AdNotification;
  adDataValidator;
  AuthService: AuthService;
  FireTagService;
  StylesService;
  VersionService;
  $filter;
  $scope;

  $onInit() {
    Promise.all([
      this.VersionService.getVersion(),
      this.StylesService.getStyles(),
      this.getSsoConfigsFromFirebase()
    ]).then(([version, styles, ssoConfigs]) => {
      this.$scope.$apply(() => {
        this.styles = styles;
        this.hasPartnerStyle = styles.logo !== undefined;
        this.$scope.version = version;
        this.loaded = true;
      })
      const token = this.checkForTokenInUrl();
      if (token) {
        console.log('login with url token')
        this.attemptLoginWithToken(token);
      } else {
        this.FireTagService.setPageView({
          title: 'Login',
          path: `/${window.location.hash}`
        });
        this.getRecaptcha();
      }
    });
  }

  $onDestroy() {
    this.destroyWatchGroup();
  }

  loadRecaptcha() {
    const promise = new Promise(resolve => {
      resolve(window['grecaptcha'].execute());
    });
    promise
      .then(() => {})
      .catch(() => {
        // getRecaptcha again
        this.getRecaptcha();
      });
  }

  getRecaptcha() {
    if (window.grecaptcha) {
      grecaptcha.ready(() => {
        try {
          grecaptcha.render(document.getElementById('loginRecaptcha'), {
            sitekey: this.captchaSiteToken,
            callback: token => {
              this.setCaptcha(token);
            },
            'expired-callback': () => {
              this.setCaptcha(null);
            },
            'error-callback': () => {
              this.AdNotification.notify(
                this.$filter('translate')('LOGIN_NETWORK_ISSUE'),
                'error'
              );
            }
          });
        } catch (error) {
          // cloned elements
        }
      });
    }
  }

  setCaptcha(token) {
    this.captchaSessionToken = token;
    if (this.username && this.password && token) {
      this.loginReady = true;
    } else {
      this.loginReady = false;
    }
    this.$scope.$applyAsync();
  }

  isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  toggleLoginMode() {
    this.$scope.emailPasswordLogin = !this.$scope.emailPasswordLogin;
    this.$scope.ssoLogin = !this.$scope.ssoLogin;
    this.$scope.$applyAsync();
  }

  checkEmailIsSsoCapable() {
    if (this.validEmail()) {
      let emailDomain = this.username.split("@")[1];
      if (emailDomain in this.ssoConfigs) {
        return true;
      }
    }
    return false;
  }

  validEmail() {
    return this.adDataValidator.checkEmail(this.username);
  }

  getSsoConfigsFromFirebase(): Promise<any> {
    var ssoConfigsRef = firebase.database().ref('/ssoConfigs');
    return ssoConfigsRef.once('value').then((snapshot) => {
      Object.entries(snapshot.val()).forEach(([key, config]) => {
        this.ssoConfigs[config.emailDomain] = config.samlKey;
      });
      return this.ssoConfigs;
    });
  };

  startSsoLoginFlow() {
    let emailDomain = this.username.split("@")[1];
    const provider = new firebase.auth.SAMLAuthProvider(
      this.ssoConfigs[emailDomain]
    );
    firebase.auth().signInWithPopup(provider).then(response => {
      response = JSON.parse(JSON.stringify(response));
      let email = response.user.email;
      let token = response.user.stsTokenManager.accessToken;
      this.AuthService.firebaseLogin(email, token).catch(error => {
        this.AdNotification.error(error, 'sso_login');
      })
    }).catch(error => {
      this.AdNotification.error({}, 'sso_login');
    });
  }

  loginFirebaseActDirect() {
    this.$scope.loadingActiveDirectory = true;
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        user.getIdToken().then(token => {
          this.AuthService.activeDirectoryLogin(token)
            .then(data => {
              console.log(data);
            })
            .catch(error => {
              console.log(!Error, error);
            });
        });
      }
    });

    const provider = new firebase.auth.OAuthProvider('microsoft.com');
    provider.setCustomParameters({prompt: 'consent'});
    firebase
      .auth()
      .signInWithPopup(provider)
      .catch(error => {
        this.failLogin(error);
      });
  }

  checkForTokenInUrl() {
    const location = window.location.href.replace(/\/#\//, '/');
    const url = new URL(location);
    let token = url.searchParams.get('token');
    return token;
  }

  attemptLoginWithToken(token) {
    this.AuthService.loginWithToken(token).catch(err =>
      this.failLogin(err, true)
    );
  }

  loginPostcenter() {
    this.$scope.loading = true
    this.FireTagService.setEvent({
      name: 'click_login',
      params: {"username": this.username}
    });
    this.AuthService.login(
      this.username, this.password, this.captchaSessionToken
    ).then(token => {
      this.FireTagService.setEvent({
        name: 'login_success',
        params: {"username": this.username}
      })
    }).catch(error => {
      this.failLogin(error);
      console.log(error);
      this.FireTagService.setEvent({
        name: 'login_error',
        params: {"username": this.username}
      })
    })
  }

  failLogin(data, external = false) {
    this.$scope.loading = false;
    this.AdNotification.error(data, external ? 'external_signup' : 'login');
  }

  constructor(
    $window,
    $scope,
    $filter,
    AuthService,
    AdNotification,
    adDataValidator,
    adChatService,
    FireTagService,
    RECAPTCHA_CHECKBOX_TOKEN,
    StylesService,
    VersionService,
    SSO_LOGIN,
    FRONTEND_VUE_URL,
    ENV
  ) {
    if(VU3_LOGIN_PATH && ENV !== 'local'){
        $window.location.href = FRONTEND_VUE_URL + VU3_LOGIN_PATH;
        return;
    }
    this.ssoLoginFlag = SSO_LOGIN;
    this.AdNotification = AdNotification;
    this.adDataValidator = adDataValidator;
    this.AuthService = AuthService;
    this.FireTagService = FireTagService;
    this.StylesService = StylesService;
    this.VersionService = VersionService;
    this.$filter = $filter;
    this.$scope = $scope;

    $scope.loading = false;
    $scope.signInText = 'Sign In';
    adChatService.shutDown();
    this.captchaSiteToken = RECAPTCHA_CHECKBOX_TOKEN;

    this.destroyWatchGroup = $scope.$watchGroup(
      ['vm.username', 'vm.password', 'vm.captchaSessionToken'],
      (newVal, oldVal) => {
        const [username, password, token] = newVal;
        if (username && password && token) {
          this.loginReady = true;
        } else if (!token) {
          this.loadRecaptcha();
        } else {
          this.loginReady = false;
        }
      }
    );

    $scope.login = () => this.loginPostcenter();
    $scope.loginActiveDirectory = () => this.loginFirebaseActDirect();
    $scope.toggleLoginMode = () => this.toggleLoginMode();
    $scope.validEmail = () => this.validEmail();
    $scope.checkEmailIsSsoCapable = () => this.checkEmailIsSsoCapable();
    $scope.startSsoLoginFlow = () => this.startSsoLoginFlow();
    $scope.ssoLoginFlag = this.ssoLoginFlag;
    $scope.emailPasswordLogin = this.emailPasswordLogin
    $scope.ssoLogin = this.ssoLogin
  }
}

angular.module('postCenterWebClientApp').controller('LoginCtrl', LoginCtrl);
