(module => {
  'use strict';
  notifications.$inject = [];
  function notifications() {
    return {
      restrict: 'E',
      templateUrl: 'blocks/top_bar/views/bell_notifications.html',
      controller: 'BellNotificationsCtrl',
      replace: true
    };
  }
  module.directive('notifications', notifications);
})(angular.module('postCenterWebClientApp'));
