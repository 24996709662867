const MonitoringRowsContainer = Vue.component('monitoring-rows-container', {
  props: {
    states: {type: Array, default: () => []}
  },
  template: `
    <ul class="monitoring--row-metrics list-unstyled d-flex flex-column">
      <li v-for="state in states" :key="state.key">
        <monitoring-row :state="state.key" :title="$t(state.label)"/>
      </li>
    </ul>
  `
});

const MonitoringRow = Vue.component('monitoring-row', {
  props: {
    state: {type: String, required: true},
    title: {type: String, required: true}
  },
  data() {
    return {showDetails: false, selectedChannel: ''};
  },
  computed: {...Vuex.mapGetters(['channels'])},
  methods: {
    viewChannel(channel) {
      this.showDetails =
        this.selectedChannel === channel ? !this.showDetails : true;
      this.selectedChannel = channel;
    }
  },
  template: `
    <section class="card card__shadow">
      <header>
        <h5 class="bold text-uppercase">Tickets {{ title }}</h5>
      </header>

      <ul class="monitoring--row-cell list-unstyled d-flex flex-row scrollable">
        <li class="text-center my-1"
            v-for="(label, sn) in channels">
            <button type="button"
                    class="btn btn-link"
                    @click="viewChannel(sn)">
              <monitoring-ticket-metric :title="label"
                                        :state="state"
                                        :channel="sn"
                                        :active="selectedChannel === sn"
                                        :force-open-only="state !== 'opened' && state !== 'not_assigned'" />
            </button>
        </li>
      </ul>
      <footer>
        <monitoring-row-detail v-if="showDetails"
                               :channel="selectedChannel"
                               :state="state"
                               :title="title" />
      </footer>
    </section>
  `
});

const MonitoringRowDetail = Vue.component('monitoring-row-detail', {
  props: {
    channel: {type: String, required: true},
    state: {type: String, required: true},
    title: {type: String, required: true}
  },
  data() {
    return {
      loading: false,
      tabs: [
        {
          name: this.$tc('agent', 2),
          headings: this.getColumns([
            'name',
            'account',
            'ticketCount',
            'detail'
          ]),
          active: true,
          rows: []
        },
        {
          name: this.$tc('account', 2),
          headings: this.getColumns(['account', 'ticketCount', 'detail']),
          active: false,
          rows: []
        }
      ],
      page: 1
    };
  },
  computed: {
    ...Vuex.mapGetters(['getActiveChannel']),
    indexTab: {
      get: function() {
        return this.tabs.findIndex(tab => tab.active === true);
      },
      set: function(newValue) {
        this.indexTab = newValue;
      }
    }
  },
  mounted() {
    this.loadData();
  },
  watch: {
    channel() {
      this.cleanAndLoadData();
    },
    indexTab() {
      this.cleanAndLoadData();
    }
  },
  methods: {
    ...Vuex.mapActions(['getMetric', 'getTicketsByFilters']),
    getColumns(columns = []) {
      return columns.map(field => ({
        field,
        label: this.$t(`columns.${field}`),
        component: field === 'detail' ? 'monitoring-view-column' : 'span'
      }));
    },
    getParsedRows(rows = []) {
      return rows.map(row => {
        const name = row.cm_name || this.$tc('agent', 0);

        return {
          ...row,
          name,
          ticketCount: row.total,
          account: row.account_visual_name,
          state: this.state,
          title: `Tickets ${this.title} (${name})`
        };
      });
    },
    cleanAndLoadData() {
      this.page = 1;
      this.tabs[this.indexTab].rows = [];
      this.loadData();
    },
    loadData() {
      if (!this.page) {
        return;
      }

      this.loading = true;

      const tableParams = {
        state:
          this.indexTab === 0
            ? `${this.state}_by_exec_and_sn_detail_table`
            : `${this.state}_by_sn_detail_table`,
        channel: this.channel,
        page: this.page
      };

      this.getMetric(tableParams)
        .then(({results, page, pages}) => {
          this.page = page === pages ? null : page + 1;
          const {rows: current} = this.tabs[this.indexTab];
          const data = [...current, ...this.getParsedRows(results)];
          this.tabs[this.indexTab].rows = Array.from(new Set(data));
        })
        .catch(() => (this.page = null))
        .finally(() => (this.loading = false));
    }
  },
  template: `
    <Tabs :tabs="tabs">
      <Tab v-for="(tab, index) in tabs"
            :key="index"
            :name="tab.name"
            :active="tab.active">
        <div class="p-1">
          <p v-if="!loading && !tabs[index].rows.length">{{ $t('no_data_available') }}</p>
          <Table v-else
                 :headings="tabs[index].headings"
                 :rows="tabs[index].rows"
                 v-infinite-scroll="loadData" />
          <div v-if="loading" class="text-center">
            <Loader size="2x" />
          </div>
        </div>
      </Tab>
    </Tabs>
  `
});
