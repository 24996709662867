'use strict';

angular.module('postCenterWebClientApp').directive('loading', () => {
  return {
    scope: {
    },
    templateUrl: 'blocks/global/views/loading.html',
    restrict: 'E',
    controller: [
      '$scope',
      function(
        $scope,
      ) {
      }
    ]
  };
});
