var rules = {

  createClassFunction: function (identifier, prototype, constructor) {
    prototype.__proto__ = this;
    prototype.identifier = identifier;
    constructor.identifier = identifier;
    constructor.prototype = prototype;
    prototype.constructor = constructor;
    this.subtypes[identifier] = constructor;
    return constructor;
  },

  triggers: {},
  conditions: {},
  actions: {}

};


rules.triggers.EmptyTrigger = '';
rules.triggers.CaseClosedTrigger = 'CASE_CLOSED';
rules.triggers.MessageTrigger = 'MESSAGE';
rules.triggers.CaseCustomDataSavedTrigger = 'CASE_CUSTOMDATA_SAVED';
rules.triggers.UserCustomDataSavedTrigger = 'USER_CUSTOMDATA_SAVED';
rules.triggers.OutboundMessageTrigger = 'OUTBOUND_MESSAGE_SENT';
rules.triggers.CaseCreatedTrigger = 'CASE_CREATED';


rules.BusinessRule = (function () {

  var BusinessRulePrototype = {

    isBusinessRule: function () {
      return true;
    },

    serializeCondition: function () {
      if (!this.condition) return null;
      return this.condition.serialize()
    },

    serialize: function () {
      return {
        'name': this.name,
        'trigger': this.trigger,
        'condition': this.serializeCondition(),
        'actions': this.actions.map(function (action) {
          return action.serialize()
        })
      }
    }
  };

  var BusinessRuleConstructor = function (name, trigger, condition, actions, id) {
    this.name = name;
    this.trigger = trigger;
    this.condition = condition;
    this.actions = actions;
    this.rule_id = id;
  };

  BusinessRuleConstructor.createEmptyRule = function () {
    return new BusinessRuleConstructor(
      null,
      rules.triggers.EmptyTrigger,
      undefined,
      [new rules.actions.EmptyAction()]
    );
  };

  BusinessRuleConstructor.parse = function (ruleJson) {
    return new BusinessRuleConstructor(
      ruleJson.name,
      ruleJson.trigger,
      ruleJson.condition ? rules.conditions.Condition.parse(ruleJson.condition): null,
      ruleJson.actions.map(function (action) {
        return rules.actions.Action.parse(action)
      }),
      ruleJson.rule_id
    )
  };
  BusinessRuleConstructor.prototype = BusinessRulePrototype;
  BusinessRulePrototype.constructor = BusinessRuleConstructor;
  return BusinessRuleConstructor
})();
