'use strict';

angular.module('postCenterWebClientApp')
  .directive('passwdCheck', [function () {
  return {
    require: 'ngModel',
    link: function (scope, elem, attrs, ctrl) {
      var firstPassword = '#' + attrs.passwdCheck;
      elem.add(firstPassword).on('keyup', function () {
        scope.$apply(function () {
          var v = elem.val() === $(firstPassword).val();
          ctrl.$setValidity('passwdmatch', v);
        });
      });
    }
  };
}]);
