'use strict';

angular.module('postCenterWebClientApp')
  .directive('adFileUpload', function () {
    return {
      scope: {
        successCallback: '&',
        errorCallback: '&'
      },
      templateUrl: 'blocks/global/views/adereso_file_upload.html',
      restrict: 'E',
      controller: ['$scope', '$timeout', '$http', 'AdRequest', 'bitly', 'AderesoFilename',
      function ($scope, $timeout, $http, AdRequest, bitly, AderesoFilename) {

        $scope.loading = false;
        $scope.onFileSelect = function(files) {

          $scope.loading = true;
          var uploadedFiles = new FormData();
          var fileData = null;
          angular.forEach(files, function(file) {
            var filename = AderesoFilename.slugFileName(file.name);
            uploadedFiles.append('file', file, filename);
            fileData = file;
          });
          var uploadUrl = '/api/v1/files/document/';
          AdRequest.post(uploadUrl, uploadedFiles, {
            transformRequest: angular.identity,
            headers: {'Content-Type': undefined}
          })
          .then(function(response) {
            var url = response.url;
            bitly.getShortUrl(url).then(function(shortUrl){
              $scope.successCallback({url: shortUrl});
            }, function(error){
              $scope.successCallback({url: url});
            });
          })
          .catch(function(exception) {
            $scope.errorCallback({error: exception});
          })
          .finally(function() {
            $scope.loading = false;
            angular.element("input.file-input").val("");
          });
        };

      }]
    };
  });
