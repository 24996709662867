'use strict';

angular.module('postCenterWebClientApp')
  .filter('setAccountName', ['AdDataFormatter', function (AdDataFormatter) {
    return function (input, socialNetwork) {
      if (socialNetwork === 'twitter') {
        input = '@' + input;
      } else if (socialNetwork === 'whatsapp') {
        input = AdDataFormatter.formatPhoneNumber(input);
      }
      return input;
    };
  }]);
