const StatusesFilter = Vue.component('StatusesFilter', {
  computed: {
    ...Vuex.mapGetters(['statuses'])
  },
  methods: {
    ...Vuex.mapActions(['setFilters']),
    filterStatuses() {
      const filteredStatuses = this.statuses
        .filter(status => !status.active)
        .map(_status => _status.name);
      this.setFilters({filter: 'online_status', payload: filteredStatuses});
    }
  },
  i18n: {
    messages: {
      es: {
        status: 'Estado | Estados'
      },
      pt: {
        status: 'Estado | Estados'
      },
      en: {
        status: 'Status | Statuses'
      }
    }
  },
  template: `
    <CustomSelector
        :filter-data="statuses"
        :change-data="filterStatuses"
        :title="$tc('status', 2)"
    />`
});
