const EpaThirdStep = Vue.component('EpaThirdStep', {
  data() {
    return {
      unsavedChanges: false,
      showButtons: {
        questionOne: false,
        questionTwo: false
      }
    };
  },
  computed: {
    ...Vuex.mapGetters('epaConfig', ['editingSurvey', 'getSurveyAlternatives']),
    sortedSurvey: {
      get() {
        return this.editingSurvey.survey.map(survey => {
          const {type} = survey;
          return {
            ...survey,
            title: this.$tc(`survey.${type}.title`, 1),
            desc:
              type !== 'res' && type !== 'open'
                ? this.$tc(`survey.${type}.title`, 2)
                : ''
          };
        });
      },
      set(survey) {
        this.SORT_SURVEY(survey);
      }
    },
    surveyType() {
      return type => this.getSurveyAlternatives(type);
    }
  },
  methods: {
    ...Vuex.mapMutations('epaConfig', ['SORT_SURVEY'])
  },
  template: `<div v-if="editingSurvey.hasOwnProperty('name')">
    <p class="p-1">
      <strong class="color-text__interactive">TIP: </strong>{{ $t('tip') }}
    </p>
    <Collapse
        :title="sortedSurvey[0].title"
        theme="white"
        icon="lock"
        iconPosition="left"
        class="survey-custom-collapse">
          <template v-slot:optional>
            <ToggleSwitchModel
              class="float-right"
              v-model="surveyType(sortedSurvey[0].type).enabled"
            />
          </template>
          <ChoiceSurvey isResolution/>
    </Collapse>
    <draggable v-model="editingSurvey.survey">
      <div v-for="(survey, index) in sortedSurvey"
        :key="survey.type"
        v-if="index > 0">
        <Collapse
          :title="survey.title"
          :detail="survey.desc"
          :draggable="true"
          theme="white"
          class="survey-custom-collapse">
          <template v-slot:optional>
            <ToggleSwitchModel
              class="float-right"
              v-model="surveyType(survey.type).enabled"
            />
          </template>
          <CesSurvey v-if="survey.type === 'ces'" />
          <ChoiceSurvey v-if="survey.type === 'fcr'" />
          <CsatSurvey v-if="survey.type === 'csat'" />
          <div v-if="survey.type === 'open'">
            <CustomerQuestionForm survey-type="open" />
          </div>
        </Collapse>
      </div>
    </draggable>
  </div>`,
  i18n: {
    messages: {
      es: {
        tip: 'Entre menos preguntas agregues aumentan las posibilidades de que la encuesta sea contestada'
      },
      pt: {
        tip: 'Quanto menos perguntas você acrescentar, maiores serão as chances de que a pesquisa seja respondida.'
      },
      en: {
        tip: 'The fewer questions you add, the higher the chances that the survey will be answered.'
      }
    }
  }
});
