'use strict';

angular.module('postCenterWebClientApp')
  .directive('adAvatarUpload', function () {
    return {
      scope: {
        fileValidation: '&',
        successCallback: '&',
        errorCallback: '&',
        hideFakeButton: '<'
      },
      templateUrl: 'blocks/global/views/adereso_avatar_upload.html',
      restrict: 'E',
      controller: ['$scope', '$timeout', 'AdRequest',
      function ($scope, $timeout, AdRequest) {
        $scope.uploading = false;
        $scope.uploadImage = function (fileData) {
          var uploadedFiles = new FormData();
          uploadedFiles.append('file', fileData);
          var uploadUrl = '/api/v1/files/image/';
          AdRequest.post(uploadUrl, uploadedFiles, {
              transformRequest: angular.identity,
              headers: {'Content-Type': undefined}
          })
          .then(function(response) {
            $scope.successCallback({url: response.url});
          })
          .catch(function(exception) {
            $scope.errorCallback({error: exception});
          })
          .finally(function() {
            $scope.uploading = false;
          });
        };

        $scope.dontUploadImage = function() {
          $timeout(function() {
            $scope.uploading = false;
          });
        };

        $scope.onFileSelect = function(files) {
          $scope.uploading = true;
          var uploadedFiles = new FormData();
          var fileData = null;
          angular.forEach(files, function(file) {
            uploadedFiles.append('file', file);
            fileData = file;
          });

          $scope.fileValidation({
            fileData: fileData,
            successFn: $scope.uploadImage,
            errorFn: $scope.dontUploadImage
          });
        };

      }]
    };
  });
