'use strict';
angular.module('postCenterWebClientApp')
  .directive('adVisibleOnScroll', function ($window, $timeout) {
    return {
      restrict: 'A',
      scope: {
        target: '@adVisibleOnScrollTarget',
        isVisible: '=adVisibleOnScrollIsVisible',
      },
      link: function (scope, containerElement) {

        var timer;
        containerElement.bind('scroll', function () {
          if (timer) {
            $window.clearTimeout(timer);
          }
          timer = $window.setTimeout(function() {
            var targetClass = scope.target;
            var currentElement = containerElement.find(targetClass);
            if (currentElement.length === 1) {
              var currentElementTop = currentElement.offset().top,
                containerElementTop = containerElement.offset().top,
                currentElementRelativeTop =
                  currentElementTop - containerElementTop,
                currentElementRelativeBottom =
                  currentElementRelativeTop + currentElement.height(),
                containerClientHeight = containerElement[0].clientHeight,
                isOutByTop = currentElementRelativeTop < 0,
                isOutByBottom =
                  currentElementRelativeBottom > containerClientHeight;

              if (isOutByTop || isOutByBottom) {
                $timeout(function(){
                  scope.isVisible = false;
                }, 1)
              } else {
                $timeout(function(){
                  scope.isVisible = true;
                }, 1)
              }
            }

          }, 50);

        });
      }
    };
  });
