'use strict';

var app = angular.module('postCenterWebClientApp');
app.controller('EmailValidationModalCtrl', function(
  $scope,
  AdPolling,
  $sanitize,
  SocialAccount,
  SocialAccountService,
  account,
  AdNotification
) {
  var self = this,
    polling = null;

  self.step = 0;
  self.account = account;
  self.sanitizedForwardEmail = $sanitize(account.config.forward_email);

  self.sendValidation = function() {
    self.step = 1;
    SocialAccountService.validateSocialAccount(account.id).catch(error => {
      AdNotification.error(error, 'send_email_validation');
      self.$dismiss();
    });
  };

  const vmStepListenerOff = $scope.$watch('vm.step', newStep => {
    if (newStep === 1) {
      polling = AdPolling.start(
        () => {
          return SocialAccountService.getSocialAccount(
            'email',
            self.account.uid
          );
        },
        5,
        account => {
          if (account.statuses.forwarding_validated) {
            SocialAccount.onChannelConnected(1);
            self.$close(account);
          }
        },
        () => {}
      );
    } else {
      if (polling !== null) {
        polling.stop();
      }
    }
  });

  $scope.$on('$destroy', () => {
    if (polling !== null) {
      polling.stop();
    }
    vmStepListenerOff();
  });
});
