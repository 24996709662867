const EpaTabDetail = Vue.component('EpaTabDetail', {
  data() {
    return {
      scroll: 0,
      cardHeight: 'auto',
      expandedTable: false
    };
  },
  computed: {
    ...Vuex.mapGetters('epaAnalytics', ['table', 'loading']),
    getHeight() {
      const isNum = num => !isNaN(num);
      return {
        height: isNum(this.cardHeight)
          ? `${this.cardHeight}px`
          : this.cardHeight
      };
    },
    fullScreen() {
      const expand = this.expandedTable;
      return expand ? 'expanded-table' : '';
    }
  },
  methods: {
    ...Vuex.mapActions('epaAnalytics', ['getCsvData']),
    expandTable() {
      this.expandedTable = !this.expandedTable;
      const sticky = document.getElementsByTagName('sticky-scroll');
      const toggleClass = toggle => {
        Array.from(sticky).forEach(elem => {
          if (toggle === 'add') {
            elem.classList.add('block-scroll');
          } else {
            elem.classList.remove('block-scroll');
          }
        });
      };
      if (this.expandedTable) {
        this.scroll = sticky[0].scrollTop;
        sticky[0].scrollTo({top: 0, behavior: 'smooth'});
        toggleClass('add');
        this.cardHeight = sticky[0].offsetHeight;
      } else {
        sticky[0].scrollTo({top: this.scroll, behavior: 'smooth'});
        toggleClass('remove');
        this.cardHeight = 'auto';
      }
    }
  },
  template: `<KpiCard :title="$t('detail.table')" 
    :style="getHeight" :class="fullScreen">
      <template slot="filters">
        <div class="text-center">
          <Loader v-if="loading" size="1x" />
          <button class="ads-button ads-button--link"
            v-if="!loading"
            @click="getCsvData({
              query_kind: 'detail',
              title: 'detail'
            })">{{ $t('download.button') }}
          </button>
        </div>
      </template>
      <EpaDetailTable />
    </KpiCard>`
});
