'use strict';

angular.module('postCenterWebClientApp')
  .directive('messageGif', function () {
    return {
      scope: {
        src: '@'
      },
      templateUrl: 'blocks/message/views/message_gif.html',
      restrict: 'E',
      controller: ['$scope',
      function ($scope) {
        jQuery('.magnific-popup-image').magnificPopup({
          type:'image',
          image: {
            cursor: null,
          }
        });
        var canvas = angular.element.find('canvas')[0];
        var context = canvas.getContext('2d');
        var imageObj = new Image();
        imageObj.onload = function() {
          canvas.width = imageObj.width;
          canvas.height = imageObj.height;
          context.drawImage(imageObj, 0, 0);
        };
        imageObj.src = $scope.src;
      }]
    };
  });
