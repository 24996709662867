const CustomerQuestionForm = Vue.component('CustomerQuestionForm', {
  props: {
    surveyType: {
      type: String,
      required: true,
      validator: survey =>
        ['res', 'csat', 'ces', 'fcr', 'open'].includes(survey)
    }
  },
  computed: {
    ...Vuex.mapGetters('epaConfig', ['getSurveyAlternatives']),
    survey() {
      return this.getSurveyAlternatives(this.surveyType);
    },
    getQuestion() {
      return this.$t(`survey.${this.surveyType}.question`);
    },
    getDescription() {
      return this.$t(`survey.${this.surveyType}.desc`);
    },
    getInfo() {
      const type = this.surveyType.toUpperCase();
      return this.$tc('moreInfo', this.getType, {
        type
      });
    },
    getType() {
      return this.surveyType === 'res' ? 1 : 2;
    }
  },
  template: `
  <div class="d-flex flex-column">
    <div v-if="surveyType !== 'open'" class="d-flex space-between mb-2">
      <p>{{ getDescription }}</p>
      <div class="survey-form__info d-flex align-flex-start flex-end">
        <a href="https://help.adere.so/" target="_blank">
          <span
            class="fa fa-question-circle-fas fa-x2 icon-blue"
          />
          {{ getInfo }}
        </a>
      </div>
    </div>
    <div class="mb-2">
      <div class="mb-1">
        <strong>{{ getQuestion }}</strong>
      </div>
      <InputText
        class="w-60"
        v-model="survey.text"
        :disabled="survey.enabled === false"
      />
    </div>
  </div>
  `,
  i18n: {
    messages: {
      es: {
        moreInfo: 'Conoce más | Conoce más sobre {type}'
      },
      pt: {
        moreInfo: 'Saber mais | Saber mais sobre {type}'
      },
      en: {
        moreInfo: 'More info | More info about {type}'
      }
    }
  }
});
