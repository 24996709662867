'use strict';

angular.module('postCenterWebClientApp')
  .factory('adDateFormatter', function () {
    return {
      responseTime: function (seconds) {

        if (seconds === undefined || seconds === null) {
          return null;
        }

        if (seconds < 60) {
          return Math.round(seconds) + ' s';
        } else if (seconds < 3600) {
          return Math.round(seconds / 60) + ' m';
        } else if (seconds < 216000) {
          return Math.round(seconds / 3600) + ' h';
        } else if (seconds < 1512000) {
          return Math.round(seconds / 216000) + ' d';
        } else {
          return Math.round(seconds / 1512000) + ' w';
        }

      },
      publicationDate: function (dateInSeconds) {
        return moment(dateInSeconds * 1000).fromNow();
      },
      publicationDateLong: function (dateInSeconds) {
        return moment(dateInSeconds * 1000).format('MMMM D, YYYY, HH:mm:ss');
      },
      publicationDateMedium: function (dateInSeconds) {
        return moment(dateInSeconds * 1000).format('DD/MM/YY, HH:mm');
      },
      parseToLocale: function (date) {
        return moment(date).fromNow();
      },
      notificationTime: function (dateInSeconds) {
        return moment(dateInSeconds * 1000).fromNow();
      }
    };
  });
