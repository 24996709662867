'use strict';
angular.module('postCenterWebClientApp')
.directive('timeAgo', function($timeout) {
    const actualTime = new Date().getTime()
    function update(scope, element) {
      const timeRemain = moment(scope.actualTime).fromNow();
      const diff = Math.abs(actualTime - scope.actualTime);
      const diffHours = Math.ceil(diff / (1000 * 60 * 60))
      if (diffHours <= 1) {
        $timeout(() => {
            update(scope, element);
        }, 60000);
      }
      element.text(timeRemain);
      
    }
    
    return {
      scope: {
        actualTime: '=timeAgo'
      },
      link(scope, element) {
        update(scope, element);
      }
    };
});