'use strict';

angular
  .module('postCenterWebClientApp')
  .directive('columns', columnsDirective);

function columnsDirective() {
  return {
    templateUrl: 'blocks/crm/views/columns/columns.html',
    restrict: 'E',
    controller: 'ColumnsCtrl'
  };
}
