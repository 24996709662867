'use strict';

angular.module('postCenterWebClientApp')
.factory('adDataValidator', function () {

  function existsAndNotEmpty(key, obj) {

    if (key in obj && angular.isDefined(obj[key]) && obj[key] !== null) {
      if (obj[key] !== '') {
        return true;
      }
      return false;
    }
    return false;
  }

  function formatRut(rut) {
    rut = rut.replace(/[^0123456789kK]/g,'');
    var dv = rut.substr(rut.length - 1);
    rut = rut.substr(0, rut.length - 1);
    rut = rut.replace(/\D/g,'');
    if (rut === "" || dv === "") {
      return '';
    } else {
      return rut + '-' + dv;
    }
  }

  function checkEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  function validatePhoneNumber(text){
    var cleanNumber = '+' + text.replace(/[\W_]/g, "");
    var numberValidator = new libphonenumber.parsePhoneNumberFromString(cleanNumber);
    if (numberValidator && numberValidator.number != null) {
      return numberValidator.isValid()
    }
    return false;
  }

  function checkRut(rut){

    var rexp = new RegExp(/^([0-9])+\-([kK0-9])+$/);
    if (rut.match(rexp)) {
      var splitted = rut.split("-");
      var start = splitted[0].split("");
      var factor = 2;
      var add = 0;
      var dv;
      for(var i=(start.length-1); i >= 0; i--){
        factor = factor > 7 ? 2 : factor;
        add += parseInt(start[i])*parseInt(factor++);
      }
      dv = 11 - (add % 11);
      if(dv == 11){
        dv = 0;
      }else if (dv == 10){
        dv = "k";
      }
      if(dv == splitted[1].toLowerCase()){
        return true;
      }else{
        return false;
      }
    }
    return false;
  }

  function isBlank(str) {
    return (!str || /^\s*$/.test(str));
  }

  function hasData(value) {
    return value !== null && value !== "" && value !== undefined;
  }

  return {
    isBlank: isBlank,
    hasData: hasData,
    checkRut: checkRut,
    checkEmail: checkEmail,
    validatePhoneNumber: validatePhoneNumber,
    formatRut: formatRut,
    existsAndNotEmpty: existsAndNotEmpty
  };

});
