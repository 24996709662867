(module => {
  'use strict';
  caseDetailViews.$inject = [];
  module.directive('caseDetailViews', caseDetailViews);
  function caseDetailViews() {
    return {
      templateUrl: 'blocks/case/views/case_detail_views.html',
      restrict: 'E',
      controller: 'caseDetailViewsCtrl',
      controllerAs: 'vm',
      bindToController: true
    };
  }
})(angular.module('postCenterWebClientApp'));
