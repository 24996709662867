/* global i18nEpa */
const getSurveys = () => {
  const surveyTypes = ['res', 'csat', 'ces', 'fcr', 'open'];
  const getTags = type => {
    if (type === 'csat' || type === 'ces') {
      const ind = type === 'csat' ? 1 : 2;
      return {
        lowest: i18nEpa.tc('survey.answer_tags.options.lowest', ind),
        low: i18nEpa.tc('survey.answer_tags.options.low', ind),
        middle: i18nEpa.tc('survey.answer_tags.options.middle', ind),
        high: i18nEpa.tc('survey.answer_tags.options.high', ind),
        highest: i18nEpa.tc('survey.answer_tags.options.highest', ind)
      };
    }
    return {
      yes: i18nEpa.tc('survey.answer_tags.option', 1),
      no: i18nEpa.tc('survey.answer_tags.option', 2)
    };
  };
  const surveys = surveyTypes.map(survey => {
    const answer_tags = survey !== 'open' ? getTags(survey) : null;
    return {
      type: survey,
      should_refer_to_agent: true,
      enabled: false,
      inverted: false,
      ranking_type: survey === 'csat' ? 'tags' : null,
      text: i18nEpa.t(`survey.${survey}.text`),
      answer_tags
    };
  });
  return surveys;
};
const getEpaDefaultState = () => {
  return {
    creatingSurvey: false,
    now: moment().unix(),
    enabledAccounts: ['whatsapp', 'chat', 'facebook', 'twitter', 'instagram', 'external'],
    defaultSurvey: {
      name: '',
      accounts: [],
      delivery_type: 'manual', // automatic | manual
      delivery_timeout: '00:01',
      spam_timeout: 1,
      goodbye_message: i18nEpa.t('survey.goodbye_message'),
      survey: getSurveys()
    },
    warningActiveSurvey: false,
    surveyChanged: false,
    editSurvey: {},
    surveys: [],
    usedAccounts: []
  };
};
store.registerModule('epaConfig', {
  namespaced: true,
  state: getEpaDefaultState(),
  getters: {
    warningActiveSurvey: state => state.warningActiveSurvey,
    surveyChanged: state => state.surveyChanged,
    enabledAccounts: state => state.enabledAccounts,
    editingSurvey: state => state.editSurvey,
    getSurveyAlternatives: state => filter =>
      state.editSurvey.survey.find(({type}) => type === filter),
    getDefaultSurvey: state => state.defaultSurvey.survey,
    creatingSurvey: state => state.creatingSurvey,
    surveys: state => state.surveys,
    surveyByID: state => id => state.surveys.find(survey => survey.id === id),
    usedAccounts: state => state.usedAccounts,
    checkForChanges: (state, getters) => {
      const {editSurvey} = state;
      const actualSurvey = getters.surveyByID(editSurvey.id) || {};
      return !_.isEqual(editSurvey, actualSurvey);
    }
  },
  mutations: {
    MUTATE(state, {property, value}) {
      state[property] = value;
    },
    EDIT_SURVEY(state, {survey, editing}) {
      state.editSurvey = survey;
      state.creatingSurvey = editing;
      state.surveyChanged = false;
    },
    UPDATE_SURVEYS(state, payload) {
      const index = state.surveys.findIndex(survey => survey.id === payload.id);
      if (index !== -1) {
        state.surveys.splice(index, 1, payload);
      }
    },
    UPDATE_ACCOUNTS(state, payload) {
      const {accounts} = state.editSurvey;
      const indexFound = accounts.findIndex(account => account === payload);
      if (indexFound !== -1) {
        state.editSurvey.accounts.splice(indexFound, 1);
      } else {
        state.editSurvey.accounts.splice(indexFound, 0, payload);
      }
    },
    UPDATE_USED_ACCOUNTS(state, payload) {
      const {usedAccounts} = state;
      const indexFound = usedAccounts.findIndex(account => account === payload);
      if (indexFound !== -1) {
        state.usedAccounts.splice(indexFound, 1);
      } else {
        state.usedAccounts.splice(indexFound, 0, payload);
      }
    }
  },
  actions: {
    debouncedUpdate: debounce(({dispatch}) => {
      dispatch('updateSurvey');
    }, 3000),
    updateAccounts({dispatch, commit, state}, id) {
      const {
        editSurvey: {is_active, accounts}
      } = state;
      commit('UPDATE_ACCOUNTS', id);
      commit('UPDATE_USED_ACCOUNTS', id);
      if (is_active) {
        if (accounts.length > 0) {
          dispatch('updateSurvey');
        }
      } else {
        dispatch('updateSurvey');
      }
    },
    toggleModal({commit}, {name, toggle = false}) {
      commit('MUTATE', {
        property: name,
        value: toggle
      });
    },
    openSurvey({dispatch, getters, commit}, survey) {
      if (getters.checkForChanges) {
        dispatch('toggleModal', {
          name: 'surveyChanged',
          toggle: true
        });
        return;
      }
      const surveyEdit = JSON.parse(JSON.stringify(survey));
      commit('EDIT_SURVEY', {
        survey: surveyEdit,
        editing: true
      });
    },
    isActiveSurvey({dispatch, state}) {
      const {
        editSurvey: {is_active}
      } = state;
      if (is_active) {
        dispatch('toggleModal', {
          name: 'warningActiveSurvey',
          toggle: true
        });
      } else {
        dispatch('updateSurvey');
      }
    },
    async saveSurvey({state, dispatch}) {
      const {defaultSurvey} = state;
      try {
        const savedSurvey = await this.EpaService.saveSurvey(defaultSurvey);
        dispatch(
          'notify',
          {
            message: i18nEpa.t('toast.safe.create'),
            type: 'success'
          },
          {root: true}
        );
        dispatch('getSurveys');
        return savedSurvey;
      } catch (error) {
        dispatch(
          'notify',
          {
            message: i18nEpa.t('toast.errors.create'),
            type: 'error'
          },
          {root: true}
        );
      }
    },
    cancelEdition({commit}) {
      commit('EDIT_SURVEY', {
        survey: {},
        editing: false
      });
    },
    async removeSurvey({dispatch}, id) {
      try {
        const {deleted = false} = await this.EpaService.removeSurvey(id);
        if (deleted) {
          dispatch('cancelEdition');
          dispatch('getSurveys');
          dispatch(
            'notify',
            {
              message: i18nEpa.t('toast.safe.remove'),
              type: 'success'
            },
            {root: true}
          );
        }
        return deleted;
      } catch {
        dispatch(
          'notify',
          {
            message: i18nEpa.t('toast.errors.remove'),
            type: 'error'
          },
          {root: true}
        );
      }
    },
    async toggleSurvey(_context, payload) {
      return await this.EpaService.toggleSurvey(payload);
    },
    async updateSurvey({state, dispatch, commit}) {
      const {editSurvey} = state;
      this.EpaService.updateSurvey(editSurvey).then(({data}) => {
        dispatch(
          'notify',
          {
            message: i18nEpa.t('toast.safe.updated'),
            type: 'success'
          },
          {root: true}
        );
        commit('EDIT_SURVEY', {
          survey: data,
          editing: true
        });
        dispatch('getSurveys');
        dispatch('toggleModal', {name: 'warningActiveSurvey', toggle: false});
        dispatch('toggleModal', {name: 'surveyChanged', toggle: false});
      });
    },
    async getSurveys({commit, state}, openFirst = false) {
      const {usedAccounts} = state;
      this.EpaService.getSurveys().then(surveys => {
        if (Array.isArray(surveys)) {
          const lastSurvey = [...surveys].pop();
          surveys.map(survey => {
            survey.accounts.map(account => {
              if (!usedAccounts.includes(account)) {
                usedAccounts.push(account);
              }
            });
          });
          surveys.map(survey => {
            survey.survey.map(question => {
              if (question.type === 'res' && question.should_refer_to_agent === undefined) {
                question.should_refer_to_agent = true;
              }
            });
          });
          commit('MUTATE', {
            property: 'usedAccounts',
            value: usedAccounts
          });
          commit('MUTATE', {
            property: 'surveys',
            value: surveys
          });
          if (openFirst) {
            commit('EDIT_SURVEY', {
              survey: lastSurvey,
              editing: true
            });
          }
        } else {
          commit('MUTATE', {
            property: 'usedAccounts',
            value: []
          });
          commit('MUTATE', {
            property: 'surveys',
            value: []
          });
        }
      });
    },
    async isSatisfactionModuleEnabled() {
      try {
        const establishment = await this.EstablishmentService.getEstablishment();
        return establishment.features.satisfaction_module;

      } catch (err) {
        console.log(`Error in satisfaction module feature flag calculation: ${err}`);
        return false;
      }
    }
  }
});
