const Card = Vue.component('Card', {
  props: {
    active: {
      type: Boolean
    }
  },
  template: `<div class="basic-card" :class="{'active': active}">
    <slot />
  </div>`
});
