'use strict';

var app = angular.module('postCenterWebClientApp');
app.directive('caseMessage', function () {
  return {
    scope: {
      message: '=',
      shouldShowDateAndStatus: '=',
      canReply: '&',
    },
    templateUrl: 'blocks/message/views/case_message_new.html',
    restrict: 'E',
    controller: 'MessageCtrl'
  };
});
