Vue.component('Counter', {
  props: {
    value: {type: Number, required: true},
    max: {type: Number, required: true},
    min: {type: Number, default: 0}
  },
  data() {
    return {newValue: this.value};
  },
  computed: {
    counter: {
      get() {
        return this.value;
      },
      set(value) {
        this.newValue = value;
      }
    },
    outOfBounds() {
      const max = this.newValue >= this.max;
      const min = this.newValue <= this.min;

      return {max, min};
    }
  },
  watch: {
    newValue() {
      this.emitResult();
    },
    max() {
      this.emitResult();
    }
  },
  methods: {
    emitResult() {
      const higherValue = this.outOfBounds.max ? this.max : this.newValue;
      this.$emit('input', higherValue);
    },
    add() {
      this.counter = this.counter + 1;
    },
    sub() {
      this.counter = this.counter - 1;
    }
  },
  template: `
    <div class="counter counter--container d-flex align-center row no-gutters">
      <button type="button"
              class="btn btn-link col-sm-4"
              :disabled="outOfBounds.min"
              @click="sub()">
        <span class="counter--button fa fa-minus-circle fa-2x text-info"></span>
      </button>
      <input type="text"
             disabled
             class="counter--value text-center col-sm-4"
             :value="value">
      <button type="button"
              class="btn btn-link col-sm-4"
              :disabled="outOfBounds.max"
              @click="add()">
        <span class="counter--button fa fa-plus-circle fa-2x text-info"></span>
      </button>
    </div>
  `
});
