'use strict';

angular
  .module('postCenterWebClientApp')
  .directive('kpiAddressedTable', function () {
    return {
      scope: {
        period: '=',
        account: '=',
        businessTime: '=',
        filterByCreated: '='
      },
      templateUrl: 'blocks/analytics/views/kpi_addressed_table.html',
      restrict: 'E',
      controller: kpiAddressedTableController,
      controllerAs: 'vm'
    };
  });

kpiAddressedTableController.$inject = [
  '$rootScope',
  '$scope',
  '$filter',
  '$uibModal',
  'InitialData',
  'AnalyticsDashboard',
  'UserFilter',
  'adExportToCsv',
  'FireTagService'
];

function kpiAddressedTableController(
  $rootScope,
  $scope,
  $filter,
  $uibModal,
  InitialData,
  AnalyticsDashboard,
  UserFilter,
  adExportToCsv,
  FireTagService
) {
  var cms = {};
  var filteredCms = {};
  var loadingLock = false;
  var vm = this;
  var translate = $filter('translate');

  vm.loading = true;
  vm.tableOpened = true;

  vm.rows = [];
  vm.openHelpModal = openHelpModal;

  vm.timeBlockSizes = [5, 10, 15, 20, 30];
  vm.timeBlockSize = 5;
  vm.toggleTimeBlockSize = function (timeBlockSize) {
    vm.timeBlockSize = timeBlockSize;
  };

  vm.exportAddressment = () => {
    const title = $filter('translate')('KPI_ADDRESSED_TABLE_TITLE');
    const name = $filter('translate')('KPI_ADDRESSED_TABLE_NAME');
    const headers = [];
    const data = [];

    headers.push(name);
    vm.timeBlocks.map(time => {
      headers.push(`${time}"`);
    });
    headers[headers.length - 1] += '+';
    vm.rows.map(row => {
      let item = {};
      row.map((value, i) => {
        item[headers[i]] = value;
      });
      data.push(item);
    });
    adExportToCsv.exportToCsv(title, data);
    FireTagService.setEvent({
      name: 'exportacion_general',
      params: {
        export_name: title
      }
    });
  };

  UserFilter.updateDepartmentsOptions(vm).then(results => {
    const currentUser = results[0]
    vm.filterParams = {
      name: '',
      department: UserFilter.getDefaultDepartment(currentUser, results[1])
    }
  })

  $scope.$on('$destroy', () => {
    watchObservablesOff();
    analyticsTabSelectedOff();
  });

  const watchObservablesOff = $scope.$watch(observables, softRefreshTable, true);
  const analyticsTabSelectedOff = $scope.$on('analytic:dashboard:tabSelected', function (e, selectedTab) {
    if (selectedTab === 'executives') {
      refreshTable(false);
    }
  });

  // Init

  InitialData.getCms().then(function () {
    cms = _.indexBy(angular.copy($rootScope.communityManagers), 'cmid');
    filteredCms = cms;
  });

  function observables() {
    return [$scope.period, vm.timeBlockSize, vm.filterParams];
  }

  function softRefreshTable() {
    return refreshTable(true);
  }

  function refreshTable(softLoading) {
    if (loadingLock === true) {
      return; // avoid multiple refresh actions
    } else {
      loadingLock = true;
    }

    if (softLoading) {
      vm.softLoading = true;
    } else {
      vm.loading = true;
    }

    return AnalyticsDashboard.executiveKPI(
      'addressed_table',
      $scope.period.now,
      $scope.period.until,
      $scope.account,
      $scope.businessTime,
      $scope.filterByCreated
    )
      .then(function (rawTable) {
        buildTable(rawTable);
        vm.loading = false;
        vm.softLoading = false;
        loadingLock = false;
      })
      .catch(function (error) {
        vm.loading = false;
        vm.softLoading = false;
        loadingLock = false;
      });
  }

  /**
   * Backend output is a matrix:
   *   {<cm_id>: {<time_block>: <n_of_addressment>, ...}, ...}

   * A time block is 5 minutes long and is represented by the starting minute.
   * The higher time block allowed is 120, any addressment done after that is
   * counted in the time block 120.
   *   0, 5, 10 ..., 120+
   */
  function buildTable(rawTable) {
    vm.timeBlocks = [];
    for (var i = 0; i <= 120; i += vm.timeBlockSize) {
      vm.timeBlocks.push(i);
    }
    // Users filter
    var preFilteredUsers = UserFilter.getFilteredUsers(
      vm.filterParams.name,
      undefined,
      vm.filterParams.department
    );
    filteredCms = _.filter(cms, function (user) {
      var userId = user.cmid;
      for (var index = 0; index < preFilteredUsers.length; index++) {
        if (preFilteredUsers[index].id === userId) {
          return true;
        }
      }
      return false;
    });

    // Compute rows info
    vm.rows = _.map(filteredCms, function (cm) {
      var addrPerMinutes = rawTable[cm.cmid] || {};
      var cmAddressments = _.times(vm.timeBlocks.length, _.constant(0));

      _.each(addrPerMinutes, function (num, timeBlock) {
        var timeBlockIndex = parseInt(timeBlock / vm.timeBlockSize);
        cmAddressments[timeBlockIndex] += num;
      });
      cmAddressments.unshift(cm.name);
      return cmAddressments;
    });
    var totals = totalRow(vm.rows);
    if (totals) {
      vm.rows.push(totals);
    }
  }

  function totalRow(tableData) {
    if (tableData.length === 0) {
      return;
    }
    var totalRow = [];
    totalRow[0] = 'Total';
    var singleRow = tableData[0];
    singleRow.forEach(function (value, index) {
      if (!isNaN(value)) {
        totalRow[index] = 0;
        tableData.forEach(function (row) {
          totalRow[index] += row[index];
        });
      }
    });
    return totalRow;
  }

  function openHelpModal() {
    $uibModal.open({
      templateUrl: 'blocks/analytics/views/dashboard_help_modal.html',
      size: 'sm',
      controller: ['$scope', '$uibModalInstance', '$filter', modalController]
    });
    function modalController($scope, $uibModalInstance, $filter) {
      $scope.title = 'KPI_ADDRESSED_TABLE_TITLE';
      $scope.helpText = $filter('translate')('KPI_ADDRESSED_TABLE_HELP');
      $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
      };
    }
  }
}
