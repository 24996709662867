const HistoryCounters = Vue.component('HistoryCounters', {
  computed: {
    ...Vuex.mapGetters(['getCounters', 'getTabGroup', 'activeGroup']),
    kpiLength() {
      const list = this.getTabGroup(this.activeGroup) || [];
      const kpiLength = list.filter(
        ({visualization_type}) => visualization_type === 'counter'
      ).length;
      return kpiLength;
    },
    getTotals() {
      return this.getCounters;
    },
    loader() {
      const countersLength = this.getCounters.length;
      if (
        this.kpiLength === 0 ||
        countersLength === 0 ||
        this.kpiLength !== countersLength
      ) {
        return true;
      }
      return false;
    }
  },
  template: `
    <ul class="history--summary list-unstyled scrollable scrollable-x">
    <slot v-if="loader">
      <ContentLoaderCounter
        v-for="loader in [1,2,3,4,5]"
        :key="loader"
      />
      </slot>
      <slot v-else>
      <li v-for="kpi in getTotals" :key="kpi.identifier">
        <div class="card text-center">
          <h2 class="mt-1">{{ kpi.result }}</h2>
          <p class="text-overflow">{{kpi.label}}</p>
          <span class="border" />
        </div>
      </li>
      </slot>
    </ul>
  `
});
const ContentLoaderCounter = Vue.component('ContentLoaderCounter', {
  components: {
    ContentLoader: contentLoaders.ContentLoader
  },
  template: `<content-loader
  :width="120"
  :height="92"
  :speed="2"
  primaryColor="#d3e3ec"
  secondaryColor="#a8c7d9"
>
<rect x="0" y="15" rx="4" ry="6" width="104" height="32" />
<rect x="22" y="70" rx="4" ry="4" width="62" height="8" />
</content-loader>`
});
const SkeletonCounters = Vue.component('SkeletonCounters', {
  props: {
    size: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      loaders: []
    };
  },
  mounted() {
    this.loaders = new Array(this.size);
  },
  template: `<div class="history--summary">
  <ContentLoaderCounter v-for="loader in loaders" />
</div>`
});
