const AssignmentModeFilter = Vue.component('AssignmentModeFilter', {
  computed: {
    ...Vuex.mapGetters(['assignationModeOptions'])
  },
  methods: {
    ...Vuex.mapActions(['setFilters']),
    filterAssignmentModes() {
      const filteredAssignationMode = this.assignationModeOptions
        .filter(status => !status.active)
        .map(mode => mode.id);
      this.setFilters({
        filter: 'assignment_modes',
        payload: filteredAssignationMode
      });
    }
  },
  i18n: {
    messages: {
      es: {
        status: 'Método de asignación'
      },
      pt: {
        status: 'Método de atribuição'
      },
      en: {
        status: 'Assignment Method'
      }
    }
  },
  template: `
    <CustomSelector
        :filter-data="assignationModeOptions"
        :change-data="filterAssignmentModes"
        :title="$t('status')"
    />`
});
