'use strict';

app.directive('userPicker', function () {
  return {
    scope: {},
    controllerAs: 'ctrl',
    bindToController: {
      userList: '=userList',
    },
    templateUrl: 'blocks/user/views/user_picker.html',
    restrict: 'E',
    controller: UserPickerCtrl
  };
});

UserPickerCtrl.$inject = ['$scope', 'CaseDetailManager'];

function UserPickerCtrl($scope, CaseDetailManager) {

  $scope.userList = this.userList;
  $scope.pickUser = function (user) {
    CaseDetailManager.showUser(user);
    $scope.close();
  }

  $scope.close = function() {
    CaseDetailManager.closeUserPicker();
  };

}
