(module => {
  'use strict';
  BellNotificationsCtrl.$inject = [
    '$scope',
    '$rootScope',
    'NotificationsService',
    'AdPolling',
    'localStorageService',
    'adDateFormatter',
    '$location',
    '$window',
    '$timeout',
    'snErrorMessage',
    'UserService',
    'CaseDetailManager'
  ];
  function BellNotificationsCtrl(
    $scope,
    $rootScope,
    NotificationsService,
    AdPolling,
    localStorageService,
    adDateFormatter,
    $location,
    $window,
    $timeout,
    snErrorMessage,
    UserService: UserService,
    CaseDetailManager: CaseDetailManager
  ) {
    const exportOptions = [
      'EXPORT_FINISHED_CASES',
      'EXPORT_FINISHED_MESSAGES',
      'EXPORT_FINISHED'
    ];
    const restartOptions = ['INCOMING_RESTART', 'INCOMING_RESTART_COMPLETED'];
    const secondsToPolling = 120;
    const secondsToPollingUnread = 40;
    const secondsToFadeOut = 15;

    UserService.getProfile().then(user => {
      $scope.user = user
    })

    function onUnreadPollSuccess(response) {
      const previous_unread =
        $scope.unreadNotifications == null
          ? 100000
          : $scope.unreadNotifications;
      $scope.unreadNotifications = parseInt(response.total);
      const new_notifications = $scope.unreadNotifications - previous_unread;
      if (new_notifications > 0) {
        NotificationsService.new(new_notifications).then(
          response => {
            if ($rootScope.authActive) {
              angular.forEach(response, toast => {
                toast.ago = adDateFormatter.notificationTime(toast.created);
                toast.icon = getIcon(toast);
                toast.type = getType(toast);
                toast.hide = false;
                if (toast.params.social_network){
                  toast.params.social_network = capitalizeFirstCharacter(toast.params.social_network);
                }
                toast = handleSpecialCases(toast);
                $scope.toasts.push(toast);
                toast.timeout = $timeout(() => {
                  $scope.toasts.splice($scope.toasts.indexOf(toast), 1);
                }, 1000 * secondsToFadeOut);
                $rootScope.$emit('topBell:bellNotification', {
                  topBellToast: localStorageService.get('topBarBellToast'),
                  topBellAudio: localStorageService.get('topBarBellAudio'),
                  icon: toast.icon,
                  text: $scope.toasts,
                  timeout: 4000
                });
              });
              joinToasts();
            }
          },
          () => {
            $scope.notificationsError = true;
          }
        );
      } else {
        $scope.toasts = [];
        $scope.reducedToasts = [];
      }
    }

    function capitalizeFirstCharacter(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }

    $scope.closeToast = function (toast) {
      $scope.reducedToasts.splice($scope.reducedToasts.indexOf(toast), 1);
      const notificationsIds = toast.ids;
      readSelectedNotifications(notificationsIds);
    };

    function joinToasts() {
      // Check the value url, text and read (must be false) and create and object that has the toast object and the number of times that it is repeated
      const toasts = $scope.toasts.reduce((acc, toast) => {
        const toastKey = getToastKey(toast);
        acc[toastKey] = acc[toastKey] || {toast, count: 0, ids: []};
        acc[toastKey].count++;
        if (acc[toastKey].count > 1) {
          removeToastParams(acc[toastKey].toast);
        }
        acc[toastKey].ids.push(toast.id);
        return acc;
      }, {});
      // set a value in scopes that has the toasts and the number of times that it is repeated
      $scope.reducedToasts = Object.values(toasts);
    }

    function removeToastParams(toast) {
      const params = toast.params || {};
      if (!params)  return
      for (const key in params) {
        params[key] = '';
      }
    }

    function getToastKey(toast) {
      const params = toast.params || {};
      let paramsString = ''
      const skipParams = [
        ...exportOptions,
        ...restartOptions,
        'WHATSAPP_CAMPAIGN_FINISHED_LOADING',
      ];

      if (skipParams.includes(toast.text)) {
        return `${toast.text}`;
      }
      for (const key in params) {
        paramsString += `${key}:${params[key]}`
      }
      return `${toast.url}-${toast.text}-${paramsString}`;
    }

    function onUnreadPollError(error) {
      $scope.unreadNotifications = 0;
    }
    const unreadPoll = AdPolling.start(
      NotificationsService.unread,
      secondsToPollingUnread,
      onUnreadPollSuccess,
      onUnreadPollError
    );

    function onImportantPollSuccess(response) {
      if (angular.isDefined(response.text) && response.text !== null) {
        $rootScope.$emit('importantNotify', response.text);
      }
    }

    const importantPoll = AdPolling.start(
      NotificationsService.important,
      secondsToPolling,
      onImportantPollSuccess,
      () => {}
    );

    $scope.$on('$destroy', () => {
      unreadPoll.stop();
      importantPoll.stop();
      $scope.disableNotificationEvents();
    });

    $scope.disableNotificationEvents = $rootScope.$on(
      'loadNotifications',
      () => {
        unreadPoll.run();
        importantPoll.run();
      }
    );

    function handleSpecialCases(notification) {
      const {params} = notification;

      if (notification.text === 'EXPORT_FINISHED') {
        if (params['type'] === 'case') {
          notification.text = 'EXPORT_FINISHED_CASES';
        } else {
          notification.text = 'EXPORT_FINISHED_MESSAGES';
        }
      }

      if (notification.text === 'INCOMING_RESTART') {
        const miliseconds = params.hours * 60 * 60 + params.minutes * 60;
        const restartTimestamp = parseInt(notification.created) + miliseconds;
        const now = parseInt(new Date().getTime() / 1000);

        notification.params['time_left'] =
          adDateFormatter.notificationTime(restartTimestamp);

        if (now > restartTimestamp) {
          notification.text = 'INCOMING_RESTART_COMPLETED';
          notification.hide = true;
        }
      }

      return notification;
    }

    function getNotifications() {
      NotificationsService.get().then(
        response => {
          /* since it's async may be the case
             when user signout and data
             arrives after that, so weird things
             happens when user data is not available anymore.
             Check if auth is active in order to prevent this.
          */
          if ($rootScope.authActive) {
            angular.forEach(response, elem => {
              elem.ago = adDateFormatter.notificationTime(elem.created);
              elem.icon = getIcon(elem);
              elem.hide = false;
              elem = handleSpecialCases(elem);
            });

            $scope.notifications = response;
            $scope.loading = false;
          }
        },
        () => {
          $scope.notificationsError = true;
          $scope.loading = false;
        }
      );
    }

    async function readSelectedNotifications(notificationIds) {
      await NotificationsService.readSelected({notifications: notificationIds});
      NotificationsService.unread().then(response => {
        onUnreadPollSuccess(response);
      })
    }

    function handleOpenUrl(url) {
      $window.open(url);
    }

    function handleOpenCase(caseId) {
      const path = $location.url();
      if (path.indexOf('admin') > -1 || path.indexOf('analytics') > -1) {
        handleOpenUrl(`/#/case/${caseId}`);
      } else {
        CaseDetailManager.openCaseState(caseId);
      }
    }

    function handleExportNotification() {
      handleOpenUrl('/#/admin/export/');
    }

    function handleAssignationNotification() {
      handleOpenUrl('/#/admin/assignments');
    }

    function getIcon(notification) {
      if (notification.text === 'ASSIGNED_BY') {
        return '🔀';
      } else if (notification.text === 'LINK') {
        return '🔗';
      } else if (notification.text === 'NEW_VERSION') {
        return '📣';
      } else if (notification.text === 'MAX_RETWEETS_REACHED') {
        return '⚠️';

      } else if (
        notification.text === 'CASE_COMMENTED' ||
        notification.text === 'CASE_TAGGED_COMMENT'
      ) {
        return '💬';
      } else if (
        notification.text === 'TOKEN_EXPIRED_ADMIN' ||
        notification.text === 'TOKEN_EXPIRED_CM'
      ) {
        return '⚠️';
      } else if (notification.text === 'VALID_TOKEN') {
        return '✅';
      } else if (notification.text === 'OUTBOUND_MESSAGE_FAILURE') {
        return '⚠️';
      }
      if (exportOptions.indexOf(notification.text) > -1) {
        return '📁';
      }
      if (restartOptions.indexOf(notification.text) > -1) {
        return '🕒';
      }
    }

    function getType(notification) {
      if (notification.severity){
        return notification.severity.toLowerCase();
      }
      const infoTypes = ['ASSIGNED_BY', 'LINK', 'NEW_VERSION', 'CASE_COMMENTED', 'WHATSAPP_CAMPAIGN_FINISHED_LOADING'] ;
      if (
        infoTypes.includes(notification.text) ||
        restartOptions.indexOf(notification.text) > -1
      ) {
        return 'info';
      } else if (notification.text === 'MAX_RETWEETS_REACHED') {
        return 'danger';
      } else if (
        notification.text === 'VALID_TOKEN' ||
        exportOptions.indexOf(notification.text) > -1
      ) {
        return 'success';
      } else {
        return 'warning';
      }
    }

    $scope.readNotifications = function () {
      if (!$scope.loading && $rootScope.authActive) {
        $scope.loading = true;
        $scope.unreadNotifications = 0;
        $scope.toasts = [];
        $scope.reducedToasts = [];
        getNotifications();
      }
    };

    function checkNotificationStatus(notificationStatus) {
      if (notificationStatus) {
        return 'ACTIVE';
      } else {
        return 'DISABLED';
      }
    }
    $scope.toggleaAudioPending = localStorageService.get('topBarBellAudio');
    if ($scope.toggleaAudioPending === null) {
      $scope.toggleaAudioPending = false;
    }

    $scope.toggleAudioNotify = `TOPBAR_AUDIO_NOTIFICATION_IS_${checkNotificationStatus(
      $scope.toggleaAudioPending
    )}`;

    $scope.toggleTopBarBellAudio = function () {
      $scope.toggleaAudioPending = !$scope.toggleaAudioPending;
      localStorageService.set('topBarBellAudio', $scope.toggleaAudioPending);
      $scope.toggleAudioNotify = `TOPBAR_AUDIO_NOTIFICATION_IS_${checkNotificationStatus(
        $scope.toggleaAudioPending
      )}`;
    };

    $scope.toggleToastPending = localStorageService.get('topBarBellToast');
    if ($scope.toggleToastPending === null) {
      $scope.toggleToastPending = false;
    }
    $scope.toggleToastNotification = `TOPBAR_TOAST_NOTIFICATION_IS_${checkNotificationStatus(
      $scope.toggleToastPending
    )}`;

    $scope.toggleTopBarBellToast = function () {
      $scope.toggleToastPending = !$scope.toggleToastPending;
      localStorageService.set('topBarBellToast', $scope.toggleToastPending);
      $scope.toggleToastNotification = `TOPBAR_TOAST_NOTIFICATION_IS_${checkNotificationStatus(
        $scope.toggleToastPending
      )}`;
    };

    $scope.handleNotification = function (notifications) {
      const notification = notifications.toast;
      $scope.closeToast(notifications);
      if (notification.text === 'ASSIGNED_BY') {
        handleOpenCase(notification.params['case_id']);
      } else if (
        notification.text === 'NEW_VERSION' ||
        notification.text === 'LINK'
      ) {
        handleOpenUrl(notification.url);
      } else if (
        notification.text === 'MAX_RETWEETS_REACHED' ||
        notification.text === 'CASE_COMMENTED' ||
        notification.text === 'CASE_TAGGED_COMMENT'
      ) {
        handleOpenCase(notification.params['case_id']);
      }
      if (exportOptions.indexOf(notification.text) > -1) {
        handleExportNotification();
      }
    };

    $scope.getSocialNetworkErrorMessage = function (errorDetail) {
      const {sn, code, subcode} = errorDetail;
      const {language: locale} = $scope.user.profile;
      return snErrorMessage.getMessage({
        sn,
        code,
        subcode,
        locale
      });
    };

    $scope.toasts = [];
    $scope.reducedToasts = [];
  }
  module.controller('BellNotificationsCtrl', BellNotificationsCtrl);
})(angular.module('postCenterWebClientApp'));
