(function(module) {
  CaseDetailsService.$inject = [];
  function CaseDetailsService() {
    return {
      params: {},
      set(name, params = {}) {
        this.params[name] = params;
      },
      get(name) {
        return this.params[name];
      },
      clear() {
        this.params = {};
      }
    };
  }
  module.factory('CaseDetailsService', CaseDetailsService);
})(angular.module('postCenterWebClientApp'));
