'use strict';

var app = angular.module('postCenterWebClientApp');
app.service('AdActiveUser', function Polling($window, $rootScope, UserService) {
  const self = this;

  this.timeToRefresh = 5 * 1000;
  this.checkUserActivity = function () {
    this.lastTime = Date.now();
    $window.onload = setUserActive;
    $window.onmousemove = setUserActive;
    $window.onclick = setUserActive;
    $window.onscroll = setUserActive;
    $window.onkeypress = setUserActive;

    // Stop tracking user and sending requests
    $rootScope.$on('stopTracking', () => this.stop());

    function setUserActive() {
      var now = Date.now();
      var delta = now - self.lastTime;
      if (delta > self.timeToRefresh) {
        $rootScope.$broadcast('userActive', {
          key: 'now',
          value: now
        });
        self.lastTime = now;
        UserService.updateActivityUser();
      }
    }

    this.stop = function () {
      $window.onload = null;
      $window.onmousemove = null;
      $window.onclick = null;
      $window.onscroll = null;
      $window.onkeypress = null;
    };

    return this;
  };
});
