Vue.component('ToggleSwitch', {
  props: {
    active: {type: Boolean, default: false}
  },
  methods: {
    notifyChange() {
      // General event, for any change
      this.$emit('switched');
      // Event for specific changes:
      // if active, notify when deactivated (and viceversa)
      this.$emit(this.active ? 'deactivated' : 'activated');
    }
  },
  template: `
    <label class="btn-switch">
      <input type="checkbox" :checked="active" @click="notifyChange()">
      <span class="slider round"></span>
    </label>
  `
});
Vue.component('ToggleSwitchModel', {
  inheritAttrs: false,

  model: {
    prop: 'checked',
    event: 'change'
  },
  props: {
    checked: Boolean
  },
  template: `
    <label class="btn-switch">
      <input
        type="checkbox"
        v-bind="$attrs"
        v-bind:checked="checked"
        v-on:change="$emit('change', $event.target.checked)"
      />
      <span class="slider round"></span>
    </label>
  `
});
