/* eslint-disable prefer-arrow-callback */
'use strict';

var app = angular.module('postCenterWebClientApp');
app.service('InitialData', InitialData);

InitialData.$inject = [
  '$q',
  '$rootScope',
  '$window',
  'UsersService',
  'BotService',
  'UserService',
  'EstablishmentService',
  'CustomData',
  'DepartmentService',
  'SocialAccount',
  'SocialAccountService',
  'TALKCENTER_TOKEN',
  'NotificationGroupService',
  'WorkflowStatuses',
  'BannedKeywordsService',
  'adChatService',
  'FireTagService',
  'ThirdPartyService'
];

function InitialData(
  $q,
  $rootScope,
  $window,
  UsersService: UsersService,
  BotService,
  UserService: UserService,
  EstablishmentService: EstablishmentService,
  CustomData,
  DepartmentService: DepartmentService,
  SocialAccount,
  SocialAccountService: SocialAccountService,
  TALKCENTER_TOKEN,
  NotificationGroupService,
  WorkflowStatuses,
  BannedKeywordsService,
  adChatService: AdChatService,
  FireTagService,
  ThirdPartyService
) {
  function getGroups() {
    return UsersService.getGroups().then(function (groups) {
      $rootScope.groups = groups;
      return groups;
    });
  }
  // para evitar el doble registro =(
  let registered = false;
  const setEstablishment = (name, id) => {
    if (!registered) {
      FireTagService.setGlobalConfig({
        establishment_id: id,
        establishment_name: name
      });
      registered = true;
    }
  };
  function getEstablishment(): Promise<Establishment> {
    return EstablishmentService.getEstablishment().then(establishment => {
      const {name, id} = establishment;
      setEstablishment(name, id);

      $rootScope.establishment = establishment;
      $rootScope.$emit('initialData.establishmentReady');
      return establishment;
    });
  }

  function getSocialAccounts(): Promise<SocialAccount[]> {
    return SocialAccountService.getSocialAccounts().then(function (
      socialAccounts
    ) {
      $rootScope.accounts = SocialAccount.parseSocialAccounts(
        socialAccounts,
        false
      );
      $rootScope.$emit('initialData:accountsReady');
      return socialAccounts;
    });
  }

  function getBots(): Promise<Bot[]> {
    return BotService.getBots().then(function (bots) {
      $rootScope.bots = bots;
      return bots;
    });
  }

  function getDepartments() {
    return DepartmentService.getDepartments().then(function (departments) {
      $rootScope.departments = departments;
      return departments;
    });
  }

  function getChartTeamCMs(): Promise<CmSummary[]> {
    return UsersService.getChartTeamCMs().then(function (cms) {
      $rootScope.communityManagersForChart = cms;
      return cms;
    });
  }

  function getBannedKeywords() {
    return BannedKeywordsService.getBannedKeywords().then(function (responses) {
      $rootScope.bannedKeyword = {
        list: responses
      };
      return responses;
    });
  }

  function getCustomData() {
    return CustomData.getCustomData().then(function (customData) {
      $rootScope.loadedCustomData = true;
      $rootScope.customData = customData;
      return customData;
    });
  }

  function getNotificationUsers() {
    return NotificationGroupService.getNotificationUsers().then(function (
      users
    ) {
      $rootScope.loadedNotificationUsers = true;
      $rootScope.notificationUsers = users;
    });
  }

  function getNotificationGroups() {
    return NotificationGroupService.getNotificationGroups().then(function (
      users
    ) {
      $rootScope.loadedNotificationGroups = true;
      $rootScope.notificationGroups = users;
    });
  }

  function getWorkflowStatus() {
    // This leaves the data inside the service.
    return WorkflowStatuses.getAll().then(function (elements) {
      return elements;
    });
  }
  function getNotificationEntitySearch() {
    return NotificationGroupService.getNotificationEntitySearch().then(
      function (elements) {
        $rootScope.loadedNotificationSearch = true;
        $rootScope.notificationEntitySearch = elements;
        return elements;
      }
    );
  }
  $rootScope.chatServiceInit = false;

  function getUsers() {
    return UsersService.getUsers().then(function (users) {
      $rootScope.users = users;
    });
  }

  function getUsersCms(): Promise<Cm[]> {
    return UsersService.getCms().then(function (cms) {
      $rootScope.communityManagers = cms
      getUsersBots(cms)
      return cms;
    });
  }
  function getUsersBots(cms) {
    return UsersService.getBots().then(function (bots) {
      $rootScope.botCommunityManagerIds = bots.map(bot => bot.cmid);
      $rootScope.communityManagersAndBot = cms.concat(bots);
      return bots;
    });
  }

  function getAllCms(): Promise<[CmSummary[], Cm[]]> {
    return $q.all([getChartTeamCMs(), getUsersCms()])
  }

  this.refreshAdmin = function () {
    var deferred = $q.defer();
    $q.all([
      getEstablishment(),
      getCustomData(),
      getSocialAccounts(),
      getBannedKeywords(),
      getBots(),
      getNotificationEntitySearch(),
      getWorkflowStatus()
    ]).then(
      data => {
        UserService.getProfile().then(
          user => {
            if (user.isAnalystNotAdmin) {
              deferred.resolve(data);
            } else if (user.isSupervisor) {
              $q.all([
                getDepartments(),
                getGroups(),
                getAllCms(),
                getUsers()
              ]).then(
                function (data) {
                  deferred.resolve(data);
                },
                function (error) {
                  deferred.reject(error);
                }
              );
            } else {
              $q.all([
                getDepartments(),
                getGroups(),
                getAllCms(),
                getUsers(),
                getNotificationUsers(),
                getNotificationGroups()
              ]).then(
                function (data) {
                  deferred.resolve(data);
                },
                function (error) {
                  deferred.reject(error);
                }
              );
            }
          },
          error => deferred.reject(error)
        )
      },
      function (error) {
        deferred.reject(error);
      }
    );
    return deferred.promise;
  };

  this.refreshAnalytics = function () {
    var deferred = $q.defer();
    $q.all([
      getEstablishment(),
      getCustomData(),
      getDepartments(),
      getSocialAccounts(),
      getGroups(),
      getAllCms(),
      getUsers(),
      getBannedKeywords(),
      getWorkflowStatus()
    ]).then(
      function (data) {
        $q.all([UserService.getProfile()]).then(
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
      },
      function (error) {
        deferred.reject(error);
      }
    );

    return deferred.promise;
  };

  this.refreshSettings = function () {
    var deferred = $q.defer()
    $q.all([
      getEstablishment(),
      UserService.getProfile(),
      getGroups(),
      getCustomData(),
      getSocialAccounts(),
      getBannedKeywords(),
    ]).then(
      data => deferred.resolve(data),
      error => deferred.reject(error)
    )
    return deferred.promise
  }

  this.refreshOnboarding = function () {
    var deferred = $q.defer();
    $q.all([
      getEstablishment(),
      getGroups()
    ]).then(
      function (data) {
        $q.all([UserService.getProfile()]).then(
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
      },
      function (error) {
        deferred.reject(error);
      }
    );
    return deferred.promise;
  };

  this.getCms = getAllCms;

  this.refreshCRM = function () {
    const deferred = $q.defer()
    $q.all([
      UserService.getProfile(),
      getEstablishment(),
      getSocialAccounts(),
      getCustomData(),
      getDepartments(),
      getAllCms(),
      getBannedKeywords(),
      getNotificationEntitySearch(),
      getBots(),
      getWorkflowStatus()
    ]).then(
      (crmData) => deferred.resolve(crmData),
      (error) => {
        console.error(error)
        deferred.reject(error)
      }
    )
    return deferred.promise
  }

  this.refreshCustomData = function () {
    return getCustomData();
  };

  this.refreshEstablishment = function () {
    return getEstablishment();
  };

  this.refreshSocialAccounts = function () {
    return $q.all([getSocialAccounts()]);
  };

  this.refreshBannedKeywords = function () {
    return getBannedKeywords();
  };

  this.refreshUsersDepartments = function () {
    var deferred = $q.defer();
    $q.all([getUsers(), getAllCms(), getDepartments()]).then(
      function (data) {
        $q.all([UserService.getProfile()]).then(
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
      },
      function (error) {
        deferred.reject(error);
      }
    );
    return deferred.promise;
  };

  this.refreshNotificationUsers = function () {
    return getNotificationUsers();
  };

  this.refreshNotificationGroups = function () {
    return getNotificationGroups();
  };

  this.refreshNotificationEntitySearch = function () {
    getNotificationEntitySearch();
  };
}
