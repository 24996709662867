const SurveyAgents = Vue.component('SurveyAgents', {
  data() {
    return {
      labels: {
        select: 'Filtrar por ejecutivo'
      }
    };
  },
  computed: {
    ...Vuex.mapGetters('agents', ['cms']),
    agentsList() {
      return this.cms.map(({id, name}) => ({
        id,
        name,
        active: false
      }));
    }
  },
  created() {
    this.labels.select = this.$t('labels.select');
  },
  methods: {
    ...Vuex.mapActions('epaAnalytics', ['agentsFilter']),
    filterAgents() {
      const filteredAgents = this.agentsList
        .filter(agent => !agent.active)
        .map(_agent => _agent.id);
      this.agentsFilter({
        agents: filteredAgents
      });
    }
  },
  i18n: {
    messages: {
      es: {
        agents: 'Ejecutivo | Ejecutivos',
        labels: {
          select: 'Filtrar por ejecutivo'
        }
      },
      pt: {
        agents: 'Executivo | Executivos',
        labels: {
          select: 'Filtro por executivo'
        }
      },
      en: {
        agents: 'Agent | Agents',
        labels: {
          select: 'Filter by agent'
        }
      }
    }
  },
  template: `
    <div class="">
      <CustomSelector
        :labels="labels"
        :filter-data="agentsList"
        :change-data="filterAgents"
        :finder="$tc('agents', 1)"
      />
    </div>`
});
