'use strict';

var app = angular.module('postCenterWebClientApp');
app.directive('quotedMessageContent', function () {
  return {
    scope: {
      message: '=',
      openModal: '&',
      showContentAlways: '&',
    },
    templateUrl: 'blocks/message/views/quoted_message_content.html',
    restrict: 'E',
    controller: 'QuotedMessageContentCtrl'
  };
});
