'use strict';

var app = angular.module('postCenterWebClientApp');
app.filter('orderObjectBy', function () {
  return function (items, field, reverse) {
    var filtered = [];
    angular.forEach(items, function (item) {
      filtered.push(item);
    });
    filtered.sort(function (a, b) {
      var result = 0;
      if (a[field] > b[field]) {
        result = 1;
      } else if (a[field] < b[field]) {
        result = -1;
      }
      return result;
    });

    if (reverse) {
      filtered.reverse();
    }

    return filtered;
  };
});
