'use strict';
angular.module('postCenterWebClientApp').controller('AnalyticsClientsCtrl', [
  '$rootScope',
  '$scope',
  '$filter',
  'SocialAccountService',
  'FireTagService',
  function($rootScope, $scope, $filter, SocialAccountService, FireTagService) {
    FireTagService.setPageView({
      title: 'Clientes',
      path: `/${window.location.hash}`
    });
    $rootScope.analyticTab.selected = 'clients';
    $scope.widgets = [];

    $scope.$on('$destroy', () => {
      analyticsGotPeriodOff();
    });

    const analyticsGotPeriodOff = $scope.$on('analytics:gotPeriod', function(event, period) {
      $scope.selectedPeriod = period;
      angular.forEach($scope.widgets, function(widget) {
        widget.period = period;
      });
    });

    function defineCharts(socialAccounts) {
      const identifiedClientsWidget = {
        name: 'indentifiedClients',
        title: 'ANALYTICS_IDENTIFIED_CLIENTS',
        period: $scope.selectedPeriod,
        realtime: false,
        resume: 'total',
        charts: {
          main: {
            type: 'pie',
            stacked: false
          },
          detail: {
            type: 'areaspline',
            stacked: true
          }
        },
        dataScheme: {
          id: 'ClientsIdentified',
          type: 'number',
          metrics: [
            {
              name: 'counter',
              type: 'single',
              key: 'src',
              resolve: null
            },
            {
              name: 'detail',
              key: 'src',
              type: 'parent',
              resolve: null
            },
            {
              name: 'total',
              key: 'src',
              type: 'single',
              resolve: null
            }
          ]
        },
        style: {
          icons: {
            email: 'fa-envelope',
            total: 'fa-arrow-right'
          }
        },
        help: {
          text: $filter('translate')(
            'ANALYTICS_CLIENTS_IDENTIFIED_CLIENTS_HELP_TEXT'
          )
        }
      };

      const servedClientsWidget = {
        name: 'servedClients',
        title: 'ANALYTICS_SERVED_CLIENTS',
        period: $scope.selectedPeriod,
        realtime: false,
        resume: 'total',
        charts: {
          main: {
            type: 'pie',
            stacked: false
          },
          detail: {
            type: 'areaspline',
            stacked: true
          }
        },
        dataScheme: {
          id: 'ClientsServed',
          type: 'number',
          metrics: [
            {
              name: 'counter',
              type: 'single',
              key: 'src',
              resolve: null
            },
            {
              name: 'detail',
              key: 'sa',
              type: 'aggregated',
              resolve: {
                resolver: 'id',
                key: 'username',
                data: socialAccounts
              }
            },
            {
              name: 'total',
              key: 'src',
              type: 'single',
              resolve: null
            }
          ]
        },
        style: {
          icons: {
            email: 'fa-envelope'
          }
        },
        help: {
          text: $filter('translate')(
            'ANALYTICS_CLIENTS_SERVED_CLIENTS_HELP_TEXT'
          )
        }
      };
      $scope.widgets.push(identifiedClientsWidget, servedClientsWidget);
    }

    $scope.loading = true;
    SocialAccountService.getChartSocialAccounts().then(socialAccounts => {
      defineCharts(socialAccounts);
      $scope.loading = false;
    });
  }
]);
