(function(module) {
  'use strict';
  userMetadata.$inject = [];
  function userMetadata() {
    return {
      templateUrl: 'blocks/case/views/user_metadata.html',
      restrict: 'E',
      controller: 'userMetadataCtrl',
      controllerAs: 'vm',
      scope: {
        userObject: '=',
        caseObject: '='
      },
      bindToController: true
    };
  }
  module.directive('userMetadata', userMetadata);
})(angular.module('postCenterWebClientApp'));
