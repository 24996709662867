const DepartmentsSelector = Vue.component('DepartmentsSelector', {
  data() {
    return {
      name: '',
      toggleList: false,
      isChecked: true
    };
  },
  computed: {
    ...Vuex.mapGetters(['getDepartments']),
    getDepartmentsByFilter() {
      return this.getDepartments.filter(department =>
        new RegExp(this.name, 'ig').test(this.$t(department.name))
      );
    },
    isActive() {
      return this.toggleList ? 'active' : '';
    }
  },
  methods: {
    updateTableByDepartments() {
      this.checkList();
    },
    checkList() {
      const inactive = this.getDepartments.find(
        department => !department.active
      );
      this.isChecked = inactive ? false : true;
    },
    toggleSelector(event) {
      if (
        event.target.id !== 'department-search-input' &&
        event.target.id !== 'department-checkbox'
      ) {
        this.toggleList = !this.toggleList;
      }
    },
    closeSelector() {
      this.toggleList = false;
    },
    allSelector() {
      this.getDepartments.map(department => {
        department.active = !this.isChecked;
      });
    }
  },
  template: `
    <div class="ads-selector-wrapper">
        <div
        class="ads-selector departments-selector"
        @click="toggleSelector"
        v-click-outside="closeSelector">
        <div class="ads-selector__header d-flex" :class="isActive">
          <h5 class="bold d-inline">{{ $t('all_departments') }}</h5>
          <span class="fa fa-chevron-down" />
        </div>
        <div v-show="toggleList">
        <label>
          <input
          type="checkbox"
          key="departments-header"
          v-model="isChecked"
          @click="allSelector"
          />
          <span class="mx-1">{{ $t('all_departments') }}</span>
        </label>
        <div class="p-1" >
          <input type="text"
          id="department-search-input"
          class="form-control mb-1"
          :placeholder="$t('inputs.department_finder')"
          v-model="name" />
        </div>
        <p class="p-1" v-if="!getDepartmentsByFilter.length">{{ $t('issues.not_found_departments') }}</p>
          <ul v-else class="list-unstyled">
            <li v-for="department in getDepartmentsByFilter" :key="department.id">
              <label>
                <input type="checkbox"
                  @change="updateTableByDepartments(department)"
                  :name="department.id"
                  v-model="department.active"
                />
                <span class="mx-1">{{ $t(department.name) }}</span>
              </label>
            </li>
          </ul>
        </div>
      </div>
      </div>
    `
});
