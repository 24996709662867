class Campaign {
    id: number
    visual_name: string
    status: string
}

class CampaignService {
    private AdRequest: AdRequestService;
    private CampaignsModel: AdModelFetcher<Campaign[]>;

    constructor(AdRequest: AdRequestService, private $rootScope) {
        this.AdRequest = AdRequest;
        this.CampaignsModel = new AdModelFetcher(this.fetchCampaigns.bind(this));
        AdRequest.loggedOut.subscribe(() => this.CampaignsModel.clear());
    }

    async fetchCampaigns(): Promise<Campaign[]> {
        try {
            const response = await this.AdRequest.get(`/api/v1/whatsapp/campaigns/?page_size=100&status=completed&status=finished_with_errors&status=started`);
            this.$rootScope.campaigns = response.result;
            return response.result;
        } catch (error) {
            console.error(error);
            return [];
        }
    }

    getCampaigns(): Promise<Campaign[]> {
        return this.CampaignsModel.get();
    }
}

angular 
    .module('postCenterWebClientApp')
    .service('CampaignService', CampaignService);