'use strict';

angular
  .module('postCenterWebClientApp')
  .factory('CustomOnlineStatuses', CustomOnlineStatuses);

CustomOnlineStatuses.$inject = ['$q', 'AdRequest'];

function CustomOnlineStatuses($q, AdRequest) {

  var ONLINE_STATUS_FACTOR = -1000000;

  var cosList = [];
  var completeList = [];
  var service = {};

  function onlineStatusFactor(status) {
    return ONLINE_STATUS_FACTOR * status.online_status + status.index;
  }

  service.completeList = function () {
    return angular.copy(completeList);
  };

  service.list = function () {
    return angular.copy(cosList);
  };

  service.fetch = function () {
    cosList = [];
    completeList = [];
    return AdRequest
      .get('/api/v1/custom_online_statuses/')
      .then(function (statuses) {
        _.map(statuses, function(status) {
          completeList.push(status);
          if (!status.deleted){
            cosList.push(status);
          }
        });
        cosList = _.sortBy(cosList, onlineStatusFactor);
        return service.list();
      });
  };

  service.add = function (cos, config) {
    return AdRequest.post('/api/v1/custom_online_status/', cos, config);
  };

  service.addAll = function (cosList, config) {
    return $q.all(_.map(cosList, service.add));
  };

  service.update = function(data) {
    return AdRequest.put('/api/v1/custom_online_status/', data);
  };

  service.updateIndex = function(data) {
    return AdRequest.put('/api/v1/custom_online_status_index/', data);
  };
  service.updateAll = function (cosList, config) {
    return AdRequest.put('/api/v1/custom_online_status/', cosList, config);
  };

  service.remove = function (cos) {
    var path = '/api/v1/custom_online_status/' + cos.resource_id + '/';
    return AdRequest.delete(path);
  };

  service.removeAll = function (cosList) {
     return $q.all(_.map(cosList, service.remove));
  };

  service.getHistory = function(page) {
    if (angular.isUndefined(page) || page === null) {page = 0;}
    var path = '/api/v1/custom_online_status/history/'+page+'/';
    return AdRequest.get(path);
  };
  return service;
}
