/* global chartMixin */
const AderesoChartBar = Vue.component('AderesoChartBar', {
  mixins: [chartMixin],
  methods: {
    redraw() {
      const series = this.chartData.series;
      this.chart.update({
        series,
        xAxis: {
          ...this.chart.xAxis,
          categories: this.chartData.xAxis.categories
        },
        yAxis: this.chartData.yAxis
      });
    },
    render() {
      this.chart = Highcharts.chart(this.$el, this.chartData);
    }
  }
});
const AderesoChart = Vue.component('AderesoChart', {
  mixins: [chartMixin],
  data() {
    return {
      defaultConfig: {
        credits: {
          enabled: false
        },
        global: {
          useUTC: false
        }
      },
      plotOptions: {
        credits: {
          enabled: false
        },
        series: {
          // pointStart: 0,
          marker: {
            enabled: false,
            symbol: 'circle',
            radius: 2,
            states: {
              hover: {
                enabled: true
              }
            }
          },
          events: {
            legendItemClick(event) {
              const {
                target: {userOptions}
              } = event;
              const {series} = this.chart;
              const lengthArray = series.filter(serie => serie.visible).length;
              if (
                lengthArray > 1 ||
                (lengthArray === 1 && userOptions.visible === false)
              ) {
                return true;
              }
              return false;
            }
          }
        }
      },
      width: 0
    };
  },
  computed: {
    resized() {
      return {
        visible: false,
        width:
          this.width < 0 ? `${this.width + 5}px !important` : '100% !important',
        height: `${this.height}px`
      };
    }
  },
  watch: {
    chartData() {
      this.redraw();
    },
    resize() {
      this.width = this.$el?.parentElement?.clientWidth;
      this.chart.reflow();
    }
  },
  mounted() {
    this.width = this.$el?.parentElement?.clientWidth;
    this.render();
  },
  methods: {
    redraw() {
      const {
        config: {granularity},
        series
      } = this.chartData;
      // this.chart.series.map(serie => {
      //   const lost = series[serie.index];
      //   if (!lost) {
      //     if (serie.visible) {
      //       serie.hide();
      //     } else {
      //       serie.show();
      //     }
      //   }
      // });
      this.chart.update({
        series,
        tooltip: {
          xDateFormat: granularity !== 'minute' ? '%d/%m/%Y' : '%H:%M'
        }
      });
    },
    render() {
      const {
        config: {
          label = '',
          title = '',
          granularity,
          type = 'line',
          yAxisTitle = 'Y',
          xAxisTitle = 'X',
          yAxis1Title = 'Y'
        },
        series
      } = this.chartData;
      this.chart = Highcharts.chart(this.$el, {
        ...this.defaultConfig,
        plotOptions: {
          ...this.plotOptions
        },
        chart: {
          marginBottom: 80,
          marginRight: 50,
          type,
          zoomType: 'x'
        },
        title: {text: title},
        yAxis: [
          {
            title: {
              text: yAxisTitle
            },
            opposite: false,
            index: 0
          },
          {
            title: {
              text: yAxis1Title
            },
            type: 'percent',
            opposite: true,
            index: 1,
            labels: {
              formatter() {
                return `${this.value}%`;
              }
            }
          }
        ],
        xAxis: {
          title: {
            text: xAxisTitle
          },
          type: 'datetime'
        },
        tooltip: {
          xDateFormat: granularity !== 'minute' ? '%d/%m/%Y' : '%H:%M'
        },
        label,
        series,
        legend: {
          // eslint-disable-next-line id-length
          y: 15,
          maxHeight: 45,
          itemDistance: 10,
          itemMarginTop: 5,
          itemMarginBottom: 5,
          marginBottom: 15,
          align: 'center',
          verticalAlign: 'bottom',
          floating: false
        }
      });
    }
  },
  template: `
    <div class="adereso-chart" :style="resized"></div>
  `
});
