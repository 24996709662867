'use strict';

var app = angular.module('postCenterWebClientApp');

app.directive('socialAccountOption', function() {
  return {
    templateUrl:
      'blocks/admin/views/social_accounts/social_account_option.html',
    restrict: 'E',
    scope: {
      option: '=',
      accountIndex: '=',
      isChild: '@'
    },
    controller: [
      '$scope',
      'AdUtils',
      'AdNotification',
      '$timeout',
      '$filter',
      function($scope, AdUtils, AdNotification, $timeout, $filter) {
        // to prevent child scope assignment hell
        $scope.state = {editor: null};

        $scope.defaultChatLogo = 'https://app.talkcenter.io/assets/logo.png';

        $scope.addTag = function(option, index, tag) {
          const finalTag = '{{' + tag.key + '}}';
          if (option.value === null || option.value === undefined) {
            option.value = [];
          }
          if (
            option.value[index] === null ||
            option.value[index] === undefined
          ) {
            option.value[index] = '';
          }
          option.value[index] = option.value[index] + ' ' + finalTag;
        };

        $scope.addSignatureTag = function(tag) {
          const finalTag = '{{' + tag.key + '}}';
          $scope.state.editor.summernote('insertText', finalTag);
        };

        $scope.validateImage = function(fileData, successFn, errorFn) {
          const MAX_IMAGE_SIZE = 400;
          const MIN_IMAGE_SIZE = 180;
          AdUtils.loadImage(fileData)
            .then(function(image) {
              let msg = '';
              let isValid = true;
              if (image.width != image.height) {
                msg = $filter('translate')('SOCIALACCOUNTS_AVATAR_NOT_SQUARE');
                isValid = false;
              } else if (image.width > MAX_IMAGE_SIZE) {
                msg = $filter('translate')('SOCIALACCOUNTS_AVATAR_TOO_BIG');
                isValid = false;
              } else if (image.width < MIN_IMAGE_SIZE) {
                msg = $filter('translate')('SOCIALACCOUNTS_AVATAR_TOO_SMALL');
                isValid = false;
              }
              if (isValid) {
                successFn(fileData);
              } else {
                errorFn();
                AdNotification.notify(msg, 'error');
              }
            })
            .catch(function(exception) {
              errorFn();
            });
        };

        $scope.successImgFn = function(url) {
          $scope.option.value = url;
        };

        $scope.errorImgFn = function(exception) {
          const msg = $filter('translate')(
            'SOCIALACCOUNTS_CHAT_LOGO_UPLOAD_ERROR'
          );
          AdNotification.notify(msg, 'error');
        };

        $scope.snippetCallback = function(option) {
          option.callback(option);
        };

        $scope.updateValueByUnit = function(option) {
          if (
            option.unit === option.selectUnit &&
            !option.selectValues.includes(option.value)
          ) {
            option.value = option.selectValues[0];
          }
          option.selectValue = option.value.toString();
        };
      }
    ]
  };
});
