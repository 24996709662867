(module => {
  'use strict';
  PlaygroundCtrl.$inject = [];
  module.controller('PlaygroundCtrl', PlaygroundCtrl);
  function PlaygroundCtrl() {
    const vm = this;
    vm.$onInit = () => {};
    vm.playgroundI18n = new VueI18n({
      locale: 'en',
      fallbackLocale: ['es', 'en', 'pt'],
      silentTranslationWarn: true
    });
    vm.playgroundStore = new Vuex.Store({
      state: {},
      getters: {},
      mutations: {},
      actions: {}
    });
    vm.playgroundVue = new Vue({
      el: document.querySelector('#playground')
    });
  }
})(angular.module('postCenterWebClientApp'));
