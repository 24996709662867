(function(module) {
  'use script';
  caseOmnichannelCtrl.$inject = [
    '$scope',
    '$filter',
    'PersonData',
    'UserService',
    'AdAvailableSn',
    'AdNotification',
    'CaseDetailManager',
    'CaseDetailsService',
    'EstablishmentService',
    'SocialAccountService'
  ];
  function caseOmnichannelCtrl(
    $scope,
    $filter,
    PersonData,
    UserService,
    AdAvailableSn,
    AdNotification,
    CaseDetailManager,
    CaseDetailsService,
    EstablishmentService: EstablishmentService,
    SocialAccountService: SocialAccountService
  ) {
    const vm = this

    vm.$onInit = () => {
      vm.userObject = vm.userObject
        ? vm.userObject
        : CaseDetailsService.get('userObject');

      init().then(() => initOmnichannel())
    }

    const init = () => {
      return Promise.all([
        EstablishmentService.getEstablishment(),
        SocialAccountService.getSocialAccounts()
      ]).then(results => {
        const establishment = results[0]
        const accounts = results[1]
        vm.accounts = accounts
        vm.searchParam = 'force_name'
        vm.merging = false
        vm.searching = false
        vm.searched = false
        vm.searchResults = []

        vm.snName = AdAvailableSn.getTitle
        vm.countryIdName = establishment.country.resident_id
      })
    }

    const userUpdateListenerOff = $scope.$on('userCase:hasBeenUpdated', (_event, userCase) => {
      vm.userObject = userCase;
      init().then(() => initOmnichannel())
    })

    $scope.$on('$destroy', () => {
      userUpdateListenerOff();
    });

    function getUsersFromPerson() {
      if (!!vm.userObject.person) {
        PersonData.getUsersByPerson(vm.userObject.person).then(
          data => {
            vm.personUsers = data.users;
            vm.linkedUsers = data.users.filter(user => {
              return user.id !== vm.userObject.resource_id;
            });
          },
          () => {
            vm.personUsers = [vm.userObject];
            vm.linkedUsers = [];
          }
        );
      } else {
        vm.personUsers = [vm.userObject];
        vm.linkedUsers = [];
      }
    }

    function initOmnichannel() {
      const {social_accounts = []} = vm.userObject;
      if (social_accounts.length === 1) {
        const account = vm.accounts.find(
          account => account.id === social_accounts[0]
        );
        vm.currentAccount = account ? account.visual_name : '';
      }
      getUsersFromPerson();
    }

    vm.searchUsers = function() {
      vm.searching = true;
      const method = vm.searchParam;
      const userId = vm.userObject.resource_id;
      vm.searched = false;
      UserService.searchRelatedUsers(method, userId).then(
        data => {
          var personUsersId = vm.personUsers.map(user => {
            return user.id ? user.id : user.resource_id;
          });
          vm.searchResults = data.filter(user => {
            return !personUsersId.includes(user.resource_id);
          });
          vm.searchResults.forEach(user => {
            if (!!user.person) {
              PersonData.getUsersByPerson(user.person).then(function(data) {
                user.usersInPerson = Math.max(data.users.length - 1, 0);
              });
            } else {
              user.usersInPerson = 0;
            }
          });
          vm.searched = true;
          vm.searching = false;
          vm.searchResults.forEach(user => {
            const account = vm.accounts.find(function(_account) {
              return _account.id === user.social_accounts[0];
            });
            if (account) {
              vm.currentAccount = account.visual_name;
            }
          });
        },
        () => {
          vm.searched = true;
          vm.searching = false;
        }
      );
    };

    vm.mergeUsers = function(otherUser) {
      if (vm.merging) {
        return;
      }
      vm.merging = true;
      otherUser.merging = true;
      const {resource_id: otherUserId} = otherUser;
      const {resource_id: myUserId} = vm.userObject;
      PersonData.mergeUsers(myUserId, otherUserId).then(
        data => {
          CaseDetailManager.updateUser();
          vm.personUsers = data.users;
          vm.linkedUsers = data.users.filter(user => {
            return user.id !== vm.userObject.resource_id;
          });
          vm.searchUsers();
          vm.merging = false;
          otherUser.merging = false;
          AdNotification.notify(
            $filter('translate')('CASE_OMNICHANNEL_MERGE_OK'),
            'success'
          );
        },
        error => {
          vm.merging = false;
          otherUser.merging = false;
          AdNotification.notify(
            $filter('translate')('CASE_OMNICHANNEL_MERGE_FAIL'),
            'error'
          );
        }
      );
    };

    vm.goToUser = function(userObj) {
      if (angular.isDefined(userObj.id)) {
        CaseDetailManager.searchUserById(userObj.id);
      } else {
        CaseDetailManager.searchUserById(userObj.resource_id);
      }
    };

    vm.removeMyPerson = function(userId) {
      vm.removing = true;
      vm.userObject.removing = true;
      PersonData.removePerson(userId).then(
        data => {
          CaseDetailManager.updateUser();
          vm.personUsers = [data];
          vm.linkedUsers = [];
          if (vm.searched) {
            vm.searchUsers();
          }
          vm.removing = false;
          vm.userObject.removing = false;
        },
        () => {
          vm.removing = false;
          vm.userObject.removing = false;
          AdNotification.notify(
            $filter('translate')('CASE_OMNICHANNEL_REMOVE_FAIL'),
            'error'
          );
        }
      );
    };

    vm.removePersonToUser = function(user) {
      vm.removing = true;
      user.removing = true;
      var userId = user.id;
      PersonData.removePerson(userId).then(
        () => {
          initOmnichannel();
          vm.removing = false;
          user.removing = false;
        },
        () => {
          vm.removing = false;
          user.removing = false;
          AdNotification.notify(
            $filter('translate')('CASE_OMNICHANNEL_REMOVE_FAIL'),
            'error'
          );
        }
      );
    };
  }

  module.controller('caseOmnichannelCtrl', caseOmnichannelCtrl);
})(angular.module('postCenterWebClientApp'));
