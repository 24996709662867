'use strict';

angular.module('postCenterWebClientApp')
  .directive('newColumnModal', function () {
    return {
      restrict: 'E',
      templateUrl: 'blocks/column/views/new_column_modal.html',
      controller: 'NewColumnModalCtrl'
    };
  });
