angular.module('postCenterWebClientApp').component('adToggleSwitch', {
  template: `
      <label class="btn-switch"
             ng-class="{ 'switch__props-only': vm.propsOnly }">
        <input type="checkbox"
               ng-model="vm.active"
               ng-change="vm.changed(vm.active)">
        <span class="slider round"></span>
      </label>
    `,
  bindings: {
    active: '=',
    propsOnly: '<',
    onChange: '&',
    onActive: '&',
    onInactive: '&'
  },
  controller: function() {
    const vm = this;

    vm.$onInit = () => {};

    vm.changed = (newValue) => {
      vm.active = newValue;
      vm.onChange({value: vm.active});
    };

  },
  controllerAs: 'vm'
});
