const CountersHeader = Vue.component('CountersHeader', {
  props: {
    counters: {
      type: Array,
      default: () => []
    }
  },
  template: `
    <ul class="ads-counters-header list-unstyled scrollable scrollable-x">
      <li v-for="counter in counters">
        <div :content="counter.tooltip" class="card text-center" v-tippy="{
          placement: 'top',
          arrow: true,
          animateFill: false,
          boundary: 'window'
        }">
          <h2 class="mt-1">{{counter.result}}</h2>
          <p class="text-overflow">{{counter.label}}</p>
          <span class="border" />
        </div>
      </li>
    </ul>
  `
});
