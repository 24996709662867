angular.module('postCenterWebClientApp').directive('crmIntegration', () => {
  return {
    templateUrl: 'blocks/admin/views/integration_config/crm-integration.html',
    restrict: 'E',
    scope: {
      config: '=',
      crmActive: '@'
    },
    controller: [
      '$scope',
      'CrmService',
      '$filter',
      'AdNotification',
      function ($scope, CrmService, $filter, AdNotification) {
        $scope.savingConfig = false;
        $scope.saveCrmIntegrationConfig = () => {
          $scope.savingConfig = true;
          CrmService.configCrm($scope.crmActive.toLowerCase(), $scope.config)
            .then(() => {
              AdNotification.notify(
                $filter('translate')('ADMIN_INTEGRATION_SAVE_SUCCESS'),
                'success'
              );
            })
            .catch(() => {
              AdNotification.notify(
                $filter('translate')('ADMIN_INTEGRATION_SAVE_ERROR'),
                'error'
              );
            })
            .finally(() => ($scope.savingConfig = false));
        };
      }
    ]
  };
});
