const signupMessages = {
  es: {
    signUp: {
      titles: {
        firstStep: 'Crea tu cuenta',
        secondStep: 'Cuéntanos más'
      },
      subtitles: {
        secondStep:
          'Queremos brindarte un mejor servicio, cuéntanos un poco sobre tu empresa.'
      },
      step: 'Paso',
      keywordOf: 'de',
      firstName: {
        label: 'Nombre',
        errors:
          'Es necesario tu nombre. | Tu nombre debe tener al menos 3 caracteres y no exceder los 100 caracteres.| No debe tener caracteres especiales.'
      },
      lastName: {
        label: 'Apellido',
        errors:
          'Es necesario al menos un apellido. | Tu apellido debe tener al menos 3 caracteres y no exceder los 100 caracteres.| No debe tener caracteres especiales.'
      },
      establishment: {
        label: 'Nombre de empresa',
        errors:
          'Debes ingresar el nombre de tu empresa. | El nombre de tu empresa debe tener al menos 3 caracteres y no exceder los 50 caracteres. | Esta empresa ya existe en Adereso.'
      },
      email: {
        label: 'Correo corporativo',
        errors:
          'Revisa que tu correo este escrito correctamente. | Tu correo no debe tener más de 70 caracteres. | Este correo ya tiene una cuenta con nosotros. | Algo ocurrió por favor intenta más tarde.'
      },
      password: {
        label: 'Contraseña',
        errors: 'Tu contraseña debe tener al menos 4 caracteres.'
      },
      industry: {
        label: '¿A qué industria pertenece?',
        placeholder: 'Elige industria'
      },
      solution: {
        label: '¿Qué solución estás buscando?',
        placeholder: 'Elige solución'
      },
      code: {
        placeholder: 'Código'
      },
      phone: {
        label: 'Si deseas nos contactaremos contigo por teléfono.',
        placeholder: 'Teléfono de contacto',
        errors: 'No es un número válido, por favor ingresa sólo números.'
      },
      industries: [
        'Alimentos',
        'Bienes raíces',
        'Construcción',
        'Energía',
        'Finanzas',
        'Hotelería y alojamientos',
        'Publicidad y medios',
        'Retail',
        'Salud',
        'Transporte',
        'Otro'
      ],
      solutions: [
        'Unificar los canales digitales (omnicanalidad)',
        'Conectar WhatsApp Business API',
        'Automatizar respuestas',
        'Sistema de gestión de tickets',
        'Métricas de desempeño',
        'Otra'
      ],
      terms: {
        errors:
          'Por favor acepta nuestros términos y condiciones. | Tenemos problemas con tu registro, puedes volver a intentar o revisar que todo este en orden.'
      },
      form: {
        terms:
          'He leído y aceptado las <a href="https://www.adere.so/legal/terminos" target="_blank">Condiciones de uso</a> y la <a href="https://www.adere.so/legal/politica-de-privacidad" target="_blank">Política de privacidad</a>.',
        tryAgain: 'Intentar nuevamente',
        check: 'Revisar formulario'
      },
      links: {
        login: '#/login'
      },
      quote: {
        paragraph:
          'En nuestro trabajo con Adereso destaco como unen el servicio de una gran tecnología creada y la disposición humana detrás para hacer soporte a cualquier inquietud que tenemos.',
        author: 'Felipe Toro <br>Jefe de Experiencia de E-commerce en Walmart.'
      },
      btn: {
        next: 'Siguiente',
        skip: 'Omitir'
      },
      haveAccount: '¿Ya tienes una cuenta?',
      login: 'Inicia sesión',
      errorMessage: 'Algo ha fallado por favor intenta nuevamente.'
    }
  },
  en: {
    signUp: {
      titles: {
        firstStep: 'Create account',
        secondStep: 'Tell us more'
      },
      subtitles: {
        secondStep: 'We would like to know a bit more about your company'
      },
      step: 'Step',
      keywordOf: 'of',
      firstName: {
        label: 'First name',
        errors:
          'First name is required. | Your first name must be between 3 and 100 characters. | Special characters are not allowed.'
      },
      lastName: {
        label: 'Last name',
        errors:
          'Last name is required. | Your last name must be between 3 and 100 characters. | Special characters are not allowed.'
      },
      establishment: {
        label: 'Company name',
        errors:
          'Company name is required. | The Company must be between 3 and 50 characters. | Your company already has an account.'
      },
      email: {
        label: 'Email',
        errors:
          'Please check if your email is written correctly. |Your email must have 70 characters or less. |This email already has an account with us. | Something happened, please try again later.'
      },
      password: {
        label: 'Password',
        errors: 'Your password must be at least 4 characters long.'
      },
      industry: {
        label: 'Which business does your company belong to?',
        placeholder: 'Choose business'
      },
      solution: {
        label: 'Wich solution are you looking for?',
        placeholder: 'Choose a solution'
      },
      code: {
        placeholder: 'Code'
      },
      phone: {
        label: 'In case we need to get in touch',
        placeholder: 'Contact phone',
        errors: "it isn't a valid number. Please use numbers only"
      },
      industries: [
        'Food',
        'Real State',
        'Construction',
        'Energy',
        'Finance',
        'Hospitality',
        'Media',
        'Retail',
        'Health',
        'Transport',
        'Other'
      ],
      solutions: [
        'Unify digital channels (omnichannel)',
        'Connect WhatsApp Business API',
        'Automate responses',
        'Ticket management system',
        'Performance metrics',
        'Other'
      ],
      terms: {
        errors:
          'Please accept the terms and conditions of the Adereso service.| We have problems with your registration, you can try again or check that everything is in order'
      },
      form: {
        terms:
          'I have read and accept the <a href="https://www.adere.so/legal/terminos" target="_blank">Terms and conditions</a> and the <a href="https://www.adere.so/legal/politica-de-privacidad" target="_blank">Privacy policy</a> of the Adereso service.',
        tryAgain: 'Try again',
        check: 'Check my data'
      },
      links: {
        login: '#/login'
      },
      quote: {
        paragraph:
          'En nuestro trabajo con Adereso destaco como unen el servicio de una gran tecnología creada y la disposición humana detrás para hacer soporte a cualquier inquietud que tenemos.',
        author: 'Felipe Toro <br>Jefe de Experiencia de E-commerce en Walmart.'
      },
      btn: {
        next: 'Next',
        skip: 'Skip'
      },
      haveAccount: 'Already have an account?',
      login: 'Login',
      errorMessage: 'Something happened, please try again.'
    }
  },
  pr: {
    signUp: {
      titles: {
        firstStep: 'Crie sua conta',
        secondStep: 'Conte-nos mais'
      },
      subtitles: {
        secondStep:
          'Queremos atendê-lo melhor, conte-nos um pouco sobre a sua empresa.'
      },
      step: 'Etapa',
      keywordOf: 'de',
      firstName: {
        label: 'Nome',
        errors:
          'Seu nome é necessário. | Seu nome deve conter de 3 a 100 caracteres. | Não deve ter caracteres especiais.'
      },
      lastName: {
        label: 'Sobrenome',
        errors:
          'É necessário pelo menos um sobrenome. | Seu sobrenome deve conter de 3 a 100 caracteres. | Não deve ter caracteres especiais.'
      },
      establishment: {
        label: 'Nome da empresa',
        errors:
          'Você deve inserir o nome da sua empresa. |O nome da sua empresa deve conter de 3 a 100 caracteres. | Esta empresa já existe no Adereso.'
      },
      email: {
        label: 'Email corporativo',
        errors:
          'Verifique se o seu e-mail está escrito corretamente. | Seu e-mail deve ter 70 caracteres ou menos. | Este e-mail já tem uma conta conosco. | Algo aconteceu, por favor tente novamente mais tarde. '
      },
      password: {
        label: 'Senha',
        errors: 'Sua senha deve ter pelo menos 4 caracteres.'
      },
      industry: {
        label: 'A qual setor você pertence?',
        placeholder: 'Escolha o setor'
      },
      solution: {
        label: 'Que solução você está procurando?',
        placeholder: 'Escolha a solução'
      },
      code: {
        placeholder: 'Código'
      },
      phone: {
        label: 'Se desejar, entraremos em contato por telefone.',
        placeholder: 'Telefone de contato',
        errors: 'Este não é um número válido. Use apenas números'
      },
      industries: [
        'Comida',
        'Real State',
        'Construção',
        'Energia',
        'Finança',
        'Hospitalidade',
        'Meios de comunicação',
        'Varejo',
        'Saúde',
        'Transporte',
        'De outros'
      ],
      solutions: [
        'Unificar canais digitais (omnichannel)',
        'Connect WhatsApp Business API',
        'Automatizar respostas',
        'Sistema de gerenciamento de tíquetes',
        'Métricas de desempenho',
        'Outro'
      ],
      terms: {
        errors:
          'Por favor, aceite nossos termos e condições.|Temos problemas com o seu registo, pode tentar novamente ou verificar se está tudo em ordem'
      },
      form: {
        terms:
          'Li e aceitei o <a href="https://www.adere.so/legal/terminos" target="_blank">Termos de uso</a> e o <a href="https://www.adere.so/legal/politica-de-privacidad" target="_blank">Política de Privacidadey</a>.',
        tryAgain: 'Tente novamente',
        check: 'revisão do formulário'
      },
      links: {
        login: '#/login'
      },
      quote: {
        paragraph:
          'En nuestro trabajo con Adereso destaco como unen el servicio de una gran tecnología creada y la disposición humana detrás para hacer soporte a cualquier inquietud que tenemos.',
        author: 'Felipe Toro <br>Jefe de Experiencia de E-commerce en Walmart.'
      },
      btn: {
        next: 'Próximo',
        skip: 'Pular'
      },
      haveAccount: 'Você já tem uma conta?',
      login: 'iniciar sessão',
      errorMessage: 'Algo aconteceu, por favor tente novamente.'
    }
  }
};
