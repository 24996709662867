'use strict';

var app = angular.module('postCenterWebClientApp');

app.directive('massiveActionsCloseTicketsList', function() {
  return {
    templateUrl:
      'blocks/admin/views/massive_actions/close_tickets/close_tickets_list.html',
    restrict: 'E',
    scope: {refreshParentHistory: '<'},
    controller: CloseTicketsListDirective
  };
});

CloseTicketsListDirective.$inject = [
  '$scope',
  'CloseTicketsService',
  'AdNotification',
  '$filter'
];

function CloseTicketsListDirective(
  $scope,
  CloseTicketsService,
  AdNotification,
  $filter
) {
  $scope.selectedTickets = [];
  $scope.formLoading = false;

  $scope.onTagAdding = function(tag) {
    return /^\d+$/.test(tag.text);
  };

  $scope.closeTickets = function() {
    $scope.formLoading = true;

    // flat list
    var ticketsToClose = $scope.selectedTickets.map(function(ticket) {
      return ticket.text;
    });

    var data = {
      tickets: ticketsToClose
    };

    CloseTicketsService.closeTicketList(data).then(
      function(result) {
        $scope.selectedTickets = [];
        var message = $filter('translate')('MASSIVE_ACTION_CLOSE_SUCCESS');
        AdNotification.notify(message, 'success');
        $scope.formLoading = false;
        $scope.refreshParentHistory();
      },
      function(error) {
        var message = $filter('translate')('MASSIVE_ACTION_CLOSE_ERROR');
        $scope.formLoading = false;
        AdNotification.notify(message, 'error');
      }
    );
  };
}
