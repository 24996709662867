(module => {
  'use strict';
  EstablishmentCtrl.$inject = [
    '$scope',
    '$rootScope',
    '$timeout',
    'EstablishmentService',
    'AdNotification'
  ];
  function EstablishmentCtrl(
    $scope,
    $rootScope,
    $timeout,
    EstablishmentService: EstablishmentService,
    AdNotification
  ) {
    EstablishmentService.getEstablishment().then(establishment => {
      $scope.establishment = establishment
    })

    $scope.timer = 0
    function addTimer() {
      $timeout(() => {
        $scope.timer += 1
        addTimer()
      }, 1000)
    }
    addTimer()

    $scope.editEstablishment = (isValid) => {
      if (!isValid) {
        return
      }
      EstablishmentService.editEstablishment($scope.establishment).then(
        () => {
          $rootScope.goToParentState()
          EstablishmentService.refreshEstablishment().then(
            establishment => {
              AdNotification.success(201, 'edit_establishment')
              $scope.establishment = establishment
            },
            data => {
              AdNotification.error(data, 'edit_establishment')
            }
          )
        },
        data => {
          AdNotification.error(data, 'edit_establishment')
        }
      )
    }
  }
  module.controller('EstablishmentCtrl', EstablishmentCtrl)
})(angular.module('postCenterWebClientApp'))
