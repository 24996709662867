'use strict';

angular.module('postCenterWebClientApp')
  .directive('messageLocation', function () {
    return {
      scope: {
        data: '=',
        link: '@',
        width: '@',
        height: '@'
      },
      templateUrl: 'blocks/message/views/message_location.html',
      restrict: 'E',
      controller: ['$scope', '$filter', '$timeout',
      function ($scope, $filter, $timeout) {
        if ($scope.link.indexOf('maps.google.com') > -1) {
          $scope.map_url = $scope.link;
          $scope.is_embeddable = true;
        } else {
          if($scope.data.latitude && $scope.data.longitude) {
            var coords = $scope.data.latitude+","+$scope.data.longitude;
            $scope.map_url = "http://maps.google.com/maps";
            $scope.map_url += "?q="+coords
            $scope.is_embeddable = true;
          } else {
            $scope.map_url = $scope.link;
            $scope.is_embeddable = false;
          }
        }
        if ($scope.data.hasOwnProperty('name')) {
          if ($scope.data.name) {
            $scope.name = $scope.data.name;
          } else {
            $scope.name = $filter('translate')('MESSAGE_MAP_CURRENT_LOCATION');
          }
        } else {
          $scope.name = $filter('translate')('MESSAGE_MAP_CURRENT_LOCATION');
        }
        if($scope.is_embeddable) {
          $timeout(function() {
            jQuery('.magnific-popup-map').magnificPopup({
              type:'iframe'
            });
          });
        }
      }]
    };
  });
