(module => {
  'use strict';
  SecurityCtrl.$inject = [
    '$scope',
    '$rootScope',
    '$sce',
    'FRONTEND3_URL',
    'FireTagService',
    'AdRequest'
  ];
  function SecurityCtrl(
    $scope,
    $rootScope,
    $sce,
    FRONTEND3_URL,
    FireTagService,
    AdRequest
  ) {
    FireTagService.setPageView({
      title: 'Seguridad',
      path: `/${window.location.hash}`
    });
    $rootScope.adminTab.selected = 'security';

    $scope.iFrameUrl = function() {
      const token = AdRequest.getToken();
      const {postcenterVersion} = $rootScope;
      const url = `${FRONTEND3_URL}admin/security?auth=${token}&pc-ver=${postcenterVersion}`;
      return $sce.trustAsResourceUrl(url);
    };

    addEventListener('blur', () => {
      if (
        document.activeElement === document.getElementById('securityIframe')
      ) {
        const topBar = angular.element(
          document.getElementsByTagName('ad-top-bar')
        );
        topBar.trigger('click');
      }
    });
  }
  module.controller('SecurityCtrl', SecurityCtrl);
})(angular.module('postCenterWebClientApp'));
