'use strict';

angular.module('postCenterWebClientApp')
  .directive('timeControl', [function () {
    return {
      scope : {
        options: '=timeControl'
      },
      templateUrl: 'blocks/analytics/views/time_control.html',
      restrict: 'AE',
      controller: TimeControlController
    }
  }]);

TimeControlController.$inject = ['$scope', '$rootScope', '$filter'];

function TimeControlController($scope, $rootScope, $filter) {
  var translate = $filter('translate');
  var label = function (translatable, prefix) {
    var text = prefix ? prefix + ' ' : '';
    return text + translate(translatable);
  }

  function toMomentUnit(key) {
    switch(key) {
      case 'm':
        return 'minutes';
      case 'h':
        return 'hours';
      case 'd':
        return 'days';
      case 'mo':
        return 'months';
    }
  }

  function Granularity(i18nStr, key, maxDelta, presetPeriods, customPeriod) {
    var self = this;

    // instance vars
    self.i18nStr = i18nStr;
    self.key = key;
    self.now = moment();
    self.maxDelta = maxDelta;
    self.presetPeriods = presetPeriods;
    self.customPeriod = customPeriod;
    self.setNow = setNow;
    self.customRange = null;

    // methods
    self.truncateMoment = truncateMoment;
    self.getMinimumNow = getMinimumNow;
    self.formatMoment = formatMoment;
    self.refreshCustomPeriod = refreshCustomPeriod;

    // initCode
    self.presetPeriods = _.filter(self.presetPeriods, function (period) {
      if (period.delta > self.maxDelta) {
        if (period.customPeriod) {
          period.delta = self.maxDelta;
        } else {
          return false;
        }
      }
      period.setGranularity(self);
      return true;
    });
    angular.forEach(presetPeriods, function(period){
      period.setGranularity(self);
    });
    customPeriod.setGranularity(self);

    function truncateMoment(momentTime) {
      switch(self.key){
        case 'mo':
          momentTime.date(1);
        case 'd':
          momentTime.hour(0);
        case 'h':
          momentTime.minute(0);
        case 'm':
          momentTime.millisecond(0);
          momentTime.second(0);
          break;
      }
    }

    function getMinimumNow(){
      var minimumNow = self.now.clone().subtract(
        self.maxDelta - 1, toMomentUnit(self.key)
      );
      self.truncateMoment(minimumNow);
      return minimumNow;
    }

    function formatMoment(momentDate){
      var formatByGranularity = {
        m: translate('ANALYTICS_M_GRANULARITY_FMT'),
        h: translate('ANALYTICS_H_GRANULARITY_FMT'),
        d: translate('ANALYTICS_D_GRANULARITY_FMT'),
        mo: translate('ANALYTICS_MO_GRANULARITY_FMT'),
      },
        format = formatByGranularity[self.key];
      return momentDate.format(format);
    }

    function refreshCustomPeriod(){
    }

    function setNow(now){
      self.truncateMoment(now);
      self.now = now;
    }

    $scope.watchRangeRangeOff = $scope.$watch(function(){
      return self.customPeriod.range;
    }, function(range){
      self.customRange = angular.copy(range);
    }, true);

    $scope.watchRangeOff = $scope.$watch(function(){
      return self.customRange;
    }, function(range){
      if(range){
        self.customRange = angular.copy(range);
        self.customPeriod.setRange(range);
      }
    }, true);
  }

  function Period(now, delta, lbl, isCustomPeriod, fixedUnit) {
    var self = this;

    // Instance variables
    self.now = now;
    self.delta = delta;
    self.lbl = lbl;
    self.granularity = null;
    self.isCustomPeriod = isCustomPeriod;
    self.range = null;
    self.fixedUnit = fixedUnit;

    // Methods
    self.setRange = setRange;
    self.setNow = setNow;
    self.setDelta = setDelta;
    self.setGranularity = setGranularity;
    self.generateQueryRequest = generateQueryRequest;
    self.getHumanFrom = getHumanFrom;
    self.getHumanTo = getHumanTo;
    self.saveInLocalStorage = saveInLocalStorage;
    self.getTooltipHtml = getTooltipHtml;
    self.incrementLowerBound = incrementLowerBound;
    self.decrementLowerBound = decrementLowerBound;
    self.decrementUpperBound = decrementUpperBound;
    self.incrementUpperBound = incrementUpperBound;

    function setRange(range){
      var minimumTime = self.granularity.getMinimumNow();

      self.range = [
        Math.min(self.granularity.maxDelta, Math.max(0,range[0])),
        Math.min(self.granularity.maxDelta, Math.max(0,range[1])),
      ];
      self.now = minimumTime.add(
        self.range[1], toMomentUnit(self.granularity.key)
      );
      self.delta = self.range[1] - self.range[0];
    }

    function setNow(now){
      self.granularity.truncateMoment(now);
      self.now = now;
      computeRange();
    }

    function setDelta(delta){
      self.delta = delta;
      computeRange();
    }

    function setGranularity(granularity){
      self.granularity = granularity;
      self.granularity.truncateMoment(self.now);
      computeRange();
    }

    function generateQueryRequest(){
      var queryDict = {
        delta: self.delta, key: self.granularity.key
      };
      if(self.isCustomPeriod){
        queryDict.now = self.now;
      }
      if (self.fixedUnit != null) {
        // Asume we need today in hours:
        // - analytics needs as now 2017-05-17 23:00
        // - dashboard needs as now 2017-05-18 00:00
        queryDict.now = moment().endOf(self.fixedUnit);  // 2017-05-17 23:59
        self.granularity.truncateMoment(queryDict.now);  // 2017-05-17 23:00
        queryDict.dashboardNow = moment()
          .startOf(self.fixedUnit)  // 2017-05-17 00:00
          .add(1, self.fixedUnit);  // 2017-05-18 00:00
        // improving delta accuracy
        var start = moment().startOf(self.fixedUnit);  // 2017-05-17 00:00
        var diff = moment.duration(queryDict.dashboardNow - start);
        queryDict.delta = Math.round(diff.as(toMomentUnit(queryDict.key)));
      }
      return queryDict;
    }

    function computeRange(){
      var minimumTime = self.granularity.getMinimumNow();
      var upperBound = self.now.diff(
          minimumTime, toMomentUnit(self.granularity.key)
        ),
        lowerBound = upperBound - self.delta;
      self.range = [
        Math.min(self.granularity.maxDelta, Math.max(0,lowerBound)),
        Math.min(self.granularity.maxDelta, Math.max(0,upperBound)),
      ];
    }

    function getHumanFrom(){
      var from = self.now.clone().subtract(
        self.delta, toMomentUnit(self.granularity.key)
      );
      return self.granularity.formatMoment(from);
    }

    function getHumanTo(){
      return self.granularity.formatMoment(self.now);
    }

    function saveInLocalStorage(){
      var widgetName = $scope.options.name || '', obj = {};
      obj.key = self.granularity.key;
      if(self.isCustomPeriod){
        obj.customPeriod = {
          now: self.now.format(),
          delta: self.delta,
        };
      } else {
        obj.presetPeriod = {delta: self.delta};
      }
      localStorage[widgetName + '_time_control'] = JSON.stringify(
        obj
      );
    }

    function getTooltipHtml(){
      if(!self.isCustomPeriod){
        return "";
      } else {
        return translate(
          "TIMECONTROL_CUSTOM_PERIOD_TOOLTIP",
          {
            from: self.getHumanFrom(),
            to: self.getHumanTo(),
          }
        );
      }
    }

    function decrementLowerBound(){
      self.setRange([self.range[0] - 1, self.range[1]]);
    }

    function incrementLowerBound(){
      var targetBound = Math.min(self.range[0] + 1, self.range[1] - 1);
      self.setRange([targetBound, self.range[1]]);
    }

    function decrementUpperBound(){
      var targetBound = Math.max(self.range[0] + 1, self.range[1] - 1);
      self.setRange([self.range[0], targetBound]);
    }

    function incrementUpperBound(){
      self.setRange([self.range[0], self.range[1] + 1]);
    }
  }

  var maxDeltas = ($scope.options.maxGranularitiesDelta || {});
  var availableGranularities = {
    'm': true, 'h': true, 'd': true, 'mo': true
  }
  if ($scope.options.availableGranularities !== undefined) {
    availableGranularities = $scope.options.availableGranularities;
  }

  $scope.granularities = [];

  if (availableGranularities['m']) {
    var shortcuts = !$scope.options.shortcuts ? [] : [
      new Period(moment(), 60, label('CURR_HOUR'), false, 'hour'),
      new Period(moment(), 1440, label('CURR_DAY'), false, 'day'),
    ];
    $scope.granularities.push(
      new Granularity(
        'MINUTE',
        'm',
        maxDeltas['m'] || 1440,
        shortcuts.concat([
          new Period(moment(), 30, label('MINUTES', 30)),
          new Period(moment(), 60, label('MINUTES', 60)),
          new Period(moment(), 180, label('HOURS', 3)),
          new Period(moment(), 360, label('HOURS', 6)),
          new Period(moment(), 720, label('HOURS', 12)),
          new Period(moment(), 1440, label('HOURS', 24)),
        ]),
        new Period(moment(), 1440, label('MINUTES', 1440), true)
      )
    );
  }
  if (availableGranularities['h']) {
    var shortcuts = !$scope.options.shortcuts ? [] : [
      new Period(moment(), 24, label('CURR_DAY'), false, 'day'),
      new Period(moment(), 168, label('CURR_WEEK'), false, 'week'),
    ];
    $scope.granularities.push(
      new Granularity(
        'HOUR',
        'h',
        maxDeltas['h'] || 168,
        shortcuts.concat([
          new Period(moment(), 6, label('HOURS', 6)),
          new Period(moment(), 12, label('HOURS', 12)),
          new Period(moment(), 24, label('HOURS', 24)),
          new Period(moment(), 48, label('DAYS', 2)),
          new Period(moment(), 96, label('DAYS', 4)),
          new Period(moment(), 168, label('DAYS', 7)),
        ]),
        new Period(moment(), 1440, label('HOURS', 1440), true)
      )
    );
  }
  if (availableGranularities['d']) {
    var shortcuts = !$scope.options.shortcuts ? [] : [
      new Period(moment(), 7, label('CURR_WEEK'), false, 'week'),
      new Period(moment(), 30, label('CURR_MONTH'), false, 'month'),
      new Period(moment(), 364, label('CURR_YEAR'), false, 'year'),
    ];
    $scope.granularities.push(
      new Granularity(
        'DAY',
        'd',
        maxDeltas['d'] || 364,
        shortcuts.concat([
          new Period(moment(), 7, label('DAYS', 7)),
          new Period(moment(), 15, label('DAYS', 15)),
          new Period(moment(), 30, label('DAYS', 30)),
          new Period(moment(), 90, label('MONTHS', 3)),
          new Period(moment(), 181, label('MONTHS', 6)),
          new Period(moment(), 364, label('YEAR', 1)),
        ]),
        new Period(moment(), 364, label('DAYS', 364), true)
      )
    );
  }
  if (availableGranularities['mo']) {
    var shortcuts = !$scope.options.shortcuts ? [] : [
      new Period(moment(), 12, label('CURR_YEAR'), false, 'year'),
    ];
    $scope.granularities.push(
      new Granularity(
        'MONTH',
        'mo',
        maxDeltas['mo'] || 18,
        shortcuts.concat([
          new Period(moment(), 3, label('MONTHS', 3)),
          new Period(moment(), 6, label('MONTHS', 6)),
          new Period(moment(), 9, label('MONTHS', 9)),
          new Period(moment(), 12, label('MONTHS', 12)),
          new Period(moment(), 15, label('MONTHS', 15)),
          new Period(moment(), 18, label('MONTHS', 18)),
        ]),
        new Period(moment(), 18, label('MONTHS', 18), true)
      )
    );
  }

  $scope.selectedGranularity = null;
  $scope.selectedPeriod = null;

  $scope.selectPresetPeriod = function(presetPeriod) {
    $scope.controlOpened = false;
    $scope.selectedPeriod = presetPeriod;
    presetPeriod.setNow(moment());
    onSelected(presetPeriod);
  };

  $scope.selectCustomPeriod = function(period) {
    $scope.controlOpened = false;
    $scope.selectedPeriod = period;
    onSelected(period);
  };

  $scope.toggleFilterOpen = function() {
    $scope.controlOpened = !$scope.controlOpened;
  };

  $scope.isControlOpen = function() {
    return $scope.controlOpened;
  };

  $scope.selectGranularity = function(granularity) {
    if ($scope.isGranularitySelected(granularity)) {
      $scope.selectedGranularity = null;
      return;
    }
    $scope.selectedGranularity = granularity;
    $scope.selectedGranularity.setNow(moment());
  };

  $scope.isGranularitySelected = function(granularity) {
    return granularity === $scope.selectedGranularity;
  };

  $scope.checkSelectorState = function(periodsKey) {
    return $scope.state.selector.active === periodsKey;
  };

  $scope.dontPropagate = function(e) {
    e.stopPropagation();
    e.preventDefault();
  };

  $scope.onSlide = function(e, ui) {
    var difference = ui.values[1] - ui.values[0];
    if (difference < 2) {
      return false;
    }
    var slider = angular.element(e.target);
    slider.trigger('aderesoUpdateSlider', ui);
  };

  function onSelected(period) {
    var onSelectedCallback = $scope.options.onSelected;
    if (angular.isFunction(onSelectedCallback)) {
      period.saveInLocalStorage();
      onSelectedCallback(period.generateQueryRequest());
    }
  }

  function restoreSavedPeriod(widgetName) {
    var localSavedDefault =
      localStorage[widgetName + '_time_control'] || undefined;
    if (angular.isDefined(localSavedDefault)) {
      try{
        var savedObj = JSON.parse(localSavedDefault),
          savedGranularity = _.find($scope.granularities,
            function(granularity) {
              return granularity.key == savedObj.key;
            }
          );
          if (savedObj.customPeriod && savedObj.customPeriod.now &&
            savedObj.customPeriod.delta
          ) {
            savedGranularity.customPeriod.setNow(
              moment(savedObj.customPeriod.now)
            );
            savedGranularity.customPeriod.setDelta(
              savedObj.customPeriod.delta
            );
            $scope.selectCustomPeriod(savedGranularity.customPeriod);
          } else if (
            savedObj.presetPeriod && savedObj.presetPeriod.delta
          ) {
            var savedPresetPeriod = _.find(
              savedGranularity.presetPeriods,
              function(presetPeriod) {
                return presetPeriod.delta ===
                  savedObj.presetPeriod.delta;
              });
              if (savedPresetPeriod) {
                $scope.selectPresetPeriod(savedPresetPeriod);
              } else {
                throw "savedPresetPeriod not found";
              }
          } else {
            throw "Unexpected localSavedDefault format";
          }
      } catch (exception) {
        console.warn(
          'Bad default time control period saved for widget: "'
          + widgetName + '". Clearing it up.'
        );
        delete localStorage[widgetName + '_time_control'];
        return false;
      }
      return true;
    } else {
      return false;
    }
  }

  var changeBTPolling = 'analytics:dashboard:btPolling:event';
  $scope.watchChangeBtPollingOff = $rootScope.$on(changeBTPolling, function(event) {
    onSelected($scope.selectedPeriod);
  });

  function init() {
    var widgetName = $scope.options.name || '';
    var defaultPeriod = {};
    var defaultPeriodParent = {};
    if (!restoreSavedPeriod(widgetName)) {
      $scope.selectPresetPeriod(
        $scope.granularities[0].presetPeriods[0]
      );
    }
  }
  init();

  $scope.$on('$destroy', () => {
    $scope.watchRangeOff();
    $scope.watchRangeRangeOff();
    $scope.watchChangeBtPollingOff();
  });
};
