(function(module) {
  ngAucWrapper.$inject = ['$filter'];
  function ngAucWrapper($filter) {
    return {
      restrict: 'E',
      scope: {
        maximum: '=',
        current: '='
      },
      link(scope, elem) {
        const {maximum, current} = scope;
        const translate = (property, params = {}) =>
          $filter('translate')(property, params);

        new Vue({
          el: elem[0].querySelector('.ng-non-bindable'),
          provide: {
            translate
          },
          data: {
            translate: $filter('translate'),
            maximum,
            current
          }
        });
      }
    };
  }

  module.directive('ngAucWrapper', ngAucWrapper);
})(angular.module('postCenterWebClientApp'));
Vue.component('auc-available', {
  inject: ['translate'],
  props: {
    max: {
      type: Number,
      default: 100
    },
    used: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {};
  },
  computed: {
    getPercentage() {
      return (this.used * 100) / this.max;
    }
  },
  template: `<div>
    <div class="auc-content">
      <auc-progress-bar :max="max" :current="used" :percentage="getPercentage" />
    </div>
    <auc-help />
    <auc-button />
  </div>`
});
Vue.component('auc-help', {
  inject: ['translate'],
  data() {
    return {
      aucMeaning: this.translate('SOCIALACCOUNTS_AUC_MEANING')
    };
  },
  computed: {
    getMessage() {
      const month = moment().format('MMMM');
      return `** <strong>Active User Conversations (AUC)</strong> ${this.aucMeaning} ${month}`;
    }
  },
  template: '<p><small v-html="getMessage" /></p>'
});
Vue.component('auc-progress-bar', {
  inject: ['translate'],
  props: {
    current: Number,
    max: Number,
    percentage: {
      type: Number,
      default: 0
    }
  },
  computed: {
    getProgressStyle() {
      const percentage = (this.current * 100) / this.max;
      return {
        width: `${percentage}%`,
        backgroundColor:
          percentage >= 80
            ? '#FF677D'
            : percentage < 80 && percentage >= 40
            ? '#bf9533'
            : '#82c082'
      };
    },
    getMessage() {
      if (this.percentage >= 80) {
        const diff = this.max - this.current;
        return this.translate('SOCIALACCOUNTS_AUC_ATENTION', {
          diff,
          max: this.max
        });
      } else {
        return this.translate('SOCIALACCOUNTS_AUT_USED', {
          max: this.max,
          current: this.current
        });
      }
    }
  },
  template: `<div class="auc-progress">
                <p class="auc-message" :style="percentage >= 80 ? 'color:#FF687D':''" v-html="getMessage"></p>
                <span>{{current}} / {{max}}</span>
                <div class="auc-progress-bar">
                  <div :style="getProgressStyle"></div>
                </div>
  </div>`
});
Vue.component('auc-button', {
  inject: ['translate'],
  data() {
    return {
      btnLabel: this.translate('GET_MORE_AUC')
    };
  },
  template:
    '<button class="btn btn-primary mt-1" @click="getMoreAUC()">{{ btnLabel }}</button>',
  methods: {
    getMoreAUC() {
      const message = 'Hola, me gustaría obtener más AUC';
      window.Intercom('showNewMessage', message);
    }
  }
});
