class VersionService {

  private versionSub: rxjs.BehaviorSubject<string>
  private versionObs: rxjs.Observable<string>
  private fetching: boolean = false
  $resource

  constructor($resource) {
    this.$resource = $resource
    this.versionSub = new rxjs.BehaviorSubject(
      localStorage.getItem('pcVersion')
    )
    this.versionObs = this.versionSub.pipe(
      rxjs.filter(version => version != null)
    )
  }

  public getVersion(): Promise<string> {
    /**
     * Returns the app version if it is already loaded.
     * If not, it fetches it and then returns.
     */
     let promise = rxjs.firstValueFrom(this.versionObs)
     if (!this.versionSub.value && !this.fetching) {
       this.refreshVersion()
     }
     return promise
  }

  private refreshVersion(): Promise<string> {
    this.fetching = true
    return this.$resource('version.json', {}).get().$promise.then(f => {
      let version = this.cleanVersion(f)
      localStorage.setItem('pcVersion', version)
      this.versionSub.next(version)
      this.fetching = false
      return version
    })
  }

  private cleanVersion(data: {version: string}) {
    const versionArray = data.version.split('-')
    const [first, second = ''] = versionArray
    return second ? `${first}.${second}` : first
  }
}
angular
  .module('postCenterWebClientApp')
  .service('VersionService', VersionService);
