'use strict';
(function(module) {
  'use strict';
  caseConversation.$inject = [];

  function caseConversation() {
    return {
      templateUrl: 'blocks/case/views/case_conversation.html',
      scope: {
        initMessage: '&message',
        fallbackTargetUser: '<?'
      },
      controller: 'caseConversationCtrl',
      controllerAs: 'vm',
      bindToController: true,
      require: '^^caseDetail'
    };
  }
  module.directive('caseConversation', caseConversation);
})(angular.module('postCenterWebClientApp'));