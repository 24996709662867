/* global messageTemplatesMessage messageTemplatesStore */
(module => {
  'use strict';
  messageTemplatesCtrl.$inject = [
    '$rootScope',
    'FireTagService',
    'UserService',
    'AdNotification',
    'MessageTemplateService',
    'DepartmentService',
    'ENV'
  ];
  module.controller('messageTemplatesCtrl', messageTemplatesCtrl);
  function messageTemplatesCtrl(
    $rootScope,
    FireTagService,
    UserService,
    AdNotification,
    MessageTemplateService,
    DepartmentService,
    ENV
  ) {
    const vm = this;
    $rootScope.adminTab.selected = 'messageTemplates';
    vm.$onInit = () => {
      vm.loaded = true;
      if (ENV === 'prod') {
        FireTagService.setPageView({
          title: 'Plantilla de mensajes',
          path: `/${window.location.hash}`
        });
      }
      vm.createVueInstance();
    };
    vm.createVueInstance = () => {
      const i18nMessageTemplates = new VueI18n({
        messages: messageTemplatesMessage,
        fallbackLocale: ['es', 'en', 'pt'],
        silentTranslationWarn: ENV === ''
      });
      messageTemplatesStore.toast = AdNotification;
      messageTemplatesStore.MessageTemplateService = MessageTemplateService;
      messageTemplatesStore.UserService = UserService;
      messageTemplatesStore.DepartmentService = DepartmentService;
      vm.vueInstance = new Vue({
        el: document.getElementById('message-templates-screen'),
        i18n: i18nMessageTemplates,
        store: messageTemplatesStore,
        data: {
          ready: false // wait for translate
        },
        created() {
          Promise.all([
            this.getUser(),
            this.getTemplates(),
            this.getDepartments()
          ]).then(response => {
            const [user] = response;
            const {
              profile: {language}
            } = user;
            i18nMessageTemplates.locale = language;
            this.ready = true;
          });
        },
        methods: {
          ...Vuex.mapActions(['getUser', 'getTemplates', 'getDepartments'])
        }
      });
    };
  }
})(angular.module('postCenterWebClientApp'));
