class CrmCtrl {
  $scope;
  $rootScope;
  InitialData;
  AdNotification;
  AppStateService: AppStateService;
  UserService: UserService;
  CaseDetailManager: CaseDetailManager;
  $state;
  $translate;
  $window;
  FireTagService;
  AdTalkcenterService;
  wootricFactory;
  AuthService;
  EstablishmentService: EstablishmentService;
  VersionService: VersionService;
  caseStateSubscription;
  contactsStateSubscription;
  inboxStateSubscription;
  $location;

  constructor(
    $scope,
    $rootScope,
    InitialData,
    AdNotification,
    AppStateService: AppStateService,
    UserService: UserService,
    CaseDetailManager,
    $state,
    $translate,
    $window,
    FireTagService,
    AdTalkcenterService,
    wootricFactory,
    AuthService,
    EstablishmentService: EstablishmentService,
    VersionService: VersionService,
    $location,
  ) {
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.AdNotification = AdNotification;
    this.AppStateService = AppStateService;
    this.UserService = UserService;
    this.CaseDetailManager = CaseDetailManager;
    this.$translate = $translate;
    this.$window = $window;
    this.FireTagService = FireTagService;
    this.AdTalkcenterService = AdTalkcenterService;
    this.wootricFactory = wootricFactory;
    this.AuthService = AuthService;
    this.EstablishmentService = EstablishmentService;
    this.VersionService = VersionService;
    this.$state = $state;

    $scope.loader = {show: true};
    this.$location = $location;

    let toDestroy: {[key: string]: (() => {}) | undefined} = {
      onNoAccounts: undefined,
      onGotEstablishment: undefined,
      loadedColumns: undefined
    };

    toDestroy.loadedColumns = $rootScope.$on('columnsLoaded', () => {
      $scope.loader.show = false;
    });

    $scope.$on('$destroy', () => {
      if (toDestroy.onNoAccounts) {
        toDestroy.onNoAccounts();
      }

      if (toDestroy.onGotEstablishment) {
        toDestroy.onGotEstablishment();
      }

      let subscriptions = [
        this.caseStateSubscription,
        this.contactsStateSubscription,
        this.inboxStateSubscription
      ]
      subscriptions.forEach(sub => {
        if (sub) {
          sub.unsubscribe();
        }
      });
    });

    Promise.all([
      EstablishmentService.getEstablishment(),
      UserService.getProfile(),
      EstablishmentService.getTrialStatus()
    ])
      .then(([establishment, user, trialStatus]) => {
        if (establishment && establishment.case_counter === 0) {
          $rootScope.onboarding = true;
          this.isOnboarding();
        }
        this.openExpirationModal(trialStatus);
        return this.onRefreshCRMSuccess(user, establishment);
      })
      .then(() => {
        this.listenToStateChanges();
        this.checkCurrentStateActions();
      })
      .catch(err => this.onRefreshCRMError(err));

    $rootScope.$on('colorSchemeChanged', (_, scheme) => {
      $scope.colorScheme = scheme;
    });
  }

  private openExpirationModal(trialStatus) {
    const isEmpty = trialStatus.days_remaining === null || trialStatus.days_remaining === undefined;
    const isExpiring = !isEmpty && trialStatus.days_remaining <= 7;
    if(isExpiring) {
      this.$rootScope.$emit('trialExpiration', {})
    }
  }

  private checkCurrentStateActions() {
    // On first load, the state change subscriptions will not fire. So, we need
    // to check the current state here and perform actions as needed.
    let currentState = this.AppStateService.currentState();
    if (currentState) {
      if (currentState.isCaseState()) {
        this.actOnCaseState(currentState);
      }
      if (currentState.isInboxState()) {
        this.actOnInboxState(currentState);
      }
      if (currentState.isContactsState()) {
        this.actOnContactsState(currentState);
      }
    }
  }

  private actOnCaseState(state: AppState) {
    this.$scope.inContactAgenda = false;
    this.CaseDetailManager.findAndDisplayCase(
      state.params['id'],
      state.params['sideviewmenuoption']
    );
  }

  private actOnContactsState(state: AppState) {
    this.$scope.inContactAgenda = true;
    this.$scope.loader.show = false;
  }

  private actOnInboxState(state: AppState, prevState?: AppState) {
    this.$scope.inContactAgenda = false;
    if (prevState && prevState.isContactsState()) {
      this.$scope.loader.show = true;
    }
  }

  private listenToStateChanges() {
    this.caseStateSubscription =
      this.AppStateService.caseStateObservable.subscribe((t: StateTransition) =>
        this.actOnCaseState(t.toState)
      );
    this.contactsStateSubscription =
      this.AppStateService.contactsStateObservable.subscribe(
        (t: StateTransition) => this.actOnContactsState(t.toState)
      );
    this.inboxStateSubscription =
      this.AppStateService.inboxStateObservable.subscribe(
        (t: StateTransition) => this.actOnInboxState(t.toState, t.fromState)
      );
  }

  private isOnboarding = () => {
    this.AdTalkcenterService.shutDown();
  };

  public $onInit = () => {
    this.FireTagService.setPageView({
      title: 'Mesón de ayuda',
      path: `/${window.location.hash}`,
      preferred_view: ''
    });
    if (this.$rootScope.onboarding) {
      this.isOnboarding();
    }
    this.$rootScope.adminTab.selected = '';
    this.$rootScope.analyticTab.selected = '';
    this.$rootScope.settingsTab.selected = '';
    const url = this.$location.url();
  };

  private onRefreshCRMError(data) {
    this.$scope.loader.show = false;
    this.AuthService.logout();
  }

  private onRefreshCRMSuccess(
    user: ExtendedUser,
    establishment: Establishment
  ) {
    return this.VersionService.getVersion().then(version => {
      this.$scope.version = version;
      const {
        isAnalystOrAdmin,
        isAdminOrCm,
        first_name,
        last_name,
        isSupervisor,
        profile: {language, onboarding_progress}
      } = user;

      this.$scope.establishment = establishment.name;
      this.$scope.username = `${first_name} ${last_name}`;
      this.$scope.canExport = isAnalystOrAdmin || isSupervisor
      this.$scope.isAdminOrCM = isAdminOrCm;
      this.$scope.isOnboarded = onboarding_progress < 0;
      this.$scope.hasColumns = false;

      /*Lets send location change to intercom*/
      if (typeof window.Intercom !== 'undefined') {
        window.Intercom('update');
      }

      this.$translate.use(language);
      this.wootricFactory.run(user.email, user.date_joined);

      this.$rootScope.$emit('CRM_GOT_INITIAL_DATA');
    });
  }
}

(module => {
  'use strict';
  module.controller('CrmCtrl', CrmCtrl);
})(angular.module('postCenterWebClientApp'));
