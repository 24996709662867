class IntegrationService {
  constructor(AdRequest) {
    this.AdRequest = AdRequest;
  }
  post(name) {
    return this.AdRequest.post(`/api/v1/integrations/manage/${name}/`);
  }
  delete(name) {
    return this.AdRequest.delete(`/api/v1/integrations/manage/${name}/`);
  }
  allRequest() {
    return this.AdRequest.get('/api/v1/integration/request/');
  }
  deleteRequest(request) {
    return this.AdRequest.delete(
      `/api/v1/integration/request/${request.context.name}/${request.id}`
    );
  }
  testRequest(request) {
    return this.AdRequest.post(
      '/api/v1/integration/requests/test',
      request.serializeRequest()
    );
  }
  storeRequest(request) {
    return this.AdRequest.post(
      '/api/v1/integration/request/',
      request.serializeConfig()
    );
  }
}
angular
  .module('postCenterWebClientApp')
  .service('IntegrationService', IntegrationService);
