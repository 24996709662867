(module => {
    'use strict';
    AdminApiUsersCtrl.$inject = [
        "$scope",
        '$rootScope',
        "FRONTEND_VUE_URL",
        "$sce",
        "AdNotification",
        "AdRequest"
    ]
    module.controller('AdminApiUsersCtrl', AdminApiUsersCtrl);

    function AdminApiUsersCtrl(
        $scope, $rootScope, FRONTEND_VUE_URL, $sce, AdNotification,
        AdRequest: AdRequestService
    ) {
        $rootScope.adminTab.selected = 'api-users';
        const vm = this;

        vm.$onInit = () => {
            window.addEventListener('message', handleIframeMessage);
        };

        vm.$onDestroy = () => {
            window.removeEventListener('message', handleIframeMessage);
        }

        $scope.iFrameUrl = function () {
            const token = AdRequest.getToken();
            const url = `${FRONTEND_VUE_URL}/frames/api_users?authToken=${token}`
            return $sce.trustAsResourceUrl(url);
        }

        function handleIframeMessage ({data}) {
            if (data.type !== 'apiUserEvent') {
                return;
            }
            const {message} = data;
            const actionMap = {
                notify: notifyMessage
            };
            const fun = actionMap[message.action];
            if (fun) fun(message);
        };
    
        function notifyMessage(message) {
            const {text: messageText, type} = message;
            if(type === 'success') {
                AdNotification.success(200, messageText);
            }
        }
    }
})(angular.module('postCenterWebClientApp'))