const HistoryTabWrapper = Vue.component('HistoryTabWrapper', {
  props: {
    group: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      extendedTable: '',
      expandedChart: false,
      expandedTable: false,
      chartsOpened: [],
      skeletons: {
        charts: [],
        table: [],
        total: 0
      }
    };
  },
  computed: {
    ...Vuex.mapGetters(['getKpis', 'getCharts', 'getTabGroup', 'activeGroup'])
  },
  mounted() {
    this.clearData().then(() => {
      this.getKpiData();
    });
    //this.getKpiData();
    if (this.group === 'agents') {
      this.getAgents();
    }
    if (this.group === 'departments') {
      this.getDepartments();
    }
  },
  methods: {
    ...Vuex.mapActions([
      'getKpiList',
      'getChart',
      'getAgents',
      'getDepartments',
      'clearData'
    ]),
    getKpiData() {
      const list = this.getTabGroup(this.group) || [];
      if (list.length) {
        this.getKpisData(list);
      } else {
        this.getKpiList(this.group)
          .then(kpis => {
            this.getKpisData(kpis);
          })
          .catch(error => {
            throw Error('hemos tenido un error', error);
          });
      }
    },
    getKpisData(kpis) {
      let chartLength = 0;
      let tableLength = 0;
      kpis.map(({identifier, visualization_type: type}) => {
        if (type === 'lineal_time') {
          chartLength += 1;
        }
        if (type === 'table') {
          tableLength += 1;
        }

        this.getChart({identifier});
      });
      this.skeletons.charts = new Array(chartLength);
      this.skeletons.table = new Array(tableLength);
      this.skeletons.total = chartLength + tableLength;
    },
    getChartSizeClass(chart) {
      const {
        serialized: {visualization_type: type}
      } = chart;

      if (type === 'lineal_time') {
        return 'history--kpi-chart';
      }
      return chart.expandedTable ? 'history--kpi-chart__expanded' : '';
    },
    toggleCharts(identifier) {
      const list = this.getTabGroup(this.group) || [];
      const kpiEnabled = list.find(kpi => kpi.identifier === identifier);
      const {enabled = false} = kpiEnabled || {};
      return enabled;
    },
    expandChart(identifier, type) {
      if (type === 'table') {
        this.extendedTable = identifier;
        this.expandedTable = !this.expandedTable;
      } else {
        this.expandedChart = !this.expandedChart;
      }
    },
    fullScreen(chart) {
      const {
        serialized: {identifier, visualization_type: type}
      } = chart;
      if (type === 'table') {
        const expand = this.extendedTable === identifier && this.expandedTable;
        return expand ? 'expanded-table' : '';
      } else {
        const expand = this.chartsOpened.includes(identifier);
        return expand ? 'expanded-time' : 'compress-time';
      }
    },
    chartIsOpen(identifier) {
      if (this.chartsOpened.includes(identifier)) {
        this.chartsOpened.splice(this.chartsOpened.indexOf(identifier), 1);
      } else {
        this.chartsOpened.push(identifier);
      }
    }
  },
  template: `<div>
      <history-counters
        v-if="this.group === 'tickets'" />
      <div class="row" v-if="getCharts.length < skeletons.total">
        <slot v-if="skeletons.charts.length > 0">
        <div class="chart col-md-6" v-for="chart in skeletons.charts">
          <SkeletonChart
            class="history--kpi-chart card"
          />
        </div>
        </slot>
        <slot v-if="skeletons.table.length > 0">
        <div class="chart col-md-6" v-for="table in skeletons.table">
          <SkeletonTable
            class="history--kpi-chart card"
          />
        </div>
        </slot>
      </div>
      <div class="row" v-else>
        <div class="chart col-md-6"
          v-for="(chart, index) in getCharts"
          :key="chart.serialized.identifier"
          :class="[fullScreen(chart)]"
          v-show="toggleCharts(chart.serialized.identifier)"
          >
            <history-chart-item
              class="card"
              :group="group"
              :chartType="chart.serialized.visualization_type"
              :chart="chart"
              :class="getChartSizeClass(chart)"
              :toggleExpanded="expandChart"
              :isOpen="chartIsOpen"
              :opened="chartsOpened"
              :expandedChart="expandedChart"
              :expandedTable="expandedTable"
              :key="chart.identifier"
            />
        </div>
      </div>
  </div>`
});
const SkeletonChartMixin = {
  components: {
    ContentLoader: contentLoaders.ContentLoader
  },
  props: {
    size: {
      type: String,
      default: '0 0 100 100'
    }
  },
  data() {
    return {
      width: 100,
      height: 100
    };
  },
  computed: {
    getSize() {
      return `0 0 ${this.width} ${this.height}`;
    }
  },
  mounted() {
    this.width = this.$el?.parentElement?.clientWidth;
    this.height = this.$el?.parentElement?.clientHeight;
  }
};
const SkeletonChart = Vue.component('SkeletonChart', {
  mixins: [SkeletonChartMixin],
  template: `<div class="history--kpi-chart card history--kpi-chart" >
    <content-loader
    :width="width"
    :height="height"
      :viewBox="getSize"
      :speed="2"
      primaryColor="#d3e3ec"
      secondaryColor="#a8c7d9"
>
<rect x="28" y="134" rx="0" ry="0" width="17" height="44" />
    <rect x="51" y="156" rx="0" ry="0" width="16" height="22" />
    <rect x="73" y="149" rx="0" ry="0" width="17" height="29" />
    <rect x="96" y="149" rx="0" ry="0" width="17" height="29" />
    <rect x="118" y="114" rx="0" ry="0" width="17" height="64" />
    <rect x="142" y="126" rx="0" ry="0" width="17" height="52" />
    <rect x="164" y="104" rx="0" ry="0" width="17" height="74" />
    <rect x="229" y="134" rx="0" ry="0" width="17" height="44" />
    <rect x="186" y="156" rx="0" ry="0" width="16" height="22" />
    <rect x="207" y="149" rx="0" ry="0" width="17" height="29" />
    <rect x="251" y="149" rx="0" ry="0" width="17" height="29" />
    <rect x="273" y="114" rx="0" ry="0" width="17" height="64" />
    <rect x="295" y="126" rx="0" ry="0" width="17" height="52" />
    <rect x="316" y="104" rx="0" ry="0" width="17" height="74" />
    <rect x="1" y="35" rx="0" ry="0" width="3" height="152" />
    <rect x="3" y="183" rx="0" ry="0" width="544" height="4" />
    <rect x="1" y="10" rx="4" ry="4" width="304" height="10" />
    <rect x="147" y="206" rx="4" ry="4" width="60" height="7" />
    <rect x="226" y="206" rx="4" ry="4" width="60" height="7" />
    <rect x="308" y="206" rx="4" ry="4" width="60" height="7" />
    <rect x="385" y="206" rx="4" ry="4" width="60" height="7" />
    <rect x="379" y="134" rx="0" ry="0" width="17" height="44" />
    <rect x="337" y="156" rx="0" ry="0" width="16" height="22" />
    <rect x="357" y="149" rx="0" ry="0" width="17" height="29" />
    <rect x="401" y="149" rx="0" ry="0" width="17" height="29" />
    <rect x="422" y="114" rx="0" ry="0" width="17" height="64" />
    <rect x="444" y="126" rx="0" ry="0" width="17" height="52" />
    <rect x="466" y="104" rx="0" ry="0" width="17" height="74" />
</content-loader></div>`
});
const SkeletonTable = Vue.component('SkeletonTable', {
  mixins: [SkeletonChartMixin],
  template: `<div class="history--kpi-chart card history--kpi-chart" >
  <content-loader
  :width="width"
  :height="height"
    :viewBox="getSize"
    :speed="2"
    primaryColor="#d3e3ec"
    secondaryColor="#b1c4ce"
>
<rect x="1" y="10" rx="4" ry="4" width="304" height="10" />
<rect x="1" y="54" rx="4" ry="4" width="544" height="24" />
<rect x="0" y="95" rx="4" ry="4" width="205" height="18" />
<rect x="0" y="122" rx="4" ry="4" width="205" height="18" />
<rect x="0" y="149" rx="4" ry="4" width="205" height="18" />
<rect x="0" y="176" rx="4" ry="4" width="205" height="18" />
<rect x="0" y="203" rx="4" ry="4" width="205" height="18" />
<rect x="209" y="95" rx="4" ry="4" width="338" height="18" />
<rect x="209" y="122" rx="4" ry="4" width="338" height="18" />
<rect x="209" y="149" rx="4" ry="4" width="338" height="18" />
<rect x="209" y="176" rx="4" ry="4" width="338" height="18" />
<rect x="209" y="203" rx="4" ry="4" width="338" height="18" />
</content-loader></div>`
});
