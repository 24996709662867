'use strict';

angular.module('postCenterWebClientApp')
  .directive('messageVideo', function () {
    return {
      scope: {
        target: '@',
        image: '@'
      },
      templateUrl: 'blocks/message/views/message_video.html',
      restrict: 'E',
      controller: ['$scope', '$sce',
      function ($scope, $sce) {
        $scope.id = $scope.target.substring(
          $scope.target.lastIndexOf('/')+1,
          $scope.target.lastIndexOf('.')
        ).replace(/\W/g, '');
        $scope.selector = "#"+$scope.id;
        $scope.secureTarget = $sce.trustAsResourceUrl($scope.target);
        jQuery('.magnific-popup-video').magnificPopup({
          type:'inline',
          midClick: true
        });
      }]
    };
  });
