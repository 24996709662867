'use strict';

class UsersCtrl {
  FireTagService
  $rootScope

  constructor(
    $scope, $rootScope, $sce, FireTagService, FRONTEND3_URL, AdRequest,
    VersionService
  ) {
    this.FireTagService = FireTagService
    this.$rootScope = $rootScope

    VersionService.getVersion().then(pcVersion => {
      $scope.iFrameUrl = () => {
        const token = AdRequest.getToken()
        const url =
          FRONTEND3_URL + 'admin/users?auth=' + token + '&pc-ver=' + pcVersion
        return $sce.trustAsResourceUrl(url)
      }
    })

    addEventListener('blur', () => {
      if (document.activeElement === document.getElementById('usersIframe')) {
        const topBar = angular.element(
          document.getElementsByTagName('ad-top-bar')
        );
        topBar.trigger('click');
      }
    })
  }

  $onInit() {
    this.$rootScope.adminTab.selected = 'users'
    this.FireTagService.setPageView({
      title: 'Usuarios',
      path: `/${window.location.hash}`
    })
  }
}

angular
  .module('postCenterWebClientApp')
  .controller('UsersCtrl', UsersCtrl)
