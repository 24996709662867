'use strict';

class MainCtrl {

  private $scope
  private $rootScope
  private AdRequest: AdRequestService
  private AdActiveUser
  private AuthService: AuthService
  private EstablishmentService: EstablishmentService
  private UserService: UserService
  private AdNotification
  private SocialAccountService
  private UsersService
  private DepartmentService
  private CampaignService
  private activeUserCheck

  $onInit() {
    // Vuex store
    store.UserService = this.UserService;
    store.SocialAccountService = this.SocialAccountService;
    store.AdNotification = this.AdNotification;
    store.UsersService = this.UsersService;
    store.EstablishmentService = this.EstablishmentService;
    store.DepartmentService = this.DepartmentService;
    store.CampaignService = this.CampaignService;
  }

  async getTrialStatus() {
    return await this.EstablishmentService.getTrialStatus().then(resp => {
      const {days_remaining} = resp
      const trialStatus = {
        trialEnding: days_remaining && days_remaining <= 7,
        daysRemaining: days_remaining
      }
      return trialStatus
    })
  }

  refreshTopBar(user, establishment) {
    const {
      plan: {trial_expiration_date}
    } = establishment
    if (trial_expiration_date !== null) {
      this.getTrialStatus().then(trial => {
        this.$scope.topBar.trialEnding = trial.trialEnding;
        this.$rootScope.trialStatus = trial;
      })
    } else {
      this.$scope.topBar.trialEnding = false;
      this.$rootScope.trialStatus = {trialEnding: false, daysRemaining: 0};
    }
    this.$scope.topBar.user = {
      name: user.first_name || '',
      fullName: `${user.first_name} ${user.last_name}` || '',
      isAdmin: user.isAdmin,
      canManageAccount: user.isManager
    }
    this.$scope.topBar.establishment = establishment.name;
    this.$scope.topBar.showBar = true;
    this.$scope.topBar.showMenu = true;
  }

  private listenUserAndEstablishmentChanges(): rxjs.Subscription {
    return this.UserService.userObservable.pipe(
      rxjs.combineLatestWith(this.EstablishmentService.estObservable)
    ).subscribe(([user, establishment]) => {
      // This will execute every time the logged user or establishment changes
      this.refreshTopBar(user, establishment)
    })
  }

  private setupListeners() {
    const loginListener = this.AdRequest.loggedIn.subscribe(() => {
      this.activeUserCheck = this.AdActiveUser.checkUserActivity()
    })

    const logoutListener = this.AdRequest.loggedOut.subscribe(() => {
      this.$scope.topBar.showBar = false
      if (this.activeUserCheck) {
        this.activeUserCheck.stop()
      }
    })
    
    const userEstablishmentListener = this.listenUserAndEstablishmentChanges()

    this.$scope.$on('$destroy', () => {
      loginListener.unsubscribe()
      logoutListener.unsubscribe()
      userEstablishmentListener.unsubscribe()
    })
  }

  constructor(
    $scope,
    $rootScope,
    AdActiveUser,
    AdRequest: AdRequestService,
    AuthService: AuthService,
    EstablishmentService: EstablishmentService,
    UserService: UserService,
    AdNotification,
    SocialAccountService,
    UsersService,
    DepartmentService,
    CampaignService
  ) {

    this.$scope = $scope
    this.$rootScope = $rootScope
    this.AdActiveUser = AdActiveUser
    this.AdRequest = AdRequest
    this.AuthService = AuthService
    this.EstablishmentService = EstablishmentService
    this.UserService = UserService
    this.AdNotification = AdNotification
    this.SocialAccountService = SocialAccountService
    this.UsersService = UsersService
    this.DepartmentService = DepartmentService
    this.CampaignService = CampaignService

    $scope.topBar = {
      showBar: false,
      showMenu: false,
      user: {},
      establishment: null,
      onSignUp: false
    }

    $scope.$on('main:onSignup', (event, value) => {
      $scope.topBar.onSignUp = value
    })

    $scope.closeAlert = function (index) {
      $scope.alerts.splice(index, 1)
    }

    this.setupListeners()
  }
}

angular
  .module('postCenterWebClientApp')
  .controller('MainCtrl', MainCtrl)