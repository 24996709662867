'use strict';

angular.module('postCenterWebClientApp')
.directive('metricBubbleChart', ['$timeout', '$log', 'AnalyticsHelper', 'AnalyticsConfigs', 'AdAvailableSn',
  function($timeout, $log, AnalyticsHelper, AnalyticsConfigs, AdAvailableSn) {
    return {
      scope : {
        sample: '=',
        widget: '='
      },
      templateUrl: 'blocks/analytics/views/metric_bubble_chart.html',
      restrict: 'AE',
      controller: ['$scope', '$rootScope', '$filter',
        function ($scope, $rootScope, $filter) {

          $scope.bubbleMetric = null;

          $scope.state = {
            flags: {
              availableContent: function() {
                return $scope.bubbleMetric && $scope.bubbleMetric.data.value;
              },
              invalidContent: false
            }
          }

          function updateBubbleMetric() {

            var metricScheme = _.findWhere(
              $scope.widget.dataScheme.metrics,
              { name: 'counter' }
            );

            var metricDataList = AnalyticsHelper.getBubbleMetricDataList($scope.sample, metricScheme, null)
            if (metricDataList.length <= 0) {
              $log.warn("bubble chart widget, no metrics found");
              return;
            }

            var metricData = metricDataList[0];
            var valueType  = $scope.widget.dataScheme.type;

            $scope.bubbleMetric = {
              title: metricData.name,
              value: AnalyticsHelper.formatValue(valueType, metricData.value),
              variation: metricData.variation,
              data: {
                key: metricData.id,
                value: metricData.value,
                index: metricData.index
              },
              css: {
                titleIcon: getIcon(metricData.id),
                widgetSize: 'widget-small'
              }
            };

            function getIcon(name) {
              if (angular.isDefined($scope.widget.style)) {
                if (angular.isDefined($scope.widget.style.icons)) {
                  if ($scope.widget.style.icons[name]) {
                    return $scope.widget.style.icons[name];
                  }
                }
              }
              return AdAvailableSn.getIcon(name);
            }
          }

          var Chart = {

            create: function() {
              return {
                api: null,
                state: {}
              };
            },

            getConfigObj: function(chartReference) {
              var widget = $scope.widget;
              var widgetChartConfig = widget.charts.detail;
              return AnalyticsConfigs.getChartConfig(widget, widgetChartConfig, chartReference);
            },

            update: function() {
              var waitIntents = 0;

              //wait max 320ms to get Highcharts API (20 times * 16ms timeout);
              //some weird bug displaying the graph? try increasing time waiting for,
              //but remember, 400ms and above makes user to loose the sensation of "instantability"
              var maxWaitIntents = 40;

              waitForChartAPI();

              function waitForChartAPI() {
                $timeout(function() {
                  if ($scope.chart.api !== null) {
                    updateChart();
                  } else {
                    waitIntents++;
                    if (waitIntents < maxWaitIntents) {
                      waitForChartAPI()
                    } else {
                      $log.warn('max waiting time for chart API reached. Aborting chart update');
                    }
                  }
                }, 16);
              }

              function updateChart() {
                var metricScheme = _.findWhere(
                  $scope.widget.dataScheme.metrics,
                  { name: 'detail' }
                );
                var series = AnalyticsHelper.getSeries(
                  $scope.sample, metricScheme, $scope.parentMetric
                );
                if (series && series.length) {
                  addSeries(series);
                } else {
                  $scope.state.flags.invalidContent = true;
                }

                function addSeries(series) {
                  angular.forEach(series, function(serie, index) {
                    var serieObj = {};

                    //add new serie if needed
                    if (index >= $scope.chart.api.series.length) {
                      $scope.chart.config.series[index] = {};
                    }
                    serieObj = $scope.chart.config.series[index];
                    for (var attrname in serie) {
                      serieObj[attrname] = serie[attrname];
                    }
                  });

                  //remove series that we dont need
                  if ($scope.chart.api.series.length > series.length) {
                    var seriesToRemove = $scope.chart.api.series.length - series.length;
                    $scope.chart.config.series.splice(series.length, seriesToRemove);
                  }
                }
              }
            },

            clearSerie: function(serie) {
              serie.data = [];
            },

            addToSerie: function(serie, data) {
              if (data.y > 0) {
                serie.data.push(data);
              }
            }
          }

          $scope.hasVariation = function() {
            return $scope.bubbleMetric && $scope.bubbleMetric.variation !== 0;
          }

          $scope.getVariationIconCssClasses = function() {
            var variation = $scope.bubbleMetric.variation;
            return variation > 0 ? 'fa-angle-up' : variation < 0 ? 'fa-angle-down' : null;
          }

          $scope.getVariationCssClasses = function() {
            var variation = $scope.bubbleMetric.variation;
            return variation > 0 ? 'variation-positive' : variation < 0 ? 'variation-negative' : null;
          }

          updateBubbleMetric();

          $scope.chart = Chart.create();
          $scope.chart.config = Chart.getConfigObj($scope.chart);
          Chart.update();

          const watchBubbleMetricOff = $scope.$watch('bubbleMetric', function(bubbleMetric) {
            if (bubbleMetric && bubbleMetric.data.value) {
              Chart.update();
            }
          });

          const watchSampleCollectionOff = $scope.$watchCollection('sample', function(oldSample, newSample) {
            updateBubbleMetric();
          });

          const analyticsForceUpdateOff = $scope.$on('analytics:forceUpdate', function(){
            updateBubbleMetric();
          });

          $scope.$on('$destroy', function () {
            analyticsForceUpdateOff();
            watchSampleCollectionOff();
            watchBubbleMetricOff();
          });
        }
      ],
      link: function($scope, $element) {}
    };
  }
]);
