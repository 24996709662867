const DepartmentMonitoring = Vue.component('DepartmentMonitoring', {
  computed: {
    ...Vuex.mapGetters(['departments']),
    checked() {
      return this.departments.find(dep => !dep.active) ? false : true;
    }
  },
  methods: {
    ...Vuex.mapActions(['setFilters']),
    filterDepartments() {
      const filteredDepartments = this.departments
        .filter(dep => !dep.active)
        .map(_dep => _dep.id);

      this.setFilters({filter: 'department', payload: filteredDepartments});
      return filteredDepartments.length > 0 ? false : true;
    }
  },
  i18n: {
    messages: {
      es: {
        department: 'Departamento | Departamentos'
      },
      pt: {
        department: 'Departamento | Departamentos'
      },
      en: {
        department: 'Department | Departments'
      }
    }
  },
  template: `
      <CustomSelector
          :filter-data="departments"
          :toggle-all="checked"
          :change-data="filterDepartments"
          :title="$tc('department', 2)"
          :finder="$tc('department', 1)"
      />`
});
