interface Bot {
  allow_manual_activation: boolean
  bot_id: string
  id: number
  name: string
}

class BotService {
  private AdRequest: AdRequestService
  private botsSubject: rxjs.BehaviorSubject = new rxjs.BehaviorSubject()
  private botsObservable: rxjs.Observable = this.botsSubject.pipe(
    rxjs.filter(bots => bots != null)
  )
  private fetching: boolean = false

  constructor(AdRequest) {
    this.AdRequest = AdRequest
    AdRequest.loggedOut.subscribe(() => this.botsSubject.next(null))
  }

  refreshBots(): Promise<Bot[]> {
    this.fetching = true
    return this.AdRequest.get<Bot[]>('/api/v1/botcenter/bots').then(b => {
      this.botsSubject.next(b)
      return b
    }).finally(() => {
      this.fetching = false
    })
  }
  
  getBots(): Promise<Bot[]> {
    /**
     * Returns the current establishment if it is already loaded.
     * If not, it fetches it and then returns.
     */
    let bots = rxjs.firstValueFrom(this.botsObservable)
    if (!this.botsSubject.value && !this.fetching) {
      this.refreshBots()
    }
    return bots
  }

  save(bot) {
    if (!bot.id) {
      return this.createBot(bot);
    } else {
      return this.updateBot(bot);
    }
  }
  async createBot(bot) {
    try {
      const response = await this.AdRequest.post('/api/v1/botcenter/bots', bot);
      return response;
    } catch (error) {
      return error;
    }
  }
  async updateBot(bot) {
    try {
      const response = await this.AdRequest.put(
        `/api/v1/botcenter/bot/${bot.id}`,
        bot
      );
      return response;
    } catch (error) {
      return error;
    }
  }
  delete({id}) {
    return this.AdRequest.delete(`/api/v1/botcenter/bot/${id}`);
  }

  trigger({id}, ticket_id) {
    return this.AdRequest.post(`/api/v1/botcenter/trigger/${id}`, {
      ticket_id
    });
  }

  terminateSession(ticket_id) {
    return this.AdRequest.post('/api/v1/botcenter/terminate/', {
      ticket_id
    });
  }

  getTicketState(ticketId) {
    return this.AdRequest.get(`/api/v1/botcenter/bot_ticket/${ticketId}`);
  }
}
angular.module('postCenterWebClientApp').service('BotService', BotService);
