(function(module) {
  'use strict';
  SignupCtrl.$inject = ['$scope', '$rootScope', 'FireTagService'];
  function SignupCtrl($scope, $rootScope, FireTagService) {
    const vm = this;
    vm.$onInit = () => {
      if ($rootScope.authActive) {
        location.assign('/#/');
      }
      if (Vuex && VueI18n) {
        FireTagService.setPageView({
          title: 'Signup',
          path: `/${window.location.hash}`
        });
        vm.ready = true;
      }
    };
  }
  module.controller('SignupCtrl', SignupCtrl);
})(angular.module('postCenterWebClientApp'));
