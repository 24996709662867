'use strict';
(module => {
  column.$inject = ['$controller'];
  function column($controller) {
    return {
      scope: {
        column: '=',
        ctrl: '=',
        hideControls: '='
      },
      templateUrl: 'blocks/column/views/column.html',
      restrict: 'E',
      link(scope, elem, attrs, transclude) {
        const locals = {
          $scope: scope,
          $element: elem,
          $attrs: attrs,
          $transclude: transclude
        };
        scope = $controller(scope.ctrl, locals);
      }
    };
  }
  module.directive('column', column);
})(angular.module('postCenterWebClientApp'));



