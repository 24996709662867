'use strict';

angular.module('postCenterWebClientApp')
  .directive('handleMessages', function ($rootScope, $timeout) {
    return {
      restrict: 'A',
      link: function ($scope) {

        $scope.$on('$destroy', function () {
          $scope.errorMessageOff();
          $scope.infoMessageOff();
          $scope.warningMessageOff();
          $scope.successMessageOff();
        });

        $scope.errorMessageOff = $rootScope.$on('errorMessage', function (signal, message) {
          var alert = {type: 'danger', msg: message, icon:'fa-fire'};
          addAlert(alert);
        });

        $scope.infoMessageOff = $rootScope.$on('infoMessage', function (signal, message) {
          var alert = {type: 'info', msg: message, icon:'fa-info'};
          addAlert(alert);
        });

        $scope.warningMessageOff = $rootScope.$on('warningMessage', function (signal, message) {
          var alert = {type: 'warning', msg: message, icon:'fa-warning'};
          addAlert(alert);
        });

        $scope.successMessageOff = $rootScope.$on('successMessage', function (signal, message) {
          var alert = {type: 'success', msg: message, icon: 'fa-check'};
          addAlert(alert);
        });

        function addAlert(alert){

          var secondsToFadeOut = 5;

          alert.ts = new Date().getTime();

          $scope.alerts.push(alert);
          alert.timeout = $timeout(function(){
            $scope.alerts.splice($scope.alerts.indexOf(alert),1);
          }, 1000 * secondsToFadeOut);

        }
      }
    };
  });
