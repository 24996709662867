'use strict';

angular
  .module('postCenterWebClientApp')
  .directive('kpiExecutiveTable', function () {
    return {
      scope: {
        period: '=',
        account: '=',
        businessTime: '=',
        filterByCreated: '='
      },
      templateUrl: 'blocks/analytics/views/kpi_executive_table.html',
      restrict: 'E',
      controller: KpiExecutiveTableController,
      controllerAs: 'vm'
    };
  });

KpiExecutiveTableController.$inject = [
  '$scope',
  '$filter',
  '$uibModal',
  'AnalyticsDashboard',
  '$rootScope',
  'localStorageService',
  'UserFilter',
  'adExportToCsv',
  'FireTagService'
];

function KpiExecutiveTableController(
  $scope,
  $filter,
  $uibModal,
  AnalyticsDashboard,
  $rootScope,
  localStorageService,
  UserFilter,
  adExportToCsv,
  FireTagService
) {
  var selectTabQuit;
  var vm = this;
  var translate = $filter('translate')

  vm.executiveKPIOpen = true;
  vm.loading = false;
  vm.softLoading = false;
  vm.tableData = {};
  vm.openHelpModal = openHelpModal;
  vm.enabledKpis = [];

  vm.exportExecutiveTable = () => {
    const title = translate(
      'ANALYTICS_DASHBOARD_EXECUTIVES_TABLE_TITLE'
    );
    const names = translate('ANALYTICS_DASHBOARD_EXECUTIVES_NAME');
    const headers = [];
    const data = [];
    headers.push(names);

    vm.enabledKpis.map(kpi => {
      headers.push(kpi.label);
    });
    vm.tableData.map(value => {
      let user = {};
      user[headers[0]] = value.name;
      vm.enabledKpis.map((kpi, i) => {
        const index = i + 1;
        user[headers[index]] = value[kpi.name];
      });
      data.push(user);
    });
    adExportToCsv.exportToCsv(title, data);
    FireTagService.setEvent({
      name: 'exportacion_general',
      params: {
        export_name: title
      }
    });
  };

  UserFilter.updateDepartmentsOptions(vm).then(results => {
    const currentUser = results[0]
    const departments = results[1]
    vm.filterParams = {
      name: '',
      department: UserFilter.getDefaultDepartment(currentUser, departments)
    }
  })

  vm.multiSelectTranslations = {
    selectAll: translate('MULTISELECT_SELECT_ALL'),
    selectNone: translate('MULTISELECT_SELECT_NONE'),
    reset: translate('MULTISELECT_RESET'),
    search: translate('MULTISELECT_SEARCH'),
    nothingSelected: translate('MULTISELECT_NOTHING_SELECTED')
  };

  var kpiDefs = {
    created_tickets: {
      name: 'created_tickets',
      ticked: true,
      percent: true,
      label: translate(
        'ANALYTICS_DASHBOARD_EXECUTIVES_TICKETS_CREATED'
      ),
      help: translate(
        'ANALYTICS_DASHBOARD_EXECUTIVES_TICKETS_CREATED_HELP'
      )
    },
    assigned_tickets: {
      name: 'assigned_tickets',
      ticked: true,
      percent: true,
      label: translate(
        'ANALYTICS_DASHBOARD_EXECUTIVES_TICKETS_ASSIGNED'
      ),
      help: translate(
        'ANALYTICS_DASHBOARD_EXECUTIVES_TICKETS_ASSIGNED_HELP'
      )
    },
    closed_tickets: {
      name: 'closed_tickets',
      ticked: true,
      percent: true,
      label: translate(
        'ANALYTICS_DASHBOARD_EXECUTIVES_TICKETS_CLOSED'
      ),
      help: translate(
        'ANALYTICS_DASHBOARD_EXECUTIVES_TICKETS_CLOSED_HELP'
      )
    },
    ignored_tickets: {
      name: 'ignored_tickets',
      ticked: true,
      percent: true,
      label: translate(
        'ANALYTICS_DASHBOARD_EXECUTIVES_TICKETS_IGNORED'
      ),
      help: translate(
        'ANALYTICS_DASHBOARD_EXECUTIVES_TICKETS_IGNORED_HELP'
      )
    },
    opened_tickets: {
      name: 'opened_tickets',
      ticked: true,
      percent: true,
      label: translate(
        'ANALYTICS_DASHBOARD_EXECUTIVES_TICKETS_OPENED'
      ),
      help: translate(
        'ANALYTICS_DASHBOARD_EXECUTIVES_TICKETS_OPENED_HELP'
      )
    },
    answered_tickets: {
      name: 'answered_tickets',
      ticked: false,
      percent: true,
      label: translate(
        'ANALYTICS_DASHBOARD_EXECUTIVES_TICKETS_ANSWERED'
      ),
      help: translate(
        'ANALYTICS_DASHBOARD_EXECUTIVES_TICKETS_ANSWERED_HELP'
      )
    },
    not_answered_tickets: {
      name: 'not_answered_tickets',
      ticked: false,
      percent: true,
      label: translate(
        'ANALYTICS_DASHBOARD_EXECUTIVES_TICKETS_NOT_ANSWERED'
      ),
      help: translate(
        'ANALYTICS_DASHBOARD_EXECUTIVES_TICKETS_NOT_ANSWERED_HELP'
      )
    },
    classified_tickets: {
      name: 'classified_tickets',
      ticked: false,
      percent: true,
      label: translate(
        'ANALYTICS_DASHBOARD_EXECUTIVES_TICKETS_CLASSIFIED'
      ),
      help: translate(
        'ANALYTICS_DASHBOARD_EXECUTIVES_TICKETS_CLASSIFIED_HELP'
      )
    },
    important_tickets: {
      name: 'important_tickets',
      ticked: false,
      percent: true,
      label: translate(
        'ANALYTICS_DASHBOARD_EXECUTIVES_TICKETS_IMPORTANT'
      ),
      help: translate(
        'ANALYTICS_DASHBOARD_EXECUTIVES_TICKETS_IMPORTANT_HELP'
      )
    },
    addressed_tickets: {
      name: 'addressed_tickets',
      ticked: false,
      percent: true,
      label: translate(
        'ANALYTICS_DASHBOARD_EXECUTIVES_TICKETS_ADDRESSED'
      ),
      help: translate(
        'ANALYTICS_DASHBOARD_EXECUTIVES_TICKETS_ADDRESSED_HELP'
      )
    },
    addressed_in_sla_tickets: {
      name: 'addressed_in_sla_tickets',
      ticked: false,
      percent: true,
      label: translate(
        'ANALYTICS_DASHBOARD_EXECUTIVES_TICKETS_ADDRESSED_IN_SLA'
      ),
      help: translate(
        'ANALYTICS_DASHBOARD_EXECUTIVES_TICKETS_ADDRESSED_IN_SLA_HELP'
      )
    },
    no_client_reply: {
      name: 'no_client_reply',
      ticked: false,
      percent: true,
      label: translate(
        'ANALYTICS_DASHBOARD_EXECUTIVES_TICKETS_NO_CLIENT_REPLY'
      ),
      help: translate(
        'ANALYTICS_DASHBOARD_EXECUTIVES_TICKETS_NO_CLIENT_REPLY_HELP'
      )
    },
    addressed_time_tickets: {
      name: 'addressed_time_tickets',
      ticked: false,
      format: 'date',
      label: translate(
        'ANALYTICS_DASHBOARD_EXECUTIVES_TICKETS_ADDRESSED_TIME'
      ),
      help: translate(
        'ANALYTICS_DASHBOARD_EXECUTIVES_TICKETS_ADDRESSED_TIME_HELP'
      )
    },
    closed_time_tickets: {
      name: 'closed_time_tickets',
      ticked: false,
      format: 'date',
      label: translate(
        'ANALYTICS_DASHBOARD_EXECUTIVES_TICKETS_CLOSED_TIME'
      ),
      help: translate(
        'ANALYTICS_DASHBOARD_EXECUTIVES_TICKETS_CLOSED_TIME_HELP'
      )
    },
    addressed_clients: {
      name: 'addressed_clients',
      ticked: false,
      label: translate(
        'ANALYTICS_DASHBOARD_EXECUTIVES_TICKETS_ADDRESSED_CLIENTS'
      ),
      help: translate(
        'ANALYTICS_DASHBOARD_EXECUTIVES_TICKETS_ADDRESSED_CLIENTS_HELP'
      )
    },
    closed_tickets_performance: {
      name: 'closed_tickets_performance',
      ticked: false,
      format: 'performance',
      label: translate(
        'ANALYTICS_DASHBOARD_EXECUTIVES_TICKETS_CLOSED_PERFORMANCE_CLIENTS'
      ),
      help: translate(
        'ANALYTICS_DASHBOARD_EXECUTIVES_TICKETS_CLOSED_PERFORMANCE_CLIENTS_HELP'
      )
    },
    addressed_tickets_performance: {
      name: 'addressed_tickets_performance',
      ticked: false,
      format: 'performance',
      label: translate(
        'ANALYTICS_DASHBOARD_EXECUTIVES_TICKETS_ADDRESSED_PERFORMANCE_CLIENTS'
      ),
      help: translate(
        'ANALYTICS_DASHBOARD_EXECUTIVES_TICKETS_ADDRESSED_PERFORMANCE_CLIENTS_HELP'
      )
    }
  };

  var storageKpiDefList = 'analytics:dashboard:executiveKpiDefList';
  var storedKpiDefList = localStorageService.get(storageKpiDefList);
  (storedKpiDefList || []).forEach(function (kpidef) {
    if (kpidef.name in kpiDefs) {
      kpiDefs[kpidef.name].ticked = kpidef.ticked;
    }
  });

  vm.availableKpis = _.values(kpiDefs);

  var in_progress_idx = _.findIndex(vm.availableKpis, function (kpi) {
    return kpi.name === 'in_progress_tickets';
  });
  if ($rootScope.establishment.config.workflow_enabled) {
    if (in_progress_idx < 0) {
      vm.availableKpis.splice(3, 0, {
        name: 'in_progress_tickets',
        ticked: false,
        percent: true,
        label: translate(
          'ANALYTICS_DASHBOARD_EXECUTIVES_TICKETS_IN_PROGRESS'
        ),
        help: translate(
          'ANALYTICS_DASHBOARD_EXECUTIVES_TICKETS_IN_PROGRESS_HELP'
        )
      });
    }
  } else if (in_progress_idx >= 0) {
    vm.availableKpis.splice(in_progress_idx, 1);
  }

  const availableKpisWatcherOff = $scope.$watch(
    function () {
      return vm.availableKpis;
    },
    function () {
      localStorageService.set(storageKpiDefList, vm.availableKpis);
    },
    true
  );

  vm.humanizeDuration = function (seconds) {
    return moment.duration(seconds, 'seconds').humanize();
  };

  vm.percentage = function (dividend, divisor) {
    var percentage = 0;
    if (divisor > 0) {
      percentage = ((dividend / divisor) * 100.0).toFixed(1);
    }
    return $filter('number')(percentage, 1) + '%';
  };

  // Reload on executives tab selected
  var tabSelectedEvent = 'analytic:dashboard:tabSelected';
  selectTabQuit = $scope.$on(tabSelectedEvent, function (event, selectedTab) {
    if (selectedTab === 'executives') {
      refreshTable(false);
    }
  });

  $scope.$on('$destroy', function () {
    selectTabQuit();
  });

  // Reload on period, account, businessTime change
  const periodWatcherOff = $scope.$watch('period', function () {
    refreshTable(true);
  });
  const accountWatcherOff = $scope.$watch('account', function () {
    refreshTable(true);
  });
  const businessTimeWatcherOff = $scope.$watch('businessTime', function () {
    refreshTable(true);
  });
  const filterByCreatedWatcherOff = $scope.$watch('filterByCreated', function () {
    refreshTable(true);
  });

  const filterParamsWatcherOff = $scope.$watchCollection('vm.filterParams', function () {
    refreshTable(true);
  });

  $scope.$on('$destroy', () => {
    availableKpisWatcherOff();
    periodWatcherOff();
    accountWatcherOff();
    businessTimeWatcherOff();
    filterByCreatedWatcherOff();
    filterParamsWatcherOff();
  });

  function refreshTable(softLoading) {
    if (softLoading) {
      vm.softLoading = true;
    } else {
      vm.loading = true;
    }

    AnalyticsDashboard.executiveKPI(
      'executives_detail_table',
      $scope.period.now,
      $scope.period.until,
      $scope.account,
      $scope.businessTime,
      $scope.filterByCreated
    )
      .then(function (tableData) {
        // Users filter
        var preFilteredUsers = UserFilter.getFilteredUsers(
          vm.filterParams.name,
          undefined,
          vm.filterParams.department
        );
        tableData = _.filter(tableData, function (user, cmid) {
          for (var index = 0; index < preFilteredUsers.length; index++) {
            if (preFilteredUsers[index].id === parseInt(cmid)) {
              return true;
            }
          }
          return false;
        });
        tableData.push(totalRow(tableData));
        vm.tableData = tableData;
        if (softLoading) {
          vm.softLoading = false;
        } else {
          vm.loading = false;
        }
      })
      .catch(function (error) {
        if (softLoading) {
          vm.softLoading = false;
        } else {
          vm.loading = false;
        }
      });
  }

  function totalRow(tableData) {
    var totalRow = {};
    var singleRow = tableData[0];
    _.each(singleRow, function (value, key) {
      if (!isNaN(value)) {
        totalRow[key] = 0;
        tableData.forEach(function (row) {
          totalRow[key] += row[key];
        });
      }
    });
    totalRow['name'] = 'Total';
    return totalRow;
  }

  function openHelpModal() {
    $uibModal.open({
      templateUrl: 'blocks/analytics/views/dashboard_help_modal.html',
      size: 'sm',
      controller: ['$scope', '$uibModalInstance', '$filter', modalController]
    });

    function modalController($scope, $uibModalInstance, $filter) {
      $scope.title = 'ANALYTICS_DASHBOARD_EXECUTIVES_TABLE_TITLE';
      $scope.helpText = translate(
        'ANALYTICS_DASHBOARD_EXECUTIVES_TABLE_HELP'
      );
      $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
      };
    }
  }
}
