'use strict';

var app = angular.module('postCenterWebClientApp');
app.controller('ActionsModalMessageCtrl', function (
  $rootScope, $scope, messageData, action, AdNotification,
  Message, $uibModalInstance, $timeout, $filter
){
  /*
    private methods and props
  */

  $scope.action = action;

  function translate(key) {
    return $filter('translate')(key);
  }

  function getActionTextsForInterface(action, socialNetwork) {

    if (socialNetwork === 'twitter') {

      if (action === 'like') {
        $scope.headerMessage = translate('MESSAGE_MORE_OPTIONS_LIKE_ON_TWITTER');
        $scope.actionButtonText = translate('MESSAGE_MORE_OPTIONS_LIKE_TWITTER');
      } else if (action === 'share') {
        $scope.headerMessage = translate('MESSAGE_MORE_OPTIONS_SHARE_ON_TWITTER');
        $scope.actionButtonText = translate('MESSAGE_MORE_OPTIONS_SHARE_TWITTER');
      }

    } else if (socialNetwork === 'facebook') {

      if (action === 'like') {
        $scope.headerMessage = translate('MESSAGE_MORE_OPTIONS_LIKE_ON_FACEBOOK');
        $scope.actionButtonText = translate('MESSAGE_MORE_OPTIONS_LIKE_FACEBOOK');
      } else if (action === 'share') {
        $scope.headerMessage = translate('MESSAGE_MORE_OPTIONS_SHARE_ON_FACEBOOK');
        $scope.actionButtonText = translate('MESSAGE_MORE_OPTIONS_SHARE_FACEBOOK');
      }

    }

  }

  function filterSearchSocialAccounts(accounts, socialNetwork) {
    return _.filter(
      accounts,
      function(account) {
        var socialNetworkMatches = true;

        if (angular.isDefined(socialNetwork)){
          if (account.sn !== socialNetwork){
            socialNetworkMatches = false;
          }
        }
        return account.type !== 'search' && socialNetworkMatches;
      }
    );
  }

  function filterAuthorSocialAccount(accounts, authorAccount) {
    return _.filter(
      accounts,
      function(account) {
        return account.uid !== authorAccount.uid;
      }
    );
  }

  function getSelectedSocialAccounts(accounts) {
    var selectedAccounts = [];
    angular.forEach(accounts, function (account) {
      if (account.selected) {
        selectedAccounts.push({
          uid: account.uid,
          sn: account.sn
        });
      }
    });
    return selectedAccounts;
  }

  function getAvailableSocialAccounts(socialNetwork){
    //ignore search accounts
    var availableAccounts = filterSearchSocialAccounts(
                              angular.copy($rootScope.accounts),
                              socialNetwork);

    //if social accounts is the same on message.recipients, mark it as selected.
    function checkIfShouldBeSelected(socialAccount){
      var isSelected = _.find(angular.copy(messageData.recipients),
        function(selectedSocialAccount) {
          var sameSN = selectedSocialAccount.sn === socialAccount.sn;
          var sameUID = selectedSocialAccount.uid  === socialAccount.uid;
          return sameSN && sameUID;
        });

      if (isSelected === undefined) {
        socialAccount.selected = false;
      } else {
        socialAccount.selected = true;
      }

      return socialAccount;
    }

    if (messageData === undefined) {
      $scope.actionIntent.socialAccounts = availableAccounts;
    } else {
      $scope.actionIntent.socialAccounts = _.map(availableAccounts, checkIfShouldBeSelected);

      //do not show author social accounts
      $scope.actionIntent.socialAccounts = filterAuthorSocialAccount(
                                            $scope.actionIntent.socialAccounts,
                                            messageData.author);
    }
  }

  /*
    scoped methods and props
  */

  $scope.isAnyAccountSelected = false;

  $scope.onAccountSelection = function(){
    var socialAccounts = getSelectedSocialAccounts(
      $scope.actionIntent.socialAccounts
    );

    $scope.isAnyAccountSelected = socialAccounts.length != 0;
  }

  $scope.closeModal = function(){
    $uibModalInstance.dismiss('cancel');
  }

  $scope.triggerAction = function(action){

    if (!$scope.pendingRequest){
      $scope.pendingRequest = true;

      var socialAccounts = getSelectedSocialAccounts(
        $scope.actionIntent.socialAccounts
      );

      if (socialAccounts.length > 0){
        var messageId = messageData.resource_id;

        var actionMethod;
        var actionCode;
        if (action === 'share'){
          actionMethod = Message.share;
          actionCode = 'share_message';
        } else if (action === 'like'){
          actionMethod = Message.like;
          actionCode = 'like_message';
        }

        actionMethod(messageId, {'social_accounts': socialAccounts})
          .then(function(response){
            AdNotification.success(201, actionCode);
            $scope.closeModal();
          })
          .catch(function(exception){
            if (exception && exception.response && exception.response.detail){
              $scope.error = true;
              $scope.errorMessage = exception.response.detail;

              $timeout(function(){
                $scope.error = false;
                $scope.errorMessage = null;
              }, 5000);

            }
            AdNotification.error(exception, actionCode);
          })
          .finally(function(){
            $scope.pendingRequest = false;
          });

      }else{
        AdNotification.error(400, actionCode);
        $scope.pendingRequest = false;
      }
    }

  }

  /*
    start da party
  */
  function init(){

    var socialNetworkFilter = messageData.sn || undefined;
    $scope.height = 'auto';
    $scope.error = false;
    $scope.errorMessage = null;
    $scope.pendingRequest = false;
    $scope.socialNetwork = socialNetworkFilter;

    $scope.actionIntent = {
      socialAccounts: []
    }

    getAvailableSocialAccounts(socialNetworkFilter);
    getActionTextsForInterface(action, socialNetworkFilter);
    $scope.onAccountSelection();
  }

  init();

});
