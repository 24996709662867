interface UserChatMetaData {
  establishment_id: int;
  establishment_name: string;
}

interface TalkcenterSettings {
  user_id?: string;
  user_email?: string;
  user_name?: string;
  user_custom_data?: UserChatMetaData;
}

class AdTalkcenterService {
  created: boolean = false;
  TALKCENTER_TOKEN: string;

  constructor(TALKCENTER_TOKEN) {
    this.TALKCENTER_TOKEN = TALKCENTER_TOKEN;
  }

  start(token: string, settings: TalkcenterSettings) {
    if (!this.created) {
      if (!window.TalkCenter) {
        window.TalkCenter = {
          token,
          settings
        };
      }
      this.createScript();
    }
  }

  open() {
    window.TalkCenter?.open();
  }

  shutDown() {
    document.querySelectorAll('#talkcenter').forEach(elem => {
      elem.parentNode.removeChild(elem);
    });
    window['TalkCenter'] = undefined;
    this.created = false;
  }

  loaded() {
    return document.getElementById('talkcenter');
  }

  createScript() {
    const tc = document.createElement('script');
    tc.type = 'text/javascript';
    tc.async = true;
    tc.src = 'https://app.talkcenter.io/v2/talkcenter.min.js';
    const [s] = document.getElementsByTagName('script');
    if (s.parentNode) {
      s.parentNode.insertBefore(tc, s);
    }
    this.created = true;
  }

  getSnippet() {
    // para crear un snippet de talkcenter
  }
}

angular
  .module('postCenterWebClientApp')
  .service('AdTalkcenterService', AdTalkcenterService);
