(module => {
  'use strict';
  comment.$inject = [];
  function comment() {
    return {
      scope: {
        message: '=',
        noDate: '&',
        noReplyButton: '&'
      },
      templateUrl: 'blocks/message/views/comment.html',
      restrict: 'E',
      controller: 'MessageCtrl'
    };
  }
  module.directive('comment', comment);
})(angular.module('postCenterWebClientApp'));
