'use strict';

angular.module('postCenterWebClientApp')
  .directive('metricBubble', [function () {
    return {
      scope : {
        metric: '='
      },
      templateUrl: 'blocks/analytics/views/metric_bubble.html',
      restrict: 'AE',
      controller: ['$scope', '$rootScope', '$filter',
        function ($scope, $rootScope, $filter) {
          $scope.state = {
            value:{
              animating: false
            }
          }

          $scope.getWidgetCssClasses = function(){
            return $scope.metric.css.widgetSize || null
          }

          $scope.getTitleIconCssClasses = function(){
            return $scope.metric.css.titleIcon
          }

          $scope.getValueCssClasses = function(){
            var cssClasses = '';

            if ($scope.metric.data && $scope.metric.data.highlightValue){
              cssClasses += ' highlight-value';
            }
            return cssClasses
          }

          $scope.getVariationCssClasses = function(){
            var cssClasses = null;

            if ($scope.metric.variation > 0){
              cssClasses = 'variation-positive'
            }

            if ($scope.metric.variation < 0){
              cssClasses = 'variation-negative'
            }

            return cssClasses
          }

          $scope.getVariationIconCssClasses = function(){
            var cssClasses = null;

            if ($scope.metric.variation > 0){
              cssClasses = 'fa-angle-up'
            }

            if ($scope.metric.variation < 0){
              cssClasses = 'fa-angle-down'
            }
            return cssClasses;
          }

          $scope.hasVariation = function(){
            return $scope.metric.variation !== 0
          }

        }
      ],
      link: function($scope, $element){}
    };
  }]);

