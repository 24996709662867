'use strict';

interface ErrorData {
  status: any
  description: string
  sn: string
  customCode: any
}


class AdNotification {

  $rootScope: any;
  AdMessageDefinitions: AdMessageDefinitions
  $timeout: any;
  $filter: any;

  constructor (
    $rootScope,
    AdMessageDefinitions: AdMessageDefinitions,
    $timeout,
    $filter
  ) {
    this.$rootScope = $rootScope;
    this.AdMessageDefinitions = AdMessageDefinitions;
    this.$timeout = $timeout;
    this.$filter = $filter;
  };

  handleErrorData(data: any): ErrorData {
    var status;
    var description;
    var sn;
    var customCode;
    if (typeof data === 'object') {
      if (angular.isNumber(data.status) || angular.isString(data.status)) {
        status = data.status;
      } else {
        status = 500;
      }
      if (data.response) {
        if (typeof data.response === typeof 'string') {
          if (data.response.length < 80) {
            description = data.response;
          }
        } else if (typeof data.response === typeof {}) {
          description = data.response.detail;
          sn = data.response['social_network'];
          customCode = data.response['third_party_http_code'];
        }
      }
    } else {
      status = data;
    }
    return {status, description, sn, customCode};
  };

  getMessageFromAdMessageDefinitions(
      status: number,
      method: string,
      detail: string,
      sn: string,
      code: number,
      data: any
    ): string {
    return this.AdMessageDefinitions.getMethodMessage(
      status,
      method,
      detail,
      sn,
      code,
      data
    );
  };

  isNewBackendNotification(data: any): boolean {
    return data.response && data.response.hasOwnProperty('frontendNotification');
  };

  isLegacyBackendNotification(data: any): boolean {
    return data.response && data.response.hasOwnProperty('error_translate')
  };

  isBackendNotification(data: any): boolean {
    return this.isLegacyBackendNotification(data) || this.isNewBackendNotification(data)
  };

  handleBackendNotification(data: any): string {
    // allow backend to define its own message via response
    if(this.isLegacyBackendNotification(data)){
      // legacy backend notification interface
      return this.$filter('translate')(data.response.error_translate)
    }

    // new backend notification interface
    let key = data.response.frontendNotification.key
    let interpolations = data.response.frontendNotification.interpolations

    return this.$filter('translate')(key, interpolations)
  };

  handleLegacyNotification(data: any, action: string): string {
    var info: ErrorData = this.handleErrorData(data);

    var status = info.status,
      description = info.description,
      sn = info.sn,
      code = info.customCode;

    return this.getMessageFromAdMessageDefinitions(
      status,
      action,
      description,
      sn,
      code,
      data.response
    );
  };

  launchNotification(emit: string, data: any, action: string): void {
    let message: string;
    if (this.isBackendNotification(data)) {
      message = this.handleBackendNotification(data)
    } else {
      message = this.handleLegacyNotification(data, action)
    };


    this.$rootScope.$emit(emit, message);
  };

  notify (message: string, messageType: string) {
    if (!messageType.match(/success|error|info|warning/)) {
      console.error('No valid alert message type');
      return;
    }

    this.$rootScope.$emit(messageType + 'Message', message);
  };

  success (data: any, action: string) {
    this.launchNotification('successMessage', data, action);
  };

  error (data: any, action: string) {
    this.launchNotification('errorMessage', data, action);
  };

  info (data: any, action: string) {
    this.launchNotification('infoMessage', data, action);
  };

  warning (data: any, action: string) {
    this.launchNotification('warningMessage', data, action);
  };

  getMessage (data: any, action: string) {
    var info: ErrorData = this.handleErrorData(data);

    return this.getMessageFromAdMessageDefinitions(
      info.status,
      action,
      info.description,
      info.sn,
      info.customCode,
      data.response
    );
  };

  isKind (data: any, code: number, contains: string) {
    var info: ErrorData = this.handleErrorData(data);
    return info.status === code && info.description.indexOf(contains) > -1;
  };
}

var app = angular.module('postCenterWebClientApp');
app.service('AdNotification', AdNotification);
