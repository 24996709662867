const DepartmentFilter = Vue.component('DepartmentFilter', {
  data() {
    return {
      hiddenDepartments: []
    };
  },
  computed: {
    ...Vuex.mapGetters(['departments', 'filterBy', 'supervisorWithTeam'])
  },
  created() {
    this.hiddenDepartments = this.filterBy('department');
  },
  methods: {
    ...Vuex.mapActions(['setFilters']),
    filterDepartments() {
      let filteredDepartments = this.departments
        .filter(dep => !dep.active)
        .map(_dep => _dep.id);

      if (this.supervisorWithTeam) {
        filteredDepartments = Array.from(
          new Set([...this.hiddenDepartments, ...filteredDepartments])
        );
      }
      this.setFilters({filter: 'department', payload: filteredDepartments});
    }
  },
  i18n: {
    messages: {
      es: {
        department: 'Departamento | Departamentos'
      },
      pt: {
        department: 'Departamento | Departamentos'
      },
      en: {
        department: 'Department | Departments'
      }
    }
  },
  template: `
    <CustomSelector
        :filter-data="departments"
        :change-data="filterDepartments"
        :title="$tc('department', 2)"
        :finder="$tc('department', 1)"
    />`
});
