const EpaRanking = Vue.component('EpaRanking', {
  props: {
    rankingType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      rankings: ['tags', 'stars', 'emotions'],
      type: ''
    };
  },
  computed: {
    ...Vuex.mapGetters('epaConfig', ['getSurveyAlternatives']),
    survey() {
      return this.getSurveyAlternatives('csat');
    }
  },
  i18n: {
    messages: {
      es: {
        ranking: 'Clasificación',
        rankingType: 'Elije el modo de calificación',
        types: ['Etiquetas con palabras', 'Estrellas', 'Emociones']
      },
      pt: {
        ranking: 'Classificação',
        rankingType: 'Escolha o modo de classificação',
        types: ['Tags com palavras', 'Estrelas', 'Emoções']
      },
      en: {
        ranking: 'Ranking',
        rankingType: 'Choose the rating mode',
        types: ['Tags with words', 'Stars', 'Emotions']
      }
    }
  },
  methods: {
    getRankingImage(ranking) {
      const stars = '⭐⭐⭐⭐⭐';
      const emojis = '😡 😕 😐 😊 😍';
      return ranking === 'emotions' ? emojis : stars;
    }
  },
  template: `<div class="my-1">
    <strong>{{ $t('rankingType') }}</strong>
    <div class="d-flex space-between">
      <label class="radio-label" v-for="(ranking, index) in rankings">
        <input
          type="radio"
          class="ads-radio"
          :id="ranking"
          :value="ranking"
          :disabled="!survey.enabled"
          name="ranking"
          v-model="survey.ranking_type"
        />
        <div class="mx-1">
          <span > {{ $t('types['+ index +']')}}</span>
          <div v-if="ranking !== 'tags'">{{ getRankingImage(ranking) }}</div>
        </div>
      </label>
    </div>
  </div>`
});
