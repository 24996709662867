const AssignConfig = Vue.component('AssignConfig', {
  computed: {
    ...Vuex.mapGetters(['limit']),
    active() {
      return this.limit ? 1 : 2;
    }
  },
  methods: {
    ...Vuex.mapActions(['openConfig'])
  },
  template: `
    <div class="d-flex align-center mt-1 mb-2">
      <div class="d-flex align-center">
        <span class="fa fa-cog mr-1" />
        <span>{{$tc('limits.active', active)}}</span>
      </div>
      <a class="ads-button ads-button--link mx-1" @click="openConfig">
        <span>{{$t('limits.link')}}</span>
      </a>
      <span class="workload-divider"></span>
      <p class="mb-0 ml-1">{{ $t('info.monitoring') }}
        <a href="#/analytics/monitoring/" class="ads-button ads-button--link ml-1">
          <span>{{ $t('info.goToMonitoring') }}</span>
        </a>
      </p>
    </div>`
});
