'use strict';

angular.module('postCenterWebClientApp')
  .factory('AdAvailableSn', function () {
    return {
      availableSn: availableSn,
      availableIcons: availableIcons,
      getIcon: getIcon,
      getTitle: getTitle,
      getColor: getColor,
      channelsFromAccounts: channelsFromAccounts
    };

    function availableSn() {
      return [
        'linkedin', 'facebook', 'twitter', 'email', 'chat', 'play_store', 'app_store',
        'whatsapp', 'instagram', 'external'
      ];
    }

    function availableIcons() {
      return _.map(availableSn(), function (key) {
        return getIcon(key);
      })
    }

    function getIcon(snName) {
      var icons = {
        'linkedin': 'fa-linkedin',
        'facebook': 'fa-facebook',
        'Facebook': 'fa-facebook',
        'twitter': 'fa-twitter',
        'Twitter': 'fa-twitter',
        'twitter_search': 'fa-twitter',
        'Twitter Search': 'fa-twitter',
        'email': 'fa-envelope',
        'Email': 'fa-envelope',
        'whatsapp': 'fa-whatsapp',
        'Whatsapp': 'fa-whatsapp',
        'WhatsApp': 'fa-whatsapp',
        'instagram': 'fa-instagram',
        'Instagram': 'fa-instagram',
        'chat': 'fa-postcenter',
        'Chat': 'fa-postcenter',
        'app_store': 'fa-apple',
        'App Store': 'fa-apple',
        'play_store': 'fa-google-play',
        'Play Store': 'fa-google-play',
        'external': 'fa-external-account',
        'External': 'fa-external-account'
      }
      return icons[snName];
    }

    function getTitle(snName) {
      var titles = {
        'linkedin': 'Linkedin',
        'facebook': 'Facebook',
        'Facebook': 'Facebook',
        'twitter': 'Twitter',
        'Twitter': 'Twitter',
        'twitter_search': 'Twitter Search',
        'Twitter Search': 'Twitter Search',
        'email': 'Email',
        'Email': 'Email',
        'whatsapp': 'Whatsapp',
        'Whatsapp': 'Whatsapp',
        'instagram': 'Instagram',
        'Instagram': 'Instagram',
        'chat': 'Chat',
        'Chat': 'Chat',
        'app_store': 'App Store',
        'App Store': 'App Store',
        'play_store': 'Play Store',
        'Play Store': 'Play Store',
        'external': 'Webhook',
        'External': 'Webhook'
      }
      return titles[snName];
    }

    function getColor(snName) {
      var colors = {
        'linkedin': '#3b5998',
        'facebook': '#3b5998',
        'Facebook': '#3b5998',
        'twitter': '#55acee',
        'Twitter': '#55acee',
        'twitter_search': '#55acee',
        'Twitter Search': '#55acee',
        'email': '#f57d00',
        'Email': '#f57d00',
        'whatsapp': '#4dc247',
        'Whatsapp': '#4dc247',
        'instagram': 'black',
        'Instagram': 'black',
        'chat': '#03a9f4',
        'Chat': '#03a9f4',
        'app_store': '#b0bec5',
        'App Store': '#b0bec5',
        'play_store': '#dd4b39',
        'Play Store': '#dd4b39',
        'external': 'black',
        'External': 'black'
      }
      return colors[snName];
    }

    function channelsFromAccounts(accounts = []) {
      const uniqueSn = accounts.reduce((networks, account) => {
        const {sn} = account;
        networks = {...networks, [sn]: getTitle(sn)};
        return networks;
      }, {});

      return uniqueSn;
    }
  });
