'use strict';

angular
  .module('postCenterWebClientApp')
  .directive('dashboardTimeControl', function () {
    return {
      scope : {
        onSelected: '='
      },
      templateUrl: 'blocks/analytics/views/dashboard_time_control.html',
      restrict: 'AE',
      controller: DashboardTimeControlController
    }
  });

DashboardTimeControlController.$inject = [
  '$scope', '$rootScope', '$window', '$filter'
];

function DashboardTimeControlController($scope, $rootScope, $window, $filter) {
  $scope.expanded = false;
  $scope.selected = null;
  $scope.customDates = {
    start: null,
    startError: false,
    end: null,
    endError: false,
    errorMessage: null,
  }

  var shouldCollapse = false;
  var ignoreOutsideClick = false;

  $scope.toggleExpanded = function (event) {

    if ($scope.expanded) {
      if (shouldCollapse) {
        $scope.expanded = false;
        shouldCollapse = false;
        event.stopPropagation();
      }
    } else {
      $scope.expanded = true;
      event.stopPropagation();
    }
    $window.onclick = null;
    if ($scope.expanded) {
      $window.onclick = function (e) {
        if (!ignoreOutsideClick) {
          $scope.expanded = false;
          $scope.$apply();
        }
        ignoreOutsideClick = false;
      };
    }
  };

  $scope.ignoreClicks = function () {
    ignoreOutsideClick = true;
  };

  $scope.presetPeriods = [
    {
      label: 'CURR_HOUR',
      startGetter: generateStartGetter('hour'),
      endGetter: nowGetter,
    },
    {
      label: 'CURR_DAY',
      startGetter: generateStartGetter('day'),
      endGetter: nowGetter,
    },
    {
      label: 'CURR_WEEK',
      // Change week for isoweek because we start the week on monday not sunday
      startGetter: generateStartGetter('isoweek'),
      endGetter: nowGetter,
    },
    {
      label: 'CURR_MONTH',
      startGetter: generateStartGetter('month'),
      endGetter: nowGetter,
    },
  ];

  $scope.selectPreset = function (period) {
    $scope.selected = period;
    shouldCollapse = true;
    storeSelection();
    notifySelection();
  }

  $scope.selectCustom = function () {
    if (verifyCustomDates()) {
      $scope.selected = generateCustomPeriod();
      shouldCollapse = true;
      storeSelection();
      notifySelection();
    }
  }

  loadStoredSelection();
  if (!$scope.selected) {
    $scope.selectPreset($scope.presetPeriods[0]);
  }

  // private ----

  function nowGetter () {
    return moment();
  }

  function generateStartGetter (unit) {
    return function () {
      return nowGetter().startOf(unit);
    }
  }

  function generateCustomDateGetter (date) {
    return function () {
      return $scope.customDates[date];
    }
  }

  function verifyCustomDates () {

    var start = $scope.customDates.start;
    var end = $scope.customDates.end;

    if (start == null || end == null) {
      $scope.customDates.startError = start == null;
      $scope.customDates.endError = end == null;
      $scope.customDates.errorMessage = 'TIME_CONTROL_PERIOD_INVALID';
      return false;
    }

    $scope.customDates.startError = false;
    $scope.customDates.endError = false;
    start = moment(start);
    end = moment(end);

    if (moment.duration(end - start) > moment.duration(13, "months")) {
      $scope.customDates.errorMessage = 'TIME_CONTROL_PERIOD_TOO_LONG';
    } else if (end <= start) {
      $scope.customDates.errorMessage = 'TIME_CONTROL_PERIOD_INVALID';
    } else if (moment() < start) {
      $scope.customDates.errorMessage = 'TIME_CONTROL_FUTURE_PERIOD_INVALID';
      $scope.customDates.startError = true;
    } else {
      $scope.customDates.errorMessage = null;
      return true;
    }

    return false;
  }

  function generateCustomPeriod () {
    var start = moment($scope.customDates.start);
    var end = moment($scope.customDates.end);
    return {
      label: 'TIMECONTROL_CUSTOM_PERIOD',
      startGetter: function () { return start; },
      endGetter: function () {
        var now = moment();
        return end < now ? end : now;
      }
    }
  }

  function notifySelection () {
    if ($scope.selected != null && angular.isFunction($scope.onSelected)) {
      $scope.onSelected(angular.copy($scope.selected));

    }
  }

  function storeSelection () {
    localStorage.setItem("dashboard-selected-period", $scope.selected.label);
    if ($scope.selected.label === 'TIMECONTROL_CUSTOM_PERIOD') {
      var customDates = angular.toJson($scope.customDates);
      localStorage.setItem("dashboard-custom-dates", customDates);
    }
  }

  function loadStoredSelection () {
    var label = localStorage.getItem("dashboard-selected-period");
    if (label === 'TIMECONTROL_CUSTOM_PERIOD') {
      var customDates = localStorage.getItem("dashboard-custom-dates");
      $scope.customDates = angular.fromJson(customDates);
      $scope.selected = generateCustomPeriod();
      notifySelection();
    } else {
      var period = _.indexBy($scope.presetPeriods, 'label')[label];
      if (period != null) {
        $scope.selected = period;
        notifySelection();
      }
    }
  }
};
