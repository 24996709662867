const EpaAnalytics = Vue.component('EpaAnalytics', {
  template: `<div class="epa-analytics">
  <SectionHeader
    :title="$t('title')"
    icon="poll"
    support-section="anal%C3%ADtica/89RzusWYBEWNTBJg2WFu5G/anal%C3%ADtica-satisfacci%C3%B3n-%F0%9F%A7%AE/r7RcXZ65CczVQfpA7T1rKt">
    <template v-slot:subheader>
      <span />
    </template>
  </SectionHeader>
  <EpaHeaderFilters />
  <EpaTabs />
</div>`
});
