'use strict';

type CustomDataType = 'text' | 'textarea' | 'checkbox' | 'radiobutton' | 'select' | 'list' | 'boolean' | 'datetime'


class CustomDataOption {
  index: number
  key: string
  value: string
  deleted: boolean
  integration_key: string
  custom_data_id: string
}


class CustomData {
  deleted: boolean
  department_ids: number[]
  entity: 'C' | 'M' | 'U'
  fixed: boolean
  help?: string
  index: number
  integration_key?: string
  is_crm_integrable: boolean
  is_crm_searchable: boolean
  is_integrable: boolean
  key: string
  label: string
  level: number
  list: string[]
  only_read: boolean
  options: CustomDataOption[]
  placeholder?: string
  required: boolean
  required_to_close: boolean
  resource_id: string
  type: CustomDataType
  visible: boolean
}

class CustomDataService {
  private AdRequest: AdRequestService
  private customData: AdModelFetcher<CustomData[]>
  
  constructor(AdRequest: AdRequestService) {
    this.AdRequest = AdRequest
    this.customData = new AdModelFetcher(
      () => this.AdRequest.get<CustomData[]>('/api/v1/custom_data/')
    )
  }

  getCustomData(): Promise<CustomData[]> {
    return this.customData.get()
  }

  addCustomData (data, config){
    var path = '/api/v1/custom_data/';
    return this.AdRequest.post(path, data, config);
  }

  editCustomData(data, config){
    var path = '/api/v1/custom_data/'+data['resource_id']+'/';
    return this.AdRequest.put(path, data, config);
  }

  deleteCustomData(data, config){
    var path = '/api/v1/custom_data/' + data['resource_id']+'/';
    return this.AdRequest.delete(path, data, config);
  }
}

angular
  .module('postCenterWebClientApp')
  .service('CustomData', CustomDataService)
