/**
 * analytics states config
 */
app.config([
  '$routeProvider',
  '$stateProvider',
  '$urlServiceProvider',
  function ($routeProvider, $stateProvider, $urlServiceProvider) {
    var states = {};

    /********************************
      ANALYTICS
    ********************************/

    /*
     * /analytics/
     */
    states.analytics = {
      name: 'analytics',
      abstract: true,
      url: '/analytics/',
      templateUrl: 'blocks/analytics/views/analytics.html',
      controller: 'AnalyticsCtrl'
    };

    $stateProvider.state(states.analytics);
    $urlServiceProvider.rules.when('/analytics/', [
      '$state',
      'UserService',
      function ($state, UserService: UserService) {
        UserService.getProfile().then(user => {
          if (user.isSupervisor) {
            $state.go('analytics.dashboard');
          } else {
            $state.go('analytics.clients');
          }
        });
      }
    ]);

    /********************************
      DASHBOARD
    ********************************/
    /*
     * /analytics/dashboard/
     */
    states.dashboard = {
      name: 'analytics.dashboard',
      url: 'dashboard/',
      views: {
        content: {
          templateUrl: 'blocks/analytics/views/dashboard.html',
          controller: 'AnalyticsDashboardCtrl'
        }
      },
      resolve: {
        userAllowed: function (
          $q,
          UserService: UserService,
          AppStateService: AppStateService
        ) {
          return UserService.getProfile().then(user => {
            if (user.isAdmin || user.isSupervisor || user.isAnalyst) {
              return $q.resolve();
            } else {
              AppStateService.goToInbox();
              return $q.reject();
            }
          });
        }
      }
    };
    $stateProvider.state(states.dashboard);
    /********************************
      MONITORING
    ********************************/
    /*
     * /analytics/monitoring/
     */
    states.monitoring = {
      name: 'analytics.monitoring',
      url: 'monitoring/',
      views: {
        content: {
          templateUrl: 'blocks/analytics/monitoring/views/monitoring.html',
          controller: 'MonitoringCtrl',
          controllerAs: 'vm'
        }
      },
      resolve: {
        userAllowed: function (
          $q,
          UserService: UserService,
          AppStateService: AppStateService
        ) {
          return UserService.getProfile().then(user => {
            if (user.isAdmin || user.isAnalyst || user.isSupervisor) {
              return $q.resolve();
            } else {
              AppStateService.goToInbox();
              return $q.reject();
            }
          });
        }
      }
    };

    $stateProvider.state(states.monitoring);

    /********************************
      history
    ********************************/
    /*
     * /analytics/history/
     */
    states.history = {
      name: 'analytics.history',
      url: 'history/',
      views: {
        content: {
          templateUrl: 'blocks/analytics/history/history.html',
          controller: 'AnalyticsHistoryCtrl',
          controllerAs: 'vm'
        }
      },
      resolve: {
        userAllowed: function (
          $q,
          UserService: UserService,
          AppStateService: AppStateService
        ) {
          return UserService.getProfile().then(user => {
            if (user.isAdmin || user.isSupervisor || user.isAnalyst) {
              return $q.resolve();
            } else {
              AppStateService.goToInbox();
              return $q.reject();
            }
          });
        }
      }
    };

    $stateProvider.state(states.history);

    states.epaAnalytics = {
      name: 'analytics.satisfaction',
      url: 'satisfaction/',
      views: {
        content: {
          templateUrl: 'blocks/analytics/epa/epa-analytics.html',
          controller: 'EpaAnalyticsCtrl',
          controllerAs: 'vm'
        }
      },
      resolve: {
        userAllowed: function (
          $q,
          UserService: UserService,
          AppStateService: AppStateService,
          EstablishmentService: EstablishmentService
        ) {
          return UserService.getProfile().then(user => {
            return EstablishmentService.getEstablishment().then(establishment => {
              if ((user.isAdmin || user.isAnalyst || user.isSupervisor) && establishment.features.satisfaction_module) {
                return $q.resolve()
              } else {
                AppStateService.goToInbox();
                return $q.reject();
              }
            });
          });
        }
      }
    };

    $stateProvider.state(states.epaAnalytics);
    /********************************
      CUSTOM DASHBOARD
    ********************************/
    /*
     * /analytics/dashboard/
     */
    states.dashboard = {
      name: 'analytics.custom-dashboard',
      url: 'custom-dashboard/',
      views: {
        content: {
          templateUrl: 'blocks/analytics/views/custom_dashboard.html',
          controller: 'AnalyticsCustomDashboardCtrl'
        }
      },
      resolve: {
        userAllowed: function (
          $q,
          UserService: UserService,
          AppStateService: AppStateService
        ) {
          return UserService.getProfile().then(user => {
            if (user.isAdmin || user.isSupervisor || user.isAnalyst) {
              return $q.resolve();
            } else {
              AppStateService.goToInbox();
              return $q.reject();
            }
          });
        }
      }
    };

    $stateProvider.state(states.dashboard);

    /********************************
      CLIENTS
    ********************************/
    /*
     * /analytics/clients/
     */
    states.clients = {
      name: 'analytics.clients',
      url: 'clients/',
      views: {
        content: {
          templateUrl: 'blocks/analytics/views/clients.html',
          controller: 'AnalyticsClientsCtrl'
        }
      },
      resolve: {
        userAllowed: function (
          $q,
          UserService: UserService,
          AppStateService: AppStateService
        ) {
          return UserService.getProfile().then(user => {
            if (user.isAdmin || user.isAnalyst) {
              return $q.resolve();
            } else {
              AppStateService.goToInbox();
              return $q.reject();
            }
          });
        }
      }
    };

    $stateProvider.state(states.clients);

    /********************************
      MESSAGES
    ********************************/
    /*
     * /analytics/messages/
     */
    states.messages = {
      name: 'analytics.messages',
      url: 'messages/',
      views: {
        content: {
          templateUrl: 'blocks/analytics/views/messages.html',
          controller: 'AnalyticsMessagesCtrl'
        }
      },
      resolve: {
        userAllowed: function (
          $q,
          UserService: UserService,
          AppStateService: AppStateService
        ) {
          return UserService.getProfile().then(user => {
            if (user.isAdmin || user.isAnalyst) {
              return $q.resolve();
            } else {
              AppStateService.goToInbox();
              return $q.reject();
            }
          });
        }
      }
    };

    $stateProvider.state(states.messages);

    /********************************
      TICKETS
    ********************************/
    /*
     * /analytics/tickets/
     */
    states.tickets = {
      name: 'analytics.tickets',
      url: 'tickets/',
      views: {
        content: {
          templateUrl: 'blocks/analytics/views/tickets.html',
          controller: 'AnalyticsTicketsCtrl'
        }
      },
      resolve: {
        userAllowed: function (
          $q,
          UserService: UserService,
          AppStateService: AppStateService
        ) {
          return UserService.getProfile().then(user => {
            if (user.isAdmin || user.isAnalyst) {
              return $q.resolve();
            } else {
              AppStateService.goToInbox();
              return $q.reject();
            }
          });
        }
      }
    };

    $stateProvider.state(states.tickets);

    /********************************
      USER METRICS
    ********************************/
    /*
     * /analytics/user-metrics/
     */
    states.userMetrics = {
      name: 'analytics.user-metrics',
      url: 'user-metrics/',
      views: {
        content: {
          templateUrl: 'blocks/analytics/views/user_metrics.html',
          controller: 'AnalyticsUserMetricsCtrl'
        }
      },
      resolve: {
        userAllowed: function (
          $q,
          UserService: UserService,
          AppStateService: AppStateService
        ) {
          return UserService.getProfile().then(user => {
            if (user.isAdmin || user.isAnalyst) {
              return $q.resolve();
            } else {
              AppStateService.goToInbox();
              return $q.reject();
            }
          });
        }
      }
    };

    $stateProvider.state(states.userMetrics);

    /********************************
      ACTIVITY LOG
    ********************************/

    /**
     * analytics/activity-log
     */
    states.activityLog = {
      name: 'analytics.activity-log',
      url: 'activity-log/',
      views: {
        content: {
          templateUrl: 'blocks/analytics/views/activity_log/log.html',
          controller: 'ActivityLogController as vm'
        }
      },
      resolve: {
        userAllowed: function (
          $q,
          UserService: UserService,
          AppStateService: AppStateService
        ) {
          return UserService.getProfile().then(user => {
            if (user.isAdmin || user.isSupervisor || user.isAnalyst) {
              return $q.resolve();
            } else {
              AppStateService.goToInbox();
              return $q.reject();
            }
          });
        }
      }
    };
    $stateProvider.state(states.activityLog);

    /********************************
      WORD CLOUD
    ********************************/
    /*
     * /analytics/dashboard/
     */
    states.wordCloud = {
      name: 'analytics.word-cloud',
      url: 'word-cloud/',
      views: {
        content: {
          templateUrl: 'blocks/analytics/views/word_cloud.html',
          controller: 'WordCloudCtrl'
        }
      },
      resolve: {
        userAllowed: function (
          $q,
          UserService: UserService,
          AppStateService: AppStateService
        ) {
          return UserService.getProfile().then(user => {
            if (user.isAdmin || user.isAnalyst) {
              return $q.resolve();
            } else {
              AppStateService.goToInbox();
              return $q.reject();
            }
          });
        }
      }
    };

    $stateProvider.state(states.wordCloud);
  }
]);
