'use strict';

var app = angular.module('postCenterWebClientApp');
app.service('SocialAccountWhatsappModals', function SocialAccount($uibModal) {
  function getResolve(config) {
    var whatsapp = undefined;
    var whatsappState = undefined;

    if (config) {
      whatsapp = config.whatsapp;
      whatsappState = config.whatsappState;
    }

    var resolve = {
      whatsapp: function() {
        return whatsapp;
      },
      whatsappState: function() {
        return whatsappState;
      }
    };

    return resolve;
  }
  function smoochConnection() {
    return {};
  }

  this.addAccount = function() {
    return $uibModal.open({
      templateUrl:
        'blocks/admin/views/social_accounts/new_whatsapp_account.html',
      controller: 'WhatsappNewAccountCtrl',
      size: 'small',
      resolve: getResolve(),
      backdrop: false,
      keyboard: false
    });
  };
  this.addSmoochAccount = function() {
    return $uibModal.open({
      templateUrl:
        'blocks/admin/views/social_accounts/new_whatsapp_smooch.html',
      controller: 'addWhatsappSmoochCtrl',
      controllerAs: 'vm',
      size: 'lg',
      resolve: smoochConnection(),
      backdrop: false,
      keyboard: false
    });
  };
  this.clientStatus = function(status) {
    return $uibModal.open({
      templateUrl:
        'blocks/admin/views/social_accounts/modal_whatsapp_client_status.html',
      controller: 'WhatsappClientStatusCtrl',
      size: 'lg',
      resolve: {
        clientStatus: function() {
          return status;
        }
      },
      backdrop: false,
      keyboard: false
    });
  };
});
