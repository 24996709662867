'use strict';

var app = angular.module('postCenterWebClientApp');

app.directive('timepointsConfiguration', function () {
  return {
    templateUrl: 'blocks/admin/views/integration_config/timepoints.html',
    restrict: 'E',
    scope: {
      config: '='
    },
    controller: ['$scope', '$rootScope', 'AdRequest', '$filter', 'BusinessRulesService',
      function ($scope, $rootScope, AdRequest, $filter, BusinessRulesService) {

        $scope.availableTriggers = [rules.triggers.CaseClosedTrigger];

        $scope.availableConditions = [
          rules.conditions.SocialAccountCondition.identifier,
          rules.conditions.CaseTypificationCondition.identifier
        ];

        $scope.actionTypes = [
          rules.actions.SendTimepointsSurveyAction.identifier,
          rules.actions.EnableManualTimepointsSurvey.identifier
        ];
        $scope.rules = [];

        BusinessRulesService.get().then(function (rules) {
          $scope.rules = rules.filter(function (rule) {
            return $scope.actionTypes.includes(rule.actions[0].identifier)
          });
        });

        $scope.addRule = function () {
          $scope.rules.push(rules.BusinessRule.createEmptyRule())
        };

        $scope.saveTimepointsConfig = function () {
          var data = {
            active: $scope.config.active,
            token: $scope.config.token
          };
          AdRequest.post('/api/v1/timepoints/config', data).then(
            function (result) {
              $rootScope.$emit(
                'successMessage',
                $filter('translate')('ADMIN_INTEGRATION_SAVE_SUCCESS')
              );
              // $scope.config.showing = false;
            },
            function (error) {
              $rootScope.$emit(
                'errorMessage',
                $filter('translate')('ADMIN_INTEGRATION_SAVE_ERROR')
              )
            }
          )
        }

      }]
  };
});
