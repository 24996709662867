const DatetimePopover = Vue.component('DatetimePopover', {
  props: {
    updateFiltersHandler: Function,
    from: String,
    to: String
  },
  data() {
    return {
      customHour: false,
      errorMessage: '',
      custom: {
        hour: {
          from: '00:00',
          to: '23:59'
        },
        date: {}
      },
      masks: {
        input: 'D/MMM/YYYY',
        title: 'MMMM',
        weekdays: 'WWW'
      }
    };
  },
  computed: {
    ...Vuex.mapGetters({
      locale: 'locale',
      limits: 'getLimits',
      limitDate: 'getLimitDate',
      limitDays: 'getLimitDays'
    }),
    getMinDate() {
      return !this.custom.date.to ? new Date() : this.custom.date.to;
    }
  },
  mounted() {
    this.setLimitDate();
  },
  methods: {
    ...Vuex.mapActions(['setLimitDate']),
    getAttrs(input) {
      return {
        placeholder: this.$t(input),
        readonly: true
      };
    },
    updateFilters() {
      const {
        hour: {from: hourFrom, to: hourTo},
        date: {from: rangeStart = '', to: rangeEnd = ''}
      } = this.custom;
      if (rangeStart && rangeEnd) {
        const formatRangeFrom = this.getDateFormat({
          date: rangeStart,
          time: hourFrom
        });
        const formatRangeTo = this.getDateFormat({
          date: rangeEnd,
          time: hourTo
        });
        const converToLocalTs = date => moment(date).format('X');

        this.updateFiltersHandler({
          from: converToLocalTs(formatRangeFrom),
          to: converToLocalTs(formatRangeTo)
        });
        this.errorMessage = '';
      } else {
        this.errorMessage = this.$t('custom-date.non-dates');
      }
    },
    getDateFormat({date, time}) {
      const [hours, minutes] = time.split(':');
      const day = date.getDate();
      const month = date.getMonth();
      const year = date.getFullYear();
      return new Date(year, month, day, Number(hours), Number(minutes));
    }
  },
  template: `<div class="custom-filters-date">
                <h6><strong>{{ $t('custom_range') }}</strong></h6>
                <div class="custom-dates">
                  <p>{{ $t('choose_period') }}</p>
                  <div class="d-flex grid-flex">
                    <v-date-picker
                      class="flex-col-2 mr-2"
                      mode="single"
                      :locale="locale"
                      :masks="masks"
                      :min-date="limitDate"
                      :max-date="getMinDate"
                      v-model="custom.date.from"
                      color="teal">
                      <template v-slot="{ inputValue, inputEvents }">
                      <input
                        class="w-90 picker-input picker-input--active bold"
                        :value="inputValue"
                        :placeholder="$t('from')"
                        v-on="inputEvents"
                        readonly
                      />
                    </template>
                    <template slot="footer" v-if="limits.limit_max >= 0">
                      <i class="fa fa-exclamation-circle-fas calendar-limits-icon"></i>
                      <p class="calendar-limits">
                        <span v-html="$t('calendar-limits', {limit: limitDays})"></span>
                        <a :href="$t('calendar-limits-link')" target="_blank">{{$t('calendar-limits-btn')}}</a>
                      </p>
                    </template>
                    </v-date-picker>

                    <v-date-picker
                      class="flex-col-2"
                      :locale="locale"
                      :masks="masks"
                      :min-date="custom.date.from"
                      :max-date="new Date()"
                      v-model="custom.date.to"
                      color="teal">
                      <template v-slot="{ inputValue, inputEvents }">
                      <input
                        class="w-90 picker-input picker-input--active bold"
                        :value="inputValue"
                        :placeholder="$t('to')"
                        v-on="inputEvents"
                        readonly
                        :disabled="!custom.date.from"
                      />
                    </template>
                    <template slot="footer" v-if="limits.limit_max >= 0">
                      <i class="fa fa-exclamation-circle-fas calendar-limits-icon"></i>
                      <p class="calendar-limits">
                        <span v-html="$t('calendar-limits', {limit: limitDays})"></span>
                        <a :href="$t('calendar-limits-link')" target="_blank">{{$t('calendar-limits-btn')}}</a>
                      </p>
                    </template>
                    </v-date-picker>
                  </div>
                </div>
                <div class="custom-hours my-2">
                  <label class="ads-checkbox">
                    <input type="checkbox"
                    v-model="customHour"
                    :checked="customHour" />
                    <span class="mx-1"> {{ $t('choose_hours') }}</span>
                  </label>
                  <div class="grid-flex d-flex space-between ml-5"
                    :class="{disabled: !customHour}">
                    <div class="flex-col-2">Desde:
                      <input type="time"
                        :disabled="!customHour"
                        v-model="custom.hour.from" required class="ad-input-time" /></div>
                    <div class="flex-col-2">Hasta:
                      <input type="time"
                        :disabled="!customHour"
                        v-model="custom.hour.to" class="ad-input-time"
                      />
                    </div>
                  </div>
                </div>
                <button
                  class="btn btn-primary"
                  @click="updateFilters()"
                >
                  {{ $t('inputs.accept') }}
                </button>
                <div class="text-center mt-2" v-if="errorMessage">
                  <span>{{ errorMessage }}</span>
                </div>

              </div>`
});
