const SlaAgent = Vue.component('SlaAgent', {
  components: {
    ProgressBar: deskUI.ProgressBar
  },
  props: {
    reload: {
      type: String,
      default: null
    }
  },
  computed: {
    ...Vuex.mapGetters(['sla']),
    getColors() {
      const {
        addressed_in_sla_tickets: {percent = 0}
      } = this.sla || {};
      if (percent >= 90) {
        return 'sla-optimal';
      } else if (percent >= 80) {
        return 'sla-good';
      } else if (percent >= 70) {
        return 'sla-careful';
      }
      return 'sla-alert';
    }
  },
  watch: {
    reload(update) {
      this.reset(update);
    }
  },
  methods: {
    ...Vuex.mapActions(['reset']),
    getProgress(value, total) {
      return ((value / total) * 100).toFixed(0);
    },
    getOf(from, to) {
      return `${from} ${this.$t('of')} ${to}`;
    }
  },
  template: `<aside class="d-flex px-2 pt-1" :class="!sla.limit && 'space-between'">
    <div class="sla-agent d-flex" >
      <div class="sla-performance">
        <strong class="performance">{{ $t('myStats') }}</strong>
        <a class="link-help" href="https://help.adere.so/canales/m-tricas-del-agente" target="_blank">
          <span class="fa fa-question-circle help-icon"></span>
        </a>
      </div>
      <AgentSlaStatus :addressed="sla.addressed_in_sla_tickets"
        v-tippy="{arrow:true, placement: 'bottom'}"
        :content="$t('tooltips.totalTicketsSla')"/>
      <slaPerformance
        :title="$t('sla.addressed')"
        :stat="sla.addressed_tickets"
        dot
        v-tippy="{arrow:true, placement: 'bottom'}"
        :content="$t('tooltips.totalAnswered')"
      />
     <slaPerformance
        v-if="!sla.limit"
        :title="$t('sla.assigned')"
        :stat="sla.opened_tickets"
        dot
        v-tippy="{arrow:true}"
        :content="$t('tooltips.totalAssigned')"
      />
    </div>
    <div class="sla-agent d-flex ml-2" v-if="sla.limit">
      <slaPerformance
        :title="$t('sla.hold')"
        :stat="getOf(sla.not_answered_tickets, sla.not_answered_tickets_limit)">
        <ProgressBar
          class="mx-1"
          v-tippy="{arrow:true, placement: 'bottom'}"
          :pixel-width="true"
          :content="$t('tooltips.limitTickets')"
          :progress="getProgress(sla.not_answered_tickets, sla.not_answered_tickets_limit)"
        />
      </slaPerformance>
      <slaPerformance
        :title="$t('sla.assigned')"
        :stat="getOf(sla.opened_tickets, sla.opened_tickets_limit)" dot>
        <ProgressBar
          class="mx-1"
          v-tippy="{arrow:true, placement: 'bottom'}"
          :pixel-width="true"
          :content="$t('tooltips.limitAssigned')"
          :progress="getProgress(sla.opened_tickets, sla.opened_tickets_limit)"
        />
      </slaPerformance>
    </div>
    <div class="sla-agent d-flex ml-2" v-if="!sla.limit">
      <SlaPerformance
        :stat="sla.opened_tickets"
        :title="$t('totalTickets')"
        v-tippy="{arrow:true, placement: 'bottom'}"
        :content="$t('tooltips.totalOpened')"
      />
   </div>


  </aside>`
});
const SlaPerformance = Vue.component('SlaPerformance', {
  props: {
    title: {type: String, required: true},
    stat: {type: [String, Number], default: null},
    dot: {type: Boolean}
  },
  template: `<div class="sla-performance" :class="dot && 'dot'">
    <strong>{{ title }}:</strong>
    <slot></slot>
    <span>{{ stat }}</span>
  </div>`
});
const AgentSlaStatus = Vue.component('AgentSlaStatus', {
  props: {
    addressed: {
      type: Object,
      required: true
    }
  },
  computed: {
    getColors() {
      const {percent = 0} = this.addressed || {};
      if (percent >= 90) {
        return 'sla-optimal';
      } else if (percent >= 80) {
        return 'sla-good';
      } else if (percent >= 70) {
        return 'sla-careful';
      }
      return 'sla-alert';
    },
    roundPercent() {
      return Number.parseFloat(this.addressed.percent).toFixed(2);
    }
  },
  template: `<div class="sla-performance">
        <span class="fa fa-circle mr-1 user-status" :class="getColors" />
        <span>
          {{ $t('sla.slaAddressed') }}: {{ addressed.total }} ({{ roundPercent }}%)
        </span>
      </div>`
});
