// TODO: refactor the following controller to be a class!

(function (module) {
  'use strict';
  caseMessagesCtrl.$inject = [
    '$rootScope',
    '$scope',
    '$filter',
    'CaseService',
    '$element',
    '$timeout',
    'AdNotification',
    'UserService',
    'Message',
    'AdPolling',
    '$q'
  ];

  function caseMessagesCtrl(
    $rootScope,
    $scope,
    $filter,
    CaseService,
    $element,
    $timeout,
    AdNotification,
    UserService: UserService,
    messageService: MessageService,
    AdPolling,
    $q
  ) {
    // private instance variables
    const vm = this;
    const pageSize = 15;
    const oldMessagesToRefresh = 5;
    vm.loading = true;
    vm.lastMessageMid = '';

    let couldBeOlderMessages = false;
    let reachedLastOlderMessage = false;
    let conversationRefresher = new ConversationRefresher(vm);

    const translate = (property, params = {}) => $filter('translate')(property, params);

    let messageSelectedSub = messageService.onMessageSelected.subscribe((msg: Message) => {
      if (msg.kind == "message" || msg.kind == "private" ) {
        if (msg.sn == 'whatsapp' && msg.ours) {
          return;
        }
        setReplyMessage(msg)
        // Should we focus or not? commenting for now
        //$element.find('.case-messages__footer form textarea').focus()
      }
    })

    vm.$onInit = () => {
      // instance variables
      vm.loadingOlderMessages = false;
      vm.messagesConversation = [];
      vm.targetOnView = true;
      vm.showMergedCaseMessage = false;
      UserService.getProfile().then(user => {
        vm.canInteract = user.isAdminOrCm
      })
      vm.getMessageDomId = messageService.getDomId;
      vm.onEnd = true;
      vm.onPublishReady = true;
    }

    vm.$onDestroy = () => {
      messageSelectedSub.unsubscribe()
      conversationRefresher.stopPolling()
    }

    // public methods
    vm.onPublish = function () {
      vm.onPublishReady = false;
      conversationRefresher.stopPolling();
      conversationRefresher = new ConversationRefresher(vm);
      conversationRefresher
        .startPolling()
        .getNextPollingPromise()
        .then(function () {
          $timeout(function () {
            _scrollDown();
          }, 1);
          vm.onPublishReady = true;
        });
    }

    vm.scrollToMarker = function (message, speed = 300) {
      const viewport = $element.find('.case-messages__body-viewport');
      viewport.scrollTo('#' + messageService.getDomId(message) + '-flag', speed);
    };

    vm.scrollToMsg = function (message, speed = 300) {
      const viewport = $element.find('.case-messages__body');
      const selector = '#' + messageService.getDomId(message);
      viewport.scrollTo(selector, speed);
    };

    vm.highlightMessage = function (message) {
      const selector = '#' + messageService.getDomId(message);
      angular.element(selector).addClass('highlighted');
      setTimeout(() => {
          angular.element(selector).removeClass('highlighted');
      }, 700);
    }

    $rootScope.$on('highlightQuotedMessage', (event, message) => {
      const quotedMessageMid = message.quoted_message.mid;
      const quotedMessage = vm.messagesConversation.find(msg => msg.mid === quotedMessageMid);
      if (quotedMessage) {
        vm.highlightMessage(quotedMessage)
        vm.scrollToMsg(quotedMessage)
      } else {
        AdNotification.error(400, 'highlightQuotedMessageError');
      };
    });

    vm.merge = function () {
      var action = 'merge_case';
      var absorbedCaseId = vm.caseObjectMerge['resource_id'];
      var masterCaseId = vm.caseObject['resource_id'];
      CaseService.merge(masterCaseId, absorbedCaseId).then(
        function (caseMerged) {
          vm.caseObject = caseMerged;
          vm.caseObject.messages = undefined;
          vm.caseObjectMerge = undefined;
          vm.messagesConversation = [];
          vm.messagesConversationMerge = [];
          vm.canLoadOlderMessages = false;
          vm.loading = true;
          AdNotification.success(201, 'merge_case');
          $rootScope.$emit('updateCaseColumns');
          conversationRefresher.stopPolling();
          conversationRefresher = new ConversationRefresher(vm);
          conversationRefresher.startPolling().onNextPollingDontKeepReply();
          // TODO: error al fusionar ticket!
          // vm.caseDetailCtrl.caseMerged(caseMerged);
          $rootScope.$emit('column:actionOnCase', {
            action: 'updateCase',
            element: caseMerged
          });
        },
        function (data) {
          AdNotification.error(data, action);
        }
      );
    };

    vm.goToMergedCase = function () {
      vm.caseObject = vm.caseObject['merged_with'];
      vm.caseObjectMerge = undefined;
      vm.showMergedCaseMessage = false;
      vm.loading = true;
      conversationRefresher.stopPolling();
      conversationRefresher = new ConversationRefresher(vm);
      conversationRefresher.startPolling().onNextPollingDontKeepReply();
    };

    vm.loadOlderMessages = function () {
      if (vm.loadingOlderMessages) {
        return;
      }
      vm.loadingOlderMessages = true;

      // I set this pivot so we scroll down to here
      var oldestMsg = vm.messagesConversation[0];

      var action = 'loading_older_messages_from_case';
      CaseService.getOlderMessages(
        vm.caseObject['resource_id'],
        oldestMsg.created
      ).then(
        function (olderCaseMessagesSet) {
          vm.loadingOlderMessages = false;
          var oldMessages = olderCaseMessagesSet.messages;
          var resultLength = oldMessages.length;
          if (resultLength < pageSize) {
            reachedLastOlderMessage = true;
            vm.canLoadOlderMessages =
              couldBeOlderMessages && !reachedLastOlderMessage;
          }
          if (resultLength > 0) {
            // We scroll to the marker flag
            var newerMessages = vm.messagesConversation;
            vm.messagesConversation = oldMessages.concat(newerMessages);
            oldestMsg.loadingPoint = true;
            $timeout(function () {
              vm.scrollToMarker(oldestMsg, 0);
            }, 50);
          }
        },
        
        function (data) {
          reachedLastOlderMessage = true;
          vm.canLoadOlderMessages =
            couldBeOlderMessages && !reachedLastOlderMessage;
          vm.loadingOlderMessages = false;
          AdNotification.error(data, action);
        }
      );
    };

    UserService.getProfile().then(user => {
      vm.canUserPost = user.isAdminOrCm
    })

    vm.canReply = function () {
      var caseClosed = vm.caseObject.is_closed;
      return !caseClosed && vm.canUserPost && !vm.isOptInBlocked();
    };

    vm.isOptInBlocked = function () {
      return vm.caseObject.is_optin_case && vm.replyMessage.kind === "private";
    }

    vm.isAppstore = function () {
      return vm.caseObject.sn === 'app_store';
    }

    vm.isWhatsApp = function () {
      return vm.caseObject.sn === 'whatsapp';
    }

    vm.newCaseModal = function () {
      let accountUid = vm.caseObject.establishment_users[0].uid;
      let userUid = vm.userObject.uid;
      $rootScope.$emit('modalNewCaseOpen', accountUid, userUid);
    }

    vm.isEmail = function () {
      return vm.caseObject.sn === 'email';
    }

    vm.timeWindowMessage = function () {

      let user = vm.userObject
      let userName = user.uname;
      let lastMessageTime = vm.userObject.last_message;

      let lastContactMessage = '';
      let policyMessage = '';

      let socialNetworkLabelMap = {
        whatsapp: {
          label: 'WhatsApp',
          helpLink: 'https://www.whatsapp.com/legal/business-policy/?lang=en#:~:text=You%20may%20reply%20to%20a%20user%20message%20without%20use%20of%20a%20Message%20Template%20as%20long%20as%20it%27s%20within%2024%20hours%20of%20the%20last%20user%20message.',
          timeQuantity: '24',
          timeUnit: $filter('translate')('hours')
        },
        facebook: {
          label: 'Facebook',
          helpLink: 'https://developers.facebook.com/docs/messenger-platform/policy/policy-overview#standard_messaging',
          timeQuantity: '7',
          timeUnit: $filter('translate')('days')
        },
        instagram: {
          label: 'Instagram',
          helpLink: 'https://developers.facebook.com/docs/messenger-platform/instagram/',
          timeQuantity: '7',
          timeUnit: $filter('translate')('days')
        },
      }

      let socialNetwork = vm.caseObject.sn;

      let snMap = socialNetworkLabelMap[socialNetwork];

      let hasPublic = vm.caseObject.has_public

      if (hasPublic) {
        policyMessage = translate(
          'TIME_WINDOW_OUT_OF_SCHEDULE_PUBLIC',
          {
            socialNetwork: snMap.label,
            timeQuantity: snMap.timeQuantity,
            timeUnit: snMap.timeUnit
          }
        );
      } else {
        policyMessage = translate(
          'TIME_WINDOW_OUT_OF_SCHEDULE_PRIVATE',
          {
            socialNetwork: snMap.label,
            helpLink: snMap.helpLink,
            timeQuantity: snMap.timeQuantity,
            timeUnit: snMap.timeUnit
          }
        );
      }

      if (lastMessageTime) {
        let lastMessageMoment = moment.utc(lastMessageTime);
        let nowMoment = moment.utc(new Date());
        let diff = nowMoment.diff(lastMessageMoment);
        let lastMessageString = moment.duration(diff).humanize();
        lastContactMessage = translate('TIME_WINDOW_LAST_CONTACT', {userId: userName, lastMessageString: lastMessageString})
      } else {
        lastContactMessage = translate('TIME_WINDOW_NO_MESSAGES', {userId: userName});
      }

      return `${lastContactMessage} ${policyMessage}`;
    };

    const socialNetworkTimeWindowPrivate = {
      whatsapp: 86400,
      facebook: 604800,
      instagram: 604800
    }

    const socialNetworkTimeWindowPublic = {
      facebook: 604800,
      instagram: 604800
    }

    vm.isAnswerable = () => {

      let socialNetwork = vm.caseObject.sn;
      let hasPublic = vm.caseObject.has_public

      let timeWindow = 0;

      if (hasPublic) {
        timeWindow = socialNetworkTimeWindowPublic[socialNetwork]
      } else {
        timeWindow = socialNetworkTimeWindowPrivate[socialNetwork]
      }

      let lastMessageTime = vm.userObject.last_message;

      if (!timeWindow) return true;

      if (!lastMessageTime) return false;

      let lastMessageMoment = moment.utc(lastMessageTime);
      let nowMoment = moment.utc(new Date());
      let diff = nowMoment.diff(lastMessageMoment) / 1000;

      return diff < timeWindow;
    };

    function isLastMessageFromThread (message: Message, nextMessage: Message) {
      return message.ours !== nextMessage.ours;
    };

    function isDifferentAuthor (message: Message, nextMessage: Message) {
      if (message.ours && (message.publisher && nextMessage.publisher)) {
        return message.publisher.cmid !== nextMessage.publisher.cmid;
      }
      return message.sender_id !== nextMessage.sender_id;
    };

    function isDifferentStatus (message: Message, nextMessage: Message) {
      return message.status !== nextMessage.status;
    };

    function isOldEnoughToShowStatus (message: Message, nextMessage: Message) {
      let timeDiff = Number(nextMessage.created) - Number(message.created);
      return timeDiff > 60;
    };

    vm.shouldShowDateAndStatus = function (index: number, message: Message) {
      let nextMessage: Message | null = vm.messagesConversation[index + 1];
      if (
        !nextMessage ||
        isLastMessageFromThread(message, nextMessage) ||
        isOldEnoughToShowStatus(message, nextMessage) ||
        isDifferentAuthor(message, nextMessage) ||
        isDifferentStatus(message, nextMessage)
      ) return true;
      return false;
    }

    vm.is_close = function () {
      return vm.caseObject.is_closed;
    };

    vm.getUserFromCase = function () {
      return vm.caseObject.users.length > 0
        ? vm.caseObject.users[0]
        : vm.caseObject.establishment_users[0];
    };

    // private methods
    const _reloadCaseMessages = (caseObj, keepReply) => {
      // If new messages are more than what we already have,
      // Re-render case messages.

      // This sets if we should show "Load Older Messages"
      vm.canLoadOlderMessages =
        couldBeOlderMessages && !reachedLastOlderMessage;

      if (keepReply && vm.replyMessage) {
        setReplyMessage(vm.replyMessage);
      } else {
        setReplyMessage(findReplyMessage());
      }
    };

    function ConversationRefresher(ctrl) {
      reachedLastOlderMessage = false;
      var vm = this,
        newerThan = null,
        poller = null,
        secondsToRefresh = 10,
        firstTime = true,
        nextPollingDeferred = null,
        keepReply = true;

      // public methods
      vm.startPolling = startPolling;
      vm.stopPolling = stopPolling;
      vm.getNextPollingPromise = getNextPollingPromise;
      vm.onNextPollingDontKeepReply = onNextPollingDontKeepReply;

      function startPolling() {
        poller = AdPolling.start(
          getConversation,
          secondsToRefresh,
          onConversationGetSuccess,
          onConversationGetError,
          '',
          'caseMessages:' + ctrl.caseObject.resource_id
        );
        return vm;
      }

      function stopPolling() {
        if (poller !== null) {
          poller.stop();
        }
        return vm;
      }

      function onNextPollingDontKeepReply() {
        keepReply = false;
        return vm;
      }

      function getNextPollingPromise() {
        nextPollingDeferred = $q.defer();
        return nextPollingDeferred.promise;
      }

      function isPolling() {
        if (poller === null) {
          return false;
        }
        return !poller.stopped;
      }

      function getConversation() {
        // If we have loaded messages, we try to refresh the last ones
        // when asking for newer stuff
        var resourceId = ctrl.caseObject.resource_id;
        var pivotIndex = null;
        // We must keep this because keep the case updated
        CaseService.get(resourceId).then(function (caseObject) {
          CaseService.setFormattedDates(caseObject);
          caseObject.messages_unread = 0;
          ctrl.caseObject = caseObject;
          
          $rootScope.$emit('column:actionOnCase', {
            action: 'updateCase',
            element: caseObject
          });
        });

        // Here I pick the correct date to compare against when asking for newer
        // messages.
        // If null, we get all the newer messages with a limit from backend.
        var messagesLength = ctrl.messagesConversation.length;
        firstTime = messagesLength === 0;
        if (!firstTime) {
          if (messagesLength > oldMessagesToRefresh) {
            // From bottom to top
            pivotIndex = messagesLength - oldMessagesToRefresh;
            newerThan = ctrl.messagesConversation[pivotIndex].created;
          }
        }
        return CaseService.getNewerMessages(resourceId, newerThan);
      }

      function onConversationGetSuccess(caseObject) {
        if (!isPolling()) {
          return;
        }

        if (caseObject.messages.length > 0) {
          var messagesBefore = ctrl.messagesConversation.length;
          ctrl.loading = false;

          if (!newerThan) {
            // All messages must be refreshed
            ctrl.messagesConversation = caseObject.messages;
          } else {
            caseObject.messages.forEach(function (element) {
              ctrl.messagesConversation.forEach(function (belement, j) {
                if (belement && element) {
                  if (belement['resource_id'] === element['resource_id']) {
                    ctrl.messagesConversation.splice(j, 1);
                  }
                }
              });
            });
            ctrl.messagesConversation = ctrl.messagesConversation.concat(
              caseObject.messages
            );
          }

          // we check if there are new messages to emit the newMessages event
          var messagesNow = ctrl.messagesConversation.length;
          var hasNewMessages = messagesNow > messagesBefore;
          if (hasNewMessages) {
            var lastMessage =
              ctrl.messagesConversation[ctrl.messagesConversation.length - 1];

            $scope.$broadcast('caseMessages:newMessages', lastMessage);
          }

          couldBeOlderMessages = false;
          if (ctrl.messagesConversation.length >= pageSize) {
            //This means that there is a posibility to have more pagination.
            couldBeOlderMessages = true;
          }
          _reloadCaseMessages(caseObject, keepReply);
          if (firstTime || caseObject.messages.length > oldMessagesToRefresh) {
            $timeout(function () {
              const shouldScroll = shouldScrollDown(caseObject);
              if (shouldScroll){
                _scrollDown();
              }
              $rootScope.$emit('caseView:opened:finishLoading');
            }, 1);
          }

          if (nextPollingDeferred) {
            nextPollingDeferred.resolve(caseObject);
            nextPollingDeferred = null;
          }

          keepReply = true;
        }
      }

      function onConversationGetError(data) {
        var action = 'get_conversation';

        if (!isPolling()) {
          return;
        }
        ctrl.loading = false;

        if (AdNotification.isKind(data, 404, 'Merge')) {
          ctrl.showMergedCaseMessage = true;
          ctrl.detailError = AdNotification.getMessage(data, action);
        } else if (AdNotification.isKind(data, 404, 'no messages')) {
          ctrl.showMergedCaseMessage = true;
          ctrl.detailError = AdNotification.getMessage(data, action);
        } else {
          AdNotification.error(data, action);
        }

        stopPolling();

        if (nextPollingDeferred) {
          nextPollingDeferred.reject(data);
          nextPollingDeferred = null;
        }

        keepReply = true;
      }
    }

    const saveLastMessage = (mid: string): void => {
      vm.lastMessageMid = mid;
    }

    const shouldScrollDown = (obj: any): boolean => {
      if (obj.messages && obj.messages.length > 0) {
        const lastMessageObj = obj.messages[obj.messages.length - 1];
        if (vm.lastMessageMid === ''){
          saveLastMessage(lastMessageObj.mid);
          return true;
        }
        if (lastMessageObj.mid === vm.lastMessageMid) {
          return false; // Los mid son iguales
        } else {
          saveLastMessage(lastMessageObj.mid); // Reemplazar la constante con el nuevo último mid
          return true; // Los mid son distintos
        }
      } else {
        return true; // No hay mid en el objeto
      }
    }

    const _scrollDown = (to = 'max') => {
      setTimeout(
        () => $element.find('.case-messages__body').scrollTo(to, 300),
        10
      )
    }

    function findReplyMessage() {
      /*
     This method helps to determine to which message of the conversation
     I should reply. If I reply to myvm, it will break the thread_root
     So we must pick the first message which I'm not author from bottom to top.
     */
      const {messagesConversation: reversedConversation} = vm;
      const replyMessage = _.find(reversedConversation, isMessageOfTheirs);

      if (replyMessage) {
        return replyMessage;
      } else {
        return reversedConversation[0];
      }
    }

    function setReplyMessage(message: Message) {
      vm.selectedAccount = $rootScope.accounts.find(function(account) {
        return account.id === vm.caseObject.account_id;
      });
      if (vm.replyMessage) {
        vm.replyMessage.beingReplied = false;
      }
      angular.forEach(vm.messagesConversation, function (curMsg) {
        curMsg.beingReplied = curMsg.mid === message.mid;
      });
      vm.replyMessage = message;
    }

    function isMessageOfTheirs(message) {
      return !message.ours;
    }

    function updateMergeConversation(resourceId) {
      var action = 'get_conversation';
      CaseService.getMessages(resourceId).then(
        function (conversation) {
          $timeout(function () {
            vm.loadingMerge = false;
            vm.messagesConversationMerge = conversation.messages;
            $timeout(function () {
              const shouldScroll = shouldScrollDown(conversation);
              if (shouldScroll){
                _scrollDown();
              }
            }, 100);
          }, 1500);
        },
        function (data) {
          vm.loadingMerge = false;
          AdNotification.error(data, action);
        }
      );
    }

    function updateMergeConflicts(resourceId1, resourceId2) {
      vm.customDataConflicts = [];
      vm.hasCustomDataConflicts = true;
      CaseService.getCustomDataConflicts(resourceId1, resourceId2).then(conflicts => {
        vm.customDataConflicts = [];
        conflicts.forEach(key => {
          vm.customDataConflicts.push(
            $rootScope.customData.find(customData => customData.key == key)
              .label
          );
        });
        vm.hasCustomDataConflicts = conflicts.length > 0;
      });
    }

    const caseMetadataUpdateOff = $rootScope.$on('caseMetadata:hasBeenUpdated', () => {
      if (vm.caseObject && vm.caseObjectMerge)
        updateMergeConflicts(
          vm.caseObject['resource_id'],
          vm.caseObjectMerge['resource_id']
        );
    });

    function refreshMessages() {
      vm.caseObject.messages = undefined;
      vm.caseObjectMerge = undefined;
      vm.messagesConversation = [];
      vm.messagesConversationMerge = [];
      vm.showMergedCaseMessage = false;
      vm.canLoadOlderMessages = false;
      vm.loading = true;

      conversationRefresher.stopPolling();
      conversationRefresher = new ConversationRefresher(vm);
      conversationRefresher.startPolling().onNextPollingDontKeepReply();
    }

    // event hooks
    const replyToEventOff = $scope.$on('replyTo', function (e, message) {
      setReplyMessage(message);
    });

    const caseUpdatedEventOff = $scope.$on('case:hasBeenUpdated', function (event, caseObject) {
      $scope.caseObject = caseObject;
    });

    const userUpdatedEventOff = $scope.$on('userCase:hasBeenUpdated', (_event, userCase) => {
      $scope.userObject = userCase;
    });

    const mergeCaseEventOff = $scope.$on('caseDetail:mergeCase', function () {
      if (vm.caseObjectMerge !== undefined) {
        return;
      }

      vm.loadingMerge = true;
      const action = 'get_previous_case';
      var resourceId = vm.caseObject['resource_id'];
      CaseService.getPreviousCase(resourceId).then(
        function (caseObject) {
          vm.caseObjectMerge = caseObject;
          $timeout(function () {
            updateMergeConversation(caseObject['resource_id']);
            updateMergeConflicts(
              vm.caseObject['resource_id'],
              caseObject['resource_id']
            );
          }, 1);
        },
        function (data) {
          vm.loadingMerge = false;
          AdNotification.error(data, action);
        }
      );
    });

    const changeReplyOff = $scope.$on('caseMessages:changeReply', (e, replyMessage) => {
      setReplyMessage(replyMessage);
    });

    const caseWatcherOff = $scope.$watch('caseObject', function () {
      refreshMessages();
    });

    $scope.$on('$destroy', () => {
      replyToEventOff();
      caseWatcherOff();
      changeReplyOff();
      caseUpdatedEventOff();
      userUpdatedEventOff();
      mergeCaseEventOff();
      caseMetadataUpdateOff();
    });
  }
  module.controller('caseMessagesCtrl', caseMessagesCtrl);
})(angular.module('postCenterWebClientApp'));
