(module => {
  'use strict';
  caseDetail.$inject = [];
  module.directive('caseDetail', caseDetail);
  function caseDetail() {
    return {
      scope: {
        caseObject: '=case',
        userObject: '=user',
        sideViewMenuOption: '=sideViewMenuOption'
      },
      templateUrl: 'blocks/case/views/case_detail.html',
      restrict: 'E',
      controller: 'CaseDetailCtrl',
      controllerAs: 'vm',
      bindToController: true
    };
  }
})(angular.module('postCenterWebClientApp'));
