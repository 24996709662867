(module => {
  'use strict';

  function cmSelectorResults() {
    return {
      bindToController: {
        parent: '=cmSelectorResults'
      },
      controllerAs: 'containerCtrl',
      controller() {},
      link(scope, elem, attr, controller) {
        controller.parent.setContainerElem(elem);
      }
    };
  }
  module.directive('cmSelectorResults', cmSelectorResults);
})(angular.module('postCenterWebClientApp'));
