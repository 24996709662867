'use strict';
(function(module) {
  'use strict';
  caseOmnichannel.$inject = [];
  function caseOmnichannel() {
    return {
      templateUrl: 'blocks/case/views/case_omni_channel.html',
      restrict: 'E',
      controller: 'caseOmnichannelCtrl',
      controllerAs: 'vm',
      scope: {
        userObject: '=userObject'
      },
      bindToController: true
    };
  }
  module.directive('caseOmnichannel', caseOmnichannel);
})(angular.module('postCenterWebClientApp'));
