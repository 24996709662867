(module => {
  'use strict';

  const MAX_ELEMENTS_PER_PAGE = 15;
  const DEFAULT_REFRESH_SECS = 30;

  function deduplicateElements(array: {resource_id}[]): {resource_id}[] {
    const hash = {}
    const deduplicated: {resource_id}[] = []
  
    for (const item of array) {
        if (!hash[item.resource_id]) {
          hash[item.resource_id] = true;
          deduplicated.push(item)
        }
    }
    return deduplicated
  }

  /**
   * https://stackoverflow.com/a/4673436
   * @param baseString
   * @param args
   */
  function formatString(baseString: string, ...args: string[]) {
    args = Array.prototype.slice.call(arguments, 1);
    return baseString.replace(/{(\d+)}/g, (match, number) => {
      return typeof args[number] != 'undefined' ? args[number] : match;
    })
  }

  function notEmpty (value) {
    return value !== undefined && value !== null;
  }

  function paramBelongs(paramInColumn, paramInEntity) {
    return !(notEmpty(paramInColumn) && paramInColumn !== paramInEntity);
  }

  function messageBelongsToColumn(message, column) {
    return (
      paramBelongs(column.answered, message.answered) &&
      paramBelongs(column.assigned, message.assigned)
    )
  }

  function caseBelongsToColumn(targetCase, column, rootScope) {
    if (
      !paramBelongs(column.answered, targetCase.answered) ||
      !paramBelongs(column.assigned, targetCase.assigned) ||
      !paramBelongs(column.important, targetCase.important) ||
      !paramBelongs(column.addressed, targetCase.is_addressed)
    ) {
      return false;
    }
    if (
      column.workflow_status != null &&
      targetCase.workflow_status != null
    ) {
      const column_wsid = column.workflow_status.resource_id;
      const case_wsid = targetCase.workflow_status.resource_id;
      if (column_wsid !== case_wsid) {
        return false;
      }
    }

    if (notEmpty(column.assignment)) {
      switch (column.assignment.filter_type) {
        case 'whitelist':
          return (
            targetCase.current_assignment !== null &&
            targetCase.current_assignment !== undefined &&
            column.assignment.community_managers.includes(
              targetCase.current_assignment.cmid
            )
          );
        case 'not_assigned':
          return (
            targetCase.current_assignment === null ||
            targetCase.current_assignment === undefined
          );
        case 'any':
          return (
            targetCase.current_assignment !== null &&
            targetCase.current_assignment !== undefined
          );
        case 'non_bots':
          return (
            targetCase.current_assignment !== null &&
            targetCase.current_assignment !== undefined &&
            rootScope.botCommunityManagerIds !== null &&
            rootScope.botCommunityManagerIds !== undefined &&
            rootScope.botCommunityManagerIds.includes(
              targetCase.current_assignment.cmid
            )
          );
        case 'only_bots':
          return (
            targetCase.current_assignment !== null &&
            targetCase.current_assignment !== undefined &&
            rootScope.botCommunityManagerIds !== null &&
            rootScope.botCommunityManagerIds !== undefined &&
            !rootScope.botCommunityManagerIds.includes(
              targetCase.current_assignment.cmid
            )
          );
        default:
          return true;
      }
    } else {
      return true;
    }
  }

  ColumnCtrl.$inject = [
    '$rootScope',
    '$scope',
    '$filter',
    '$element',
    '$timeout',
    'ColumnsService',
    'AdNotification',
    'FireTagService'
  ];
  module.controller('ColumnCtrl', ColumnCtrl);
  function ColumnCtrl(
    $rootScope,
    $scope,
    $filter,
    $element,
    $timeout,
    ColumnsService: ColumnsService,
    AdNotification,
    FireTagService
  ) {
    $scope.canRefresh = false
    $scope.loading = true

    $scope.ticketDividedView = false;
    $scope.toDestroy = {};
    const viewDivisionSub = ColumnsService.dividedView.subscribe(dividedView => {
      $scope.ticketDividedView = dividedView
    })

    const {limitReached, canExport, canEditColumn} =
      $scope.$parent.config;

    $scope.limitReached = limitReached;
    $scope.canExport = canExport;
    $scope.canEditColumn = canEditColumn;

    const Ctrl = this;
    $scope.buffer = [];
    $scope.closing = false;
    $scope.bufferLength = 0;

    $scope.polling = null;
    $scope.pollingEnabled = false;
    $scope.updateTime = 0;
    $scope.greaterTime = 0;
    $scope.lesserTime = 0;
    $scope.isFirstLoad = true;
    $scope.signalColumnSelection = false;
    $scope.countLimitColumn = 999;

    $scope.newCasesText = formatString(
      $filter('translate')('COLUMN_NOTIFICATION_MESSAGE'),
      '',
      $filter('translate')($scope.column.column_type),
      's',
      'are'
    )

    $scope.newNonCasesText = formatString(
      $filter('translate')('COLUMN_NOTIFICATION_MESSAGE_NEW'),
      '',
      $filter('translate')($scope.column.column_type),
      's',
      'are'
    )

    Ctrl.getMethod = function getMethod() {
      return ColumnsService.getEntities;
    };

    Ctrl.getQuery = () => {
      const {asc_items: asc, resource_id} = $scope.column;
      const pivotDate = getDateFromPivotEntity();
      let path = `${resource_id}/`;

      if (pivotDate) {
        path += asc ? `?older_than=${pivotDate}` : `?newer_than=${pivotDate}`;

        if (asc) {
          path = `${path}&asc=${asc}`;
        }
      }

      path += `${path[path.length - 1] === '/' ? '?' : '&'}`;
      return path;
    };

    const removeLegacyTickets = async () => {
      try {
        const {resource_id, cases = []} = $scope.column;
        if (cases.length === 0) {
          return [];
        }
        const ids = cases.map(_case => _case.resource_id);
        const legacyCases = await ColumnsService.getLegacyTickets(
          resource_id,
          ids
        );
        if (legacyCases.length === 0) {
          return [];
        }
        $scope.column.cases = cases.filter(
          elem => !legacyCases.includes(elem.resource_id)
        );
        $scope.resetRepeater = {reset: true};
        return legacyCases;
      } catch (error) {
        return [];
      }
    };

    Ctrl.updateColumnWithData = (
      data, firstTime = false, releaseImmediately = false
    ): Promise<any> => {
      const entity = getEntityKey();
      const asc = $scope.column?.asc_items;
      let promise: Promise<any> = undefined;
      if (firstTime || entity !== 'cases') {
        $scope.column.content_count = data.content_count;
        promise = Promise.resolve()
      } else {
        promise = removeLegacyTickets().finally(() => {
          $scope.column.content_count = data.content_count;
        });
      }

      return promise.then(
        () => {
          if (data[entity].length < MAX_ELEMENTS_PER_PAGE && releaseImmediately) {
            $scope.nomoredata = true;
          }

          const newContent = Ctrl.addNewContent(
            data[entity],
            entity,
            releaseImmediately,
            asc
          )

          if (newContent.length < MAX_ELEMENTS_PER_PAGE) {
            const old_length = $scope.bufferLength;
            return Ctrl.requestLastEntities(!asc).finally(() => {
              if ($scope.bufferLength > old_length || newContent.length > 0) {
                //Ctrl.updateColumn(true);
              } else if ($scope.firstReleaseInvertedColumn) {
                $scope.firstReleaseInvertedColumn = false;
                $scope.firstLoading = true;
                //Ctrl.updateColumn();
              } else {
                Ctrl.checkContentCount();
              }
            })
          } else if (newContent.length > 0) {
            // This else if is important to avoid loading double updateColumn.
            // new content on the column we change poll configuration
            //this.updateColumn();
          } else if (
            !$scope.column[entity] &&
            $scope.bufferLength === 0 &&
            $scope.updateTime !== 0
          ) {
            // the column is empty
            //this.updateColumn();
            $scope.updateTime = 0;
          }
        },
        data => Ctrl.handleIssuesWithColumn(data)
      ).finally(() => {
        if ($scope.firstLoading) {
          $scope.firstLoading = false;
        } else {
          $scope.loading = false;
        }
      })
    }
  
    const maxRetries = 10;
    $scope.column.retries = 0;
    let newMessagesAudioNotification = false;
    let newMessagesToastNotification = false;

    $scope.getColumnName = function () {
      const {name} = $scope.column;
      if (!name) {
        $scope.column.name = $filter('translate')('NEWCOLUMN_COLUMN');
      }
      return $scope.column.name;
    };

    $scope.newColumnName = $scope.getColumnName();

    $scope.deleteColumn = function () {
      $scope.closing = true;
      const resourceId = $scope.column.resource_id;
      ColumnsService.deleteColumn(resourceId).then(
        () => {
          $scope.closing = false;
          deleteColumnInterface();
          stopPolling()
        },
        data => {
          AdNotification.error(data, 'delete_column');
        }
      );
    };

    $scope.exportColumn = function () {
      $rootScope.$emit('modalNewColumn', 'Export', $scope.column);
    };

    $scope.editColumn = function () {
      $rootScope.$emit('modalNewColumn', 'Edit', $scope.column);
    };

    // get the next content of the column at the moment of scroll
    $scope.getNextPage = function () {
      $scope.loading = true;

      $scope.lockGetNextPage = true;

      const entityKey = getEntityKey();
      const isCase = entityKey === 'cases';
      const entities = $scope.column[entityKey];

      if (entities.length > 0) {
        let lastEntityCreated = null;
        const lastEntity = entities[entities.length - 1];

        lastEntityCreated = isCase
          ? lastEntity[$scope.column.pagination_field]
          : lastEntity?.created;

        const asc = $scope.column.asc_items;
        const timeline = asc ? 'newer_than' : 'older_than';

        ColumnsService.getTickets(
          $scope.column.resource_id,
          lastEntityCreated,
          asc,
          timeline
        ).then(
          data => {
            const {content_count} = data;
            if (data[entityKey].length > 0) {
              $scope.sortEntities(data[entityKey])
              $scope.column[entityKey] =  deduplicateElements([
                ...$scope.column[entityKey],
                ...data[entityKey]
              ])
              if (content_count === $scope.column[entityKey].length) {
                $scope.nomoredata = true;
              }
            } else {
              $scope.nomoredata = true;
            }
            $scope.lockGetNextPage = false;
            $scope.loading = false;
          },
          data => {
            $scope.lockGetNextPage = false;
            $scope.loading = false;
            Ctrl.handleIssuesWithColumn(data);
          }
        );
      } else {
        $scope.nomoredata = true;
        $scope.lockGetNextPage = false;
      }
    };
    
    $scope.getSocialNetworks = function () {
      if (
        $scope.column.social_networks != null &&
        $scope.column.social_networks.length > 0
      ) {
        return $scope.column.social_networks;
      } else {
        return Array.from(
          new Set($scope.column.social_accounts.map(element => element.sn))
        );
      }
    };

    $scope.sortEntities = function (entities) {
      const entity = getEntityKey();
      if (entity === 'cases') {
        const sortCriteria = $scope.column.pagination_field;
        if ($scope.column.asc_items) {
          entities.sort((en1, en2) => {
            return en1[sortCriteria] - en2[sortCriteria];
          });
        } else {
          entities.sort((en1, en2) => {
            return en2[sortCriteria] - en1[sortCriteria];
          });
        }
      } else {
        if ($scope.column.asc_items) {
          entities.sort((en1, en2) => {
            return en1.created - en2.created;
          });
        } else {
          entities.sort((en1, en2) => {
            return en2.created - en1.created;
          });
        }
      }
    }

    $scope.releaseBuffer = function () {
      const entity = getEntityKey();
      removeBufferedEntities(entity);
      $scope.column[entity] = $scope.buffer.concat($scope.column[entity]);
      if ($scope.isFirstLoad) {
        $scope.isFirstLoad = false;
      } else {
        //Order the content because could be new content in between
        $scope.sortEntities($scope.column[entity])
        Ctrl.markNewMessages(entity);
        updateOtherColumns($scope.buffer, entity, $scope.column.resource_id);
      }

      $scope.column[entity].splice(100);
      $scope.buffer = [];
      $scope.bufferLength = 0;
      $scope.resetRepeater = {reset: true};
      newMessagesAudioNotification = false;
      if ($scope.column[entity].length < MAX_ELEMENTS_PER_PAGE) {
        $scope.nomoredata = true;
      }
    };

    $scope.sameAuthorThread = function (index: number, message: Message) {
      let priorMessage = $scope.column.messages[index - 1]
      if (priorMessage) {
        return priorMessage.author.resource_id == message.author.resource_id && priorMessage.case_id == message.case_id
      }
      return false;
    };

    $scope
      .$createObservableFunction('toggleAutoRefresh')
      .debounce(300)
      .subscribe(() => {
        toggleAutoRefreshFunction();
      });

    function toggleAutoRefreshFunction() {
      $scope.toggleAutoRefreshPending = true;
      ColumnsService.updateColumn(
        $scope.column.resource_id,
        {
          autorefresh: !$scope.column.autorefresh
        },
        'autorefresh'
      )
        .then(result => {
          $scope.column.autorefresh = result.autorefresh
          $scope.refreshColumn()
          $scope.toggleAutoRefreshPending = false
        })
        .finally(() => {});
    }

    $scope.refreshColumn = function (fixCounter = false) {
      Ctrl.cleanUpColumn();
      Ctrl.firstUpdate(fixCounter);
    }

    $scope
      .$createObservableFunction('toggleAsc')
      .debounce(300)
      .subscribe(() => {
        toggleAscFunction();
      });

    function toggleAscFunction() {
      $scope.toggleAscPending = true;
      ColumnsService.updateColumn(
        $scope.column.resource_id,
        {
          asc_items: !$scope.column.asc_items
        },
        'asc_items'
      )
        .then(result => {
          $scope.column.asc_items = result.asc_items;
          $scope.refreshColumn()
        })
        .finally(() => {
          $scope.toggleAscPending = false;
        });
    }

    $scope.toggleColumnAudio = function () {
      $scope.toggleColumnAudioPending = true;
      ColumnsService.updateColumn(
        $scope.column.resource_id,
        {
          audio: !$scope.column.audio
        },
        'audio'
      )
        .then(result => {
          $scope.column.audio = result.audio;
        })
        .finally(() => {
          $scope.toggleColumnAudioPending = false;
        });
    };

    $scope.toggleColumnToast = function () {
      $scope.toggleColumnToastPending = true;

      const inverseToastPermission = function () {
        ColumnsService.updateColumn(
          $scope.column.resource_id,
          {
            toast: !$scope.column.toast
          },
          'toast'
        )
          .then(result => {
            $scope.column.toast = result.toast;
          })
          .finally(() => {
            if ($scope.column.toast) {
              Push.Permission.request(
                () => {},
                () => {
                  const message = $filter('translate')(
                    'PUSH_NOTIFICATION_PERMISSION_ERROR'
                  );
                  $timeout(() => {
                    AdNotification.notify(message, 'warning');
                  }, 1000 * 3);
                }
              );
            }
            $scope.toggleColumnToastPending = false;
          });
      };
      inverseToastPermission();
    };

    $scope.scrollToTop = function () {
      $element.find('.viewport').scrollTo(0, 200);
    };

    const updateOtherColumns = (buffer, entityType, columnId) => {
      const emit =
        entityType === 'cases'
          ? 'column:actionOnCase'
          : entityType === 'messages'
          ? 'column:actionOnMessage'
          : null;

      const maxElementsToNotify = 10;
      const mostNewBuffer = buffer.slice(0, maxElementsToNotify);
      if (emit != null) {
        angular.forEach(mostNewBuffer, (element, index) => {
          $rootScope.$emit(emit, {
            action: 'updateCaseFromOtherColumn',
            element,
            ignoreOnColumn: columnId
          });
        });
      }
    };

    this.markNewMessages = function markNewMessages(entity) {
      _.each($scope.column[entity], (element, i) => {
        element.isNew = i <= $scope.buffer.length - 1;
      });

      $timeout(() => {
        for (let i = 0; i < $scope.buffer.length; i++) {
          if ($scope.column[entity][i] !== undefined) {
            $scope.column[entity][i].isNew = false;
          }
        }
      }, 3000);
    };

    this.forceRefreshColumn = function forceRefreshColumn() {
      $scope.loading = true;
      ColumnsService.getColumn($scope.column.resource_id).then(
        data => {
          if (data.cases.length > 0) {
            $scope.column.cases = data.cases
            $scope.resetRepeater = {reset: true}
          }
          $scope.loading = false
        },
        data => {
          $scope.loading = false
          Ctrl.handleIssuesWithColumn(data)
        }
      );
    };

    this.requestLastEntities = function (
      asc,
      fixCounter = false
    ): Promise<any> {
      const pivotDate = getDateFromPivotEntity();
      const timeline = asc ? 'older_than' : 'newer_than';
      return ColumnsService.getTickets(
        $scope.column.resource_id,
        pivotDate,
        asc,
        timeline,
        fixCounter
      )
    }

    this.handleIssuesWithColumn = function handleIssuesWithColumn(
      errorResponse
    ) {
      // in some case the response doesn't include the field detail
      // and that breaks the polling. We check if the fields are in the response.
      if ('status' in errorResponse && 'response' in errorResponse) {
        const detail = 'Server Error';
        let responseStatus = 500;

        if (errorResponse.status !== null) {
          responseStatus = errorResponse.status;
        }

        if (responseStatus === 404) {
          deleteColumnInterface();
          stopPolling();
        } else if (responseStatus === 401) {
          console.error('Unauthorized!!')
          // A 401 error should destroy the whole view so the following lines
          // should not be necessary
          //stopPolling();
        } else {
          stopPolling()
          Ctrl.startPolling()
        }
      } else {
        stopPolling()
        Ctrl.startPolling()
      }
    };

    this.isChat = function isChat() {
      return (
        $scope.column.social_networks.some(account => account === 'chat') ||
        $scope.column.social_accounts.some(account => account.sn === 'chat')
      );
    }

    this.addNewContent = function (
      content,
      entity,
      releaseImmediately,
      older = false
    ) {
      let newContent = [];
      if (content.length > 0) {
        if (older) {
          newContent =
            entity === 'cases'
              ? appendOlderCases(content)
              : entity === 'messages'
              ? appendOlderMessages(content)
              : [];
        } else {
          newContent =
            entity === 'cases'
              ? appendNewerCases(content)
              : entity === 'messages'
              ? appendNewerMessages(content)
              : [];
        }
        $scope.buffer = newContent.concat($scope.buffer);
      }
      $scope.bufferLength = $scope.buffer.length;
      $scope.buffer.splice(100);

      Ctrl.updateTopBarNotification();
      if (older) {
        releaseImmediately =
          releaseImmediately || $scope.firstReleaseInvertedColumn;
      }
      if (releaseImmediately) {
        $scope.releaseBuffer();
      } else if (newContent.length > 0) {
        if ($scope.column.autorefresh) {
          $scope.releaseBuffer();
        }
        notifyNewContent();
      }
      return newContent;
    }

    this.getSecondsToRefresh = function getSecondsToRefresh() {
      let speedFactor = 1;
      if (this.isChat()) {
        speedFactor = 6;
      }
      return DEFAULT_REFRESH_SECS / speedFactor;
    };

    this.queryColumn = () => {
      const query = Ctrl.getQuery()
      return ColumnsService.getEntities(query)
    }

    function stopPolling() {
      if ($scope.pollingEnabled) {
        console.debug(`Stopped polling ${$scope.column.resource_id}`)
        if ($scope.polling) {
          $scope.polling.unsubscribe()
        }
        $scope.pollingEnabled = false
      }
    }

    this.startPolling = () => {
      if (!$scope.pollingEnabled) {
        $scope.pollingEnabled = true
        //console.debug(`Started polling ${$scope.column.resource_id}`)
        const pollingObservable = rxjs.defer(Ctrl.queryColumn).pipe(
          AdPollingHelper.pollWhile(
            Ctrl.getSecondsToRefresh() * 1000,
            () => $scope.pollingEnabled
          )
        )
        $scope.polling = pollingObservable.subscribe(
          data => Ctrl.updateColumnWithData(data),
          error => {
            Ctrl.handleIssuesWithColumn(error)
          }
        )
      }
    }

    this.firstUpdate = function (fixCounter): Promise<any> {
      const shouldFixCounter = fixCounter || false
      const asc = $scope.column.asc_items
      $scope.lesserTime = Number.POSITIVE_INFINITY
      $scope.greaterTime = 0

      $scope.firstReleaseInvertedColumn = true
      $scope.loading = true
      stopPolling()
      return Ctrl.requestLastEntities(asc, shouldFixCounter).then(data =>
        Ctrl.updateColumnWithData(data, true, true)
      ).finally(() => {
        Ctrl.startPolling()
        $scope.loading = false
      });
    };

    this.cleanUpColumn = function cleanUpColumn() {
      stopPolling();
      const entityKey = getEntityKey();
      $scope.column[entityKey] = [];
      $scope.buffer = [];
      $scope.bufferLength = 0;
      $scope.nomoredata = false;
      newMessagesAudioNotification = false;
    };

    function getEntityKey() {
      return $scope.column.column_type + 's';
    }

    function deleteColumnInterface() {
      $scope.$emit('deleteColumn', $scope.column);
      $scope.$destroy();
    }

    function removeBufferedEntities(entity) {
      _.each($scope.buffer, (element, i) => {
        const resourceId = element.resource_id;
        _.each($scope.column[entity], (belement, j) => {
          const exists = angular.isDefined(belement);
          if (exists && belement.resource_id === resourceId) {
            $scope.column[entity].splice(j, 1);
          }
        });
      });
    }

    function getDateFromPivotEntity() {
      const entity = getEntityKey();
      const isCase = entity === 'cases';

      const entities =
        $scope.buffer.length > 0 ? $scope.buffer : $scope.column[entity];

      // This is the element that we pick to refresh.
      // We avoid the first so we can refresh the nth elements over this one.
      const pivotPoint = 6;

      if (entities.length > 0) {
        const timeKey = isCase ? $scope.column?.pagination_field : 'created';
        if (entities.length > pivotPoint) {
          return entities[pivotPoint][timeKey];
        }
        return entities[entities.length - 1][timeKey];
      }
    }

    function removeFromBuffer(resourceId) {
      if ($scope.buffer.length > 0) {
        $scope.buffer.forEach((bufferElement, index) => {
          const {resource_id} = bufferElement;
          if (resource_id === resourceId) {
            $scope.buffer.splice(index, 1);
          }
        });
      }
    }

    function appendNewerMessages(content) {
      const newContent = [];
      let greaterTime = angular.copy($scope.greaterTime);
      _.each(content, (element, i) => {
        if (element.created > $scope.greaterTime) {
          if (element.created > greaterTime) {
            greaterTime = element.created;
          }
          newContent.push(element);
          removeFromBuffer(element.resource_id);
        }
      });
      $scope.greaterTime = angular.copy(greaterTime);
      return newContent;
    }

    function appendNewerCases(content) {
      const newContent = [];
      let greaterTime = angular.copy($scope.greaterTime);

      _.each(content, (element, i) => {
        const updated = element.updated_time;
        const {closed} = element;

        if (updated > $scope.greaterTime || closed > $scope.greaterTime) {
          if (updated > greaterTime || closed > greaterTime) {
            greaterTime = updated > closed ? updated : closed;
          }
          newContent.push(element);
          removeFromBuffer(element.resource_id);
        }
      });
      $scope.greaterTime = angular.copy(greaterTime);
      return newContent;
    }

    function appendOlderMessages(content) {
      const newContent = [];
      let lesserTime = angular.copy($scope.lesserTime);
      _.each(content, (element, i) => {
        if (element.created < $scope.lesserTime) {
          if (element.created < lesserTime) {
            lesserTime = element.created;
          }
          newContent.push(element);
          removeFromBuffer(element.resource_id);
        }
      });
      $scope.lesserTime = angular.copy(lesserTime);
      return newContent;
    }

    function appendOlderCases(content) {
      const newContent = [];
      let lesserTime = angular.copy($scope.lesserTime);

      _.each(content, (element, i) => {
        const updated = element.updated_time;
        const {closed} = element;

        if (updated < $scope.lesserTime || closed < $scope.lesserTime) {
          if (updated < lesserTime || closed < lesserTime) {
            lesserTime = updated < closed ? updated : closed;
          }
          newContent.push(element);
          removeFromBuffer(element.resource_id);
        }
      });
      $scope.lesserTime = angular.copy(lesserTime);

      return newContent;
    }

    function notifyNewContent() {
      // The notification will sound/appear only one time in a period of 4 seconds.
      const notificationsTimeout = 4000;

      if (!newMessagesAudioNotification) {
        newMessagesAudioNotification = true;
        $rootScope.$emit('column:audioNotification', {column: $scope.column});
        $timeout(() => {
          newMessagesAudioNotification = false;
        }, notificationsTimeout);
      }

      if (!newMessagesToastNotification) {

        newMessagesToastNotification = true;
        const {toast, name: title} = $scope.column;

        const baseBody = $filter('translate')('COLUMN_NOTIFICATION_MESSAGE');
        const numberOfEvents = '';
        const typeOfEvent = $filter('translate')($scope.column.column_type);
        const plural = 's';
        const englishPlural = 'are';

        const body = formatString(
          baseBody,
          numberOfEvents,
          typeOfEvent,
          plural,
          englishPlural
        );

        $rootScope.$emit('column:toastNotification', {
          title,
          body,
          toast,
          timeout: notificationsTimeout
        });
        $timeout(() => {
          newMessagesToastNotification = false;
        }, notificationsTimeout);
      }
    }

    this.updateTopBarNotification = function updateTopBarNotification() {
      let plural = $scope.bufferLength > 1 ? 's' : '';
      let englishPlural = $scope.bufferLength > 1 ? 'are' : 'is';
      $scope.newCasesText = formatString(
        $filter('translate')('COLUMN_NOTIFICATION_MESSAGE'),
        $scope.bufferLength.toString(),
        $filter('translate')($scope.column.column_type),
        plural,
        englishPlural
      );

      $scope.newNonCasesText = formatString(
        $filter('translate')('COLUMN_NOTIFICATION_MESSAGE_NEW'),
        $scope.bufferLength.toString(),
        $filter('translate')($scope.column.column_type),
        plural,
        englishPlural
      );
    };

    this.checkContentCount = function checkContentCount() {
      if (Ctrl.inconsistentContentCount()) {
        if ($scope.column.retries < maxRetries) {
          $scope.refreshColumn(true)
          $scope.resetRepeater = {reset: true}
          $scope.column.retries += 1
        }
      } else {
        $scope.column.retries = 0
      }
    };

    this.inconsistentContentCount = function (): boolean {
      const contentCount = $scope.column.content_count;
      return (
        contentCount < MAX_ELEMENTS_PER_PAGE &&
        contentCount !== getNumberOfDistinctEntities()
      );
    };

    function getNumberOfDistinctEntities() {
      const ids = new Set();
      const entities = $scope.column[getEntityKey()];
      if (entities.length > 0) {
        angular.forEach(entities, entity => {
          if (entity && angular.isDefined(entity.resource_id)) {
            ids.add(entity.resource_id);
          }
        });
      }
      if ($scope.buffer.length > 0) {
        angular.forEach($scope.buffer, entity => {
          if (entity && angular.isDefined(entity.resource_id)) {
            ids.add(entity.resource_id);
          }
        });
      }

      return ids.size;
    }

    $scope.toDestroy.onColAcOnMsg = $rootScope.$on(
      'column:actionOnMessage',
      (event, data) => {
        const targetMessage = data.element;
        angular.forEach($scope.column.messages, (message, index) => {
          if (message.resource_id === targetMessage.resource_id) {
            message = _.extend(message, targetMessage);
            if (!messageBelongsToColumn(targetMessage, $scope.column)) {
              $scope.column.messages.splice(index, 1);
              $scope.resetRepeater = {reset: true};
            }
          }
        });
      }
    );

    $scope.toDestroy.onUpdCC = $rootScope.$on('updateCaseColumns', () => {
      if ($scope.column.column_type === 'case') {
        $timeout(() => {
          Ctrl.forceRefreshColumn();
        }, 2000);
      }
    });

    $scope.toDestroy.onColAct = $rootScope.$on(
      'column:actionOnCase',
      (event, data) => {
        const {action, ignoreOnColumn: columnId, element: targetCase} = data;
        const {resource_id: currentColumn} = $scope.column;
        const shouldReload = !columnId || currentColumn !== columnId;
        let alreadyRemoved = false;
        if (shouldReload && targetCase) {
          angular.forEach($scope.column.cases, (currentCase, index) => {
            if (currentCase) {
              if (currentCase.resource_id === targetCase.resource_id) {
                const needsUpdate =
                  action === 'updateCaseFromOtherColumn'
                    ? targetCase.updated_time > currentCase.updated_time
                    : targetCase.updated_time >= currentCase.updated_time;

                if (needsUpdate) {
                  currentCase = _.extend(currentCase, targetCase);
                  if (!caseBelongsToColumn(targetCase, $scope.column, $rootScope)) {
                    $scope.column.cases.splice(index, 1);
                    $scope.column.content_count -= 1;
                    if ($scope.column.content_count < 0) {
                      $scope.column.content_count = 0;
                    }
                    $scope.resetRepeater = {reset: true};
                    alreadyRemoved = true;
                  }
                }
              }
            }
          });
          angular.forEach($scope.buffer, (currentCase, index) => {
            if (currentCase) {
              if (currentCase.resource_id === targetCase.resource_id) {
                const needsUpdate =
                  action === 'updateCaseFromOtherColumn'
                    ? targetCase.updated_time > currentCase.updated_time
                    : targetCase.updated_time >= currentCase.updated_time;

                if (needsUpdate) {
                  _.extend(currentCase, targetCase);
                  if (!caseBelongsToColumn(targetCase, $scope.column, $rootScope)) {
                    $scope.buffer.splice(index, 1);
                    if (!alreadyRemoved) {
                      $scope.column.content_count -= 1;
                      if ($scope.column.content_count < 0) {
                        $scope.column.content_count = 0;
                      }
                    }
                    $scope.bufferLength -= 1;
                    if ($scope.bufferLength < 0) {
                      $scope.bufferLength = 0;
                    }
                    Ctrl.updateTopBarNotification();
                    $scope.resetRepeater = {reset: true};
                  }
                }
              }
            }
          });
          Ctrl.checkContentCount();
        }
      }
    );

    $scope.close_tutorial = function () {
      const data = {column_tutorial: false};
      ColumnsService.updateColumns(
        $scope.column.resource_id,
        data,
        'tutorial'
      ).then(result => {
        $scope.column.column_tutorial = result.column_tutorial;
        $scope.toggleAutoRefreshPending = false;
        FireTagService.setEvent({
          name: 'onboarding_process',
          params: {
            detail: 'Entiende tutorial'
          }
        });
      });
    };

    $scope.toDestroy.watch = $rootScope.$watch(
      () => {
        return $scope.bufferLength;
      },
      (newValue, oldValue) => {
        if (oldValue !== newValue) {
          $rootScope.$emit(
            'columnUpdateBufferLength',
            $scope.column.resource_id,
            newValue
          );
        }
      }
    );

    $scope.toDestroy.onColCrmSl = $rootScope.$on('columnCrmSelected', (signal, columnId) => {
      if ($scope.column.resource_id === columnId) {
        $scope.signalColumnSelection = true;
        $timeout(() => {$scope.signalColumnSelection = false}, 500);
      }
    });

    Ctrl.firstUpdate();

    $scope.$on('$destroy', () => {
      Ctrl.cancel = true;
      stopPolling()
      viewDivisionSub.unsubscribe()
      for (const [key, destroyMethod] of Object.entries($scope.toDestroy)) {
          if (destroyMethod) {
              destroyMethod();
          }
      }
    });
  }
})(angular.module('postCenterWebClientApp'));
