'use strict';

app.directive('caseMessages', function() {
  return {
    templateUrl: 'blocks/case/views/case_messages.html',
    restrict: 'E',
    scope: {},
    bindToController: {
      caseObject: '=',
      userObject: '='
    },
    controller: 'caseMessagesCtrl',
    controllerAs: 'ctrl',
    require: '^^caseDetail',
    link: function(scope, elem, attrs, caseDetailCtrl) {
      scope.ctrl.caseDetailCtrl = caseDetailCtrl;
    }
  };
});
