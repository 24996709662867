const SupportLink = Vue.component('SupportLink', {
  props: {
    supportPath: {
      type: String,
      default: null,
      required: false
    },
    customText: {
      type: String,
      default: null,
      required: false
    }
  },
  data() {
    return {
      supportUrl: 'https://help.adere.so/'
    };
  },
  computed: {
    getHelpUrl() {
      if (this.supportPath && this.supportPath.startsWith('http')) {
        return this.supportPath;
      }
      return this.supportPath
        ? `${this.supportUrl}${this.supportPath}`
        : this.supportUrl;
    }
  },
  template: `<a
    :href="getHelpUrl"
    target="_blank"
    class="btn ads-button ads-button--primary section-header--help">
    <span class="fa fa-question-circle mr-1" />
    {{ customText? customText : $t('linkLabel') }}
  </a>`,
  i18n: {
    messages: {
      es: {
        linkLabel: 'Mira la ayuda para esta sección'
      },
      en: {
        linkLabel: 'Check the help for this section'
      },
      pt: {
        linkLabel: 'Veja a ajuda para esta seção'
      }
    }
  }
});
