'use strict';

type LoginState = 'onboarding' | 'crm.inbox'

function getFirstState(establishment): LoginState {
  if (!establishment.is_paying && isTrialNearExpiration(establishment)) {
    return 'crm.inbox';
  }

  const goToOnboarding =
    isPendingConnection(establishment.onboarding_networks) &&
    partnerHasOnboardingActive(establishment.config);
  if (goToOnboarding) {
    return 'onboarding';
  }
  return 'crm.inbox';
}

class AuthService {
  AdRequest: AdRequestService;
  EstablishmentService: EstablishmentService;
  VersionService: VersionService;
  token?: string;
  $state;
  $rootScope;

  constructor(
    AdRequest: AdRequestService,
    EstablishmentService: EstablishmentService,
    VersionService: VersionService,
    $state,
    $rootScope
  ) {
    this.AdRequest = AdRequest;
    this.EstablishmentService = EstablishmentService;
    this.VersionService = VersionService;
    this.$state = $state;
    this.$rootScope = $rootScope;

    AdRequest.loggedIn.subscribe(() => {
      this.$rootScope.authActive = true;
      if (this.shouldRedirectLoggedIn()) {
        this.redirectToFirstLoggedState();
      }
    });

    AdRequest.loggedOut.subscribe(() => {
      this.$rootScope.authActive = false;
      if (this.shouldRedirectToLogin()) {
        this.$state.go(AppState.LOGIN_STATE);
      }
    });
  }

  private redirectToFirstLoggedState() {
    return this.EstablishmentService.getEstablishment().then(establishment => {
      const firstState = getFirstState(establishment);
      return this.$state.go(firstState);
    });
  }

  private shouldRedirectToLogin(): boolean {
    const dontRedirect = [
      'login',
      'forgotten_password',
      'forgotten_password_token',
      'signup'
    ];
    const {name: currentState} = this.$state.$current;
    return !dontRedirect.includes(currentState);
  }

  private shouldRedirectLoggedIn(): boolean {
    const redirectIfLoggedIn = ['login', 'verify_email'];
    const {name: currentState} = this.$state.$current;
    return redirectIfLoggedIn.includes(currentState);
  }

  public login(username, password, captchaToken): Promise<string> {
    return this.AdRequest.login(username, password, captchaToken);
  }

  public loginWithToken(token: string): Promise<string> {
    return this.AdRequest.setToken(token).then(() => {
      return this.VersionService.getVersion()
        .then(version => {
          return this.AdRequest.post(
            '/api/v1/user/active/',
            {version: version},
            {headers: {Authorization: `Token ${token}`}}
          );
        })
        .then(() => token);
    });
  }

  public logout() {
    this.AdRequest.logout();
  }

  checkForTokenInUrl() {
    return this.checkForQueryParam('token');
  }

  public checkForQueryParam(name: string) {
    const location = window.location.href.replace(/\/#\//, '/');
    const url = new URL(location);
    return url.searchParams.get(name);
  }

  public urlTokenSetup() {
    let token = this.checkForTokenInUrl();
    if (token) {
      this.AdRequest.setToken(token).then(()=>{
        window.location = window.location.pathname
      })
    };
  }

  public async isAuthed(): Promise<boolean> {
    return this.AdRequest.getStoredSessionToken().then(token => {
      if (token) {
        return true
      }
      return false;
    })
  }

  firebaseLogin(username: string, token: string) {
    return this.AdRequest.post<{token: string}>('/api/v1/firebase/login/', {
      username,
      token
    },
    {},
    0,
    true
    ).then(
      (resp) => {
        let token = resp.token
        return this.AdRequest.setToken(token)
      }
    );
  }

  activeDirectoryLogin(token) {
    return this.AdRequest.post('/api/v1/active_directory/login/', {token});
  }

  sendForgottenPasswordEmail(email, response_user) {
    return this.AdRequest.post('/api/v1/forgotten_password/', {
      email,
      response_user
    },
    {},
    0,
    true
    );
  }

  verifyEmail(user, verificationToken, doLogin) {
    let path = `/api/v1/user/${user}/verify/${verificationToken}/`;

    if (doLogin) {
      path += `?do_login=1`;
    }
    return this.AdRequest.post(path, {});
  }

  resendVerificationEmail(userId) {
    return this.AdRequest.post(
      `/api/v1/user/${userId}/resend_verification_email/`
    );
  }

  verifyUrl(token) {
    return this.AdRequest.get(`/api/v1/forgotten_password/${token}/`);
  }

  changePassword(password, verify_password, token, state) {
    return this.AdRequest.post(`/api/v1/forgotten_password/${token}/`, {
      password,
      verify_password,
      state
    });
  }
}
angular.module('postCenterWebClientApp').service('AuthService', AuthService);
