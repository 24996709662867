'use strict';

var app = angular.module('postCenterWebClientApp');
app.service('AdDataConnector', function DataConnector(

) {
  this.get_social_account_id_from_uid = function (uid, social_accounts) {
    for(var i=0; i<social_accounts.length; i++) {
      if(social_accounts[i].uid === uid) {
        return social_accounts[i].id;
      }
    }
    return false;
  };

  this.get_assignation_mode = function (assignations, account) {
    for(var i=0; i<assignations.length; i++) {
      var assignation = assignations[i];
      if(assignation.id === account) {
        return assignation.mode;
      }
    }
    return 0;
  };
});
