const EpaRankingChart = Vue.component('EpaRankingChart', {
  props: {
    chart: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    getChartData() {
      return this.chart;
    }
  },
  template: `<div class="d-flex mt-1">
    <AderesoChartBar
      v-if="chart" 
      :chart-data="getChartData"
      :height="300" />
  </div>`
});
