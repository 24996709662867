const KpiSelector = Vue.component('KpiSelector', {
  props: {
    toggle: Boolean
  },
  data() {
    return {
      name: '',
      toggleList: false,
      isChecked: true
    };
  },
  computed: {
    ...Vuex.mapGetters(['getKpiTitle', 'getTabs', 'activeGroup']),
    kpiByName() {
      const list = this.getTabs[this.activeGroup] || [];
      const kpiList = list.filter(
        ({visualization_type}) => visualization_type !== 'counter'
      );
      return kpiList.filter(kpi => new RegExp(this.name, 'ig').test(kpi.label));
    },
    isActive() {
      return this.toggleList ? 'active' : '';
    }
  },
  methods: {
    ...Vuex.mapActions(['setFilters']),
    toggleKPI() {
      this.checkList();
    },
    checkList() {
      const disabled = this.kpiByName.find(kpi => !kpi.enabled);
      this.isChecked = disabled ? false : true;
    },
    getTitleIdentifier(identifier) {
      const translate = this.getKpiTitle[identifier];
      return this.$t(translate);
    },
    toggleSelector(event) {
      if (
        event.target.id !== 'kpi-search-input' &&
        event.target.id !== 'kpi-checkbox'
      ) {
        this.toggleList = !this.toggleList;
      }
    },
    closeSelector() {
      this.toggleList = false;
    },
    allSelector() {
      this.kpiByName.map(kpi => {
        kpi.enabled = !this.isChecked;
      });
    }
  },
  template: `
    <div class="ads-selector-wrapper">
      <div
        class="ads-selector"
        @click="toggleSelector"
        v-click-outside="closeSelector"
      >
        <div class="ads-selector__header d-flex" :class="isActive">
          <h5 class="bold d-inline">{{ $t('all_kpi') }}</h5>
          <span class="fa fa-chevron-down" />
        </div>
        <div v-show="toggleList">
          <label>
            <input
              type="checkbox"
              key="kpi-header"
              v-model="isChecked"
              @click="allSelector"
            />
            <span class="mx-1">{{ $t('all_kpi') }}</span>
          </label>
          <div class="p-1">
            <input
              type="text"
              id="kpi-search-input"
              class="form-control mb-1"
              :placeholder="$t('inputs.kpi_finder')"
              v-model="name"
            />
          </div>
          <p class="p-1" v-if="!kpiByName.length">
            {{ $t('issues.not_found_kpi') }}
          </p>
          <ul v-else class="list-unstyled">
            <li v-for="kpi in kpiByName" :key="kpi.index">
              <label>
                <input
                  type="checkbox"
                  @change="toggleKPI"
                  :name="kpi.identifier"
                  v-model="kpi.enabled"
                />
                <span class="mx-1">{{ kpi.label }}</span>
              </label>
            </li>
          </ul>
        </div>
      </div>
    </div>
  `
});
