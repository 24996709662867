const ConfirmModal = Vue.component('ConfirmModal', {
  props: {
    open: {type: Boolean, required: true},
    onConfirm: {
      type: Function,
      required: true
    },
    onCancel: {
      type: Function,
      required: false,
      default: null
    },
    labels: {
      type: Object,
      default: null
    },
    title: {
      type: String,
      default: 'Confirm modal'
    },
    description: {
      type: String,
      default: null
    }
  },
  i18n: {
    messages: {
      es: {
        confirm: 'Aceptar',
        cancel: 'Cancelar'
      },
      pt: {
        confirm: 'Aceitar',
        cancel: 'Cancelar'
      },
      en: {
        confirm: 'Confirm',
        cancel: 'Cancel'
      }
    }
  },
  computed: {
    setLabels() {
      return {
        confirm: this.$t(this.labels ? this.labels.confirm : 'confirm'),
        cancel: this.$t(this.labels ? this.labels.cancel : 'cancel')
      };
    }
  },
  template: `
    <div v-if="open"
         class="position-fixed modal-dialog--container d-flex justify-center align-center">
      <aside class="modal-dialog">
        <header class="modal-header">
          <h5>{{ title }}</h5>
        </header>
        <div class="modal-body" v-if="description">
          <slot>
            <p v-html="description"></p>
          </slot>
        </div>
        <footer class="modal-footer">
          <button
            type="button"
            v-if="onCancel"
            @click="onCancel()"
            class="btn ads-button ads-button--danger">
            {{ $t(setLabels.cancel) }}
          </button>
          <button
            type="button"
            @click="onConfirm()"
            class="btn ads-button ads-button--primary">
            {{ $t(setLabels.confirm) }}
          </button>
        </footer>
      </aside>
    </div>
  `
});
