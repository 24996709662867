const SidebarEditor = Vue.component('SidebarEditor', {
  props: {
    title: {
      type: String,
      default: '',
      required: true
    },
    onSave: {
      type: Function,
      default: () => {}
    },
    saveDisabled: {
      type: Boolean,
      default: false
    },
    onCancel: {
      type: Function,
      default: () => {}
    }
  },
  computed: {
    disabled() {
      return this.saveDisabled ? 'ads-button--disabled' : '';
    }
  },
  i18n: {
    messages: {
      es: {
        save: 'Guardar',
        cancel: 'Cancelar'
      },
      en: {
        save: 'Save',
        cancel: 'Cancel'
      },
      pt: {
        save: 'Salvar',
        cancel: 'Cancelar'
      }
    }
  },
  template: `<aside class="ads-sidebar-editor">
    <header class="ads-sidebar-editor--header">
      <h4>{{ title }}</h4>
    </header>
    <slot class="ads-sidebar-editor--content" />
    <footer class="ads-sidebar-editor--footer">
      <button
        @click="onSave"
        :disabled="saveDisabled"
        :class="disabled"
        class="btn ads-button ads-button--primary">
          {{ $t('save') }}
      </button>
      <button
        @click="onCancel"
        class="btn ads-button ads-button--danger">
        {{ $t('cancel') }}
      </button>
    </footer>
  </aside>`
});
