const SimpleAvatar = Vue.component('SimpleAvatar', {
  props: {
    size: {
      type: [Number, String],
      default: 22
    },
    src: {
      type: [String],
      default: ''
    },
    alt: {
      type: String,
      default: ''
    }
  },
  computed: {
    getSize() {
      return this.size ? this.size : '22px';
    },
    image() {
      return this.src
        ? this.src
        : 'https://assets.postcenter.io/img/generic_avatar.png';
    }
  },
  methods: {
    imageError(event) {
      event.target.src = 'https://assets.postcenter.io/img/generic_avatar.png';
    }
  },
  template: `<img class="ads-circle"
    :src="image" :alt="alt" :width="getSize" :height="getSize" @error="imageError" />`
});
const Avatar = Vue.component('Avatar', {
  props: {
    channel: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    src: {
      type: String,
      default: null
    },
    isInfluencer: {type: Boolean},
    size: {
      type: String,
      default: 'medium',
      validator: size => ['small', 'medium', 'big'].includes(size)
    },
    centered: {
      type: Boolean,
      default: true
    },
    bordered: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      channels: [
        {
          name: 'facebook',
          color: '#3b5998',
          icon: 'fa-facebook',
          title: 'Facebook'
        },
        {
          name: 'twitter',
          color: '#55acee',
          icon: 'fa-twitter',
          title: 'Twitter'
        },
        {
          name: 'twitter_search',
          color: '#55acee',
          icon: 'fa-twitter',
          title: 'Twitter Search'
        },
        {name: 'email', color: '#f57d00', icon: 'fa-envelope', title: 'Email'},
        {
          name: 'whatsapp',
          color: '#4dc247',
          icon: 'fa-whatsapp',
          title: 'Whatsapp'
        },
        {
          name: 'instagram',
          color: '#000000',
          icon: 'fa-instagram',
          title: 'Instagram'
        },
        {name: 'chat', color: '#03a9f4', icon: 'fa-postcenter', title: 'Chat'},
        {
          name: 'app_store',
          color: '#b0bec5',
          icon: 'fa-apple',
          title: 'App Store'
        },
        {
          name: 'play_store',
          color: '#dd4b39',
          icon: 'fa-google-play',
          title: 'Play Store'
        },
        {
          name: 'external',
          color: '#1eb045',
          icon: 'fa-external-account',
          title: 'Webhook'
        }
      ]
    };
  },
  computed: {
    classes() {
      const defaultClass = 'ad-avatar__avatar position-relative';
      const sizeClass = `ad-avatar--size-${this.size}`;
      const isCentered = this.centered ? 'd-block mx-auto' : '';
      const isBordered = this.bordered ? 'ad-avatar--border' : '';
      const hasChannelIcon = this.channel ? 'ad-avatar__with-channel' : '';
      return [defaultClass, sizeClass, isCentered, isBordered, hasChannelIcon];
    },
    getIcon() {
      if (!this.channel) {
        return '';
      }
      return this.getChannelType('icon');
    },
    color() {
      if (!this.channel) {
        return '';
      }
      return `color:${this.getChannelType('color')}`;
    },
    avatarSrc() {
      // Required to resolve any type-checking issues with props
      return this.src || 'https://assets.postcenter.io/img/generic_avatar.png';
    }
  },
  methods: {
    getChannelType(type) {
      const sn = this.channel.toLowerCase().replace(/ /g, '_');
      const {[type]: snType} = this.channels.find(({name}) => name === sn);
      return snType;
    },
    imageError(event) {
      event.target.src = 'https://assets.postcenter.io/img/generic_avatar.png';
    }
  },
  template: `
    <span :title="title" :class="classes" :style="color">
      <img :src="src" class="ad-avatar--image img-circle" @error="imageError"/>
      <span v-if="channel"
        class="ad-avatar--chanel text-center fa"
        :class="getIcon" />
      <span
        class="ad-avatar--star fa fa-star"
        v-if="isInfluencer" />
    </span>
  `
});

