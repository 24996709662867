/* global i18nEpaAnalytics */
(module => {
  'use strict';
  EpaAnalyticsCtrl.$inject = [
    '$rootScope',
    'FireTagService',
    'EpaService',
    'AuthService'
  ];
  module.controller('EpaAnalyticsCtrl', EpaAnalyticsCtrl);
  function EpaAnalyticsCtrl(
    $rootScope,
    FireTagService,
    EpaService,
    AuthService
  ) {
    const vm = this;
    vm.$onInit = () => {
      vm.loaded = true;
      FireTagService.setPageView({
        title: 'EPA analítica',
        path: `/${window.location.hash}`
      });
      $rootScope.analyticTab.selected = 'satisfaction';

      store.EpaService = EpaService;
      store.AuthService = AuthService;
      vm.createVueInstance();
    };
    vm.createVueInstance = () => {
      vm.vueInstance = new Vue({
        el: document.getElementById('epa-analytics-screen'),
        i18n: i18nEpaAnalytics,
        store,
        data: {
          ready: false
        },
        computed: {
          ...Vuex.mapGetters('epaAnalytics', ['departmentsSelector', 'filters', 'dataReady'])
        },
        created() {
          this.getSurveys();
          this.getAccounts();
          this.getEstablishment();
        },
        mounted() {
          Promise.all([this.getUser(), this.getUsers(), this.getDepartments()]).then(resp => {
            const [user] = resp;
            const {
              profile: {language}
            } = user;
            i18nEpaAnalytics.locale = language;
            this.updateUser(user)
            const userDepartments = JSON.parse(JSON.stringify(this.departmentsSelector))
            if (userDepartments.length > 0){
              this.filters.departments = [userDepartments[0].id]
              this.setFilters(this.filters)

            }
            this.getResumeData();
            this.ready = true;
          });
        },
        methods: {
          ...Vuex.mapMutations('epaAnalytics', {
            mutation: 'MUTATE'
          }),
          ...Vuex.mapActions('agents', ['getUsers']),
          ...Vuex.mapActions('user', ['getUser']),
          ...Vuex.mapActions('establishment', ['getEstablishment']),
          ...Vuex.mapActions('socialAccounts', ['getAccounts']),
          ...Vuex.mapActions(
            'epaAnalytics',
            ['getResumeData', 'getSurveys', 'updateUser', 'getDepartments', 'setFilters']
          )
        }
      });
    };
  }
})(angular.module('postCenterWebClientApp'));
