'use strict';

var app = angular.module('postCenterWebClientApp');
app.directive(
  'messageControlMoreOptions',
  function (
    $rootScope,
    $timeout,
    Message,
    AdNotification,
    $uibModal,
    AdConfirmModal,
    $filter
  ) {
    return {
      require: '^messageControls',
      templateUrl: 'blocks/message/views/message_control_more_options.html',
      restrict: 'EA',
      replace: 'true',
      link: function ($scope, $element, $attrs, $messageControlsCtrl) {
        var translate = $filter('translate');
        $scope.messageMoreOptions = {
          state: {
            active: false,
            modal: false
          },
          menu: {}
        };

        $scope.messageMoreOptions.toggleMenu = function (event) {
          function toggle() {
            $scope.messageMoreOptions.state.active =
              !$scope.messageMoreOptions.state.active;
          }

          if ($scope.messageMoreOptions.state.active) {
            $timeout(function () {
              toggle();
            }, 100);
          } else {
            toggle();
          }
        };

        function messageAction(message, state, action) {
          var actionMethod;
          var actionConfirm;
          var actionMsgCode;
          var actionConfirmButtonText;

          if (action === 'delete') {
            actionMethod = Message.delete;
            actionConfirm = translate('MESSAGE_MORE_OPTIONS_DELETE_CONFIRM');
            actionMsgCode = 'delete_message';
            actionConfirmButtonText = translate('MESSAGE_MORE_OPTIONS_DELETE');
          } else if (action === 'hide') {
            actionMethod = Message.hide;
            actionConfirm = translate('MESSAGE_MORE_OPTIONS_HIDE_CONFIRM');
            actionMsgCode = 'hide_message';
            actionConfirmButtonText = translate('MESSAGE_MORE_OPTIONS_HIDE');
          } else if (action === 'unhide') {
            actionMethod = Message.unhide;
            actionConfirm = translate('MESSAGE_MORE_OPTIONS_UNHIDE_CONFIRM');
            actionMsgCode = 'unhide_message';
            actionConfirmButtonText = translate('MESSAGE_MORE_OPTIONS_UNHIDE');
          }

          function doAction() {
            if (!state.pendingRequest) {
              state.pendingRequest = true;
              Message[action](message['resource_id'])
                .then(function (response) {
                  if (action === 'delete') {
                    message.active = false;
                  }

                  if (action === 'hide') {
                    message.hidden = true;
                  }

                  if (action === 'unhide') {
                    message.hidden = false;
                  }

                  var emitData = {action: action, element: message};
                  $rootScope.$emit('column:actionOnMessage', emitData);
                  AdNotification.success(201, actionMsgCode);
                })
                .catch(function (exception) {
                  AdNotification.error(exception, actionMsgCode);
                })
                .finally(function () {
                  state.pendingRequest = false;
                });
            }
          }

          if (!state.pendingRequest) {
            AdConfirmModal({
              message: actionConfirm,
              confirmButtonText: actionConfirmButtonText,
              confirmButtonClasses: ['btn-danger'],
              confirmCallback: doAction
            });
          }
        }

        function messageToggleCaseCreation(message, create) {
          var messageId = message['resource_id'];
          Message.edit(messageId, {no_cs_on_answer: create}).then(function (
            response
          ) {
            message['no_cs_on_answer'] = create;
            $scope.messageMoreOptions.toggleMenu();
          });
        }

        function makeModalAction(action, state) {
          $scope.messageMoreOptions.state.modal = true;

          $uibModal.open({
            templateUrl: 'blocks/message/views/message_action_modal.html',
            controller: 'ActionsModalMessageCtrl',
            resolve: {
              messageData: function () {
                return $scope.message;
              },
              action: function () {
                return action;
              }
            },
            windowClass: 'modal'
          });
        }

        function generateFacebookMenu() {
          function getFacebookPermalink(message) {
            /*
            Facebook user ID. When it's original or shared post,
            look for message.author.id,
            when it's a comment, look into message.recipient_id
            */
            var recipientUid = message.recipient_id || message.author.uid;
            var threadRoot = message.thread_root
              ? message.thread_root.split('_')
              : undefined;

            /*
              there is a case not investigated (maybe backend bug)
              when message.thread_root is defined,
              and message.recipient_id differs from message.thread_root's first param,
              we need to set recipientUid to message.thread_roots's first param
            */
            if (threadRoot && threadRoot[0] != recipientUid) {
              recipientUid = threadRoot[0];
            }

            const mid = (function () {
              if (
                message.extra_data &&
                message.extra_data.hasOwnProperty('sn_mid')
              )
                return message.extra_data.sn_mid;
              return message.mid;
            })();

            //when post, it's FB ID, when comment it's STORY ID
            let [partOne, partTwo] = mid.split('_');
            //when post, it's STORY ID, when comment it's COMMENT ID

            var fbPermalinkTemplate =
              'https://www.facebook.com/<fb_id>/posts/<story_id>';
            var fbPermalinkTemplateWithComment =
              fbPermalinkTemplate + '?comment_id=<comment_id>';
            var fbPermalink = null;

            if (partOne === recipientUid) {
              //it's a post link
              fbPermalink = fbPermalinkTemplate
                .replace('<fb_id>', recipientUid)
                .replace('<story_id>', partTwo);
            } else {
              //it's a comment link
              fbPermalink = fbPermalinkTemplateWithComment
                .replace('<fb_id>', recipientUid)
                .replace('<story_id>', partOne)
                .replace('<comment_id>', partTwo);
            }

            return fbPermalink;
          }

          $scope.messageMoreOptions.menu.facebook = [
            {
              label: 'MESSAGE_MORE_OPTIONS_CASE_CREATION_ENABLE',
              link: null,
              visible: function () {
                var message = $scope.message;
                return (
                  message.kind !== 'private' &&
                  message.no_cs_on_answer &&
                  message.published === true &&
                  $scope.canInteract
                );
              },
              action: function () {
                messageToggleCaseCreation($scope.message, false);
              }
            },
            {
              label: 'MESSAGE_MORE_OPTIONS_CASE_CREATION_DISABLE',
              link: null,
              visible: function () {
                var message = $scope.message;
                return (
                  message.kind !== 'private' &&
                  !message.no_cs_on_answer &&
                  message.published === true &&
                  $scope.canInteract
                );
              },
              action: function () {
                messageToggleCaseCreation($scope.message, true);
              }
            },
            {
              label: 'MESSAGE_MORE_OPTIONS_VIEW_ON_FACEBOOK',
              link: null,
              visible: function () {
                var message = $scope.message;
                const STATUS_DEFAULT = 0;
                const has_been_sent = message.status > STATUS_DEFAULT;
                return (
                  (!message.ours || has_been_sent) && message.kind !== 'private'
                );
              },
              action: function () {
                var post_url = getFacebookPermalink($scope.message);
                fetch('templates/facebook_thread_root/facebook_thread_root_post.html').then(
                  (data) => {
                      data.text().then(body => {
                          const result = body.replace('{{post_url}}', post_url);
                          const newWin = window.open();
                          newWin.document.write(result);
                          newWin.document.close();
                      })
                  }
                );
                $scope.messageMoreOptions.toggleMenu();
              }
            },
            {
              label: 'MESSAGE_MORE_OPTIONS_DELETE',
              link: null,
              state: {
                pendingRequest: false
              },
              visible: function () {
                var message = $scope.message;
                const STATUS_DEFAULT = 0;
                const has_been_sent = message.status > STATUS_DEFAULT;
                return (
                  has_been_sent &&
                  message.kind !== 'private' &&
                  $scope.canInteract
                );
              },
              action: function () {
                messageAction($scope.message, this.state, 'delete');
              }
            },
            {
              label: 'MESSAGE_MORE_OPTIONS_HIDE',
              link: null,
              state: {
                pendingRequest: false
              },
              visible: function () {
                var message = $scope.message;
                return (
                  message.kind !== 'private' &&
                  !message.hidden &&
                  (message.level === 0 || !message.ours) &&
                  $scope.canInteract
                );
              },
              action: function () {
                messageAction($scope.message, this.state, 'hide');
              },
              loading: true
            },
            {
              label: 'MESSAGE_MORE_OPTIONS_UNHIDE',
              link: null,
              state: {
                pendingRequest: false
              },
              visible: function () {
                var message = $scope.message;
                return (
                  message.kind !== 'private' &&
                  message.hidden &&
                  (message.level === 0 || !message.ours) &&
                  $scope.canInteract
                );
              },
              action: function () {
                messageAction($scope.message, this.state, 'unhide');
              },
              loading: true
            },
            {
              label: 'MESSAGE_MORE_OPTIONS_LIKE_ON_FACEBOOK',
              link: null,
              visible: function () {
                var message = $scope.message;
                return (
                  !message.ours &&
                  message.kind !== 'private' &&
                  $scope.canInteract
                );
              },
              action: function () {
                makeModalAction('like');
                $scope.messageMoreOptions.toggleMenu();
              }
            }
          ];
        }

        function generateTwitterMenu() {
          $scope.messageMoreOptions.menu.twitter = [
            {
              label: 'MESSAGE_MORE_OPTIONS_CASE_CREATION_ENABLE',
              link: null,
              visible: function () {
                var message = $scope.message;
                return (
                  message.kind !== 'private' &&
                  message.no_cs_on_answer &&
                  message.published === true &&
                  $scope.canInteract
                );
              },
              action: function () {
                messageToggleCaseCreation($scope.message, false);
              }
            },
            {
              label: 'MESSAGE_MORE_OPTIONS_CASE_CREATION_DISABLE',
              link: null,
              visible: function () {
                var message = $scope.message;
                return (
                  message.kind !== 'private' &&
                  !message.no_cs_on_answer &&
                  message.published === true &&
                  $scope.canInteract
                );
              },
              action: function () {
                messageToggleCaseCreation($scope.message, true);
              }
            },
            {
              label: 'MESSAGE_MORE_OPTIONS_VIEW_ON_TWITTER',
              link:
                'https://www.twitter.com/' +
                $scope.message.author.uname +
                '/status/' +
                $scope.message.mid,
              visible: function () {
                var message = $scope.message;
                return message.kind != 'private';
              },
              action: function () {
                window.open(this.link, '_blank');
                $scope.messageMoreOptions.toggleMenu();
              }
            },
            {
              label: 'MESSAGE_MORE_OPTIONS_VIEW_STATS_ON_TWITTER',
              link:
                'https://analytics.twitter.com/user/' +
                $scope.message.author.uname +
                '/tweet/' +
                $scope.message.mid,
              visible: function () {
                var message = $scope.message;
                return (
                  message.kind !== 'private' &&
                  message.published === true &&
                  message.sn === 'twitter' &&
                  $scope.canViewStats
                );
              },
              action: function () {
                window.open(this.link, '_blank');
                $scope.messageMoreOptions.toggleMenu();
              }
            },
            {
              label: 'MESSAGE_MORE_OPTIONS_DELETE',
              link: null,
              state: {
                pendingRequest: false
              },
              visible: function () {
                var message = $scope.message;
                return (
                  message.kind !== 'private' &&
                  message.published === true &&
                  $scope.canInteract
                );
              },
              action: function () {
                messageAction($scope.message, this.state, 'delete');
              }
            },
            {
              label: 'MESSAGE_MORE_OPTIONS_SHARE_ON_TWITTER',
              link: null,
              visible: function () {
                var message = $scope.message;
                return message.kind !== 'private' && $scope.canInteract;
              },
              action: function () {
                makeModalAction('share');
                $scope.messageMoreOptions.toggleMenu();
              }
            },
            {
              label: 'MESSAGE_MORE_OPTIONS_LIKE_ON_TWITTER',
              link: null,
              visible: function () {
                var message = $scope.message;
                return message.kind != 'private' && $scope.canInteract;
              },
              action: function () {
                makeModalAction('like');
                $scope.messageMoreOptions.toggleMenu();
              }
            }
          ];
        }

        function generateInstagramMenu() {
          $scope.messageMoreOptions.menu.instagram = [
            {
              label: 'MESSAGE_MORE_OPTIONS_VIEW_ON_INSTAGRAM',
              link: null,
              visible: function () {
                var message = $scope.message;
                return message.kind !== 'private';
              },
              action: function () {
                var message = $scope.message;
                var url = message.url || 'https://www.instagram.com';
                window.open(url, '_blank');
                $scope.messageMoreOptions.toggleMenu();
              }
            }
          ];
        }

        function init() {
          generateTwitterMenu();
          generateFacebookMenu();
          generateInstagramMenu();
        }

        init();
      }
    };
  }
);
