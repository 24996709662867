class SignupService {
  constructor(AdRequest) {
    this.AdRequest = AdRequest;
  }
  createAccount(data) {
    return this.AdRequest.post('/api/v1/signup/', data);
  }
  checkEmail(email) {
    return this.AdRequest.post('/api/v1/signup/user/check/', {email});
  }
  checkName(name) {
    return this.AdRequest.post('/api/v1/signup/establishment/check/', {name});
  }
  getPlansInfo(locale = 'es') {
    return this.AdRequest.get(`/api/v1/plans_info?locale=${locale}`);
  }
  async getSteps() {
    return this._getWizards('./wizards/signup/steps.json');
  }
  getPrefix() {
    return this._getWizards('./wizards/signup/prefix-numbers.json');
  }
  async _getWizards(url) {
    const response = await fetch(url);
    const waitResp = await response.json();
    return waitResp;
  }
}
angular
  .module('postCenterWebClientApp')
  .service('SignupService', SignupService);
