(module => {
  'use strict';

  module.controller('EmailVerificationCtrl', EmailVerificationCtrl);
  function EmailVerificationCtrl(
    AdRequest,
    $transition$,
    $state,
    AuthService,
    AppStateService: AppStateService,
    AdNotification,
    FireTagService,
    StylesService,
    $scope
  ) {
    FireTagService.setPageView({
      title: 'Verificación de email',
      path: `/${window.location.hash}`
    });
    const {userId} = $transition$.params();
    const {verificationToken} = $transition$.params();

    const registerCookie = token => {
      AdRequest.setToken(token);
    };

    StylesService.getStyles().then(styles => {
      $scope.styles = styles;
    });

    if (userId && verificationToken) {
      const doLogin = !AdRequest.getToken();
      AuthService.verifyEmail(userId, verificationToken, doLogin).then(
        ({token}) => {
          if (token) {
            registerCookie(token);
          } else {
            AppStateService.goToInbox();
          }
          AdNotification.success(201, 'email_verification');
        },
        () => {
          // If verification fails.
          $state.go('login');
          AdNotification.error(400, 'email_verification');
        }
      );
    } else {
      $state.go('login');
    }
  }
})(angular.module('postCenterWebClientApp'));
