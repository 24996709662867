class CloseTicketsService {
  constructor(AdRequest) {
    this.AdRequest = AdRequest;
  }
  closeTicketList(data) {
    return this.AdRequest.post('/api/v1/massive_actions/close/list/', data);
  }
  closeTicketFilters(data) {
    return this.AdRequest.post('/api/v1/massive_actions/close/filters/', data);
  }
  closeTicketUnassigned(data) {
    return this.AdRequest.post('/api/v1/massive_actions/close/unassigned/', data);
  }
  getCloseTicketsHistory(page = 0) {
    return this.AdRequest.get(`/api/v1/massive_actions/close/history/${page}/`);
  }
}
angular
  .module('postCenterWebClientApp')
  .service('CloseTicketsService', CloseTicketsService);
