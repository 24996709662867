'use strict';

angular
  .module('postCenterWebClientApp')
  .directive('socialAccountAvatar', function () {
    return {
      scope: {
        socialAccount: '=',
        placeholder: '@',
        avatarType: '<' // 'account' (default) OR 'executive'. Defines the endpoint to upload to
      },
      templateUrl:
        'blocks/admin/views/social_accounts/social_account_avatar.html',
      restrict: 'E',
      controller: [
        '$scope',
        '$timeout',
        '$filter',
        'SocialAccountService',
        'AdNotification',
        'AdUtils',
        'UserService',
        function (
          $scope,
          $timeout,
          $filter,
          SocialAccountService,
          AdNotification,
          AdUtils,
          UserService
        ) {
          const action = 'update_avatar';
          const avatarType = $scope.avatarType || 'account';

          $scope.validateImage = function (fileData, successFn, errorFn) {
            var MAX_IMAGE_SIZE = 400;
            var MIN_IMAGE_SIZE = 180;
            AdUtils.loadImage(fileData)
              .then(function (image) {
                var msg = '';
                var isValid = true;
                if (image.width != image.height) {
                  msg = $filter('translate')(
                    'SOCIALACCOUNTS_AVATAR_NOT_SQUARE'
                  );
                  isValid = false;
                } else if (image.width > MAX_IMAGE_SIZE) {
                  msg = $filter('translate')('SOCIALACCOUNTS_AVATAR_TOO_BIG');
                  isValid = false;
                } else if (image.width < MIN_IMAGE_SIZE) {
                  msg = $filter('translate')('SOCIALACCOUNTS_AVATAR_TOO_SMALL');
                  isValid = false;
                }
                if (isValid) {
                  $timeout(function () {
                    $scope.socialAccount.avatar = image.src;
                  });
                  successFn(fileData);
                } else {
                  errorFn();
                  AdNotification.notify(msg, 'error');
                }
              })
              .catch(function (exception) {
                errorFn();
              });
          };

          $scope.successFn = (url = '') => {
            const response = () => {
              $scope.socialAccount.avatar = url;
              AdNotification.success(200, action);
            };
            const onError = () => AdNotification.error(500, action);
            if (avatarType === 'account') {
              SocialAccountService.updateAvatar(url, $scope.socialAccount)
                .then(response)
                .catch(onError);
            } else {
              UserService.updateAvatar(url, $scope.socialAccount)
                .then(response)
                .catch(onError);
            }
          };

          $scope.errorFn = function (exception) {
            AdNotification.error('error', action);
            throw new Error(exception);
          };
        }
      ]
    };
  });
