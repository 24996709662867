(module => {
  'use strict';
  cannedResponseContextCtrl.$inject = ['$scope', '$rootScope', '$interpolate'];
  function cannedResponseContextCtrl($scope, $rootScope, $interpolate) {
    const self = this;
    const delimiter = '/';

    const shortcutPattern = `^\\s*(?:[^\\s]+\\s+)*(${delimiter}[a-z-0-9-A-Z_]{0,20})$`;
    const shortcutRegex = new RegExp(shortcutPattern);
    // instance vars
    self.suggestionsActive = false;
    self.shortcutMatch = null;
    self.selectedIndex = -1;

    function getRelevantSuggestions(partialToken) {
      const {cannedResponses} = self;
      return cannedResponses.filter(template =>
        tokenIsContainedIn(partialToken, template.key)
      );
    }

    function tokenIsContainedIn(partialToken = '', fullToken) {
      partialToken = partialToken.toLowerCase();
      fullToken = fullToken.toLowerCase();
      return fullToken.indexOf(partialToken) !== -1;
    }
    function _isHtml(str) {
      return /<\/?[a-z][\s\S]*>/i.test(str);
    }
    function _parseHtml(str) {
      let separateLines = str.split(/\r?\n|\r|\n/g);
      let text = ''
      separateLines.forEach(line => {
        text += `<p>${line}</p>`
      });
      return text
    }
    function _toString(elem) {
      return elem.replace(/<[^>]+>/g, ' ').trim();
    }

    function modifyIndex(offset){
        const {selectedIndex} = self;
        const {length} = self.cannedResponsesSuggestions;
        const newIndex = selectedIndex+offset;
        self.selectedIndex = (newIndex < 0) ? length-1 : (newIndex)%length;
        if(self.selectedIndex==0){
            document.querySelector('.canned-response-suggestions.ctr').scrollTop=0;
        }
        if(self.selectedIndex>0){
            const el = document.getElementsByClassName('canned-response-suggestions__suggestion')[self.selectedIndex];
            el.scrollIntoView();
        }
        $scope.$apply();
    }

    function activateIfEmpty(c){
        if(c.text=="")
            c.text="/";
    }

    self.selectResponse = function (){
        const templates = self.cannedResponsesSuggestions[self.selectedIndex];
        self.selectCannedResponse(templates);
    }
    self.receiveUpEvent = function (e, inputCtrl){
        if(!self.suggestionsActive) return;
        e.preventDefault();
        modifyIndex(-1);
    }
    self.receiveDownEvent = function (e, inputCtrl){
        if(!self.suggestionsActive){
            activateIfEmpty(inputCtrl);
            return;
        }
        e.preventDefault();
        modifyIndex(1);
    }
    self.receiveEnterEvent = function (e){
        if(!self.suggestionsActive || self.selectedIndex == -1)
            return;

        e.preventDefault();

        const selection = self.cannedResponsesSuggestions[self.selectedIndex];
        self.selectCannedResponse(selection);
    }
    self.receiveEscapeEvent = function (e, inputCtrl){
        if(!self.suggestionsActive) return;
        e.preventDefault();
        if(inputCtrl.text=="/"){
            inputCtrl.text="";
        }
        self.selectedIndex = -1;
        $scope.$apply();
    }

    // methods
    self.updateWithInputCtrl = function(inputCtrl) {
      self.inputCtrl = inputCtrl;
      let isHtml = _isHtml(inputCtrl.text);

      const htmlString = _toString(inputCtrl.text);

      const matches = !isHtml
        ? inputCtrl.text.match(shortcutRegex)
        : htmlString.match(shortcutRegex);

      if (matches) {
        self.shortcutMatch = matches[1];
        const matchedToken = self.shortcutMatch.substring(1);
        const relevantSuggestions = getRelevantSuggestions(matchedToken);

        if (relevantSuggestions.length === 1) {
          const [matchingCannedResponse] = relevantSuggestions;
          if (tokenIsContainedIn(matchingCannedResponse.key, matchedToken)) {
            self.selectCannedResponse(matchingCannedResponse);
          }
        }
        if (self.suggestions) {
          self.cannedResponsesSuggestions = relevantSuggestions;
        }
      }
      if (self.suggestions) {
        self.suggestionsActive = !!matches;
        self.selectedIndex = -1;
      }
    };

    self.selectCannedResponse = function(templates) {
      const {inputCtrl} = self;
      const {canned_response} = templates;
      const interpolatedResponseText = $interpolate(canned_response)(
        self.availableParams
      );
      if (self.shortcutMatch !== null) {
        if (_isHtml(inputCtrl.text)) {
          const html = document.createElement('div');
          html.innerHTML = inputCtrl.text;

          const updateHtml = inputCtrl.text.replace(
            self.shortcutMatch,
            _parseHtml(interpolatedResponseText)
          );
          $('#summernoteReply').summernote('reset');
          $('#summernoteReply').summernote('saveRange');
          setTimeout(() => {
            $('#summernoteReply').summernote('code', updateHtml);
          }, 100);
          $('#summernoteReply').summernote('restoreRange');
          self.shortcutMatch = '';
        } else if (inputCtrl.isRichText) {
          $('#summernoteReply').summernote(
            'code', `<div>${_parseHtml(interpolatedResponseText)}</div>`
          );
        } else {
          self.inputCtrl.text = [
            inputCtrl.text.substring(
              0,
              inputCtrl.text.length - self.shortcutMatch.length
            ),
            interpolatedResponseText
          ].join('');
        }
      } else {
        if (inputCtrl.isRichText) {
          $('#summernoteReply').summernote('saveRange');
          $('#summernoteReply').summernote('restoreRange');
          $('#summernoteReply').summernote(
            'code', `<div>${_parseHtml(interpolatedResponseText)}</div>`
          );
        } else {
          inputCtrl.text = [inputCtrl.text, interpolatedResponseText].join('');
        }
      }
    };

    self.toggleSuggestionActive = function() {
      self.suggestionsActive = !self.suggestionsActive;
      self.cannedResponsesSuggestions = getRelevantSuggestions();
    };
  }
  module.controller('cannedResponseContextCtrl', cannedResponseContextCtrl);
})(angular.module('postCenterWebClientApp'));
