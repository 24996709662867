'use strict';

angular
  .module('postCenterWebClientApp')
  .directive('kpiExecutiveDetailTable', function () {
    return {
      scope: {
        period: '=',
        account: '=',
        businessTime: '=',
        filterByCreated: '='
      },
      templateUrl: 'blocks/analytics/views/kpi_executive_detail_table.html',
      restrict: 'E',
      controller: KpiExecutiveDetailTableController,
      controllerAs: 'vm'
    };
  });

KpiExecutiveDetailTableController.$inject = [
  '$scope',
  '$filter',
  '$uibModal',
  'AnalyticsDashboard',
  'UserFilter',
  'adExportToCsv',
  'FireTagService',
  'EstablishmentService'
];

function KpiExecutiveDetailTableController(
  $scope,
  $filter,
  $uibModal,
  AnalyticsDashboard,
  UserFilter,
  adExportToCsv,
  FireTagService,
  EstablishmentService
) {
  var selectTabQuit;
  var vm = this;

  vm.executiveKPIOpen = true;
  vm.loading = false;
  vm.softLoading = false;
  vm.executiveTableData = {};
  vm.openHelpModal = openHelpModal;

  vm.exportTable = () => {
    const title = $filter('translate')(
      'ANALYTICS_DASHBOARD_EXECUTIVES_TABLE_TITLE'
    );

    const name = $filter('translate')('ANALYTICS_DASHBOARD_EXECUTIVES_NAME');
    const headers = [];
    headers.push(name);
    vm.headers.map(header => {
      headers.push(header);
    });
    const data = [];
    vm.executiveTableData.map(detail => {
      let objExecutive = {};
      objExecutive[headers[0]] = detail.name;
      vm.headers.map((header, i) => {
        objExecutive[headers[i + 1]] = detail[header];
      });
      data.push(objExecutive);
    });
    adExportToCsv.exportToCsv(title, data);
    FireTagService.setEvent({
      name: 'exportacion_general',
      params: {
        export_name: title
      }
    });
  };

  UserFilter.updateDepartmentsOptions(vm).then(results => {
    const currentUser = results[0];
    vm.filterParams = {
      name: '',
      department: UserFilter.getDefaultDepartment(currentUser, results[1])
    }
  })
  
  vm.multiSelectTranslations = {
    selectAll: $filter('translate')('MULTISELECT_SELECT_ALL'),
    selectNone: $filter('translate')('MULTISELECT_SELECT_NONE'),
    reset: $filter('translate')('MULTISELECT_RESET'),
    search: $filter('translate')('MULTISELECT_SEARCH'),
    nothingSelected: $filter('translate')('MULTISELECT_NOTHING_SELECTED')
  };

  const kpiDefs = {
    assigned_tickets: {
      name: 'assigned_tickets',
      ticked: true,
      percent: true,
      label: $filter('translate')(
        'ANALYTICS_DASHBOARD_EXECUTIVES_TICKETS_ASSIGNED'
      ),
      help: $filter('translate')(
        'ANALYTICS_DASHBOARD_EXECUTIVES_TICKETS_ASSIGNED_HELP'
      )
    },
    created_tickets_in_business_time: {
      name: 'created_tickets_in_business_time',
      ticked: true,
      percent: true,
      label: $filter('translate')(
        'ANALYTICS_DASHBOARD_EXECUTIVES_TICKETS_CREATED_IN_BUSINESS_TIME'
      ),
      help: $filter('translate')(
        'ANALYTICS_DASHBOARD_EXECUTIVES_TICKETS_CREATED_IN_BUSINESS_TIME_HELP'
      )
    },
    closed_tickets: {
      name: 'closed_tickets',
      ticked: true,
      percent: true,
      label: $filter('translate')(
        'ANALYTICS_DASHBOARD_EXECUTIVES_TICKETS_CLOSED'
      ),
      help: $filter('translate')(
        'ANALYTICS_DASHBOARD_EXECUTIVES_TICKETS_CLOSED_HELP'
      )
    },
    ignored_tickets: {
      name: 'ignored_tickets',
      ticked: true,
      percent: true,
      label: $filter('translate')(
        'ANALYTICS_DASHBOARD_EXECUTIVES_TICKETS_IGNORED'
      ),
      help: $filter('translate')(
        'ANALYTICS_DASHBOARD_EXECUTIVES_TICKETS_IGNORED_HELP'
      )
    },
    opened_tickets: {
      name: 'opened_tickets',
      ticked: true,
      percent: true,
      label: $filter('translate')(
        'ANALYTICS_DASHBOARD_EXECUTIVES_TICKETS_OPENED'
      ),
      help: $filter('translate')(
        'ANALYTICS_DASHBOARD_EXECUTIVES_TICKETS_OPENED_HELP'
      )
    },
    addressed_tickets: {
      name: 'addressed_tickets',
      ticked: false,
      percent: true,
      label: $filter('translate')(
        'ANALYTICS_DASHBOARD_EXECUTIVES_TICKETS_ADDRESSED'
      ),
      help: $filter('translate')(
        'ANALYTICS_DASHBOARD_EXECUTIVES_TICKETS_ADDRESSED_HELP'
      )
    },
    addressed_in_sla_tickets: {
      name: 'addressed_in_sla_tickets',
      ticked: false,
      percent: true,
      label: $filter('translate')(
        'ANALYTICS_DASHBOARD_EXECUTIVES_TICKETS_ADDRESSED_IN_SLA'
      ),
      help: $filter('translate')(
        'ANALYTICS_DASHBOARD_EXECUTIVES_TICKETS_ADDRESSED_IN_SLA_HELP'
      )
    }
  };

  EstablishmentService.getEstablishment().then(establishment => {
    vm.availableKpis = _.values(kpiDefs);
    vm.enabledKpi = vm.availableKpis[0];

    var in_progress_idx = _.findIndex(vm.availableKpis, function (kpi) {
      return kpi.name === 'in_progress_tickets';
    });
    if (establishment.config.workflow_enabled) {
      if (in_progress_idx < 0) {
        vm.availableKpis.splice(3, 0, {
          name: 'in_progress_tickets',
          ticked: false,
          percent: true,
          label: $filter('translate')(
            'ANALYTICS_DASHBOARD_EXECUTIVES_TICKETS_IN_PROGRESS'
          ),
          help: $filter('translate')(
            'ANALYTICS_DASHBOARD_EXECUTIVES_TICKETS_IN_PROGRESS_HELP'
          )
        });
      }
    } else if (in_progress_idx >= 0) {
      vm.availableKpis.splice(in_progress_idx, 1);
    }

    vm.changeKpi = function () {
      refreshTable(false);
    };

    // Reload on executives detail tab selected
    var tabSelectedEvent = 'analytic:dashboard:tabSelected';
    selectTabQuit = $scope.$on(tabSelectedEvent, function (event, selectedTab) {
      if (selectedTab === 'executiveDetail') {
        refreshTable(false);
      }
    });

    // Reload on period, account, businessTime change
    const periodWatcherOff = $scope.$watch('period', function () {
      refreshTable(true);
    });
    const accountWatcherOff = $scope.$watch('account', function () {
      refreshTable(true);
    });
    const businessTimeWatcherOff = $scope.$watch('businessTime', function () {
      refreshTable(true);
    });
    const filterByCreatedWatcherOff = $scope.$watch('filterByCreated', function () {
      refreshTable(true);
    });

    const filterParamsWatcherOff =$scope.$watchCollection('vm.filterParams', function () {
      refreshTable(true);
    });

    $scope.$on('$destroy', () => {
      periodWatcherOff();
      accountWatcherOff();
      businessTimeWatcherOff();
      filterByCreatedWatcherOff();
      filterParamsWatcherOff();
    });
  })

  vm.humanizeDuration = function (seconds) {
    return moment.duration(seconds, 'seconds').humanize();
  };

  $scope.$on('$destroy', function () {
    selectTabQuit();
  });

  function refreshTable(softLoading) {
    if (softLoading) {
      vm.softLoading = true;
    } else {
      vm.loading = true;
    }

    AnalyticsDashboard.executiveDetailTable(
      'executives_resume_table',
      $scope.period.now,
      $scope.period.until,
      $scope.account,
      $scope.businessTime,
      $scope.filterByCreated,
      vm.enabledKpi.name
    )
      .then(function (tableData) {
        // Users filter
        var executives = tableData.executives;
        var preFilteredUsers = UserFilter.getFilteredUsers(
          vm.filterParams.name,
          undefined,
          vm.filterParams.department
        );
        executives = _.filter(executives, function (user, cmid) {
          for (var index = 0; index < preFilteredUsers.length; index++) {
            if (preFilteredUsers[index].id === parseInt(cmid)) {
              return true;
            }
          }
          return false;
        });
        executives.push(totalRow(executives));
        vm.executiveTableData = executives;
        var headers = tableData.headers;
        vm.headers = headers.headers.split(',');

        if (softLoading) {
          vm.softLoading = false;
        } else {
          vm.loading = false;
        }
      })
      .catch(function (error) {
        if (softLoading) {
          vm.softLoading = false;
        } else {
          vm.loading = false;
        }
      });
  }

  function totalRow(tableData) {
    var totalRow = {};
    var singleRow = tableData[0];
    _.each(singleRow, function (value, key) {
      if (!isNaN(value)) {
        totalRow[key] = 0;
        tableData.forEach(function (row) {
          totalRow[key] += row[key];
        });
      }
    });
    totalRow['name'] = 'Total';
    return totalRow;
  }

  function openHelpModal() {
    $uibModal.open({
      templateUrl: 'blocks/analytics/views/dashboard_help_modal.html',
      size: 'sm',
      controller: ['$scope', '$uibModalInstance', '$filter', modalController]
    });

    function modalController($scope, $uibModalInstance, $filter) {
      $scope.title = 'ANALYTICS_DASHBOARD_EXECUTIVES_TABLE_TITLE';
      $scope.helpText = $filter('translate')(
        'ANALYTICS_DASHBOARD_EXECUTIVES_TABLE_DETAIL_HELP'
      );
      $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
      };
    }
  }
}
