(module => {
    'use strict';
    CreateCampaignCtrl.$inject = [
        "$scope",
        '$rootScope',
        "FRONTEND_VUE_URL",
        "$sce",
        "AdNotification",
        "AdRequest"
    ]
    module.controller('CreateCampaignCtrl', CreateCampaignCtrl);

    function CreateCampaignCtrl(
        $scope, $rootScope, FRONTEND_VUE_URL, $sce, AdNotification,
        AdRequest: AdRequestService
    ) {
        $rootScope.adminTab.selected = 'create_campaign';

        $scope.iFrameUrl = function () {
            const token = AdRequest.getToken();
            const url = `${FRONTEND_VUE_URL}/frames/campaigns/create_campaign?authToken=${token}`
            return $sce.trustAsResourceUrl(url);
        }
    }
})(angular.module('postCenterWebClientApp'))