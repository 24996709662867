const MonitoringModals = Vue.component('monitoring-modals', {
  data() {
    return {loading: false};
  },
  computed: {
    ...Vuex.mapState(['modalStatus']),
    selectedModal() {
      const {modalStatus} = this;

      if (!modalStatus) {
        return null;
      }

      const modal = modalStatus === 'viewDashboard' ? 'created' : 'new';

      return {
        title: this.$t(`modals.${modal}.title`),
        description: this.$t(`modals.${modal}.description`),
        onConfirm: () => {
          if (modal === 'new') {
            this.createColumnAction();
          } else {
            this.setModal(null).then(() => window.open('/', '_blank'));
          }
        },
        onCancel: () => this.setModal(null)
      };
    }
  },
  methods: {
    ...Vuex.mapActions(['createColumn', 'setModal']),
    createColumnAction() {
      this.loading = true;
      this.createColumn()
        .then(() => this.setModal('viewDashboard'))
        .finally(() => (this.loading = false));
    }
  },
  template: `
    <div v-if="modalStatus" class="monitoring--modals">
      <ConfirmModal
        :open="selectedModal"
        v-bind="selectedModal">
        <div class="text-center">
          <Loader v-if="loading" size="2x" />
        </div>
      </ConfirmModal/>
    </div>
  `
});
