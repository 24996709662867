(function(module) {
  'use strict';
  smoochConnectService.$inject = ['$filter'];
  function smoochConnectService($filter) {
    const translate = $filter('translate');
    const translatePrefix = 'SOCIALACCOUNTS_SMOOCH_';
    return {
      smoochSteps: [
        {
          message: translate(`${translatePrefix}GO_FORM`),
          nextStep: translate(`${translatePrefix}NEXT_FORM`),
          numberStep: 1
        },
        {
          message: translate(`${translatePrefix}FACEBOOK_REVISION`),
          nextStep: translate(`${translatePrefix}NEXT_REVISION`),
          numberStep: 2
        },
        {
          message: translate(`${translatePrefix}APPROVED_NUMBER`),
          nextStep: translate(`${translatePrefix}NEXT_APPROVED`),
          numberStep: 3
        },
        {
          message: translate(`${translatePrefix}ACTIVATED_NUMBER`),
          nextStep: translate(`${translatePrefix}NEXT_ACTIVATED`),
          numberStep: 4
        }
      ],
      errorStep: [
        {
          message: translate(`${translatePrefix}INVALID_ID`),
          nextStep: translate(`${translatePrefix}INVALID_ID_STEP`),
          numberStep: 2,
          warning: true
        },
        {
          message: translate(`${translatePrefix}APPROVED_CHANGE_NAME`),
          nextStep: translate(`${translatePrefix}APPROVED_CHANGE_NAME_STEP`),
          numberStep: 2,
          warning: true
        },
        {
          message: translate(`${translatePrefix}REJECTED_NUMBER`),
          nextStep: translate(`${translatePrefix}REJECTED_NUMBER_STEP`),
          numberStep: 2,
          warning: true
        },
        {
          message: translate(`${translatePrefix}APPROVED_MIGRATE_NUMBER`),
          nextStep: translate(`${translatePrefix}APPROVED_MIGRATE_NUMBER_STEP`),
          numberStep: 3,
          warning: true
        }
      ],
      getStatus(n) {
        const status = [
          'rejected_invalid_id',
          'approved_change_name',
          'rejected',
          'approved_must_migrate',
          'added',
          'sent',
          'approved',
          'activated',
          'ready'
        ];
        return status[n];
      },
      getSmoochSteps(status) {
        switch (status) {
          case 'added':
            return this.smoochSteps[0];
          case 'sent':
            return this.smoochSteps[1];
          case 'approved':
            return this.smoochSteps[2];
          case 'activated':
            return this.smoochSteps[3];
          case 'rejected_invalid_id':
            return this.errorStep[0];
          case 'approved_change_name':
            return this.errorStep[1];
          case 'rejected':
            return this.errorStep[2];
          case 'approved_must_migrate':
            return this.errorStep[3];
        }
      }
    };
  }
  module.factory('smoochConnectService', smoochConnectService);
})(angular.module('postCenterWebClientApp'));
