'use strict';

var app = angular.module('postCenterWebClientApp');
app.directive('adClickOutside', function($document) {
  return {
    restrict: 'A',
    scope: {
      trigger: '@',
      triggerMethod: '&',
    },
    link: function(scope, elem) {

      elem.bind('click', function(e) {
        e.stopPropagation();
      });

      var elements = $(scope.trigger);

      elements.each(function(index, element){
        $(element).bind('click', function(e){
          $document.bind('click', handler);
          e.stopPropagation();
        });
      });


      var handler = function() {
        scope.$apply(scope.triggerMethod());
        $document.unbind('click', handler);
      };

    }
  };
});
