Vue.component('Button', {
  props: {
    text: {
      type: String,
      default: ''
    },
    centered: Boolean,
    padded: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: 'secondary'
    },
    callback: {
      type: Function,
      default: () => {}
    }
  },
  computed: {
    classes() {
      const defaultButton = 'btn';
      const colorModifier = `btn-${this.type}`;
      const paddingModifier = this.padded ? 'px-3' : '';
      const positionModifier = this.centered ? 'd-block mx-auto' : '';

      return this.joinClasses(
        defaultButton,
        colorModifier,
        paddingModifier,
        positionModifier
      );
    }
  },
  methods: {
    joinClasses(...classes) {
      return classes.join(' ');
    },
    emitClick() {
      this.$emit('clicked');
    }
  },
  template: `<button type="button" @click="emitClick()" :class="classes">
    {{ text }}
    <slot></slot>
  </button>`
});
