'use strict';

var app = angular.module('postCenterWebClientApp');
app.controller(
  'adConfirmModalCtrl',
  function ($scope, message, confirmButtonText, confirmButtonClasses, $filter){
    $scope.message = message;
    $scope.confirmButtonText = confirmButtonText?
      confirmButtonText : $filter('translate')('AD_CONFIRM_MODAL_OK');
    if(confirmButtonClasses === undefined ||
        confirmButtonClasses === null ||
        _.isEmpty(confirmButtonClasses)
    ){
      confirmButtonClasses = ["btn-default"];
    }
    $scope.confirmButtonClasses = confirmButtonClasses;
  }
)