'use strict';

var app = angular.module('postCenterWebClientApp');
app.directive('channelTwitterOptions', function() {
  return {
    templateUrl: 'blocks/admin/views/social_accounts/twitter_options.html',
    restrict: 'E',
    controller: [
      '$scope',
      '$rootScope',
      'EstablishmentService',
      'InitialData',
      'AdNotification',
      function(
        $scope,
        $rootScope,
        EstablishmentService,
        InitialData,
        AdNotification
      ) {
        $scope.establishment = $rootScope.establishment;
        $scope.loading = false;

        $scope.saveOptions = function() {
          $scope.loading = true;
          EstablishmentService.editEstablishment($scope.establishment).then(
            function() {
              InitialData.refreshEstablishment().then(
                function() {
                  AdNotification.success(201, 'save_twitter_options');
                  $scope.establishment = angular.copy($rootScope.establishment);
                  $scope.loading = false;
                },
                function(data) {
                  AdNotification.error(data, 'save_twitter_options');
                  $scope.loading = false;
                }
              );
            },
            function(data) {
              AdNotification.error(data, 'save_twitter_options');
              $scope.loading = false;
            }
          );
        };
      }
    ]
  };
});
