'use strict';

var app = angular.module('postCenterWebClientApp');

app.directive('caseCard', function () {
  return {
    scope: {
      case: '='
    },
    templateUrl: 'blocks/case/views/case.html',
    restrict: 'E',
    controller: 'CaseCtrl'
  };
});
