(function(module) {
  'use strict';
  caseEscalation.$inject = [];
  function caseEscalation() {
    return {
      templateUrl: 'blocks/case/views/case_escalation.html',
      restrict: 'E',
      controller: 'caseEscalationCtrl',
      scope: {caseObject: '='},
      controllerAs: 'vm',
      bindToController: true
    };
  }
  module.directive('caseEscalation', caseEscalation);
})(angular.module('postCenterWebClientApp'));
