(module => {
  AnalyticsMonitoringCtrl.$inject = [
    '$element',
    'AdAvailableSn',
    '$rootScope',
    'UsersService',
    'Analytics',
    'AnalyticsService',
    'ColumnsService',
    'WorkflowStatuses',
    'FireTagService'
  ];

  function AnalyticsMonitoringCtrl(
    $element,
    AdAvailableSn,
    $rootScope,
    UsersService,
    Analytics,
    AnalyticsService,
    ColumnsService,
    WorkflowStatuses,
    FireTagService
  ) {
    FireTagService.setPageView({
      title: 'Monitoreo - Beta',
      path: `/${window.location.hash}`
    });

    $rootScope.analyticTab.selected = 'monitoring';

    const vm = this;

    const {user, accounts, departments} = $rootScope;
    if(user.isSupervisor && !user.isAnalystOrAdmin) {
      departments;
  } else {
    departments.push({
      name: 'Sin departamentos',
      id: 0,
      active: true
    })};

    const filteredDepartments = departments
      .map(department => {
        const {id, name} = department;
        return {
          name,
          id,
          active: true
        };
      })
      .sort((sortA, sortB) => {
        return sortA.name.toLowerCase() > sortB.name.toLowerCase() ? 1 : -1;
      });

    const ticketStates = [
      // Show in summary & rows
      'opened',
      'not_addressed',
      'not_answered',
      'not_assigned',
      // Summary only
      'oldest_open',
      'avg_time_opened'
    ];

    const ticketTables = {
      opened: 'opened_detail_table',
      not_addressed: 'not_addressed_detail_table',
      not_answered: 'not_answered_detail_table',
      not_assigned: 'not_assigned_and_open'
    };

    const i18n = new VueI18n({
      locale: user.profile.language,
      fallbackLocale: 'es',
      silentTranslationWarn: true
    });

    const getOpenWorkflow = async () => {
      const workflows = await WorkflowStatuses.getAll();
      return workflows.find(flow => flow.kind === 0);
    };
    const store = new Vuex.Store({
      // here we create a vuex store
      state: {
        locale: user.profile.language,
        accounts,
        departments: filteredDepartments,
        executives: [],
        kpiPerExecutive: {},
        monitoring: {},
        ticketStates,
        ticketTables,
        lastUpdateTime: null,
        accountDetail: null,
        modalStatus: null,
        user,
        filters: {
          department: []
        },
        range: {
          from: moment()
            .subtract(30, 'days')
            .unix(),
          to: moment().unix()
        }
      },
      // here just are getters functions that return something from store
      getters: {
        locale: state => state.locale,
        departments: state => state.departments,
        filters: state => state.filters,
        lastUpdateTime: state => state.lastUpdateTime,
        monitoring: state => state.monitoring,
        getRange: state => state.range,
        executives: state => state.executives,
        accounts: state => state.accounts,
        accountsByChannel: state => (channel = '') => {
          return state.accounts.filter(account => account.sn === channel);
        },
        getAccountById: state => (accountId = '') => {
          return state.accounts.find(account => account.id === accountId);
        },
        channels: state => AdAvailableSn.channelsFromAccounts(state.accounts),
        getChannelTitle: _state => (sn = '') => {
          return AdAvailableSn.getTitle(sn);
        },
        getChannelByAccount: state => (accountId = '') => {
          const account = state.accounts.find(
            account => account.id === accountId
          );
          return AdAvailableSn.getTitle(account.sn);
        },
        ticketStates: state => ({
          all: state.ticketStates,
          rows: state.ticketStates.slice(0, 4)
        }),
        user: state => state.user,
        userDepartments: state => state.user.profile.departments,
        getTableByState: state => (ticketState = '') => {
          return state.ticketTables[ticketState];
        }
      },
      mutations: {
        mutate(state, {prop, data}) {
          state[prop] = data;
        },
        UPDATE_FILTERS(state, {filter, payload}) {
          state.filters[filter] = payload;
        }
      },
      actions: {
        setFilters({commit}, {filter, payload}) {
          commit('UPDATE_FILTERS', {
            filter,
            payload
          });
        },
        updateMonitoring({commit}) {
          commit('mutate', {
            prop: 'lastUpdateTime',
            data: moment().unix()
          });
        },
        setModal({commit}, status) {
          commit('mutate', {prop: 'modalStatus', data: status});
        },
        updateRange({commit}, data) {
          commit('mutate', {prop: 'range', data});
        },
        async createColumn({state, getters}) {
          if (!state.accountDetail) {
            return;
          }

          const {account} = state.accountDetail;
          const {cmid, sn, account_id} = account;
          const {
            range: {from, to}
          } = state;
          const workflow_status = await getOpenWorkflow();

          const payload = {
            name: account.title,
            column_type: 'case',
            pagination_field: 'updated_time',
            social_networks: sn ? [sn] : [],
            updated_from_date: from,
            updated_to_date: to,
            workflow_status
          };

          if (cmid) {
            payload.assignment = {
              filter_type: 'whitelist',
              community_managers: [cmid]
            };
          }

          if (account_id) {
            const foundAcc = getters.getAccountById(account_id);
            payload.social_accounts = [
              {
                sn: foundAcc.sn,
                uid: foundAcc.uid
              }
            ];
          }

          if (account.state !== 'opened') {
            const columnState = account.state.replace('not_', '');
            payload[columnState] = false;
          }

          return ColumnsService.asyncPostColumn(payload);
        },
        setTicketsDetail({commit, state}, detail = {}) {
          const _commit = data => {
            commit('mutate', {prop: 'accountDetail', data});
          };

          if (!detail) {
            _commit(null);
            return;
          }

          if (detail.page > 1) {
            const {results: currentTickets} = state.accountDetail;
            detail.results = [...currentTickets, ...detail.results];
          }

          detail.page++;
          _commit(detail);
        },
        async getTicketsDetail({dispatch, state, getters}, account) {
          const {user, userDepartments} = getters;
          if (!account.state) {
            dispatch('setTicketsDetail');
          }

          const page = state.accountDetail?.page;
          const {account_id: id, cmid, state: status} = account;
          const name = `${status}_ticket_details_by_account`;
          const {from: until, to: now} = state.range;
          
          const response = await AnalyticsService.getTicketMetrics({
            until,
            now,
            name,
            account: {id},
            cmid,
            page,
            ...(user.isSupervisor && !user.isAnalystOrAdmin ? {userDepartments} : {})
          });
          
          dispatch('setTicketsDetail', {...response, account});
          return response;
        },
        async getExecutives({commit, getters}) {
          const {user, userDepartments} = getters;
          const executives = await UsersService.getCms();
          
          const filteredExecutives = user.isSupervisor && !user.isAnalystOrAdmin
            ? executives.filter(executive => {
              const {departments} = executive;
                return departments.some(department => userDepartments.includes(department));
              })
            : executives;
          commit('mutate', {prop: 'executives', data: filteredExecutives});
        },
        async getKPI({commit, state, getters}, filters = {}) {
          const {user, userDepartments} = getters;
          const {kpiPerExecutive: current, range} = state;
          const {executives, pages} = await AnalyticsService.getExecutivesKPI({
            range,
            extra_filters: {
              ...filters,
              department: user.isSupervisor && !user.isAnalystOrAdmin && userDepartments ? [...userDepartments] : undefined,
            },
          });
          let data;

          if (pages && filters.page > pages) {
            throw new Error('Not enough pages');
          }

          switch (filters.page) {
            case 0:
              data = {};
              break;
            case 1:
              data = executives;
              break;
            default:
              data = {...current, ...executives};
          }

          if (!Object.keys(data).length) {
            data = {empty: true};
          }

          commit('mutate', {prop: 'kpiPerExecutive', data});
          return filters.page;
        },
        async getMetric({state, getters}, req) {
          const {user, userDepartments} = getters;
          const {state: name, channel: sn, account, page} = req;
          const {
            range: {from: until, to: now}
          } = state;
          const response = await AnalyticsService.getTicketMetrics({
            now,
            until,
            name,
            sn,
            account,
            page,
            departments: user.isSupervisor && !user.isAnalystOrAdmin && userDepartments ? [...userDepartments] : undefined,
          });

          if (response.pages && page > response.pages) {
            throw new Error('Not enough pages');
          }

          return response;
        }
      }
    });

    const initVueInstance = () => {
      const config = {
        el: $element[0].querySelector('.ng-non-bindable'),
        store,
        i18n,
        provide: {
          socialNetworkService: AdAvailableSn
        },
        created() {
          Analytics.getTranslations('monitoring', i18n.locale).then(
            translations => {
              i18n.setLocaleMessage(i18n.locale, translations);
            }
          );
        }
      };
      vm.vueInstance = new Vue(config);
    };

    const listenForRouteChanges = () => {
      // On route change, destroy Vue instance
      $rootScope.$on('$stateChangeStart', () => {
        vm.vueInstance.$destroy();
      });
    };

    initVueInstance();
    listenForRouteChanges();
  }

  module.controller('AnalyticsMonitoringCtrl', AnalyticsMonitoringCtrl);
})(angular.module('postCenterWebClientApp'));
