(module => {
  'use strict';
  metricsWidget.$inject = [
    '$interval',
    'AnalyticsHelper',
    'AnalyticsConfigs',
    'AdAvailableSn'
  ];
  function metricsWidget(
    $interval,
    AnalyticsHelper,
    AnalyticsConfigs,
    AdAvailableSn
  ) {
    return {
      scope: {
        widget: '='
      },
      templateUrl: 'blocks/analytics/views/metrics_widget.html',
      restrict: 'AE',
      controller: [
        '$scope',
        '$filter',
        function($scope, $filter) {
          /***********************************
           *
           *  MISCELLANEOUS HELPER
           *
           ***********************************/
          const Utils = {
            getData(silent=false) {
              const config = {
                name: $scope.widget.dataScheme.id,
                delta: $scope.widget.period.delta * 2,
                granularity: $scope.widget.period.granularity,
                silent: silent || false,
                now: $scope.widget.period.now
              };
              $scope.$emit('analytics:getSample', config);
            }
          };

          /***********************************
           *
           *  CHART HELPER
           *
           ***********************************/
          const Chart = {
            getConfigObj(chartReference) {
              const {widget} = $scope;
              const widgetChartConfig = widget.charts.main;
              return AnalyticsConfigs.getChartConfig(
                widget,
                widgetChartConfig,
                chartReference
              );
            },
            addTo(chartObj, metricDataList) {
              const {type} = chartObj.config.chart;
              if (type === 'pie') {
                addToPieChart();
              }
              if (type === 'column') {
                addToColumnChart();
              }

              function addToPieChart() {
                //clear serie data in a way that do not trigger highcharts full repaint
                chartObj.config.series[0].data.splice(
                  0,
                  chartObj.config.series[0].data.length
                );

                metricDataList.forEach(metricData => {
                  if (metricData.value > 0 && !metricData.ignoreInCharts) {
                    chartObj.config.series[0].data.push({
                      name: metricData.name,
                      y: metricData.value
                    });
                  }
                });
              }

              function addToColumnChart() {
                const series = [];

                angular.forEach(metricDataList, (metricData, index) => {
                  if (metricData.value > 0 && !metricData.ignoreInCharts) {
                    series.push({
                      name: metricData.name,
                      data: [metricData.value]
                    });
                  }
                });

                addSeries(series);
              }

              function addSeries(series) {
                angular.forEach(series, (serie, index) => {
                  let serieObj = {};

                  //add new serie if needed
                  if (index >= chartObj.config.series.length) {
                    chartObj.config.series[index] = {};
                  }
                  serieObj = chartObj.config.series[index];
                  serieObj.name = serie.name;
                  serieObj.data = serie.data;
                });

                //remove series that we dont need
                if (chartObj.config.series.length > series.length) {
                  const seriesToRemove =
                    chartObj.config.series.length - series.length;
                  chartObj.config.series.splice(series.length, seriesToRemove);
                }
              }
            }
          };

          /***********************************
           *
           *  METRICS HELPER
           *
           ***********************************/
          const Metrics = {
            clean() {
              $scope.metrics = [];
            },
            update(metricData, highlight) {
              const metric = _.findWhere($scope.metrics, {
                title: metricData.name
              });
              const {type: valueType} = $scope.widget.dataScheme;

              if (metric) {
                metric.value = AnalyticsHelper.formatValue(
                  valueType,
                  metricData.value
                );
                metric.variation = metricData.variation;
                metric.data.value = metricData.value;
              } else {
                $scope.metrics.push({
                  title: metricData.name.replace('_', ' '),
                  value: AnalyticsHelper.formatValue(
                    valueType,
                    metricData.value
                  ),
                  variation: metricData.variation,
                  data: {
                    key: metricData.id,
                    highlightValue: highlight ? true : false,
                    value: metricData.value,
                    index: metricData.index
                  },
                  css: {
                    titleIcon: getIcon(metricData.id),
                    widgetSize: 'widget-small'
                  }
                });
              }

              function getIcon(name) {
                if ($scope.widget.style) {
                  if ($scope.widget.style.icons) {
                    if ($scope.widget.style.icons[name]) {
                      return $scope.widget.style.icons[name];
                    }
                  }
                }
                return AdAvailableSn.getIcon(name);
              }
            },

            build(sample) {
              const metricScheme = _.findWhere(
                $scope.widget.dataScheme.metrics,
                {
                  name: 'counter'
                }
              );

              //var dataDetail = sample.data.detail[metricScheme.key];
              const metricDataList = AnalyticsHelper.getBubbleMetricDataList(
                sample,
                metricScheme,
                null
              );
              const metricDataResume = buildMetricDataResume(
                $scope.widget.resume,
                metricDataList
              );
              metricDataList.push(metricDataResume);

              Metrics.clean();

              metricDataList.forEach(metricData => {
                Metrics.update(metricData);
              });

              Chart.addTo($scope.chart, metricDataList);

              function buildMetricDataResume(resumeType, metricDataList) {
                const resume = {
                  id: $filter('translate')(
                    `ANALYTICS_${resumeType.toUpperCase()}`
                  ),
                  name: $filter('translate')(
                    `ANALYTICS_${resumeType.toUpperCase()}`
                  ),
                  index: 999,
                  value: 0,
                  previousValue: 0,
                  variation: 0,
                  ignoreInCharts: true,
                  type: resumeType,
                  _iters: 0
                };

                metricDataList.forEach(metricData => {
                  const {value: cValue, previousValue: pValue} = metricData;

                  resume._iters++;
                  resume.value += angular.isNumber(cValue) ? cValue : 0;
                  resume.previousValue += angular.isNumber(pValue) ? pValue : 0;
                });

                if (resumeType === 'average') {
                  resume.value =
                    resume._iters === 0 ? 0 : resume.value / resume._iters;
                  resume.previousValue =
                    resume._iters === 0
                      ? 0
                      : resume.previousValue / resume._iters;
                  resume.variation = AnalyticsHelper.periodDiffCalc(
                    resume.value,
                    resume.previousValue
                  );
                }
                return resume;
              }
            }
          };

          /***********************************
           *
           *  WIDGET DATA HOLDERS
           *
           ***********************************/
          $scope.sample = {};
          $scope.metrics = [];

          /***********************************
           *
           *  WIDGET STATES
           *
           ***********************************/
          $scope.state = {
            loading: false,
            dataError: false,
            isLoading() {
              return $scope.state.loading;
            },
            isInFailure() {
              return $scope.state.dataError;
            },
            isReady() {
              return !$scope.state.loading && !$scope.state.dataError;
            },
            isMetricSelected(metric) {
              if (!metric) {
                return false;
              }

              return (
                $scope.toggleableContent.state.selected &&
                $scope.toggleableContent.state.selected.data.key ===
                  metric.data.key
              );
            }
          };

          /***********************************
           *
           *  CHART
           *
           ***********************************/
          $scope.chart = {
            api: null,
            state: {
              hasData() {
                const chartData = $scope.chart.config.series;
                const isDefined = chartData && chartData[0];
                return (
                  isDefined && chartData[0].data && chartData[0].data.length > 0
                );
              }
            }
          };

          $scope.chart.config = Chart.getConfigObj($scope.chart);

          /***********************************
           *
           *  REALTIME
           *
           ***********************************/
          $scope.realtime = {
            state: {
              label() {
                if ($scope.widget.realtime) {
                  return 'ANALYTICS_DISABLE_REALTIME';
                }
                return 'ANALYTICS_ENABLE_REALTIME';
              },
              current() {
                return $scope.widget.realtime ? 'active' : null;
              }
            },
            intervalFn: null,
            watchFn(enabled) {
              if (enabled === true) {
                if (!$scope.realtime.intervalFn) {
                  $scope.realtime.intervalFn = $interval(() => {
                    Utils.getData(true);
                  }, 45000);
                }
              } else {
                if ($scope.realtime.intervalFn) {
                  $interval.cancel($scope.realtime.intervalFn);
                  $scope.realtime.intervalFn = null;
                }
              }
            },
            toggle() {
              $scope.widget.realtime = !$scope.widget.realtime;
            },
            canBeEnabled() {
              return true; //!$scope.widget.period.now;
            },
            turnOff() {
              $scope.widget.realtime = false;
            }
          };

          /***********************************
           *
           *  TOGGLEABLE CONTENT
           *
           ***********************************/
          $scope.toggleableContent = {
            state: {
              active: false,
              selected: null,
              isOpen() {
                const {active} = $scope.toggleableContent?.state;
                return active ? 'open' : null;
              }
            },
            toggle(metric) {
              const {state} = $scope.toggleableContent;
              const sameTitle =
                state.selected !== null &&
                state.selected.title === metric.title;

              if (state.active && sameTitle) {
                state.active = false;
              } else {
                state.active = true;
                state.selected = metric;
              }
            }
          };

          /***********************************
           *
           *  HELP CONTENT
           *
           ***********************************/
          $scope.helpContent = {
            state: {
              shouldShow() {
                if ($scope.widget.help) {
                  if ($scope.widget.help.text) {
                    return true;
                  }
                }
                return false;
              }
            }
          };

          /***********************************
           *
           *  WATCHERS AND EVENT LISTENERS
           *
           ***********************************/
          const widgetRealtimeWatcher = $scope.$watch('widget.realtime', $scope.realtime.watchFn);

          const widgetPeriodWatcher = $scope.$watch('widget.period', period => {
            Utils.getData();
          });

          const gotSampleOff = $scope.$on('analytics:gotSample', (event, sample) => {
            if (sample && sample.name === $scope.widget.dataScheme.id) {
              $scope.sample = sample.obj;
              Metrics.build(sample.obj);
              $scope.state.loading = false;
            }
          });

          const errorGettingSampleOff = $scope.$on('analytics:errorGettingSample', (event, metricName) => {
            $scope.state.loading = false;
            $scope.state.dataError = true;
          });

          const requestingSampleOff = $scope.$on('analytics:requestingSample', (event, metricName) => {
            $scope.state.dataError = false;
            if (metricName && metricName === $scope.widget.dataScheme.id) {
              $scope.state.loading = true;
            }
          });

          const analyticsForceUpdateOff = $scope.$on('analytics:forceUpdate', () => {
            Metrics.build($scope.sample);
          });

          $scope.$on('$destroy', () => {
            if ($scope.realtime.intervalFn) {
              $interval.cancel($scope.realtime.intervalFn);
              $scope.realtime.intervalFn = null;
            }
            widgetRealtimeWatcher();
            widgetPeriodWatcher();
            gotSampleOff();
            errorGettingSampleOff();
            requestingSampleOff();
            analyticsForceUpdateOff();
          });
        }
      ]
    };
  }
  module.directive('metricsWidget', metricsWidget);
})(angular.module('postCenterWebClientApp'));
