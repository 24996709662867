'use strict';

var app = angular.module('postCenterWebClientApp');
app.directive('caseAssign', function() {
  return {
    scope: {
      caseObject: '=case'
    },
    templateUrl: 'blocks/case/views/case_assign.html',
    restrict: 'A',
    controller: [
      '$scope',
      '$rootScope',
      'CaseService',
      'AdNotification',
      '$element',
      function($scope, $rootScope, CaseService, AdNotification, $element) {
        const currentAssignment = $scope.caseObject['current_assignment'];

        $scope.assignDropdown = $element;

        $scope.communityManagers = _.filter(
          $rootScope.communityManagers,
          function(cm) {
            return cm.is_assignable;
          }
        );

        if (currentAssignment == null) {
          $scope.communityManager = null;
        } else {
          for (let i in $scope.communityManagers) {
            if ($scope.communityManagers[i].cmid === currentAssignment.cmid) {
              $scope.communityManager = $scope.communityManagers[i];
              break;
            }
          }
        }

        $element.on('click', function() {
          $scope.$applyAsync(function() {
            $element.find('input[type=text]').focus();
          });
        });

        $element.on('keyup', function(e) {
          const ESC_CODE = 27;
          if (e.keyCode === ESC_CODE) {
            $element.removeClass('open');
          }
        });

        $scope.assign = function() {
          $scope.loading = true;

          const caseData = {
            current_assignment: null,
            assigned: false
          };

          if ($scope.communityManager) {
            caseData['current_assignment'] = {
              cmid: $scope.communityManager.cmid,
              name: $scope.communityManager.name
            };
            caseData.assigned = true;
          }

          const action = 'assign_cm';
          CaseService.assignCM($scope.caseObject['resource_id'], caseData).then(
            function(caseObject) {
              const emitData = {action: 'assign', element: caseObject};
              $scope.showExtra = undefined;
              $scope.caseObject['current_assignment'] =
                caseObject['current_assignment'];

              const currentAssignment = $scope.caseObject['current_assignment'];
              if (currentAssignment !== null) {
                currentAssignment.initials = CaseService.getInitials(
                  currentAssignment.name
                );
              }
              $rootScope.$emit('column:actionOnCase', emitData);

              $scope.loading = false;
              AdNotification.success(201, action);
            },
            function(data) {
              $scope.loading = false;
              AdNotification.error(data, action);
            }
          );
        };

        $scope.setAndAssign = function($event, cm) {
          $scope.communityManager = cm;
          $scope.assign();
          $element.find('label').removeClass('active');
          $element.removeClass('open');
          $event.stopPropagation();
        };

        $scope.getInitials = function() {
          const currentAssignment = $scope.caseObject['current_assignment'];
          if (currentAssignment !== null) {
            return CaseService.getInitials(currentAssignment.name);
          }
          return '';
        };
      }
    ]
  };
});
