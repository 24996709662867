'use strict';

angular.module('postCenterWebClientApp')
  .filter('labelTranslate', function (translateFilter) {
    return function (input, establishment) {
      if (input === 'METADATA_COUNTRY_ID') {
        return establishment.country.resident_id;
      }
      return translateFilter(input);
    };
  });
