const Tab = Vue.component('Tab', {
  props: {
    name: {type: String, required: true},
    active: {type: Boolean, default: false}
  },
  computed: {
    isActive() {
      // Required to avoid mutating "active" prop directly.
      // (Vue throws this warning)
      return this.active;
    }
  },
  template: `
    <div v-show="isActive">
      <slot></slot>
    </div>
  `
});

const Tabs = Vue.component('Tabs', {
  props: {
    disabled: {type: Boolean, default: false},
    paddingTabs: {
      type: Number,
      default: null
    },
    tabs: {
      type: Array,
      required: true
    }
  },
  computed: {
    padding() {
      return this.paddingTabs ? `px-${this.paddingTabs}` : '';
    }
  },
  methods: {
    selectTab(index) {
      return this.tabs.map((tab, tabIndex) => {
        tab.active = tabIndex === index;
        return tab;
      });
    }
  },
  template: `
    <div class="tabs">
      <ul class="tabs--list tabs--of-auto list-unstyled d-flex space-evenly" :class="[padding]">
        <li v-for="(tab, index) in tabs"
            class="tab"
            :class="{ 'tab__active': tab.active }"
            :key="tab.index"
            >
          <button
            type="button"
            @click="selectTab(index)"
            :disabled="disabled"
            class="btn btn-link">{{ tab.name }}</button>
        </li>
      </ul>

      <div class="tabs--details scrollable scrollable-y">
        <slot></slot>
      </div>
    </div>
  `
});
