'use strict';

var app = angular.module('postCenterWebClientApp');
app.directive('messageContentModal', function () {
  return {
    scope: {
      message: '=',
      openModal: '&'
    },
    templateUrl: 'blocks/message/views/message_content_modal.html',
    restrict: 'E',
    controller: 'MessageContentCtrl'
  };
});