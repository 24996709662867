'use strict';

angular.module('postCenterWebClientApp')
  .directive('adReplyAccountSelectorResult', function(){
    return {
      bindToController: {
        'parent': '=adReplyAccountSelectorResult'
      },
      controllerAs: 'resultCtrl',
      controller: function(){},
      link: function(scope, elem, attr, controller){
        controller.parent.setIthResultElem(scope.$index, elem);
        scope.$watch('$index', function($index){
          controller.parent.setIthResultElem($index, elem);
        });
      }
    }
  });
