'use strict';

var app = angular.module('postCenterWebClientApp');
app.component('adWordsInput', {
  bindings: {
    selectedWords: '=ngModel',
    placeholder: '@',
  },
  controller: adWordsInputCtrl,
  templateUrl: 'blocks/global/views/ad_words_input.html'
});

adWordsInputCtrl.$inject = ['$rootScope', 'fuzzySearch'];

function adWordsInputCtrl($rootScope, fuzzySearch) {
  var self = this;

  self.onTagAdding = function (tag) {
    if ('kind' in tag) {
      return true;
    } else {
      return true;
    }
  };

}
