angular.module('postCenterWebClientApp')
  .directive('adHelpButton', [
    function() {
      return {
        restrict: 'AE',
        scope:{
          helpLink: '@'
        },
        templateUrl: 'blocks/global/views/adereso_help_button.html',
        controller: ['$scope', function($scope){
        }],
      };
    }
  ]);
