const Modal = Vue.component('Modal', {
  props: {
    title: String,
    uniqueKey: {
      type: String,
      default: 'modalID'
    }
  },
  template: `
  <div>
    <span type="button" class="btn btn-md grey" data-toggle="modal" :data-target="'#' + uniqueKey">
      <i class="fa fa-question-circle-fas tooltip-icon"></i>
    </span>

    <div class="modal fade" :id="uniqueKey" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header clean">
            <h5 class="modal-title" id="exampleModalLabel">{{title}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span>x</span>
            </button>
          </div>
          <div class="modal-body clean">
            <slot> </slot>
          </div>
          
        </div>
      </div>
    </div>
  </div>`
});
