(module => {
  'use strict';
  BannedKeywordsEditCtrl.$inject = [
    '$scope',
    '$rootScope',
    '$state',
    'AdNotification',
    'InitialData',
    '$transition$',
    'BannedKeywordsService'
  ];
  function BannedKeywordsEditCtrl(
    $scope,
    $rootScope,
    $state,
    AdNotification,
    InitialData,
    $transition$,
    BannedKeywordsService
  ) {
    $scope.bannedKeyword = {};

    if ($state.$current.name.indexOf('banned-keywords.edit') !== -1) {
      $scope.formAction = 'edit';

      const filtered_keywords = $rootScope.bannedKeyword.list.filter(
        keyword => {
          return keyword.id === $transition$.params().id;
        }
      );

      if (filtered_keywords.length > 0) {
        $scope.bannedKeyword = filtered_keywords[0];
      }
    }

    if ($state.$current.name.indexOf('banned-keywords.add') !== -1) {
      $scope.formAction = 'add';
      setTimeout(
        () => document.querySelector('.sidebar-content .form-control').focus(),
        300
      );
    }

    function refreshData() {
      InitialData.refreshBannedKeywords().then(() => {
        $rootScope.goToParentState();
      });
    }

    $scope.addBannedKeyword = function () {
      BannedKeywordsService.addBannedKeyword($scope.bannedKeyword)
        .then(response => {
          AdNotification.success(201, 'add_banned_keyword');
          if (response.had_duplicates) {
            AdNotification.success(201, 'duplicate_banned_keyword');
          }
          refreshData();
        })
        .catch(exception => {
          AdNotification.error(exception, 'add_banned_keyword');
        })
        .finally(() => {});
    };

    $scope.editBannedKeyword = function () {
      BannedKeywordsService.editBannedKeyword(
        $scope.bannedKeyword.id,
        $scope.bannedKeyword
      )
        .then(response => {
          AdNotification.success(201, 'edit_banned_keyword');
          if (response.had_duplicates) {
            AdNotification.success(201, 'duplicate_banned_keyword');
          }
          refreshData();
        })
        .catch(exception => {
          AdNotification.error(exception, 'edit_banned_keyword');
        })
        .finally(() => {});
    };

    $scope.saveChanges = function () {
      switch ($scope.formAction) {
        case 'edit':
          $scope.editBannedKeyword();
          break;
        case 'add':
          $scope.addBannedKeyword();
          break;
      }
    };
  }
  module.controller('BannedKeywordsEditCtrl', BannedKeywordsEditCtrl);
})(angular.module('postCenterWebClientApp'));
