'use strict';

angular
  .module('postCenterWebClientApp')
  .directive('cosMoved', ['$timeout', function ($timeout) {
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {

        var timer = null;

        scope.$watch(function () {
          return attrs.cosMoved;
        }, moved);

        function moved(newVal, oldVal) {
          if (timer) {
            $timeout.cancel(timer);
            timer = null;
          }
          if (oldVal == null || newVal > oldVal) {
            element.addClass("cos-admin__moved");
            timer = $timeout(function () {
              element.removeClass("cos-admin__moved");
            }, 600);
          }
        };
      }
    };
  }]);
