/**
 * settings states config
 */
app.config([
  '$stateProvider',
  '$urlServiceProvider',
  function ($stateProvider, $urlServiceProvider) {
    // AngularTranslate resolver => avoid Flash of Untranslated Content
    const resolve = {
      translateReady: ['$translate', $translate => $translate.onReady()]
    };
    var states = {};

    /********************************
      SETTINGS
    ********************************/

    /*
     * /settings/
     */
    states.settings = {
      name: 'settings',
      abstract: true,
      url: '/settings/',
      templateUrl: 'blocks/settings/views/settings.html',
      controller: 'SettingsCtrl',
      resolve
    };

    $stateProvider.state(states.settings);

    $urlServiceProvider.rules.when('/settings/', '/settings/profile/');
    /********************************
      PROFILE
    ********************************/
    /*
     * /settings/profile/
     */
    states.profile = {
      name: 'settings.profile',
      url: 'profile/',
      views: {
        content: {
          templateUrl: 'blocks/settings/views/profile.html',
          controller: 'ProfileCtrl'
        }
      }
    };

    $stateProvider.state(states.profile);

    /********************************
      NOTIFICATIONS
    ********************************/
    /*
     * /settings/notifications
     */
    states.notifications = {
      name: 'settings.notifications',
      url: 'notifications/',
      views: {
        content: {
          templateUrl: 'blocks/settings/views/notifications.html',
          controller: 'SettingsNotificationsCtrl'
        }
      }
    };

    $stateProvider.state(states.notifications);
  }
]);
