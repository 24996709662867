Vue.component('Collapsable', {
  props: {
    open: {type: Boolean, default: true},
    keepOpen: {type: Boolean, default: false},
    paddingLevel: {type: Number, default: 2},
    background: {
      type: String,
      validator: bg => ['light', 'dark', 'clear', 'none'].includes(bg),
      default: 'dark'
    }
  },
  computed: {
    headerClasses() {
      const hideArrow = this.keepOpen ? 'summary__noarrow' : 'clickable';
      const backgroundColor = `summary__${this.background}`;

      return [hideArrow, backgroundColor];
    },
    bodyClasses() {
      const pY = 'py-1';
      const pX = `px-${this.paddingLevel}`;
      return [pY, pX];
    }
  },
  methods: {
    shouldToggle(event) {
      if (this.keepOpen) {
        event.preventDefault();
        event.stopPropagation();
      }
    }
  },
  template: `
    <details class="collapsable admin-panel no-margin" :open="keepOpen || open">
      <summary :class="headerClasses"
               @click="shouldToggle($event)">
        <h5 class="bold d-inline">
          <slot name="title">Título</slot>
        </h5>
        <slot name="icon"></slot>
      </summary>
      <div :class="bodyClasses">
        <slot></slot>
      </div>
    </details>
  `
});
