'use strict';

var app = angular.module('postCenterWebClientApp');

app.directive('massiveActionsCloseTicketsFilter', function() {
  return {
    templateUrl:
      'blocks/admin/views/massive_actions/close_tickets/close_tickets_filter.html',
    restrict: 'E',
    scope: {refreshParentHistory: '<'},
    controller: CloseTicketsFilterDirective
  };
});

CloseTicketsFilterDirective.$inject = [
  '$scope',
  'CloseTicketsService',
  'AdNotification',
  '$filter',
  'SocialAccountService'
];

function CloseTicketsFilterDirective(
  $scope,
  CloseTicketsService,
  AdNotification,
  $filter,
  SocialAccountService
) {

  SocialAccountService.getSocialAccounts().then(accounts => {
    $scope.accounts = accounts;
  })
  $scope.formLoading = false;
  $scope.dateProblems = false;

  $scope.dateFilterTags = [
    {
      name: 'MASSIVE_ACTION_CLOSE_TICKET_FILTER_PARAM_DATE_TAG_UPDATE',
      id: 1,
      tag: 'UPDATE'
    },
    {
      name: 'MASSIVE_ACTION_CLOSE_TICKET_FILTER_PARAM_DATE_TAG_CREATION',
      id: 2,
      tag: 'CREATION'
    }
  ];

  $scope.filters = {
    account: null,
    filterTag: $scope.dateFilterTags[0],
    onlyBot: false,
    dateFrom: null,
    dateUntil: null
  };

  $scope.resetFilters = function() {
    $scope.filters = {
      account: null,
      filterTag: $scope.dateFilterTags[0],
      onlyBot: false,
      dateFrom: null,
      dateUntil: null
    };
  };

  $scope.closeTickets = function() {
    $scope.formLoading = true;

    var dateFrom = $scope.filters.dateFrom.getTime() / 1000;
    var dateUntil = $scope.filters.dateUntil.getTime() / 1000;

    var data = {
      account: $scope.filters.account.uid,
      sn: $scope.filters.account.sn,
      bot: $scope.filters.onlyBot,
      dateTag: $scope.filters.filterTag,
      dateFrom: dateFrom,
      dateUntil: dateUntil
    };

    CloseTicketsService.closeTicketFilters(data).then(
      function(result) {
        $scope.resetFilters();
        var message = $filter('translate')('MASSIVE_ACTION_CLOSE_SUCCESS');
        AdNotification.notify(message, 'success');
        $scope.formLoading = false;
        $scope.refreshParentHistory();
      },
      function(error) {
        var message = $filter('translate')('MASSIVE_ACTION_CLOSE_ERROR');
        $scope.formLoading = false;
        AdNotification.notify(message, 'error');
      }
    );
  };

  const dateFromWatcherOff = $scope.$watch('filters.dateFrom', function() {
    $scope.dateProblems = $scope.checkInvalidDates();
  });

  const dateuntilWatcherOff = $scope.$watch('filters.dateUntil', function() {
    $scope.dateProblems = $scope.checkInvalidDates();
  });

  $scope.$on('$destroy', () => {
    dateFromWatcherOff();
    dateuntilWatcherOff();
  });

  $scope.checkInvalidDates = function() {
    if ($scope.filters.dateFrom != null && $scope.filters.dateUntil != null) {
      var difference =
        $scope.filters.dateUntil.getTime() - $scope.filters.dateFrom.getTime();
      if (difference <= 0) {
        return true;
      }
    }
    return false;
  };
}
