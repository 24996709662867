(function(module) {
  'use strict';
  caseIntegrationCtrl.$inject = [
    '$scope',
    '$timeout',
    '$sce',
    'AdRequest',
    '$filter',
    '$element',
    'EstablishmentService',
    'UserService',
    'CustomData'
  ];
  function caseIntegrationCtrl(
    $scope,
    $timeout,
    $sce,
    AdRequest,
    $filter,
    $element,
    EstablishmentService: EstablishmentService,
    UserService: UserService,
    CustomData: CustomDataService
  ) {
    const vm = this;
    const translate = $filter('translate');

    vm.$onInit = () => {
      init();
    };
    const init = () => {
      Promise.all([
        EstablishmentService.getEstablishment(),
        UserService.getProfile(),
        CustomData.getCustomData()
      ]).then(results => {
        let establishment = results[0]
        let user = results[1]
        let customData = results[2]
        vm.customData = customData
        $scope.activeIntegrationIframe = false
        $scope.cmData = getCMData(user.id)
        $scope.establishment = establishment
        $scope.config = establishment.config
        $scope.method = $scope.config.integration_window_request.method
        $scope.action = $sce.trustAsResourceUrl(
          $scope.config.integration_window_request.url
        )
        $scope.authError = undefined
        $scope.doingAuth = false
        $scope.caseData = getCaseData(vm.caseObject)
        $scope.userData = getUserData(vm.userObject)
        $scope.checkFormValidity()
        $scope.show = false
      })
    }

    const caseUpdateListenerOff = $scope.$on('case:hasBeenUpdated', (_event, caseObject) => {
      vm.caseObject = caseObject;
      init();
    })

    const userUpdateListenerOff = $scope.$on('userCase:hasBeenUpdated', (_event, userCase) => {
      vm.userObject = userCase;
      init();
    })

    $scope.$on('$destroy', () => {
      caseUpdateListenerOff();
      userUpdateListenerOff();
    });
    
    vm.$onDestroy = () => {};

    $scope.validFieldValue = function(value) {
      if (isList(value)) {
        return value.length > 0 && value[0].length > 0;
      } else if (value === '') {
        return false;
      } else {
        return value != undefined;
      }
    };

    $scope.closeIntegrationIframe = function() {
      $scope.activeIntegrationIframe = false;
      $element.find('iframe').attr('src', '');
    };

    $scope.hasList = function(datum) {
      return datum.list != undefined;
    };

    $scope.checkFormValidity = function() {
      var data = dataToSend();
      for (var prop in data) {
        var value = data[prop];
        if (!$scope.validFieldValue(value)) {
          $scope.invalidForm = true;
          return;
        }
      }
      $scope.invalidForm = false;
    };
    $scope.openIntegrationIframe = function() {
      var data = dataToSend();
      $scope.doingAuth = true;
      AdRequest.get('/api/v1/integration_auth/').then(
        function(response) {
          $scope.doingAuth = false;
          $scope.authError = undefined;
          for (var prop in response) {
            data[prop] = response[prop];
          }
          $scope.postData = data;
          $scope.$applyAsync();
          $timeout(function() {
            $scope.activeIntegrationIframe = true;
            $element.find('form').submit();
          }, 100);
        },
        function(error_response) {
          $scope.doingAuth = false;
          $scope.authError = error_response.response.error;
        }
      );
    };
    function isList(value) {
      return Object.prototype.toString.call(value) === '[object Array]';
    }

    function getCustomData() {
      return vm.customData.filter(function(cd) {
        return cd['is_integrable'] && !cd.deleted;
      });
    }

    function getUserData(userObj) {
      var userData = getCustomData().filter(function(cd) {
        return cd.entity === 'U';
      });

      userData.forEach(function(v) {
        var value = userObj[v.key];
        if (isList(value) && value.length > 0) {
          v.list = value;
          v.value = value[0];
        } else v.value = value;
        v.selected = $scope.validFieldValue(v.value);
      });

      userData.unshift({
        label: translate('INTEGRATION_WINDOW_CUSTOMER_ID'),
        integration_key: 'PC_CLIENT',
        value: userObj.resource_id,
        selected: true
      });

      return userData;
    }

    function getCaseData(caseObj) {
      var caseData = getCustomData().filter(function(v) {
        return v.entity === 'C';
      });

      caseData.unshift({
        label: translate('INTEGRATION_WINDOW_TICKET_ID'),
        integration_key: 'PC_TICKET',
        key: 'resource_id',
        entity: 'C'
      });

      caseData.forEach(function(v) {
        var value = caseObj[v.key];
        if (isList(value) && value.length > 0) {
          v.list = value;
          v.value = value[0];
        } else v.value = value;
        v.selected = $scope.validFieldValue(v.value);
      });

      return caseData;
    }

    function getCMData(cm_id) {
      return [
        {
          label: translate('INTEGRATION_WINDOW_AGENT_ID'),
          key: 'PC_ID',
          value: cm_id,
          selected: true
        }
      ];
    }

    function generateDataToSend(customData) {
      return customData.map(function(datum) {
        return {
          label: datum.label,
          key: datum.integration_key,
          value: datum.value,
          selected: datum.selected
        };
      });
    }

    function dataToSend() {
      var userData = generateDataToSend($scope.userData);
      var caseData = generateDataToSend($scope.caseData);
      var cmData = $scope.cmData;
      var data = userData
        .concat(caseData)
        .concat(cmData)
        .filter(function(v) {
          return v.selected;
        });

      data.push({
        key: 'PC_ESTABLISHMENT_ID',
        value: $scope.establishment.id
      })

      var dataObj = {};
      data.forEach(function(datum) {
        dataObj[datum.key] = datum.value;
      });

      return dataObj;
    }
  }
  module.controller('caseIntegrationCtrl', caseIntegrationCtrl);
})(angular.module('postCenterWebClientApp'));
