(module => {
  'use strict';

  function departmentSelectorResult() {
    return {
      bindToController: {
        parent: '=departmentSelectorResult'
      },
      controllerAs: 'resultCtrl',
      controller() {},
      link(scope, elem, attr, controller) {
        controller.parent.setIthResultElem(scope.$index, elem);
        const indexWatcherOff = scope.$watch('$index', $index => {
          controller.parent.setIthResultElem($index, elem);
        });

        scope.$on('$destroy', function () {
          indexWatcherOff();
        });
      }
    };
  }

  module.directive('departmentSelectorResult', departmentSelectorResult);
})(angular.module('postCenterWebClientApp'));
