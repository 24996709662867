/* global workloadStore i18nWorkload */
(module => {
  'use strict';
  AssignmentMonitoringCtrl.$inject = [
    '$scope',
    'AssignmentService',
    'EstablishmentService',
    '$rootScope',
    'UsersService',
    'UserService',
    'DepartmentService',
    'CustomOnlineStatuses',
    'AnalyticsService'
  ];
  module.controller('AssignmentMonitoringCtrl', AssignmentMonitoringCtrl);
  function AssignmentMonitoringCtrl(
    $scope,
    AssignmentService,
    EstablishmentService: EstablishmentService,
    $rootScope,
    UsersService,
    UserService: UserService,
    DepartmentService,
    CustomOnlineStatuses,
    AnalyticsService
  ) {
    const vm = this;

    vm.$onInit = () => {
      Promise.all([
        EstablishmentService.getEstablishment(),
        UserService.getProfile()
      ]).then(([establishment, user]) => {
        $rootScope.assignmentOpenConfig = false
        const {
          config: {enable_queued_assignment: enableLimits}
        } = establishment
        const {
          profile: {language}
        } = user;
        vm.createVueInstance(language, enableLimits, user);
      })
    }
    workloadStore.AssignmentService = AssignmentService;
    workloadStore.DepartmentService = DepartmentService;
    workloadStore.CustomOnlineStatuses = CustomOnlineStatuses;
    workloadStore.UsersService = UsersService;
    workloadStore.AnalyticsService = AnalyticsService;

    vm.createVueInstance = (locale, limits, user) => {
      vm.vueInstance = new Vue({
        el: document.getElementById('workload'),
        i18n: i18nWorkload,
        store: workloadStore,
        data: {
          ready: false
        },
        created() {
          this.setLimits({
            property: 'limit',
            value: limits
          });
          this.setUser({
            value: user
          });
          Promise.all([
            this.getDepartments(),
            this.getAgents(),
            this.getStatuses()
          ]).then(resp => {
            this.ready = true;
            this.getWorkload();
            if (!limits) {
              this.getTotals({
                name: 'opened_and_not_answered_tickets_critical_table',
                property: 'nonAnswered'
              });
            }
            this.getTotals({
              name: 'opened_and_not_addressed_tickets_critical_table',
              property: 'nonAddressed'
            });
          });
        },
        methods: {
          ...Vuex.mapMutations({
            setLimits: 'MUTATE',
            setUser: 'SET_USER'
          }),
          ...Vuex.mapActions([
            'getWorkload',
            'getDepartments',
            'getAgents',
            'getStatuses',
            'getTotals'
          ])
        }
      });
    };
  }
})(angular.module('postCenterWebClientApp'));
