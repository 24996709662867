const SectionHeader = Vue.component('SectionHeader', {
  props: {
    icon: {
      type: String,
      default: 'circle'
    },
    title: {
      type: String,
      default: 'Section title',
      required: true
    },
    supportSection: {
      type: String,
      default: null,
      required: false
    },
    customTextButton: {
      type: String,
      default: null,
      required: false
    }
  },
  computed: {
    getIcon() {
      return `fa-${this.icon}`;
    }
  },
  template: `
    <header class="section-header--container">
      <div class="section-header--main d-flex align-center">
        <span class="fa fa-lg mr-1" :class="getIcon" />
        <h3 class="section-header--title bold m-0">{{ title }}</h3>
        <div class="section-header--buttons">
          <slot name="buttons"></slot>
          <SupportLink :support-path="supportSection" :customText="customTextButton"/>
        </div>
      </div>
      <div v-if="$slots.subheader" class="d-block w-100">
        <hr />
        <slot name="subheader"></slot>
      </div>
    </header>
  `
});
