const WorkloadTimer = Vue.component('WorkloadTimer', {
  data() {
    return {
      timer: 30,
      auto: true,
      active: true
    };
  },
  computed: {
    ...Vuex.mapGetters(['totalElements']),
    getTimer() {
      return this.timer < 10 ? `0${this.timer}` : this.timer;
    },
    getWarnColor() {
      return {
        color: this.timer <= 5 ? '#9D2B23' : ''
      };
    }
  },
  watch: {
    timer(newval) {
      if (newval === 0) {
        this.setFilters({
          filter: 'pagination_size',
          payload: this.totalElements
        });
      }
    },
    auto(newval) {
      if (newval) {
        this.active = true;
        this.startTimer();
      } else {
        this.active = false;
        this.timer = 30;
        this.pauseTimer();
      }
    }
  },
  mounted() {
    this.startTimer();
  },
  methods: {
    ...Vuex.mapActions(['setFilters']),
    startTimer() {
      this.interval = setInterval(() => {
        if (!this.timer) {
          this.timer = 30;
        }
        this.timer -= 1;
      }, 1000);
    },
    pauseTimer() {
      clearInterval(this.interval);
    }
  },
  template: `<div class="workload-timer">
    <slot v-if="active">
    <span class="fa fa-clock mr-1" />
    <strong class="mr-2" :style="getWarnColor">{{ getTimer }}</strong>
    </slot>
    <label class="ads-checkbox">
      <input type="checkbox" v-model="auto" />
      <span>{{ $t('autoReload') }}</span>
    </label>
  </div>`
});
