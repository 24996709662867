angular
  .module('postCenterWebClientApp')
  .directive('businessCrmDefinition', () => {
    return {
      templateUrl:
        'blocks/admin/views/integration_config/business_crm_definition.html',
      restrict: 'E',
      scope: {
        entity: '@',
        crmActive: '@'
      },
      controller: [
        '$scope',
        '$rootScope',
        '$filter',
        'AdNotification',
        'CrmService',
        function ($scope, $rootScope, $filter, AdNotification, CrmService) {

          if ($scope.crmActive === 'SALESFORCE') {
            $scope.crmActiveCapitalize = 'Salesforce';
          } else if ($scope.crmActive === 'HUBSPOT') {
            $scope.crmActiveCapitalize = 'HubSpot';
          } else {
            $scope.crmActiveCapitalize = ''
          }
          $scope.customData = $rootScope.customData.filter(
            ({entity, deleted, is_crm_integrable: integrable}) =>
              entity === $scope.entity.toUpperCase() && !deleted && integrable
          );

          $scope.saveKey = metadata => {
            metadata.saving = true;
            const {resource_id} = metadata;
            CrmService.editMetadataCrm(resource_id, metadata)
              .then(() => {
                AdNotification.notify(
                  $filter('translate')('ADMIN_INTEGRATION_SAVE_SUCCESS'),
                  'success'
                );
              })
              .catch(() => {
                AdNotification.notify(
                  $filter('translate')('ADMIN_INTEGRATION_SAVE_ERROR'),
                  'error'
                );
              })
              .finally(() => (metadata.saving = false));
          };

          $scope.validKeyIsFilled = ({crm_integration = {}}) => {
            const crmActive = $scope.crmActive.toLowerCase();
            return !crm_integration[`${crmActive}_id`];
          };

          $scope.noResultDescription = $filter('translate')(
            'INTEGRATION_CRM_NO_RESULT',
            {
              crmName: $scope.crmActiveCapitalize,
              crmPath:
                $scope.crmActive === 'SALESFORCE'
                  ? 'integraciones-salesforce'
                  : 'hubspot'
            }
          );
        }
      ]
    };
  });
