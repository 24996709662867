(module => {
  'use strict';
  ngTranslateCtrl.$inject = ['$scope', '$rootScope', '$translate', 'UserService'];
  module.controller('ngTranslateCtrl', ngTranslateCtrl);
  function ngTranslateCtrl($scope, $rootScope, $translate, UserService) {
    $scope.$on('$destroy', () => {
      $scope.changeLanguageOff();
      $scope.changeUserLanguageOff();
      $scope.selectedLanguageChangedOff();
    });

    $scope.languages = {
      Español: 'es',
      English: 'en'
    };
    $scope.languages = {...$scope.languages, Português: 'pt'};

    $scope.changeLanguageOff = $rootScope.$on(
      'changeLanguage',
      (event, langKey) => {
        $translate.use(langKey);
      }
    );

    $scope.changeUserLanguageOff = $rootScope.$on(
      'changeUserLanguage',
      (event, user, langKey) => {
        UserService.editUserLanguage(user, langKey).then(() => {
          $rootScope.$emit('changeLanguage', langKey);
        });
      }
    );

    $scope.selectedLanguageChangedOff = $rootScope.$on(
      'selectedLanguageChanged',
      () => {
        const {user} = $rootScope;
        const langKey = $scope.selectedLanguage;
        if (user && langKey) {
          $rootScope.$emit('changeUserLanguage', user, langKey);
        } else {
          new Error("Error in changing user's language");
        }
      }
    );
  }
})(angular.module('postCenterWebClientApp'));
