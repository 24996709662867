const i18nWorkload = new VueI18n({
  locale: 'es',
  fallbackLocale: ['es', 'en', 'pt'],
  silentTranslationWarn: true,
  messages: {
    es: {
      autoReload: 'Actualizar automáticamente',
      table: {
        agents: 'Ejecutivos',
        opened: 'Tickets Abiertos',
        awaitingResponse: 'Tickets que esperan respuesta',
        unanswered: 'Tickets sin abordar'
      },
      limits: {
        link: 'Ir a configuraciones de topes',
        active: 'Topes de asignación activado | Topes de asignación desactivado'
      },
      openedAwait: {
        title: 'Tickets abiertos y que esperan respuesta',
        desc: 'Relación de tickets asignados en comparación del tope de asignación que se ha establecido para cada Ejecutivo. Las barras de progreso se marcan en estado saturado o crítico cuando:'
      },
      awaitResponse: {
        title: 'Tickets que esperan respuesta',
        desc: 'Es el porcentaje de tickets esperando respuesta en comparación de los tickets abiertos. Las celdas se marcan en estado bajo o crítico cuando:'
      },
      assignMode: 'FIFO | LIFO',
      assignationModeOptions: [
        'Usan la configuración de la empresa',
        'Asignación FIFO',
        'Asignación FIFO exclusivo',
        'Asignación LIFO',
        'Asignación LIFO exclusivo'
      ],
      withoutAddressing: {
        title: 'Tickets sin abordar',
        percent: {
          low: 'Porcentaje sin abordar: <strong>entre {low}% y {high}%</strong>',
          high: 'Porcentaje sin abordar: <strong>mayor del {high}%</strong>'
        },
        desc: 'Es el porcentaje de tickets sin abordar en comparación de los tickets abiertos. Las celdas se marcan en estado bajo o crítico cuando:'
      },
      info: {
        lowPercentage:
          'Porcentaje de respuesta baja: <strong>entre {low}% y {high}%</strong>',
        criticalPercentage:
          'Porcentaje de respuesta critica: <strong>mayor del {high}%</strong>',
        moreThan: 'Mayor del {percent}',
        moreThanOrEqual: 'Mayor o igual a 100%',
        between: 'entre {firstPercent} y {secondPercent}',
        goToMonitoring: 'Ir a monitoreo',
        monitoring:
          'Puedes consultar más información en la sección de Monitoreo'
      }
    },
    en: {
      autoReload: 'Update automatically',
      table: {
        agents: 'Executives',
        opened: 'Open Tickets',
        awaitingResponse: 'Tickets awaiting response',
        unanswered: 'Tickets without boarding'
      },
      limits: {
        link: 'Go to cap settings',
        active: 'Allocation caps activated | Assignment stops disabled '
      },
      openedAwait: {
        title: 'Tickets open and awaiting response',
        desc: 'List of assigned tickets compared to the allocation ceiling that has been established for each Executive. Progress bars are marked in saturated or critical state when: '
      },
      awaitResponse: {
        title: 'Tickets awaiting response',
        desc: 'It is the percentage of tickets waiting for a response compared to open tickets. Cells are marked low or critical when: '
      },
      assignMode: 'FIFO | LIFO',
      assignationModeOptions: [
        'Use establishment mode',
        'Assignment FIFO',
        'Assignment FIFO exclusive',
        'Assignment LIFO',
        'Assignment LIFO exclusive'
      ],
      withoutAddressing: {
        title: 'Tickets without boarding',
        percent: {
          low: 'Percentage not addressed: <strong> between {low}% and {high}%</strong>',
          high: 'Percentage not addressed: <strong> greater than {high}%</strong>'
        },
        desc: 'It is the percentage of tickets not addressed compared to open tickets. Cells are marked low or critical when:'
      },
      info: {
        lowPercentage:
          'Low response percentage: <strong>between {low}% and {high}%</strong>',
        criticalPercentage:
          'Percentage of critical response: <strong>greater than {high}%</strong>',
        moreThan: 'Greater than {percent}',
        moreThanOrEqual: 'Greater than or equal to 100%',
        between: 'between {firstPercent} and {secondPercent}',
        goToMonitoring: 'Go to monitoring',
        monitoring: 'You can consult more information in the Monitoring section'
      }
    },
    pt: {
      autoReload: 'Atualizar automaticamente',
      table: {
        agents: 'Executivos',
        opened: 'Tickets Abertos',
        awaitingResponse: 'Tickets aguardando resposta',
        unanswered: 'Tickets sem embarque'
      },
      limits: {
        link: 'Ir para configurações de limite',
        active:
          'Limites de alocação ativados | Paradas de atribuição desativadas'
      },
      openedAwait: {
        title: 'Tickets abertos e aguardando resposta',
        desc: 'Lista de tickets atribuídos em comparação com o teto de alocação que foi estabelecido para cada executivo. As barras de progresso são marcadas em estado saturado ou crítico quando:'
      },
      awaitResponse: {
        title: 'Tickets aguardando resposta',
        desc: 'É a porcentagem de tíquetes esperando por uma resposta em comparação com tíquetes abertos. As células são marcadas como baixas ou críticas quando:'
      },
      assignMode: 'FIFO | LIFO',
      assignationModeOptions: [
        'Usam as configurações da empresa',
        'Atribuição FIFO',
        'Atribuição FIFO exclusivo',
        'Atribuição LIFO',
        'Atribuição LIFO exclusivo'
      ],
      withoutAddressing: {
        title: 'Tickets sem embarque',
        percent: {
          low: 'Porcentagem não endereçada: <strong>entre {low}% e {high}%</strong>',
          high: 'Porcentagem não endereçada: <strong>maior que {high}%</strong>'
        },
        desc: 'É a porcentagem de tickets não endereçados em relação aos tickets abertos. As células são marcadas como baixas ou críticas quando:'
      },
      info: {
        lowPercentage:
          'Baixo percentual de resposta: <strong>entre {low}% e {high}%</strong>',
        criticalPercentage:
          'Porcentagem de resposta crítica: <strong>maior que {high}%</strong>',
        moreThan: 'Maior que {percent}',
        moreThanOrEqual: 'Maior que ou igual a 100%',
        between: 'entre {firstPercent} e {secondPercent}',
        goToMonitoring: 'Ir para monitoramento',
        monitoring:
          'Você pode consultar mais informações na seção Monitoramento'
      }
    }
  }
});
