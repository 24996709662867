'use strict';

angular.module('postCenterWebClientApp')
  .factory('AdGlobalInformation', function(){
    return {
      androidVersions : androidVersions,
      countryCodes: countryCodes,
      getMaxProactiveMessagesPerMonth: getMaxProactiveMessagesPerMonth
    };

    function androidVersions() {
      return [
        {value: '6', name: 'Android 6 - Marshmallow'},
        {value: '7', name: 'Android 7 - Nougat'},
        {value: '8', name: 'Android 8 - Oreo'},
        {value: '9', name: 'Android 9 - Pie'},
        {value: 'iphone', name: 'Iphone'}
      ];
    }

    function countryCodes() {
      return [
        {value: '54', name: 'Argentina', id: 'ar54'},
        {value: '591', name: 'Bolivia', id: 'bo591'},
        {value: '55', name: 'Brasil', id: 'br55'},
        {value: '56', name: 'Chile', id: 'cl56'},
        {value: '57', name: 'Colombia', id: 'co57'},
        {value: '506', name: 'Costa Rica', id: 'cr506'},
        {value: '53', name: 'Cuba', id: 'cu53'},
        {value: '593', name: 'Ecuador', id: 'ec593'},
        {value: '503', name: 'El Salvador', id: 'sv03'},
        {value: '502', name: 'Guatemala', id: 'gt502'},
        {value: '509', name: 'Haití', id: 'ht509'},
        {value: '504', name: 'Honduras', id: 'hn504'},
        {value: '34', name: 'España', id: 'es34'},
        {value: '52', name: 'México', id: 'mx52'},
        {value: '505', name: 'Nicaragua', id: 'ni505'},
        {value: '507', name: 'Panamá', id: 'pa507'},
        {value: '595', name: 'Paraguay', id: 'py595'},
        {value: '51', name: 'Perú', id: 'pe51'},
        {value: '1809', name: 'República Dominicana (1-809)', id: 'do1809'},
        {value: '1829', name: 'República Dominicana (1-829)', id: 'do1829'},
        {value: '1849', name: 'República Dominicana (1-849)', id: 'do1849'},
        {value: '598', name: 'Uruguay', id: 'uy598'},
        {value: '58', name: 'Venezuela', id: 've58'}
      ];
    }

    function getMaxProactiveMessagesPerMonth() {
      // TODO generate endpoint to request this variable.
      return 1000;
    }

  });
