'use strict';

var app = angular.module('postCenterWebClientApp');
app.service('AdModal', function($uibModal, $timeout){

  function build(config, callbackOnSuccess, callbackOnOpen, callbackOnClose) {

    var customModal = $uibModal.open({
      templateUrl: config.template,
      controller: config.controller,
      size: config.size,
      resolve: config.resolve
    });

    customModal.result.then(function(callbackParams) {
      if (callbackOnSuccess) {
        callbackOnSuccess(callbackParams);
      }
    }, function (callbackParams) {
      if (callbackOnClose) {
        callbackOnClose(callbackParams);
      }
    });

    customModal.opened.then(function(callbackParams) {
      if (callbackOnOpen) {
        callbackOnOpen(callbackParams);
      }
    });

    return customModal;

  }

  function dismiss(modal) {
    if (modal) {
      $timeout(function(){
        modal.dismiss();
      }, 1);
    }
  }

  this.build = build;
  this.dismiss = dismiss;

});
