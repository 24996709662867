/* global i18nEpa epaStore */
(module => {
  'use strict';
  EpaBotCtrl.$inject = [
      "$scope",
      '$rootScope',
      "FRONTEND_VUE_URL",
      "$sce",
      "AdNotification",
      "AdRequest"
  ]
  module.controller('EpaBotCtrl', EpaBotCtrl);

  function EpaBotCtrl(
      $scope, $rootScope, FRONTEND_VUE_URL, $sce, AdNotification,
      AdRequest: AdRequestService
  ) {
      $rootScope.adminTab.selected = 'satisfaction';
      const vm = this;

      vm.$onInit = () => {
        window.addEventListener('message', handleIframeMessage);
      };

      vm.$onDestroy = () => {
          window.removeEventListener('message', handleIframeMessage);
      }

      $scope.iFrameUrl = function () {
          const token = AdRequest.getToken();
          const url = `${FRONTEND_VUE_URL}/frames/satisfaction?authToken=${token}`
          return $sce.trustAsResourceUrl(url);
      }

      function handleIframeMessage ({data}) {
        if (data.type !== 'satisfactionEvent') {
            return;
        }
        const {message} = data;
        const actionMap = {
            notify: notifyMessage,
            redirect: deskRedirect,
            newTab: redirectNewTab
        };
        const fun = actionMap[message.action];
        if (fun) fun(message);
      };

      function notifyMessage(message) {
          const {text: messageText, type} = message;
          if(type === 'success') {
              AdNotification.success(201, messageText);
          }
          else {
              AdNotification.error(500, messageText);
          }
      }

      function deskRedirect({url}) {
          window.location.href = url;
      }

      function redirectNewTab({url}) {
          window.open(url, "_blank")
      }
  }
})(angular.module('postCenterWebClientApp'))
