(module => {
  'use strict';
  assignmentTable.$inject = [];
  module.directive('assignmentTable', assignmentTable);
  function assignmentTable() {
    return {
      templateUrl: 'blocks/admin/views/assignment/assignment_table.html',
      restrict: 'E',
      scope: {
        legacy: '='
      },
      controller: 'AssignmentTableCtrl'
    };
  }
})(angular.module('postCenterWebClientApp'));
