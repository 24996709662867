const EmptyPoll = Vue.component('EmptyPoll', {
  props: {
    isSatisfactionModuleEnabled: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      benefits: [1, 2, 3]
    };
  },
  methods: {
    openContactPage() {
      const CONTACT_URL = 'https://adereso.ai/agendamiento/?utm_source=adereso_app&utm_medium=adereso_app&utm_campaign=solicitud_demo_epa_adereso_app';
      window.open(CONTACT_URL, '_blank');
    },
    adjustContainerHeight() {
      const container = document.querySelector<HTMLDivElement>('.empty-poll');
      const contentHeight = container.scrollHeight;
      container.style.height = contentHeight + 'px';
    }
  },
  mounted() {
    this.adjustContainerHeight();
    window.addEventListener('resize', this.adjustContainerHeight);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.adjustContainerHeight);
  },
  i18n: {
    messages: {
      es: {
        title: 'Crea y configura encuestas',
        benefits: [
          'Permite que tus clientes califiquen el servicio recibido.',
          'Revela áreas de oportunidad y potencia los aciertos.',
          'Favorece la fidelización y diferenciación de tu marca.'
        ],
        contactInfo: 'Para más detalles, puedes contactar a nuestros consultores'
      },
      pt: {
        title: 'Crie e configure pesquisas',
        benefits: [
          'Permita que seus clientes avaliem o serviço recebido.',
          'Revele áreas de oportunidade e aprimore os resultados.',
          'Favorece a fidelização e diferenciação da sua marca.'
        ],
        contactInfo: 'Para mais detalhes, você pode entrar em contato com nossos consultores'
      },
      en: {
        title: 'Create and configure surveys',
        benefits: [
          'Allow your customers to rate the service received.',
          'Reveal areas of opportunity and enhance hits.',
          'Promote loyalty and differentiation of your brand.'
        ],
        contactInfo: 'For more details, you can contact our consultants'
      }
    }
  },
  template: `<aside class="empty-poll">
              <h3>{{ $t('title') }}</h3>
              <img src="images/epa_empty.svg" :alt="$t('title')" width="80%" />
              <ul>
                <li v-for="(benefit, index) in benefits">
                  <p><b>{{ $t('benefits['+ index +']')}}</b></p>
                </li><br>
              </ul>
              <div v-if="!isSatisfactionModuleEnabled">
                <h4>{{ $t('contactInfo') }}</h4>
                <div>
                  <button
                    type="button"
                    class="btn ads-button ads-button--primary mt-3"
                    @click="openContactPage"
                  >
                    {{ $t('buttons.contactExecutive') }}
                  </button>
                </div>
              </div>
            </aside>`
});
