const Tag = Vue.component('Tag', {
  props: {
    text: {
      type: String,
      required: true
    },
    isShadow: {
      type: Boolean
    },
    contentFirst: {
      type: Boolean
    }
  },
  computed: {
    shadow() {
      return {
        shadow: this.isShadow
      };
    },
    direction() {
      return {
        'flex-row-reverse flex-end': this.contentFirst
      };
    }
  },
  template: `<div class="ads-tag d-flex" :class="{...shadow, ...direction}">
    <span class="text">{{text}}</span>
    <slot></slot>
  </div>`
});
