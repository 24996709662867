(module => {
    'use strict';
    WordCloudCtrl.$inject = [
        "$scope",
        "FRONTEND_VUE_URL",
        "$sce",
        'AdRequest'
    ]
    module.controller('WordCloudCtrl', WordCloudCtrl);

    function WordCloudCtrl(
        $scope, FRONTEND_VUE_URL, $sce, AdRequest
    ) {
        $scope.iFrameUrl = function () {
            const url = `${FRONTEND_VUE_URL}/frames/word_cloud?authToken=${AdRequest.getToken()}`
            return $sce.trustAsResourceUrl(url);
        }
    }
})(angular.module('postCenterWebClientApp'))