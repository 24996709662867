/* eslint-disable angular/di-unused */
'use strict';

angular
  .module('postCenterWebClientApp')
  .controller('AnalyticsDashboardCtrl', AnalyticsDashboardCtrl);

AnalyticsDashboardCtrl.$inject = [
  '$rootScope',
  '$scope',
  '$filter',
  '$interval',
  '$timeout',
  '$q',
  'AnalyticsDashboard',
  'Analytics',
  'localStorageService',
  'adExportToCsv',
  'FireTagService'
];

function AnalyticsDashboardCtrl(
  $rootScope,
  $scope,
  $filter,
  $interval,
  $timeout,
  $q,
  AnalyticsDashboard,
  Analytics,
  localStorageService,
  adExportToCsv,
  FireTagService
) {
  let initializing = true;

  const destroyables = {
    changeBTDestroy: undefined,
    changeAccountDestroy: undefined,
    changeRTDestroy: undefined,
    changeFilterByCreatedDestroy: undefined,
    availableTicketGroupsDestroy: undefined
  };
  FireTagService.setPageView({
    title: 'Dashboard analítica',
    path: `/${window.location.hash}`
  });
  $scope.$on('$destroy', function () {
    if ($scope.polling !== null) {
      $interval.cancel($scope.polling);
    }
    if (destroyables.changeBTDestroy) {
      destroyables.changeBTDestroy();
    }
    if (destroyables.changeAccountDestroy) {
      destroyables.changeAccountDestroy();
    }
    if (destroyables.changeRTDestroy) {
      destroyables.changeRTDestroy();
    }
    if (destroyables.changeFilterByCreatedDestroy) {
      destroyables.changeFilterByCreatedDestroy();
    }
    if (destroyables.availableTicketGroupsDestroy) {
      destroyables.availableTicketGroupsDestroy();
    }
  });

  $rootScope.analyticTab.selected = 'dashboard';

  $scope.kpis = {};
  $scope.showTicketsGroups = {};
  $scope.selectedAnyKpi = false;

  const defaultTab = $scope.user.isSupervisor ? 'executives' : 'resume';
  $scope.selectedTab = localStorage.getItem('kpi-dashboard-tab') || defaultTab;
  if (!_.contains(['resume', 'executives'], $scope.selectedTab)) {
    $scope.selectedTab = 'resume';
  }

  const storageDSelectedAccount = 'analytics:dashbrdSelectedAccount';
  $scope.selectedAccount = localStorageService.get(storageDSelectedAccount);
  const storageBTKey = 'analytics:onlyBusinessTimeOn';
  $scope.onlyBusinessTimeOn = localStorageService.get(storageBTKey) || false;
  const storageFilterKey = 'analytics:filterByCreated';
  $scope.filterByCreated = localStorageService.get(storageFilterKey) || false;

  $scope.multiSelectTranslations = {
    selectAll: $filter('translate')('MULTISELECT_SELECT_ALL'),
    selectNone: $filter('translate')('MULTISELECT_SELECT_NONE'),
    reset: $filter('translate')('MULTISELECT_RESET'),
    search: $filter('translate')('MULTISELECT_SEARCH'),
    nothingSelected: $filter('translate')('MULTISELECT_NOTHING_SELECTED')
  };

  let dashboardPeriod = null;

  function refreshSelectedPeriod() {
    let end = dashboardPeriod.endGetter();
    let start = dashboardPeriod.startGetter();
    // for histogram...
    const deltaDuration = moment.duration(end - start);
    let delta = deltaDuration.asMinutes();
    let granularity = 'm';
    if (deltaDuration.asHours() >= 6) {
      delta = deltaDuration.asHours();
      granularity = 'h';
    }
    if (deltaDuration.asDays() >= 7) {
      delta = deltaDuration.asDays();
      granularity = 'd';
    }
    if (deltaDuration.asMonths() >= 3) {
      delta = deltaDuration.asMonths();
      granularity = 'mo';
    }
    $scope.selectedPeriod = {
      now: end,
      until: start,
      delta: parseInt(delta),
      granularity: granularity
    };
  }

  function recalculateKPIs() {
    const disabled = _.filter($scope.availableTicketGroups, {
      ticked: false
    });
    _.each(disabled, function (kpiDef) {
      delete $scope.kpis[kpiDef.name];
      $scope.showTicketsGroups[kpiDef.name] = false;
    });
    const enabled = _.filter($scope.availableTicketGroups, {
      ticked: true
    });
    $scope.selectedAnyKpi = false;
    _.each(enabled, function (group) {
      $scope.showTicketsGroups[group.name] = true;
      $scope.selectedAnyKpi = true;
    });
  }

  function refreshKPIs() {
    recalculateKPIs();
    refreshSelectedPeriod();
    $scope.loadTabKPIs(true);
  }

  $scope.onPeriodUpdate = function (period) {
    dashboardPeriod = period;
    refreshKPIs();
  };

  // TICKETS KPIS SELECTION ------

  const ticketKpiDefs = {
    updated: {
      name: 'updated',
      ticked: true,
      label: $filter('translate')('ANALYTICS_DASHBOARD_TICKETS_UPDATED')
    },
    created: {
      name: 'created',
      ticked: true,
      label: $filter('translate')('ANALYTICS_DASHBOARD_TICKETS_CREATED')
    },
    closed: {
      name: 'closed',
      ticked: true,
      label: $filter('translate')('ANALYTICS_DASHBOARD_TICKETS_CLOSED')
    },
    ignored: {
      name: 'ignored',
      ticked: true,
      label: $filter('translate')('ANALYTICS_DASHBOARD_TICKETS_IGNORED')
    },
    opened: {
      name: 'opened',
      ticked: true,
      label: $filter('translate')('ANALYTICS_DASHBOARD_TICKETS_OPENED')
    },
    addressed: {
      name: 'addressed',
      ticked: true,
      label: $filter('translate')('ANALYTICS_DASHBOARD_TICKETS_ADDRESSED')
    },
    addressed_in_sla: {
      name: 'addressed_in_sla',
      ticked: true,
      label: $filter('translate')(
        'ANALYTICS_DASHBOARD_TICKETS_ADDRESSED_IN_SLA'
      )
    },
    important: {
      name: 'important',
      ticked: true,
      label: $filter('translate')('ANALYTICS_DASHBOARD_TICKETS_IMPORTANT')
    },
    proactive: {
      name: 'proactive',
      ticked: true,
      label: $filter('translate')('ANALYTICS_DASHBOARD_TICKETS_PROACTIVE')
    },
    classified: {
      name: 'classified',
      ticked: true,
      label: $filter('translate')('ANALYTICS_DASHBOARD_TICKETS_CLASSIFIED')
    },
    not_answered: {
      name: 'not_answered',
      ticked: true,
      label: $filter('translate')('ANALYTICS_DASHBOARD_TICKETS_NOT_ANSWERED')
    },
    not_answered_in_sla: {
      name: 'not_answered_in_sla',
      ticked: true,
      label: $filter('translate')(
        'ANALYTICS_DASHBOARD_TICKETS_NOT_ANSWERED_IN_SLA'
      )
    },
    no_client_reply: {
      name: 'no_client_reply',
      ticked: true,
      label: $filter('translate')('ANALYTICS_DASHBOARD_NO_CLIENT_REPLY')
    },
    finished: {
      name: 'finished',
      ticked: true,
      label: $filter('translate')('ANALYTICS_DASHBOARD_TICKETS_FINISHED')
    },
    manual_close: {
      name: 'manual_close',
      ticked: true,
      label: $filter('translate')('ANALYTICS_DASHBOARD_TICKETS_MANUAL_CLOSE')
    },
    messages_avg: {
      name: 'messages_avg',
      ticked: true,
      label: $filter('translate')('ANALYTICS_DASHBOARD_TICKETS_MESSAGES')
    },
    closed_time: {
      name: 'closed_time',
      ticked: true,
      label: $filter('translate')('ANALYTICS_DASHBOARD_TICKETS_CLOSED_TIME')
    },
    addressed_time: {
      name: 'addressed_time',
      ticked: true,
      label: $filter('translate')('ANALYTICS_DASHBOARD_TICKETS_ADDRESSED_TIME')
    },
    sentiments: {
      name: 'sentiments',
      ticked: true,
      label: $filter('translate')(
        'ANALYTICS_DASHBOARD_TICKETS_SENTIMENTS_TITLE'
      )
    }
  };

  const ticketGroupsDefs = {
    totals: {
      name: 'totals',
      ticked: true,
      label: $filter('translate')(
        'ANALYTICS_DASHBOARD_TICKETS_TOTAL_GROUP_TITLE'
      )
    },
    states: {
      name: 'states',
      ticked: true,
      label: $filter('translate')(
        'ANALYTICS_DASHBOARD_TICKETS_STATE_GROUP_TITLE'
      )
    },
    inSla: {
      name: 'inSla',
      ticked: true,
      label: $filter('translate')(
        'ANALYTICS_DASHBOARD_TICKETS_IN_SLA_GROUP_TITLE'
      )
    },
    extraInformation: {
      name: 'extraInformation',
      ticked: true,
      label: $filter('translate')(
        'ANALYTICS_DASHBOARD_TICKETS_EXTRA_GROUP_TITLE'
      )
    },
    averages: {
      name: 'averages',
      ticked: true,
      label: $filter('translate')(
        'ANALYTICS_DASHBOARD_TICKETS_AVERAGE_GROUP_TITLE'
      )
    },
    messagesKpi: {
      name: 'messagesKpi',
      ticked: true,
      label: $filter('translate')('ANALYTICS_DASHBOARD_MESSAGES_CONTENT_TITLE')
    },
    closeReason: {
      name: 'closeReason',
      ticked: true,
      label: $filter('translate')('ANALYTICS_DASHBOARD_CLOSE_REASON_TITLE')
    },
    sentiments: {
      name: 'sentiments',
      ticked: true,
      label: $filter('translate')(
        'ANALYTICS_DASHBOARD_TICKETS_SENTIMENTS_TITLE'
      )
    }
  };

  if ($rootScope.establishment.config.workflow_enabled) {
    ticketKpiDefs['in_progress'] = {
      name: 'in_progress',
      ticked: true,
      label: $filter('translate')('ANALYTICS_DASHBOARD_TICKETS_IN_PROGRESS')
    };
  }

  const storageTicketKpiDefList = 'analytics:dashboard:ticketKpiDefList';
  const storedTicketKpiDefList = localStorageService.get(
    storageTicketKpiDefList
  );
  (storedTicketKpiDefList || []).forEach(function (group) {
    if (group.name in ticketGroupsDefs) {
      ticketGroupsDefs[group.name].ticked = group.ticked;
      $scope.showTicketsGroups[group.name] = group.ticked;
    }
  });

  $scope.availableTicketGroups = _.values(ticketGroupsDefs);
  // Remove unselected kpis and reload selected kpis
  destroyables.availableTicketGroupsDestroy = $scope.$watch(
    'availableTicketGroups',
    function () {
      if (initializing) {
        $timeout(function () {
          initializing = false;
        });
      } else {
        recalculateKPIs();
        $scope.loadTabKPIs(true);
      }
      localStorageService.set(
        storageTicketKpiDefList,
        $scope.availableTicketGroups
      );
    },
    true
  );

  const changeBT = 'analytics:dashboard:changeBusinessTimeOn';
  destroyables.changeBTDestroy = $rootScope.$on(
    changeBT,
    function (event, onlyBusinessTimeOn) {
      $scope.onlyBusinessTimeOn = onlyBusinessTimeOn;
      refreshKPIs();
    }
  );

  const changeAccount = 'analytics:dashboard:changeAccount';
  destroyables.changeAccountDestroy = $rootScope.$on(
    changeAccount,
    function (event, selectedAccount) {
      $scope.selectedAccount = selectedAccount;
      refreshKPIs();
      event.stopPropagation();
    }
  );

  const changeRT = 'analytics:dashboard:changeRealTimeOn';
  destroyables.changeRTDestroy = $rootScope.$on(
    changeRT,
    function (event, realTimeOn) {
      if (realTimeOn) {
        turnOnPolling();
      } else {
        turnOffPolling();
      }
    }
  );

  const changeFilterByCreated = 'analytics:dashboard:changeFilterByCreated';
  destroyables.changeFilterByCreatedDestroy = $rootScope.$on(
    changeFilterByCreated,
    function (event, filterByCreated) {
      $scope.filterByCreated = filterByCreated;
      refreshKPIs();
    }
  );

  function turnOnPolling() {
    const secondsToRefresh = 45;
    if ($scope.polling !== null) {
      refreshKPIs();
      $scope.polling = $interval(refreshKPIs, secondsToRefresh * 1000);
    }
  }

  function turnOffPolling() {
    if ($scope.polling !== null) {
      $interval.cancel($scope.polling);
    }
  }

  $scope.loadTab = function (tabName) {
    if (!_.contains(['resume', 'executives', 'executiveDetail'], tabName)) {
      tabName = 'resume';
    }

    $scope.selectedTab = tabName;
    localStorage.setItem('kpi-dashboard-tab', $scope.selectedTab);
    $scope.$broadcast('analytic:dashboard:tabSelected', $scope.selectedTab);

    // full reload on tab selection
    if (initializing) {
      $timeout(function () {
        initializing = false;
      });
    } else {
      $scope.kpis = {};
      $scope.loadTabKPIs(false);
    }
  };

  $scope.subTab = 'kpi';
  $scope.loadSubTab = function (tabName) {
    if (!_.contains(['kpi', 'histogram'], tabName)) {
      tabName = 'kpi';
    }
    $scope.subTab = tabName;
  };

  $scope.loadTabKPIs = function (softLoading) {
    if ($scope.selectedTab === 'resume') {
      return loadResumeKpis(softLoading);
    } else {
      return null;
    }
  };

  let ticketLoadingLock = false;

  function loadResumeKpis(softLoading) {
    let value;
    if (ticketLoadingLock) {
      return;
    } else {
      ticketLoadingLock = true;
    }

    let query = {
      now: $scope.selectedPeriod.now,
      until: $scope.selectedPeriod.until,
      account: $scope.selectedAccount,
      inBusinessTime: $scope.onlyBusinessTimeOn,
      filterByCreated: $scope.filterByCreated
    };

    // always, others depend on them
    let updated = ticketKPIGenerator('updated', query);
    let opened = ticketKPIGenerator('opened', query);
    let addressed = ticketKPIGenerator('addressed', query);
    let created = ticketKPIGenerator('created', query);
    let finished = ticketKPIGenerator('finished', query);
    let totalTickets = updated;

    if ($scope.filterByCreated === 'true') {
      totalTickets = created;
    }

    if (ticketGroupsDefs['totals'].ticked) {
      loadKPI('created', created, softLoading);
      loadKPI('updated', updated, softLoading);
      value = ticketKPIGenerator('merged', query);
      loadKPI('merged', value, softLoading);
    }
    if (ticketGroupsDefs['states'].ticked) {
      let closed = ticketKPIGenerator('closed', query);
      loadPercentageKPI('closed', closed, totalTickets, softLoading);
      let ignored = ticketKPIGenerator('ignored', query);
      loadPercentageKPI('ignored', ignored, totalTickets, softLoading);
      loadPercentageKPI('opened', opened, totalTickets, softLoading);
      if ($rootScope.establishment.config.workflow_enabled) {
        value = ticketKPIGenerator('in_progress', query);
        loadPercentageKPI('in_progress', value, totalTickets, softLoading);
      }
    }
    if (ticketGroupsDefs['inSla'].ticked) {
      value = ticketKPIGenerator('not_answered_in_sla', query);
      loadPercentageKPI(
        'not_answered_in_sla',
        value,
        totalTickets,
        softLoading
      );
      value = ticketKPIGenerator('addressed_in_sla', query);
      loadPercentageKPI('addressed_in_sla', value, totalTickets, softLoading);
    }
    if (ticketGroupsDefs['extraInformation'].ticked) {
      loadPercentageKPI('addressed', addressed, totalTickets, softLoading);
      value = ticketKPIGenerator('classified', query);
      loadPercentageKPI('classified', value, totalTickets, softLoading);
      value = ticketKPIGenerator('important', query);
      loadPercentageKPI('important', value, totalTickets, softLoading);
      value = ticketKPIGenerator('not_answered', query);
      loadPercentageKPI('not_answered', value, totalTickets, softLoading);
      value = ticketKPIGenerator('proactive', query);
      loadPercentageKPI('proactive', value, totalTickets, softLoading);
    }
    if (ticketGroupsDefs['sentiments'].ticked) {
      let sentiments = ticketKPIGenerator('sentiments', query);
      loadKPI('sentiments', sentiments, softLoading);
    }
    if (ticketGroupsDefs['averages'].ticked) {
      value = ticketKPIGenerator('addressed_time', query);
      loadKPI('addressed_time', humanizeDuration(value), softLoading);
      value = ticketKPIGenerator('closed_time', query);
      loadKPI('closed_time', humanizeDuration(value), softLoading);
      value = ticketKPIGenerator('messages_avg', query);
      loadKPI('messages_avg', round(value), softLoading);
    }
    if (ticketGroupsDefs['closeReason'].ticked) {
      value = ticketKPIGenerator('no_client_reply', query);
      loadPercentageKPI('no_client_reply', value, finished, softLoading);
      value = ticketKPIGenerator('manual_close', query);
      loadPercentageKPI('manual_close', value, finished, softLoading);
    }
    if (ticketGroupsDefs['messagesKpi'].ticked) {
      query = {
        now: $scope.selectedPeriod.now,
        until: $scope.selectedPeriod.until,
        account: $scope.selectedAccount,
        inBusinessTime: $scope.onlyBusinessTimeOn
      };
      let received = messageKPIGenerator('received', query);
      loadKPI('received', received, softLoading);
      let sent = messageKPIGenerator('sent', query);
      loadKPI('sent', sent, softLoading);
      let answered = messageKPIGenerator('answered', query);
      loadPercentageKPI('answered', answered, received, softLoading);
      let answeredTime = messageKPIGenerator('answered_time', query);
      loadKPI('answered_time', humanizeDuration(answeredTime), softLoading);
    }
    ticketLoadingLock = false;
  }

  function ticketKPIGenerator(name, query) {
    return AnalyticsDashboard.ticketKPI(
      name,
      query.now,
      query.until,
      query.account,
      query.inBusinessTime,
      query.filterByCreated
    );
  }

  function messageKPIGenerator(name, query) {
    return AnalyticsDashboard.messageKPI(
      name,
      query.now,
      query.until,
      query.account,
      query.inBusinessTime
    );
  }

  function loadKPI(name, valuePromise, softLoading) {
    if (!_.has($scope.kpis, name)) {
      $scope.kpis[name] = {
        loading: true,
        value: null,
        error: null
      };
    }
    const kpi = $scope.kpis[name];
    kpi.softLoading = softLoading && !kpi.loading;
    kpi.loading = !softLoading || kpi.loading;
    valuePromise
      .then(function (value) {
        kpi.value = value;
        kpi.loading = false;
        kpi.softLoading = false;
      })
      .catch(function (error) {
        kpi.error = error;
        kpi.loading = false;
        kpi.softLoading = false;
      });
  }

  function loadPercentageKPI(name, valuePromise, totalPromise, softLoading) {
    if (!_.has($scope.kpis, name)) {
      $scope.kpis[name] = {
        loading: true,
        value: null,
        total: null,
        percent: null,
        error: null
      };
    }
    const kpi = $scope.kpis[name];
    kpi.softLoading = softLoading && !kpi.loading;
    kpi.loading = !softLoading || kpi.loading;
    $q.all([valuePromise, totalPromise])
      .then(function (results) {
        kpi.value = results[0];
        kpi.total = results[1];
        kpi.percent = getPercent(kpi.value, kpi.total);
        kpi.loading = false;
        kpi.softLoading = false;
      })
      .catch(function (error) {
        kpi.error = error;
        kpi.loading = false;
        kpi.softLoading = false;
      });

    function getPercent(segment, total) {
      return total <= 0 ? 0 : Math.round((segment / total) * 1000) / 10;
    }
  }

  function humanizeDuration(secondsPromise) {
    return secondsPromise.then(function (seconds) {
      return $filter('formatSeconds')(seconds, true);
    });
  }

  function round(numberPromise) {
    return numberPromise.then(function (number) {
      return Math.round(number);
    });
  }
  $scope.exportKpiSentiments = () => {
    const sentiments = $scope.kpis.sentiments.value;

    const title = $filter('translate')(
      'ANALYTICS_DASHBOARD_TICKETS_SENTIMENTS_TITLE'
    );
    const headers = {
      line: '-',
      negative: $filter('translate')('CASECONTROL_SENTIMENT_NEGATIVE'),
      neutral: $filter('translate')('CASECONTROL_SENTIMENT_NEUTRAL'),
      positive: $filter('translate')('CASECONTROL_SENTIMENT_POSITIVE')
    };
    const data = [
      {
        [headers.line]: headers.positive,
        [headers.negative]: sentiments[2][0],
        [headers.neutral]: sentiments[2][1],
        [headers.positive]: sentiments[2][2]
      },
      {
        [headers.line]: headers.neutral,
        [headers.negative]: sentiments[1][0],
        [headers.neutral]: sentiments[1][1],
        [headers.positive]: sentiments[1][2]
      },
      {
        [headers.line]: headers.negative,
        [headers.negative]: sentiments[0][0],
        [headers.neutral]: sentiments[0][1],
        [headers.positive]: sentiments[0][2]
      }
    ];
    adExportToCsv.exportToCsv(title, data);
    FireTagService.setEvent({
      name: 'exportacion_general',
      params: {
        export_name: title
      }
    });
  };
}
