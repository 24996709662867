rules.actions.Action = {

  subtypes: {},

  isTimepointsSurveySend: function () {
    return false;
  },
  isManualTimepointsSurvey: function () {
    return false;
  },
  isStartBotSession: function () {
    return false;
  },
  isEndBotSession: function () {
    return false;
  },

  isWebhookNotificationV2: function () {
    return false;
  },

  replaceWith: function (actionConstructor) {
    return new actionConstructor();
  },
  fillWithSendTimepointsSurvey: function (anotherAction) {
    return this
  },
  fillWithManualTimepointsSurvey: function (anotherAction) {
    return this
  },

  createActionType: rules.createClassFunction,

  parse: function (actionList) {
    var actionClass = this.subtypes[actionList[0]];
    if (angular.isUndefined(actionClass)) {
      return new actions.UnknownAction(actionList)
    }
    return actionClass.parse(actionList)
  }
};
var actions = rules.actions;

actions.UnknownAction = function (actionList) {
  this.identifier = actionList[0];
  this.action = actionList
};
actions.UnknownAction.prototype = actions.Action;

actions.EmptyAction = actions.Action.createActionType(
  'EMPTY_ACTION', {}, function () {}
);

actions.SlackSendMessageAction = actions.Action.createActionType(
  'SLACK_INTEGRATION_POST',
  {
    isSlackSendMessage: function () {
      return true;
    },
    serialize: function () {
      return [
        this.identifier,
        ['value', this.message],
        ['value', this.channel]
      ]
    }
  },
  function (message, channel) {
    this.message = message;
    this.channel = channel;
  }
);
actions.SlackSendMessageAction.parse = function (actionList) {
  return new actions.SlackSendMessageAction(actionList[1][1], actionList[2][1])
};

actions.SendTimepointsSurveyAction = actions.Action.createActionType(
  'SEND_TIMEPOINTS_SURVEY',
  {
    isTimepointsSurveySend: function () {
      return true;
    },
    serialize: function () {
      return [
        this.identifier,
        ['value', this.surveyNumber],
        ['value', this.delay],
        ['value', this.messageTemplate],
        ['value', this.spamThreshold]
      ]
    },
    replaceWith: function (actionConstructor) {
      var newAction = new actionConstructor();
      return newAction.fillWithSendTimepointsSurvey(this);
    },
    fillWithManualTimepointsSurvey: function (anotherAction) {
      this.surveyNumber = anotherAction.surveyNumber;
      this.messageTemplate = anotherAction.messageTemplate;
      this.spamThreshold = anotherAction.spamThreshold;
      return this
    }
  },
  function (surveyNumber, delay, messageTemplate, spamThreshold) {
    this.surveyNumber = surveyNumber;
    this.delay = delay;
    if (angular.isUndefined(messageTemplate)) {
      messageTemplate = '{URL}'
    }
    this.messageTemplate = messageTemplate;
    this.spamThreshold = spamThreshold;
  }
);

actions.SendTimepointsSurveyAction.parse = function (actionList) {
  return new actions.SendTimepointsSurveyAction(
    actionList[1][1],
    actionList[2][1],
    actionList[3][1],
    actionList[4][1]
  )
};

actions.WebhookNotificationAction = actions.Action.createActionType(
  'WEBHOOK_NOTIFICATION', {
    serialize: function() {return [this.identifier]}
  }, function () {}
);

actions.WebhookNotificationAction.parse = function (actionList) {
  return new actions.WebhookNotificationAction()
};


actions.WebhookNotificationActionV2 = actions.Action.createActionType(
  'WEBHOOK_NOTIFICATION_V2',
  {
    isWebhookNotificationV2: function () {
      return true;
    },
    serialize: function () {
      return [
        this.identifier,
        this.targetId
      ]
    }
  },
  function (targetId) {
    this.targetId = targetId;
  }
);

actions.WebhookNotificationActionV2.parse = function (actionList) {
  return new actions.WebhookNotificationActionV2(actionList[1])
};


actions.ChilquintaWebHook = actions.Action.createActionType(
  'CHILQUINTA_WEBHOOK', {
    serialize: function () {return [this.identifier]},
  }, function () {}
);

actions.ChilquintaWebHook.parse = function (actionList) {
  return new actions.ChilquintaWebHook();
};

actions.EnableManualTimepointsSurvey = actions.Action.createActionType(
  'ENABLE_MANUAL_TIMEPOINTS_SURVEY',
  {
    isManualTimepointsSurvey: function () {
      return true;
    },
    serialize: function () {
      return [
        this.identifier,
        ['value', this.surveyNumber],
        ['value', this.messageTemplate],
        ['value', this.spamThreshold]
      ]
    },
    replaceWith: function (actionConstructor) {
      var newAction = new actionConstructor();
      return newAction.fillWithManualTimepointsSurvey(this);
    },
    fillWithSendTimepointsSurvey: function (anotherAction) {
      this.surveyNumber = anotherAction.surveyNumber;
      this.messageTemplate = anotherAction.messageTemplate;
      this.spamThreshold = anotherAction.spamThreshold;
      return this
    }
  },
  function (surveyNumber, messageTemplate, spamThreshold) {
    this.surveyNumber = surveyNumber;
    if (angular.isUndefined(messageTemplate)) {
      messageTemplate = '{URL}'
    }
    this.messageTemplate = messageTemplate;
    this.spamThreshold = spamThreshold;
  }
);
actions.EnableManualTimepointsSurvey.parse = function (actionList) {
  return new actions.EnableManualTimepointsSurvey(
    actionList[1][1],
    actionList[2][1],
    actionList[3][1]
  )
};

actions.StartBotSessionAction = actions.Action.createActionType(
  'START_BOT_SESSION',
  {
    isStartBotSession: function () {
      return true;
    },
    serialize: function () {
      return [
        this.identifier,
        this.botConfigId
      ]
    }
  },
  function (botConfigId) {
    this.botConfigId = botConfigId;
  }
);
actions.StartBotSessionAction.parse = function (actionList) {
  return new actions.StartBotSessionAction(actionList[1])
};

actions.EndBotSessionAction = actions.Action.createActionType(
  'END_BOT_SESSION',
  {
    isEndBotSession: function () {
      return true;
    },
    serialize: function () {
      return [this.identifier]
    }
  },
  function () {}
);
actions.EndBotSessionAction.parse = function (actionList) {
  return new actions.EndBotSessionAction()
};

actions.RaiseStatusCodeAction = actions.Action.createActionType(
  'RAISE_STATUS_CODE',
  {
    isRaiseStatusCode: function () {
      return true;
    },
    serialize: function () {
      return [this.identifier, this.statusCode]
    }
  },
  function (statusCode) {
    this.statusCode = statusCode;
  }
);
actions.RaiseStatusCodeAction.parse = function (actionList) {
  return new actions.RaiseStatusCodeAction(actionList[1])
};
