const SectionTab = Vue.component('SectionTab', {
  computed: {
    ...Vuex.mapGetters('epaAnalytics', ['empty', 'activeTab'])
  },
  mounted() {
    this.getDataByTab();
  },
  methods: {
    ...Vuex.mapActions('epaAnalytics', ['getDataByTab'])
  },
  template: `<div class="px-1">
    <EmptyResults v-if="empty" />
    <slot v-else>
      <EpaTab
        :name="activeTab.group"
        v-if="activeTab.group !== 'detail'"
      />
      <EpaTabDetail v-else />
    </slot>
  </div>`
});
