'use strict';

angular.module('postCenterWebClientApp')
  .directive('adListfield', function () {
    return {
      scope: {
        list: '=',
        change: '&?',
        caseMetadataStyle: '&?',
      },
      templateUrl: 'blocks/global/views/adereso_listfield.html',
      restrict: 'E',
      controller: ['$scope', function ($scope) {

        $scope.caseMetadataStyle = $scope.caseMetadataStyle || function(){
          return false;
        };

        $scope.$watch('list', function(list, oldList){
          if(list === oldList){
            return;
          }
          if (list === undefined || list.length === 0) {
            $scope.list = [''];
          }
          else if($scope.change){
            $scope.change({'list': list});
          }
        }, true);

        this.isEmpty = function (list) {
          return list === undefined || list.length === 0;
        };

        if (this.isEmpty($scope.list)) {
          $scope.list = [''];
        }

        $scope.canAddRow = function () {
          if (angular.isUndefined($scope.list)) return false;
          if ($scope.list.length > 0) {
            var lastRow = $scope.list[$scope.list.length-1];
            return lastRow.length > 0;
          }
          return false;
        };

        $scope.canRemoveRow = function () {
          if (angular.isUndefined($scope.list)) return false;
          if ($scope.list.length === 0) return false;
          if ($scope.list.length === 1) return false;
          
          var lastRow = $scope.list[$scope.list.length-1];
          return lastRow.length === 0;
        };

        $scope.addRow = function () {
          $scope.list.push('');
        };

        $scope.removeRow = function () {
          $scope.list.pop();
        };

      }]
    };
  });
