'use strict';

angular.module('postCenterWebClientApp')
  .directive('newCaseModal', function () {
    return {
      restrict: 'E',
      templateUrl: 'blocks/crm/views/new_case_modal.html',
      controller: 'NewCaseModalCtrl'
    };
  });
