(module => {
  'use strict';
  cosAdminView.$inject = [];
  function cosAdminView() {
    return {
      templateUrl: 'blocks/admin/views/assignment/custom_online_status.html',
      restrict: 'E',
      scope: {},
      controller: 'COSAdminCtrl as vm'
    };
  }
  module.directive('cosAdminView', cosAdminView);
})(angular.module('postCenterWebClientApp'));
