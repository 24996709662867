/* eslint-disable angular/component-limit */
/* eslint-disable angular/di-unused */
'use strict';

var app = angular.module('postCenterWebClientApp');

function isEmpty(value) {
  return !value;
}

/**
 * NewEmailAccountCtrl
 */
app.controller('NewEmailAccountCtrl', function(
  $scope,
  $uibModalInstance,
  establishment,
  $filter,
  SocialAccount,
  SocialAccountService,
  AdNotification,
  adDataValidator,
  FireTagService
) {
  var vm = this;

  var MODAL_STATE_INITIAL = 'initial';
  var MODAL_STATE_FORWARDING = 'forwarding';

  vm.modalState = establishment.features.imap_available
    ? MODAL_STATE_INITIAL
    : MODAL_STATE_FORWARDING;
  vm.nextState = null;
  vm.isDone = false;

  vm.goToNextState = function() {
    vm.modalState = vm.nextState;
  };

  vm.goBackToInitial = function() {
    vm.modalState = MODAL_STATE_INITIAL;
  };

  vm.ok = function() {
    var isOk = true;
    vm.fullNameError = '';
    vm.emailError = '';

    if (isEmpty(vm.fullName)) {
      vm.fullNameError = $filter('translate')('EMPTY_FIELD_ERROR');
      isOk = false;
    }

    if (isEmpty(vm.email)) {
      vm.emailError = $filter('translate')('EMPTY_FIELD_ERROR');
      isOk = false;
    } else if (!adDataValidator.checkEmail(vm.email)) {
      vm.emailError = $filter('translate')('GENERAL_ENTER_VALID_EMAIL');
      isOk = false;
    }

    if (vm.modalState === 'imap') {
      if (isEmpty(vm.imapHost)) {
        vm.imapHostError = $filter('translate')(
          'ADD_EMAIL_ACCOUNT_EMPTY_HOST_ERROR'
        );
        isOk = false;
      }
      if (isEmpty(vm.imapPort)) {
        vm.imapPortError = $filter('translate')(
          'ADD_EMAIL_ACCOUNT_EMPTY_PORT_ERROR'
        );
        isOk = false;
      }
      if (isEmpty(vm.imapUsername)) {
        vm.imapUsernameError = $filter('translate')(
          'ADD_EMAIL_ACCOUNT_EMPTY_USERNAME_ERROR'
        );
        isOk = false;
      }
      if (isEmpty(vm.imapPassword)) {
        vm.imapPasswordError = $filter('translate')(
          'ADD_EMAIL_ACCOUNT_EMPTY_PASSWORD_ERROR'
        );
        isOk = false;
      }
    }

    if (isOk) {
      var data = {
        name: vm.fullName,
        email: vm.email,
        imap_host: vm.imapHost,
        imap_port: vm.imapPort,
        imap_ssl: !!vm.imapSsl,
        imap_username: vm.imapUsername,
        imap_password: vm.imapPassword,
        provider: 'email',
        type: vm.modalState === MODAL_STATE_FORWARDING ? 'email' : 'imap',
        create_column: true
      };

      vm.connecting = true;
      SocialAccountService.accessToken(data).then(
        response => {
          vm.connecting = false;
          // Validate first
          const shouldRedirect = vm.modalState === 'imap';
          SocialAccount.onChannelConnected(0, shouldRedirect);
          FireTagService.setEvent({
            name: 'conecta_cuenta',
            params: {
              channel_name: 'Email'
            }
          });

          vm.forwardEmail = response[0].config.forward_email;
          vm.isDone = true;
        },
        data => {
          vm.connecting = false;
          AdNotification.error(data, 'login_social_account');
        }
      );
    }
  };

  vm.cancel = function() {
    $uibModalInstance.dismiss('cancel');
  };

  vm.close = function() {
    $uibModalInstance.close();
  }
});

/**
 * NewPlayStoreCtrl
 */
app.controller('NewPlayStoreCtrl', function(
  $scope,
  $uibModalInstance,
  $filter
) {
  $scope.ok = function() {
    var isOk = true;
    $scope.appNameError = '';
    $scope.appCodeError = '';
    $scope.appPackageNameError = '';

    if (isEmpty($scope.appName)) {
      $scope.appNameError = $filter('translate')('EMPTY_FIELD_ERROR');
      isOk = false;
    }
    if (isEmpty($scope.appPackageName)) {
      $scope.appPackageNameError = $filter('translate')('EMPTY_FIELD_ERROR');
      isOk = false;
    }
    if (isEmpty($scope.appCode)) {
      $scope.appCodeError = $filter('translate')('EMPTY_FIELD_ERROR');
      isOk = false;
    }

    if (isOk) {
      var data = {
        app_name: $scope.appName,
        credentials: $scope.appCode,
        app_package: $scope.appPackageName,
        provider: 'play_store',
        type: 'play_store'
      };
      $uibModalInstance.close(data);
    }
  };

  $scope.cancel = function() {
    $uibModalInstance.dismiss('cancel');
  };
});

/**
 * NewTwitterSearchCtrl
 */

app.controller('NewTwitterSearchAccountCtrl', function(
  $scope,
  $uibModalInstance,
  $filter,
  SocialAccount
) {
  $scope.twitterOptions = SocialAccount.getSocialAccountsOptions(
    'twitter',
    'user'
  );

  $scope.ok = function() {
    var isOk = true;
    $scope.accountNameError = '';
    $scope.keywordsError = '';
    $scope.twitterAccountError = '';
    if (isEmpty($scope.accountName)) {
      $scope.accountNameError = $filter('translate')('EMPTY_FIELD_ERROR');
      isOk = false;
    }
    if (isEmpty($scope.keywords)) {
      $scope.keywordsError = $filter('translate')('EMPTY_FIELD_ERROR');
      isOk = false;
    }
    if (isEmpty($scope.twitterAccount) || $scope.twitterAccount === '?') {
      $scope.twitterAccountError = $filter('translate')('EMPTY_FIELD_ERROR');
      isOk = false;
    }

    if (isOk) {
      var data = {
        name: $scope.accountName,
        keywords: $scope.keywords,
        twitter_account: $scope.twitterAccount,
        provider: 'twitter',
        type: 'search'
      };
      $uibModalInstance.close(data);
    }
  };

  $scope.cancel = function() {
    $uibModalInstance.dismiss('cancel');
  };
});

/**
 * NewAppStoreCtrl
 */
app.controller('NewAppStoreCtrl', function($scope, $uibModalInstance, $filter) {
  $scope.ok = function() {
    var isOk = true;
    $scope.appNameError = '';
    $scope.appIdError = '';

    if (isEmpty($scope.appName)) {
      $scope.appNameError = $filter('translate')('EMPTY_FIELD_ERROR');
      isOk = false;
    }
    if (isEmpty($scope.appId)) {
      $scope.appIdError = $filter('translate')('EMPTY_FIELD_ERROR');
      isOk = false;
    }

    if (isOk) {
      var data = {
        app_name: $scope.appName,
        app_id: $scope.appId,
        provider: 'app_store',
        type: 'app_store'
      };
      $uibModalInstance.close(data);
    }
  };

  $scope.cancel = function() {
    $uibModalInstance.dismiss('cancel');
  };
});

/**
 * NewChatAccountCtrl
 */
app.controller('NewChatAccountCtrl', function(
  $scope,
  $uibModalInstance,
  $filter
) {
  $scope.ok = function() {
    var isOk = true;
    $scope.fullNameError = '';
    $scope.domainError = '';

    if (isEmpty($scope.fullName)) {
      $scope.fullNameError = $filter('translate')('EMPTY_FIELD_ERROR');
      isOk = false;
    }

    if (isEmpty($scope.domain)) {
      $scope.domainError = $filter('translate')('EMPTY_FIELD_ERROR');
      isOk = false;
    }

    if (isOk) {
      var data = {
        name: $scope.fullName,
        domain: $scope.domain,
        provider: 'chat',
        type: 'chat'
      };
      $uibModalInstance.close(data);
    }
  };

  $scope.cancel = function() {
    $uibModalInstance.dismiss('cancel');
  };
});

/**
 * NewFacebookAccountController
 */
app.controller('NewFacebookAccountController', NewFacebookAccountController);
NewFacebookAccountController.$inject = [
  '$scope',
  '$uibModalInstance',
  'SocialAccountService',
  'AdNotification',
  'token'
];

function NewFacebookAccountController(
  $scope,
  $uibModalInstance,
  SocialAccountService,
  AdNotification,
  token
) {
  var vm = this;

  vm.selectedAccount = false;
  vm.newAccounts = [];
  vm.loading = true;

  var tokenData = {
    access_token: token,
    provider: 'facebook'
  };

  SocialAccountService.accessToken(tokenData)
    .then(function(newAccounts) {
      vm.loading = false;
      vm.newAccounts = newAccounts;
    })
    .catch(function(error) {
      vm.loading = false;
      AdNotification.error(error, 'login_social_account');
      let detail = error;
      if (error.response && error.response.detail) {
        detail = error.response.detail;
      }
      throw new Error(`Error obtaining facebook account: ${detail}`);
    });

  vm.ok = function() {
    $uibModalInstance.close([vm.selectedAccount]);
  };

  vm.cancel = function() {
    $uibModalInstance.dismiss('cancel');
  };

  vm.showChannelOptions = false;
}

/**
 * NewInstagramAccountController
 */
app.controller('NewInstagramAccountController', NewInstagramAccountController);
NewInstagramAccountController.$inject = [
  '$scope',
  '$uibModalInstance',
  'SocialAccountService'
];

function NewInstagramAccountController(
  $scope,
  $uibModalInstance,
  SocialAccountService
) {
  $scope.selectedAccount = {
    account: undefined
  };
  $scope.newAccounts = [];
  $scope.loading = true;

  SocialAccountService.getAvailableInstagram().then(accounts => {
    $scope.newAccounts = accounts;
    $scope.loading = false;
  });

  $scope.ok = function() {
    $uibModalInstance.close($scope.selectedAccount.account);
  };

  $scope.cancel = function() {
    $uibModalInstance.dismiss('cancel');
  };
}

class SocialAccountTab {
  title: string
  socialNetwork: string
  type: string
  maxAccounts: number
  globalOptions: boolean = false
  route: string
  refreshList: () => null
  createClosedCases: boolean = false
  socialAccounts: any[] = []
  alert: boolean = false
  
  constructor(
    title, socialNetwork, type, maxAccounts, route, refreshList
  ) {
    this.title = title
    this.socialNetwork = socialNetwork
    this.type = type
    this.maxAccounts = maxAccounts
    this.route = route
    this.refreshList = refreshList
  }
}

class EmailAccountsTab extends SocialAccountTab {
  max_domains: number
  domains: string[]

  constructor(
    title, socialNetwork, type, maxAccounts, route, refreshList,
    maxDomains, domains
  ) {
    super(title, socialNetwork, type, maxAccounts, route, refreshList)
    this.max_domains = maxDomains
    this.domains = domains
  }
}

class TwitterAccountsTab extends SocialAccountTab {
  globalOptions: boolean = true
}

class AppStoreAccountsTab extends SocialAccountTab {
  createClosedCases: boolean = true
}

/**
 *SocialAccountsCtrl
 */
app.controller('SocialAccountsCtrl', function(
  $scope,
  $rootScope,
  $timeout,
  $filter,
  AdNotification,
  FireTagService,
  EstablishmentService: EstablishmentService,
  SocialAccountService: SocialAccountService
) {
  FireTagService.setPageView({
    title: 'Canales',
    path: `/${window.location.hash}`
  });
  $rootScope.adminTab.selected = 'social_accounts';

  let accountsSubscription

  $scope.$on('$destroy', function() {
    if ($scope.updateAccountsPromise) {
      $timeout.cancel($scope.updateAccountsPromise);
    }
    if ($scope.polling) {
      $scope.polling.stop();
    }
    if (accountsSubscription) {
      accountsSubscription.unsubscribe()
    }
  })

  $scope.tabs = [];
  $scope.externalChannel = '';

  EstablishmentService.getEstablishment().then(establishment => {
    $scope.establishment = establishment
    $scope.tabs = [
      new SocialAccountTab(
        'WhatsApp',
        'whatsapp',
        'aplication',
        establishment['max_whatsapp_accounts'],
        'admin.channels.whatsapp',
        refreshData
      ),
      new SocialAccountTab(
        'Facebook',
        'facebook',
        'page',
        establishment['max_facebook_accounts'],
        'admin.channels.facebook',
        refreshData
      ),
      new SocialAccountTab(
        'Instagram',
        'instagram',
        'page',
        establishment['max_instagram_accounts'],
        'admin.channels.instagram',
        refreshData
      ),
      new SocialAccountTab(
        'Chat',
        'chat',
        'chat',
        establishment['max_chat_accounts'],
        'admin.channels.chat',
        refreshData
      ),
      new SocialAccountTab(
        'LinkedIn',
        'linkedin',
        'aplication',
        establishment['max_whatsapp_accounts'],
        'admin.channels.linkedin',
        refreshData
      ),
      new EmailAccountsTab(
        'Email',
        'email',
        undefined,
        establishment['max_email_accounts'],
        'admin.channels.email',
        refreshData,
        establishment['max_email_domains'],
        []
      ),
      new TwitterAccountsTab(
        'Twitter',
        'twitter',
        'user',
        establishment['max_twitter_accounts'],
        'admin.channels.twitter',
        refreshData
      ),
      new SocialAccountTab(
        'Twitter Search',
        'twitter',
        'search',
        establishment['max_twitter_search_accounts'],
        'admin.channels.twitter_search',
        refreshData
      ),
      new SocialAccountTab(
        $scope.externalChannel = $filter('translate')('EXTERNAL_CHANNEL'),
        'external',
        undefined,
        -1,
        'admin.channels.external',
        refreshData
      )
    ]
    if (establishment.is_corporate) {
      $scope.tabs.push(
        new AppStoreAccountsTab(
          'App Store',
          'app_store',
          'aplication',
          establishment['max_app_store_accounts'],
          'admin.channels.app_store',
          refreshData
        )
      )
      $scope.tabs.push(
        new SocialAccountTab(
          'Play Store',
          'play_store',
          'aplication',
          establishment['max_play_store_accounts'],
          'admin.channels.play_store',
          refreshData
        )
      )
    }

    accountsSubscription = SocialAccountService.accountsObservable.subscribe(accounts => {
      $scope.nonAccounts = false
      $scope.tabs.map(tab => {
        tab.alert = false;
      })
      if (accounts.length > 0) {
        setSocialAccounts(accounts)
        $scope.tabs.forEach(tab => {
          if(tab.socialAccounts.some(account => !account.isHealthy)) {
            tab.alert = true;
          }
          tab.hidden = hideExternalIfNoAccounts(tab) || hideStoresIfNotCorporate(tab)
        })
      } else {
        $scope.nonAccounts = true;
        $scope.label = $filter('translate')('SOCIALACCOUNTS_WITHOUT_ACCOUNTS')
      }
      $scope.list = true
    })
  })

  function hideExternalIfNoAccounts(tab) {
    return tab.socialNetwork == 'external' && tab.socialAccounts.length == 0
  };

  function hideStoresIfNotCorporate(tab) {
    let isStore = ['app_store', 'play_store'].includes(tab.socialNetwork)
    return isStore && !$scope.establishment.is_corporate
  };

  function setSocialAccounts(accounts: ExtendedSocialAccount[]) {
    $scope.socialAccounts = {};
    $scope.hasFailingAccounts = false;
    $scope.failingAccounts = [];

    angular.forEach(accounts, function(socialAccount) {
      socialAccount = angular.copy(socialAccount);

      if (angular.isUndefined($scope.socialAccounts[socialAccount.sn])) {
        $scope.socialAccounts[socialAccount.sn] = {};
      }
      if (
        angular.isUndefined(
          $scope.socialAccounts[socialAccount.sn][socialAccount.type]
        )
      ) {
        $scope.socialAccounts[socialAccount.sn][socialAccount.type] = [];
      }

      if (!socialAccount.isHealthy) {
        $scope.hasFailingAccounts = true;
        $scope.failingAccounts.push(socialAccount);
      }

      $scope.socialAccounts[socialAccount.sn][socialAccount.type].push(
        socialAccount
      );
    });

    angular.forEach($scope.tabs, function(tab) {
      if (angular.isUndefined($scope.socialAccounts[tab.socialNetwork])) {
        tab.socialAccounts = [];
      } else {
        if (angular.isUndefined(tab.type)) {
          tab.socialAccounts = [].concat.apply(
            [],
            Object.values($scope.socialAccounts[tab.socialNetwork])
          );
        } else {
          let accounts = $scope.socialAccounts[tab.socialNetwork][tab.type]
          if (accounts) {
            tab.socialAccounts = accounts
          }
        }
      }
    });
  }

  function refreshData() {
    SocialAccountService.refreshSocialAccounts().catch(
      error => AdNotification.error(error, 'get_social_accounts')
    )
  }
})
