class AssignmentService {
  constructor(AdRequest, $rootScope) {
    this.AdRequest = AdRequest;
    this.$rootScope = $rootScope;
  }
  openConfig() {
    this.$rootScope.assignmentOpenConfig = true;
    this.$rootScope.$apply();
  }

  checkOpenTickets() {
    return this.AdRequest.get('/api/v1/assignation/check/');
  }

  getHistory(page = 0) {
    return this.AdRequest.get(`/api/v1/assignation/history/${page}/`);
  }

  getRebalance(data) {
    return this.AdRequest.post('/api/v1/assignation/rebalance/', data);
  }
  getHistoryRebalance(page = 0) {
    return this.AdRequest.get(
      `/api/v1/assignation/history_re_balance/${page}/`
    );
  }
  saveAssignment(data = []) {
    return this.AdRequest.put('/api/v1/assignation/', data);
  }
  saveExecutive(executive = {}) {
    const preparedData = this.prepareExecutiveData(executive);
    return this.AdRequest.put('/api/v1/assignation/single/', preparedData);
  }
  getAssignmentSummary() {
    return this.AdRequest.get('/api/v1/assignation/status_detail/');
  }
  updateExecutiveLimits(executive = {}, limits = {}) {
    return this.AdRequest.post('/api/v1/assignation/limit/update/', {
      cmid: executive.cmid,
      total_workload: limits.total_workload,
      unanswered_workload: limits.unanswered_workload,
      using_establishment_default:
        executive.assignation_limit.using_establishment_default
    });
  }
  prepareExecutiveData(executive = {}) {
    const activeAccounts = executive.accounts.filter(
      account => account.mode.id > 0
    );

    return {
      cmid: executive.cmid,
      assignation_config: executive.assignation_config,
      is_assignable: executive.isAssignable,
      assignable_accounts: activeAccounts.map(account => account.id),
      assignations: executive.accounts.map(account => ({
        id: account.id,
        mode: account.mode.id
      })),
      profile: {
        assignation_workload: executive.assignation_workload,
        assignation_limit: executive.assignation_limit
      }
    };
  }
}
angular
  .module('postCenterWebClientApp')
  .service('AssignmentService', AssignmentService);
