(function(module) {
  'use strict';
  WhatsappNewAccountCtrl.$inject = [
    '$scope',
    '$uibModalInstance',
    '$filter',
    'AdNotification',
    'SocialAccountService',
    'AdRequest',
    'AdGlobalInformation'
  ];
  function WhatsappNewAccountCtrl(
    $scope,
    $uibModalInstance,
    $filter,
    AdNotification,
    SocialAccountService,
    AdRequest,
    AdGlobalInformation
  ) {
    $scope.countryCodes = AdGlobalInformation.countryCodes();
    $scope.androidVersions = AdGlobalInformation.androidVersions();

    $scope.state = {
      pendingRequest: false,
      lastAttempt: null,
      method: null,
      result: 'pending',
      retryAfter: 0
    };

    $scope.data = {
      provider: 'whatsapp',
      cc: 0,
      mnc: 0,
      phone: null,
      name: null,
      voice: false,
      phone_model: null,
      phone_brand: null,
      phone_android: null
    };

    function whatsappDataValidate() {
      var validData = true;

      function validCountryCode() {
        var valid = true;
        if (
          angular.isDefined($scope.data.cc) &&
          angular.isObject($scope.data.cc)
        ) {
          var cc = angular.copy($scope.data.cc);
          if (!(angular.isDefined(cc.id) && angular.isDefined(cc.value))) {
            valid = false;
          }
        } else {
          valid = false;
        }
        return valid;
      }

      function validNumber() {
        var phone = $scope.data.phone;
        if (!angular.isDefined(phone) || !angular.isString(phone)) {
          return false;
        }

        phone = phone.replace(/\D/g, '');
        if (phone.length < 4) {
          return false;
        }
        if ($scope.data.cc.value === '56' && phone.length !== 9) {
          return false;
        }

        $scope.data.phone = phone;
        return true;
      }

      function validTextInput(input) {
        return input != null && input.length !== 0;
      }

      if (
        !validCountryCode() ||
        !validNumber() ||
        !validTextInput($scope.data.name) ||
        !validTextInput($scope.data.phone_model) ||
        !validTextInput($scope.data.phone_brand) ||
        !validTextInput($scope.data.phone_android)
      ) {
        validData = false;
      }

      return validData;
    }

    function whatsappDataNormalize(whatsappData) {
      var data = angular.copy(whatsappData);
      data.cc = data.cc && data.cc.value ? data.cc.value : data.cc;
      data.mnc = data.mnc && data.mnc.value ? data.mnc.value : data.mnc;
      return data;
    }

    function whatsappConnectAccount(callback) {
      var state = $scope.state;
      if (!state.pendingRequest) {
        state.pendingRequest = true;
        var data = whatsappDataNormalize($scope.data);

        SocialAccountService.accessToken(data).then(
          function(response) {
            typeof callback === 'function' && callback(false, response);
            state.pendingRequest = false;
          },
          function(exception) {
            typeof callback === 'function' && callback(true, exception);
            state.pendingRequest = false;
          }
        );
      }
    }

    function getModalCallbackParams() {
      var data = {
        whatsapp: $scope.data,
        whatsappState: $scope.state
      };
      return data;
    }

    $scope.canCreate = function() {
      return whatsappDataValidate();
    };

    $scope.intentResultStatus = '';

    $scope.addAccount = function() {
      var fullPhone = $scope.data.cc.value + $scope.data.phone;
      var data = {
        name: $scope.data.name,
        phone_model: $scope.data.phone_model,
        phone_brand: $scope.data.phone_brand,
        phone_android: $scope.data.phone_android
      };
      return AdRequest.post('/api/v1/whatsapp/' + fullPhone, data).then(
        function(response) {
          $uibModalInstance.close(response);
        },
        function(error) {
          AdNotification.error(error, 'whatsapp_account');
        }
      );
    };

    $scope.legacyAddAccount = function(retries) {
      var state = $scope.state;

      function setState(stateData) {
        state.lastAttempt = stateData['registration_last_attempt'];
        state.method = stateData['registration_method'];
        state.result = stateData['registration_status'];
        state.retryAfter = stateData['retry_after'];
        state.reason = stateData['registration_reason'];
      }

      whatsappConnectAccount(function(operationError, data) {
        var errorConstant = 'SOCIALACCOUNTS_WHATSAPP_CONNECT_FAILED';
        var errorExistsConstant =
          'SOCIALACCOUNTS_WHATSAPP_CONNECT_ALREADY_EXISTS';
        var errorStatus = ['ERROR_CREATING_INSTANCE', 'MACHINE_ALREADY_EXISTS'];
        if (!operationError && data.machine) {
          setState(data.statuses);
          var status = data.machine.status;
          if (errorStatus.indexOf(status) > -1) {
            if (retries > 0) {
              $scope.intent(retries - 1);
            } else {
              message = $filter('translate')(errorConstant);
              AdNotification.notify(data, 'error');
            }
          } else {
            // Request is ok!
            $uibModalInstance.close(getModalCallbackParams());
          }
        } else {
          if (data.response.indexOf('Account already exists') > -1) {
            var message = $filter('translate')(errorExistsConstant);
            AdNotification.notify(message, 'error');
          } else {
            var message = $filter('translate')(errorConstant);
            AdNotification.notify(message, 'error');
          }
        }
      });
    };

    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };

    $scope.resetCountry = function() {
      $scope.data.mnc = 0;
      $scope.data.cc = 0;
    };
  }
  module.controller('WhatsappNewAccountCtrl', WhatsappNewAccountCtrl);
})(angular.module('postCenterWebClientApp'));
