(function(module) {
  'use strict';
  caseSideViewComments.$inject = [];
  function caseSideViewComments() {
    return {
      templateUrl: 'blocks/case/views/case_side_view_comments.html',
      controller: 'caseSideViewCommentsCtrl',
      controllerAs: 'vm',
      scope: {
        caseObject: '='
      },
      bindToController: true,
      require: '^^caseSideView'
    };
  }
  module.directive('caseSideViewComments', caseSideViewComments);
})(angular.module('postCenterWebClientApp'));
