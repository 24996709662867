(module => {
  'use strict';
  DashboardCtrl.$inject = [
    '$scope',
    '$rootScope',
    'UserService',
    '$uibModal',
    'FireTagService',
    'FRONTEND_VUE_URL',
    '$sce',
    'AdRequest',
    '$location'
  ];
  function DashboardCtrl(
    $scope,
    $rootScope,
    UserService,
    $uibModal,
    FireTagService,
    FRONTEND_VUE_URL,
    $sce,
    AdRequest,
    $location
  ) {
    FireTagService.setPageView({
      title: 'Cuenta',
      path: `/${window.location.hash}`
    });
    $rootScope.adminTab.selected = 'dashboard';
    UserService.getProfile().then(user => $scope.myself = user)
    $scope.exportedColumns = [];

    $scope.iFrameUrl = function () {
      const token = AdRequest.getToken()
      let section = $location.search().section;
      let url = `${FRONTEND_VUE_URL}/frames/billing?authToken=${token}`
      if (section) {url += `&section=${section}`}
      return $sce.trustAsResourceUrl(url);
    }

    $scope.showTokenOnModal = function (token) {
      $scope.token = token;

      $scope.tokenModal = $uibModal.open({
        templateUrl: 'blocks/admin/views/token_modal.html',
        scope: $scope,
        size: 'lg'
      });

      $scope.tokenModal.result.finally(() => {
        modalScope.$destroy();
      });
    };
  }
  module.controller('DashboardCtrl', DashboardCtrl);
})(angular.module('postCenterWebClientApp'));
