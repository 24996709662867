// Prefer this helper over the older AdPolling service!
class AdPollingHelper {

  public static pollWhile<T>(
    pollInterval: number,
    isPollingActive: (res: T) => boolean
  ): rxjs.MonoTypeOperatorFunction<T> {
    return source$ => {
      const poll$ = rxjs.timer(pollInterval, pollInterval).pipe(
        rxjs.switchMap(() => source$),
        rxjs.takeWhile(isPollingActive, true)
      );
      return poll$;
    };
  }
}

angular
.module('postCenterWebClientApp')
.service('AdPolling', function Polling($timeout) {
  const PollingService = this;
  this.idleFactor = 1;
  this.idleFactorLimit = 3;
  this.secondsToIdle = 60 * 10;

  function Poller(getter, seconds, onSuccess, onError, query, id) {
    this.getter = getter;
    this.query = query;
    this.respawnIn = seconds * 1000;
    this.onSuccess = onSuccess;
    this.onError = onError;
    this.stopped = false;
    if (id === undefined) {
      this.id = Math.round(Math.random() * 10000);
    } else {
      this.id = id;
    }

    const self = this;

    self.poll = function() {
      const respawn = self.respawnIn * PollingService.idleFactor;
      self.getter(self.query).then(
        response => {
          self.onSuccess(response);
          if (!self.stopped) {
            self.fetcher = $timeout(self.poll, respawn);
          }
        },
        error => {
          self.onError(error);
          if (!self.stopped) {
            self.fetcher = $timeout(self.poll, respawn * 1.5);
          }
        }
      );
    };

    self.run = function() {
      self.getter(self.query).then(
        response => {
          self.onSuccess(response);
        },
        error => {
          self.onError(error);
        }
      );
    };

    self.stop = function() {
      self.stopped = true;
      if (self.fetcher) {
        return $timeout.cancel(self.fetcher);
      }
    };

    self.start = function() {
      self.poll();
      return self;
    };

    self.upload = function(getter, seconds, onsucces, onerror, query) {
      self.stop();
      self.getter = getter;
      self.respawnIn = seconds * 1000;
      self.onSuccess = onsucces;
      self.onError = onerror;
      self.query = query;
      self.stopped = false;
      self.poll();
    };

    self.lightPoll = function() {
      const respawn = self.respawnIn * PollingService.idleFactor;
      self.fetcher = $timeout(self.poll, respawn);
    };

    self.lightUpload = function(getter, seconds, onsucces, onerror, query) {
      self.stop();
      self.getter = getter;
      self.respawnIn = seconds * 1000;
      self.onSuccess = onsucces;
      self.onError = onerror;
      self.query = query;
      self.stopped = false;
      self.lightPoll();
    };
  }

  this.start = function(getter, seconds, onsucces, onerror, query, name) {
    query = typeof query !== 'undefined' ? query : '';
    return new Poller(
      getter,
      seconds,
      onsucces,
      onerror,
      query,
      name
    ).start();
  };
});
