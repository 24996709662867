'use strict';

angular
  .module('postCenterWebClientApp')
  .directive('screenLock', function () {
    return {
      scope: {
        reason: '=',
        userStatus: '=',
      },
      templateUrl: 'blocks/top_bar/views/screen_lock.html',
      restrict: 'E',
      controller: ['$scope', '$rootScope', '$interval', screenLockController],
      controllerAs: 'vm'
    };
  });

function screenLockController($scope, $rootScope, $interval) {
  var format = 'HH:mm:ss';
  var vm = this;
  vm.duration = '';

  var startTime = new Date();
  var elapsedTime = 0; 

  function updateTime() {
    var currentTime = new Date();
    elapsedTime = Math.floor((currentTime - startTime) / 1000);

    var duration = moment.duration(elapsedTime, 'seconds');
    vm.duration = moment.utc(duration.asMilliseconds()).format(format);
  }

  updateTime();
  var stopTime = $interval(updateTime, 1000);

  $scope.$on('$destroy', function () {
    $interval.cancel(stopTime);
  });

  $scope.$watch('userStatus.custom_online_status', function () {
    startTime = new Date(); 
    elapsedTime = 0; 
    updateTime();
  }, true);
}
