app.factory('wootricFactory', [
  'WOOTRIC_TOKEN',
  'ENV',
  function (WOOTRIC_TOKEN, ENV) {
    return {
      run: function (email, created_at) {
        if (ENV == 'prod') {
          window.wootricSettings = {
            email: email,
            created_at: created_at,
            account_token: WOOTRIC_TOKEN
          };

          window.wootric('run');
        }
      }
    };
  }
]);
