'use strict';

var app = angular.module('postCenterWebClientApp');

app.directive('richMessage', function () {
  return {
    templateUrl: 'blocks/message/views/rich_message/rich_message.html',
    restrict: 'E',
    scope: {
      message: '='
    },
    controller: ['$scope', function ($scope) {
      $scope.component = $scope.message.component;
    }]
  };
});

var ComponentsController = ['$scope', '$window', function ($scope, $window) {

  var openTab = function (url) {
    $window.open(url, '_blank');
  };

  $scope.buttonClicked = function (button) {
    var action = button.action;

    if (action.type == 'url') {
      openTab(action.url)
    }
    else if (action.type == 'postback') {
    }
  };

  $scope.elementClicked = function (element) {
    var action = element.action;

    if (action != null) {
      $scope.buttonClicked(element)
    }
  };

}];

app.directive('carouselComponent', function () {
  return {
    templateUrl: 'blocks/message/views/rich_message/carousel_component.html',
    restrict: 'E',
    scope: {
      component: '='
    },
    controller: ComponentsController
  };
});

app.directive('listComponent', function () {
  return {
    templateUrl: 'blocks/message/views/rich_message/list_component.html',
    restrict: 'E',
    scope: {
      component: '='
    },
    controller: ComponentsController
  };
});

app.directive('buttonsComponent', function () {
  return {
    templateUrl: 'blocks/message/views/rich_message/buttons_component.html',
    restrict: 'E',
    scope: {
      component: '='
    },
    controller: ComponentsController
  };
});

app.directive('quickReplyComponent', function () {
  return {
    templateUrl: 'blocks/message/views/rich_message/quick_reply.html',
    restrict: 'E',
    scope: {
      component: '='
    },
    controller: ComponentsController
  }
});
