/**
 * Grid-light theme for Highcharts JS
 * @author Torstein Honsi
 */

Highcharts.theme = {
  // old template
  //colors:  ['#FF5722', '#FDD835', '#00796B', '#FF9800', '#AFB42B', '#0097A7',
  //           '#7CB342', '#00796B', '#303F9F', '#7B1FA2', '#CDDC39'],
  colors: [
    '#06A955',
    '#7694FF',
    '#FFC107',
    '#F44336',
    '#00B0FF',
    '#CFD8DC',
    '#78909C',
    '#455A64',
    '#263238',
    '#BA68C8',
    '#FFAB91'
  ],
  chart: {
    backgroundColor: null,
    style: {
      fontFamily: 'Lato, sans-serif'
    }
  },
  title: {
    style: {
      fontSize: '16px',
      fontWeight: 'bold',
      textTransform: 'uppercase'
    }
  },
  tooltip: {
    borderWidth: 0,
    backgroundColor: 'rgba(219,219,216,0.8)',
    shadow: false
  },
  legend: {
    itemStyle: {
      fontWeight: 'bold',
      fontSize: '13px'
    }
  },
  xAxis: {
    gridLineWidth: 1,
    labels: {
      style: {
        fontSize: '12px'
      }
    }
  },
  yAxis: {
    minorTickInterval: 'auto',
    title: {
      style: {
        textTransform: 'uppercase'
      }
    },
    labels: {
      style: {
        fontSize: '12px'
      }
    }
  },
  plotOptions: {
    candlestick: {
      lineColor: '#404048'
    }
  },

  // General
  background2: '#F0F0EA'
};

// Apply the theme
Highcharts.setOptions(Highcharts.theme);
