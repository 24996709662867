'use strict';

var app = angular.module('postCenterWebClientApp');

app.directive('assignmentDirect', function () {
  return {
    templateUrl: 'blocks/admin/views/assignment/assignment_direct.html',
    restrict: 'E',
    scope: {},
    controller: 'AssignmentDirectCtrl'
  };
});
