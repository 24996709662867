'use strict';

//display full errors on Chrome´s console
window.onerror = function(
  errorMsg,
  url,
  lineNumber,
  columnNumber,
  errorObject
) {
  if (errorObject && /<omitted>/.test(errorMsg)) {
    if (console) {
      console.error('Full exception message: ' + errorObject.message);
    }
  }
};

/**
 * app module declaration
 */
var app = angular.module('postCenterWebClientApp', [
  'ngCookies',
  'ngResource',
  'ngSanitize',
  'ngRoute',
  'ui.router.tabs',
  'angular-ladda',
  'ui.bootstrap',
  'postCenter.environment',
  'adereso.virtualScroll',
  'ui.bootstrap.datetimepicker',
  'angularFileUpload',
  'highcharts-ng',
  'ui.router',
  'ui.utils',
  'toggle-switch',
  'autocomplete',
  'pascalprecht.translate',
  'angularMoment',
  'summernote',
  'ngclipboard',
  'LocalStorageModule',
  'vs-repeat',
  'ngFuzzySearch',
  'cfp.hotkeys',
  'bitly.generator',
  'ui.slider',
  'isteven-multi-select',
  'ngTagsInput',
  'sticky',
  'colorpicker.module',
  'rx'
]);

/**
 * app module run
 */
app.run(function(
  $rootScope,
  FB_APP_ID,
  FB_API_VERSION,
  $window,
  $state,
  SENTRY_DSN,
  $filter,
  uibPaginationConfig,
  VersionService: VersionService,
  $http,
  ENV,
  FIREBASE_CONFIG,
  $transitions,
  AuthService: AuthService,
  AppStateService: AppStateService
) {
  // Initialize Firebase
  firebase.initializeApp(FIREBASE_CONFIG);
  if (firebase.analytics) {
    firebase.analytics();
    $rootScope.fireEvent = firebase.analytics();
    if (gtag) {
      // previene el registro automático de pageView
      gtag('set', {
        send_page_view: false
      });
    }
  }

  VersionService.getVersion().then(version => {
    if (typeof Sentry !== "undefined" && ENV !== '') {
      Sentry.init({
        dsn: SENTRY_DSN,
        release: version,
        environment: ENV
      });
    } else {
      console.warn('Sentry is NOT available for local, u should use devTools');
    }
    $http.defaults.headers.common['PC-Version'] = version;
  })

  function setPaginationConfig() {
    var previous = $filter('translate')('PAGINATION_PREVIOUS'),
      next = $filter('translate')('PAGINATION_NEXT'),
      first = $filter('translate')('PAGINATION_FIRST'),
      last = $filter('translate')('PAGINATION_LAST');
    uibPaginationConfig.previousText = previous;
    uibPaginationConfig.nextText = next;
    uibPaginationConfig.firstText = first;
    uibPaginationConfig.lastText = last;
  }

  $window.fbAsyncInit = function() {
    FB.init({
      appId: FB_APP_ID,
      version: FB_API_VERSION
    });
  };

  function setRootScopeBaseConfig() {
    $rootScope.adminTab = {
      selected: ''
    };
    $rootScope.analyticTab = {
      selected: ''
    };
    $rootScope.settingsTab = {
      selected: ''
    };
    $rootScope.columnTypes = [
      {
        type: 'case',
        name: 'Cases'
      },
      {
        type: 'message',
        name: 'Messages'
      }
    ];
    $rootScope.alerts = [];
  }
  setRootScopeBaseConfig();

  $transitions.onStart({}, ($transition)=>{
    let toState = $transition.$to();
    if (angular.isDefined(toState.data)) {
      if (toState.data.sidebarActive) {
        $rootScope.sidebarActive = true;
      }
    } else {
      if ($rootScope.sidebarActive) {
        $rootScope.sidebarActive = false;
      }
    }
  });
  $rootScope.$on('$locationChangeStart', function() {
    AuthService.urlTokenSetup();
    AuthService.isAuthed().then(authed => {
      if (!authed) {
        AppStateService.goToLogin()
      } else {
        let install = AuthService.checkForQueryParam('install');
        let code = AuthService.checkForQueryParam('code');

        if (install && code) {
          AppStateService.goToInstall(install, code);
        }
      }
    })
  })

  if (angular.isDefined(Highcharts)) {
    Highcharts.setOptions({
      global: {
        useUTC: false
      }
      //lang: HighchartLanguage
    });
  }

  $rootScope.goToParentState = function() {
    $state.go($state.$current.parent);
  };

  $rootScope.goToState = function(state: string, stateParams: Object = undefined) {
    $state.go(state, stateParams);
  };

  $rootScope.$on('changeLanguage', function(event, langKey) {
    var lang = langKey;

    if (numeral) {
      switch (langKey) {
        case 'en':
          lang = 'en-gb';
          break;
        case 'pt':
          lang = 'pt-br';
          break;
      }
      numeral.language(lang);
    }

    setPaginationConfig();
    setHighchartLang();
  });

  function setHighchartLang() {
    Highcharts.setOptions({
      lang: {
        months: [
          $filter('translate')('HIGHCHARTS_JANUARY'),
          $filter('translate')('HIGHCHARTS_FEBRUARY'),
          $filter('translate')('HIGHCHARTS_MARCH'),
          $filter('translate')('HIGHCHARTS_APRIL'),
          $filter('translate')('HIGHCHARTS_MAY'),
          $filter('translate')('HIGHCHARTS_JUNE'),
          $filter('translate')('HIGHCHARTS_JULY'),
          $filter('translate')('HIGHCHARTS_AUGUST'),
          $filter('translate')('HIGHCHARTS_SEPTEMBER'),
          $filter('translate')('HIGHCHARTS_OCTOBER'),
          $filter('translate')('HIGHCHARTS_NOVEMBER'),
          $filter('translate')('HIGHCHARTS_DECEMBER')
        ],
        shortMonths: [
          $filter('translate')('HIGHCHARTS_SHORT_JANUARY'),
          $filter('translate')('HIGHCHARTS_SHORT_FEBRUARY'),
          $filter('translate')('HIGHCHARTS_SHORT_MARCH'),
          $filter('translate')('HIGHCHARTS_SHORT_APRIL'),
          $filter('translate')('HIGHCHARTS_SHORT_MAY'),
          $filter('translate')('HIGHCHARTS_SHORT_JUNE'),
          $filter('translate')('HIGHCHARTS_SHORT_JULY'),
          $filter('translate')('HIGHCHARTS_SHORT_AUGUST'),
          $filter('translate')('HIGHCHARTS_SHORT_SEPTEMBER'),
          $filter('translate')('HIGHCHARTS_SHORT_OCTOBER'),
          $filter('translate')('HIGHCHARTS_SHORT_NOVEMBER'),
          $filter('translate')('HIGHCHARTS_SHORT_DECEMBER')
        ],
        weekdays: [
          $filter('translate')('WEEKDAY_SUNDAY'),
          $filter('translate')('WEEKDAY_MONDAY'),
          $filter('translate')('WEEKDAY_TUESDAY'),
          $filter('translate')('WEEKDAY_WEDNESDAY'),
          $filter('translate')('WEEKDAY_THURSDAY'),
          $filter('translate')('WEEKDAY_FRIDAY'),
          $filter('translate')('WEEKDAY_SATURDAY')
        ]
      }
    });
  }
});
