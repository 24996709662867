const EpaSecondStep = Vue.component('EpaSecondStep', {
  data() {
    return {
      hours: 0,
      minutes: 1,
      daysSelector: new Array(32).fill(1),
      hoursSelector: new Array(24).fill(1),
      minutesSelector: new Array(60).fill(1)
    };
  },
  computed: {
    ...Vuex.mapGetters('epaConfig', ['editingSurvey'])
  },
  watch: {
    editingSurvey({delivery_timeout}) {
      this.setIndexTime(delivery_timeout);
    },
    hours() {
      this.setDefaultTime();
    },
    minutes() {
      this.setDefaultTime();
    }
  },
  mounted() {
    const {delivery_timeout} = this.editingSurvey || {};
    this.setIndexTime(delivery_timeout);
  },
  methods: {
    setDefaultTime() {
      if (this.minutes === 0 && this.hours === 0) {
        this.minutes = 1;
      }
    },
    formatTime(time) {
      return time < 10 ? `0${time}` : `${time}`;
    },
    setIndexTime(delivery_timeout = '00:01') {
      const [hour, minute] = delivery_timeout.split(':');
      this.hours = parseInt(hour, 10);
      this.minutes = parseInt(minute, 10);
    },
    updateTime() {
      this.editingSurvey.delivery_timeout = `${this.formatTime(
        this.hours
      )}:${this.formatTime(this.minutes)}`;
    }
  },
  i18n: {
    messages: {
      es: {
        label: 'Establece la forma en la que se enviará la encuesta.',
        deliveryType: 'Manual | Automática',
        deliveryTimeout:
          'Luego de cerrar el ticket, enviar encuesta después de',
        spamTimeout: 'Establece el umbral de spam',
        manualHelp:
          'El envío manual habilita un botón en la conversación que le permite al ejecutivo enviar la encuesta una vez que se ha cerrado el ticket.',
        time: {
          minutes: 'Minuto | Minutos',
          hours: 'Hora | Horas'
        },
        timeLabel: 'de cerrado el ticket',
        days: 'Día | Días',
        manualModeAdvice:
          'La encuesta se cierra automáticamente después de 30 minutos de no tener respuesta del cliente',
        image: {
          title:
            'Adapta las configuraciones a <strong>tus necesidades</strong> pero recuerda que todas las encuestas son voluntarias.',
          alt: 'Configura estas encuestas.'
        }
      },
      pt: {
        label: 'Defina a forma como a pesquisa será enviada.',
        deliveryType: 'Manual | Automático',
        deliveryTimeout: 'Após o fechamento do ticket, enviar a pesquisa após',
        spamTimeout: 'Defina o limite de spam',
        manualHelp:
          'O envio manual habilita um botão na conversa que permite ao executivo enviar a pesquisa assim que o ticket for fechado.',
        timeLabel: 'após o fechamento do ticket',
        days: 'Dia | Dias',
        time: {
          minutes: 'Minuto | Minutos',
          hours: 'Hora | Horas'
        },
        manualModeAdvice:
          'A pesquisa é fechada automaticamente após 30 minutos se não houver resposta do cliente.',
        image: {
          title:
            'Adapte as configurações às <strong>suas necessidades</strong>, mas lembre-se de que todas as pesquisas são voluntárias.',
          alt: 'Configure essas pesquisas.'
        }
      },
      en: {
        label: 'Set the way the survey will be sent.',
        deliveryType: 'Manual | Automatic',
        deliveryTimeout: 'After closing the ticket, submit survey after',
        spamTimeout: 'Set the spam threshold',
        manualHelp:
          'Manual submission enables a button in the conversation that allows the executive to send the survey once the ticket has been closed.',
        timeLabel: 'after ticket closed',
        days: 'Day | Days',
        time: {
          minutes: 'Minute | Minutes',
          hours: 'Hour | Hours'
        },
        manualModeAdvice:
          'The survey closes automatically after 30 minutes if there is no response from the client.',
        image: {
          title:
            'Adapt the settings to <strong>your needs</strong> but remember that all surveys are voluntary.',
          alt: 'Configure these surveys.'
        }
      }
    }
  },
  template: `<div class="d-flex">
    <div class="flex-col-12">
      <p>{{ $t('label') }}</p>
      <label class="radio-label">
        <input
          type="radio"
          class="ads-radio"
          label="Name"
          value="manual"
          id="manual"
          v-model="editingSurvey.delivery_type"
        />
        <span class="mx-1">{{ $tc('deliveryType', 1) }}</span>
      </label>
      <slot v-if="editingSurvey.delivery_type === 'manual'">
        <p>{{ $t('manualHelp') }}</p>
      </slot>
      <label class="radio-label">
        <input
          type="radio"
          class="ads-radio"
          label="Name"
          value="automatic"
          id="automatic"
          v-model="editingSurvey.delivery_type"
        />
        <span class="mx-1">{{ $tc('deliveryType', 2) }}</span>
      </label>
      <slot v-if="editingSurvey.delivery_type === 'automatic'">
        <p>{{ $t('deliveryTimeout') }}</p>
        <div class="d-flex">
          <label class="d-flex align-center mr-1">
            <select class="ads-select" v-model="hours" @change="updateTime">
              <option
                v-for="(_, index) in hoursSelector"
                :value="index">
                {{ formatTime(index) }}
              </option>
            </select>
            <span class="ml-1"> {{ $tc('time.hours', hours) }} </span>
          </label>
          <label class="d-flex align-center">
            <select class="ads-select" v-model="minutes" @change="updateTime">
              <option
                v-for="(_, index) in minutesSelector"
                :value="index"
              >
                {{ formatTime(index) }}
              </option>
            </select>
            <span class="ml-1"> {{ $tc('time.minutes', minutes) }} </span>
          </label>
        </div>
      </slot>
      <p class="mt-2">
        {{ $t('spamTimeout') }}
        <span
          class="fa fa-question-circle-fas fa-x2 icon-blue"
          :content="$t('tooltips.spamTimeout')"
          v-tippy="{ arrow : true}"
        />
      </p>

      <label class="d-flex align-center">
        <select class="ads-select w-10" v-model="editingSurvey.spam_timeout">
          <option v-for="(_, index) in daysSelector" :value="index">
            {{ index }}
          </option>
        </select>
        <span class="ml-1">
          {{ $tc('days', editingSurvey.spam_timeout) }}
        </span>
      </label>
      <hr />
      <div class="d-flex align-center mb-2">
        <span class="fa fa-info-circle mr-1" />
        <span>{{ $t('manualModeAdvice') }}</span>
      </div>
    </div>
    <div class="flex-col-4 pl-2">
      <img src="images/epa-step2.svg" :alt="$t('image.alt')" width="80%" />
      <p v-html="$t('image.title')" />
    </div>
  </div>`
});
