'use strict';

angular
  .module('postCenterWebClientApp')
  .directive('kpiAttentionStatus', kpiAttentionStatus);

kpiAttentionStatus.$inject=[];

function kpiAttentionStatus() {
  return {
    scope : {
      kpi: '='
    },
    templateUrl: 'blocks/analytics/views/kpi_attention_status.html',
    restrict: 'E',
    controller: ['$scope', function ($scope) {}],
    link: function($scope, $element) {}
  };
}
