const ToggleAssign = Vue.component('ToggleAssign', {
  props: {
    agent: {
      type: Object,
      required: true
    },
    assignHandler: {
      type: Function,
      default: null
    }
  },
  data() {
    return {show: false};
  },
  methods: {
    toggleSelector() {
      this.show = !this.show;
    },
    changeAssign(mode) {
      this.assignHandler(this.agent, mode);
    }
  },
  template: `<div class="col-sm-9 clickable"
                @click="toggleSelector">
                <label class="m-0">{{ agent.name }}</label>
              <i class="text-info fa"
              :class="show ? 'fa-chevron-circle-up' : 'fa-chevron-circle-down'" />
              <p>{{ agent.mode.name }}</p>
              <AssignmentDropdown
                v-if="show"
                :current-mode="agent.mode.id"
                :modeSelected="changeAssign"
              />
</div>`
});

const AssignmentDropdown = Vue.component('AssignmentDropdown', {
  props: {
    currentMode: {type: Number, default: null},
    modeSelected: {
      type: Function,
      default: null
    }
  },
  computed: {
    ...Vuex.mapGetters(['assignmentModes'])
  },
  methods: {
    onChanges(mode = null) {
      mode && this.modeSelected(mode);
    }
  },
  template: `
    <ul class="dropdown-menu d-block"
        v-on-outer-click="onChanges">
      <li v-for="mode in assignmentModes">
        <button type="button"
          class="btn btn-link btn-block text-left"
          :class="{ bold: mode.id === currentMode }"
          @click="onChanges(mode)">{{ mode.name }}</button>
      </li>
    </ul>
  `
});
