(module => {
  'use strict';
  KeywordSearchModalCtrl.$inject = ['$scope', '$uibModalInstance'];
  function KeywordSearchModalCtrl($scope, $uibModalInstance) {
    $scope.ok = function() {
      $uibModalInstance.close($scope.keyword);
    };

    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };
  }
  module.controller('KeywordSearchModalCtrl', KeywordSearchModalCtrl);
})(angular.module('postCenterWebClientApp'));
(module => {
  'use strict';
  KeywordsCtrl.$inject = [
    '$timeout',
    'AdNotification',
    '$scope',
    '$rootScope',
    '$uibModal',
    'InitialData',
    'SocialAccount',
    'SocialAccountService',
    'EstablishmentService'
  ];
  function KeywordsCtrl(
    $timeout,
    AdNotification,
    $scope,
    $rootScope,
    $uibModal,
    InitialData,
    SocialAccount,
    SocialAccountService,
    EstablishmentService: EstablishmentService
  ) {
    $rootScope.adminTab.selected = 'keywords'

    EstablishmentService.getEstablishment().then(establishment => {
      $scope.tabs = [
        {
          title: 'Twitter',
          socialNetwork: 'twitter',
          maxAccounts: establishment['max_twitter_search_accounts']
        },
        {
          title: 'Facebook',
          socialNetwork: 'facebook',
          maxAccounts: 0
        }
      ]
    })

    $scope.disconnectAccount = function(sn, uid) {
      SocialAccountService.disconnectSearch(sn, uid).then(
        () => {
          refreshData()
          AdNotification.success(201, 'disconnect_social_account')
        },
        data => {
          AdNotification.error(data, 'disconnect_social_account')
        }
      )
    }

    function setSocialAccounts() {
      $scope.socialAccounts = {}
      SocialAccountService.getSocialAccounts().then(accounts => {
        accounts.forEach(account => {
          if (account.type === 'search') {
            if ($scope.socialAccounts[account.sn] === undefined) {
              $scope.socialAccounts[account.sn] = []
            }
            $scope.socialAccounts[account.sn].push(account)
          }
        });
        angular.forEach($scope.tabs, tab => {
          tab.socialAccounts = $scope.socialAccounts[tab.socialNetwork]
        });
      })
    }

    setSocialAccounts()

    $scope.$on('$destroy', () => {
      if ($scope.updateAccountsPromise) {
        $timeout.cancel($scope.updateAccountsPromise)
      }
    })

    $scope.quotap = 0
    function computeQuotaPercentage() {
      EstablishmentService.getEstablishment().then(establishment => {
        $scope.quota = establishment.quota
        $scope.max_quota = establishment.max_quota
        $scope.quotap = $scope.quota / $scope.max_quota
        $scope.quotap = parseInt($scope.quotap * 100)
      })
    }

    computeQuotaPercentage()

    function computeQuotaPercent() {
      $scope.updateAccountsPromise = $timeout(() => {
        InitialData.refreshEstablishment().then(
          () => {
            computeQuotaPercentage()
            computeQuotaPercent()
          },
          error => {}
        );
      }, 5 * 1000)
    }
    computeQuotaPercent()

    $scope.addKeyword = function(sn) {
      const modalInstance = $uibModal.open({
        templateUrl: 'newSearchModal.html',
        controller: 'KeywordSearchModalCtrl',
        size: 'sm',
        resolve: {
          sn() {
            return sn;
          }
        }
      });

      modalInstance.result.then(
        selectedKeyword => {
          $scope.selectedKeyword = selectedKeyword;
          SocialAccountService.connectSearch($scope.selectedKeyword, sn).then(
            accounts => {
              refreshData();
              SocialAccount.onChannelConnected();
            },
            error => {}
          );
        },
        () => {}
      );
    };

    function refreshData() {
      InitialData.refreshSocialAccounts().then(
        () => {
          setSocialAccounts();
        },
        data => {
          AdNotification.error(data, 'get_social_accounts');
        }
      );
    }
  }
  module.controller('KeywordsCtrl', KeywordsCtrl);
})(angular.module('postCenterWebClientApp'));
