class SocialAccountConfig {
  account_author: boolean
  anonymous_user?: boolean
  answer_sla_unit: string
  answer_sla_value: number
  auto_close_ticket_message_active: boolean
  auto_close_ticket_messages
  away_message_active: boolean
  away_message_minute_threshold: number
  away_messages
  aws_socketurl?: string
  backend_url?: string
  case_notification_emails: string[]
  case_notifications: number
  chat_button_large?: boolean
  chat_button_large_content?
  chat_side?: boolean
  close_ticket_message_active: boolean
  close_ticket_message_answered: boolean
  close_ticket_messages: string[]
  color
  contact_error_message
  contact_form_account: number
  contact_form_email
  contact_form_fallback: boolean
  contact_form_fallback_account: boolean
  contact_form_fallback_email: boolean
  contact_header_message
  contact_header_message_extra
  contact_success_message
  containment_active: boolean
  containment_last_message
  containment_max_retries: number
  containment_messages
  containment_minute_threshold: number
  conversation_header_message
  create_closed_cases: boolean
  disclaimer_login?: boolean
  disclaimer_login_content?: string
  domain?: string
  email_as_id?: boolean
  enable_autoassignation: boolean
  enable_ticket_creation: boolean
  fb_login?: boolean
  first_contact_message_active: boolean
  first_contact_messages
  google_login?: boolean
  hidden: boolean
  important_keywords: string[]
  important_keywords_active: boolean
  locale: string
  login_message?: string
  logo?
  message_url?: string
  mini_chat_can_reply?: boolean
  mini_chat_max_messages?: number
  new_ticket_active: boolean
  new_ticket_messages
  no_client_reply_close: boolean
  no_client_reply_close_time: number
  no_client_reply_close_unit: string
  only_admins_can_post: boolean
  only_social_login?: boolean
  open_on_device?: string
  open_onload?: boolean
  open_onload_delay?: number
  open_onload_device?: string
  out_work_hours_active: boolean
  out_work_hours_case_action: number
  out_work_hours_messages
  proactive?: boolean
  request_country_id?: boolean
  request_phone?: boolean
  separate_public_from_private_cases: boolean
  sound_notifications: boolean
  text_color?
  title?: string
  validate_rut?: boolean
  welcome_message_author?
  welcome_message_content?
  welcome_message_delay?: number
  welcome_message_device?: string
  welcome_message_frequency?: number
  welcome_message_persistent?: boolean
  welcome_message_show?: boolean
}

class SocialAccount {
  avatar_url: string
  config: SocialAccountConfig
  id: number
  is_connected: boolean
  is_monitored: boolean
  prealloced: boolean
  social_network: string
  social_type: string
  status: number
  statuses
  uid: string
  username: string
  visual_name: string
}

class ExtendedSocialAccount extends SocialAccount {
  sn: string
  selected: boolean
  name: string
  avatar: string
  type: string
  monitored: boolean
  is_connected: boolean
}

class SocialAccountService {

  private AdRequest: AdRequestService;
  private accountsModel: AdModelFetcher<ExtendedSocialAccount[]>
  public accountsObservable: rxjs.Observable<ExtendedSocialAccount[]>
  private $rootScope

  constructor(AdRequest: AdRequestService, $rootScope) {
    this.AdRequest = AdRequest
    this.$rootScope = $rootScope
    this.accountsModel = new AdModelFetcher(() => this.fetchAccounts())
    this.accountsObservable = this.accountsModel.observable()
    AdRequest.loggedOut.subscribe(() => this.accountsModel.clear())
  }

  private fetchAccounts(): Promise<ExtendedSocialAccount[]> {
    return this.AdRequest.get<SocialAccount[]>('/api/v1/social_accounts/')
      .then(acc => {
        let accounts = this.processSocialAccounts(acc)
        return accounts
      })
  }

  private processSocialAccounts(accounts: SocialAccount[]): ExtendedSocialAccount[] {
    const processedAccounts = accounts
      .filter(account => account.is_connected)
      .map(account => {
        const extendedAccount = Object.assign(account, {
          sn: account.social_network,
          selected: false,
          name: account.visual_name,
          avatar: account.avatar_url,
          type: account.social_type,
          monitored: account.is_monitored,
          is_connected: account.is_connected
        })
        return extendedAccount
      })
    this.$rootScope.accounts = processedAccounts
    return processedAccounts
  }

  refreshSocialAccounts(): Promise<ExtendedSocialAccount[]> {
    return this.accountsModel.refresh()
  }
  
  getSocialAccounts(): Promise<ExtendedSocialAccount[]> {
    return this.accountsModel.get()
  }

  accessToken(data) {
    return this.AdRequest.post('/api/v1/social_accounts/import/', data, null, 0);
  }

  connectSearch(keyword, sn) {
    const data = {
      type: 'search',
      keyword,
      sn
    };
    return this.AdRequest.post('/api/v1/social_accounts/import/', data, null, 0);
  }

  disconnectSearch(sn, uid) {
    return this.AdRequest.delete(
      `/api/v1/social_account/keyword/${sn}/${uid}/`
    );
  }

  disconnect(sn, uid) {
    return this.AdRequest.delete(`/api/v1/social_account/${sn}/${uid}/`);
  }

  connectionStatus(sn, uid) {
    return this.AdRequest.get(`/api/v1/social_account/status/${sn}/${uid}/`);
  }

  requestCode(sn, uid, method) {
    return this.AdRequest.get(
      `/api/v1/social_account/requestcode/${sn}/${uid}/${method}/`
    );
  }

  connect(data) {
    return this.AdRequest.post(
      '/api/v1/social_accounts/activate/',
      data,
      null,
      0
    );
  }

  connectSmooch(params) {
    return this.AdRequest.post(
      '/api/v1/social_accounts/connect/whatsapp/smooch',
      params,
      null,
      0
    );
  }

  getChartSocialAccounts() {
    return this.AdRequest.get('/api/v1/social_accounts_chart/');
  }

  getSocialAccount(sn, id) {
    return this.AdRequest.get(`/api/v1/social_account/${sn}/${id}/`);
  }

  validateSocialAccount(id) {
    return this.AdRequest.post(`/api/v1/social_account/${id}/validate/`);
  }

  updateAvatar(avatar_url = '', account = {}) {
    const {sn, uid} = account;
    return this.AdRequest.put(`/api/v1/social_account/${sn}/${uid}/`, {
      avatar_url
    });
  }

  saveConfig(id, data) {
    return this.AdRequest.put(`/api/v1/social_account/${id}/config/`, data);
  }

  checkSnAccounts(sn) {
    return this.AdRequest.get(`/api/v1/social_accounts_check/${sn}/`);
  }
  getAvailableInstagram() {
    return this.AdRequest.get('/api/v1/instagram/');
  }
}
angular
  .module('postCenterWebClientApp')
  .service('SocialAccountService', SocialAccountService);
