store.registerModule('socialAccounts', {
  namespaced: true,
  state: () => ({
    list: []
  }),
  getters: {
    accounts: state => state.list,
    accountsLength: state => state.list.length,
    accountsBySn: state => filterSn => state.list(({sn}) => sn === filterSn)
  },
  mutations: {
    SET_ACCOUNTS(state, payload) {
      state.list = payload;
    }
  },
  actions: {
    async getAccounts({commit}) {
      try {
        const socialAccounts =
          await this.SocialAccountService.getSocialAccounts();
        commit('SET_ACCOUNTS', socialAccounts);
      } catch {
        throw new Error('Get socialAccounts error');
      }
    }
  }
});
