const AssignmentDetailContainer = Vue.component('AssignmentDetailContainer', {
  provide: {
    setAssignment: (account, mode) => {
      return Object.assign(account, {isActive: mode.id > 0, mode});
    },
    findAssignmentMode: (modes, modeId) => {
      return modes.find(mode => mode.id === modeId);
    },
    getAssignmentCount(modes, assignments) {
      return modes.reduce((obj, mode) => {
        return {
          ...obj,
          [mode.id]: assignments.filter(
            assignment => assignment.mode.id === mode.id
          ).length
        };
      }, {});
    }
  },
  props: {
    details: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      warningChange: false,
      newDetails: null,
      holdDetails: null
    };
  },
  computed: {
    ...Vuex.mapGetters(['lastDetails', 'editMode'])
  },
  watch: {
    details(details) {
      if (!this.editMode) {
        this.checkDetails(details);
      } else {
        // replace details if user wanna continues anyway
        this.holdDetails = details;
        this.warningChange = true;
      }
    }
  },
  methods: {
    ...Vuex.mapActions([
      'saveDetails',
      'saveUserAssignment',
      'updateAccount',
      'editingMode'
    ]),
    continuesWithouSave() {
      this.warningChange = false;
      this.editingMode(false);
      this.saveDetails(this.setDetails(this.holdDetails));
    },
    setDetails(newDetails) {
      const {template, executives, executive, channel} = newDetails;
      if (template === 'channel') {
        const agents = executives.map(agent => {
          const account = agent.accounts.find(({id}) => id === channel.id);
          if (!account) {
            this.goBack({category: 'reset'});
          }
          return Object.assign({mode: account.mode}, agent);
        });
        this.newDetails = {
          executives: JSON.parse(JSON.stringify(agents)),
          channel: JSON.parse(JSON.stringify(channel)),
          template
        };
      } else if (template === 'executive') {
        this.newDetails = {
          executive: JSON.parse(JSON.stringify(executive)),
          template
        };
      }
      return this.newDetails;
    },
    saveChanges() {
      const {executive, template, executives} = this.lastDetails;
      const {executive: holdAgent} = this.holdDetails;
      if (template === 'executive') {
        this.saveUserAssignment(executive).then(() => {
          this.warningChange = false;
          this.saveDetails(this.setDetails(this.holdDetails))
          this.lastDetails.executive = this.holdDetails.executive
        });
      }
      if (template === 'channel') {
        this.updateAccount().then(() => {
          this.warningChange = false;
          if (holdAgent) {
            const agent = executives.find(
              agent => agent.cmid === holdAgent?.cmid
            );
            this.holdDetails.executive = agent;
          }
          this.saveDetails(this.setDetails(this.holdDetails));
        });
      }
    },
    checkDetails(details) {
      const {executive = {}, template, channel = {}} = details;
      const {
        executive: lastAgent,
        template: lastTemplate,
        channel: lastChannel
      } = this.lastDetails;
      if (template === 'executive') {
        if (executive.cmid !== lastAgent?.cmid) {
          return this.saveDetails(this.setDetails(details));
        }
      } else if (template === 'channel') {
        if (channel.id !== lastChannel?.id) {
          return this.saveDetails(this.setDetails(details));
        }
      } else {
        return this.saveDetails(this.setDetails(details));
      }
    }
  },
  template: `
    <div class="assignments--details--container">
      <AssignmentDetailDefault v-if="!details.template" />
      <AssignmentDetailAgent
        :executive="lastDetails.executive"
        v-if="details.template === 'executive' && lastDetails.executive" />
      <AssignmentDetailAccount
        :account="lastDetails.channel"
        :executives="lastDetails.executives"
        v-if="details.template === 'channel'" />
      <ConfirmModal
        :title="$t('warningTitle')"
        :description="$t('warningMessage')"
        :labels="{
          confirm: $t('buttons.saveAndContinues'),
          cancel: $t('buttons.continuesWithoutSave')
        }"
        :open="warningChange"
        :onConfirm="saveChanges"
        :onCancel="continuesWithouSave" />
    </div>
  `
});
