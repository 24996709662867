const secondStep = Vue.component('SecondStep', {
  data() {
    return {
      form: {
        prefix: '',
        phone: '',
        industry: '',
        solution: ''
      },
      titles: {
        prefix: 'signUp.code.placeholder',
        industry: 'signUp.industry.placeholder',
        solution: 'signUp.solution.placeholder'
      },
      validPhone: true
    };
  },
  computed: {
    ...Vuex.mapGetters([
      'industries',
      'solutions',
      'prefix',
      'totalSteps',
      'counterSteps',
      'newAccount',
      'recaptcha',
      'accountStatus'
    ]),
    newSolutions() {
      let newSolutions = [];
      this.solutions.forEach((solution, index) => {
        newSolutions.push({
          name: this.$t('signUp.solutions[' + index + ']'),
          id: solution,
          active: false
        });
      });
      return newSolutions;
    },
    newIndustries() {
      let newIndustries = [];
      this.industries.forEach((industry, index) => {
        newIndustries.push({
          name: this.$t('signUp.industries[' + index + ']'),
          id: industry,
          active: false
        });
      });
      return newIndustries;
    },
    newPrefix() {
      let newNumbers = [];
      this.prefix.forEach(prefix => {
        newNumbers.push({
          name: prefix.code,
          id: prefix.code,
          active: false
        });
      });
      newNumbers = newNumbers.reduce(
        (acc, prefix) =>
          acc.concat(acc.find(code => code.id === prefix.id) ? [] : [prefix]),
        []
      );
      return newNumbers;
    }
  },
  mounted() {
    this.loadRecaptcha();
  },
  methods: {
    ...Vuex.mapActions(['updateNewAccount', 'createAccount', 'loadRecaptcha']),
    checkPhone() {
      const numberReg = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/;
      const phoneTest = this.form.phone.trim();
      const phoneLength = this.form.phone.length <= 15;
      const valid = phoneLength && numberReg.test(phoneTest);

      this.validPhone = this.form.phone.length === 0 ? true : valid;
    },
    updateFormIndustry() {
      const {id, name} = this.newIndustries.find(
        industry => industry.active === true
      );
      this.form.industry = id;
      this.titles.industry = name;
    },
    updateFormSolution() {
      const {id, name} = this.newSolutions.find(
        solution => solution.active === true
      );
      this.form.solution = id;
      this.titles.solution = name;
    },
    updateFormPrefix() {
      const {id, name} = this.newPrefix.find(prefix => prefix.active === true);
      this.form.prefix = id;
      this.titles.prefix = name;
    },
    validateFormIgnored() {
      this.updateNewAccount({
        phone: '0',
        industry: '',
        solution: ''
      });

      this.createAccount();
    },
    validateForm() {
      const {prefix, phone, industry, solution} = this.form;
      this.updateNewAccount({
        phone: prefix + phone || '0',
        industry,
        solution
      });

      this.createAccount();
    }
  },
  template: `
      <section class="container-second-step">
        <div class="row">
            <div class= "col-xs-12">
                <form class="form mx-2">
                  <div class="header">
                    <h1 class="header__title">{{ $t('signUp.titles.secondStep') }}</h1>
                    <span class="mt-2">
                      <counter-steps :counter-steps="counterSteps" :total-steps="totalSteps"/>
                    </span>
                  </div>
                  <hr />
                  <p>{{ $t('signUp.subtitles.secondStep') }}</p>
                  <div class="selector industry">
                    <label>{{ $t('signUp.industry.label') }}</label>
                    <CustomSelector
                        :is-radio="true"
                        :filter-data="newIndustries"
                        :title="$t(titles.industry) "
                        :change-data="updateFormIndustry"
                    />
                  </div>
                  <div class="selector solution">
                    <label>{{ $t('signUp.solution.label') }}</label>
                    <CustomSelector
                        :is-radio="true"
                        :filter-data="newSolutions"
                        :title="$t(titles.solution) "
                        :change-data="updateFormSolution"
                    />
                  </div>
                  <div class="selector code">
                    <label>{{ $t('signUp.phone.label') }}</label>
                    <div class="contact">
                      <CustomSelector
                          :is-radio="true"
                          :filter-data="newPrefix"
                          :title="$t(titles.prefix) "
                          :change-data="updateFormPrefix"
                      />
                      <inputText
                        class="phone"
                        type="text"
                        name="phone"
                        v-model="form.phone"
                        label=""
                        :placeholder="$t('signUp.phone.placeholder')"
                        @keyup="checkPhone()"
                        :input-error="!validPhone"
                        :error-message="$t('signUp.phone.errors')"
                      />
                      </div>
                  </div>
                  <button
                    type="button"
                    @click="validateForm()"
                    class="btn ads-button ads-button--primary">{{ $t('signUp.btn.next') }}</button>
                  <button
                  type="button"
                  @click="validateFormIgnored()"
                  class="btn ads-button ads-button--link">{{ $t('signUp.btn.skip') }}</button>
                  <span v-if="accountStatus.tryAgain">{{ $t('signUp.errorMessage') }}</span>
                </form>
            </div>
        </div>
      </section>
     `
});
