'use strict';

// We need this directive since Chrome has issues with Iframes with dynamic
// name attribute as the yielded ones by using interpolation. The node appears
// in the DOM tree, but it's ignored when submiting a form targeting it and
// instead a new tab is opened.

angular.module('postCenterWebClientApp').directive('integrationIframe', () => {
  return {
    scope: {
      caseObject: '=',
      iframeUrl: '='
    },
    link(scope, elem) {
      const id = `integration-id`;
      elem.html(
        '<iframe class="integration-view__iframe" style="border: 0;width: 100%;height: 90%;" name="' +
          id +
          '" id="' +
          id +
          '"></iframe>'
      );
    }
  };
});
