Vue.component('contact-form-message', {
  inject: ['socialNetworkService'],
  data() {
    return {
      sending: false,
      contactInfo: [],
      hsm: [],
      form: {
        from: null,
        to: null,
        message: null,
        hsm_parameters: []
      }
    };
  },
  computed: {
    ...Vuex.mapGetters(['contact']),
    maxMessageLength() {
      const {from} = this.form;
      if (from?.sn === 'email') {
        return 10000;
      }
      if (from?.sn === 'facebook') {
        return 2000;
      }

      return 256;
    },
    notContactable() {
      const {contact} = this;

      if (!contact.accounts) {
        return false;
      }

      return contact.accounts.find(account => !account.is_contactable);
    },
    availableChannels() {
      if (!this.contactInfo?.length) {
        return null;
      }

      return this.contactInfo.reduce((channels, account) => {
        const currentAccounts = channels[account.sn] || [];

        return {
          ...channels,
          [account.sn]: [...currentAccounts, account]
        };
      }, {});
    },
    sendToAccounts() {
      const account = this.contactInfo.find(
        account => account.from.uid === this.form.from?.uid
      );

      return account?.send_to;
    },
    getHsm() {
      return this.hsm;
    },
    hsmParamsCount() {
      if (!this.form.message) {
        return 0;
      }

      const count = this.form.message.canned_response.match(/{{/g)?.length;
      return count || 0;
    }
  },
  methods: {
    ...Vuex.mapActions([
      'sendContactForm',
      'setVisibleForm',
      'getMessagingInfo'
    ]),
    onSubmit() {
      this.sending = true;

      this.form = {...this.contact, ...this.form};
      this.sendContactForm(this.form)
        .then(() => this.setVisibleForm(false))
        .finally(() => {
          this.sending = false;
        });
    },
    prettifySN(sn = '') {
      return this.socialNetworkService.getTitle(sn);
    },
    updateHsmList() {
      const {
        from: {uid}
      } = this.form;
      const account = this.contactInfo.find(
        account => account.from.uid === uid
      );
      const {hsm_list} = account;
      this.hsm = hsm_list;
    }
  },
  watch: {
    'form.from'(newValue = '', oldValue = '') {
      this.updateHsmList();
      this.form.subject = null;
      this.form.message = null;
      if (newValue !== oldValue) {
        this.form.to = null;
      }
    },
    'form.message': {
      deep: true,
      handler() {
        this.form.hsm_parameters = [];
      }
    }
  },
  async created() {
    this.getMessagingInfo(this.contact._id)
      .then(data => {
        this.contactInfo = data;
      })
      .catch(() => {
        this.contactInfo = null;
      });
  },
  template: `
    <form class="contact-agenda--form mx-auto" @submit.prevent="onSubmit">
      <h5 class="bold">{{ $t('CTA.contact') }}</h5>

      <div v-if="availableChannels"
           class="d-flex flex-column center w-100">
        <label>{{ $t('forms.contact.chooseAccountFrom') }}</label>
        <select v-model="form.from"
                class="form-control mb-1"
                required>
          <option :value="null" disabled>{{ $t('columns.accountsFrom') }}</option>
          <optgroup v-for="(accounts, channel) in availableChannels"
                    :label="prettifySN(channel)">
            <option v-for="account in accounts"
                    :value="account.from">{{ account.from.visual_name }}</option>
          </optgroup>
        </select>

        <template v-if="sendToAccounts">
          <label>{{ $t('forms.contact.chooseAccountTo') }}</label>
          <select v-model="form.to"
                  class="form-control mb-1"
                  required>
              <option :value="null" disabled>
                {{ $t('columns.accounts') }}
                ({{ prettifySN(form.from.sn) }})
              </option>
              <option v-for="account in sendToAccounts"
                      :value="account.uid">{{ account.username }}</option>
            </optgroup>
          </select>
        </template>
        <template v-if="form.from">
          <template v-if="form.from.sn === 'whatsapp'">
            <label>{{ $t('forms.contact.chooseCannedMessage') }}</label>
            <select v-model="form.message"
                    class="form-control"
                    required>
              <option :value="null" disabled>{{ $t('forms.messages') }}</option>
              <option v-for="message in getHsm"
                      :value="message">{{ message.canned_response }}</option>
            </select>

            <template v-if="hsmParamsCount">
              <label class="mt-1">{{ $t('forms.hsmParams', {count: hsmParamsCount}) }}:</label>
              <input type="text"
                     class="form-control mb-1"
                     v-model="form.hsm_parameters[index]"
                     v-for="(param, index) in hsmParamsCount"
                     :key="index"
                     :placeholder="$t('forms.hsmParamPlaceholder', {index: index+1})"
                     required />
            </template>
          </template>

          <template v-else>
            <label>{{ $t('forms.contact.chooseMessage' )}}</label>
            <input type="text"
                   v-if="form.from.sn === 'email'"
                   class="form-control mb-1"
                   maxlength="60"
                   :placeholder="$t('forms.contact.subject')"
                   v-model="form.subject"
                   required/>
            <textarea v-model="form.message"
                      :maxlength="maxMessageLength"
                      :placeholder="$t('forms.contact.chooseMessage')"
                      class="form-control no-resizable"
                      required></textarea>
            <contact-form-maxlength :max="maxMessageLength"
                                    :current="form.message?.length" />
          </template>
        </template>

        <footer class="text-center m-1">
          <button type="button"
                  class="btn btn-danger"
                  @click="setVisibleForm(false)">{{ $t('CTA.cancel') }}</button>
          <button type="submit"
                  :disabled="sending"
                  class="btn btn-success">{{ $t('CTA.confirm') }}</button>
        </footer>
      </div>

      <p v-else-if="notContactable">
        <client-not-contactable :reasons="notContactable.not_contactable_reason" />
      </p>

      <Loader v-else size="2x" />
    </form>
  `
});

Vue.component('contact-form-edit', {
  data() {
    return {
      editForm: {},
      validations: {
        email: {
          type: 'email',
          maxlength: 320
        },
        country_id: {
          type: 'text',
          maxlength: 10
        },
        undefined: {
          type: 'text',
          maxlength: 120
        }
      }
    };
  },
  methods: {
    ...Vuex.mapActions(['sendEditForm', 'setVisibleForm']),
    onSubmit() {
      this.sendEditForm(this.editForm).then(() => this.setVisibleForm(false));
    },
    setFields(fields) {
      fields = Object.values(fields);

      return {
        text: fields.filter(field => typeof field === 'string'),
        list: fields.filter(field => Array.isArray(field)),
        boolean: fields.filter(field => typeof field === 'boolean')
      };
    }
  },
  watch: {
    'contact._id'(value = {}) {
      this.setFields(value);
    }
  },
  computed: {
    ...Vuex.mapGetters(['contact', 'tableColumns'])
  },
  async mounted() {
    this.editForm = {...this.contact};
  },
  template: `
    <form class="contact-agenda--form mx-auto"  @submit.prevent="onSubmit">
      <h5 class="bold">{{ $t('CTA.edit') }}</h5>
      <div class="d-flex flex-column w-100">
        <ul class="list-unstyled">
          <li v-for="field in tableColumns.all"
              v-if="!field.required && field.label"
              :key="field.label"
              class="mb-1">
            <label>{{ field.label }}</label>
            <input type="text"
                   v-bind="validations[field.field]"
                   class="form-control"
                   v-model="editForm[field.field]"
                   :placeholder="field.label" />
          </li>
        </ul>

        <footer class="text-center m-1">
          <button type="button"
                  class="btn btn-danger mr-2"
                  @click="setVisibleForm(false)">{{ $t('CTA.cancel') }}</button>
          <button type="submit"
                  class="btn btn-success">{{ $t('CTA.confirm') }}</button>
        </footer>
      </div>
    </form>
  `
});

Vue.component('contact-form-note', {
  data() {
    return {
      maxTitleLength: 45,
      maxNoteLength: 256,
      sending: false,
      form: {subject: '', content: ''}
    };
  },
  computed: {
    ...Vuex.mapGetters(['contact', 'note']),
    header() {
      const label = this.note ? 'editingNote' : 'creatingNote';
      return this.$t('forms.' + label);
    }
  },
  mounted() {
    if (this.note) {
      this.form = Object.assign({}, this.note);
    }
  },
  methods: {
    ...Vuex.mapActions(['saveNote', 'setVisibleForm']),
    onSubmit() {
      this.sending = true;

      const payload = {...this.form, contact_id: this.contact._id};

      this.saveNote(payload)
        .then(() => {
          this.form = {};
          this.setVisibleForm(false);
        })
        .finally(() => (this.sending = false));
    }
  },
  template: `
    <form class="contact-agenda--form mx-auto" @submit.prevent="onSubmit">
      <h5 class="bold">{{ header }}...</h5>
      <div class="d-flex flex-column center w-100">
        <label>
          {{ $t('forms.contact.subject') }}
          <contact-form-maxlength class="pull-right" :max="maxTitleLength" :current="form.subject.length" />
        </label>
        <input type="text"
               class="form-control mb-1"
               :maxlength="maxTitleLength"
               v-model="form.subject"
               required
               :placeholder="$t('forms.contact.subject')" />

        <label>
          {{ $t('forms.contact.content') }}
          <contact-form-maxlength class="pull-right" :max="maxNoteLength" :current="form.content.length" />
        </label>
        <textarea class="form-control no-resizable"
                  v-model="form.content"
                  required
                  :placeholder="$t('forms.contact.content')"
                  :maxlength="maxNoteLength"></textarea>

        <footer class="text-center m-1">
          <button type="button"
                  class="btn btn-danger"
                  @click="setVisibleForm(false)">{{ $t('CTA.cancel') }}</button>
          <button type="submit"
                  :disabled="sending"
                  class="btn btn-success">{{ $t('CTA.confirm') }}</button>
        </footer>
      </div>
    </form>
  `
});

Vue.component('contact-form-maxlength', {
  props: {
    current: {type: Number, default: 0},
    max: {type: Number, default: 0}
  },
  template: `
    <p class="text-right m-0"
      :class="{'text-danger': current >= max}">
      {{ current }}/{{ max }}
    </p>
  `
});
