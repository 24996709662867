'use strict';

angular
  .module('postCenterWebClientApp')
  .directive('webhooksConfiguration', () => {
    return {
      templateUrl: 'blocks/admin/views/integration_config/webhooks.html',
      restrict: 'E',
      scope: {
        config: '='
      },
      controller: [
        '$scope',
        '$rootScope',
        'AdRequest',
        'IntegrationRequest',
        'IntegrationService',
        '$filter',
        '$uibModal',
        function(
          $scope,
          $rootScope,
          AdRequest,
          IntegrationRequest,
          IntegrationService,
          $filter,
          $uibModal
        ) {
          $scope.savedRequests = [];

          var formatSecondsTimestamp = function(timestamp) {
            return moment(timestamp * 1000).format('ddd h:mmA');
          };

          var openAnalyticsModal = function(title, successData, errorData) {
            $scope.chartConfig = {
              chart: {
                type: 'areaspline',
                width: 900
              },
              title: {text: title},
              tooltip: {
                formatter() {
                  var date = formatSecondsTimestamp(this.point.x);
                  return (
                    '<b>' + this.point.y + ' requests/h' + '</b><br>' + date
                  );
                }
              },
              xAxis: {
                labels: {
                  formatter() {
                    return formatSecondsTimestamp(this.value);
                  }
                }
              },
              yAxis: {
                title: {
                  text: $filter('translate')('GENERAL_REQUESTS')
                }
              },
              series: [
                {
                  name: $filter('translate')('GENERAL_SUCCESS'),
                  color: 'rgba(0, 247, 100, 0.7)',
                  data: successData
                },
                {
                  name: $filter('translate')('GENERAL_ERROR'),
                  color: 'rgba(255, 135, 0, 0.7)',
                  data: errorData
                }
              ]
            };
            $uibModal.open({
              template: '<highchart config="chartConfig"></highchart>',
              scope: $scope,
              size: 'lg'
            });
          };

          $scope.showClientEventsAnalytics = function() {
            AdRequest.get('/api/v1/webhooks/history/client').then(r => {
              openAnalyticsModal(
                'Envío de eventos de clientes',
                r.success,
                r.failure
              );
            });
          };

          $scope.showTicketEventsAnalytics = function() {
            AdRequest.get('/api/v1/webhooks/history/ticket').then(r => {
              openAnalyticsModal(
                'Envío de eventos de tickets',
                r.success,
                r.failure
              );
            });
          };

          var refreshRequests = function() {
            IntegrationService.allRequest().then(requests => {
              request.map(request => {
                MP_RequestData.prototype.fromObject(request);
              });
              $scope.savedRequests = requests;
            });
          };

          refreshRequests();

          $scope.getRequest = function(requestId) {
            return $scope.savedRequests.filter(request => {
              if (request.name === 'DEFAULT_EVENTS_REQUEST') {
                request.name = $filter('translate')(
                  'MINIPOSTMAN_DEFAULT_EVENTS_REQUEST'
                );
              }
              return request.id === requestId;
            })[0];
          };

          $scope.editRequest = function(requestId) {
            IntegrationRequest.edit(
              $scope.getRequest(requestId),
              refreshRequests
            );
          };

          $scope.validEndpoint = undefined;

          $scope.undefineEndpointValidity = function() {
            $scope.validEndpoint = undefined;
          };

          $scope.testEndpoint = function() {
            AdRequest.post('/api/v1/webhooks/test', {
              endpoint: $scope.config.endpoint
            }).then(
              () => {
                $scope.validEndpoint = true;
              },
              () => {
                $scope.validEndpoint = false;
              }
            );
          };

          $scope.saveWebhooksConfig = function() {
            var data = {
              active: $scope.config.active,
              ticket_events: $scope.config.ticket_events,
              ticket_events_request_id: $scope.config.ticket_events_request_id,
              client_events: $scope.config.client_events,
              client_events_request_id: $scope.config.client_events_request_id
            };

            AdRequest.post('/api/v1/webhooks/config', data).then(
              () => {
                $rootScope.$emit(
                  'successMessage',
                  $filter('translate')('ADMIN_INTEGRATION_SAVE_SUCCESS')
                );
                $scope.config.showing = false;
              },
              error => {
                var message = 'ADMIN_INTEGRATION_SAVE_ERROR';
                if (error.status === 403) {
                  $scope.validEndpoint = false;
                  message = 'INTEGRATION_CONFIG_WEBHOOKS_FORBIDDEN';
                }

                $rootScope.$emit('errorMessage', $filter('translate')(message));
              }
            );
          };
        }
      ]
    };
  });
