const SurveyFilter = Vue.component('SurveyFilter', {
  props: {
    filter: {
      type: String,
      default: 'agents'
    }
  },
  data() {
    return {
      filterData: [],
      labels: {
        select: 'Filtrar por encuesta'
      }
    };
  },
  computed: {
    ...Vuex.mapGetters('epaAnalytics', ['surveys'])
  },
  mounted() {
    // cloning observer from vuex
    this.filterData = JSON.parse(JSON.stringify(this.surveys));
  },
  methods: {
    ...Vuex.mapActions('epaAnalytics', ['agentsFilter', 'dateFilter']),
    filterSurvey() {
      const filteredSurvey = this.filterData
        .filter(survey => !survey.active)
        .map(survey => survey.id);
      if (this.filter === 'agents') {
        this.agentsFilter({surveys: filteredSurvey});
      } else {
        this.dateFilter({surveys: filteredSurvey});
      }
    }
  },
  template: `
  <div class="">
    <CustomSelector
      is-radio
      :filter-data="filterData"
      :change-data="filterSurvey"
      :title="$tc('surveys', 2)"
      :labels="labels"
    />
  </div>`,
  i18n: {
    messages: {
      es: {
        surveys: 'Encuesta | Encuestas',
        labels: {
          select: 'Filtrar por encuesta'
        }
      },
      pt: {
        surveys: 'Pesquisa | Pesquisas',
        labels: {
          select: 'Filtro por pesquisa'
        }
      },
      en: {
        surveys: 'Survey | Surveys',
        labels: {
          select: 'Filter by survey'
        }
      }
    }
  }
});
