'use strict';

angular
  .module('postCenterWebClientApp')
  .controller(
    'WhatsappClientStatusCtrl',
    ($scope, $uibModalInstance, AdRequest, $timeout, clientStatus) => {
      $scope.pollingStatus = true;

      $scope.updateClientStatus = function(clientStatus) {
        $scope.phoneNumber = clientStatus['phone_number'];
        $scope.runningStatus = clientStatus['client_status'];
        $scope.loginCode = clientStatus['login_code'];
        $scope.accountName = clientStatus['name'];
        $scope.avatar = clientStatus['avatar'];
        $scope.clientStatus = clientStatus;
      };

      $scope.closeModal = function() {
        $uibModalInstance.close($scope.clientStatus);
      };

      $scope.updateClientStatus(clientStatus);

      $scope.refreshStatus = function refreshStatus() {
        if ($scope.pollingStatus) {
          var data = {'action': 'connect'};
          AdRequest.post(`/api/v1/whatsapp/${$scope.phoneNumber}`, data).then(
            response => {
              $scope.updateClientStatus(response);
              $timeout(refreshStatus, 3000);
            }
          );
        }
      };

      $scope.$on('$destroy', () => {
        $scope.pollingStatus = false;
      });

      $scope.refreshStatus();
    }
  );
