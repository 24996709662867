'use strict';

angular.module('postCenterWebClientApp')
  .directive('messageLink', function () {
    return {
      scope: {
        media: '=',
      },
      templateUrl: 'blocks/message/views/message_link.html',
      restrict: 'E',
      controller: ['$scope', '$sce', function ($scope, $sce) {

        var maxLinkLength = 60;
        var longLinkEnd = '...'

        $scope.name = $scope.media.name;
        $scope.target = $scope.media['viewer_url'];
        if ($scope.target.length < maxLinkLength - 3) {
          longLinkEnd = '';
        }
        $scope.targetLimitChars = $scope.target.substring(0, maxLinkLength);
        $scope.targetLimitChars += longLinkEnd;
        $scope.src = $sce.trustAsResourceUrl($scope.media['img_url']);
        $scope.hasImage = $scope.media['img_url'] !== undefined && $scope.media['img_url'] !== '';

      }]
    };
  });
