Vue.component('contact-agenda-settings', {
  data() {
    return {newColumns: []};
  },
  mounted() {
    this.newColumns = this.$store.state.tableColumns.map(item => ({
      ...item,
      enabled: item.required || item.enabled
    }));
  },
  methods: {
    saveColumns() {
      this.$store.dispatch('editTableColumns', this.newColumns);
      this.closeSettings();
    },
    closeSettings() {
      this.$emit('closeSettings');
    },
    getColumnLabel(column = {}) {
      return this.$t(`columns.${column.field}`);
    }
  },
  template: `
    <ConfirmModal
      :open="true"
      :title="$t('settings.title')"
      :onConfirm="saveColumns"
      :onCancel="closeSettings">
      <div class="d-flex flex-column align-center">
        <strong>{{ $t('settings.description') }}</strong>
        <ol class="contact-agenda--settings-table list-unstyled my-2 scrollable">
          <li v-for="(column, index) in newColumns">
            <label>
              <input type="checkbox"
                    :name="column.label"
                    v-model="column.enabled"
                    :disabled="column.required" />
              <span class="mx-1">
                {{ $t('columns.column') }} {{ index+1 }}:
                {{ column.label || getColumnLabel(column) }}
              </span>
            </label>
          </li>
        </ol>
        <p v-html="$t('settings.recommendation')"></p>
      </div>
    </ConfirmModal>
  `
});
