'use strict';

(function () {
  angular
    .module('postCenterWebClientApp')
    .component('replyMessageEmailRecipients', {
      bindings: {
        replyToMessage: '<',
        selectedAccount: '<',
        onRecipientsChange: '&'
      },
      controller: [
        '$element',
        '$scope',
        '$rootScope',
        function ($element, $scope, $rootScope) {
          var self = this;


          self.addrAutocompleteList = [];
          self.setReplyTo = function () {
            if($rootScope.email){
              $rootScope.email.css({
                'border-radius': '',
                'border-style': '',
                'border-color': '',
                'border-width': ''
             });
            }
            self.icon = 'fa-reply';
            self.popoverOpen = false;
            self.targetAddrs = [{text: self.replyToMessage.author.uid}];
          };

          self.setReplyAll = function () {
            if($rootScope.email){
              $rootScope.email.css({
                'border-radius': '',
                'border-style': '',
                'border-color': '',
                'border-width': ''
             });
            }
            self.icon = 'fa-reply-all';
            self.popoverOpen = false;
            self.targetAddrs = [];
            var ccList = emailAddresses.parseAddressList(
              self.replyToMessage.extra_data.cc || ''
            );
            var toList = emailAddresses.parseAddressList(
              self.replyToMessage.extra_data.to || ''
            );
            angular.forEach(ccList, function (addr) {
              if (!isMyAddress(addr.address)) {
                self.targetAddrs.push({text: addr.address});
              }
            });
            angular.forEach(toList, function (addr) {
              if (!isMyAddress(addr.address)) {
                self.targetAddrs.push({text: addr.address});
              }
            });
            self.targetAddrs.push({text: self.replyToMessage.author.uid});
            self.targetAddrs = Array.from(
              new Set(self.targetAddrs.map(JSON.stringify))
            ).map(JSON.parse);
          };

          self.$onChanges = function (changesObj) {
            self.setReplyAll();
          };

          $scope.openRecipients = false;
          $scope.toggleOpenRecipients = function () {
            $scope.openRecipients = !$scope.openRecipients;
          }

          $scope.$watchCollection('$ctrl.targetAddrs', function (targetAddrs) {
            self.onRecipientsChange({
              recipients: targetAddrs.map(function (addr) {
                return addr.text;
              })
            });
          });

          function isMyAddress(emailAddr) {
            if (!self.selectedAccount) {
              return false;
            }
            return emailAddr === self.selectedAccount.uid;
          }
        }
      ],
      templateUrl: 'blocks/message/views/reply_message_email_recipients.html'
    });
})();
