'use strict';

angular.module('postCenterWebClientApp').factory('ChartService', [
  '$filter',
  $filter => {
    var CS = {
      setDonutChart: function(elementId, labels, values, theme) {
        var data = [];
        labels.forEach(function(value, index) {
          var point = {name: value, y: values[index]};
          data.push(point);
        });

        var config = {
          credits: false,
          series: [{data: data, innerSize: '50%'}],
          chart: {type: 'pie', renderTo: 'container'},
          title: '',
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              colors: makeBkgColors(theme),
              borderWidth: 2,
              dataLabels: {
                enabled: true,
                format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                style: {
                  color:
                    (Highcharts.theme && Highcharts.theme.contrastTextColor) ||
                    'black'
                }
              },
              showInLegend: true
            }
          },
          tooltip: {
            backgroundColor: 'black',
            borderColor: 'black',
            borderRadius: 5,
            borderWidth: 1,
            style: {padding: 7},
            useHTML: true,
            formatter: function() {
              var style = 'color:white;';
              var tooltip = '<span style="' + style + '">';
              tooltip = tooltip + '<b>' + this.point.name + '</b>: ';
              tooltip = tooltip + this.point.y + '</span>';
              return tooltip;
            }
          }
        };
        return config;
      },

      setTimeSeriesChart: function(elementId, labels, values, theme) {
        var key = 'ANALYTICS_CUSTOM_DASHBOARD_NEW_TICKETS';
        var title = $filter('translate')(key);
        var serie = {data: [], name: title, color: makeBkgColors(theme)[0]};
        labels.forEach(function(value, index) {
          var point = [value * 1000, values[index]];
          serie.data[index] = point;
        });

        var config = {
          credits: false,
          series: [serie],
          chart: {type: 'areaspline'},
          title: '',
          xAxis: {
            title: {text: ''},
            type: 'datetime',
            gridLineWidth: 1,
            gridLineColor: '#F5F5F5',
            minorGridLineWidth: 0,
            tickColor: '#f5f5f5',
            labels: {style: {color: '#9E9E9E'}}
          },
          yAxis: {
            title: {text: ''},
            gridLineWidth: 1,
            gridLineColor: '#F5F5F5',
            minorGridLineWidth: 0,
            tickColor: '#f5f5f5',
            labels: {style: {color: '#9E9E9E'}}
          },
          tooltip: {
            style: {
              color: 'white'
            },
            backgroundColor: 'black',
            borderColor: 'black',
            borderRadius: 5,
            borderWidth: 1
          }
        };

        return config;
      },

      setBarChart: function(elementId, labels, values, theme) {
        var config = {
          credits: false,
          chart: {type: 'column'},
          title: '',
          xAxis: {
            title: {text: ''},
            categories: labels
          },
          yAxis: {
            title: {text: ''}
          },
          series: [{data: values}],
          legend: {
            enabled: false
          },
          tooltip: {
            backgroundColor: 'black',
            borderColor: 'black',
            borderRadius: 5,
            borderWidth: 1,
            style: {padding: 7},
            useHTML: true,
            formatter: function() {
              var style = 'color:white;';
              var tooltip = '<span style="' + style + '">';
              tooltip = tooltip + '<b>' + this.x + '</b>: ';
              tooltip = tooltip + this.y + '</span>';
              return tooltip;
            }
          }
        };
        return config;
      },

      setBarStackedChart: function(elementId, labels, values, theme, title) {
        var configTitle = '';
        if (title) {
          configTitle = {
            text: title
          };
        }

        var config = {
          credits: false,
          chart: {type: 'bar'},
          title: configTitle,
          xAxis: {
            title: {text: ''},
            categories: labels
          },
          yAxis: {
            min: 0,
            allowDecimals: false,
            title: {text: ''}
          },
          series: values,
          legend: {
            enabled: true,
            reversed: true
          },
          plotOptions: {
            series: {
              stacking: 'normal'
            }
          }
        };

        return config;
      }
    };

    function makeRGBA(r, g, b, a) {
      return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + a + ')';
    }

    function makeBorderRGB(r, g, b) {
      return makeRGBA(r, g, b, '1');
    }

    function makeFillRGB(r, g, b) {
      return makeRGBA(r, g, b, '0.7');
    }

    function baseColors(theme) {
      if (theme === undefined) {
        theme = 'blue';
      }
      var themes = {
        blue: [
          [2, 136, 209],
          [179, 229, 252],
          [224, 224, 224],
          [57, 73, 171]
        ],
        yellow: [
          [255, 152, 0],
          [255, 167, 38],
          [255, 193, 7],
          [253, 216, 53]
        ],
        red: [
          [198, 40, 40],
          [255, 61, 0],
          [244, 67, 54],
          [239, 154, 154]
        ],
        bar: [
          [229, 57, 53],
          [216, 27, 96],
          [142, 36, 170],
          [94, 53, 177],
          [57, 73, 171]
        ]
      };
      themes['blue_4'] = themes['blue'];
      themes['blue_3'] = themes['blue'];
      themes['blue_2'] = themes['blue'];
      themes['yellow_4'] = [
        [255, 152, 0],
        [253, 216, 53],
        [179, 229, 252],
        [2, 136, 209]
      ];
      themes['yellow_3'] = [
        [255, 152, 0],
        [179, 229, 252],
        [2, 136, 209]
      ];
      themes['yellow_2'] = [
        [255, 152, 0],
        [2, 136, 209],
        [255, 193, 7],
        [253, 216, 53]
      ];
      themes['red_4'] = [
        [244, 67, 54],
        [239, 154, 154],
        [179, 229, 252],
        [2, 136, 209]
      ];
      themes['red_3'] = [
        [244, 67, 54],
        [179, 229, 252],
        [2, 136, 209]
      ];
      themes['red_2'] = [
        [244, 67, 54],
        [2, 136, 209],
        [244, 67, 54],
        [239, 154, 154]
      ];
      return themes[theme];
    }

    function makeColors(method, theme) {
      return _.map(baseColors(theme), function(color) {
        return method(color[0], color[1], color[2]);
      });
    }

    function makeBkgColors(theme) {
      return makeColors(makeFillRGB, theme);
    }

    function makeBorderColors(theme) {
      return makeColors(makeBorderRGB, theme);
    }

    return CS;
  }
]);
