/**
 * error exception handler for Sentry
 */
app.config([
  '$provide',
  'ENV',
  function ($provide, ENV) {
    $provide.decorator('$exceptionHandler', [
      '$delegate',
      function ($delegate) {
        return function (exception, cause) {
          $delegate(exception, cause);
          if (typeof Sentry !== "undefined" && ENV !== '') {
            Sentry.captureException(exception, {extra: cause});
          } else {
            throw new Error(exception);
          }
        };
      }
    ]);
  }
]);

/**
 * http provider configuration
 */
app.config([
  '$httpProvider',
  function ($httpProvider) {
    // $httpProvider.defaults.withCredentials = true;
    $httpProvider.interceptors.push(() => {
      return {
        request(config) {
          if (config.url.indexOf('http') < 0) {
            if (config.headers.Authorization !== undefined) {
              delete config.headers.Authorization;
            }
          }
          return config;
        }
      };
    });
  }
]);

/**
 * ladda configuration
 */
app.config([
  'laddaProvider',
  function (laddaProvider) {
    laddaProvider.setOption({
      style: 'slide-down'
    });
  }
]);

/**
 * translate configuration
 */
app.config([
  '$translateProvider',
  function ($translateProvider) {
    $translateProvider.useStaticFilesLoader({
      prefix: '/languages/',
      suffix: '.json'
    });
    $translateProvider.registerAvailableLanguageKeys(['en', 'es', 'pt'], {
      'pt_*': 'pt',
      en_US: 'en',
      en_UK: 'en',
      en_AU: 'en',
      'en-US': 'en',
      'en-AU': 'en',
      'en-UK': 'en',
      en: 'en',
      es_ES: 'es',
      'es-ES': 'es',
      es: 'es',
      'es-419': 'es'
    });

    const localTranslate = localStorage.getItem('NG_TRANSLATE_LANG_KEY');
    const locale = window.navigator.language.slice(0, 2) || 'es';
    const getLocale = () => {
      if (localTranslate) {
        return localTranslate;
      }
      if (locale === 'es' || locale === 'en' || locale === 'pt') {
        return locale;
      }
      return 'es';
    };
    $translateProvider.preferredLanguage(getLocale());
    $translateProvider.useLocalStorage();
    $translateProvider.useSanitizeValueStrategy(null);
  }
]);

/**
 * Strict Contextual Escaping (SCE) config
 */
app.config([
  '$sceDelegateProvider',
  function ($sceDelegateProvider) {
    $sceDelegateProvider.resourceUrlWhitelist([
      'self',
      'http*://www.youtube.com/embed/**',
      'http*://player.vimeo.com/video/**'
    ]);
  }
]);

/**
 * Local storage configuration
 */
app.config([
  'localStorageServiceProvider',
  function (localStorageServiceProvider) {
    localStorageServiceProvider.setPrefix('postcenter');
  }
]);

/**
 * Hotkeys configuration
 */
app.config([
  'hotkeysProvider',
  function (hotkeysProvider) {
    hotkeysProvider.cheatSheetHotkey = 'shift+h';
  }
]);

/**
 * Bitly configuration
 */
app.config([
  'bitlyProvider',
  function (bitlyProvider) {
    bitlyProvider.cfgBitly({
      login: 'aderesoapps',
      api: 'R_d2dfa8f9766f43659bc75fa8b758cbf0',
      // optional. (http://api.bit.ly by default)
      domain: 'https://api-ssl.bitly.com',
      // optional. Tested with bitly api versions: 2.0.1 & 3 (3 by default)
      version: '3'
    });
  }
]);
