'use strict';

// We need this directive since Chrome has issues with Iframes with dynamic
// name attribute as the yielded ones by using interpolation. The node appears
// in the DOM tree, but it's ignored when submiting a form targeting it and
// instead a new tab is opened.

(module => {
  'use strict';
  userOrdersIframe.$inject = ['AdRequest'];
  module.directive('userOrdersIframe', userOrdersIframe);

  function userOrdersIframe(AdRequest) {
    return {
      scope: {
        userObject: '<',
        urlFront: '<'
      },
      link(scope, elem) {
        const userObjectWatcherOff = scope.$watch('userObject', function(user) {
          const id = `user-orders-id`;
          const token = AdRequest.getToken()
          const url = `${scope.urlFront}/frames/orders/?authToken=${token}&userID=${scope.userObject.resource_id}`;
          elem.html(
            `<iframe src="${url}" class="user-orders-view__iframe" name="'${id}'" id="'${id}'" allow="clipboard-write *"></iframe>`
          );
        })

        scope.$on('$destroy', function () {
          userObjectWatcherOff();
        });
      }
    };
  }
})(angular.module('postCenterWebClientApp'));
