'use strict';

var app = angular.module('postCenterWebClientApp');

app.filter('formatSeconds', ['$filter', function($filter) {
  return function(seconds, shortText) {
    if (!angular.isNumber(seconds)) {
      return seconds;
    }
    var suffix = ' ';
    var time = 'asda';

    var SECONDS_IN_MINUTE = 60;
    var SECONDS_IN_HOUR = SECONDS_IN_MINUTE * 60;
    var SECONDS_IN_DAY = SECONDS_IN_HOUR * 24;
    var SECONDS_IN_WEEK = SECONDS_IN_DAY * 7;

    if (seconds < SECONDS_IN_MINUTE) {
      time = Math.round(seconds*10)/10;
      if(shortText){
        suffix += $filter('translate')("ANALYTICS_SHORT_SECONDS");
      } else {
        if(seconds > 1 || seconds === 0){
          suffix += $filter('translate')("ANALYTICS_SECONDS");
        } else {
          suffix += $filter('translate')("ANALYTICS_SECOND");
        }
      }
      return time + suffix;
    } else if (seconds < SECONDS_IN_HOUR) {
      time = Math.round(seconds*10/SECONDS_IN_MINUTE)/10;
      if(shortText){
        suffix += $filter('translate')("ANALYTICS_SHORT_MINUTES");
      } else {
        if(time > 1){
          suffix += $filter('translate')("ANALYTICS_MINUTES");
        } else {
          suffix += $filter('translate')("ANALYTICS_MINUTE");
        }
      }
      return time + suffix;
    } else if (seconds < SECONDS_IN_DAY) {
      time = Math.round(seconds*10/SECONDS_IN_HOUR)/10;
      if(shortText){
        suffix += $filter('translate')("ANALYTICS_SHORT_HOURS");
      } else {
        if(time > 1){
          suffix += $filter('translate')("ANALYTICS_HOURS");
        } else {
          suffix += $filter('translate')("ANALYTICS_HOUR");
        }
      }
      return time + suffix;
    } else if (seconds < SECONDS_IN_WEEK) {
      time = Math.round(seconds*10/SECONDS_IN_DAY)/10;
      if(shortText){
        suffix += $filter('translate')("ANALYTICS_SHORT_DAYS");
      } else {
        if(time > 1){
          suffix += $filter('translate')("ANALYTICS_DAY");
        } else {
          suffix += $filter('translate')("ANALYTICS_DAYS");
        }
      }
      return time + suffix;
    } else {
      time = Math.round(seconds*10/SECONDS_IN_WEEK)/10;
      if(shortText){
        suffix += $filter('translate')("ANALYTICS_SHORT_WEEKS");
      } else {
        if(time > 1){
          suffix += $filter('translate')("ANALYTICS_WEEKS");
        } else {
          suffix += $filter('translate')("ANALYTICS_WEEK");
        }
      }
      return time + suffix;
    }
  };
}]);
