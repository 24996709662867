conditions.InBusinessTimeCondition = conditions.Condition.createConditionType(
  'IN_BUSINESS_TIME',
  {
    isInBusinessTime: function () {
      return true;
    }
  },
  function () {}
);
conditions.Condition.isInBusinessTime = function () {
  return false;
};
conditions.InBusinessTimeCondition.parse = function (conditionList) {
  return new conditions.InBusinessTimeCondition()
};


conditions.NotInBusinessTimeCondition = conditions.Condition.createConditionType(
  'NOT_IN_BUSINESS_TIME',
  {
    isNotInBusinessTime: function () {
      return true;
    }
  },
  function () {}
);
conditions.Condition.isNotInBusinessTime = function () {
  return false;
};
conditions.NotInBusinessTimeCondition.parse = function (conditionList) {
  return new conditions.NotInBusinessTimeCondition()
};


conditions.InWeekdayCondition = conditions.Condition.createConditionType(
  'DAY_OF_THE_WEEK',
  {
    daysOfTheWeek: [
      [0, 'WEEKDAY_MONDAY'],
      [1, 'WEEKDAY_TUESDAY'],
      [2, 'WEEKDAY_WEDNESDAY'],
      [3, 'WEEKDAY_THURSDAY'],
      [4, 'WEEKDAY_FRIDAY'],
      [5, 'WEEKDAY_SATURDAY'],
      [6, 'WEEKDAY_SUNDAY']
    ],
    isInWeekday: function () {
      return true;
    },
    serialize: function () {
      return [this.identifier, this.weekday]
    }
  },
  function (weekday) {
    this.weekday = weekday;
  }
);
conditions.Condition.isInWeekday = function () {
  return false;
};
conditions.InWeekdayCondition.parse = function (conditionList) {
  return new conditions.InWeekdayCondition(conditionList[1])
};


conditions.InDateTimeRangeCondition = conditions.Condition.createConditionType(
  'DATETIME_RANGE',
  {
    isInDateTimeRange: function () {
      return true;
    },
    serialize: function () {
      return [this.identifier, this.startDt, this.endDt]
    }
  },
  function (startDt, endDt) {
    this.startDt = startDt;
    this.endDt = endDt;
  }
);
conditions.Condition.isInDateTimeRange = function () {
  return false;
};
conditions.InDateTimeRangeCondition.parse = function (conditionList) {
  return new conditions.InDateTimeRangeCondition(conditionList[1], conditionList[2])
};


conditions.InTimeRangeCondition = conditions.Condition.createConditionType(
  'TIME_RANGE',
  {
    isInTimeRange: function () {
      return true;
    },
    serialize: function () {
      return [
        this.identifier,
        this.startHour,
        this.startMinute,
        this.endHour,
        this.endMinute
      ]
    }
  },
  function (startHour, startMinute, endHour, endMinute) {
    this.startHour = startHour;
    this.startMinute = startMinute;
    this.endHour = endHour;
    this.endMinute = endMinute;
  }
);
conditions.Condition.isInTimeRange = function () {
  return false;
};
conditions.InTimeRangeCondition.parse = function (conditionList) {
  return new conditions.InTimeRangeCondition(
    conditionList[1],
    conditionList[2],
    conditionList[3],
    conditionList[4]
  )
};
