'use strict';

angular.module('postCenterWebClientApp')
  .directive('adDatetimepicker', function () {
    return {
      controller: 'adDatetimepicker',
      bindToController: {
        model: '=ngModel',
        disabled: '=ngDisabled',
        placement: '@',
        label: '@',
      },
      controllerAs: 'ctrl',
      templateUrl: 'blocks/global/views/ad_datetimepicker.html',
      restrict: 'E',
      scope: {},
    };
  })
  .controller('adDatetimepicker', function(){
    var ctrl = this;
    ctrl.placement = ctrl.placement || "auto bottom";
    ctrl.popover = {
      isOpen: false,
    };
    ctrl.onSetTime = function(){
      ctrl.popover.isOpen = false;
    };
  });
