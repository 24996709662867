app.filter('numeral', function() {
  return function (value, format, preprocess) {
    var valueToReturn = value;

    if (typeof value === 'undefined' || value === null) {
        return '';
    }
    if (angular.isDefined(numeral)){
      valueToReturn = numeral(value).format(format);
    }else{
      console.error && console.error('Numeral JS lib not found');
      valueToReturn = value;
    }

    return valueToReturn
  };
});
