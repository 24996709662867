const ExpandableText = Vue.component('ExpandableText', {
  props: {
    paragraph: {type: String, default: ''},
    maxLength: {type: Number, default: 90},
    maxHeight: {
      type: Number,
      default: null
    },
    labels: {
      type: Object,
      default: null,
      validator: obj =>
        Object.prototype.hasOwnProperty.call(obj, 'more') &&
        Object.prototype.hasOwnProperty.call(obj, 'less')
    }
  },
  data() {
    return {
      showAll: false
    };
  },
  computed: {
    lessOrMore() {
      if (this.paragraph.length > this.maxLength && !this.showAll) {
        return `${this.paragraph.substring(0, this.maxLength)}...`;
      } else {
        return this.paragraph;
      }
    },
    scrolling() {
      if (this.maxHeight) {
        return {
          maxHeight: `${this.maxHeight}px`,
          overflow: 'auto'
        };
      }
      return {};
    },
    toggleLabel() {
      if (this.labels) {
        return this.showAll ? this.labels.less : this.labels.more;
      }
      const toggle = this.showAll ? 0 : 1;
      return this.$tc('readMoreLess', toggle);
    }
  },
  methods: {
    moreOrLess() {
      this.showAll = !this.showAll;
    }
  },
  i18n: {
    messages: {
      en: {
        readMoreLess: 'See more | See less'
      },
      es: {
        readMoreLess: 'Ver más | Ver menos'
      },
      pt: {
        readMoreLess: 'Ver mais | Ver menos'
      }
    }
  },
  template: `<div class="ads-expandable-text" :style="scrolling">
    <p>{{ lessOrMore }}
    <button
      v-if="paragraph.length > maxLength"
      @click="moreOrLess"
      class="ads-button ads-button--link">{{ toggleLabel }}
    </button></p>

  </div>`
});
