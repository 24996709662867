'use strict';

angular
  .module('postCenterWebClientApp')
  .controller('AnalyticsUserMetricsCtrl', AnalyticsUserMetricsController);

AnalyticsUserMetricsController.$inject = [
  '$rootScope',
  '$scope',
  '$filter',
  'FireTagService'
];

function AnalyticsUserMetricsController(
  $rootScope,
  $scope,
  $filter,
  FireTagService
) {
  FireTagService.setPageView({
    title: 'Rendimiento del equipo',
    path: `/${window.location.hash}`
  });
  $scope.cms = angular.copy($rootScope.communityManagersForChart);
  $rootScope.analyticTab.selected = 'user-metrics';

  $scope.widgets = [];
  $scope.period = null;
  $scope.usersCount = 1;

  const generalUser = {
    name: $filter('translate')('ANALYTICS_USER_METRIC_ALL_USERS'),
    cmid: null
  };

  if (!$scope.analyticsData) $scope.analyticsData = {};

  if (!$scope.analyticsData.users) {
    $scope.analyticsData.users = angular.copy($rootScope.communityManagers);
    $scope.usersCount = $scope.analyticsData.users.length;
    if ($scope.usersCount === 0) {
      $scope.usersCount = 1;
    }
  }

  $scope.$on('$destroy', () => {
    analyticsGotPeriodOff();
    analyticsSelectedUserOff();
  });

  const analyticsGotPeriodOff = $scope.$on('analytics:gotPeriod', function(event, period) {
    $scope.period = period;
    updatePeriod(period);
  });

  const analyticsSelectedUserOff =$scope.$watch('analyticsData.selectedUser', function(newUser) {
    let forceUpdate = false;

    angular.forEach($scope.widgets, function(widget) {
      angular.forEach(widget.dataScheme.metrics, function(metric) {
        if (metric.filter) {
          if (metric.filter.value !== newUser.cmid) {
            metric.filter.value = newUser.cmid;
            if (!forceUpdate) {
              forceUpdate = true;
            }
          }
        }
      });
    });

    if (forceUpdate) {
      $scope.$broadcast('analytics:forceUpdate');
    }
  });

  function updatePeriod(period) {
    $scope.selectedPeriod = period;
    angular.forEach($scope.widgets, function(widget) {
      widget.period = period;
    });
  }

  // General widget by cm
  const createdCasesWidget = {
    name: 'createdCases',
    title: 'ANALYTICS_ASSIGNED_CASES',
    period: {},
    realtime: false,
    resume: 'total',
    charts: {
      main: {
        type: 'pie',
        stacked: false
      },
      detail: {
        type: 'areaspline',
        stacked: true
      }
    },
    dataScheme: {
      id: 'OpenedCases',
      type: 'number',
      metrics: [
        {
          name: 'counter',
          type: 'single',
          key: 'cs',
          resolve: null,
          filter: {}
        },
        {
          name: 'detail',
          key: 'ca',
          type: 'crossed',
          crossResolve: {
            resolver: 'id',
            key: 'sn',
            data: $rootScope.accounts
          },
          resolve: {
            resolver: 'id',
            key: 'username',
            data: $rootScope.accounts
          },
          filter: {}
        },
        {
          name: 'total',
          key: 'cs',
          type: 'single',
          resolve: null,
          filter: {}
        }
      ]
    },
    style: {
      icons: {
        email: 'fa-envelope'
      }
    },
    help: {
      text: $filter('translate')('ANALYTICS_USER_ASSIGNED_CASES_HELP_TEXT')
    }
  };

  // General widget by cm
  const openedCasesWidget = {
    name: 'openedCases',
    title: 'ANALYTICS_OPENED_CASES',
    period: {},
    realtime: false,
    resume: 'total',
    charts: {
      main: {
        type: 'pie',
        stacked: false
      },
      detail: {
        type: 'areaspline',
        stacked: true
      }
    },
    dataScheme: {
      id: 'NewOpenedCases',
      type: 'number',
      metrics: [
        {
          name: 'counter',
          type: 'single',
          key: 'cs',
          resolve: null,
          filter: {}
        },
        {
          name: 'detail',
          key: 'ca',
          type: 'crossed',
          crossResolve: {
            resolver: 'id',
            key: 'sn',
            data: $rootScope.accounts
          },
          resolve: {
            resolver: 'id',
            key: 'username',
            data: $rootScope.accounts
          },
          filter: {}
        },
        {
          name: 'total',
          key: 'cs',
          type: 'single',
          resolve: null,
          filter: {}
        }
      ]
    },
    style: {
      icons: {
        email: 'fa-envelope'
      }
    },
    help: {
      text: $filter('translate')('ANALYTICS_USER_OPENED_CASES_HELP_TEXT')
    }
  };

  // General widget by cm
  const inProgressCasesWidget = {
    name: 'inProgressCases',
    title: 'ANALYTICS_IN_PROGRESS_CASES',
    period: {},
    realtime: false,
    resume: 'total',
    charts: {
      main: {
        type: 'pie',
        stacked: false
      },
      detail: {
        type: 'areaspline',
        stacked: true
      }
    },
    dataScheme: {
      id: 'InProgressCases',
      type: 'number',
      metrics: [
        {
          name: 'counter',
          type: 'single',
          key: 'cs',
          resolve: null,
          filter: {}
        },
        {
          name: 'detail',
          key: 'ca',
          type: 'crossed',
          crossResolve: {
            resolver: 'id',
            key: 'sn',
            data: $rootScope.accounts
          },
          resolve: {
            resolver: 'id',
            key: 'username',
            data: $rootScope.accounts
          },
          filter: {}
        },
        {
          name: 'total',
          key: 'cs',
          type: 'single',
          resolve: null,
          filter: {}
        }
      ]
    },
    style: {
      icons: {
        email: 'fa-envelope'
      }
    },
    help: {
      text: $filter('translate')('ANALYTICS_USER_IN_PROGRESS_CASES_HELP_TEXT')
    }
  };

  const closedCasesWidget = {
    name: 'closedCases',
    title: 'ANALYTICS_CLOSED_CASES',
    period: {},
    realtime: false,
    resume: 'total',
    charts: {
      main: {
        type: 'pie',
        stacked: false
      },
      detail: {
        type: 'areaspline',
        stacked: true
      }
    },
    dataScheme: {
      id: 'ClosedCases',
      type: 'number',
      metrics: [
        {
          name: 'counter',
          type: 'single',
          key: 'cs',
          resolve: null,
          filter: {}
        },
        {
          name: 'detail',
          key: 'ca',
          type: 'crossed',
          crossResolve: {
            resolver: 'id',
            key: 'sn',
            data: $rootScope.accounts
          },
          resolve: {
            resolver: 'id',
            key: 'username',
            data: $rootScope.accounts
          },
          filter: {}
        },
        {
          name: 'total',
          key: 'cs',
          type: 'single',
          resolve: null,
          filter: {}
        }
      ]
    },
    style: {
      icons: {
        email: 'fa-envelope'
      }
    },
    help: {
      text: $filter('translate')('ANALYTICS_USER_CLOSED_CASES_HELP_TEXT')
    }
  };

  const ignoredCasesWidget = {
    name: 'ignoredCases',
    title: 'ANALYTICS_IGNORED_CASES',
    period: {},
    realtime: false,
    resume: 'total',
    charts: {
      main: {
        type: 'pie',
        stacked: false
      },
      detail: {
        type: 'areaspline',
        stacked: true
      }
    },
    dataScheme: {
      id: 'IgnoredCases',
      type: 'number',
      metrics: [
        {
          name: 'counter',
          type: 'single',
          key: 'cs',
          resolve: null,
          filter: {}
        },
        {
          name: 'detail',
          key: 'ca',
          type: 'crossed',
          crossResolve: {
            resolver: 'id',
            key: 'sn',
            data: $rootScope.accounts
          },
          resolve: {
            resolver: 'id',
            key: 'username',
            data: $rootScope.accounts
          },
          filter: {}
        },
        {
          name: 'total',
          key: 'cs',
          type: 'single',
          resolve: null,
          filter: {}
        }
      ]
    },
    style: {
      icons: {
        email: 'fa-envelope'
      }
    },
    help: {
      text: $filter('translate')('ANALYTICS_USER_IGNORED_CASES_HELP_TEXT')
    }
  };

  const identifiedClientsWidget = {
    name: 'identifiedClients',
    title: 'ANALYTICS_IDENTIFIED_CLIENTS',
    period: {},
    realtime: false,
    resume: 'total',
    charts: {
      main: {
        type: 'pie',
        stacked: false
      },
      detail: {
        type: 'areaspline',
        stacked: true
      }
    },
    dataScheme: {
      id: 'ClientsIdentified',
      type: 'number',
      metrics: [
        {
          name: 'counter',
          type: 'single',
          key: 'cs',
          resolve: null,
          filter: {}
        },
        {
          name: 'detail',
          key: 'cs',
          type: 'crossed',
          crossResolve: {
            resolver: 'id',
            key: 'sn',
            data: $rootScope.accounts
          },
          resolve: {
            resolver: 'id',
            key: 'username',
            data: $rootScope.accounts
          },
          filter: {}
        },
        {
          name: 'total',
          key: 'cs',
          type: 'single',
          resolve: null,
          filter: {}
        }
      ]
    },
    style: {
      icons: {
        email: 'fa-envelope'
      }
    },
    help: {
      text: $filter('translate')('ANALYTICS_USER_CLIENTS_IDENTIFIED_HELP_TEXT')
    }
  };

  const caseCloseTimeWidget = {
    name: 'caseCloseTime',
    title: 'ANALYTICS_CASE_CLOSE_TIME',
    period: {},
    realtime: false,
    resume: 'average',
    charts: {
      main: {
        type: 'column',
        stacked: false
      },
      detail: {
        type: 'areaspline',
        stacked: false
      }
    },
    dataScheme: {
      id: 'CaseCloseTime',
      type: 'time',
      metrics: [
        {
          name: 'counter',
          type: 'single',
          key: 'cs',
          resolve: null,
          filter: {}
        },
        {
          name: 'detail',
          type: 'crossed',
          key: 'ca',
          crossResolve: {
            resolver: 'id',
            key: 'sn',
            data: $rootScope.accounts
          },
          resolve: {
            resolver: 'id',
            key: 'username',
            data: $rootScope.accounts
          },
          filter: {}
        },
        {
          name: 'total',
          key: 'cs',
          type: 'single',
          resolve: null,
          filter: {}
        }
      ]
    },
    style: {
      icons: {
        email: 'fa-envelope'
      }
    },
    help: {
      text: $filter('translate')('ANALYTICS_USER_CASE_CLOSE_TIME_HELP_TEXT')
    }
  };

  const responseTimeWidget = {
    name: 'responseTime',
    title: 'ANALYTICS_RESPONSE_TIME',
    period: {},
    realtime: false,
    resume: 'average',
    charts: {
      main: {
        type: 'column',
        stacked: false
      },
      detail: {
        type: 'areaspline',
        stacked: false
      }
    },
    dataScheme: {
      id: 'ResponseTime',
      type: 'time',
      metrics: [
        {
          name: 'counter',
          type: 'single',
          key: 'cs',
          resolve: null,
          filter: {}
        },
        {
          name: 'detail',
          key: 'ca',
          type: 'crossed',
          crossResolve: {
            resolver: 'id',
            key: 'sn',
            data: $rootScope.accounts
          },
          resolve: {
            resolver: 'id',
            key: 'username',
            data: $rootScope.accounts
          },
          filter: {}
        },
        {
          name: 'total',
          key: 'cs',
          type: 'single',
          resolve: null,
          filter: {}
        }
      ]
    },
    style: {
      icons: {
        email: 'fa-envelope'
      }
    },
    help: {
      text: $filter('translate')('ANALYTICS_USER_RESPONSE_TIME_HELP_TEXT')
    }
  };
  //TODO darle una vuelta y borrarla
  const onlineStatusWidget = {
    name: 'onlineStatus',
    title: 'ANALYTICS_ONLINE_STATUS',
    type: 'bubble-chart',
    period: {},
    realtime: false,
    resume: 'average',
    charts: {
      main: {
        type: 'pie',
        stacked: false
      },
      detail: {
        type: 'areaspline',
        stacked: true
      }
    },
    dataScheme: {
      id: 'OnlineStatus',
      type: 'percentage',
      metrics: [
        {
          name: 'counter',
          type: 'single',
          key: 'cms',
          resolve: {
            resolver: 'cmid',
            key: 'name',
            data: $scope.cms
          },
          filter: {},
          transform: function(points, labels) {
            if (labels.length > 1) {
              const ti = labels[0];
              const tf = labels[1];
              const minutesInInterval = Math.floor((tf - ti) / (60 * 1000));
              return points.map(function(point) {
                return (
                  (100 * point) / (minutesInInterval * (labels.length / 2))
                );
              });
            }
            return points;
          }
        },
        {
          name: 'detail',
          type: 'single',
          key: 'cms',
          resolve: {
            resolver: 'cmid',
            key: 'name',
            data: $scope.cms
          },
          filter: {},
          transform: function(points, labels) {
            if (labels.length > 1) {
              const ti = labels[0];
              const tf = labels[1];
              const minutesInInterval = Math.floor((tf - ti) / (60 * 1000));
              return points.map(function(point) {
                return (100 * point) / minutesInInterval;
              });
            }
            return points;
          }
        }
      ]
    },
    style: {
      icons: {
        email: 'fa-envelope'
      }
    },
    help: {
      text: $filter('translate')('ANALYTICS_USER_ONLINE_STATUS_HELP_TEXT')
    }
  };

  const typifiedCasesWidget = {
    name: 'typifiedCases',
    title: 'ANALYTICS_TYPIFIED_CASES',
    period: {},
    realtime: false,
    resume: 'total',
    charts: {
      main: {
        type: 'pie',
        stacked: false
      },
      detail: {
        type: 'areaspline',
        stacked: true
      }
    },
    dataScheme: {
      id: 'TypifiedCases',
      type: 'number',
      metrics: [
        {
          name: 'counter',
          type: 'single',
          key: 'cs',
          resolve: null,
          filter: {}
        },
        {
          name: 'detail',
          key: 'ca',
          type: 'crossed',
          crossResolve: {
            resolver: 'id',
            key: 'sn',
            data: $rootScope.accounts
          },
          resolve: {
            resolver: 'id',
            key: 'username',
            data: $rootScope.accounts
          },
          filter: {}
        },
        {
          name: 'total',
          key: 'cs',
          type: 'single',
          resolve: null,
          filter: {}
        }
      ]
    },
    style: {
      icons: {
        email: 'fa-envelope',
        total: 'fa-arrow-right'
      }
    },
    help: {
      text: $filter('translate')('ANALYTICS_TICKETS_TYPIFIED_CASES_HELP_TEXT')
    }
  };

  // Widget for resume

  const createdCasesWidgetResume = {
    name: 'createdCases',
    title: 'ANALYTICS_ASSIGNED_CASES',
    period: {},
    realtime: false,
    resume: 'total',
    charts: {
      main: {
        type: 'pie',
        stacked: false
      },
      detail: {
        type: 'areaspline',
        stacked: true
      }
    },
    dataScheme: {
      id: 'OpenedCases',
      type: 'number',
      metrics: [
        {
          name: 'counter',
          type: 'resume',
          key: 'cs',
          resolve: null
        },
        {
          name: 'detail',
          key: 'cs',
          type: 'aggregated',
          resolve: {
            resolver: 'cmid',
            key: 'name',
            data: $scope.cms
          }
        },
        {
          name: 'total',
          key: 'cms',
          type: 'single',
          resolve: {
            resolver: 'cmid',
            key: 'name',
            data: $scope.cms
          }
        }
      ]
    },
    style: {
      icons: {
        email: 'fa-envelope'
      }
    },
    help: {
      text: $filter('translate')('ANALYTICS_USER_ASSIGNED_CASES_HELP_TEXT')
    }
  };

  const openedCasesWidgetResume = {
    name: 'openedCases',
    title: 'ANALYTICS_OPENED_CASES',
    period: {},
    realtime: false,
    resume: 'total',
    charts: {
      main: {
        type: 'pie',
        stacked: false
      },
      detail: {
        type: 'areaspline',
        stacked: true
      }
    },
    dataScheme: {
      id: 'NewOpenedCases',
      type: 'number',
      metrics: [
        {
          name: 'counter',
          type: 'resume',
          key: 'cs',
          resolve: null
        },
        {
          name: 'detail',
          key: 'cs',
          type: 'aggregated',
          resolve: {
            resolver: 'cmid',
            key: 'name',
            data: $scope.cms
          }
        },
        {
          name: 'total',
          key: 'cms',
          type: 'single',
          resolve: {
            resolver: 'cmid',
            key: 'name',
            data: $scope.cms
          }
        }
      ]
    },
    style: {
      icons: {
        email: 'fa-envelope'
      }
    },
    help: {
      text: $filter('translate')('ANALYTICS_USER_OPENED_CASES_HELP_TEXT')
    }
  };

  const inProgressCasesWidgetResume = {
    name: 'inProgressCases',
    title: 'ANALYTICS_IN_PROGRESS_CASES',
    period: {},
    realtime: false,
    resume: 'total',
    charts: {
      main: {
        type: 'pie',
        stacked: false
      },
      detail: {
        type: 'areaspline',
        stacked: true
      }
    },
    dataScheme: {
      id: 'InProgressCases',
      type: 'number',
      metrics: [
        {
          name: 'counter',
          type: 'resume',
          key: 'cs',
          resolve: null
        },
        {
          name: 'detail',
          key: 'cs',
          type: 'aggregated',
          resolve: {
            resolver: 'cmid',
            key: 'name',
            data: $scope.cms
          }
        },
        {
          name: 'total',
          key: 'cms',
          type: 'single',
          resolve: {
            resolver: 'cmid',
            key: 'name',
            data: $scope.cms
          }
        }
      ]
    },
    style: {
      icons: {
        email: 'fa-envelope'
      }
    },
    help: {
      text: $filter('translate')('ANALYTICS_USER_IN_PROGRESS_CASES_HELP_TEXT')
    }
  };

  const closedCasesWidgetResume = {
    name: 'closedCases',
    title: 'ANALYTICS_CLOSED_CASES',
    period: {},
    realtime: false,
    resume: 'total',
    charts: {
      main: {
        type: 'pie',
        stacked: false
      },
      detail: {
        type: 'areaspline',
        stacked: true
      }
    },
    dataScheme: {
      id: 'ClosedCases',
      type: 'number',
      metrics: [
        {
          name: 'counter',
          type: 'single',
          key: 'src',
          resolve: null
        },
        {
          name: 'detail',
          key: 'cs',
          type: 'aggregated',
          resolve: {
            resolver: 'cmid',
            key: 'name',
            data: $scope.cms
          }
        },
        {
          name: 'total',
          key: 'cms',
          type: 'single',
          resolve: {
            resolver: 'cmid',
            key: 'name',
            data: $scope.cms
          }
        }
      ]
    },
    style: {
      icons: {
        email: 'fa-envelope'
      }
    },
    help: {
      text: $filter('translate')('ANALYTICS_USER_CLOSED_CASES_HELP_TEXT')
    }
  };

  const ignoredCasesWidgetResume = {
    name: 'ignoredCases',
    title: 'ANALYTICS_IGNORED_CASES',
    period: {},
    realtime: false,
    resume: 'total',
    charts: {
      main: {
        type: 'pie',
        stacked: false
      },
      detail: {
        type: 'areaspline',
        stacked: true
      }
    },
    dataScheme: {
      id: 'IgnoredCases',
      type: 'number',
      metrics: [
        {
          name: 'counter',
          type: 'single',
          key: 'src',
          resolve: null
        },
        {
          name: 'detail',
          key: 'cs',
          type: 'aggregated',
          resolve: {
            resolver: 'cmid',
            key: 'name',
            data: $scope.cms
          }
        },
        {
          name: 'total',
          key: 'cms',
          type: 'single',
          resolve: {
            resolver: 'cmid',
            key: 'name',
            data: $scope.cms
          }
        }
      ]
    },
    style: {
      icons: {
        email: 'fa-envelope'
      }
    },
    help: {
      text: $filter('translate')('ANALYTICS_USER_IGNORED_CASES_HELP_TEXT')
    }
  };

  const identifiedClientsWidgetResume = {
    name: 'identifiedClients',
    title: 'ANALYTICS_IDENTIFIED_CLIENTS',
    period: {},
    realtime: false,
    resume: 'total',
    charts: {
      main: {
        type: 'pie',
        stacked: false
      },
      detail: {
        type: 'areaspline',
        stacked: true
      }
    },
    dataScheme: {
      id: 'ClientsIdentified',
      type: 'number',
      metrics: [
        {
          name: 'counter',
          type: 'single',
          key: 'src',
          resolve: null
        },
        {
          name: 'detail',
          key: 'cs',
          type: 'aggregated',
          resolve: {
            resolver: 'cmid',
            key: 'name',
            data: $scope.cms
          }
        },
        {
          name: 'total',
          key: 'cms',
          type: 'single',
          resolve: {
            resolver: 'cmid',
            key: 'name',
            data: $scope.cms
          }
        }
      ]
    },
    style: {
      icons: {
        email: 'fa-envelope'
      }
    },
    help: {
      text: $filter('translate')('ANALYTICS_USER_CLIENTS_IDENTIFIED_HELP_TEXT')
    }
  };

  const caseCloseTimeWidgetResume = {
    name: 'caseCloseTime',
    title: 'ANALYTICS_CASE_CLOSE_TIME',
    period: {},
    realtime: false,
    resume: 'average',
    charts: {
      main: {
        type: 'column',
        stacked: false
      },
      detail: {
        type: 'areaspline',
        stacked: false
      }
    },
    dataScheme: {
      id: 'CaseCloseTime',
      type: 'time',
      metrics: [
        {
          name: 'counter',
          type: 'single',
          key: 'src',
          resolve: null
        },
        {
          name: 'detail',
          key: 'cs',
          type: 'aggregated',
          resolve: {
            resolver: 'cmid',
            key: 'name',
            data: $scope.cms
          }
        },
        {
          name: 'total',
          key: 'cms',
          type: 'single',
          resolve: {
            resolver: 'cmid',
            key: 'name',
            data: $scope.cms
          }
        }
      ]
    },
    style: {
      icons: {
        email: 'fa-envelope'
      }
    },
    help: {
      text: $filter('translate')('ANALYTICS_USER_CASE_CLOSE_TIME_HELP_TEXT')
    }
  };

  const responseTimeWidgetResume = {
    name: 'responseTime',
    title: 'ANALYTICS_RESPONSE_TIME',
    period: {},
    realtime: false,
    resume: 'average',
    charts: {
      main: {
        type: 'column',
        stacked: false
      },
      detail: {
        type: 'areaspline',
        stacked: false
      }
    },
    dataScheme: {
      id: 'ResponseTime',
      type: 'time',
      metrics: [
        {
          name: 'counter',
          type: 'single',
          key: 'src',
          resolve: null
        },
        {
          name: 'detail',
          key: 'cs',
          type: 'aggregated',
          resolve: {
            resolver: 'cmid',
            key: 'name',
            data: $scope.cms
          }
        },
        {
          name: 'total',
          key: 'cms',
          type: 'single',
          resolve: {
            resolver: 'cmid',
            key: 'name',
            data: $scope.cms
          }
        }
      ]
    },
    style: {
      icons: {
        email: 'fa-envelope'
      }
    },
    help: {
      text: $filter('translate')('ANALYTICS_USER_RESPONSE_TIME_HELP_TEXT')
    }
  };

  const onlineStatusWidgetResume = {
    name: 'onlineStatus',
    title: 'ANALYTICS_ONLINE_STATUS',
    type: 'chart-column',
    period: {},
    realtime: false,
    resume: 'average',
    charts: {
      main: {
        type: 'onlinestate-column',
        stacked: false
      },
      detail: {
        type: 'areaspline',
        stacked: true
      }
    },
    dataScheme: {
      id: 'OnlineStatus',
      type: 'percentage',
      metrics: [
        {
          name: 'counter',
          type: 'number',
          key: 'cms',
          resolve: {
            resolver: 'cmid',
            key: 'name',
            data: $scope.cms
          },
          transform: function(points, labels) {
            if (labels.length > 1) {
              const ti = labels[0];
              const tf = labels[1];
              const minutesInInterval = Math.floor((tf - ti) / (60 * 1000));
              return points.map(function(point) {
                return (
                  (100 * point) / (minutesInInterval * (labels.length / 2))
                );
              });
            }
            return points;
          }
        },
        {
          name: 'detail',
          type: 'crossed',
          key: 'cms',
          resolve: {
            resolver: 'cmid',
            key: 'name',
            data: $scope.cms
          },
          transform: function(points, labels) {
            if (labels.length > 1) {
              const ti = labels[0];
              const tf = labels[1];
              const minutesInInterval = Math.floor((tf - ti) / (60 * 1000));
              return points.map(function(point) {
                return (100 * point) / minutesInInterval;
              });
            }
            return points;
          }
        },
        {
          name: 'total',
          key: 'cms',
          type: 'average',
          resolve: {
            resolver: 'cmid',
            key: 'name',
            data: $scope.cms
          },
          transform: function(points, labels) {
            if (labels.length > 1) {
              let total = 0;
              const half = points.length / 2;
              points.map(function(point, index) {
                if (index >= half) {
                  total = total + point;
                }
              });
              points = [1, total];
            }
            return points;
          }
        }
      ]
    },
    help: {
      text: $filter('translate')('ANALYTICS_USER_ONLINE_STATUS_HELP_TEXT')
    }
  };

  const typifiedCasesWidgetResume = {
    name: 'typifiedCases',
    title: 'ANALYTICS_TYPIFIED_CASES',
    period: {},
    realtime: false,
    resume: 'total',
    charts: {
      main: {
        type: 'pie',
        stacked: false
      },
      detail: {
        type: 'areaspline',
        stacked: true
      }
    },
    dataScheme: {
      id: 'TypifiedCases',
      type: 'number',
      metrics: [
        {
          name: 'counter',
          type: 'single',
          key: 'src',
          resolve: null
        },
        {
          name: 'detail',
          key: 'cs',
          type: 'aggregated',
          resolve: {
            resolver: 'cmid',
            key: 'name',
            data: $scope.cms
          }
        },
        {
          name: 'total',
          key: 'cms',
          type: 'single',
          resolve: {
            resolver: 'cmid',
            key: 'name',
            data: $scope.cms
          }
        }
      ]
    },
    style: {
      icons: {
        email: 'fa-envelope',
        total: 'fa-arrow-right'
      }
    },
    help: {
      text: $filter('translate')('ANALYTICS_TICKETS_TYPIFIED_CASES_HELP_TEXT')
    }
  };

  $scope.selectUser = function(user) {
    $scope.widgets = [];
    if (angular.isDefined(user)) {
      $scope.widgets.push(
        createdCasesWidget,
        openedCasesWidget,
        closedCasesWidget,
        ignoredCasesWidget,
        identifiedClientsWidget,
        caseCloseTimeWidget,
        responseTimeWidget,
        typifiedCasesWidget
      );
      if ($rootScope.establishment.config.workflow_enabled) {
        $scope.widgets.splice(2, 0, inProgressCasesWidget);
      }
      updatePeriod($scope.period);
      $scope.analyticsData.selectedUser = user;
    } else {
      $scope.widgets.push(
        createdCasesWidgetResume,
        openedCasesWidgetResume,
        closedCasesWidgetResume,
        ignoredCasesWidgetResume,
        identifiedClientsWidgetResume,
        caseCloseTimeWidgetResume,
        responseTimeWidgetResume,
        typifiedCasesWidgetResume
      );
      if ($rootScope.establishment.config.workflow_enabled) {
        $scope.widgets.splice(2, 0, inProgressCasesWidgetResume);
      }
      updatePeriod($scope.period);
      $scope.analyticsData.selectedUser = generalUser;
    }
  };

  $scope.selectUser();
}
