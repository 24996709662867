store.registerModule('user', {
  namespaced: true,
  state: () => ({
    first_name:'',
    last_name:''
  }),
  getters: {
    name: state => state.first_name,
    fullName: state => `${state.first_name} ${state.last_name}`,
    language: state => state.profile.language
  },
  mutations:{
    SET_USER(state, payload) {
      state = {...state, ...payload}
    }
  },
  actions: {
    async getUser({commit}) {
      return await this.UserService.getProfile().then(user => {
        commit('SET_USER', user);
        return user;
      });
    }
  }
});
