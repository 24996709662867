conditions.SocialAccountCondition = conditions.Condition.createConditionType(
  'SOCIAL_ACCOUNT',
  {
    isSocialAccount: function () {
      return true;
    },
    serialize: function () {
      return [this.identifier, this.socialAccountId]
    }
  },
  function (socialAccountId) {
    this.socialAccountId = socialAccountId;
  }
);
conditions.Condition.isSocialAccount = function () {
  return false;
};
conditions.SocialAccountCondition.parse = function (conditionList) {
  return new conditions.SocialAccountCondition(conditionList[1])
};


conditions.CaseTypificationCondition = conditions.Condition.createConditionType(
  'CASE_TYPIFICATION',
  {
    isCaseTypification: function () {
      return true;
    },
    serialize: function () {
      return [this.identifier, this.customDataKey, this.optionKey]
    }
  },
  function (customDataKey, optionKey) {
    this.customDataKey = customDataKey;
    this.optionKey = optionKey;
  }
);
conditions.Condition.isCaseTypification = function () {
  return false;
};
conditions.CaseTypificationCondition.parse = function (conditionList) {
  return new conditions.CaseTypificationCondition(conditionList[1], conditionList[2])
};


conditions.NotAssignedToCMCondition = conditions.Condition.createConditionType(
  'NOT_ASSIGNED_TO_CM',
  {
    isNotAssignedToCM: function () {
      return true;
    }
  },
  function () {}
);
conditions.Condition.isNotAssignedToCM = function () {
  return false;
};
conditions.NotAssignedToCMCondition.parse = function (conditionList) {
  return new conditions.NotAssignedToCMCondition()
};


conditions.TicketAddressedCondition = conditions.Condition.createConditionType(
  'TICKET_ADDRESSED',
  {
    isTicketAddressed: function () {
      return true;
    }
  },
  function () {}
);
conditions.Condition.isTicketAddressed = function () {
  return false;
};
conditions.TicketAddressedCondition.parse = function (conditionList) {
  return new conditions.TicketAddressedCondition()
};


conditions.TicketNotAddressedCondition = conditions.Condition.createConditionType(
  'NOT_TICKET_ADDRESSED',
  {
    isTicketNotAddressed: function () {
      return true;
    }
  },
  function () {}
);
conditions.Condition.isTicketNotAddressed = function () {
  return false;
};
conditions.TicketNotAddressedCondition.parse = function (conditionList) {
  return new conditions.TicketNotAddressedCondition()
};


conditions.TicketNotOnDerivationCondition = conditions.Condition.createConditionType(
  'NOT_TICKET_ON_DERIVATION',
  {
    isTicketNotOnDerivation: function () {
      return true;
    }
  },
  function () {}
);
conditions.Condition.isTicketNotOnDerivation = function () {
  return false;
};
conditions.TicketNotOnDerivationCondition.parse = function (conditionList) {
  return new conditions.TicketNotOnDerivationCondition()
};
