'use strict';

(function(){

  const COLORS = [
    // Green
    //"B4F38C",
    "93E76D",
    "65D740",
    "46B82E",
    "2C9A20",

    // Red
    //"FFA09C",
    "FF7C6F",
    "FF4040",
    "DB2E3D",
    "B7203A",

    // Yellow / Brown
    //"FFEB8C",
    //"FFE26F",
    "FFD540",
    "DBB12E",
    "B78F20",

    // Purple
    //"EC8DE6",
    "D66CD9",
    "B340C0",
    "8F2EA5",
    "6E208A"
  ]

  angular.module('postCenterWebClientApp')
    .component('adEmailAvatar', {
      bindings: {
        name: '@',
        color: '@'
      },
      templateUrl: 'blocks/global/views/ad_email_avatar.html',
      controller: 'adEmailAvatarCtrl'
    })
    .controller('adEmailAvatarCtrl', function(){
      var self = this;
      self.$onChanges = function(changesObj){
        if('name' in changesObj){
          var name = changesObj.name.currentValue;
          self.initials = name ? computeInitials(name) : '';
        }

        if('color' in changesObj){
          var color = changesObj.color.currentValue;
          self.bgColor = intToRGB(hashCode(color));
        }
      };

      function computeInitials(name){
        var initials;
        name = name.split(" ");
        if(name.length > 1){
          if(typeof name[1][0] != "undefined"){
            initials = name[0][0] + name[1][0];
          }else{
            initials = name[0][0]
          }
        }else{
          initials = name[0][0];
        }
        return initials?.toUpperCase();
      }

      function hashCode(str: string): number {
        var hash = 0;
        for (var i = 0; i < str.length; i++) {
           hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        return hash & 0xFFFFFF;
      }

      function intToRGB(i: number): string {
        const color = COLORS[i % COLORS.length]
        return '#' + color
      }
    });
})();
