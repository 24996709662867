'use strict';

var app = angular.module('postCenterWebClientApp');
app.controller('AnalyticsMessagesCtrl', [
  '$rootScope',
  '$scope',
  '$filter',
  'SocialAccountService',
  'FireTagService',
  function(
    $rootScope,
    $scope,
    $filter,
    SocialAccountService,
    FireTagService
  ) {
    FireTagService.setPageView({
      title: 'Mensajes',
      path: `/${window.location.hash}`
    });
    $rootScope.analyticTab.selected = 'messages';
    $scope.widgets = [];

    $scope.$on('$destroy', () => {
      analyticsGotPeriodOff();
    });

    const analyticsGotPeriodOff = $scope.$on('analytics:gotPeriod', function(event, period) {
      $scope.selectedPeriod = period;
      angular.forEach($scope.widgets, function(widget) {
        widget.period = period;
      });
    });

    function defineCharts(socialAccounts) {
      const mentionsWidget = {
        name: 'mentions',
        title: 'ANALYTICS_MENTIONS',
        period: $scope.selectedPeriod,
        realtime: false,
        resume: 'total',
        charts: {
          main: {
            type: 'pie',
            stacked: false
          },
          detail: {
            type: 'areaspline',
            stacked: true
          }
        },
        dataScheme: {
          id: 'Mentions',
          type: 'number',
          metrics: [
            {
              name: 'counter',
              type: 'single',
              key: 'src',
              resolve: null
            },
            {
              name: 'detail',
              key: 'sa',
              type: 'aggregated',
              resolve: {
                resolver: 'id',
                key: 'username',
                data: socialAccounts
              }
            },
            {
              name: 'total',
              key: 'src',
              type: 'single',
              resolve: null
            }
          ]
        },
        style: {
          icons: {
            email: 'fa-envelope'
          }
        },
        help: {
          text: $filter('translate')('ANALYTICS_MESSAGES_MENTIONS_HELP_TEXT')
        }
      };

      const publicMentionsWidget = angular.copy(mentionsWidget);
      publicMentionsWidget.dataScheme.id = 'PublicMentions';
      publicMentionsWidget.title = 'ANALYTICS_PUBLIC_MENTIONS';
      publicMentionsWidget.help.text = $filter('translate')(
        'ANALYTICS_PUBLIC_MENTIONS_HELP_TEXT'
      );

      const privateMentionsWidget = angular.copy(mentionsWidget);
      privateMentionsWidget.dataScheme.id = 'PrivateMentions';
      privateMentionsWidget.title = 'ANALYTICS_PRIVATE_MENTIONS';
      privateMentionsWidget.help.text = $filter('translate')(
        'ANALYTICS_PRIVATE_MENTIONS_HELP_TEXT'
      );

      const publicAnswersWidget = angular.copy(mentionsWidget);
      publicAnswersWidget.dataScheme.id = 'PublicAnswers';
      publicAnswersWidget.title = 'ANALYTICS_PUBLIC_ANSWERS';
      publicAnswersWidget.help.text = $filter('translate')(
        'ANALYTICS_PUBLIC_ANSWERS_HELP_TEXT'
      );

      const privateAnswersWidget = angular.copy(mentionsWidget);
      privateAnswersWidget.dataScheme.id = 'PrivateAnswers';
      privateAnswersWidget.title = 'ANALYTICS_PRIVATE_ANSWERS';
      privateAnswersWidget.help.text = $filter('translate')(
        'ANALYTICS_PRIVATE_ANSWERS_HELP_TEXT'
      );
      const responseTimeWidget = {
        name: 'response-time',
        title: 'ANALYTICS_RESPONSE_TIME',
        period: $scope.selectedPeriod,
        realtime: false,
        resume: 'average',
        charts: {
          main: {
            type: 'column',
            stacked: false
          },
          detail: {
            type: 'areaspline',
            stacked: false
          }
        },
        dataScheme: {
          id: 'ResponseTime',
          type: 'time',
          metrics: [
            {
              name: 'counter',
              type: 'single',
              key: 'src',
              resolve: null
            },
            {
              name: 'detail',
              key: 'sa',
              type: 'aggregated',
              resolve: {
                resolver: 'id',
                key: 'username',
                data: socialAccounts
              }
            },
            {
              name: 'total',
              key: 'src',
              type: 'single',
              resolve: null
            }
          ]
        },
        style: {
          icons: {
            email: 'fa-envelope'
          }
        },
        help: {
          text: $filter('translate')(
            'ANALYTICS_MESSAGES_RESPONSE_TIME_HELP_TEXT'
          )
        }
      };

      $scope.widgets.push(
        mentionsWidget,
        publicMentionsWidget,
        privateMentionsWidget,
        responseTimeWidget,
        publicAnswersWidget,
        privateAnswersWidget
      );
    }
    $scope.loading = true;
    SocialAccountService.getChartSocialAccounts().then(function(
      socialAccounts
    ) {
      defineCharts(socialAccounts);
      $scope.loading = false;
    });
  }
]);
