(function(module) {
  'use strict';
  oldCase.$inject = [];
  function oldCase() {
    return {
      templateUrl: 'blocks/case/views/old_case.html',
      restrict: 'E',
      controller: 'caseOldCaseCtrl',
      controllerAs: 'vm',
      scope: {
        currentCase: '=',
        userObject: '='
      },
      bindToController: true
    };
  }
  module.directive('oldCase', oldCase);
})(angular.module('postCenterWebClientApp'));
