'use strict';

angular.module('postCenterWebClientApp')
  .directive('messageDocument', function () {
    return {
      scope: {
        link: '@',
        text: '@',
        data: '='
      },
      templateUrl: 'blocks/message/views/message_document.html',
      restrict: 'E',
      controller: ['$scope', '$filter',
      function ($scope, $filter) {

        function getFileName(text, data) {
          if (text !== 'MEDIA_MESSAGE' && text !== ''){
            return text;
          }
          if (data.hasOwnProperty('name')) {
            return data.name;
          } else {
           return $filter('translate')('MESSAGE_DOCUMENT_UKNOWN');
          }
        }

        function getExtension(link) {
          if (link.indexOf("?") !== -1) {
            return link.split('?')[0].split('.').pop();
          } else {
            return link.split('.').pop();
          }
        }

        $scope.filename = getFileName($scope.text, $scope.data);
        $scope.extension = getExtension($scope.link);
      }]
    };
  });
