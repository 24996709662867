'use strict';

var app = angular.module('postCenterWebClientApp');
app.controller('AnalyticsTicketsCtrl', [
  '$rootScope',
  '$scope',
  '$filter',
  'SocialAccountService',
  'FireTagService',
  function($rootScope, $scope, $filter, SocialAccountService, FireTagService) {
    FireTagService.setPageView({
      title: 'Tickets de atención',
      path: `/${window.location.hash}`
    });
    $rootScope.analyticTab.selected = 'tickets';

    $scope.widgets = [];

    $scope.$on('$destroy', () => {
      analyticsGotPeriodOff();
    });

    const analyticsGotPeriodOff = $scope.$on('analytics:gotPeriod', function(event, period) {
      $scope.selectedPeriod = period;
      angular.forEach($scope.widgets, function(widget) {
        widget.period = period;
      });
    });

    function defineCharts(socialAccounts) {
      const createdCasesWidget = {
        name: 'createdCases',
        title: 'ANALYTICS_CREATED_CASES',
        period: $scope.selectedPeriod,
        realtime: false,
        resume: 'total',
        charts: {
          main: {
            type: 'pie',
            stacked: false
          },
          detail: {
            type: 'areaspline',
            stacked: true
          }
        },
        dataScheme: {
          id: 'OpenedCases',
          type: 'number',
          metrics: [
            {
              name: 'counter',
              type: 'single',
              key: 'src',
              resolve: null
            },
            {
              name: 'detail',
              key: 'sa',
              type: 'aggregated',
              resolve: {
                resolver: 'id',
                key: 'username',
                data: socialAccounts
              }
            },
            {
              name: 'total',
              key: 'src',
              type: 'single',
              resolve: null
            }
          ]
        },
        style: {
          icons: {
            email: 'fa-envelope'
          }
        },
        help: {
          text: $filter('translate')(
            'ANALYTICS_TICKETS_CREATED_CASES_HELP_TEXT'
          )
        }
      };

      const openedCasesWidget = {
        name: 'openedCases',
        title: 'ANALYTICS_OPENED_CASES',
        period: $scope.selectedPeriod,
        realtime: false,
        resume: 'total',
        charts: {
          main: {
            type: 'pie',
            stacked: false
          },
          detail: {
            type: 'areaspline',
            stacked: true
          }
        },
        dataScheme: {
          id: 'NewOpenedCases',
          type: 'number',
          metrics: [
            {
              name: 'counter',
              type: 'single',
              key: 'src',
              resolve: null
            },
            {
              name: 'detail',
              key: 'sa',
              type: 'aggregated',
              resolve: {
                resolver: 'id',
                key: 'username',
                data: socialAccounts
              }
            },
            {
              name: 'total',
              key: 'src',
              type: 'single',
              resolve: null
            }
          ]
        },
        style: {
          icons: {
            email: 'fa-envelope'
          }
        },
        help: {
          text: $filter('translate')('ANALYTICS_TICKETS_OPENED_CASES_HELP_TEXT')
        }
      };

      const inProgressCases = {
        name: 'inProgressCases',
        title: 'ANALYTICS_IN_PROGRESS_CASES',
        period: $scope.selectedPeriod,
        realtime: false,
        resume: 'total',
        charts: {
          main: {type: 'pie', stacked: false},
          detail: {type: 'areaspline', stacked: true}
        },
        dataScheme: {
          id: 'InProgressCases',
          type: 'number',
          metrics: [
            {
              name: 'counter',
              type: 'single',
              key: 'src',
              resolve: null
            },
            {
              name: 'detail',
              key: 'sa',
              type: 'aggregated',
              resolve: {
                resolver: 'id',
                key: 'username',
                data: socialAccounts
              }
            },
            {
              name: 'total',
              key: 'src',
              type: 'single',
              resolve: null
            }
          ]
        },
        style: {icons: {email: 'fa-envelope'}},
        help: {
          text: $filter('translate')(
            'ANALYTICS_TICKETS_IN_PROGRESS_CASES_HELP_TEXT'
          )
        }
      };

      const closedCasesWidget = {
        name: 'closedCases',
        title: 'ANALYTICS_CLOSED_CASES',
        period: $scope.selectedPeriod,
        realtime: false,
        resume: 'total',
        charts: {
          main: {
            type: 'pie',
            stacked: false
          },
          detail: {
            type: 'areaspline',
            stacked: true
          }
        },
        dataScheme: {
          id: 'ClosedCases',
          type: 'number',
          metrics: [
            {
              name: 'counter',
              type: 'single',
              key: 'src',
              resolve: null
            },
            {
              name: 'detail',
              key: 'sa',
              type: 'aggregated',
              resolve: {
                resolver: 'id',
                key: 'username',
                data: socialAccounts
              }
            },
            {
              name: 'total',
              key: 'src',
              type: 'single',
              resolve: null
            }
          ]
        },
        style: {
          icons: {
            email: 'fa-envelope'
          }
        },
        help: {
          text: $filter('translate')('ANALYTICS_TICKETS_CLOSED_CASES_HELP_TEXT')
        }
      };

      const ignoredCasesWidget = {
        name: 'ignoredCases',
        title: 'ANALYTICS_IGNORED_CASES',
        period: $scope.selectedPeriod,
        realtime: false,
        resume: 'total',
        charts: {
          main: {
            type: 'pie',
            stacked: false
          },
          detail: {
            type: 'areaspline',
            stacked: true
          }
        },
        dataScheme: {
          id: 'IgnoredCases',
          type: 'number',
          metrics: [
            {
              name: 'counter',
              type: 'single',
              key: 'src',
              resolve: null
            },
            {
              name: 'detail',
              key: 'sa',
              type: 'aggregated',
              resolve: {
                resolver: 'id',
                key: 'username',
                data: socialAccounts
              }
            },
            {
              name: 'total',
              key: 'src',
              type: 'single',
              resolve: null
            }
          ]
        },
        style: {
          icons: {
            email: 'fa-envelope'
          }
        },
        help: {
          text: $filter('translate')(
            'ANALYTICS_TICKETS_IGNORED_CASES_HELP_TEXT'
          )
        }
      };

      const caseCloseTime = {
        name: 'caseCloseTime',
        title: 'ANALYTICS_CASE_CLOSE_TIME',
        period: $scope.selectedPeriod,
        realtime: false,
        resume: 'average',
        charts: {
          main: {
            type: 'column',
            stacked: false
          },
          detail: {
            type: 'areaspline',
            stacked: false
          }
        },
        dataScheme: {
          id: 'CaseCloseTime',
          type: 'time',
          metrics: [
            {
              name: 'counter',
              type: 'single',
              key: 'src',
              resolve: null
            },
            {
              name: 'detail',
              key: 'sa',
              type: 'aggregated',
              resolve: {
                resolver: 'id',
                key: 'username',
                data: socialAccounts
              }
            },
            {
              name: 'total',
              key: 'src',
              type: 'single',
              resolve: null
            }
          ]
        },
        style: {
          icons: {
            email: 'fa-envelope'
          }
        },
        help: {
          text: $filter('translate')(
            'ANALYTICS_TICKETS_CASE_CLOSE_TIME_HELP_TEXT'
          )
        }
      };

      const mergedCasesWidget = {
        name: 'mergedCases',
        title: 'ANALYTICS_MERGED_CASES',
        period: $scope.selectedPeriod,
        realtime: false,
        resume: 'total',
        charts: {
          main: {
            type: 'pie',
            stacked: false
          },
          detail: {
            type: 'areaspline',
            stacked: true
          }
        },
        dataScheme: {
          id: 'MergedCases',
          type: 'number',
          metrics: [
            {
              name: 'counter',
              type: 'single',
              key: 'src',
              resolve: null
            },
            {
              name: 'detail',
              key: 'sa',
              type: 'aggregated',
              resolve: {
                resolver: 'id',
                key: 'username',
                data: socialAccounts
              }
            },
            {
              name: 'total',
              key: 'src',
              type: 'single',
              resolve: null
            }
          ]
        },
        style: {
          icons: {
            email: 'fa-envelope'
          }
        },
        help: {
          text: $filter('translate')('ANALYTICS_TICKETS_MERGED_CASES_HELP_TEXT')
        }
      };

      const importantCasesWidget = {
        name: 'importantCases',
        title: 'ANALYTICS_IMPORTANT_CASES',
        period: $scope.selectedPeriod,
        realtime: false,
        resume: 'total',
        charts: {
          main: {
            type: 'pie',
            stacked: false
          },
          detail: {
            type: 'areaspline',
            stacked: true
          }
        },
        dataScheme: {
          id: 'ImportantCases',
          type: 'number',
          metrics: [
            {
              name: 'counter',
              type: 'single',
              key: 'src',
              resolve: null
            },
            {
              name: 'detail',
              key: 'sa',
              type: 'aggregated',
              resolve: {
                resolver: 'id',
                key: 'username',
                data: socialAccounts
              }
            },
            {
              name: 'total',
              key: 'src',
              type: 'single',
              resolve: null
            }
          ]
        },
        style: {
          icons: {
            email: 'fa-envelope'
          }
        },
        help: {
          text: $filter('translate')(
            'ANALYTICS_TICKETS_IMPORTANT_CASES_HELP_TEXT'
          )
        }
      };

      const typifiedCasesWidget = {
        name: 'typifiedCases',
        title: 'ANALYTICS_TYPIFIED_CASES',
        period: $scope.selectedPeriod,
        realtime: false,
        resume: 'total',
        charts: {
          main: {
            type: 'pie',
            stacked: false
          },
          detail: {
            type: 'areaspline',
            stacked: true
          }
        },
        dataScheme: {
          id: 'TypifiedCases',
          type: 'number',
          metrics: [
            {
              name: 'counter',
              type: 'single',
              key: 'src',
              resolve: null
            },
            {
              name: 'detail',
              key: 'sa',
              type: 'aggregated',
              resolve: {
                resolver: 'id',
                key: 'username',
                data: socialAccounts
              }
            },
            {
              name: 'total',
              key: 'src',
              type: 'single',
              resolve: null
            }
          ]
        },
        style: {
          icons: {
            email: 'fa-envelope',
            total: 'fa-arrow-right'
          }
        },
        help: {
          text: $filter('translate')(
            'ANALYTICS_TICKETS_IMPORTANT_CASES_HELP_TEXT'
          )
        }
      };

      $scope.widgets.push(
        createdCasesWidget,
        openedCasesWidget,
        closedCasesWidget,
        ignoredCasesWidget,
        caseCloseTime,
        mergedCasesWidget,
        importantCasesWidget,
        typifiedCasesWidget
      );
      if ($rootScope.establishment.config.workflow_enabled) {
        $scope.widgets.splice(1, 0, inProgressCases);
      }

      // ---------
      //  CUSTOM DATA
      // --------------

      const ticketCustomData = $rootScope.customData.filter(function(data) {
        return (
          data.entity === 'C' &&
          !data.deleted &&
          data.options &&
          data.options.length > 0
        );
      });

      ticketCustomData.forEach(function(data) {
        $scope.widgets.push({
          name: 'customData::' + data.key,
          title:
            data.label.charAt(0).toUpperCase() +
            data.label.slice(1).toLowerCase(),
          subtitle: $filter('translate')('ANALYTICS_CUSTOM_DATA'),
          period: $scope.selectedPeriod,
          realtime: false,
          resume: 'total',
          charts: {
            main: {
              type: 'pie',
              stacked: false
            },
            detail: {
              type: 'customdata-column',
              stacked: true
            }
          },
          dataScheme: {
            id: 'CustomData::' + data.key,
            type: 'number',
            metrics: [
              {
                name: 'counter',
                type: 'single',
                key: 'src',
                resolve: null
              },
              {
                name: 'detail',
                type: 'aggregated',
                key: 'ts',
                resolve: {
                  resolver: 'key',
                  key: 'value',
                  data: data.options
                },
                series: 'counter'
              },
              {
                name: 'total',
                key: 'typ',
                type: 'single',
                resolve: {
                  resolver: 'key',
                  key: 'value',
                  data: data.options
                },
                series: 'counter'
              }
            ]
          },
          style: {
            icons: {
              email: 'fa-envelope',
              total: 'fa-arrow-right'
            }
          },
          help: {
            text: $filter('translate')(
              'ANALYTICS_TICKETS_CUSTOM_DATA_HELP_TEXT'
            )
          }
        });
      });
    }
    $scope.loading = true;
    SocialAccountService.getChartSocialAccounts().then(function(
      socialAccounts
    ) {
      defineCharts(socialAccounts);
      $scope.loading = false;
    });
  }
]);
