'use strict';

angular.module('postCenterWebClientApp')
  .directive('adConfirmClick', function($parse, AdConfirmModal){
    return {
      restrict: 'A',
      link: function(scope, element, attrs){
        element.on('click', function(e){
          var confirmCallback = $parse(attrs.adConfirmClick),
            confirmButtonClassesFn = $parse(attrs.adConfirmButtonClasses);
          scope.$apply(function(){
            var message = attrs.adConfirmMessage,
              confirmButtonText = attrs.adConfirmButtonText;
              AdConfirmModal({
                message: message,
                confirmButtonText: confirmButtonText,
                confirmButtonClasses: confirmButtonClassesFnInScope,
                confirmCallback: confirmCallbackInScope,
              });

              function confirmCallbackInScope(){
                return confirmCallback(scope);
              }

              function confirmButtonClassesFnInScope(){
                return confirmButtonClassesFn(scope);
              }
          });
        });
      }
    };
  });