(module => {
    'use strict';
    MonitoringCtrl.$inject = [
        "$scope",
        "FRONTEND_VUE_URL",
        "$sce",
        "AdRequest"
    ]
    module.controller('MonitoringCtrl', MonitoringCtrl);

    function MonitoringCtrl(
        $scope, FRONTEND_VUE_URL, $sce, AdRequest: AdRequestService
    ) {
        $scope.analyticTab.selected = 'monitoring';
        const vm = this;

        $scope.iFrameUrl = function () {
            const token = AdRequest.getToken();
            const url = `${FRONTEND_VUE_URL}/frames/monitoring?authToken=${token}`
            return $sce.trustAsResourceUrl(url);
        }

    }
})(angular.module('postCenterWebClientApp'));