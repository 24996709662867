Vue.component('ChannelNotificationMessage', {
  props: {
    text: {type: String, required: true}
  },
  template: '<p v-html="text"></p>'
});

Vue.component('ChannelNotificationIcon', {
  template: '<span class="fa"></span>'
});
