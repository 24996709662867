'use strict';

angular.module('postCenterWebClientApp')
  .factory('AdConfirmModal', function(AdModal){
    return function(opts){
      var messageFn = wrapInFunction(opts.message),
        confirmButtonTextFn = wrapInFunction(opts.confirmButtonText),
        confirmButtonClassesFn = wrapInFunction(opts.confirmButtonClasses),
        confirmCallback = opts.confirmCallback;

      AdModal.build({
        template: 'blocks/global/views/ad_confirm_modal.html',
        size: 'sm',
        resolve: {
          message: messageFn,
          confirmButtonText: confirmButtonTextFn,
          confirmButtonClasses: confirmButtonClassesFn,
        },
        controller: 'adConfirmModalCtrl'
      }, confirmCallback);

      function wrapInFunction(value){
        if(!angular.isFunction(value)){
          return function(){
            return value;
          };
        } else {
          return value;
        }
      }
    }
  });
