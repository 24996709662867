(module => {
  'use strict';
  UserData.$inject = ['$q', '$rootScope', 'AdRequest', 'UserService'];
  function UserData($q, $rootScope, AdRequest, UserService) {
    return {
      autocompleteCache: {},
      fetchUserStatus() {
        // this is for polling only
        return UserService.fetchUserStatus();
      },
      filterUsers(users, query) {
        if (query[0] === '@') {
          query = query.substring(1);
        }
        const result = _.chain(users)
          .filter(user => {
            return user.text.toLowerCase().indexOf(query.toLowerCase()) > -1;
          })
          .map(user => {
            return user;
          })
          .value();
        return result;
      },
      loadAutocomplete(query) {
        const deferred = $q.defer();

        const cachedValue = this.autocompleteCache[query];
        if (cachedValue !== undefined && cachedValue.length < 1) {
          const users = this.autocompleteCache[query];
          deferred.resolve(this.filterUsers(users, query));
        } else {
          AdRequest.post('/api/v1/authors_search/', {
            expression: query
          }).then(
            users => {
              this.autocompleteCache[query] = users;
              deferred.resolve(this.filterUsers(users, query));
            },
            error => {
              deferred.reject(error);
            }
          );
        }
        return deferred.promise;
      }
    };
  }
  module.factory('UserData', UserData);
})(angular.module('postCenterWebClientApp'));
