interface Column {
  addressed?: boolean
  answered?: boolean
  asc_items: boolean
  assigned?
  assignment?
  audio?: boolean
  author?
  autorefresh: boolean
  case_id?
  cases?: Case[]
  client_id: number
  column_tutorial?
  column_type: 'case' | 'message'
  commented?
  content_count: number
  created_from_date?
  created_to_date?
  departments?
  from_date?
  has_custom_data?
  important?
  in_sla?
  is_closed?
  is_mention?
  keyword?
  kind?
  messages?
  name: string
  order: number
  pagination_field: 'created' | 'updated_time' | 'sla_expiration_time'
  published?
  publisher?
  resolved?
  resource_id: string
  social_accounts?
  social_networks: SocialNetwork[]
  to_date?
  toast?
  updated_from_date?
  updated_to_date?
  workflow_status?: WorkflowStatus
}

class CaseGroup {
  /**
   * My mission is to centralize the Case objects that are present in visible
   * columns to allow lookup.
   */

  private cases = {
    byId: new Map<string, Case>(),
    byIdentifier: new Map<number, Case>()
  }
  private columnCases: Map<string, Set<string>>

  constructor() {
    this.columnCases = new Map()
  }

  public addColumn(column: Column, renderedCasesIds: string[]): void {
    let columnCases = new Set<string>(renderedCasesIds)
    this.columnCases.set(column.resource_id, columnCases)
    column.cases.forEach((caseObj: Case) => {
      this.cases.byId.set(caseObj.resource_id, caseObj)
      this.cases.byIdentifier.set(caseObj.identifier, caseObj)
      columnCases.add(caseObj.resource_id)
    })
    this.removeOldCases()
  }

  public get(caseId: number | string): Case | null {
    if (typeof caseId == 'string') {
      let caseObj = this.cases.byId.get(caseId)
      if (caseObj) {
        return caseObj
      } else {
        try {
          return this.cases.byIdentifier.get(parseInt(caseId))
        }
        catch {
          return null
        }
      }
    } else {
      return this.cases.byIdentifier.get(caseId)
    }
  }

  private removeOldCases(): Case[] {
    /**
     * Returns removed cases
     */
    let toRemove = []
    this.cases.byId.forEach((caseObj, caseId) => {
      let inColumn = false
      this.columnCases.forEach((cases) => {
        if (cases.has(caseId)) {
          inColumn = true
        }
      })
      if (!inColumn) {
        toRemove.push(caseObj)
      }
    })
    toRemove.forEach(caseObj => {
      this.cases.byId.delete(caseObj.resource_id)
      this.cases.byIdentifier.delete(caseObj.identifier)
    })
    return toRemove
  }

}

class ColumnsService {
  private AdRequest: AdRequestService
  private AdNotification
  private AdStore: AdStore
  private cases: CaseGroup
  private columnsModel: AdModelFetcher<Column[]>

  public dividedView: rxjs.BehaviorSubject<boolean>

  constructor(
    AdRequest: AdRequestService,
    AdNotification,
    AdStore: AdStore
  ) {
    this.AdRequest = AdRequest
    this.AdNotification = AdNotification
    this.AdStore = AdStore
    this.cases = new CaseGroup()
    this.columnsModel = new AdModelFetcher<Column[]>(
      () => this.AdRequest.get<Column[]>('/api/v1/columns/')
    )
    this.dividedView = new rxjs.BehaviorSubject(false)
    this.AdStore.get('ticketView').then(
      (viewMode: 'divided' | 'expanded') => {
        this.dividedView.next(viewMode != 'expanded')
      }
    )
  }

  public setDividedView() {
    this.AdStore.set('ticketView', 'divided')
    this.dividedView.next(true)
  }

  public setExpandedView() {
    this.AdStore.set('ticketView', 'expanded')
    this.dividedView.next(false)
  }

  findCase(caseId: string | number): Promise<Case | null> {
    return this.columnsModel.get().then(
      () => this.cases.get(caseId)
    )
  }

  findColumnElement(column: Column): Element {
    return document.querySelector(`[data-column_id="${column.resource_id}"]`)
  }

  findCaseElementsInColumn(column: Column): NodeListOf<Element> | null {
    let columnElem = this.findColumnElement(column)
    if (columnElem) {
      return columnElem.querySelectorAll('.case')
    }
    return null
  }

  caseIdsRenderedInColumn(column: Column): string[] {
    let currentCases: string[] = []
    let caseElements = this.findCaseElementsInColumn(column)
    if (caseElements) {
      caseElements.forEach(caseElem =>
        currentCases.push(caseElem.attributes['data-case_id'].value)
      )
    }
    return currentCases
  }

  public findCaseElement(caseObj: Case, column?: Column): Element | null {
    let root: ParentNode = document
    if (column) {
      root = this.findColumnElement(column)
    }
    let el = root.querySelector(`[data-case_identifier="${caseObj.identifier}"]`)
    if (el == null) {
      el = root.querySelector(`[data-case_id="${caseObj.resource_id}"]`)
    }
    return el
  }

  getEntities(query): Promise<Column> {
    return this.AdRequest.get<Column>(`/api/v1/column/${query}`).then(column => {
      if (column.cases?.length) {
        let currentCases = this.caseIdsRenderedInColumn(column)
        this.cases.addColumn(column, currentCases)
      }
      return column
    })
  }

  getTickets(columnID, pivotDate, asc, timeline, fix = false): Promise<Column> {
    const params = new URLSearchParams()
    pivotDate && params.set(timeline, pivotDate)
    asc && params.set('asc', asc)
    fix && params.set('fixCounter', true)
    return this.getEntities(`${columnID}/?${params.toString()}`)
  }

  getFirstColumn(): Promise<Column | null> {
    return this.columnsModel.get().then(columns => {
      if (columns) {
        columns.sort((a, b) => a.order - b.order)
        return columns[0]
      } else {
        return null
      }
    })
  }

  getColumns(): Promise<Column[]> {
    return this.columnsModel.get()
  }

  refreshColumns(): Promise<Column[]> {
    return this.columnsModel.refresh()
  }

  getColumn(columnID): Promise<Column> {
    return this.getEntities(columnID)
  }
  
  async getLegacyTickets(columnID, tickets) {
    try {
      const cases = await this.AdRequest.post(
        `/api/v1/column/${columnID}/cases_status/`,
        {
          cases_ids: tickets
        }
      );
      const removeTickets = Object.keys(cases).filter(key => {
        if (!cases[key]) {
          return key;
        }
      });
      return removeTickets;
    } catch (error) {
      return [];
    }
  }
  postColumn(data) {
    if (data['column_type'] === undefined) {
      data['column_type'] = 'message';
    }
    return this.AdRequest.post('/api/v1/columns/', data);
  }
  deleteColumn(columnID) {
    return this.AdRequest.delete(`/api/v1/column/${columnID}/`);
  }
  exportColumn(data) {
    return this.AdRequest.post('/api/v1/columns/export/', data);
  }
  updateColumn(columnID, data, type = '') {
    const options = ['name', 'audio', 'toast', 'autorefresh', 'asc_items'];
    const path = options.includes(type)
      ? `/api/v1/column/${type}/${columnID}/`
      : `/api/v1/column/${columnID}/`;
    return this.AdRequest.put(path, data);
  }
  updateColumns(columnID, data, type = '') {
    return this.AdRequest.put(`/api/v1/columns/${type}/${columnID}/`, data);
  }
  createRecommendedColumn(data) {
    return this.AdRequest.post('/api/v1/columns/base/', data);
  }
  async asyncPostColumn(columnFilters = {}) {
    const action = 'post_column';

    try {
      const response = await this.postColumn(columnFilters);
      this.AdNotification.success(201, action);
      return response;
    } catch (error) {
      this.AdNotification.error(error.status, action);
      throw new Error(error);
    }
  }
  getPendingTicketsColumn() {
    return {
      resolved: null,
      content_count: 1000,
      column_type: 'case',
      resource_id: '5e4aa8035b7d3514a489e38c',
      assignment: null,
      published: null,
      publisher: null,
      pagination_field: 'updated_time',
      is_mention: null,
      assigned: null,
      addressed: null,
      important: null,
      keyword: null,
      to_date: null,
      client_id: 7,
      in_sla: null,
      updated_from_date: null,
      cases: [],
      is_closed: null,
      autorefresh: false,
      toast: false,
      has_custom_data: null,
      kind: null,
      asc_items: false,
      column_tutorial: null,
      name: 'Tickets en espera',
      social_networks: [],
      author: null,
      workflow_status: {
        category: 0,
        kind: 0,
        label: 'Abierto',
        resource_id: '5af1aecf3ccf790b4e9b42ce'
      },
      social_accounts: [],
      messages: [],
      updated_to_date: null,
      order: 0,
      answered: false,
      from_date: null,
      case_id: null,
      created_from_date: null,
      created_to_date: null,
      audio: false,
      commented: null
    };
  }
}
angular
  .module('postCenterWebClientApp')
  .service('ColumnsService', ColumnsService);
