const chartMixin = {
  props: {
    height: Number,
    resize: Boolean,
    chartData: {
      type: Object
    }
  },
  computed: {
    resized() {
      return {
        visible: false,
        width:
          this.width < 0 ? `${this.width}px` : 'calc(100% - 15px) !important',
        height: `${this.height}px`
      };
    }
  },
  mounted() {
    this.width = this.$el?.parentElement?.clientWidth;
    this.render();
  },
  watch: {
    chartData() {
      this.redraw();
    },
    resize() {
      this.width = this.$el?.parentElement?.clientWidth;
      this.chart.reflow();
    }
  },
  template: `
    <div class="adereso-chart" :style="resized"></div>
  `
};
