'use strict';

var app = angular.module('postCenterWebClientApp');
app.service('SlaHandlerService', function($rootScope, $filter, SocialAccountService: SocialAccountService) {
  var service = this;
  service.slaForAccounts = {};
  service.slaIsReady = false;

  setSLAForAccounts();

  function setSLAForAccounts() {
    SocialAccountService.getSocialAccounts().then(accounts => {
      accounts.forEach(account => {
        let unit = 'MINUTES';
        let value = 20;
        if (account.config) {
          unit = account.config?.answer_sla_unit;
          value = account.config?.answer_sla_value;
        } else {
          console.error(`Account ${account.username}:${account.uid} has no config.`);
        }
        if (unit !== undefined && value !== undefined) {
          service.slaForAccounts[account.sn + '_' + account.uid] = {
            unit: unit,
            value: value,
            human: getDuration(value, unit)
          };
        }        
      });
      service.slaIsReady = true;
    })
  }

  function getAccountSLAConfig(account) {
    let key = account.sn + '_' + account.uid
    return service.slaForAccounts[key];
  }

  function getDuration(value, unit) {
    if (value === 1) {
      var singeUnit = unit.substring(0, unit.length - 1);
      return value + ' ' + $filter('translate')(singeUnit).toLowerCase();
    } else {
      return value + ' ' + $filter('translate')(unit).toLowerCase();
    }
  }

  function returnSLAInMinutes(unit, time) {
    if (unit === 'MINUTES') {
      return time;
    } else if (unit === 'HOURS') {
      return time * 60;
    } else if (unit === 'SECONDS') {
      return time / 60;
    } else {
      return 9999999;
    }
  }

  function getLowestSlaInMinutes(establishmentUsers) {
    // if there are multiple accounts
    // involved, then return the lowest
    var minValue = undefined;
    var minUnit = undefined;
    var defaultSLA = {unit: 'MINUTES', value: 20, 'human': getDuration(20, 'MINUTES')};

    if (establishmentUsers === null) {
      establishmentUsers = [];
    }

    angular.forEach(establishmentUsers, function(user) {
      var config = getAccountSLAConfig(user);
      if (config !== undefined) {  
        var unit = config['unit'];
        var value = config['value'];
        var comparableTime = returnSLAInMinutes(unit, value);
        var comparableMinTime = returnSLAInMinutes(minUnit, minValue);

        if (minValue === undefined || comparableMinTime > comparableTime) {
          minValue = value;
          minUnit = unit;
        }
      }
    });

    if (minValue === undefined) {
      // If sla is undefined, it means the
      // account was removed, we use default
      return defaultSLA;
    } else {
      var sla = {unit: minUnit, value: minValue, human: getDuration(minValue, minUnit)};
      return sla;
    }
  }

  function hasAnswerTimeSLA(caseObj): boolean {
    return service.slaIsReady && (!caseObj.answered && !caseObj.is_closed);
  }

  function getLastMessageTime(caseObj): number {
    var lastMsgCreated = caseObj['last_message_time'] * 1000;
    var lastUpdate = caseObj['updated_time'] * 1000;
    if (!lastMsgCreated) {
      // This is to fix older tickets with no last_message_time
      // that defaults to now.
      lastMsgCreated = lastUpdate;
    }
    return lastMsgCreated;
  }

  function getWaitingTime(caseObj): string {
    let lastMessageTime = getLastMessageTime(caseObj);
    return moment(lastMessageTime).fromNow();
  }

  function getSlaConfig(caseObj): Object {
    return getLowestSlaInMinutes(caseObj.establishment_users);
  }

  function isAnswerTimeSLAExpired(caseObj): boolean {
    var now = moment();
    let lastMessageTime = getLastMessageTime(caseObj);
    let slaConfig = getLowestSlaInMinutes(caseObj.establishment_users);
    var threshold = moment(lastMessageTime).add(slaConfig.value, slaConfig.unit);
    return threshold.isBefore(now);
  }

  this.hasAnswerTimeSLA = hasAnswerTimeSLA;
  this.isAnswerTimeSLAExpired = isAnswerTimeSLAExpired;
  this.getWaitingTime = getWaitingTime;
  this.getSlaConfig = getSlaConfig;
});
