'use strict';

var app = angular.module('postCenterWebClientApp');
app.directive('filterByCreatedSelector', function () {
  return {
    templateUrl: 'blocks/analytics/views/filter_by_created_selector.html',
    restrict: 'E',
    controller: 'filterByCreatedSelectorCtrl',
    scope: {}
  }
});

app.controller('filterByCreatedSelectorCtrl', filterByCreatedSelectorCtrl);

filterByCreatedSelectorCtrl.$inject = ['$scope', '$rootScope', 'localStorageService'];
function filterByCreatedSelectorCtrl($scope, $rootScope, localStorageService) {
  var storageFilterKey = 'analytics:filterByCreated';

  $scope.filterByCreated = localStorageService.get(storageFilterKey) || 'false';

  const filterByCreatedWatcherOff = $scope.$watch('filterByCreated', function(){
    var emitKey = 'analytics:dashboard:changeFilterByCreated';
    $rootScope.$emit(emitKey, $scope.filterByCreated);
    localStorageService.set(storageFilterKey, $scope.filterByCreated);
  });

  $scope.$on('$destroy', () => {
    filterByCreatedWatcherOff();
  });
};
