app.filter('capitalize', function() {
    return function(input, scope) {
      try{
        if (input !== null) {
          input = input.toLowerCase();
        }
        return input.substring(0, 1).toUpperCase() + input.substring(1);
      }
      catch {
        return input;
      }
    };
});