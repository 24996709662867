'use strict';

class AccountStatusHandlerService {
  AdAvailableSn
  $filter
  SocialAccountService
  
  constructor(AdAvailableSn, $filter, SocialAccountService: SocialAccountService) {
    this.AdAvailableSn = AdAvailableSn
    this.$filter = $filter
    this.SocialAccountService = SocialAccountService

    SocialAccountService.accountsObservable.subscribe(accounts => {
      accounts.forEach(account => {
        this.set(account)
      })
    })
  }

  set(account) {
    const sn = account.sn;
    const hasStatuses =
      (account.statuses && account.statuses !== null) || account.status;

    account.canReconnect = false;

    if (hasStatuses) {
      switch (sn) {
        case 'twitter':
          this._setTwitterStatus(account);
          break;
        case 'facebook':
          this._setFacebookStatus(account);
          break;
        case 'whatsapp':
          this._setWhatsappStatus(account);
          break;
        case 'email':
          this._setEmailStatus(account);
          break;
        case 'instagram':
          this._setInstagramStatus(account);
          break;
        default:
          this._setHumanStatus(account);
          break;
      }
    }
  }

  _setInstagramStatus(account) {
    const statuses = account.statuses || {};
    account.isHealthy = account.status >= 11111 && statuses.valid_token !== false;

    if(!account.isHealthy){
      account.humanFixMessage = this.$filter('translate')(
        'SOCIALACCOUNTS_CONNECTED_INSTAGRAM_ERROR_MESSAGE'
      );
      return;
    }

    this._setHumanStatus(account);
  }

  _setHumanStatus(account) {
    account.isHealthy = account.status >= 11111;

    if (account.isHealthy) {
      account.humanStatusStr = this.$filter('translate')(
        'SOCIALACCOUNTS_FULLY_CONNECTED'
      );
    } else {
      account.warningStr = this.$filter('translate')(
        'SOCIALACCOUNTS_WARNING_MESSAGE',
        {
          account: account.username,
          channel: this.AdAvailableSn.getTitle(account.sn)
        }
      );
      account.humanStatusStr = this.$filter('translate')(
        'SOCIALACCOUNTS_CONNECT_ERROR'
      );
    }
  }

  _setTwitterStatus(account) {
    const statuses = account.statuses || {};

    account.isHealthy =
      statuses.valid_token !== false && statuses.token_expired !== true;

    if (account.isHealthy) {
      account.humanStatusStr = this.$filter('translate')(
        this.$filter('translate')('SOCIALACCOUNTS_FULLY_CONNECTED')
      );
    } else if (account.type === 'search') {
      account.humanStatusStr = this.$filter('translate')(
        'SOCIALACCOUNTS_TWITTER_SEARCH_TOKEN_EXPIRED'
      );
      account.humanFixMessage = this.$filter('translate')(
        'SOCIALACCOUNTS_TWITTER_TOKEN_EXPIRED_FIX'
      );
    } else {
      account.humanStatusStr = this.$filter('translate')(
        'SOCIALACCOUNTS_TWITTER_TOKEN_EXPIRED'
      );
      account.humanFixMessage = this.$filter('translate')(
        'SOCIALACCOUNTS_TWITTER_TOKEN_EXPIRED_FIX'
      );
      account.warningStr = this.$filter('translate')(
        'SOCIALACCOUNTS_WARNING_MESSAGE',
        {
          account: account.username,
          channel: this.AdAvailableSn.getTitle(account.sn)
        }
      );
    }
  }

  _setWhatsappStatus(account) {
    let statuses = account.statuses || {};

    account.maintenance = !!statuses.maintenance;
    account.isHealthy = !statuses.maintenance && statuses.is_healthy;
    account.canReconnect = !statuses.maintenance && !statuses.is_healthy;

    if (account.isHealthy) {
      account.humanStatusStr = this.$filter('translate')(
        'SOCIALACCOUNTS_FULLY_CONNECTED'
      );
    } else {
      account.warningStr = this.$filter('translate')(
        'SOCIALACCOUNTS_WARNING_MESSAGE',
        {
          account: account.username,
          channel: this.AdAvailableSn.getTitle(account.sn)
        }
      );

      if (account.maintenance) {
        account.humanStatusStr = this.$filter('translate')(
          'SOCIALACCOUNTS_FIXING_PROBLEMS'
        );

        account.humanFixMessage = this.$filter('translate')(
          'SOCIALACCOUNTS_FIXING_PROBLEMS_MESSAGE'
        );
      } else {
        // At this point, canReconnect is true.

        account.humanStatusStr = this.$filter('translate')(
          'SOCIALACCOUNTS_WHATSAPP_CONNECT_FAILED'
        );
        account.humanFixMessage = this.$filter('translate')(
          'SOCIALACCOUNTS_WHATSAPP_CONNECT_FAILED_FIX'
        );
      }
    }
  }

  _setFacebookStatus(account) {
    const statuses = account.statuses || {};

    account.isHealthy =
      statuses.valid_token !== false &&
      statuses.no_admin_privileges !== true &&
      statuses.reached_request_limit !== true;

    if (account.isHealthy) {
      account.humanStatusStr = this.$filter('translate')(
        'SOCIALACCOUNTS_FULLY_CONNECTED'
      );
    } else {
      account.warningStr = this.$filter('translate')(
        'SOCIALACCOUNTS_WARNING_MESSAGE',
        {
          account: account.username,
          channel: this.AdAvailableSn.getTitle(account.sn)
        }
      );
    }
    if (statuses.valid_token === false) {
      account.canReconnect = true;
      account.humanStatusStr = this.$filter('translate')(
        'SOCIALACCOUNTS_FACEBOOK_CREDENTIALS_EXPIRED'
      );
      account.humanFixMessage = this.$filter('translate')(
        'SOCIALACCOUNTS_CONNECTED_FACEBOOK_ERROR_MESSAGE'
      );
    } else if (statuses.no_admin_privileges === true) {
      account.humanStatusStr = this.$filter('translate')(
        'SOCIALACCOUNTS_CONNECTED_NOT_FACEBOOK_ADMIN'
      );
      account.humanFixMessage = this.$filter('translate')(
        'SOCIALACCOUNTS_CONNECTED_FACEBOOK_ERROR_MESSAGE'
      );
    } else if (statuses.reached_request_limit === true) {
      account.humanStatusStr = this.$filter('translate')(
        'SOCIALACCOUNTS_REACHED_REQUEST_LIMIT'
      );
      account.humanFixMessage = this.$filter('translate')(
        'SOCIALACCOUNTS_REACHED_REQUEST_LIMIT_MESSAGE'
      );
    }
  }

  _setEmailStatus(account) {
    const statuses = account.statuses || {};
    account.isHealthy =
      (account.type === 'email' && statuses.forwarding_validated) ||
      (account.type === 'imap' &&
        !statuses.failed_imap_connection &&
        !statuses.bad_imap_credentials);

    if (!account.isHealthy) {
      account.warningStr = this.$filter('translate')(
        'SOCIALACCOUNTS_WARNING_MESSAGE',
        {
          account: account.username,
          channel: this.AdAvailableSn.getTitle(account.sn)
        }
      );
    }
    if (account.isHealthy) {
      account.humanStatusStr = this.$filter('translate')(
        'SOCIALACCOUNTS_FULLY_CONNECTED'
      );
    } else if (account.type === 'email' && !statuses.forwarding_validated) {
      account.humanStatusStr = this.$filter('translate')(
        'SOCIALACCOUNTS_EMAIL_NOT_VALIDATED'
      );
      account.humanFixMessage = this.$filter('translate')(
        'SOCIALACCOUNTS_EMAIL_NOT_VALIDATED_FIX',
        {
          email: account.uid,
          postcenterEmail: account.config.forward_email
        }
      );
    } else if (account.type === 'imap' && statuses.failed_imap_connection) {
      account.humanStatusStr = this.$filter('translate')(
        'SOCIALACCOUNTS_EMAIL_FAILED_IMAP_CONNECTION'
      );
      account.humanFixMessage = this.$filter('translate')(
        'SOCIALACCOUNTS_EMAIL_FAILED_IMAP_CONNECTION_FIX'
      );
    } else if (account.type === 'imap' && statuses.bad_imap_credentials) {
      account.humanStatusStr = this.$filter('translate')(
        'SOCIALACCOUNTS_EMAIL_BAD_IMAP_CREDENTIALS'
      );
      account.humanFixMessage = this.$filter('translate')(
        'SOCIALACCOUNTS_EMAIL_BAD_IMAP_CREDENTIALS_FIX'
      );
    } else {
      account.humanStatusStr = this.$filter('translate')(
        'SOCIALACCOUNTS_FULLY_CONNECTED'
      );
    }
  }
}


angular
  .module('postCenterWebClientApp')
  .service('adAccountStatus', AccountStatusHandlerService)
