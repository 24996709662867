const DateTimePopoverMixin = {
  props: {
    updateFiltersHandler: Function,
    from: String,
    to: String
  },
  data() {
    return {
      customHour: false,
      errorMessage: '',
      custom: {
        hour: {
          from: '00:00',
          to: '23:59'
        },
        date: {}
      },
      masks: {
        input: 'D/MMM/YYYY',
        title: 'MMMM',
        weekdays: 'WWW'
      }
    };
  },
  computed: {
    getMinDate() {
      return !this.custom.date.to ? new Date() : this.custom.date.to;
    }
  },
  methods: {
    getAttrs(input) {
      return {
        placeholder: this.$t(input),
        readonly: true
      };
    },
    updateFilters() {
      const {
        hour: {from: hourFrom, to: hourTo},
        date: {from: rangeStart = '', to: rangeEnd = ''}
      } = this.custom;
      if (rangeStart && rangeEnd) {
        const formatRangeFrom = this.getDateFormat({
          date: rangeStart,
          time: hourFrom
        });
        const formatRangeTo = this.getDateFormat({
          date: rangeEnd,
          time: hourTo
        });
        const converToLocalTs = date => moment(date).format('X');

        this.updateFiltersHandler({
          from: converToLocalTs(formatRangeFrom),
          to: converToLocalTs(formatRangeTo)
        });
        this.errorMessage = '';
      } else {
        this.errorMessage = this.$t('noDateFound');
      }
    },
    getDateFormat({date, time}) {
      const [hours, minutes] = time.split(':');
      const day = date.getDate();
      const month = date.getMonth();
      const year = date.getFullYear();
      return new Date(year, month, day, Number(hours), Number(minutes));
    }
  },
  i18n: {
    messages: {
      es: {
        title: 'Rango personzalido',
        from: 'Desde',
        to: 'Hasta',
        nonDateFound: 'Debe seleccionar un periodo para visualizar',
        accept: 'Aceptar',
        chooseTime: 'Si deseas, elige un periodo de hora',
        choosePeriod: 'Elige el periodo de fecha que deseas visualizar'
      },
      pt: {
        title: 'Rango personzalido',
        from: 'Desde',
        to: 'Hasta',
        nonDateFound: 'Debe seleccionar un periodo para visualizar',
        accept: 'Concordar',
        chooseTime: 'Si deseas, elige un periodo de hora',
        choosePeriod: 'Elige el periodo de fecha que deseas visualizar'
      },
      en: {
        title: 'Rango personzalido',
        from: 'Desde',
        to: 'Hasta',
        nonDateFound: 'Debe seleccionar un periodo para visualizar',
        accept: 'Accept',
        chooseTime: 'Si deseas, elige un periodo de hora',
        choosePeriod: 'Elige el periodo de fecha que deseas visualizar'
      }
    }
  },
  template: `<div class="custom-filters-date">
  <h6><strong>{{ $t('title') }}</strong></h6>
  <div class="custom-dates">
    <p>{{ $t('choosePeriod') }}</p>
    <div class="d-flex grid-flex">
      <v-date-picker
        class="flex-col-2 mr-2"
        mode="single"
        :locale="$i18n.locale"
        :masks="masks"
        :min-date="new Date(2020, 7, 1)"
        :max-date="getMinDate"
        :popover="{ placement: 'bottom', visibility: 'click' }"
        v-model="custom.date.from"
        color="teal">
        <template v-slot="{ inputValue, inputEvents }">
        <input
          class="w-90 picker-input picker-input--active bold"
          :value="inputValue"
          v-on="inputEvents"
          :placeholder="$t('from')"
          readonly
        />
      </template>
      </v-date-picker>

      <v-date-picker
        class="flex-col-2"
        :locale="$i18n.locale"
        :masks="masks"
        :min-date="custom.date.from"
        :max-date="new Date()"
        :popover="{ placement: 'bottom', visibility: 'click' }"
        v-model="custom.date.to"
        color="teal">
        <template v-slot="{ inputValue, inputEvents }">
        <input
          class="w-90 picker-input picker-input--active bold"
          :value="inputValue"
          v-on="inputEvents"
          :placeholder="$t('to')"
          readonly
        />
      </template>
      </v-date-picker>
    </div>
  </div>
  <div class="custom-hours my-2">

    <Checkbox
      v-model="customHour"
      :label="$t('chooseTime')"
    />
    <div class="grid-flex d-flex space-between mt-2 ml-5"
      :class="{disabled: !customHour}">
      <div class="flex-col-2">Desde:
        <input type="time"
          :disabled="!customHour"
          v-model="custom.hour.from" required class="ad-input-time" /></div>
      <div class="flex-col-2">{{ $t('to') }}:
        <input type="time"
          :disabled="!customHour"
          v-model="custom.hour.to" class="ad-input-time"
        />
      </div>
    </div>
  </div>
  <button
    class="btn btn-primary"
    @click="updateFilters()"
  >
    {{ $t('accept') }}
  </button>
  <div class="text-center mt-2" v-if="errorMessage">
    <span>{{ errorMessage }}</span>
  </div>

</div>`
};
