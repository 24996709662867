class EpaService {
  constructor(AdRequest) {
    this.AdRequest = AdRequest;
    this.analyticsPath = '/api/v1/botcenter/survey_analytics/';
    this.configPath = '/api/v1/botcenter/survey_bot_config/';
  }
  getSurveys() {
    return this.AdRequest.get(this.configPath);
  }
  saveSurvey(payload) {
    return this.AdRequest.post(this.configPath, payload);
  }
  updateSurvey(payload) {
    const {id} = payload;
    return this.AdRequest.put(`${this.configPath}${id}`, payload);
  }
  toggleSurvey(payload) {
    const {id, is_active} = payload;
    const params = {
      activate_survey_bot: is_active
    };
    return this.AdRequest.post(`${this.configPath}${id}/`, params);
  }
  removeSurvey(id) {
    return this.AdRequest.delete(`${this.configPath}${id}/`);
  }
  async getAnalyticsSurveys(filters) {
    try {
      const data = await this.AdRequest.post(`${this.analyticsPath}`, filters);
      return await data;
    } catch (error) {
      throw new Error(error);
    }
  }
  exportSurveyCloud({client_id, filters, token, env = 'dev'}) {
    const url = `https://us-central1-survey-bot-epa.cloudfunctions.net/get-survey-csv-table-${
      env
    }-export-csv`;
    return fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        client_id,
        ...filters
      }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: token
      }
    });
  }
}
angular.module('postCenterWebClientApp').service('EpaService', EpaService);
