// adereso-virtual-scroll - v0.1

(function() {
'use strict';
angular.module('adereso.virtualScroll', []);
}());

// adereso-virtual-repeat directive
// ===========================

(function() {
  'use strict';

  var mod = angular.module('adereso.virtualScroll');
  var DONT_WORK_AS_VIEWPORTS = ['TABLE', 'TBODY', 'THEAD', 'TR', 'TFOOT'];
  var DONT_WORK_AS_CONTENT = ['TABLE', 'TBODY', 'THEAD', 'TR', 'TFOOT'];
  var DONT_SET_DISPLAY_BLOCK = ['TABLE', 'TBODY', 'THEAD', 'TR', 'TFOOT'];

  mod.directive('aderesoVirtualRepeat', ['$rootElement', '$timeout', function($rootElement, $timeout) {

    return {
      require: '?ngModel',
      transclude: 'element',
      priority: 1000,
      terminal: true,
      compile: aderesoVirtualRepeatCompile
    };

    function parseRepeatExpression(expression) {
      var match = expression.match(/^\s*([\$\w]+)\s+in\s+([\S]*)(\s+minRender:\d+)?(\s+maxRender:\d+)?(\s+atEnd:\w+\(\))?(\s+lockAtEnd:\w+)?(\s+reset:\w+)?\s*$/);
      if (! match) {
        var msg = 'Expected aderesoVirtualRepeat in form of "_i';
        msg = msg + 'tem_ in _collection_ [cacheHeight:{_number_}]" but got "';
        throw new Error(msg + expression + '".');
      }
      var ret = {
        value: match[1],
        collection: match[2]
      };

      addExtraExpresion(match[3], ret);
      addExtraExpresion(match[4], ret);
      addExtraExpresion(match[5], ret);
      addExtraExpresion(match[6], ret);
      addExtraExpresion(match[7], ret);

      if (ret.minRender === undefined) {
        ret.minRender = 500;
      } else {
        ret.minRender = parseInt(ret.minRender, 10);
      }
      if (ret.maxRender === undefined) {
        ret.maxRender = 1500;
      } else {
        ret.maxRender = parseInt(ret.maxRender, 10);
      }

      ret.middleRender = (ret.minRender + ret.maxRender) / 2;
      return ret;

    }

    function addExtraExpresion(match, ret) {
      if (match !== undefined) {
        var extra = match.split(':');
        if (extra[1] !== undefined) {
          ret[extra[0].trim()] = extra[1];
        } else {
          ret[extra[0].trim()] = true;
        }
      }
    }

    // Utility to filter out elements by tag name
    function isTagNameInList(element, list) {
      var t;
      var tag = element.tagName.toUpperCase();
      for( t = 0; t < list.length; t++ ) {
        if (list[t] === tag ) {
          return true;
        }
      }
      return false;
    }

    // Utility to find the viewport/content elements given the start element:
    function findViewportAndContent(startElement) {
      /*jshint eqeqeq:false, curly:false */
      var root = $rootElement[0];
      var e;
      var n;
      // Somewhere between the grandparent and the root node
      for( e = startElement.parent().parent()[0]; e !== root; e = e.parentNode ) {
        // is an element
        if (e.nodeType != 1 ) {break;}
        // that isn't in the blacklist (tables etc.),
        if (isTagNameInList(e, DONT_WORK_AS_VIEWPORTS) ) {continue;}
        // has a single child element (the content),
        if (e.childElementCount != 1 ) {continue;}
        // which is not in the blacklist
        if (isTagNameInList(e.firstElementChild, DONT_WORK_AS_CONTENT) ) continue;
        // and no text.
        for( n = e.firstChild; n; n = n.nextSibling ) {
          if (n.nodeType == 3 && /\S/g.test(n.textContent) ) {
            break;
          }
        }
        if (n === null ) {
          // That element should work as a viewport.
          return {
            viewport: angular.element(e),
            content: angular.element(e.firstElementChild)
          };
        }
      }
      throw new Error('No suitable viewport element');
    }

    // Apply explicit height and overflow styles to the viewport element.
    //
    // If the viewport has a max-height (inherited or otherwise), set max-height.
    // Otherwise, set height from the current computed value or use
    // window.innerHeight as a fallback
    //
    function setViewportCss(viewport) {

      var viewportCss = {};
      var style = window.getComputedStyle ? window.getComputedStyle(viewport[0]) : viewport[0].currentStyle;
      var maxHeight = style && style.getPropertyValue('max-height');
      var height = style && style.getPropertyValue('height');

      if (maxHeight && maxHeight !== '0px' ) {
        viewportCss.maxHeight = maxHeight;
      }else if (height && height !== '0px' ) {
        viewportCss.height = height;
      } else {
        viewportCss.height = window.innerHeight;
      }
      viewport.css(viewportCss);
    }

    // Apply explicit styles to the content element to prevent pesky padding
    // or borders messing with our calculations:
    function setContentCss(content) {
      var contentCss = {
        margin: 0,
        padding: 0,
        border: 0,
        'box-sizing': 'border-box'
      };
      content.css(contentCss);
    }

    function aderesoVirtualRepeatCompile(element, attr, linker) {

      var params = parseRepeatExpression(attr.aderesoVirtualRepeat);
      return {
        'post': aderesoVirtualRepeatPostLink
      };

      function aderesoVirtualRepeatPostLink(scope, iterStartElement, attrs) {

        var rendered = [];
        var paddingTop = 0;
        var oldScrollTop = 0;
        var oldForward = true;
        var dom = findViewportAndContent(iterStartElement);
        var myLockAtEnd = false;
        var lastBlockMap = {};
        var nextBlockMap = {};
        var thisScope = scope;

        var didScroll = false;
        var scrollEvent = undefined;

        var state = 'ngModel' in attrs ? scope.$eval(attrs.ngModel) : {};
        state.head = 0;
        state.tail = 0;
        state.bottom = 0;
        state.top = 0;

        setContentCss(dom.content);
        setViewportCss(dom.viewport);
        dom.viewport.bind('scroll', onScrollFn);

        let resetWatcherOff = () => {};

        const virtualRepeatWatcherOff = scope.$watch(aderesoVirtualRepeatWatchExpression, aderesoVirtualRepeatListener, true);
        if (params.reset) {
          resetWatcherOff = scope.$watch(params.reset, aderesoVirtualRepeatResetListener, true);
        }

        /*
          here we intentionally use native setInterval instead of $interval
          in order to prevent digest to occur everytime interval is called
        */
        var scrollTrigger = setInterval(function() {
          if (didScroll) {
            scope.$apply(function() {
              var evt = scrollEvent
              didScroll = false;

              if (!angular.isDefined(scrollEvent)) {
                return;
              }

              state.top = evt.target.scrollTop - paddingTop;
              state.bottom = evt.target.scrollHeight - (evt.target.scrollTop + dom.viewport[0].clientHeight);
              state.forward = oldScrollTop < evt.target.scrollTop;

              oldForward = state.forward;

              checkTop();
              checkBottom();

              oldScrollTop = evt.target.scrollTop;
            })

          }
        }, 500);

        scope.$on('$destroy', function() {
          clearInterval(scrollTrigger);
          virtualRepeatWatcherOff();
          resetWatcherOff();
        })

        return;

        // Apply explicit styles to the item element
        function setElementCss (element) {
          var elementCss = {};
          if (!isTagNameInList(element[0], DONT_SET_DISPLAY_BLOCK) ) {
            elementCss.display = 'block';
          }
          element.css(elementCss);
        }

        function setElementCssNone (element) {
          var elementCss = {};
          if (!isTagNameInList(element[0], DONT_SET_DISPLAY_BLOCK) ) {
            elementCss.display = 'none';
          }
          element.css(elementCss);
        }

        function makeNewScope (collectionIndex, collectionExpression, containerScope) {

          var childScope = containerScope.$new();
          var collection = containerScope.$eval(collectionExpression);
          childScope[params.value] = collection[collectionIndex];

          childScope.$index = collectionIndex;
          childScope.$first = (collectionIndex === 0);
          childScope.$last = (collectionIndex === (collection.length - 1));
          childScope.$middle = !(childScope.$first || childScope.$last);

          const collectionWatcherOff = childScope.$watch(function(){
            collection = containerScope.$eval(collectionExpression);
            return collection[collectionIndex];
          }, function(value){
            childScope[params.value] = value;
          });

          const childScopeParamsWatcherOff = childScope.$watch(function(){
            return childScope[params.value];
          }, function(value){
            collection = containerScope.$eval(collectionExpression);
            collection[collectionIndex] = value;
          });

          childScope.$on('$destroy', () => {
            collectionWatcherOff();
            childScopeParamsWatcherOff();
          });

          return childScope;
        }

        function showElement(element) {
          element.css({display: 'block'});
        }

        function addElements (start, end, collectionExpression, containerScope, insPoint) {
          var frag = document.createDocumentFragment();
          var newElements = [], element, idx, childScope;
          for( idx = start; idx <= end; idx ++ ) {
            childScope = makeNewScope(idx, collectionExpression, containerScope);
            element = linker(childScope, angular.noop);
            setElementCss(element);
            newElements.push(element);
            frag.appendChild(element[0]);
          }
          insPoint.after(frag);
          return newElements;
        }

        function addElement (action, collectionExpression, containerScope) {

          var collectionIndex;
          if (action === 'push') {
            state.tail = state.tail + 1;
            collectionIndex = state.tail;
          } else if (action === 'unshift') {
            state.head = state.head - 1;
            collectionIndex = state.head;
          } else {
            throw new Error('Action not found: '+action);
          }

          var frag = document.createDocumentFragment();
          var element;
          var childScope;

          childScope = makeNewScope(collectionIndex, collectionExpression, containerScope);
          element = linker(childScope, angular.noop);
          setElementCssNone(element);
          frag.appendChild(element[0]);

          if (action === 'push') {
            var lastElement = rendered[rendered.length-1];
            rendered.push(element);
            lastElement.after(frag);
          } else if (action === 'unshift') {
            rendered.unshift(element);
            iterStartElement.after(frag);
          }

          return element;
        }

        function onScrollFn(evt) {
          didScroll = true;
          scrollEvent = evt;
        }

        function checkTop() {
          if (state.top > params.maxRender) {
            state.removeTop = true;
          } else {
            state.removeTop = false;
          }

          if (state.head > 0 && state.top < params.minRender) {
            state.addTop = true;
          } else {
            state.addTop = false;
          }
        }

        function checkBottom() {
          if (state.total > state.tail + 1 && state.bottom < params.minRender) {
            state.addBottom = true;
          } else {
            state.addBottom = false;
          }

          if (state.bottom > params.maxRender) {
            state.removeBottom = true;
          } else {
            state.removeBottom = false;
          }
        }

        function aderesoVirtualRepeatWatchExpression(scope) {
          var coll = scope.$eval(params.collection);
          if (coll.length !== state.total ) {
            state.total = coll.length;
          }
          return {
            addTop: state.addTop,
            removeTop: state.removeTop,
            addBottom: state.addBottom,
            removeBottom: state.removeBottom,
            len: coll.length
          };
        }

        function deleteAllActiveElements() {
          var dead;
          state.head = 0;
          state.tail = 0;
          state.total = 0;
          for(var i = 0; i < rendered.length; i++) {
            dead = rendered[i];
            dead.scope().$destroy();
            dead.remove();
          }
        }

        var previousHead = 0;
        var previousTail = 0;

        function destroyActiveElement(action) {
          var dead;
          var remover = Array.prototype[action];
          if (action === 'shift') {
            state.head = state.head+1;
          } else if (action === 'pop') {
            state.tail = state.tail -1;
          }

          dead = remover.call(rendered);

          if (!dead) {
            return undefined;
          }

          var clientHeight = dead[0].clientHeight;
          dead.scope().$destroy();
          dead.remove();
          return clientHeight;
        }
        function aderesoVirtualRepeatResetListener(newValue, oldValue, scope) {
          if (newValue && newValue.reset === true) {
            myLockAtEnd = true;
            deleteAllActiveElements();
            dom.content.css({'padding-top': '0px'});
            dom.content.css({'height': '0px'});
            state.bottom = 0;
            state.top = 0;
            dom.viewport[0].scrollTop = 0;

            state.tail = 0;
            state.total = scope.$eval(params.collection).length;
            rendered = addElements(state.head, state.tail, params.collection, scope, iterStartElement);

            if (state.total > 1){
              addBottom(thisScope);
            }
            myLockAtEnd = false;
            newValue.reset = false;
          }
        }

        function aderesoVirtualRepeatListener(newValue, oldValue, scope) {
          let currentRemoveBottomIteration = 0;
          const maxRemoveBottomIterations = 10;
        
          if (newValue === oldValue) {
            if (newValue.len > 0) {
              state.tail = 0;
              rendered = addElements(state.head, state.tail, params.collection, scope, iterStartElement);
              checkBottom();
            }
          } else {
            if (newValue.len > oldValue.len && oldValue.len === 0) {
              state.tail = 0;
              rendered = addElements(state.head, state.tail, params.collection, scope, iterStartElement);
              checkBottom();
            }
            if (newValue.addTop) addTop(scope);
            if (newValue.removeTop) state.removeTop = false;
            if (newValue.addBottom) addBottom(scope);
        
            if (newValue.removeBottom) {
              let tmp = 0;
              currentRemoveBottomIteration = 0;
              while (params.maxRender < state.bottom - tmp) {
                let clientHeight = destroyActiveElement('pop');
                if (typeof clientHeight === 'undefined' || currentRemoveBottomIteration === maxRemoveBottomIterations) break;
                currentRemoveBottomIteration++;
                tmp += clientHeight;
              }
              state.removeBottom = false;
            }
          }
        }

        function addBottom(scope) {
          var element = addBottomAux(scope);
          $timeout(function() {
            showElement(element);
            var pxAdded = element[0].clientHeight;
            state.bottom += pxAdded;
            if (state.total > state.tail +1 && state.bottom < params.minRender) {
              addBottom(scope);
            } else {
              if (state.total === state.tail + 1  && params.atEnd !== undefined) {
                if (!myLockAtEnd && !scope.$eval(params.lockAtEnd)) {
                  scope.$eval(params.atEnd);
                }
              }
            }
          },1);
          state.addBottom = false;
          return element;
        }

        function addBottomAux(scope) {
          var element = addElement('push', params.collection, scope);
          return element;
        }

        function addTop(scope) {
          var element = addTopAux(scope);
          $timeout(function() {
            showElement(element);
            var pxAdded = element[0].clientHeight;
            state.top += pxAdded;
            paddingTop -= pxAdded;
            dom.content.css({'padding-top': paddingTop + 'px'});
            if (state.head > 0 && state.top < params.minRender) {
              addTop(scope);
            } else {
              if (state.head === 0) {
                paddingTop = 0;
                dom.content.css({'padding-top': paddingTop + 'px'});
              }
            }

          },1);
          state.addTop = false;
          return element;
        }

        function addTopAux(scope) {
          var element = addElement('unshift', params.collection, scope);
          return element;
        }

      }
    }
  }]);

}());
