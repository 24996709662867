'use strict';

angular.module('postCenterWebClientApp')
  .filter('roleTranslate', function (translateFilter) {
    return function (input) {
      var rolesToStr = {
        "Administrators": "USERS_ROLE_ADMIN",
        "Analysts": "USERS_ROLE_ANALYST",
        "Community Managers": "USERS_ROLE_CM",
      };
      var str = rolesToStr[input];
      return str? translateFilter(str) : input;
    };
  });
