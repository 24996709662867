const WorkloadFilters = Vue.component('WorkloadFilters', {
  i18n: {
    messages: {
      es: {
        filter: 'Filtros'
      },
      pt: {
        filter: 'Filters'
      },
      en: {
        filter: 'Filtros'
      }
    }
  },
  template: `<div class="d-flex">
  <h6>{{$t('filter')}}:</h6>
  <DepartmentFilter class="ml-1 mr-3" />
  <AgentsFilter class="mr-3" />
  <StatusesFilter class="mr-3" />
  <AssignmentModeFilter />
  </div>`
});
