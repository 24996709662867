'use strict';

angular.module('postCenterWebClientApp').factory('AnalyticsHelper', [
  '$filter',
  function($filter) {
    var AH = {
      periodDiffCalc: function(previous, current) {
        if (previous === 0 && current > 0) {
          return 100;
        } else if (previous === 0 && current === 0) {
          return 0;
        }

        var diff = Math.round(current - (previous * 100) / previous);
        if (isNaN(diff)) {
          return 0;
        }
        return diff;
      },

      splitPoints: function(points) {
        if (angular.isArray(points)) {
          var copy = angular.copy(points);
          var secondHalf = copy.splice(Math.ceil(copy.length / 2));
          return [copy, secondHalf];
        }
        return points;
      },

      resolveLabel: function(resolve, key) {
        if (resolve && resolve.resolver && resolve.data) {
          var searchObj = {};
          searchObj[resolve.resolver] = key;
          // if key is number, we need to convert it to int
          if (isFinite(key) && !isNaN(parseInt(key))) {
            searchObj[resolve.resolver] = parseInt(key);
          }
          // We base the visibility of metrics on the state is_connected.
          // this only work with channels graph.
          var match = _.findWhere(resolve.data, searchObj);
          if (match) {
            var info = {
              value: match[resolve.key],
              success: true,
              visible: true
            };
            if (angular.isDefined(match.is_connected)) {
              info.visible = match.is_connected;
            }
            return info;
          }
        }
        return {
          success: false,
          value: key
        };
      },

      formatValue: function(type, value) {
        switch (type) {
          case 'time':
            return $filter('formatSeconds')(value, true);
          case 'number':
            return $filter('numeral')(value, '0[.]00 a');
          case 'percentage':
            return $filter('numeral')(value, '0[.] a') + '%';
        }
        return value;
      },

      getRawDataList: function(sample, metricScheme, parentMetric) {
        var detailData = getDetailData(sample, metricScheme, parentMetric);
        return buildRawDataList(sample, metricScheme, detailData);

        function buildRawDataList(sample, metricScheme, detailData) {
          var rawDataList = [];

          angular.forEach(detailData, function(data, key) {
            if (metricScheme.filter && metricScheme.filter.value != null) {
              var filtered = data[metricScheme.filter.value];
              if (filtered && filtered.points) {
                data = filtered;
              } else if (key != metricScheme.filter.value) {
                return;
              }
            }

            var name = key;
            var points = data.points;
            var visible = true;

            if (angular.isFunction(metricScheme.transform)) {
              points = metricScheme.transform(points, sample.data.labels);
            }

            if (metricScheme.resolve) {
              var resolution = AH.resolveLabel(metricScheme.resolve, key);
              if (resolution.success) {
                name = resolution.value;
                visible = resolution.visible;
              }
            }

            rawDataList.push({
              key: key,
              name: name,
              points: points,
              visible: visible
            });
          });

          return rawDataList;
        }

        function getDetailData(sample, metricScheme, parentMetric) {
          var dataDetail = sample.data.detail[metricScheme.key] || {};

          switch (metricScheme.type) {
            case 'parent':
              var detail = {};
              var parentDetail = dataDetail[parentMetric.data.key];
              if (parentDetail) {
                detail[parentMetric.data.key] = parentDetail;
              }
              return detail;
            case 'aggregated':
              if (parentMetric) {
                dataDetail = dataDetail[parentMetric.data.key];
              }
              break;
            case 'crossed':
              if (metricScheme.crossResolve) {
                return Object.keys(dataDetail).reduce(function(
                  crossDetail,
                  key
                ) {
                  var resolution = AH.resolveLabel(
                    metricScheme.crossResolve,
                    key
                  );
                  if (
                    resolution &&
                    resolution.value === parentMetric.data.key
                  ) {
                    crossDetail[key] = dataDetail[key];
                  }
                  return crossDetail;
                },
                {});
              }
              break;
            case 'resume':
              // Resumes the data of the second level into the first level,
              // in other words, the two-levels data detail is transformed
              // in an one-level data datail. Example:
              //   cs (cms x source) is transformed into just cms
              var resume = {};
              for (var key in dataDetail) {
                var keyResume = (resume[key] = {overall: 0, points: []});
                var keyData = dataDetail[key];
                for (var subKey in keyData) {
                  var subKeyPoints = keyData[subKey]['points'] || [];
                  subKeyPoints.forEach(function(point, idx) {
                    keyResume.points[idx] =
                      (keyResume.points[idx] || 0) + point;
                  });
                  keyResume.overall += keyData[subKey]['overall'] || 0;
                }
              }
              dataDetail = resume;
              break;
          }

          return dataDetail;
        }
      },

      getSeries: function(sample, metricScheme, parentMetric) {
        var rawDataList = AH.getRawDataList(sample, metricScheme, parentMetric);

        switch (metricScheme.series) {
          case 'counter':
            return generateCounterSeries(rawDataList, sample.data.labels);
          default:
            return generateDefaultSeries(rawDataList, sample.data.labels);
        }

        // [t1, t2...] [v1, v2...] => [V], V = v1 + v2 ...
        function generateCounterSeries(rawDataList, labels) {
          var data = rawDataList.map(function(rawData) {
            return {
              name: rawData.name,
              y: currentPeriodSummatory(rawData.points)
            };
          });

          var name = parentMetric ? parentMetric.data.key : '';
          return [{name: name, colorByPoint: true, data: data}];

          function currentPeriodSummatory(points) {
            return points.slice(points.length / 2).reduce(function(sum, value) {
              return sum + value;
            }, 0);
          }
        }

        // [t1, t2...] [v1, v2...] => [[t1, v1], [t2, v2]...]
        function generateDefaultSeries(rawDataList, labels) {
          return rawDataList.map(function(data) {
            var serieData = [];
            if (angular.isArray(data.points[0])) {
              angular.forEach(data.points, function(values, index) {
                serieData.push([labels[index], values[1]]);
              });
            } else {
              angular.forEach(data.points, function(value, index) {
                serieData.push([labels[index], value]);
              });
            }

            return {
              name: data.name,
              data: AH.splitPoints(serieData)[1],
              visible: data.visible
            };
          });
        }
      },

      getBubbleMetricDataList: function(sample, metricScheme, parentMetric) {
        var iterCount = 0;
        var rawDataList = AH.getRawDataList(sample, metricScheme, parentMetric);

        return rawDataList.map(function(rawData) {
          var segregated = AH.splitPoints(rawData.points);
          var currCounter = count(segregated[1]);
          var prevCounter = count(segregated[0]);

          var currSegregated = getCountResult(currCounter);
          var prevSegregated = getCountResult(prevCounter);

          iterCount++;

          return {
            id: rawData.key,
            name: rawData.name,
            index: iterCount,
            value: currSegregated,
            previousValue: prevSegregated,
            variation: AH.periodDiffCalc(prevSegregated, currSegregated),
            previousPeriodCounter: prevCounter,
            inPeriodCounter: currCounter
          };
        });

        function getCountResult(result) {
          if (angular.isArray(result)) {
            var value = result[result.length - 1];
            return isNaN(value) ? 0 : value;
          }
          return result;
        }

        function count(data) {
          if (angular.isArray(data)) {
            if (angular.isArray(data[0])) {
              return countDouble();
            }
            return countSingle();
          }
          return 0;

          function countSingle() {
            return data.reduce(function(total, val) {
              return total + val;
            }, 0);
          }

          function countDouble() {
            var value1 = 0;
            var value2 = 0;
            angular.forEach(data, function(values) {
              value1 += values[0];
              value2 += values[1];
            });

            var result = value1 > 0 ? value2 / value1 : value1;
            result = isNaN(result) ? 0 : Math.round(result);

            return [value1, value2, result];
          }
        }
      }
    };

    return AH;
  }
]);
