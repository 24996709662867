const DateFilterMixin = {
  data() {
    return {
      custom: false,
      selected: 0,
      lastSelected: 0,
      toggleList: false,
      input: ''
    };
  },
  computed: {
    isActive() {
      return this.toggleList ? 'active' : '';
    }
  },
  methods: {
    fromDaysAgo(days = 0) {
      return moment().subtract(days, 'days').unix();
    },
    cancelCustom() {
      this.custom = false;
      this.selected = this.lastSelected;
    },
    closeSelector() {
      this.toggleList = false;
    },
    toggleSelector(event) {
      if (
        event.target.id !== 'date-search-input' &&
        event.target.id !== 'date-radio'
      ) {
        this.toggleList = !this.toggleList;
      }
    },
    isLetter(event) {
      let character = String.fromCharCode(event.keyCode);
      if (/^[A-Za-z0-9']+$/.test(character)) {
        return true;
      } else {
        event.preventDefault();
      }
    }
  }
};
