'use strict';

angular.module('postCenterWebClientApp')
  .factory('AderesoFilename', function() {

    // https://gist.github.com/mathewbyrne/1280286
    function slugify (text) {

      var a = 'àáäâèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/_,:;'
      var b = 'aaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh------'
      var p = new RegExp(a.split('').join('|'), 'g')

      return text.toString().toLowerCase()
        .replace(/\s+/g, '-')           // Replace spaces with -
        .replace(p, function(c) { return b.charAt(a.indexOf(c)); }) // Replace special chars
        .replace(/&/g, '-and-')         // Replace & with 'and'
        .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
        .replace(/\-\-+/g, '-')         // Replace multiple - with single -
        .replace(/^-+/, '')             // Trim - from start of text
        .replace(/-+$/, '')             // Trim - from end of text
    }

    function extension(filename) {
      var re = /(?:\.([^.]+))?$/;
      return re.exec(filename)[1];
    }

    function slugFileName(filename) {
      var ext = extension(filename);
      var slug = slugify(filename);
      return slug + '.' + ext;
    }

    return {
      slugify: slugify,
      extension: extension,
      slugFileName: slugFileName
    };
  }
);
