const EpaAnswersTags = Vue.component('EpaAnswersTags', {
  props: {
    surveyType: {
      type: String,
      required: true,
      validator: survey =>
        ['res', 'csat', 'ces', 'fcr', 'open'].includes(survey)
    },
    optionType: {
      type: String,
      default: 'tags',
      validator: tag => ['tags', 'choices'].includes(tag)
    },
    inverted: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      validTags: true,
      unsavedChanges: false,
      tags: ['lowest', 'low', 'middle', 'high', 'highest'],
      choices: ['no', 'yes']
    };
  },
  computed: {
    ...Vuex.mapGetters('epaConfig', ['getSurveyAlternatives']),
    survey() {
      return this.getSurveyAlternatives(this.surveyType);
    },
    getAnswers() {
      return this.optionType === 'tags' ? this.tags : this.choices;
    },
    areTags() {
      return this.optionType === 'tags';
    },
    tagGrid() {
      return this.areTags ? 'tags' : 'choices';
    },
    answersToDisplay() {
      if (this.inverted) {
        const reversedAnswers = this.getAnswers.slice().reverse();
        return reversedAnswers.map((tag, index) => ({
          tag,
          label: this.$t('types[' + (this.getAnswers.length - index - 1) + ']')
        }));
      } else {
        return this.getAnswers.map((tag, index) => ({
          tag,
          label: this.$t('types[' + index + ']')
        }));
      }
    }
  },
  methods: {
    checkTags() {
      const {answer_tags} = this.survey;
      this.unsavedChanges = true;
      this.validTags =
        Object.values(answer_tags).find(tag => tag === '') === undefined;
    }
  },
  i18n: {
    messages: {
      es: {
        types: ['Más bajo', 'Bajo', 'Medio', 'Alto', 'Más alto'],
        choicesDescription: ['Valor negativo', 'Valor afirmativo']
      },
      pt: {
        types: ['Menos', 'Baixo', 'Médio', 'Alto', 'Mais alto'],
        choicesDescription: ['Valor negativo', 'Valor afirmativo']
      },
      en: {
        types: ['Lowest', 'Low', 'Medium', 'High', 'Hightest'],
        choicesDescription: ['Negative value', 'Affirmative value']
      }
    }
  },
  template: `
  <div>
    <div class="answer-tags mb-1" v-for="(answer, index) in answersToDisplay" :class="tagGrid">
      <div class="answer-tags__number" v-if="areTags">
        <label class="mr-1">{{index + 1}}.{{ answer.label }}</label>
      </div>
      <div class="answer-tags__input">
        <InputText
          class="w-90"
          @input="checkTags"
          :disabled="!survey.enabled"
          v-model="survey.answer_tags[answer.tag]"
        />
      </div>
      <div class="answer-tags__info" v-if="!areTags">
        <p>{{ $t('choicesDescription['+ index +']')}}</p>
      </div>
    </div>
  </div>`
});
