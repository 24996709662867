(module => {
  'use strict';
  NewForgottenPasswordCtrl.$inject = [
    '$scope',
    '$transition$',
    'AdNotification',
    'AuthService',
    'StylesService'
  ];
  function NewForgottenPasswordCtrl(
    $scope,
    $transition$,
    AdNotification,
    AuthService,
    StylesService
  ) {
    $scope.verifying = true;
    $scope.valid = true;

    if ($transition$.params().token) {
      AuthService.verifyUrl($transition$.params().token).then(
        data => {
          $scope.state = data['state'];
          $scope.valid = true;
          $scope.verifying = false;
        },
        () => {
          $scope.verifying = false;
          $scope.valid = false;
        }
      );
    } else {
      $scope.verifying = false;
      $scope.valid = false;
    }

    StylesService.getStyles().then(styles => {
      $scope.styles = styles;
    });

    $scope.changePassword = function () {
      AuthService.changePassword(
        $scope.password,
        $scope.verify_password,
        $transition$.params().token,
        $scope.state
      ).then(
        () => {
          $scope.changed = true;
        },
        data => {
          $scope.changed = false;
          AdNotification.error(data, 'change_password');
        }
      );
    };
  }
  module.controller('NewForgottenPasswordCtrl', NewForgottenPasswordCtrl);
})(angular.module('postCenterWebClientApp'));
