'use strict';

var app = angular.module('postCenterWebClientApp');
app.directive('realTimeSelector', function () {
  return {
    templateUrl: 'blocks/analytics/views/directives/real_time_selector.html',
    restrict: 'E',
    controller: 'RealTimeSelectorCtrl',
    scope: {}
  }
});

app.controller('RealTimeSelectorCtrl', RealTimeSelectorCtrl);

RealTimeSelectorCtrl.$inject = [
  '$scope', '$rootScope', 'localStorageService'
];
function RealTimeSelectorCtrl($scope, $rootScope, localStorageService) {

  var storageRTKey = 'analytics:realTimeOn'
  $scope.realTimeOn = localStorageService.get(storageRTKey) || false;

  const realTimeWatcherOff = $scope.$watch('realTimeOn', function() {

    var emitKey = 'analytics:dashboard:changeRealTimeOn';
    $rootScope.$emit(emitKey, $scope.realTimeOn);
    localStorageService.set(storageRTKey, $scope.realTimeOn);

  });

  $scope.$on('$destroy', function () {
    realTimeWatcherOff();
  });
}

