(module => {
  'use strict';
  function adTopBar() {
    return {
      restrict: 'E',
      scope: {
        config: '='
      },
      templateUrl: 'blocks/top_bar/views/ad-top-bar.html',
      controller: 'topBarCtrl'
    };
  }
  module.directive('adTopBar', adTopBar);
})(angular.module('postCenterWebClientApp'));
