'use strict';

angular
  .module('postCenterWebClientApp')
  .directive('socialAccount', function () {
    return {
      templateUrl: 'blocks/admin/views/social_accounts/social_account.html',
      restrict: 'E',
      scope: {
        socialAccount: '=',
        disconnectCallback: '=',
        accountIndex: '=',
        reconnect: '='
      },
      controller: [
        '$scope',
        '$rootScope',
        'EstablishmentService',
        'InitialData',
        'AdNotification',
        'SocialAccount',
        'SocialAccountService',
        '$filter',
        '$uibModal',
        'AdGlobalInformation',
        'smoochConnectService',
        '$sce',
        'AdRequest',
        'FRONTEND_VUE_URL',
        function (
          $scope,
          $rootScope,
          EstablishmentService,
          InitialData,
          AdNotification,
          SocialAccount,
          SocialAccountService,
          $filter,
          $uibModal,
          AdGlobalInformation,
          smoochConnectService,
          $sce,
          AdRequest,
          FRONTEND_VUE_URL
        ) {
          $scope.loading = false;
          $scope.openConfig = false;
          $scope.accountConfigurations = {};
          $scope.isCategoryOpen = {};
          $scope.snippets = {};

          const translate = $filter('translate');

          const {sn, uid} = $scope.socialAccount;

          // original with status from socialAccount
          const {whatsapp_provider, status} = $scope.socialAccount.config;

          if (sn === 'whatsapp' && whatsapp_provider === 'smooch') {
            //to show last step on status
            $scope.smoochProcess = status !== 'ready';
            $scope.socialAccount.config.smooch =
              smoochConnectService.getSmoochSteps(status);
            if ($scope.smoochProcess) {
              $scope.openConfig = true;
            }
          }
          $scope.askForPin = () => {
            const message = translate(
              'SOCIALACCOUNTS_SMOOCH_PROCESS_SUPPORT_MESSAGE',
              {
                number: uid
              }
            );
            window.Intercom('showNewMessage', message);
          };
          function processAccountConfigurations() {
            const configurations = $scope.socialAccount.config;

            const {sn, type} = $scope.socialAccount;

            const parseableKeys = [
              'first_contact_messages',
              'away_messages',
              'out_work_hours_messages',
              'new_ticket_messages',
              'close_ticket_messages',
              'auto_close_ticket_messages',
              'containment_messages',
              'case_notification_emails'
            ];

            if (!configurations) {
              return;
            } else {
              angular.forEach(configurations, function (config, key) {
                if (parseableKeys.indexOf(key) !== -1) {
                  if (
                    config &&
                    angular.isString(config) &&
                    config.trim().length > 0
                  ) {
                    configurations[key] = JSON.parse(config);
                  }
                }
              });
            }
            setAccountConfigurations(configurations, sn, type);
          }

          const shouldHide = function (parent, value = undefined) {
            return function () {
              let parentConfig = null;
              let hide = false;

              if (this.parent) {
                parentConfig = _.findWhere(parent, {
                  key: this.parent
                });

                if (parentConfig) {
                  if (angular.isUndefined(value)) {
                    hide = parentConfig.value ? false : true;
                  } else {
                    hide = parentConfig.value !== value;
                  }
                }
              }
              return hide;
            };
          };

          $scope.saveConfigurations = function () {
            $scope.loading = true;
            const params = {};
            const accountId = $scope.socialAccount.id;

            function parseConfiguration(config) {
              if (config.editable) {
                if (config.type === 'time_unit') {
                  if (!angular.isNumber(config.value) || config.value < 0) {
                    config.value = 0;
                  }

                  params[config.valueKey] = JSON.stringify(config.value);
                  params[config.unitKey] = config.unit;
                } else if (config.type === 'select_time_unit') {
                  if (config.unit === config.selectUnit) {
                    config.value = parseInt(config.selectValue, 10);
                  }
                  if (!angular.isNumber(config.value) || config.value < 1) {
                    config.value = 1;
                  }

                  params[config.valueKey] = JSON.stringify(config.value);
                  params[config.unitKey] = config.unit;
                } else {
                  if (config.type === 'list') {
                    params[config.key] = _.map(config.value, function (obj) {
                      try {
                        return obj.text;
                      } catch (error) {
                        return '';
                      }
                    });
                  } else if (angular.isArray(config.value)) {
                    params[config.key] = JSON.stringify(config.value);
                  } else if (angular.isObject(config.value)) {
                    params[config.key] = JSON.stringify(config.value);
                  } else {
                    params[config.key] = config.value;
                  }
                }

                if (angular.isDefined(config.childs)) {
                  angular.forEach(config.childs, parseConfiguration);
                }
              }
            }

            angular.forEach($scope.accountConfigurations, function (category) {
              angular.forEach(category, parseConfiguration);
            });

            SocialAccountService.saveConfig(accountId, params).then(
              function (updatedAccount) {
                InitialData.refreshAdmin().then(
                  function () {
                    $scope.loading = false;
                    $scope.socialAccount.config = updatedAccount.config;
                    processAccountConfigurations();
                    AdNotification.success(201, 'edit_social_account_config');
                  },
                  function (error) {
                    $scope.loading = false;
                    AdNotification.error(error, 'edit_social_account_config');
                  }
                );
              },
              function (error) {
                $scope.loading = false;
                AdNotification.error(error, 'edit_social_account_config');
              }
            );
          };

          $scope.disconnectAccount = function (sn, uid) {
            $scope.disconnectingAccount = true;
            SocialAccountService.disconnect(sn, uid).then(
              function (response) {
                $scope.disconnectingAccount = false;
                $scope.disconnectCallback();
                AdNotification.success(201, 'disconnect_social_account');
              },
              function (data) {
                $scope.disconnectingAccount = false;
                AdNotification.error(data, 'disconnect_social_account');
              }
            );
          };
          const showStatus = translate('SOCIALACCOUNTS_SHOW_STATUS');
          const hideStatus = translate('SOCIALACCOUNTS_HIDE_STATUS');

          $scope.statusButton = hideStatus;
          $scope.toggleAccountConfig = function () {
            $scope.openConfig = !$scope.openConfig;
            $scope.statusButton = $scope.openConfig ? hideStatus : showStatus;
          };

          $scope.openEmailValidationModal = function (socialAccount) {
            const modalInstance = $uibModal.open({
              templateUrl:
                'blocks/admin/views/social_accounts/' +
                'email_validation_modal.html',
              controller: 'EmailValidationModalCtrl',
              controllerAs: 'vm',
              bindToController: 'true',
              size: 'small',
              resolve: {
                account: function () {
                  return socialAccount;
                }
              }
            });

            modalInstance.opened.then(function () {
              $scope.loading = false;
            });

            modalInstance.result.then(function (updatedAccount) {
              $scope.socialAccount.statuses.forwarding_validated = true;
              $scope.socialAccount.isHealthy = true;
            });
          };
          $scope.reconnectFacebook = () => {
            $scope.reconnect('facebook');
          };

          $scope.reconnectAccount = function (account) {
            function reconnectWhatsapp() {
              function extractPhoneNumber(rawPhone, countryCode) {
                let phone = undefined;
                if (rawPhone.indexOf(countryCode) === 0) {
                  phone = rawPhone.substr(countryCode.length);
                }
                return phone;
              }

              const whatsappData = {
                provider: 'whatsapp',
                cc: account.config.country_code,
                mnc: account.config.mnc,
                phone: extractPhoneNumber(
                  account.uid,
                  account.config.country_code
                ),
                name: account.name,
                voice:
                  account.statuses.registration_method === 'voice'
                    ? true
                    : false
              };

              const whatsappState = {
                pendingRequest: false,
                lastAttempt: account.statuses.registration_last_attempt,
                method: account.statuses.registration_method,
                result: 'fail',
                retryAfter: account.statuses.retry_after || 0
              };

              const eventData = {
                whatsapp: whatsappData,
                whatsappState: whatsappState
              };

              const event = 'AddSocialAccount:backToRequestCode';
              $rootScope.$emit(event, eventData);
            }
            switch (account.sn) {
              case 'whatsapp':
                reconnectWhatsapp();
                break;
            }
          };

          $scope.getOptionCategoryTranslation = function (categoryName) {
            return $filter('translate')(
              'ACCOUNT_CONFIG_CATEGORY_' + categoryName.toUpperCase()
            );
          };

          $scope.hasConfigs = function () {
            return _.some($scope.accountConfigurations, function (category) {
              return category.length > 0;
            });
          };

          $scope.toggleCategory = function (categoryName) {
            $scope.isCategoryOpen[categoryName] =
              !$scope.isCategoryOpen[categoryName];
          };

          $scope.iFrameUrl = function(uid: string, sn: string) {
            const token = AdRequest.getToken();
            const url = `${FRONTEND_VUE_URL}/frames/channel/${sn}/${uid}/config?authToken=${token}`
           return $sce.trustAsResourceUrl(url);
          };

          function addAutomaticMessagesConfigurations(configurations, sn) {
            const type = 'multi-long-text';
            let fcPlaceholderKey =
              'ACCOUNT_CONFIG_FIRST_CONTACT_MESSAGES_ACTIVE_PLACEHOLDER';
            let amPlaceholderKey =
              'ACCOUNT_CONFIG_AWAY_MESSAGES_ACTIVE_PLACEHOLDER';
            let owPlaceholderKey =
              'ACCOUNT_CONFIG_OUT_WORK_HOUR_MESSAGES_ACTIVE_PLACEHOLDER';
            let ntPlaceholderKey =
              'ACCOUNT_CONFIG_NEW_TICKET_MESSAGES_ACTIVE_PLACEHOLDER';
            let ocPlaceholderKey =
              'ACCOUNT_CONFIG_CLOSE_TICKET_MESSAGES_ACTIVE_PLACEHOLDER';
            let ctPlaceholderKey =
              'ACCOUNT_CONFIG_CONTAINMENT_ACTIVE_PLACEHOLDER';

            if (sn === 'email') {
              fcPlaceholderKey = `${fcPlaceholderKey}_EMAIL`;
              amPlaceholderKey = `${amPlaceholderKey}_EMAIL`;
              owPlaceholderKey = `${owPlaceholderKey}_EMAIL`;
              ntPlaceholderKey = `${ntPlaceholderKey}_EMAIL`;
              ocPlaceholderKey = `${ocPlaceholderKey}_EMAIL`;
              ctPlaceholderKey = `${ctPlaceholderKey}_EMAIL`;
            }

            const tags = [
              {
                key: 'case_id',
                label: translate('GENERAL_TICKET_ID')
              },
              {
                key: 'uname',
                label: translate('GENERAL_USER_NAME')
              },
              {
                key: 'name',
                label: translate('GENERAL_USER_NICKNAME')
              }
            ];

            $scope.accountConfigurations.automation = [];

            $scope.accountConfigurations.automation.push({
              key: 'no_client_reply_close',
              label: translate('ACCOUNT_CONFIG_NO_CLIENT_REPLY_CLOSE'),
              help: translate('ACCOUNT_CONFIG_NO_CLIENT_REPLY_CLOSE_HELP'),
              value: configurations.no_client_reply_close,
              type: 'boolean',
              editable: true,
              copy: false,
              longContent: false,
              childs: [
                {
                  valueKey: 'no_client_reply_close_time',
                  unitKey: 'no_client_reply_close_unit',
                  label: translate('ACCOUNT_CONFIG_NO_CLIENT_REPLY_CLOSE_TIME'),
                  help: translate(
                    'ACCOUNT_CONFIG_NO_CLIENT_REPLY_CLOSE_TIME_HELP'
                  ),
                  value: configurations.no_client_reply_close_time,
                  unit: configurations.no_client_reply_close_unit,
                  availableUnits: ['MINUTES', 'HOURS', 'DAYS'],
                  type: 'select_time_unit',
                  selectValue:
                    configurations.no_client_reply_close_time.toString(),
                  selectUnit: 'MINUTES',
                  selectValues: [5, 15, 30, 45],
                  editable: true,
                  copy: false,
                  longContent: false,
                  parent: 'no_client_reply_close',
                  shouldHide: shouldHide(
                    $scope.accountConfigurations.automation
                  )
                }
              ]
            });
            if (sn !== 'instagram') {
              $scope.accountConfigurations.automation.push({
                key: 'first_contact_message_active',
                label: translate('ACCOUNT_CONFIG_FIRST_CONTACT_MESSAGE_ACTIVE'),
                help: translate(
                  'ACCOUNT_CONFIG_FIRST_CONTACT_MESSAGE_ACTIVE_HELP'
                ),
                value: configurations.first_contact_message_active,
                type: 'boolean',
                editable: true,
                copy: false,
                longContent: false,
                childs: [
                  {
                    key: 'first_contact_messages',
                    label: translate('ACCOUNT_CONFIG_FIRST_CONTACT_MESSAGES'),
                    help: translate(
                      'ACCOUNT_CONFIG_FIRST_CONTACT_MESSAGES_ACTIVE_HELP'
                    ),
                    value: configurations.first_contact_messages,
                    type: type,
                    placeholder: translate(fcPlaceholderKey),
                    editable: true,
                    copy: false,
                    longContent: true,
                    parent: 'first_contact_message_active',
                    tags: tags,
                    shouldHide: shouldHide(
                      $scope.accountConfigurations.automation
                    )
                  }
                ]
              });

              $scope.accountConfigurations.automation.push({
                key: 'away_message_active',
                label: translate('ACCOUNT_CONFIG_AWAY_MESSAGE_ACTIVE'),
                help: translate('ACCOUNT_CONFIG_AWAY_MESSAGE_ACTIVE_HELP'),
                value: configurations.away_message_active,
                type: 'boolean',
                editable: true,
                copy: false,
                longContent: false,
                childs: [
                  {
                    key: 'away_messages',
                    label: translate('ACCOUNT_CONFIG_AWAY_MESSAGES'),
                    help: translate('ACCOUNT_CONFIG_AWAY_MESSAGES_ACTIVE_HELP'),
                    value: configurations.away_messages,
                    type: type,
                    placeholder: translate(amPlaceholderKey),
                    editable: true,
                    longContent: true,
                    copy: false,
                    parent: 'away_message_active',
                    tags: tags,
                    shouldHide: shouldHide(
                      $scope.accountConfigurations.automation
                    )
                  }
                ]
              });

              $scope.accountConfigurations.automation.push({
                key: 'out_work_hours_active',
                label: translate('ACCOUNT_CONFIG_OUT_WORK_HOUR_MESSAGE_ACTIVE'),
                help: translate(
                  'ACCOUNT_CONFIG_OUT_WORK_HOUR_MESSAGE_ACTIVE_HELP'
                ),
                value: configurations.out_work_hours_active,
                type: 'boolean',
                editable: true,
                copy: false,
                longContent: false,
                childs: [
                  {
                    key: 'out_work_hours_case_action',
                    label: translate(
                      'ACCOUNT_CONFIG_OUT_WORK_HOUR_MESSAGE_CLOSE'
                    ),
                    help: translate(
                      'ACCOUNT_CONFIG_OUT_WORK_HOUR_MESSAGE_CLOSE_HELP'
                    ),
                    value: configurations.out_work_hours_case_action,
                    type: 'boolean',
                    editable: true,
                    longContent: false,
                    copy: false,
                    parent: 'out_work_hours_active',
                    shouldHide: shouldHide(
                      $scope.accountConfigurations.automation
                    )
                  },
                  {
                    key: 'out_work_hours_messages',
                    label: translate('ACCOUNT_CONFIG_OUT_WORK_HOUR_MESSAGE'),
                    help: translate(
                      'ACCOUNT_CONFIG_OUT_WORK_HOUR_MESSAGES_ACTIVE_HELP'
                    ),
                    value: configurations.out_work_hours_messages,
                    placeholder: translate(owPlaceholderKey),
                    type: type,
                    editable: true,
                    longContent: true,
                    copy: false,
                    parent: 'out_work_hours_active',
                    tags: tags,
                    shouldHide: shouldHide(
                      $scope.accountConfigurations.automation
                    )
                  }
                ]
              });

              $scope.accountConfigurations.automation.push({
                key: 'new_ticket_active',
                label: translate('ACCOUNT_CONFIG_NEW_TICKET_MESSAGE_ACTIVE'),
                help: translate(
                  'ACCOUNT_CONFIG_NEW_TICKET_MESSAGE_ACTIVE_HELP'
                ),
                value: configurations.new_ticket_active,
                type: 'boolean',
                editable: true,
                copy: false,
                longContent: false,
                childs: [
                  {
                    key: 'new_ticket_messages',
                    label: translate('ACCOUNT_CONFIG_NEW_TICKET_MESSAGE'),
                    help: translate(
                      'ACCOUNT_CONFIG_NEW_TICKET_MESSAGES_ACTIVE_HELP'
                    ),
                    value: configurations.new_ticket_messages,
                    placeholder: translate(ntPlaceholderKey),
                    type: type,
                    editable: true,
                    longContent: true,
                    copy: false,
                    parent: 'new_ticket_active',
                    tags: tags,
                    shouldHide: shouldHide(
                      $scope.accountConfigurations.automation
                    )
                  }
                ]
              });

              $scope.accountConfigurations.automation.push({
                key: 'close_ticket_message_active',
                label: translate('ACCOUNT_CONFIG_CLOSE_TICKET_MESSAGE_ACTIVE'),
                help: translate(
                  'ACCOUNT_CONFIG_CLOSE_TICKET_MESSAGE_ACTIVE_HELP'
                ),
                value: configurations.close_ticket_message_active,
                type: 'boolean',
                editable: true,
                copy: false,
                longContent: false,
                childs: [
                  {
                    key: 'close_ticket_messages',
                    help: translate(
                      'ACCOUNT_CONFIG_CLOSE_TICKET_MESSAGES_HELP'
                    ),
                    value: configurations.close_ticket_messages,
                    placeholder: translate(ocPlaceholderKey),
                    type: type,
                    editable: true,
                    longContent: true,
                    copy: false,
                    parent: 'close_ticket_message_active',
                    tags: tags,
                    shouldHide: shouldHide(
                      $scope.accountConfigurations.automation
                    )
                  }
                ]
              });

              $scope.accountConfigurations.automation.push({
                key: 'auto_close_ticket_message_active',
                label: translate(
                  'ACCOUNT_CONFIG_AUTO_CLOSE_TICKET_MESSAGE_ACTIVE'
                ),
                help: translate(
                  'ACCOUNT_CONFIG_AUTO_CLOSE_TICKET_MESSAGE_ACTIVE_HELP'
                ),
                value: configurations.auto_close_ticket_message_active,
                type: 'boolean',
                editable: true,
                copy: false,
                longContent: false,
                childs: [
                  {
                    key: 'auto_close_ticket_messages',
                    help: translate(
                      'ACCOUNT_CONFIG_AUTO_CLOSE_TICKET_MESSAGES_HELP'
                    ),
                    value: configurations.auto_close_ticket_messages,
                    placeholder: translate(ocPlaceholderKey),
                    type: type,
                    editable: true,
                    longContent: true,
                    copy: false,
                    parent: 'auto_close_ticket_message_active',
                    tags: tags,
                    shouldHide: shouldHide(
                      $scope.accountConfigurations.automation
                    )
                  }
                ]
              });

              $scope.accountConfigurations.automation.push({
                key: 'containment_active',
                label: translate('ACCOUNT_CONFIG_CONTAINMENT_ACTIVE'),
                help: translate('ACCOUNT_CONFIG_CONTAINMENT_ACTIVE_HELP'),
                value: configurations.containment_active,
                type: 'boolean',
                editable: true,
                copy: false,
                longContent: false,
                childs: [
                  {
                    key: 'containment_messages',
                    help: translate('ACCOUNT_CONFIG_CONTAINMENT_MESSAGES_HELP'),
                    value: configurations.containment_messages,
                    placeholder: translate(ctPlaceholderKey),
                    type: type,
                    editable: true,
                    longContent: true,
                    copy: false,
                    parent: 'containment_active',
                    tags: tags,
                    shouldHide: shouldHide(
                      $scope.accountConfigurations.automation
                    )
                  },
                  {
                    key: 'containment_max_retries',
                    help: translate(
                      'ACCOUNT_CONFIG_CONTAINMENT_MAX_RETRIES_HELP'
                    ),
                    value: configurations.containment_max_retries,
                    type: 'number',
                    editable: true,
                    copy: false,
                    min: 0,
                    parent: 'containment_active',
                    longContent: false,
                    shouldHide: shouldHide(
                      $scope.accountConfigurations.automation
                    )
                  },
                  {
                    key: 'containment_last_message',
                    help: translate(
                      'ACCOUNT_CONFIG_CONTAINMENT_LAST_MESSAGE_HELP'
                    ),
                    value: configurations.containment_last_message,
                    placeholder: translate(ctPlaceholderKey),
                    type: 'text',
                    editable: true,
                    longContent: true,
                    copy: false,
                    parent: 'containment_active',
                    tags: tags,
                    shouldHide: shouldHide(
                      $scope.accountConfigurations.automation
                    )
                  }
                ]
              });
            }
          }

          function addAccountGeneralConfiguration(configData, allowedNetworks=null, sn=null, type=null) {
            const config = {
              editable: true,
              copy: false,
              longContent: false,
              ...configData
            };
            const shouldAddConfig = allowedNetworks === null || (
              allowedNetworks.indexOf(sn) > -1 && type != 'search'
            );
            if (shouldAddConfig) {
              $scope.accountConfigurations.general.push(config);
            }
          }

          function setAccountConfigurations(configurations, sn, type) {
            let options;

            $scope.accountConfigurations.slaAndAutoActions = [];
            $scope.accountConfigurations.general = [];
            $scope.accountConfigurations.general.push({
              key: 'important_keywords_active',
              label: translate('ACCOUNT_CONFIG_IMPORTANT_KEYWORDS_ACTIVE'),
              help: translate('ACCOUNT_CONFIG_IMPORTANT_KEYWORDS_ACTIVE_HELP'),
              value: configurations.important_keywords_active,
              type: 'boolean',
              editable: true,
              copy: false,
              longContent: false,
              childs: [
                {
                  key: 'important_keywords',
                  label: translate('ACCOUNT_CONFIG_IMPORTANT_KEYWORDS'),
                  help: translate('ACCOUNT_CONFIG_IMPORTANT_KEYWORDS_HELP'),
                  value: configurations.important_keywords,
                  placeholder: translate(
                    'ACCOUNT_CONFIG_IMPORTANT_KEYWORDS_ACTIVE_PLACEHOLDER'
                  ),
                  type: 'list',
                  editable: true,
                  longContent: true,
                  copy: false,
                  parent: 'important_keywords_active',
                  shouldHide: shouldHide($scope.accountConfigurations.general)
                }
              ]
            });

            const twAndFb = ['twitter', 'facebook'];
            const twAndFbConfig = {
              key: 'only_admins_can_post',
              label: translate('ACCOUNT_CONFIG_ONLY_ADMINS_CAN_POST'),
              help: translate('ACCOUNT_CONFIG_ONLY_ADMINS_CAN_POST_HELP'),
              value: configurations.only_admins_can_post,
              type: 'boolean'
            };
            addAccountGeneralConfiguration(twAndFbConfig, twAndFb, sn, type);

            const publicNetworks = [ 'twitter', 'facebook', 'instagram' ];
            const publicNetworksConfig = {
              key: 'separate_public_from_private_cases',
              label: translate('ACCOUNT_CONFIG_SEPARATE_PUBLIC_AND_PRIVATE_TICKETS'),
              help: translate('ACCOUNT_CONFIG_SEPARATE_PUBLIC_AND_PRIVATE_TICKETS_HELP'),
              value: configurations.separate_public_from_private_cases,
              type: 'boolean'
            }
            addAccountGeneralConfiguration(publicNetworksConfig, publicNetworks, sn, type);

            $scope.accountConfigurations.general.push({
              key: 'case_notifications',
              label: translate('ACCOUNT_CONFIG_CASE_NOTIFICATIONS'),
              help: translate('ACCOUNT_CONFIG_CASE_NOTIFICATIONS_HELP'),
              value: configurations.case_notifications,
              type: 'select',
              editable: true,
              copy: false,
              longContent: false,
              options: [
                {
                  label: translate(
                    'ACCOUNT_CONFIG_CASE_NOTIFICATIONS_DISABLED'
                  ),
                  value: 0
                },
                {
                  label: translate(
                    'ACCOUNT_CONFIG_CASE_NOTIFICATIONS_IMPORTANT_ONLY'
                  ),
                  value: 1
                },
                {
                  label: translate('ACCOUNT_CONFIG_CASE_NOTIFICATIONS_ALL'),
                  value: 2
                }
              ],
              childs: [
                {
                  key: 'case_notification_emails',
                  help: translate(
                    'ACCOUNT_CONFIG_CASE_NOTIFICATION_EMAILS_HELP'
                  ),
                  value: configurations.case_notification_emails,
                  type: 'emails',
                  editable: true,
                  placeholder: translate(
                    'ACCOUNT_CONFIG_CASE_NOTIFICATION_EMAILS_PLACEHOLDER'
                  ),
                  copy: false,
                  parent: 'case_notifications',
                  longContent: true,
                  shouldHide: shouldHide($scope.accountConfigurations.general)
                }
              ]
            });

            $scope.accountConfigurations.general.push({
              key: 'create_closed_cases',
              label: translate('ACCOUNT_CONFIG_CREATE_IGNORED_TICKETS'),
              help: translate('ACCOUNT_CONFIG_CREATE_IGNORED_TICKETS_HELP'),
              value: configurations.create_closed_cases,
              type: 'boolean',
              editable: true,
              copy: false,
              longContent: false
            });
            if (sn === 'twitter' && type === 'user') {
              $scope.accountConfigurations.twitter = [];

              $scope.accountConfigurations.twitter.push({
                key: 'mark_influencer_by_followers',
                label: translate('ACCOUNT_CONFIG_MARK_INFLUENCER_BY_FOLLOWERS'),
                help: translate(
                  'ACCOUNT_CONFIG_MARK_INFLUENCER_BY_FOLLOWERS_HELP'
                ),
                value: configurations.mark_influencer_by_followers,
                type: 'boolean',
                editable: true,
                copy: false,
                longContent: false,
                childs: [
                  {
                    key: 'range_influencer_followers',
                    label: translate(
                      'ACCOUNT_CONFIG_RANGE_INFLUENCER_FOLLOWERS'
                    ),
                    value: configurations.range_influencer_followers,
                    type: 'number',
                    editable: true,
                    min: 0,
                    copy: false,
                    unit: translate(
                      'ACCOUNT_CONFIG_RANGE_INFLUENCER_FOLLOWERS_UNIT'
                    ),
                    parent: 'mark_influencer_by_followers',
                    longContent: true,
                    shouldHide: shouldHide($scope.accountConfigurations.twitter)
                  }
                ]
              });
            } else if (sn === 'email') {
              if (!configurations['smtp_port']) {
                configurations['smtp_port'] = 465;
              } else {
              }
              $scope.accountConfigurations.email = [];
              $scope.accountConfigurations.email.push({
                key: 'email_type',
                label: translate('ACCOUNT_CONFIG_EMAIL_CONNECTION_METHOD'),
                help: (
                  ($rootScope.establishment.features.imap_available || configurations['email_type'] === 'imap') 
                  ? translate('ACCOUNT_CONFIG_EMAIL_CONNECTION_METHOD_HELP')
                  : translate('ACCOUNT_CONFIG_EMAIL_FORWARDING_CONNECTION_METHOD_HELP')
                ),
                value: configurations['email_type'],
                type: $rootScope.establishment.features.imap_available ? 'select' : 'text' ,
                editable: $rootScope.establishment.features.imap_available,
                copy: false,
                longContent: false,
                styleCompactChildren: true,
                options: [
                  {
                    label: translate('GENERAL_EMAIL_FORWARDING'),
                    value: 'email'
                  },
                  {
                    label: translate('GENERAL_EMAIL_IMAP'),
                    value: 'imap'
                  }
                ],
                childs: [
                  {
                    key: 'forward_email',
                    label: translate('ACCOUNT_CONFIG_FORWARD_EMAIL'),
                    help: translate('ACCOUNT_CONFIG_FORWARD_EMAIL_HELP'),
                    value: configurations['forward_email'],
                    type: 'text',
                    editable: false,
                    copy: true,
                    longContent: false,
                    parent: 'email_type',
                    shouldHide: shouldHide(
                      $scope.accountConfigurations.email,
                      'email'
                    )
                  },
                  {
                    key: 'imap_host',
                    help: translate('GENERAL_EMAIL_IMAP_HOST'),
                    value: configurations['imap_host'],
                    type: 'text',
                    editable: true,
                    copy: false,
                    parent: 'email_type',
                    longContent: false,
                    shouldHide: shouldHide(
                      $scope.accountConfigurations.email,
                      'imap'
                    )
                  },
                  {
                    key: 'imap_port',
                    help: translate('GENERAL_EMAIL_IMAP_PORT'),
                    value: configurations['imap_port'],
                    type: 'number',
                    editable: true,
                    copy: false,
                    min: 0,
                    max: 65535,
                    parent: 'email_type',
                    longContent: false,
                    shouldHide: shouldHide(
                      $scope.accountConfigurations.email,
                      'imap'
                    )
                  },
                  {
                    key: 'imap_ssl',
                    help: translate('GENERAL_EMAIL_IMAP_SSL'),
                    value: configurations['imap_ssl'],
                    type: 'boolean',
                    editable: true,
                    copy: false,
                    parent: 'email_type',
                    longContent: false,
                    shouldHide: shouldHide(
                      $scope.accountConfigurations.email,
                      'imap'
                    )
                  },
                  {
                    key: 'imap_username',
                    help: translate('GENERAL_EMAIL_IMAP_USERNAME'),
                    value: configurations['imap_username'],
                    type: 'text',
                    editable: true,
                    copy: false,
                    parent: 'email_type',
                    longContent: false,
                    shouldHide: shouldHide(
                      $scope.accountConfigurations.email,
                      'imap'
                    )
                  },
                  {
                    key: 'imap_password',
                    help: translate('GENERAL_EMAIL_IMAP_PASSWORD'),
                    value: configurations['imap_password'],
                    type: 'password',
                    editable: true,
                    copy: false,
                    parent: 'email_type',
                    longContent: false,
                    shouldHide: shouldHide(
                      $scope.accountConfigurations.email,
                      'imap'
                    )
                  }
                ]
              });
              $scope.accountConfigurations.email.push({
                key: 'email_send_method',
                label: translate('ACCOUNT_CONFIG_EMAIL_SEND_METHOD'),
                help: translate('ACCOUNT_CONFIG_EMAIL_SEND_METHOD_HELP'),
                value: configurations['email_send_method'],
                type: 'select',
                editable: true,
                copy: false,
                longContent: false,
                styleCompactChildren: true,
                options: [
                  {
                    label: translate('GENERAL_EMAIL_FORWARDING_DEFAULT'),
                    value: 'postcenter_smtp'
                  },
                  {
                    label: translate('SMTP'),
                    value: 'smtp'
                  }
                ],
                childs: [
                  {
                    key: 'smtp_host',
                    help: translate('GENERAL_EMAIL_SMTP_HOST'),
                    value: configurations['smtp_host'],
                    type: 'text',
                    editable: true,
                    copy: false,
                    parent: 'email_send_method',
                    longContent: false,
                    shouldHide: shouldHide(
                      $scope.accountConfigurations.email,
                      'smtp'
                    )
                  },
                  {
                    key: 'smtp_port',
                    help: translate('GENERAL_EMAIL_SMTP_PORT'),
                    extra_help: translate('GENERAL_EMAIL_SMTP_PORT_EXTRA_HELP'),
                    value: configurations['smtp_port'],
                    type: 'number',
                    editable: true,
                    copy: false,
                    min: 0,
                    max: 65535,
                    parent: 'email_send_method',
                    longContent: false,
                    shouldHide: shouldHide(
                      $scope.accountConfigurations.email,
                      'smtp'
                    )
                  },
                  {
                    key: 'smtp_tls',
                    help: translate('GENERAL_EMAIL_SMTP_TLS'),
                    extra_help: translate('GENERAL_EMAIL_SMTP_TLS_EXTRA_HELP'),
                    value: configurations['smtp_tls'],
                    type: 'boolean',
                    editable: true,
                    copy: false,
                    parent: 'email_send_method',
                    longContent: false,
                    shouldHide: shouldHide(
                      $scope.accountConfigurations.email,
                      'smtp'
                    )
                  },
                  {
                    key: 'smtp_username',
                    help: translate('GENERAL_EMAIL_SMTP_USERNAME'),
                    value: configurations['smtp_username'],
                    type: 'text',
                    editable: true,
                    copy: false,
                    parent: 'email_send_method',
                    longContent: false,
                    shouldHide: shouldHide(
                      $scope.accountConfigurations.email,
                      'smtp'
                    )
                  },
                  {
                    key: 'smtp_password',
                    help: translate('GENERAL_EMAIL_SMTP_PASSWORD'),
                    extra_help: translate(
                      'GENERAL_EMAIL_SMTP_PASSWORD_EXTRA_HELP'
                    ),
                    value: configurations['smtp_password'],
                    type: 'password',
                    editable: true,
                    copy: false,
                    parent: 'email_send_method',
                    longContent: false,
                    shouldHide: shouldHide(
                      $scope.accountConfigurations.email,
                      'smtp'
                    )
                  }
                ]
              });
              $scope.accountConfigurations.email.push({
                key: 'blocked_senders',
                label: translate('ACCOUNT_CONFIG_BLOCKED_SENDERS'),
                help: translate('ACCOUNT_CONFIG_BLOCKED_SENDERS_HELP'),
                value: configurations.blocked_senders,
                type: 'textarea',
                editable: true,
                copy: false,
                longContent: true
              });
              $scope.accountConfigurations.email.push({
                key: 'signature',
                label: translate('ACCOUNT_CONFIG_EMAIL_SIGNATURE'),
                help: translate('ACCOUNT_CONFIG_EMAIL_SIGNATURE_HELP'),
                value: configurations.signature,
                type: 'html',
                editable: true,
                copy: false,
                longContent: true,
                tags: [
                  {
                    key: 'first_name',
                    label: translate('GENERAL_FIRST_NAME')
                  },
                  {
                    key: 'last_name',
                    label: translate('GENERAL_LAST_NAME')
                  },
                  {
                    key: 'email',
                    label: translate('GENERAL_EMAIL')
                  },
                  {
                    key: 'phone',
                    label: translate('GENERAL_PHONE')
                  },
                  {
                    key: 'department',
                    label: translate('GENERAL_DEPARTMENT')
                  }
                ],
                wysiwygOptions: {
                  height: 200,
                  focus: true,
                  toolbar: [
                    ['style', ['bold', 'italic', 'underline', 'clear']],
                    ['textsize', ['fontsize']],
                    ['fontclr', ['color']],
                    ['insert', ['link', 'picture', 'hr']],
                    ['view', ['codeview']]
                  ]
                }
              });
              $scope.accountConfigurations.email.push({
                key: 'get_from_email_from_text',
                label: translate('ACCOUNT_CONFIG_GET_FROM_EMAIL_FROM_TEXT'),
                help: translate('ACCOUNT_CONFIG_GET_FROM_EMAIL_FROM_TEXT_HELP'),
                value: configurations.get_from_email_from_text,
                type: 'boolean',
                editable: true,
                copy: false,
                longContent: false,
                childs: [
                  {
                    key: 'get_from_emails',
                    help: translate('ACCOUNT_CONFIG_GET_FROM_EMAILS_HELP'),
                    value: configurations.get_from_emails,
                    type: 'emails',
                    editable: true,
                    copy: false,
                    longContent: true,
                    placeholder: '  ',
                    maxlength: 20,
                    parent: 'get_from_email_from_text',
                    shouldHide: shouldHide($scope.accountConfigurations.email)
                  }
                ]
              });
              $scope.accountConfigurations.email.push({
                key: 'include_previous_message',
                label: translate('ACCOUNT_CONFIG_INCLUDE_PREVIOUS_MESSAGE'),
                help: translate('ACCOUNT_CONFIG_INCLUDE_PREVIOUS_MESSAGE_HELP'),
                value: configurations.include_previous_message,
                type: 'boolean',
                editable: true,
                copy: false,
                longContent: false
              });
            } else if (sn === 'whatsapp') {
              $scope.accountConfigurations.whatsapp = [];
              if (configurations.is_official_wsp) {
                const values = {
                  brandName: {
                    label: translate(
                      'ACCOUNT_CONFIG_WHATSAPP_SNIPPET_BRAND_NAME'
                    ),
                    type: 'text',
                    value: $scope.socialAccount.name,
                    editable: true,
                    maxlength: 34
                  },
                  brandSubtitle: {
                    label: translate(
                      'ACCOUNT_CONFIG_WHATSAPP_SNIPPET_BRAND_SUBTITLE'
                    ),
                    type: 'text',
                    value: translate(
                      'ACCOUNT_CONFIG_WHATSAPP_SNIPPET_BRAND_SUBTITLE_DEFAULT'
                    ),
                    editable: true,
                    maxlength: 46
                  },
                  welcomeText: {
                    label: translate(
                      'ACCOUNT_CONFIG_WHATSAPP_SNIPPET_WELCOME_TEXT'
                    ),
                    type: 'text',
                    value: translate(
                      'ACCOUNT_CONFIG_WHATSAPP_SNIPPET_WELCOME_TEXT_DEFAULT'
                    ),
                    editable: true,
                    maxlength: 74
                  },
                  messageText: {
                    label: translate(
                      'ACCOUNT_CONFIG_WHATSAPP_SNIPPET_MESSAGE_TEXT'
                    ),
                    type: 'text',
                    value:
                      translate(
                        'ACCOUNT_CONFIG_WHATSAPP_SNIPPET_MESSAGE_TEXT_DEFAULT'
                      ) + $scope.socialAccount.name,
                    editable: true
                  },
                  talkButtontext: {
                    label: translate(
                      'ACCOUNT_CONFIG_WHATSAPP_SNIPPET_BUTTON_TEXT'
                    ),
                    type: 'text',
                    value: translate(
                      'ACCOUNT_CONFIG_WHATSAPP_SNIPPET_BUTTON_TEXT_DEFAULT'
                    ),
                    editable: true,
                    maxlength: 34
                  },
                  brandImg: {
                    label: translate(
                      'ACCOUNT_CONFIG_WHATSAPP_SNIPPET_BRAND_IMAGE'
                    ),
                    type: 'text',
                    value: $scope.socialAccount.avatar,
                    editable: true
                  },
                  buttonColor: {
                    label: translate(
                      'ACCOUNT_CONFIG_WHATSAPP_SNIPPET_BUTTON_COLOR'
                    ),
                    type: 'color',
                    value: '#4dc247',
                    editable: true,
                    copy: false,
                    key: 'whatsapp_button_color',
                    placeholder: '',
                    maxlength: 40
                  },
                  brandColor: {
                    label: translate(
                      'ACCOUNT_CONFIG_WHATSAPP_SNIPPET_BRAND_COLOR'
                    ),
                    type: 'color',
                    value: '#0a5f54',
                    editable: true,
                    copy: false,
                    key: 'whatsapp_button_color',
                    placeholder: '',
                    maxlength: 40
                  },
                  position: {
                    label: translate(
                      'ACCOUNT_CONFIG_WHATSAPP_SNIPPET_POSITION'
                    ),
                    type: 'select',
                    value: 'right',
                    options: [
                      {
                        label: translate('GENERAL_RIGHT'),
                        value: 'right'
                      },
                      {
                        label: translate('GENERAL_LEFT'),
                        value: 'left'
                      }
                    ],
                    editable: true,
                    copy: false,
                    key: 'whatsapp_button_color',
                    placeholder: '',
                    maxlength: 40
                  }
                };

                const generateWhatsappSnippetSettings = function (option) {
                  return {
                    enabled: true,
                    chatButtonSetting: {
                      backgroundColor: option.values.buttonColor.value,
                      ctaText: '',
                      borderRadius: '25',
                      marginLeft: '50',
                      marginBottom: '50',
                      marginRight: '50',
                      position: option.values.position.value
                    },
                    brandSetting: {
                      brandName: option.values.brandName.value,
                      brandSubTitle: option.values.brandSubtitle.value,
                      brandImg: option.values.brandImg.value,
                      welcomeText: option.values.welcomeText.value,
                      messageText: encodeURI(option.values.messageText.value),
                      backgroundColor: option.values.brandColor.value,
                      ctaText: option.values.talkButtontext.value,
                      borderRadius: '25',
                      autoShow: false,
                      phoneNumber: $scope.socialAccount.username
                    }
                  };
                };

                const generateWhatsappSnippetJavascript = function (
                  snippetSettings
                ) {
                  return `
    var url = 'https://whatsapp-widget.adere.so/widget.min.js';
    var s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.src = url;
    var options = ${JSON.stringify(snippetSettings, null, '\t')};
    s.onload = function() {
        CreateWhatsappChatWidget(options);
    };
    var x = document.getElementsByTagName('script')[0];
    x.parentNode.insertBefore(s, x);`;
                };

                const generateWhatsappSnippet = function (snippetScript) {
                  var snippet = `<script>${snippetScript}\n</script>`;
                  return snippet;
                };

                const generateSnippet = function (option) {
                  var snippetSettings = generateWhatsappSnippetSettings(option);
                  var snippetScript =
                    generateWhatsappSnippetJavascript(snippetSettings);
                  option.snippetScript = snippetScript;
                  option.snippet = generateWhatsappSnippet(snippetScript);
                };

                var whatsappSnippetConfig = {
                  key: 'click_to_wsp',
                  label: translate('ACCOUNT_CONFIG_WHATSAPP_SNIPPET'),
                  help: translate('ACCOUNT_CONFIG_WHATSAPP_SNIPPET_HELP'),
                  values: values,
                  type: 'snippet',
                  editable: false,
                  copy: true,
                  longContent: true,
                  callback: generateSnippet
                };

                var snippetSettings = generateWhatsappSnippetSettings(
                  whatsappSnippetConfig
                );
                var snippetScript =
                  generateWhatsappSnippetJavascript(snippetSettings);
                whatsappSnippetConfig.snippetScript = snippetScript;
                whatsappSnippetConfig.snippet =
                  generateWhatsappSnippet(snippetScript);

                $scope.accountConfigurations.whatsapp.push(
                  whatsappSnippetConfig
                );

                $scope.accountConfigurations.whatsapp.push({
                  key: 'prepend_cm_name',
                  label: translate('ACCOUNT_CONFIG_WHATSAPP_PREPEND_CM_NAME'),
                  help: translate(
                    'ACCOUNT_CONFIG_WHATSAPP_PREPEND_CM_NAME_HELP'
                  ),
                  value: configurations.prepend_cm_name,
                  type: 'boolean',
                  editable: true,
                  copy: false,
                  longContent: false
                });

                $scope.accountConfigurations.whatsapp.push({
                  key: 'blocked_senders',
                  label: translate('ACCOUNT_CONFIG_BLOCKED_SENDERS_WHATSAPP'),
                  help: translate(
                    'ACCOUNT_CONFIG_BLOCKED_SENDERS_WHATSAPP_HELP'
                  ),
                  value: configurations.blocked_senders,
                  type: 'textarea',
                  editable: true,
                  copy: false,
                  longContent: true
                });
              } else {
                $scope.accountConfigurations.whatsapp.push({
                  key: 'proactive',
                  label: translate('ACCOUNT_CONFIG_WHATSAPP_PROACTIVE'),
                  help: translate('ACCOUNT_CONFIG_WHATSAPP_PROACTIVE_HELP'),
                  value: configurations.proactive,
                  type: 'boolean',
                  editable: true,
                  copy: false,
                  longContent: false
                });

                let maximumProactiveTickets =
                  AdGlobalInformation.getMaxProactiveMessagesPerMonth();
                const progressValue =
                  (configurations.proactive_month_sent * 100) /
                  maximumProactiveTickets;

                $scope.accountConfigurations.whatsapp.push({
                  key: 'proactive_current_use',
                  label: translate(
                    'ACCOUNT_CONFIG_WHATSAPP_PROACTIVE_CURRENT_USE'
                  ),
                  help: translate(
                    'ACCOUNT_CONFIG_WHATSAPP_PROACTIVE_CURRENT_USE_HELP'
                  ),
                  progressLabel: translate(
                    'ACCOUNT_CONFIG_WHATSAPP_PROACTIVE_CURRENT_USE_LABEL',
                    {
                      current: configurations.proactive_month_sent,
                      max: maximumProactiveTickets
                    }
                  ),
                  progressValue: progressValue,
                  type: 'progressBar',
                  editable: false,
                  copy: true,
                  longContent: false
                });

                $scope.accountConfigurations.whatsapp.push({
                  key: 'email_notifications',
                  label: translate(
                    'ACCOUNT_CONFIG_WHATSAPP_EMAIL_NOTIFICATIONS'
                  ),
                  help: translate(
                    'ACCOUNT_CONFIG_WHATSAPP_EMAIL_NOTIFICATIONS_HELP'
                  ),
                  value: configurations.email_notifications,
                  type: 'boolean',
                  editable: true,
                  copy: false,
                  longContent: false,
                  childs: [
                    {
                      key: 'email_to_notify',
                      help: translate(
                        'ACCOUNT_CONFIG_CASE_NOTIFICATION_EMAILS_HELP'
                      ),
                      value: configurations.email_to_notify,
                      type: 'emails',
                      editable: true,
                      copy: false,
                      longContent: true,
                      placeholder: translate(
                        'ACCOUNT_CONFIG_CASE_NOTIFICATION_EMAILS_PLACEHOLDER'
                      ),
                      maxlength: 20,
                      parent: 'email_notifications',
                      shouldHide: shouldHide(
                        $scope.accountConfigurations.whatsapp
                      )
                    }
                  ]
                });

                $scope.accountConfigurations.whatsapp.push({
                  key: 'phone_brand',
                  label: translate('ACCOUNT_CONFIG_WHATSAPP_PHONE_BRAND'),
                  help: translate('ACCOUNT_CONFIG_WHATSAPP_PHONE_BRAND_HELP'),
                  value: configurations.phone_brand,
                  type: 'text',
                  editable: true,
                  copy: false,
                  longContent: false,
                  maxlength: 45
                });

                $scope.accountConfigurations.whatsapp.push({
                  key: 'phone_model',
                  label: translate('ACCOUNT_CONFIG_WHATSAPP_PHONE_MODEL'),
                  help: translate('ACCOUNT_CONFIG_WHATSAPP_PHONE_MODEL_HELP'),
                  value: configurations.phone_model,
                  type: 'text',
                  editable: true,
                  copy: false,
                  longContent: false,
                  maxlength: 45
                });

                const androidVersionsParsed =
                  AdGlobalInformation.androidVersions().map(function (version) {
                    return {
                      label: version.name,
                      value: version.value
                    };
                  });
                $scope.accountConfigurations.whatsapp.push({
                  key: 'phone_android',
                  label: translate('ACCOUNT_CONFIG_WHATSAPP_PHONE_ANDROID'),
                  help: translate('ACCOUNT_CONFIG_WHATSAPP_PHONE_ANDROID_HELP'),
                  value: configurations.phone_android,
                  type: 'select',
                  editable: true,
                  copy: false,
                  longContent: false,
                  options: androidVersionsParsed
                });
              }
            } else if (sn === 'chat') {
              $scope.accountConfigurations.chat = [];
              $scope.accountConfigurations.chat.push({
                key: 'title',
                label: translate('ACCOUNT_CONFIG_CHAT_NAME'),
                help: translate('ACCOUNT_CONFIG_CHAT_NAME_HELP'),
                value: configurations.title,
                type: 'text',
                editable: true,
                copy: false,
                longContent: false,
                maxlength: 45
              });

              $scope.accountConfigurations.chat.push({
                key: 'hidden',
                label: translate('ACCOUNT_CONFIG_CHAT_HIDDEN'),
                help: translate('ACCOUNT_CONFIG_CHAT_HIDDEN_HELP'),
                value: configurations.hidden,
                type: 'boolean',
                editable: true,
                copy: false,
                longContent: false
              });

              const chatSnippet =
                '<script type="text/javascript">\n' +
                '(function() {\n' +
                '  var tc = document.createElement("script"); tc.type = "text/javascript"; tc.async = true;\n' +
                '  tc.src = "https://app.talkcenter.io/v2/talkcenter.min.js";\n' +
                '  var s = document.getElementsByTagName("script")[0]; s.parentNode.insertBefore(tc, s);\n' +
                '  })();\n' +
                '  window.TalkCenter = {token: "' +
                $scope.socialAccount.uid +
                '"};\n' +
                '</script>\n';

              $scope.accountConfigurations.chat.push({
                key: 'chat_snippet',
                label: translate('ACCOUNT_CONFIG_CHAT_SNIPPET'),
                help: translate('ACCOUNT_CONFIG_CHAT_SNIPPET_HELP'),
                value: chatSnippet,
                type: 'textarea',
                editable: false,
                copy: true,
                longContent: false
              });
              $scope.snippets.chat = [];
              $scope.snippets.chat.push({
                value: chatSnippet,
                help: translate('ACCOUNT_CONFIG_CHAT_SNIPPET_HELP_SHORT')
              });

              $scope.accountConfigurations.chat.push({
                key: 'chat_token',
                label: translate('ACCOUNT_CONFIG_CHAT_TOKEN'),
                help: translate('ACCOUNT_CONFIG_CHAT_TOKEN_HELP'),
                value: $scope.socialAccount.uid,
                type: 'text',
                editable: false,
                copy: true,
                longContent: false
              });

              $scope.accountConfigurations.chat.push({
                key: 'domain',
                label: translate('ACCOUNT_CONFIG_CHAT_DOMAIN'),
                help: translate('ACCOUNT_CONFIG_CHAT_DOMAIN_HELP'),
                value: configurations.domain,
                type: 'text',
                editable: false,
                copy: false,
                longContent: false
              });

              const childs = [
                {
                  key: 'contact_form_fallback_email',
                  help: translate(
                    'ACCOUNT_CONFIG_CONTACT_FORM_FALLBACK_SIMPLE_EMAIL_HELP'
                  ),
                  value: configurations.contact_form_fallback_email,
                  type: 'boolean',
                  editable: true,
                  copy: false,
                  longContent: false,
                  parent: 'contact_form_fallback',
                  shouldHide: shouldHide($scope.accountConfigurations.chat),
                  childs: []
                },
                {
                  key: 'contact_form_fallback_account',
                  help: translate(
                    'ACCOUNT_CONFIG_CONTACT_FORM_FALLBACK_ACCOUNT_EMAIL_HELP'
                  ),
                  value: configurations.contact_form_fallback_account,
                  type: 'boolean',
                  editable: true,
                  copy: false,
                  longContent: false,
                  parent: 'contact_form_fallback',
                  shouldHide: shouldHide($scope.accountConfigurations.chat),
                  childs: []
                }
              ];

              childs[0].onClick = function (option) {
                if (option.value === true) {
                  childs[1].value = false;
                }
              };

              childs[1].onClick = function (option) {
                if (option.value === true) {
                  childs[0].value = false;
                }
              };

              childs[0].childs = [
                {
                  key: 'contact_form_email',
                  help: translate('ACCOUNT_CONFIG_CONTACT_FORM_EMAIL_HELP'),
                  value: configurations.contact_form_email,
                  type: 'text',
                  placeholder: translate(
                    'ACCOUNT_CONFIG_FORM_EMAIL_PLACEHOLDER'
                  ),
                  editable: true,
                  longContent: true,
                  copy: false,
                  parent: 'contact_form_fallback_email',
                  shouldHide: shouldHide(childs)
                }
              ];

              const emailAccounts = _.filter(
                $rootScope.accounts,
                function (account) {
                  return account.sn === 'email';
                }
              );

              options = _.map(emailAccounts, function (account) {
                const label = account.name + ' (' + account.uid + ')';
                return {
                  label: label,
                  value: account.id
                };
              });

              childs[1].childs = [
                {
                  key: 'contact_form_account',
                  help: translate(
                    'ACCOUNT_CONFIG_CONTACT_FORM_FALLBACK_ACCOUNT_HELP'
                  ),
                  value: configurations.contact_form_account,
                  type: 'select',
                  editable: true,
                  longContent: true,
                  copy: false,
                  parent: 'contact_form_fallback_account',
                  options: options,
                  shouldHide: shouldHide(childs)
                }
              ];

              $scope.accountConfigurations.chat.push({
                key: 'contact_form_fallback',
                label: translate('ACCOUNT_CONFIG_CONTACT_FORM_FALLBACK'),
                help: translate('ACCOUNT_CONFIG_CONTACT_FORM_FALLBACK_HELP'),
                value: configurations.contact_form_fallback,
                type: 'boolean',
                editable: true,
                copy: false,
                longContent: false,
                childs: childs
              });

              $scope.accountConfigurations.chat.push({
                key: 'sound_notifications',
                label: translate('ACCOUNT_CONFIG_CHAT_SOUND_NOTIFICATIONS'),
                help: translate('ACCOUNT_CONFIG_CHAT_SOUND_NOTIFICATIONS_HELP'),
                value: configurations.sound_notifications,
                type: 'boolean',
                editable: true,
                copy: false,
                longContent: false
              });

              $scope.accountConfigurations.chat.push({
                key: 'color',
                label: translate('ACCOUNT_CONFIG_CHAT_COLOR'),
                help: translate('ACCOUNT_CONFIG_CHAT_COLOR_HELP'),
                value: configurations.color,
                type: 'color',
                editable: true,
                copy: false,
                longContent: false
              });

              $scope.accountConfigurations.chat.push({
                key: 'text_color',
                label: translate('ACCOUNT_CONFIG_CHAT_TEXT_COLOR'),
                help: translate('ACCOUNT_CONFIG_CHAT_TEXT_COLOR_HELP'),
                value: configurations.text_color,
                type: 'color',
                editable: true,
                copy: false,
                longContent: false
              });

              $scope.accountConfigurations.chat.push({
                key: 'logo',
                label: translate('ACCOUNT_CONFIG_CHAT_LOGO'),
                help: translate('ACCOUNT_CONFIG_CHAT_LOGO_HELP'),
                value: configurations.logo,
                type: 'image-upload',
                editable: true,
                copy: false,
                longContent: false
              });

              $scope.accountConfigurations.chat.push({
                key: 'chat_side',
                label: translate('ACCOUNT_CONFIG_CHAT_LEFT_SIDE'),
                help: translate('ACCOUNT_CONFIG_CHAT_LEFT_SIDE_HELP'),
                value: configurations.chat_side,
                type: 'boolean',
                editable: true,
                copy: false,
                longContent: false
              });

              $scope.accountConfigurations.chat.push({
                key: 'chat_button_large',
                label: translate('ACCOUNT_CONFIG_CHAT_LARGE_BUTTON'),
                help: translate('ACCOUNT_CONFIG_CHAT_LARGE_BUTTON_HELP'),
                value: configurations.chat_button_large,
                type: 'boolean',
                editable: true,
                copy: false,
                longContent: false,
                childs: [
                  {
                    key: 'chat_button_large_content',
                    help: translate(
                      'ACCOUNT_CONFIG_CHAT_LARGE_BUTTON_CONTENT_HELP'
                    ),
                    value: configurations.chat_button_large_content,
                    type: 'text',
                    editable: true,
                    copy: false,
                    longContent: false,
                    maxlength: 20,
                    parent: 'chat_button_large',
                    shouldHide: shouldHide($scope.accountConfigurations.chat)
                  }
                ]
              });

              $scope.accountConfigurations.chat.push({
                key: 'account_author',
                label: translate('ACCOUNT_CONFIG_CHAT_ACCOUNT_AUTHOR'),
                help: translate('ACCOUNT_CONFIG_CHAT_ACCOUNT_AUTHOR_HELP'),
                value: configurations.account_author,
                type: 'boolean',
                editable: true,
                copy: false,
                longContent: false
              });

              $scope.accountConfigurations.chat.push({
                key: 'fb_login',
                label: translate('ACCOUNT_CONFIG_CHAT_FB_LOGIN'),
                help: translate('ACCOUNT_CONFIG_CHAT_FB_LOGIN_HELP'),
                value: configurations.fb_login,
                type: 'boolean',
                editable: true,
                copy: false,
                longContent: false,
                childs: [
                  {
                    key: 'only_social_login',
                    help: translate(
                      'ACCOUNT_CONFIG_CHAT_ONLY_SOCIAL_LOGIN_HELP'
                    ),
                    value: configurations.only_social_login,
                    type: 'boolean',
                    editable: true,
                    copy: false,
                    longContent: false,
                    parent: 'fb_login',
                    shouldHide: shouldHide($scope.accountConfigurations.chat)
                  }
                ]
              });

              $scope.accountConfigurations.chat.push({
                key: 'google_login',
                label: translate('ACCOUNT_CONFIG_CHAT_GOOGLE_LOGIN'),
                help: translate('ACCOUNT_CONFIG_CHAT_GOOGLE_LOGIN_HELP'),
                value: configurations.google_login,
                type: 'boolean',
                editable: true,
                copy: false,
                longContent: false,
                childs: [
                  {
                    key: 'only_social_login',
                    help: translate(
                      'ACCOUNT_CONFIG_CHAT_ONLY_SOCIAL_LOGIN_HELP'
                    ),
                    value: configurations.only_social_login,
                    type: 'boolean',
                    editable: true,
                    copy: false,
                    longContent: false,
                    parent: 'google_login',
                    shouldHide: shouldHide($scope.accountConfigurations.chat)
                  }
                ]
              });

              $scope.accountConfigurations.chat.push({
                key: 'anonymous_user',
                label: translate('ACCOUNT_CONFIG_CHAT_ANONYMOUS_USER'),
                help: translate('ACCOUNT_CONFIG_CHAT_ANONYMOUS_USER_HELP'),
                value: configurations.anonymous_user,
                type: 'boolean',
                editable: true,
                copy: false,
                longContent: false
              });

              $scope.accountConfigurations.chat.push({
                key: 'email_as_id',
                label: translate('ACCOUNT_CONFIG_CHAT_EMAIL_AS_ID'),
                help: translate('ACCOUNT_CONFIG_CHAT_EMAIL_AS_ID_HELP'),
                value: configurations.email_as_id,
                type: 'boolean',
                editable: true,
                copy: false,
                longContent: false
              });

              $scope.accountConfigurations.chat.push({
                key: 'request_country_id',
                label:
                  translate('ACCOUNT_CONFIG_CHAT_REQUEST_COUNTRY_ID') +
                  $rootScope.establishment.country.resident_id,
                help:
                  translate('ACCOUNT_CONFIG_CHAT_REQUEST_COUNTRY_ID_HELP') +
                  $rootScope.establishment.country.resident_id,
                value: configurations.request_country_id,
                type: 'boolean',
                editable: true,
                copy: false,
                longContent: false,
                childs: [
                  {
                    key: 'validate_rut',
                    help: translate('ACCOUNT_CONFIG_CHAT_REQUEST_VALIDATE_RUT'),
                    value: configurations.validate_rut,
                    type: 'boolean',
                    editable: true,
                    copy: false,
                    longContent: false,
                    parent: 'request_country_id',
                    shouldHide: shouldHide($scope.accountConfigurations.chat)
                  }
                ]
              });

              $scope.accountConfigurations.chat.push({
                key: 'request_phone',
                label: translate('ACCOUNT_CONFIG_CHAT_REQUEST_PHONE'),
                help: translate('ACCOUNT_CONFIG_CHAT_REQUEST_PHONE_HELP'),
                value: configurations.request_phone,
                type: 'boolean',
                editable: true,
                copy: false,
                longContent: false,
                category: 'chat'
              });

              $scope.accountConfigurations.chat.push({
                key: 'open_onload',
                label: translate('ACCOUNT_CONFIG_CHAT_OPEN_ON_LOAD'),
                help: translate('ACCOUNT_CONFIG_CHAT_OPEN_ON_LOAD_HELP'),
                value: configurations.open_onload,
                type: 'boolean',
                editable: true,
                copy: false,
                longContent: false,
                childs: [
                  {
                    key: 'open_onload_delay',
                    help: translate('ACCOUNT_CONFIG_CHAT_OPEN_ON_LOAD_DELAY'),
                    value: configurations.open_onload_delay,
                    type: 'number',
                    editable: true,
                    longContent: true,
                    copy: false,
                    min: 0,
                    parent: 'open_onload',
                    shouldHide: shouldHide($scope.accountConfigurations.chat),
                    unit: translate('GENERAL_SECONDS')
                  },
                  {
                    key: 'open_onload_device',
                    help: translate('ACCOUNT_CONFIG_CHAT_OPEN_ON_LOAD_DEVICE'),
                    value: configurations.open_onload_device,
                    type: 'select',
                    editable: true,
                    longContent: true,
                    copy: false,
                    parent: 'open_onload',
                    shouldHide: shouldHide($scope.accountConfigurations.chat),
                    options: [
                      {
                        label: translate('GENERAL_DEVICES_ANY'),
                        value: 'any'
                      },
                      {
                        label: translate('GENERAL_DEVICES_MOBILE'),
                        value: 'mobile'
                      },
                      {
                        label: translate('GENERAL_DEVICES_DESKTOP'),
                        value: 'desktop'
                      }
                    ]
                  }
                ]
              });

              $scope.accountConfigurations.chat.push({
                key: 'locale',
                label: translate('ACCOUNT_CONFIG_CHAT_LANGUAGE_CHANGE'),
                help: translate('ACCOUNT_CONFIG_CHAT_LANGUAGE_CHANGE_HELP'),
                value: configurations.locale || 'es',
                type: 'select',
                editable: true,
                longContent: false,
                copy: false,
                shouldHide: shouldHide($scope.accountConfigurations.chat),
                options: [
                  {
                    label: translate('ACCOUNT_CONFIG_CHAT_LANGUAGE_ES'),
                    value: 'es'
                  },
                  {
                    label: translate('ACCOUNT_CONFIG_CHAT_LANGUAGE_EN'),
                    value: 'en'
                  },
                  {
                    label: translate('ACCOUNT_CONFIG_CHAT_LANGUAGE_PT'),
                    value: 'pt'
                  }
                ]
              });

              $scope.accountConfigurations.chat.push({
                key: 'disclaimer_login',
                label: translate('ACCOUNT_CONFIG_CHAT_DISCLAIMER_LOGIN'),
                help: translate('ACCOUNT_CONFIG_CHAT_DISCLAIMER_HELP'),
                value: configurations.disclaimer_login,
                type: 'boolean',
                editable: true,
                copy: false,
                longContent: false,
                childs: [
                  {
                    key: 'disclaimer_login_content',
                    help: translate('ACCOUNT_CONFIG_CHAT_DISCLAIMER_CONTENT'),
                    value: configurations.disclaimer_login_content,
                    type: 'textarea',
                    editable: true,
                    longContent: true,
                    copy: false,
                    parent: 'disclaimer_login',
                    shouldHide: shouldHide($scope.accountConfigurations.chat)
                  }
                ]
              });

              $scope.accountConfigurations.chat.push({
                key: 'mini_chat_can_reply',
                label: translate('ACCOUNT_CONFIG_CHAT_MINI_CHAT'),
                help: translate('ACCOUNT_CONFIG_CHAT_MINI_CHAT_HELP'),
                value: configurations.mini_chat_can_reply,
                type: 'boolean',
                editable: true,
                copy: false,
                longContent: false,
                category: 'chat'
              });

              $scope.accountConfigurations.chat.push({
                key: 'mini_chat_max_messages',
                label: translate('ACCOUNT_CONFIG_MINI_CHAT_MAX_MESSAGES'),
                help: translate('ACCOUNT_CONFIG_MINI_CHAT_MAX_MESSAGES_HELP'),
                value: configurations.mini_chat_max_messages,
                type: 'number',
                editable: true,
                longContent: true,
                copy: false,
                min: 1,
                max: 3,
                category: 'chat',
                unit: translate('GENERAL_MESSAGES')
              });

              $scope.accountConfigurations.chat.push({
                key: 'welcome_message_show',
                label: translate('ACCOUNT_CONFIG_CHAT_WELCOME_MESSAGE'),
                help: translate('ACCOUNT_CONFIG_CHAT_WELCOME_MESSAGE_HELP'),
                value: configurations.welcome_message_show,
                type: 'boolean',
                editable: true,
                copy: false,
                longContent: false,
                category: 'chat',
                childs: [
                  {
                    key: 'welcome_message_delay',
                    help: translate(
                      'ACCOUNT_CONFIG_CHAT_WELCOME_MESSAGE_DELAY'
                    ),
                    value: configurations.welcome_message_delay,
                    type: 'number',
                    editable: true,
                    longContent: true,
                    copy: false,
                    min: 0,
                    parent: 'welcome_message_show',
                    shouldHide: shouldHide($scope.accountConfigurations.chat),
                    unit: translate('GENERAL_SECONDS')
                  },
                  {
                    key: 'welcome_message_frequency',
                    help: translate(
                      'ACCOUNT_CONFIG_CHAT_WELCOME_MESSAGE_FREQUENCY'
                    ),
                    value: configurations.welcome_message_frequency,
                    type: 'number',
                    editable: true,
                    longContent: true,
                    copy: false,
                    min: 0,
                    parent: 'welcome_message_show',
                    shouldHide: shouldHide($scope.accountConfigurations.chat),
                    unit: translate('GENERAL_MINUTES')
                  },
                  {
                    key: 'welcome_message_persistent',
                    help: translate(
                      'ACCOUNT_CONFIG_CHAT_WELCOME_MESSAGE_PERSISTENT'
                    ),
                    value: configurations.welcome_message_persistent,
                    type: 'boolean',
                    editable: true,
                    longContent: false,
                    copy: false,
                    parent: 'welcome_message_show',
                    shouldHide: shouldHide($scope.accountConfigurations.chat)
                  },
                  {
                    key: 'welcome_message_device',
                    help: translate(
                      'ACCOUNT_CONFIG_CHAT_WELCOME_MESSAGE_DEVICE'
                    ),
                    value: configurations.welcome_message_device,
                    type: 'select',
                    editable: true,
                    longContent: true,
                    copy: false,
                    parent: 'welcome_message_show',
                    shouldHide: shouldHide($scope.accountConfigurations.chat),
                    options: [
                      {
                        label: translate('GENERAL_DEVICES_ANY'),
                        value: 'any'
                      },
                      {
                        label: translate('GENERAL_DEVICES_MOBILE'),
                        value: 'mobile'
                      },
                      {
                        label: translate('GENERAL_DEVICES_DESKTOP'),
                        value: 'desktop'
                      }
                    ]
                  },
                  {
                    key: 'welcome_message_content',
                    help: translate(
                      'ACCOUNT_CONFIG_CHAT_WELCOME_MESSAGE_CONTENT'
                    ),
                    value: configurations.welcome_message_content,
                    type: 'text',
                    editable: true,
                    longContent: true,
                    copy: false,
                    parent: 'welcome_message_show',
                    shouldHide: shouldHide($scope.accountConfigurations.chat)
                  },
                  {
                    key: 'welcome_message_author',
                    help: translate(
                      'ACCOUNT_CONFIG_CHAT_WELCOME_MESSAGE_AUTHOR'
                    ),
                    value: configurations.welcome_message_author,
                    type: 'text',
                    editable: true,
                    longContent: true,
                    copy: false,
                    parent: 'welcome_message_show',
                    shouldHide: shouldHide($scope.accountConfigurations.chat)
                  }
                ]
              });

              $scope.accountConfigurations.chat.push({
                key: 'login_message',
                label: translate('ACCOUNT_CONFIG_CHAT_LOGIN_MESSAGE'),
                help: translate('ACCOUNT_CONFIG_CHAT_LOGIN_MESSAGE_HELP'),
                placeholder: translate(
                  'ACCOUNT_CONFIG_CHAT_LOGIN_MESSAGE_PLACEHOLDER'
                ),
                value: configurations.login_message,
                type: 'text',
                editable: true,
                copy: false,
                longContent: true
              });

              $scope.accountConfigurations.chat.push({
                key: 'conversation_header_message',
                label: translate(
                  'ACCOUNT_CONFIG_CHAT_CONVERSATION_HEADER_MESSAGE'
                ),
                help: translate(
                  'ACCOUNT_CONFIG_CHAT_CONVERSATION_HEADER_MESSAGE_HELP'
                ),
                placeholder: translate(
                  'ACCOUNT_CONFIG_CHAT_CONVERSATION_HEADER_MESSAGE_PLACEHOLDER'
                ),
                value: configurations.conversation_header_message,
                type: 'textarea',
                editable: true,
                copy: false,
                longContent: true
              });

              $scope.accountConfigurations.chat.push({
                key: 'contact_header_message',
                label: translate('ACCOUNT_CONFIG_CHAT_CONTACT_HEADER_MESSAGE'),
                help: translate(
                  'ACCOUNT_CONFIG_CHAT_CONTACT_HEADER_MESSAGE_HELP'
                ),
                placeholder: translate(
                  'ACCOUNT_CONFIG_CHAT_CONTACT_HEADER_MESSAGE_PLACEHOLDER'
                ),
                value: configurations.contact_header_message,
                type: 'text',
                editable: true,
                copy: false,
                longContent: true
              });

              $scope.accountConfigurations.chat.push({
                key: 'contact_header_message_extra',
                label: translate(
                  'ACCOUNT_CONFIG_CHAT_CONTACT_HEADER_EXTRA_MESSAGE'
                ),
                help: translate(
                  'ACCOUNT_CONFIG_CHAT_CONTACT_HEADER_EXTRA_MESSAGE_HELP'
                ),
                placeholder: translate(
                  'ACCOUNT_CONFIG_CHAT_CONTACT_HEADER_EXTRA_MESSAGE_PLACEHOLDER'
                ),
                value: configurations.contact_header_message_extra,
                type: 'text',
                editable: true,
                copy: false,
                longContent: true
              });

              $scope.accountConfigurations.chat.push({
                key: 'contact_success_message',
                label: translate('ACCOUNT_CONFIG_CHAT_CONTACT_SUCCESS_MESSAGE'),
                help: translate(
                  'ACCOUNT_CONFIG_CHAT_CONTACT_SUCCESS_MESSAGE_HELP'
                ),
                placeholder: translate(
                  'ACCOUNT_CONFIG_CHAT_CONTACT_SUCCESS_MESSAGE_PLACEHOLDER'
                ),
                value: configurations.contact_success_message,
                type: 'text',
                editable: true,
                copy: false,
                longContent: true,
                category: 'chat'
              });

              $scope.accountConfigurations.chat.push({
                key: 'contact_error_message',
                label: translate('ACCOUNT_CONFIG_CHAT_CONTACT_ERROR_MESSAGE'),
                help: translate(
                  'ACCOUNT_CONFIG_CHAT_CONTACT_ERROR_MESSAGE_HELP'
                ),
                placeholder: translate(
                  'ACCOUNT_CONFIG_CHAT_CONTACT_ERROR_MESSAGE_PLACEHOLDER'
                ),
                value: configurations.contact_error_message,
                type: 'text',
                editable: true,
                copy: false,
                longContent: true,
                category: 'chat'
              });
            } else if (sn === 'app_store') {
              options = [];
              SocialAccount.getCountryCodes().then(function (data) {
                const countries = data.data.countries;
                angular.forEach(countries, function (country) {
                  options.push({
                    label: country.name,
                    value: country.code
                  });
                });
              });

              $scope.accountConfigurations.app_store = [];
              $scope.accountConfigurations.app_store.push({
                key: 'country_code',
                label: translate('ACCOUNT_CONFIG_APP_COUNTRY_CODE'),
                help: translate('ACCOUNT_CONFIG_APP_COUNTRY_CODE_HELP'),
                value: configurations.country_code,
                type: 'select',
                editable: true,
                copy: false,
                longContent: false,
                options: options
              });
            } else if (sn === 'twitter' && type === 'search') {
              $scope.accountConfigurations.twitter_search = [];
              $scope.accountConfigurations.twitter_search.push({
                key: 'enable_ticket_creation',
                label: translate('ACCOUNT_CONFIG_CREATE_CASES'),
                help: translate('ACCOUNT_CONFIG_CREATE_CASES_HELP'),
                value: configurations.enable_ticket_creation,
                type: 'boolean',
                editable: true,
                copy: false,
                longContent: false
              });

              $scope.accountConfigurations.twitter_search.push({
                key: 'keyword',
                label: translate('ACCOUNT_CONFIG_KEYWORDS'),
                help: translate('ACCOUNT_CONFIG_KEYWORDS_HELP'),
                placeholder: translate('ACCOUNT_CONFIG_KEYWORDS_PLACEHOLDER'),
                value: configurations.keyword,
                type: 'text',
                editable: true,
                copy: false,
                longContent: false
              });

              options = SocialAccount.getSocialAccountsOptions(
                'twitter',
                'user'
              );

              $scope.accountConfigurations.twitter_search.push({
                key: 'twitter_user',
                label: translate('ACCOUNT_CONFIG_TWITTER_USER_ID'),
                help: translate('ACCOUNT_CONFIG_TWITTER_USER_ID_HELP'),
                value: configurations.twitter_user,
                type: 'select',
                editable: true,
                copy: false,
                longContent: false,
                options: options
              });
            }
          }
          processAccountConfigurations();
        }
      ]
    };
  });
