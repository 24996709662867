'use strict';

type SideViewMenuOptions =
  | 'userMetadata'
  | 'caseMetadata'
  | 'userOrders'
  | 'omnichannel'
  | 'comments'
  | 'integration'
  | 'escalation'
  | 'botcenter'
  | 'old_case'
  | 'assign_history';

class CaseDetailManager {
  private selectedCase: {idOrIdentifier: string | number; elem: Element};

  $rootScope;
  AppStateService: AppStateService;
  CaseService: CaseService;
  ColumnsService: ColumnsService;
  UserService: UserService;

  sideViewMenuOption: string;
  isModalOpen = false;
  isUserPickerModalOpen = false;
  currentCase = null;
  currentUser = null;
  userList = null;

  public openCaseState(
    idOrIdentifier: string | number,
    caseElement?: Element,
    sideViewMenuOption?: SideViewMenuOptions
  ): Promise<void> {
    this.setSelectedCase(idOrIdentifier, caseElement);
    return this.AppStateService.goToCase(idOrIdentifier, sideViewMenuOption);
  }

  public findAndDisplayCase(
    caseId: string,
    sideViewMenuOption?: string
  ): Promise<void> {
    let caseIdentifier = parseInt(caseId);
    let caseToSearch: string | number;
    if (isNaN(caseIdentifier)) {
      caseToSearch = caseId;
    } else {
      caseToSearch = caseIdentifier;
    }
    return this.searchCase(caseId).then(([caseObj, elem]) => {
      if (elem) {
        this.setSelectedCase(caseObj.identifier, elem);
      }
      this.showCaseDetails(caseObj, sideViewMenuOption);
    });
  }

  public searchCase(caseId: string | number): Promise<[Case, Element | null]> {
    return this.ColumnsService.findCase(caseId).then(caseObj => {
      if (caseObj) {
        let element = this.selectedCase?.elem;
        if (!this.caseHasId(caseObj, this.selectedCase?.idOrIdentifier)) {
          element = this.ColumnsService.findCaseElement(caseObj);
        }
        return [caseObj, element];
      } else {
        return this.CaseService.get(caseId).then(caseObj => [caseObj, null]);
      }
    });
  }

  private setSelectedCase(
    caseId: string | number,
    caseElement?: Element
  ): void {
    if (caseElement) {
      let container = caseElement.closest('.case-list');
      this.selectedCase = {idOrIdentifier: caseId, elem: container};
    } else {
      this.selectedCase = {idOrIdentifier: caseId, elem: null};
    }
  }

  private showCaseDetails(caseObject: Case, sideviewmenuoption?: string): void {
    this.sideViewMenuOption = sideviewmenuoption;
    this.$rootScope.caseId = caseObject.resource_id;
    this.updateCaseSelectionInDom(this.selectedCase?.elem);
    this.emitCaseViewOpenedEvent(this.selectedCase?.elem);
    const userObject = this.getUserFromCase(caseObject);
    this.showDetailView(caseObject, userObject);
    this.$rootScope.$broadcast('case:hasBeenUpdated', caseObject, userObject);
  }

  private caseHasId(caseObj: Case, idOrIdentifier: number | string): boolean {
    return (
      idOrIdentifier === caseObj.identifier ||
      idOrIdentifier === caseObj.resource_id
    );
  }

  private emitCaseViewOpenedEvent(ticketSelected?: Element): void {
    this.$rootScope.$emit('caseView:opened', ticketSelected);
  }

  private updateCaseSelectionInDom(ticketSelected?: Element): void {
    const elemArrayCases = document.getElementsByClassName('case-list');
    const selectedClass = 'selected-case';
    const newFlowCase = 'new-flow-case';
    if (elemArrayCases) {
      Array.from(elemArrayCases).forEach(elem => {
        elem.classList.remove(selectedClass);
      });
      ticketSelected && ticketSelected.classList.add(selectedClass);
    } else {
      Array.from(elemArrayCases).forEach(elem => {
        elem.classList.remove(selectedClass, newFlowCase);
      });
    }
  }

  public searchUserById(userId) {
    return this.UserService.getUser(userId).then(userObject => {
      this.showUserCase(userObject);
      return userObject;
    });
  }

  public searchUserByCountryId(countryId) {
    return this.UserService.getUserByCountryId(countryId).then(userList => {
      if (userList.length === 0) {
        throw 'Client Country Id not found.';
      } else if (userList.length === 1) {
        this.showUserCase(userList[0]);
      } else {
        this.showUserPicker(userList);
      }
      return userList;
    });
  }

  private showUserPicker(userList) {
    if (this.isUserPickerModalOpen) {
      this.closeUserPicker();
      this.$rootScope.$applyAsync(() => {
        this.isUserPickerModalOpen = true;
        this.userList = userList;
      });
    } else {
      this.isUserPickerModalOpen = true;
      this.userList = userList;
    }
  }

  private showUserCase(userObject) {
    this.getLastCaseFromUser(userObject);
  }

  public showUser(userObject) {
    this.showUserCase(userObject);
  }

  private showDetailView(caseObject, userObject) {
    this.$rootScope.$applyAsync(() => {
      this.currentCase = caseObject;
      this.currentUser = userObject;
      if (caseObject !== null) {
        caseObject.messages_unread = 0;
      }
      this.isModalOpen = true;
    });
  }

  private getUserFromCase(caseObject) {
    return caseObject.users && caseObject.users.length > 0
      ? caseObject.users[0]
      : caseObject.establishment_users[0];
  }

  private getLastCaseFromUser(userObject) {
    if (
      angular.isDefined(userObject.cases_id) &&
      userObject.cases_id.length > 0
    ) {
      var case_id = userObject.cases_id[userObject.cases_id.length - 1];
      this.findAndDisplayCase(case_id);
    } else {
      this.showDetailView(null, userObject);
    }
  }

  public closeCase() {
    this.isModalOpen = false;
    this.currentCase = null;
    this.currentUser = null;
  }

  public closeUserPicker() {
    this.isUserPickerModalOpen = false;
    this.userList = null;
  }

  public updateUser() {
    if (this.currentUser) {
      this.UserService.getUser(this.currentUser.resource_id).then(
        userObject => (this.currentUser = userObject)
      );
    }
  }

  constructor(
    AppStateService: AppStateService,
    $rootScope,
    CaseService,
    ColumnsService,
    UserService
  ) {
    this.CaseService = CaseService;
    this.ColumnsService = ColumnsService;
    this.UserService = UserService;
    this.AppStateService = AppStateService;
    this.$rootScope = $rootScope;
  }
}

var app = angular.module('postCenterWebClientApp');
app.service('CaseDetailManager', CaseDetailManager);
