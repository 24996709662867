'use strict';

interface salesforceUserEntity {
  Id: string
  FirstName: string | null
  LastName: string | null
  Email: string | null
  Phone: string | null
  Birthdate: string | null
  MailingStreet: string | null
  MailingCountry: string | null
  MailingCity: string | null
  MailingState: string | null
}

interface hubspotUserProperties {
  firstname: string | null
  lastname: string | null
  email: string | null
  phone: string | null
  gender: string | null
  date_of_birth: string | null
  address: string | null
  country: string | null
  city: string | null
  state: string | null
}

interface hubspotUserEntity {
  properties: hubspotUserProperties
}

interface CrmProperty {
  fieldname: string,
  type: string,
  translation: string,
  value: any
  editable: boolean
}

type crmUserEntity = hubspotUserEntity | salesforceUserEntity | null;

type crmUpdate = Record<string, any>

function isHubspotUser(object: any): object is hubspotUserEntity {
  return 'properties' in object;
}

function isSalesforceUser(object: any): object is salesforceUserEntity {
return 'FirstName' in object;
}

class UserMetadataCtrl {

  private AdNotification
  private CaseDetailsService
  private UserService: UserService
  private EstablishmentService: EstablishmentService
  private SocialAccountService: SocialAccountService
  private CrmService: CrmService
  private CustomData: CustomDataService
  private crmNames = ['SALESFORCE', 'HUBSPOT', 'SUGARCRM']
  $rootScope
  $scope
  $filter
  departmentWatch
  crmActive = null
  caseSideViewCtrl = null
  pendingInfluencerRequest = false
  caseObject
  userObject
  accountObject
  userCustomData = []
  canInteract: boolean
  optinActive: boolean
  pendingCrmSave = false;
  CrmIntegrationSlideOpen = false;
  loadingCrmEntity = true;
  crmUserEntity: crmUserEntity = null;
  crmProperties = [];

  $onInit() {
    this.setInfoObjects();
    this.init();
  }

  $onDestroy() {
    this.departmentWatch();
  }

  setInfoObjects() {
    this.userObject = this.userObject || this.CaseDetailsService.get('userObject')
    this.caseObject = this.caseObject || this.CaseDetailsService.get('caseObject')
  }

  getCaseId(): string | null {
    const controller = this.caseSideViewCtrl;
    if (
      !controller ||
      controller.caseObject === undefined ||
      controller.caseObject === null
    ) {
      return null;
    }
    return controller.caseObject.resource_id;
  };

  async getUserFromCrm() {
    this.loadingCrmEntity = true;
    try {
      const getEntityResponse: crmUserEntity = await this.CrmService.getEntity(this.crmName(), 'user', this.getEntityId())
      this.crmUserEntity = getEntityResponse;
      this.crmProperties = this.getCRMProperties(getEntityResponse);
    } catch (error) {
      this.AdNotification.error(error, 'get_crm_user');
      this.crmUserEntity = null;
      this.crmProperties = [];
    } finally {
      this.loadingCrmEntity = false;
      this.$scope.$applyAsync();
    }
  };

  getCRMProperties(crmUserEntity: crmUserEntity): Array<CrmProperty> {

    let defaultCrmProperties: Array<CrmProperty> = [];

    if (isHubspotUser(crmUserEntity)) {
      let properties: hubspotUserProperties = crmUserEntity.properties;
      defaultCrmProperties = [
        {
          fieldname: 'firstname',
          type: 'text',
          translation: this.$filter('translate')('NAME'),
          value: properties.firstname,
          editable: true
        },
        {
          fieldname: 'lastname',
          type: 'text',
          translation: this.$filter('translate')('LAST_NAME'),
          value: properties.lastname,
          editable: true
        },
        {
          fieldname: 'email',
          type: 'email',
          translation: this.$filter('translate')('EMAIL'),
          value: properties.email,
          editable: true
        },
        {
          fieldname: 'phone',
          type: 'text',
          translation: this.$filter('translate')('PHONE'),
          value: properties.phone,
          editable: true
        },
        {
          fieldname: 'gender',
          type: 'text',
          translation: this.$filter('translate')('GENDER'),
          value: properties.gender,
          editable: true
        },
        {
          fieldname: 'date_of_birth',
          type: 'date',
          translation: this.$filter('translate')('BIRTH_DATE'),
          value: this.getDateValue(properties.date_of_birth),
          editable: true
        },
        {
          fieldname: 'address',
          type: 'text',
          translation: this.$filter('translate')('ADDRESS'),
          value: properties.address,
          editable: true
        },
        {
          fieldname: 'country',
          type: 'text',
          translation: this.$filter('translate')('COUNTRY'),
          value: properties.country,
          editable: true
        },
        {
          fieldname: 'city',
          type: 'text',
          translation: this.$filter('translate')('CITY'),
          value: properties.city,
          editable: true
        },
        {
          fieldname: 'state',
          type: 'text',
          translation: this.$filter('translate')('STATE'),
          value: properties.state,
          editable: true
        }
      ]
    } else if (isSalesforceUser(crmUserEntity)) {
      defaultCrmProperties = [
        {
          fieldname: 'Id',
          type: 'text',
          translation: this.$filter('translate')('SALESFORCE_ID'),
          value: crmUserEntity.Id,
          editable: false
        },
        {
          fieldname: 'FirstName',
          type: 'text',
          translation: this.$filter('translate')('NAME'),
          value: crmUserEntity.FirstName,
          editable: true
        },
        {
          fieldname: 'LastName',
          type: 'text',
          translation: this.$filter('translate')('LAST_NAME'),
          value: crmUserEntity.LastName,
          editable: true
        },
        {
          fieldname: 'Email',
          type: 'email',
          translation: this.$filter('translate')('EMAIL'),
          value: crmUserEntity.Email,
          editable: true
        },
        {
          fieldname: 'Phone',
          type: 'text',
          translation: this.$filter('translate')('PHONE'),
          value: crmUserEntity.Phone,
          editable: true
        },
        {
          fieldname: 'Birthdate',
          type: 'date',
          translation: this.$filter('translate')('BIRTH_DATE'),
          value: this.getDateValue(crmUserEntity.Birthdate),
          editable: true
        },
        {
          fieldname: 'MailingStreet',
          type: 'text',
          translation: this.$filter('translate')('ADDRESS'),
          value: crmUserEntity.MailingStreet,
          editable: true
        },
        {
          fieldname: 'MailingCountry',
          type: 'text',
          translation: this.$filter('translate')('COUNTRY'),
          value: crmUserEntity.MailingCountry,
          editable: true
        },
        {
          fieldname: 'MailingCity',
          type: 'text',
          translation: this.$filter('translate')('CITY'),
          value: crmUserEntity.MailingCity,
          editable: true
        },
        {
          fieldname: 'MailingState',
          type: 'text',
          translation: this.$filter('translate')('STATE'),
          value: crmUserEntity.MailingState,
          editable: true
        }
      ]
    }
    let defaultCrmFields: Array<string> = defaultCrmProperties.map(function(element){
      return element.fieldname;
    });
    let customCrmProperties: Array<CrmProperty> = this.getCustomCrmProperties(crmUserEntity, defaultCrmFields);
    return [...defaultCrmProperties, ...customCrmProperties];
  };

  getCustomCrmProperties(crmUserEntity: crmUserEntity, defaultCrmFields: Array<string>): Array<CrmProperty> {
    let customCrmProperties: Array<CrmProperty> = []
    this.userCustomData.forEach(e => {
      if (e.crm_integration) {
        let integration_key: string = e.crm_integration[this.getCrmkey()];
        if (integration_key && !defaultCrmFields.includes(integration_key)) {
          let value = null;
          if (isHubspotUser(crmUserEntity)) {
            value = crmUserEntity.properties[integration_key]
          } else if (isSalesforceUser(crmUserEntity)){
            value = crmUserEntity[integration_key]
          }
          customCrmProperties.push({
            fieldname: integration_key,
            type: 'text',
            translation: e.label,
            value: value,
            editable: true
          })
        }
      }
    });
    return customCrmProperties;
  };

  getDateValue(date: string | null): Date | null {
    if (date) return new Date(date);
    return null;
  }

  async unlinkEntity() {
    await this.CrmService.unlinkEntity(this.crmName(), 'user', this.userObject.resource_id);
    this.userObject.crm_integration[this.getCrmkey()] = null;
    this.crmUserEntity = null;
    this.crmProperties = [];
    this.CrmIntegrationSlideOpen = false;
    this.$scope.$apply();
    this.$onInit();
  };

  getCrmUpdate(crmProperties: Array<CrmProperty>): crmUpdate {
    let crmUpdate: crmUpdate = {}
    crmProperties.forEach(e => {
      if (e.editable) {
        crmUpdate[e.fieldname] = e.value;
      }
    });
    return crmUpdate
  }

  async saveCrmProperties(crmProperties: Array<CrmProperty>) {
    this.loadingCrmEntity = true;
    let crmUpdate: crmUpdate = this.getCrmUpdate(crmProperties);
    try {
      await this.CrmService.saveEntity(this.crmName(), 'user', this.getEntityId(), crmUpdate);
      this.AdNotification.success(200, 'save_crm_properties');
      this.pendingCrmSave = false;
    } catch (error) {
      this.AdNotification.error({}, 'save_crm_properties');
    }
    this.loadingCrmEntity = false;
  };

  openCrmIntegrationWindow() {
    if (this.CrmIntegrationSlideOpen) {
      this.CrmIntegrationSlideOpen = false;
    } else {
      this.getUserFromCrm();
      this.CrmIntegrationSlideOpen = true;
    }      
  };

  getCrmName(): string | null {return this.hasCrmActive() ? this.crmName() : null};
  getCrmIcon(): string | null {return this.hasCrmActive() ? `fa-${this.crmName()}` : null};
  getCrmLogo(): string | null {return this.hasCrmActive() ? this.crmLogo() : null};

  capitalizeCrmName(): string {
    const {name = ''} = this.crmActive || {};
    return name === 'SALESFORCE' ? 'Salesforce' : 'HubSpot'
  };

  getCrmIconTooltip(): string | null {
    return this.$filter('translate')('CRM_SEE_ENTITY_ON', {
      entity: this.$filter('translate')('user'),
      crmName: this.capitalizeCrmName()
    })
  };

  getEntityId(): string {
    const {crm_integration} = this.userObject;
    return crm_integration[this.getCrmkey()];
  };

  getCrmkey(): string {
    return `${this.crmName()}_id`;
  };

  crmLogo(): string {
    const {name = ''} = this.crmActive || {};
    if (name == 'HUBSPOT') {
      return 'https://cdn.adere.so/images/desk/integrations/crm/hubspot/hubspot.png'
    } else if (name == 'SALESFORCE') {
      return 'https://cdn.adere.so/images/desk/integrations/crm/salesforce/salesforce.png'
    }
  };

  crmName(): string {
    const {name = ''} = this.crmActive || {};
    return name.toLowerCase();
  };

  hasCrmActive(): boolean {
    const {crm_integration: userCrmIntegration} = this.userObject;
    return (
      this.crmActive &&
      userCrmIntegration &&
      userCrmIntegration[this.getCrmkey()]
    );
  };

  init() {
    Promise.all([
      this.UserService.getProfile(),
      this.EstablishmentService.getEstablishment(),
      this.SocialAccountService.getSocialAccount(
        this.userObject.sn, this.caseObject.establishment_users[0].uid
      ),
      this.CustomData.getCustomData()
    ]).then(results => {
      let user: ExtendedUser = results[0];
      let establishment: Establishment = results[1];
      this.accountObject = results[2];
      const customData = results[3];

      this.userCustomData = _.sortBy(
        _.filter(customData, datum => {
          return datum.entity === 'U' && !datum.fixed && !datum.deleted;
        }),
        'index'
      );
  
      this.optinActive = this.accountObject.config.optin_config && this.accountObject.config.optin_config.is_active;
      this.canInteract = user.isAdminOrCm;
      this.crmActive = establishment.integrations.integration_configs.find(
        ({config: {active}, name}) => !!(active && this.crmNames.includes(name))
      ) || null;
      if (this.CrmIntegrationSlideOpen && this.hasCrmActive()) {
        // refresh when new user is opened;
        this.getUserFromCrm();
      } else {
        this.CrmIntegrationSlideOpen = false;
      }
    })
  }

  saveMetadata() {
    this.$scope.$broadcast('customDataBlock:save');
  };

  influencerToggled() {
    const {influencer} = this.userObject;
    this.pendingInfluencerRequest = true;
    this.UserService.setInfluencer(this.userObject.resource_id, influencer)
      .then(response => {
        this.userObject.influencer = response.influencer;
        if (angular.isDefined(this.caseObject) && this.caseObject !== null) {
          this.caseObject.important = true;
        }
        angular.forEach(response.cases_id, caseId => {
          const targetCase = {
            resource_id: caseId
          };
          if (response.influencer) {
            targetCase['important'] = true;
          }
        });
        this.AdNotification.success(200, 'toggle_influencer');
      })
      .catch(data => {
        this.userObject.influencer = !this.userObject.influencer;
        this.AdNotification.error(data, 'toggle_influencer');
      })
      .finally(() => {
        this.pendingInfluencerRequest = false;
      });
  };

  constructor(
    $scope,
    $filter,
    UserService,
    $rootScope,
    AdNotification,
    CaseDetailsService,
    EstablishmentService,
    SocialAccountService,
    CrmService,
    CustomData
  ) {
    this.CaseDetailsService = CaseDetailsService
    this.AdNotification = AdNotification
    this.UserService = UserService
    this.EstablishmentService = EstablishmentService
    this.SocialAccountService = SocialAccountService
    this.CrmService = CrmService
    this.CustomData = CustomData
    this.$rootScope = $rootScope
    this.$scope = $scope
    this.$filter = $filter

    $scope.departmentName = null;
    
    this.departmentWatch = $scope.$watch(
      'caseObject.department_id',
      newValue => {
        $scope.departmentName = newValue ? getDepartmentName() : null;
        $scope.$broadcast('customDataBlock:caseObject', this.caseObject);
      }
    );

    const getDepartmentName = (): string => {
      const {department_id: departmentId} = this.caseObject;
      const {departments} = $rootScope;
      if (departments && departments.length && departmentId) {
        const {name} = departments.find(({id}) => id === departmentId) || {};
        return name || $filter('translate')('DEPARTMENT_DELETED');
      }
      return $filter('translate')('DEPARTMENT_DELETED');
    };
    
    const caseUpdateListenerOff = $scope.$on('case:hasBeenUpdated', (_event, caseObject) => {
      caseObject = caseObject
      //init();
    })

    const userUpdateListenerOff = $scope.$on('userCase:hasBeenUpdated', (_event, userCase) => {
      this.userObject = userCase
      this.init()
    })

    const customDataSaveListenerOff = $scope.$on('customDataBlock:saveDone', () => {});

    $scope.$on('$destroy', () => {
      caseUpdateListenerOff();
      userUpdateListenerOff();
      customDataSaveListenerOff();
    });
  }
}

angular
  .module('postCenterWebClientApp')
  .controller('userMetadataCtrl', UserMetadataCtrl)
