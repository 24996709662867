Vue.component('TicketColumn', {
  props: {
    title: {type: String, default: 'Tickets'},
    tickets: {type: Array, default: () => []},
    loadMorePages: {type: Function, default: () => {}, required: false}
  },
  data() {
    return {minimized: false};
  },
  methods: {
    toggleMinimize() {
      this.minimized = !this.minimized;
    },
    formatDate(value = '') {
      return moment.unix(value).format('DD/MM/YYYY hh:mm');
    },
    getClient(ticket = {}) {
      if (!ticket.users[0]) {
        return {};
      }

      const [user] = ticket.users;
      const {name, uname, avatar: src, sn: channel} = user;

      return {
        name,
        uname,
        title: name,
        src,
        channel
      };
    },
    getExecutive(ticket = {}) {
      const {current_assignment} = ticket;
      return current_assignment;
    },
    closeColumn() {
      this.$emit('onDestroy');
    }
  },
  template: `
    <article class="v-column">
      <section>
        <header class="row d-flex space-between align-center w-100 mx-auto">
          <span class="col-md-8 text-overflow p-0">{{title}}</span>
          <div class="col-md-4 p-0 text-right">
            <button type="button"
                    @click="toggleMinimize"
                    class="btn btn-link py-0">
                    <span class="fa fa-lg no-styles"
                          :class="[minimized ? 'fa-plus' : 'fa-minus']"></span>
            </button>
            <button type="button"
                    @click="closeColumn"
                    class="btn btn-link py-0">
                    <span class="fa fa-lg fa-times no-styles"></span>
            </button>
          </div>
        </header>

        <slot></slot>

        <ul class="m-0 list-unstyled"
            v-show="!minimized"
            v-infinite-scroll="() => $emit('onBottomScroll')">
          <li v-for="ticket in tickets" :key="ticket.identifier">
            <Avatar v-bind="getClient(ticket)"
                    class="m-0"
                    :bordered="false"
                    :centered="true" />

            <div class="d-flex flex-column">
              <strong class="text-overflow">{{ getClient(ticket).name }}</strong>
              <small class="text-overflow text-muted">{{ getClient(ticket).uname }}</small>
              <small v-show="getExecutive(ticket)" class="text-overflow">
                <span class="fa fa-user" />
                {{ getExecutive(ticket)?.name }}
              </small>
              <small class="text-overflow" v-html="ticket.last_content"></small>
              <small>
                Ticket
                <a target="blank" :href="'/#/case/' + ticket.identifier">{{ticket.identifier}}</a>
              </small>
            </div>

            <small class="text-right">{{ formatDate(ticket.created) }}</small>
          </li>
        </ul>
      </section>
    </article>
  `
});
