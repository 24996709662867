const ExpandButton = Vue.component('ExpandButton', {
  props: {
    identifier: {
      type: String,
      required: true
    },
    expanded: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...Vuex.mapState(['kpis'])
  },
  methods: {
    ...Vuex.mapMutations(['mutate']),
    toggleSize() {
      const resizedKPIs = this.kpis.map(kpi => {
        if (kpi.identifier === this.identifier) {
          kpi.expanded = !kpi.expanded;
        }
        return kpi;
      });
      this.mutate({prop: 'kpiList', data: resizedKPIs});
    }
  },
  template: `
        <button type="button"
                class="btn btn-link pull-right"
                @click="toggleSize">
          <span class="fa text-info" :class="[expanded ? 'fa-compress' : 'fa-expand']"></span>
        </button>
      `
});
