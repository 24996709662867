Vue.component('contact-single', {
  data() {
    return {
      tabs: [],
      userTab: null
    };
  },
  computed: {...Vuex.mapGetters(['visibleForm', 'contact', 'permissions'])},
  watch: {
    contact({uid}) {
      if (this.userTab !== uid) {
        this.userTab = uid;
        this.tabs = this.getDefaultTabs();
      }
    }
  },
  mounted() {
    this.tabs = this.getDefaultTabs();
    // this.unsubscribe = this.$store.subscribe(({type, payload}) => {
    //   if (type === 'SET_CONTACT_DETAIL') {
    //     this.tabs = this.getDefaultTabs();
    //   } else if (type === 'SET_VISIBLE_FORM' && payload === 'notes') {
    //     this.goToTab(2);
    //   }
    // });
  },
  beforeDestroy() {
    // this.unsubscribe();
  },
  methods: {
    scrollIntoView() {
      this.$el.scrollIntoView({behavior: 'smooth'});
    },
    goToTab(index = 0) {
      this.tabs.forEach((tab, tabIndex) => {
        tab.active = tabIndex === index;
      });
    },
    getDefaultTabs() {
      return [
        {
          name: this.$t('columns.accounts'),
          component: 'contact-single-account',
          active: true
        },
        {
          name: 'Tickets',
          component: 'contact-single-tickets',
          active: false
        },
        {
          name: this.$t('columns.notes'),
          component: 'contact-single-notes',
          active: false
        }
      ];
    }
  },
  template: `
    <article class="contact-agenda--column scrollable scrollable-y">
      <contact-single-header class="card__shadow" />

      <Tabs class="card card__shadow contact-agenda--contact" :tabs="tabs"
               v-if="!visibleForm">
        <Tab v-for="tab in tabs" :name="tab.name" :active="tab.active">
          <Loader size="2x" v-if="!contact.accounts" />
          <component v-else :is="tab.component" />
        </Tab>
      </Tabs>
    </article>
  `
});

Vue.component('contact-single-header', {
  computed: {
    ...Vuex.mapGetters(['contact', 'visibleForm'])
  },
  methods: {...Vuex.mapActions(['setVisibleForm', 'viewContact'])},
  template: `
    <section class="card row">
      <Avatar class="col-xs-3 col-md-2"
                 :src="contact.avatar"
                 :title="contact.name"
                 :is-influencer="contact.raw_influencer"
                 :bordered="false"
                 size="big" />
      <div class="col-sm-12 col-md-8">
        <div class="d-flex space-between">
          <h4 class="bold text-overflow">{{ contact.name }}</h4>
          <div>
            <button type="button"
                    class="btn btn-link"
                    v-if="false"
                    @click="setVisibleForm('edit')">
              <span class="fa fa-pencil-square-o text-info"></span>
              <span class="text-info">{{ $t('CTA.edit') }}</span>
            </button>
            <button type="button"
                    class="btn btn-link"
                    @click="viewContact({})">
              <span class="fa fa-times text-info"></span>
            </button>
          </div>
        </div>
        <p class="m-0 text-overflow">{{ contact.uname }}</p>
        <p>{{ $t('columns.favorite') }}: {{ $tc('columns.boolean', contact.favorite) }}</p>
      </div>

      <div class="col-sm-12" v-if="visibleForm">
        <contact-form-message v-if="visibleForm === 'contact'" />
        <contact-form-edit v-else-if="visibleForm === 'edit'" />
        <contact-form-note v-else />
      </div>
    </section>
  `
});

Vue.component('contact-single-notes', {
  computed: {
    ...Vuex.mapGetters(['notes', 'permissions']),
    sortedNotes() {
      if (!this.notes) {
        return [];
      }

      return this.notes.sort((prev, next) => next.created - prev.created);
    }
  },
  methods: {
    ...Vuex.mapActions(['setVisibleForm', 'deleteNote']),
    ...Vuex.mapMutations(['SET_NOTE']),
    toDate(milliseconds) {
      return moment.unix(milliseconds).format('DD/MM/YYYY hh:mm:ss');
    },
    openNoteForm(note = null) {
      this.SET_NOTE(note);
      this.setVisibleForm('notes');
    }
  },
  template: `
    <section class="contact-agenda--notes">
      <ol v-if="sortedNotes.length"
          class="list-unstyled">
        <li v-for="note in sortedNotes"
            class="row no-gutters">
          <Collapsable :open="false" background="clear">
            <template v-slot:title>
              <strong class="d-inline-block w-75 text-overflow">{{ note.subject }}</strong>
            </template>
            <template v-slot:icon v-if="permissions.isAdmin">
              <div class="note--actions">
                <span class="fa fa-trash"
                      @click.prevent="deleteNote(note)"></span>
                <span class="fa fa-pencil mx-1"
                      @click.prevent="openNoteForm(note)"></span>
              </div>
            </template>
            <span class="d-block">{{ $t('forms.date') }}: {{ toDate(note.updated_time) }}</span>
            <p class="pt-1 px-2">{{ note.content }}</p>
          </Collapsable>
          <hr class="m-0" />
        </li>
      </ol>
      <p v-else>{{ $t('noResults.notes') }}</p>

      <button type="button"
              class="btn ads-button ads-button--primary d-block mx-auto"
              @click="openNoteForm(null)">
        {{ $t('CTA.newNote') }}
      </button>
    </section>
  `
});

Vue.component('contact-single-tickets', {
  inject: ['socialNetworkService'],
  computed: {
    ...Vuex.mapGetters(['contact', 'tickets'])
  },
  methods: {
    getEstablishmentAccount(ticket) {
      const {establishment_users = []} = ticket;
      return establishment_users[0];
    },
    toDate(milliseconds) {
      return moment.unix(milliseconds).format('DD/MM/YYYY');
    },
    getTicketURL(id = '') {
      return `#/case/${id}`;
    },
    cleanMessage(message) {
      return message.slice(0, 60);
    }
  },
  template: `
    <section>
      <ol v-if="tickets.length"
          class="list-unstyled">
        <li v-for="(ticket, index) in tickets"
            class="row no-gutters my-1">
          <div class="col-sm-12 col-md-2">
            <Avatar :channel="ticket.sn" :bordered="false" />
          </div>
          <div class="col-sm-12 col-md-9">
            <div class="d-flex space-between">
              <strong>{{ getEstablishmentAccount(ticket).name }}</strong>
              <span>{{ $t('CTA.lastUpdate') }}: {{ toDate(ticket.updated_time) }}</span>
            </div>

            <span>{{ socialNetworkService.getTitle(ticket.sn) }}</span>
            <p class="text-overflow m-0">
              <strong>{{ $t('CTA.lastMessage') }}:</strong> {{ cleanMessage(ticket.last_content) }}
            </p>

            <div class="d-flex space-between">
              <contact-ticket-id :ticket="ticket" />
              <a :href="getTicketURL(ticket.resource_id)" class="d-block" target="_blank">
                <span class="fa fa-arrow-right mr-1"></span>
                {{ $t('CTA.goToTicket') }}
              </a>
            </div>
          </div>
          <hr v-if="index <= tickets.length" class="w-100 m-0" />
        </li>
      </ol>
      <p v-else>{{ $t('noResults.tickets') }}</p>
    </section>
  `
});

Vue.component('contact-ticket-id', {
  props: {ticket: {type: Object, default: {}}},
  methods: {...Vuex.mapActions(['copyTicketId'])},
  template: `
    <button type="button"
            class="btn btn-link btn-xs px-0"
            @click="copyTicketId(ticket.identifier)"
            :title="$t('CTA.copyTicketId')">
      <span class="fa fa-ticket"></span>
      <strong class="mx-1">Ticket {{ ticket.identifier }}</strong>
    </button>
  `
});

Vue.component('contact-single-account', {
  data() {
    return {selectedAccount: null};
  },
  computed: {
    ...Vuex.mapState(['contact']),
    ...Vuex.mapGetters(['getSocialAccountById']),
    sections() {
      const {contact, contactedAccounts} = this;
      const {accounts} = contact;
      return [
        {
          title: this.$t('columns.accounts'),
          list: accounts,
          listAction: data => this.viewAccount(data),
          icon: 'fa-omnichannel'
        },
        {
          title: this.$t('columns.contactedAccounts'),
          list: contactedAccounts,
          listAction: _data => {}, // next feature omnichannel
          icon: 'fa-user'
        }
      ];
    },
    contactedAccounts() {
      const {social_accounts = []} = this.contact;

      return social_accounts
        .map(this.getSocialAccountById)
        .filter(account => !!account);
    }
  },
  methods: {
    ...Vuex.mapActions(['viewContact']),
    viewAccount(account) {
      if (!account) {
        throw new Error('Missing required param');
      }
      this.selectedAccount = {...this.contact, ...account, isSelected: true};
      this.viewContact(this.selectedAccount);
    }
  },
  watch: {
    contact: {
      deep: true,
      immediate: true,
      handler(value = {}) {
        if (!value.isSelected && Array.isArray(value.accounts)) {
          this.selectedAccount = null;
          this.viewAccount(value.accounts[0]);
        }
      }
    }
  },
  mounted() {
    this.viewAccount(this.contact.accounts[0]);
  },
  template: `
    <section>
      <Collapsable v-for="section in sections"
                   v-if="section.list.length"
                   :key="section.title"
                   background="none"
                   :open="true"
                   :padding-level="0">
        <template v-slot:title>
          <span class="text-info fa mr-1" :class="section.icon"></span>
          <span class="text-info">{{ section.title }}</span>
        </template>

        <AvatarList v-if="section.list"
                    :social-accounts="section.list"
                    :highlighted-uid="selectedAccount?.uid"
                    @onAccountClicked="(account) => section.listAction(account)" />
      </Collapsable>


      <template v-if="selectedAccount">
        <client-not-contactable
          v-if="!contact.is_contactable"
          :reasons="contact.not_contactable_reason" />
        <hr />
        <contact-single-account-info />
        <contact-button class="d-flex center" :clear="false" :hide="contact.is_contactable"/>

      </template>
    </section>
  `
});

Vue.component('contact-single-account-info', {
  inject: ['socialNetworkService'],
  methods: {
    getColumnLabel(column = {}) {
      const hasLabel = column.label;
      const translation = this.$t(`columns.${column.field}`);

      return hasLabel || translation;
    },
    getCheckboxField(column = {}) {
      const {options} = column;
      const clientOptions = this.contact[column.field];

      if (!clientOptions) {
        return;
      }

      return options
        .filter(option => clientOptions[option.key])
        .map(result => result.value);
    },
    getSelectField(column = {}) {
      const {options} = column;
      const selected = options.find(
        option => option.key === this.contact[column.field]
      );
      return selected?.value;
    }
  },
  computed: {
    ...Vuex.mapGetters(['tableColumns', 'contact']),
    filteredFields() {
      const hiddenFields = ['favorite', 'contact', 'influencer'];
      return this.tableColumns.all.filter(
        column => !hiddenFields.includes(column.field)
      );
    }
  },
  template: `
    <ul class="basic list-unstyled">
      <li v-for="(column, index) in filteredFields">
        <strong>{{ getColumnLabel(column) }}:</strong>

        <template v-if="column.field === 'gender'">
          {{ $tc('columns.gender', contact.gender ) }}
        </template>

        <template v-else-if="column.field === 'sn'">
          {{ socialNetworkService.getTitle(contact.sn) }}
        </template>

        <template v-else-if="column.type === 'list'">
          <ul v-if="Array.isArray(contact[column.field])">
            <li v-for="value in contact[column.field]">{{ value}}</li>
          </ul>
        </template>

        <template v-else-if="column.type === 'boolean'">
          <span>{{ $tc('columns.boolean', Number(contact[column.field])) }}</span>
        </template>

        <template v-else-if="column.type === 'checkbox'">
          <ul>
            <li v-for="option in getCheckboxField(column)"
                :key="option.key">{{option}}</li>
          </ul>
        </template>

        <template v-else-if="column.type === 'select'">
          <span>{{ getSelectField(column) }}</span>
        </template>

        <template v-else>{{ contact[column.field] }}</template>
      </li>
    </ul>
  `
});
