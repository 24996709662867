(module => {
  'use strict';

  function ExportCtrl(
    $scope,
    $rootScope,
    BASE_URL,
    ColumnExportService,
    AdNotification,
    UserService: UserService,
    $timeout,
    $filter,
    $q,
    AdRequest,
    $window,
    AppStateService: AppStateService,
    FireTagService,
    EstablishmentService: EstablishmentService,
    ThirdPartyService
  ) {

    FireTagService.setPageView({
      title: 'Exportaciones',
      path: `/${window.location.hash}`
    });
    $rootScope.adminTab.selected = 'export';

    $scope.pagination = {
      pageNumber: 1,
      pageSize: 6,
      totalItems: 0
    };

    $scope.usersId = {};
    angular.forEach($rootScope.communityManagers, cm => {
      $scope.usersId[cm.cmid] = cm;
    });

    $scope.inProgressReloaders = {};

    const pageNumberWatcherOff = $scope.$watch('pagination.pageNumber', page => {
      updateExportColumns(page);
    });

    $scope.$on('$destroy', () => {
      pageNumberWatcherOff();
    });

    var REFRESH_INTERVAL = 60; // seconds
    $scope.refresh = true;

    function refreshFunction() {
      if ($scope.refresh) {
        updateExportColumns($scope.pagination.pageNumber).then(() => {
          $timeout(refreshFunction, REFRESH_INTERVAL * 1000);
        });
      }
    }
    refreshFunction();

    $scope.$on('$destroy', () => {
      cancelInProgressReloaders();
      addExportColumListenerOff();
      $scope.refresh = false;
    });

    const addExportColumListenerOff = $scope.$on('addExportColumn', () => {
      updateExportColumns(1);
    });

    function cancelInProgressReloaders() {
      angular.forEach($scope.inProgressReloaders, reloader => {
        reloader.reject('RELOADER_CANCELED');
      });
      $scope.inProgressReloaders = {};
    }

    function launchInProgressReloaders() {
      angular.forEach($scope.exportedColumns, (column, i) => {
        var reloaders = $scope.inProgressReloaders;
        if (column.state === 'IN_PROGRESS') {
          reloaders[column.resource_id] = reloaderFactory(column);
        }

        function columnReload(column) {
          processColumn(column);
          $scope.exportedColumns[i] = column;
          if (column.state === 'IN_PROGRESS') {
            reloaders[column.resource_id] = reloaderFactory(column);
          }
        }

        function reloaderFactory(column) {
          var reloader = $q.defer();
          // aca se puede revisar el column.column_type y elegir el endpoint segun si es campaña o columna
          // otra opcion es que el endpoint de columna se ajuste para que tambien devuelva la campaña
          var timer = $timeout(angular.noop, 3000);
          timer
            .then(() => {
                return ColumnExportService.getExport(column);
            })
            .then(column => {
              reloader.resolve(column);
            });
          reloader.promise.then(columnReload).catch(reason => {
            if (reason === 'RELOADER_CANCELED') {
              $timeout.cancel(timer);
            }
          });
          return reloader;
        }
      });
    }

    function updateExportColumns(page) {
      $scope.loading = true
      return ColumnExportService.getExportedColumns(page).then(
        exportedColumns => {
          $scope.loading = false
          $scope.pagination.totalItems = exportedColumns.count
          $scope.exportedColumns = exportedColumns.docs
          processCurrentPageOfExports()
        },
        error => {
          $scope.loading = false
          AdNotification.error(error, 'export_column')
        }
      )
    }

    function processCurrentPageOfExports() {
      cancelInProgressReloaders();
      _.each($scope.exportedColumns, (column, i) => {
        processColumn(column);
      });
      launchInProgressReloaders();
    }

    function processColumn(column) {
      var errorStatuses = [
        'ERROR_GENERATING_REPORT',
        'ERROR_SAVING_FILE_ON_MACHINE'
      ];
      var okStatuses = [
        'FILE_ON_BUCKET',
        'FILE_ON_BUCKET_NOT_CLEANED',
        'FILE_ON_BUCKET_AND_CLEANED',
        'ERROR_UPLOADING_BUCKET'
      ];

      if (okStatuses.indexOf(column.status) > -1) {
        column.state = 'OK';
        var time = column.finished - column.created;
        column.completedIn = moment.duration(time, 'seconds').humanize();
        if (!column.filename) {
          column.filename = `${column.resource_id}.xls`;
        }
      } else if (errorStatuses.indexOf(column.status) > -1) {
        column.state = 'ERROR';
      } else {
        column.state = 'IN_PROGRESS';
        if (column['processed'] && column['estimated_total']) {
          column.advance = parseInt(
            (column['processed'] / column['estimated_total']) * 100
          );
        } else {
          column.advance = 0;
        }
      }
    }

    $scope.download = function (exportedColumn) {
      if (exportedColumn.full_path) {
        $window.location.href = ColumnExportService.getRealPath(exportedColumn);
      } else {
        AdRequest.get(
          `/api/v1/column/${exportedColumn.resource_id}/download/`
        ).then(response => {
          var base_url = BASE_URL.replace('http:', $window.location.protocol);
          $window.location.href = `${base_url}/api/v1/file/${response.download_id}`;
        });
      }
    };

    $scope.toggleFilter = function (exportedColumn) {
      if (exportedColumn.showingFilter === undefined) {
        exportedColumn.showingFilter = true;
      } else {
        exportedColumn.showingFilter = !exportedColumn.showingFilter;
      }
    };

    $scope.deleteFile = function (exportedColumn) {
      ColumnExportService.deleteColumn(exportedColumn).then(() => {
        cancelInProgressReloaders();
        var index = $scope.exportedColumns.indexOf(exportedColumn);
        if (index > -1) {
          $scope.exportedColumns.splice(index, 1);
        }
        launchInProgressReloaders();
      });
    };

    $scope.getSocialNetworkName = function (exportedColumn) {
      var socialNetworks = exportedColumn.social_networks;

      if (!socialNetworks || socialNetworks.length === 0) {
        return $filter('translate')('EXPORT_ANY_SOCIAL_NETWORK');
      }

      var socialNetwork = socialNetworks[0];
      return $filter('capitalize')(socialNetwork);
    };

    $scope.getSocialAccountUsername = function (exportedColumn) {
      var socialAccounts = exportedColumn.social_accounts;

      if (!socialAccounts || socialAccounts.length === 0) {
        return $filter('translate')('EXPORT_ANY_SOCIAL_ACCOUNT');
      }

      var socialAccount = socialAccounts[0];
      if (socialAccount) {
        return socialAccount;
      } else {
        return $filter('translate')('EXPORT_UNKNOWN_SOCIAL_ACCOUNT');
      }
    };

    $scope.exportColumnModal = function () {
      $rootScope.$emit('modalNewColumn', 'Export', {})
    }

    EstablishmentService.getEstablishment().then(establishment => {
      $scope.secureFiles = establishment.config.secure_files

      function limits() {
        const limitId = 'EXP'
        EstablishmentService.getLimits(limitId).then(limitData => {
          const {
            plan: {limits}
          } = establishment
          const limitExp = limits.find(limit => limit.limit_id === limitId)
  
          const {count, start_date, end_date} = limitData
          const week = `${moment.unix(start_date).format('DD MMM')} - ${moment
            .unix(end_date)
            .format('DD MMM YYYY')}`
  
          const limit = limitExp.limit_max
  
          $scope.expLimits = {
            count,
            limit,
            week
          }
  
          $scope.expLimits.pending = limit - count
          $scope.expLimits.progressValue = (count / limit) * 100
          $scope.expLimits.showLimits = limit !== -1
          $scope.expLimits.limitReached = limit === count
        })
      }

      const hasLimits = establishment.plan.limits.find(
        limit => limit.limit_max !== -1
      )
  
      $scope.expLimits = {}
      $scope.expLimits.showLimits = hasLimits
      hasLimits && limits()
  
      $rootScope.$on('updateExportLimits', limits)
    })

    $scope.showBilling = function () {
      return ThirdPartyService.showBilling()
    }
  }
  module.controller('ExportCtrl', ExportCtrl)
})(angular.module('postCenterWebClientApp'))
