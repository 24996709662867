const EpaTabs = Vue.component('EpaTabs', {
  computed: {
    ...Vuex.mapGetters('epaAnalytics', ['tabs'])
  },
  template: `
    <Tabs :tabs="tabs" :padding-tabs="1">
      <div v-for="tab in tabs"
          :key="tab.group"
          :name="tab.name"
          :active="tab.active">
        <SectionTab
          v-if="tab.active"/>
      </div>
    </Tabs>
  `
});
