(module => {
  'use strict';
  cannedResponseContext.$inject = [];
  function cannedResponseContext() {
    return {
      controller: 'cannedResponseContextCtrl',
      controllerAs: 'cannedResponseContextCtrl',
      bindToController: {
        suggestions: '=',
        availableParams: '=',
        cannedResponses: '='
      }
    };
  }
  module.directive('cannedResponseContext', cannedResponseContext);
})(angular.module('postCenterWebClientApp'));
