interface ChatParams {
  user;
  establishment: Establishment;
  postcenterVersion: string;
}

class AdChatService {

  $rootScope;
  intercomToken: string;
  AdTalkcenterService: AdTalkcenterService;
  chatParams: ChatParams = null;

  constructor($rootScope, INTERCOM_TOKEN, AdTalkcenterService) {
    this.$rootScope = $rootScope
    this.intercomToken = INTERCOM_TOKEN
    this.AdTalkcenterService = AdTalkcenterService
  }

  public init(chatParams: ChatParams) {
    this.chatParams = chatParams;
    this.shutDown();
    const {
        use_postcenter_chat,
        talkcenter_token
    } = chatParams.establishment.config.chat;
    if (use_postcenter_chat) {
      this.getTalkcenter(talkcenter_token, chatParams)
    }
    else {
      this.getIntercom()
    }
  }

  private getUserData() {
    const {
      user,
      establishment,
      postcenterVersion: postcenter_version,
    } = this.chatParams;

    const {
      email,
      id: user_id,
      first_name,
      last_name,
      isAdmin,
      isAnalyst,
      isCm,
      profile: {language, phone, avatar_url}
    } = user;
    const {
      created_at,
      name,
      id,
      timezone,
      country: {name: countryName}
    } = establishment;
    const company = {
      name,
      id,
      timezone,
      created_at: Date.parse(created_at) / 1000,
      country: countryName
    };
    const connection = this.userConnection();
    return {
      email,
      phone,
      user_id,
      company,
      language_override: language,
      postcenter_version,
      name: `${first_name} ${last_name}`,
      is_admin: isAdmin,
      is_cm: isCm,
      is_analyst: isAnalyst,
      avatar: {
        type: 'avatar',
        image_url: avatar_url
      },
      ...connection
    }
  }

  private getIntercom() {
    const token = {
      app_id: this.intercomToken
    };
    const params = {...token, ...this.getUserData()};
    window.Intercom('boot', params);
    this.connectionListener();
  }

  private userConnection() {
    const {connection: {type, effectiveType, downlink, rtt} = {}} =
      navigator || {};
    const connection_downlink = downlink ? `${downlink} Mb/s` : null;
    const connection_rtt = rtt ? `${rtt} ms` : null;
    return {
      connection_downlink,
      connection_rtt,
      connection_type: type || null,
      connection_effectiveType: effectiveType || null
    };
  }

  private connectionListener() {
    Intercom('onShow', () => {
      const connection = this.userConnection();
      Intercom('update', {...connection});
    });
  }

  private getTalkcenterSettings(chatParams : ChatParams): TalkcenterSettings {
    if (chatParams.user) {
      const {
        first_name,
        last_name,
        email: user_email,
        id: user_id
      } = chatParams.user;

      const user_custom_data = {
        establishment_id: "" + chatParams.establishment.id,
        establishment_name: chatParams.establishment.name,
      }
      return {
        user_id,
        user_email,
        user_custom_data,
        user_name: `${first_name} ${last_name}`
      };
    } else {
      return {};
    }
  }

  private getTalkcenter(token: string, chatParams : ChatParams) {
    const settings = this.getTalkcenterSettings(chatParams);
    this.AdTalkcenterService.start(token, settings);
  }

  public shutDown() {
    if (!this.$rootScope.onboarding) {
      this.AdTalkcenterService.shutDown();
    }
    window.Intercom('shutdown');
  }

}

angular
  .module('postCenterWebClientApp')
  .service('adChatService', AdChatService);
