class ContactAgendaCtrl {

  private FireTagService
  private UserService: UserService
  private $element
  private vueInstance
  private AdAvailableSn
  private ContactAgendaService
  private EstablishmentService
  private AdNotification
  private language: string
  private permissions
  private socialAccounts

  loading: boolean = true

  constructor (
    $element,
    ContactAgendaService,
    AdAvailableSn,
    AdNotification,
    FireTagService,
    UserService,
    SocialAccountService: SocialAccountService,
    EstablishmentService: EstablishmentService
  ) {

    this.FireTagService = FireTagService

    this.AdNotification = AdNotification
    this.ContactAgendaService = ContactAgendaService
    this.EstablishmentService = EstablishmentService
    this.$element = $element
    this.AdAvailableSn = AdAvailableSn
    this.UserService = UserService
    
    SocialAccountService.getSocialAccounts().then(accounts => {
      this.socialAccounts = []
      this.onRefreshSocialAccountsSuccess()
    })
  }

  private onRefreshSocialAccountsSuccess () {
    this.UserService.getProfile().then(user => {
      const {
        profile: {language = 'es'},
        isAdmin, isAdminOrCm
      } = user
      this.language = language
    
      this.permissions = {
        isAdmin,
        isAnalyst: !isAdminOrCm
      }
  
      this.initVueInstance()
      this.loading = false
      this.FireTagService.setPageView({
        title: 'Agenda de Clientes',
        path: `/${window.location.hash}`
      })
    })
  }

  private getStore () {
    let self = this;
    return new Vuex.Store({
      state: {
        locale: this.language,
        contact: {},
        tickets: [],
        notes: [],
        note: null,
        contacts: null,
        favoriteContacts: [],
        tableColumns: [],
        visibleForm: null,
        hsm: [],
        filters: {},
        permissions: this.permissions,
        totalContacts: null
      },
      getters: {
        locale: state => state.locale,
        contact: state => state.contact,
        tickets: state => state.tickets,
        notes: state => state.notes,
        note: state => state.note,
        contacts: state => state.contacts,
        favoriteContacts: state => state.favoriteContacts,
        visibleForm: state => state.visibleForm,
        tableColumns: state => ({
          all: state.tableColumns,
          enabled: state.tableColumns.filter(column => column.enabled)
        }),
        getSocialAccountById:
          _state =>
          (accountId = '') => {
            return this.socialAccounts.find(({id}) => id === accountId);
          },
        hsm: state => state.hsm,
        filters: state => state.filters,
        permissions: state => state.permissions
      },
      mutations: {
        SET_CONTACTS(state, payload) {
          state.contacts = payload;
        },
        SET_CONTACT_DETAIL(state, payload) {
          state.contact = payload;
        },
        SET_CONTACT_ACCOUNTS(state, payload) {
          state.contact.accounts = payload;
        },
        SET_CONTACT_TICKETS(state, payload) {
          state.tickets = payload;
        },
        SET_CONTACT_NOTES(state, payload) {
          state.notes = payload;
        },
        SET_FAVORITES(state, payload) {
          state.favoriteContacts = payload;
        },
        SET_VISIBLE_FORM(state, payload) {
          state.visibleForm = payload;
        },
        SET_TABLE_COLUMNS(state, payload) {
          state.tableColumns = payload;
        },
        SET_HSM(state, payload) {
          state.hsm = payload;
        },
        SET_NOTE(state, payload) {
          state.note = payload;
        },
        SET_FILTERS(state, payload) {
          state.filters = payload;
        },
        SET_TOTAL_CONTACTS(state, payload) {
          state.totalContacts = payload;
        }
      },
      actions: {
        async getFullContact({commit, state}, _id = '') {
          const accounts = await self.ContactAgendaService.getSingleContact(_id);
          commit('SET_CONTACT_DETAIL', {...state.contact, accounts});
        },
        // eslint-disable-next-line prefer-arrow-callback
        async getContacts({commit, getters}, page = 1) {
          const {contacts, filters} = getters;
  
          const response = await self.ContactAgendaService.getContacts({
            ...filters,
            page
          });
  
          if (page === 1) {
            // First page - set contacts
            commit('SET_CONTACTS', response.data);
            commit('SET_TOTAL_CONTACTS', response.elements);
          } else {
            // Other pages - concat
            if (page > response.pages) {
              throw new Error('Page cannot be greater than response pages');
            }
            commit('SET_CONTACTS', [...contacts, ...response.data]);
          }
          return response;
        },
        async getFavorites({commit, getters}) {
          const response = await self.ContactAgendaService.getContacts({
            ...getters.filters,
            favorites: 1
          });
          commit('SET_FAVORITES', response.data);
        },
        viewContact({commit, dispatch}, contact) {
          commit('SET_CONTACT_DETAIL', contact);
          dispatch('setVisibleForm', false);
          if (contact._id) {
            dispatch('setContactTickets');
            dispatch('setContactNotes');
            dispatch('getFullContact', contact._id);
          }
        },
        async setContactTickets({state, commit}) {
          const tickets = await self.ContactAgendaService.getTicketsById(
            state.contact._id
          );
          commit('SET_CONTACT_TICKETS', tickets);
        },
        async setContactNotes({state, commit}) {
          const notes = await self.ContactAgendaService.getNotesById(
            state.contact._id
          );
          commit('SET_CONTACT_NOTES', notes);
        },
        toggleFavorite({state, dispatch, commit}, contact = {}) {
          const updateField = (list = []) => {
            return list.map(item => {
              if (item._id === contact._id) {
                item.favorite = !contact.favorite;
              }
  
              return item;
            });
          };
          const {contacts, favoriteContacts} = state;
  
          commit('SET_CONTACTS', updateField(contacts));
          commit('SET_FAVORITES', updateField(favoriteContacts));
  
          self.ContactAgendaService.toggleFavorite(contact._id).then(() => {
            dispatch('getFavorites');
            dispatch('setEvent', {name: 'agenda_modifica_favorito'});
          });
        },
        setVisibleForm({commit, state}, status = null) {
          const {permissions, contact} = state;
          if (status === 'contact' && permissions.isAnalyst) {
            throw new Error('Not enough permissions');
          } else if (status === 'contact' && contact.is_contactable === 0) {
            // No messageable accounts
            return;
          }
  
          commit('SET_VISIBLE_FORM', status);
        },
        sendContactForm({dispatch}, form = {}) {
          dispatch('setEvent', {name: 'agenda_contacta_cliente'});
          return self.ContactAgendaService.sendContactForm(form);
        },
        sendEditForm({dispatch}, form = {}) {
          dispatch('setEvent', {name: 'agenda_edita_cliente'});
          return self.ContactAgendaService.editContact(form);
        },
        async saveNote({commit, dispatch}, note = {}) {
          let response;
  
          if (note.note_id) {
            response = await self.ContactAgendaService.editNote(note);
          } else {
            response = await self.ContactAgendaService.addNote(note);
          }
  
          if (response) {
            dispatch('setEvent', {
              name: 'agenda_edita_nota',
              params: {type: note.created ? 'new_note' : 'edit_note'}
            });
            dispatch('setContactNotes');
            commit('SET_NOTE', null);
          }
        },
        deleteNote({dispatch}, note = {}) {
          self.ContactAgendaService.deleteNote(note.note_id).then(() => {
            dispatch('setEvent', {name: 'agenda_elimina_nota'});
            dispatch('setContactNotes');
          });
        },
        async getTableColumns({commit}) {
          let columns = await self.ContactAgendaService.getColumns();
  
          commit('SET_TABLE_COLUMNS', columns);
        },
        editTableColumns({dispatch}, columns = []) {
          self.ContactAgendaService.editColumns(columns).then(() => {
            dispatch('setEvent', {name: 'agenda_modifica_columnas'});
            dispatch('getTableColumns');
            dispatch('getContacts');
            dispatch('getFavorites');
            dispatch('checkContactBookNewFeature');
          });
        },
        copyTicketId(_context, id = '') {
          navigator.clipboard.writeText(id);
          self.AdNotification.success('success_copy_id', 'contact_agenda');
        },
        getMessagingInfo(_context, id = '') {
          return self.ContactAgendaService.getMessagingInfo(id);
        },
        setEvent(_context, event = {name: '', params: {}}) {
          self.FireTagService.setEvent(event);
        },
        async checkContactBookNewFeature() {
            try {
              const establishment = await self.EstablishmentService.getEstablishment();
              return establishment.features.contact_book_new;
            } catch (error) {
              console.error('Error checking contact book new feature:', error);
              return false;
            }
        },
      }
    });
  };

  private initVueInstance () {
    let self = this

    let i18n = new VueI18n({
      locale: this.language,
      fallbackLocale: ['en', 'pt', 'es'],
      silentTranslationWarn: true
    });

    const config = {
      el: this.$element[0].querySelector('.ng-non-bindable'),
      data() {
        return {ready: false};
      },
      store: this.getStore(),
      i18n: i18n,
      provide: {
        socialNetworkService: this.AdAvailableSn
      },
      mounted() {
        self.ContactAgendaService.getTranslations(self.language).then(translation => {
          i18n.setLocaleMessage(self.language, translation);
          this.ready = true;
        });
      }
    };
    this.vueInstance = new Vue(config);
  };
};

(module => {
  module.controller('ContactAgendaCtrl', ContactAgendaCtrl);
})(angular.module('postCenterWebClientApp'));
