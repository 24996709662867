(module => {
  adContactAgendaWrapper.$inject = [];
  function adContactAgendaWrapper() {
    return {
      restrict: 'E',
      controller: 'ContactAgendaCtrl',
      bindToController: true
    };
  }
  module.directive('adContactAgendaWrapper', adContactAgendaWrapper);
})(angular.module('postCenterWebClientApp'));
