(module => {
  'use strict';
  function departmentSelector() {
    return {
      bindToController: {
        selectedDepartment: '=',
        currentCase: '='
      },
      scope: {
        customCallback: '&'
      },
      controllerAs: 'ctrl',
      templateUrl: 'blocks/case/views/department_selector.html',
      restrict: 'E',
      replace: true,
      controller: [
        '$scope',
        '$q',
        '$filter',
        '$timeout',
        'DepartmentService',
        'UserService',
        function (
          $scope, $q, $filter, $timeout, 
          DepartmentService: DepartmentService, UserService: UserService
        ) {
          const self = this
          let inputElementDeferred = $q.defer()
          let inputElementPromise = inputElementDeferred.promise

          self.activeIndex = -1
          self.popoverOpen = false
          self.typeaheadText = ''
          self.resultElements = []
          self.filteredDepartments = []
          self.departmentChoices = []
          self.teamRemoved = false
          self.loading = false
          
          const filteredDepartmentsWatcherOff = $scope.$watchCollection(
            'ctrl.filteredDepartments',
            filteredDepartments => {
              self.activeIndex =
                filteredDepartments && filteredDepartments.length > 0 ? 0 : -1;
            }
          );
          self.getDepartmentName = _id => {
            if (!_id) {
              return null;
            }
            const department = self.departments.find(({id}) => id === _id);
            if (!department) {
              self.teamRemoved = true;
              return null;
            }
            return department.name;
          }

          Promise.all([
            UserService.getProfile(),
            DepartmentService.getDepartments()
          ]).then(([user, departments]) => {
            self.isAdmin = user.isAdmin
            self.departments = departments.filter(
              department => department.users.length > 0
            )
            self.departmentName = self.getDepartmentName(self.selectedDepartment)
          })

          const popOverWatcherOff = $scope.$watch('ctrl.popoverOpen', popoverOpen => {
            if (popoverOpen) {
              if (self.departmentChoices.length === 0) {
                self.loading = true;
              }

              self.departmentChoices = Object.keys(self.departments).map(
                key => {
                  return self.departments[key];
                }
              );

              if (self.selectedDepartment) {
                let selectedIndex = _.findIndex(
                  self.departmentChoices,
                  hasTheSelectedId
                );
                if (selectedIndex > -1) {
                  let departmentToInsert =
                    self.departmentChoices[selectedIndex];
                  self.departmentChoices.splice(selectedIndex, 1);
                  self.departmentChoices.sort(compareByName);

                  self.departmentChoices.splice(0, 0, departmentToInsert);
                } else {
                  self.departmentChoices.sort(compareByName);
                }
              } else {
                self.departmentChoices.sort(compareByName);
              }

              self.departmentChoices.push({
                $noteam: true,
                name: $filter('translate')('CASECONTROL_DEP_NO_ASSIGNED')
              });

              inputElementPromise.then(inputElement => {
                $scope.$applyAsync(() => {
                  $timeout(() => {
                    inputElement.focus();
                  }, 10);
                });
              });
              self.loading = false;
            } else {
              $scope.$applyAsync(() => {
                self.typeaheadText = '';
                self.filteredDepartments = [];
              });
              inputElementDeferred = $q.defer();
              inputElementPromise = inputElementDeferred.promise;
            }

            function compareByName(dep1, dep2) {
              if (dep1.name === dep2.name) {
                return 0;
              } else if (dep1.name > dep2.name) {
                return 1;
              } else {
                return -1;
              }
            }
          });

          const selectedDepartmentWatcherOff = $scope.$watch('ctrl.selectedDepartment', (newValue, oldValue) => {
            if (newValue !== oldValue) {
              self.getDepartmentName(newValue);
            }
          });

          $scope.$on('$destroy', function () {
            filteredDepartmentsWatcherOff();
            popOverWatcherOff();
            selectedDepartmentWatcherOff();
          });

          self.selectMatch = function (department) {
            self.popoverOpen = false;
            self.loading = true;
            // $q.when(self.selectCallback({department})).finally(() => {
            //   self.loading = false;
            // });
            $scope.customCallback({department});
            self.departmentName = department.name;
            self.teamRemoved = false;
            self.selectedDepartment = department.id;
          };
          self.handleInput = function (e) {
            let DOWN = 40,
              UP = 38,
              ENTER = 13,
              ESC = 27;
            switch (e.which) {
              case DOWN:
                self.activeIndex = keepInBoundaries(self.activeIndex + 1);
                handleScroll();
                e.preventDefault();
                break;
              case UP:
                self.activeIndex = keepInBoundaries(self.activeIndex - 1);
                handleScroll();
                e.preventDefault();
                break;
              case ENTER:
                self.selectMatch(self.activeIndex);
                e.preventDefault();
                e.stopPropagation();
                break;
              case ESC:
                self.popoverOpen = false;
                e.preventDefault();
                e.stopPropagation();
                break;
            }
            function keepInBoundaries(index) {
              let filteredDepartments = self.filteredDepartments || [];
              if (filteredDepartments.length === 0) {
                return -1;
              }
              if (index < 0) {
                return 0;
              }
              if (index >= filteredDepartments.length) {
                return filteredDepartments.length - 1;
              }
              return index;
            }
          };

          self.setInputElem = function (element) {
            inputElementDeferred.resolve(element);
          };

          self.setIthResultElem = function (index, element) {
            self.resultElements[index] = element;
          };

          self.setContainerElem = function (element) {
            self.containerElement = element;
          };

          function hasTheSelectedId(department) {
            return department.id === self.selectedDepartment;
          }

          function getIthResultDOMElement(index) {
            return self.resultElements[index];
          }

          function getResultContainerDOMElement() {
            return self.containerElement;
          }

          function handleScroll() {
            let container = getResultContainerDOMElement(),
              containerHeight = container.height(),
              result = getIthResultDOMElement(self.activeIndex),
              resultHeight = result.outerHeight(),
              resultOffset = result.offset().top - container.offset().top,
              scrollUpwardsDelta = -resultOffset,
              scrollDownwardsDelta =
                resultOffset + resultHeight - containerHeight;

            if (scrollUpwardsDelta > 0) {
              container.scrollTop(container.scrollTop() - scrollUpwardsDelta);
            }

            if (scrollDownwardsDelta > 0) {
              container.scrollTop(container.scrollTop() + scrollDownwardsDelta);
            }
          }
        }
      ]
    };
  }
  module.directive('departmentSelector', departmentSelector);
})(angular.module('postCenterWebClientApp'));
