'use strict';

function initialText(sn, uname) {
  if (sn === 'twitter') {
    return '@' + uname + ' ';
  }
  return '';
}

var app = angular.module('postCenterWebClientApp');
app.directive('messageControls', function () {
  return {
    scope: {
      message: '=',
      emitReplyTo: '&',
      noReplyButton: '&',
      noConversationButton: '&',
      noCaseButton: '&'
    },
    templateUrl: 'blocks/message/views/message_control.html',
    restrict: 'E',
    require: '?^^caseDetail', // optional... see showConversation comments
    link: function (scope, elem, attrs, caseDetailCtrl) {
      scope.caseDetailCtrl = caseDetailCtrl;
    },
    controller: [
      '$scope',
      '$rootScope',
      'CaseDetailManager',
      'Message',
      'CaseService',
      'ColumnsService',
      'AdNotification',
      'UserService',
      'SocialAccountService',
      '$filter',
      '$timeout',
      '$element',
      function (
        $scope,
        $rootScope,
        CaseDetailManager,
        Message: MessageService,
        CaseService,
        ColumnsService,
        AdNotification,
        UserService: UserService,
        SocialAccountService: SocialAccountService,
        $filter,
        $timeout,
        $element
      ) {

        $scope.$on('$destroy', () => {
          $scope.messageDeletedListener();
          $scope.messageWatcher();
          $scope.canReplyWatcher();
          $scope.customDataBlockCancelListener();
        });

        $scope.messageDeletedListener = $rootScope.$on('message:isDeleted', function (event, messageData) {
          if (
            angular.isDefined(messageData.reply_to_msg) &&
            $scope.message.mid === messageData.reply_to_msg
          ) {
            $scope.toggleShowExtra(null);
          }
        });

        if (angular.isUndefined($scope.message)) {
          return;
        }
        var message = $scope.message;
        $scope.messageWatcher = $scope.$watch('message', function (message) {
          var CMInitials;
          if (message.current_assignment) {
            CMInitials = CaseService.getInitials(
              message.current_assignment.name
            );
            message.current_assignment.initials = CMInitials;
          }
          if (message.first_assignment) {
            CMInitials = CaseService.getInitials(message.first_assignment.name);
            message.first_assignment.initials = CMInitials;
          }

          var publisher = message['publisher'];
          if (publisher) {
            var PublisherInitials = CaseService.getInitials(publisher.name);
            publisher.initials = PublisherInitials;
          }
          message['publisher'] = publisher;
        });
        UserService.getProfile().then(user => {
          $scope.canEditColumns = user.profile.enable_edit_columns;
          $scope.canReply = user.isAdminOrCm;
          $scope.canViewStats = user.isAnalystOrAdmin;
          $scope.canInteract = $scope.canReply;
        });

        $scope.canReplyWatcher = $scope.$watch('$scope.canReply', function () {
          $scope.canInteract = $scope.canReply;
        });

        $scope.showExtra = null;
        $scope.messageType = null;
        $scope.selectedAccount = {
          model: null
        };
        $scope.newComment = '';
        $scope.canCreateCase =
          !message.case_id &&
          $scope.canReply &&
          message.active &&
          !message.ours;

        SocialAccountService.getSocialAccounts().then(accounts => {
          $scope.accounts = accounts;
          if ($scope.accounts.length === 1) {
            $scope.selectedAccount = $scope.accounts[0];
          }
        });

        var sn = $scope.message.sn;
        if (sn) {
          if (sn === 'app_store') {
            $scope.canReply = false;
          }
          $scope.socialNetwork = sn.charAt(0).toUpperCase() + sn.substring(1);
        }
        if (message.author) {
          $scope.newMessage = initialText(message.sn, message.author.uname);
        }

        $scope.allowedSocialNetwork = {
          sn: $scope.message.sn
        };

        $scope.isNotClassifiable = function () {
          var isNull = $scope.messageType === null;
          var isUndef = angular.isUndefined($scope.messageType);
          return isNull || isUndef;
        };

        $scope._showConversationContext = function (message) {
          if ($scope.caseDetailCtrl) {
            $scope.caseDetailCtrl.showConversation(message);
          } else {
            // TODO: this is not working. Figure out what to do here
            // CaseDetailManager.openCaseState(
            //   makeConversationCaseObject(message)
            // );
          }
          $scope.toggleShowExtra(null);
          $scope.newMessage = initialText(message.sn, message.author.uname);
        };

        $scope.showConversation = function () {
          // TODO handle the case when this is opened from a column
          // or something
          var message = $scope.message;
          $scope._showConversationContext(message);
        };

        $scope.showCase = function () {
          $scope.toggleShowExtra(null);
          if (!$scope.caseObject) {
            CaseService.get($scope.message['case_id']).then(function (
              caseObject
            ) {
              $scope.caseObject = caseObject;
              CaseDetailManager.findAndDisplayCase($scope.caseObject);
            });
          } else {
            CaseDetailManager.findAndDisplayCase($scope.caseObject);
          }
        };

        $scope.createCase = function () {
          $scope.creatingCase = true;
          var action = 'create_case_from_message';
          var data = {
            message_id: $scope.message['resource_id']
          };

          CaseService.createFromMessage(data).then(
            function (caseObject) {
              $scope.message['case_id'] = caseObject['resource_id'];
              $scope.creatingCase = false;
              $scope.canCreateCase = false;
              AdNotification.success(200, action);
            },
            function (data) {
              AdNotification.error(data, action);
              $scope.creatingCase = false;
            }
          );
        };

        $scope.canShowExtra = function () {
          var disallowedSocialNetworks = ['email', 'whatsapp'];
          if (
            disallowedSocialNetworks.indexOf($scope.message.sn) != -1 ||
            !$scope.message.active
          ) {
            return false;
          }

          return $scope.message.kind !== 'private';
        };

        $scope.toggleShowExtra = function (toggle) {
          var message = $scope.message;
          $scope.newMessage = initialText(message.sn, message.author.uname);
          $scope.messageType = null;
          $scope.newComment = '';

          if (toggle === $scope.showExtra) {
            $scope.showExtra = null;
          } else {
            $scope.showExtra = toggle;
          }
        };

        $scope.getAssignmentName = function (assignment) {
          if (!assignment) {
            return $filter('translate')('MESSAGECONTROL_NOONE');
          } else {
            return assignment.name;
          }
        };

        $scope.replyToAction = function () {
          if ($scope.emitReplyTo()) {
            $scope.$emit('replyTo', $scope.message);
          } else {
            $scope.toggleShowExtra('answer');
          }
          if ($rootScope.email) {
            $rootScope.email.css({
              'border-radius': '',
              'border-style': '',
              'border-color': '',
              'border-width': ''
            });
          }
          $timeout(function () {
            let textarea = $('.note-editable');
            textarea.focus();
            $rootScope.email = $(`#message-${message.resource_id}`);
            if ($scope.message.sn === 'email') {
              $rootScope.email.css({
                'border-radius': '10px',
                'border-style': 'solid',
                'border-color': 'grey',
                'border-width': '0.2em'
              });
            }
          }, 50);
        };

        $scope.toggleSentiment = function () {
          var sentiment;
          var oldSentiment = $scope.message.sentiment;
          var action = 'set_sentiment_message';
          var resourceId = $scope.message['resource_id'];

          $scope.disableSentimentButton = true;

          if (oldSentiment === true) {
            sentiment = null;
          } else if (oldSentiment === false) {
            sentiment = true;
          } else {
            sentiment = false;
          }
          $scope.message.sentiment = sentiment;

          var data = {
            sentiment: sentiment
          };

          Message.setSentiment(resourceId, data).then(
            function () {
              $scope.disableSentimentButton = false;
            },
            function (data) {
              $scope.disableSentimentButton = false;
              $scope.message.sentiment = oldSentiment;
              AdNotification.error(data, action);
            }
          );
        };

        $scope.customDataBlockCancelListener = $scope.$on(
          'customDataBlock:cancel', function () {
            $scope.showExtra = null;
        });

        function makeConversationCaseObject(message) {
          return {
            _conversation: message,
            users: [message.author],
            sn: message.sn,
            comments: []
          };
        }

        $scope.createAuthorColumn = function () {
          var author = $scope.message.author;
          createUserColumn(formatColumnData(author));
        };

        function createUserColumn(data) {
          ColumnsService.postColumn(data).then(
            function (column) {
              $scope.$emit('addColumn', column);
              AdNotification.success(201, 'post_column');
            },
            function (data) {
              AdNotification.error(data, 'post_column');
            }
          );
        }

        function formatColumnData(author) {
          var name = '';
          if (author.sn === 'twitter') {
            name = '@';
          }
          if (angular.isUndefined(author.text)) {
            name = name + author.uname;
          } else {
            name = name + author.text;
          }
          var data = {
            author: {
              id: author.resource_id,
              sn: author.sn
            },
            name: name,
            column_type: 'message'
          };
          return data;
        }
      }
    ]
  };
});
