const AssignmentDetailDefault = Vue.component('AssignmentDetailDefault', {
  methods: {
    ...Vuex.mapActions(['goBack'])
  },
  template: `
    <article>
      <h3 class="pb-4">{{ $t('release.title') }}</h3>
      <p class="my-1">{{ $t('release.instructions') }}</p>
      <img :alt="$t('release.title')"
          width="68%"
           class="d-block my-3 mx-auto"
           src="images/assignment_people.svg" />
    </article>
  `
});

const AssignmentHeaderCard = Vue.component('AssignmentHeaderCard', {
  props: {
    title: {
      type: String,
      required: true
    },
    agent: {
      type: Boolean
    },
    account: {
      type: Object,
      required: true
    }
  },
  computed: {
    isAgent() {
      return this.agent ? 'details--executive-basic' : 'details--channel-basic';
    }
  },
  methods: {
    ...Vuex.mapActions(['goBack'])
  },
  template: `<section :class="isAgent">
          <h3>
          {{ account.name }}
          <Button
            type="link pull-right"
            :padded="false"
            @clicked="goBack({ category: 'reset' })">
              <i class="fa fa-times-circle fa-lg pull-right" />
          </Button>
        </h3>
        <p>{{ title }}</p>
        <Avatar
          :src="account.avatar"
          :centered="true"
          :bordered="false"
          size="big"
        />
        <slot name="cardContent" />
  </section>`
});
const AssignmentList = Vue.component('AssignmentList', {
  props: {
    title: {
      type: String,
      required: true
    },
    total: {
      type: Number,
      default: 0
    },
    list: {
      type: Array,
      required: true
    },
    onAssignChange: {
      type: Function,
      required: true
    }
  },
  template: `<section>
    <h3>{{ title }} ({{ total }})</h3>
    <ul class="assignments--list-vertical assignments--list-nowrap">
            <li v-for="elem in list"
                class="d-flex mb-1">
              <label class="col-sm-2 mr-1">
                <Avatar
                  :channel="elem.sn"
                  :title="elem.name"
                  :src="elem.avatar"
                />
              </label>
              <ToggleAssign
                :agent="elem"
                :assignHandler="onAssignChange" />
            </li>
        </ul>
  </section>`
});
const SaveConfig = Vue.component('SaveConfig', {
  props: {
    onSave: {type: Function, required: true}
  },
  template: `<div class="fixed-bottom pt-1">
        <button
          type="button"
          class="btn btn-success ads-button ads-button--primary d-block mx-auto w-50"
          @click="onSave"
        >{{ $t('buttons.saveAgents') }}</button>
      </div>`
});
const SaveConfigAssign = Vue.component('SaveConfigAssign', {
  props: {
    toggle: {
      type: Boolean
    },
    onSave: {
      type: Function,
      required: true
    },
    onCancel: {
      type: Function,
      required: true
    }
  },
  template: `<div class="mx-auto d-flex flex-end confirm-changes"
            :class="toggle ? 'fade-in':''"
            v-show="toggle">
            <button type="button"
              @click="onCancel"
              class="btn ads-button ads-button--link mt-1">
                {{ $t('buttons.cancel') }}
            </button>
            <button type="button"
              @click="onSave"
              class="btn ads-button ads-button--primary mt-1">
                {{ $t('buttons.saveAccountConfig') }}
            </button>
          </div>`
});
const AssignmentDetailMode = Vue.component('AssignmentDetailMode', {
  props: {
    assignationMode: {
      type: Number,
      default: 0
    },
    updateMode: {
      type: Function,
      required: true,
      default: null
    }
  },
  data() {
    return {
      assignmentModes: [
        {
          value: 0,
          label: this.$t('assignmentOptionMode.defaultSetting')
        },
        {
          value: 1,
          label: this.$t('assignmentOptionMode.onlyFIFO')
        },
        {
          value: 2,
          label: this.$t('assignmentOptionMode.onlyLIFO')
        }
      ]
    };
  },
  methods: {
    notifyChange(assignationMode) {
      this.updateMode(assignationMode);
    }
  },
  template: `<p class="d-flex space-between mt-1">
    <span class="align-self-center">{{ $tc('assignmentMode') }}</span>
    <select class="form-control w-50" v-model="assignationMode" @change="notifyChange(assignationMode)">
      <option v-for="assignment in assignmentModes" :value="assignment.value" :key="assignment.value">{{ assignment.label }}</option>
    </select>
  </p>
  `
});
