(module => {
    'use strict';
    ContactAgendaNewCtrl.$inject = 
        ['$scope', 
        'FRONTEND_VUE_URL', 
        '$sce', 
        'AdNotification', 
        'UserService', 
        'AdRequest'];
  module.controller('ContactAgendaNewCtrl', ContactAgendaNewCtrl);

  function ContactAgendaNewCtrl(
    $scope, FRONTEND_VUE_URL, $sce, AdNotification,
     UserService, AdRequest
    ) {
    const vm = this;

    vm.$onInit = () => {
        window.addEventListener('message', handleIframeMessage);
    };

    vm.$onDestroy = () => {
        window.removeEventListener('message', handleIframeMessage);
    }

    function authFn() {
      UserService.getProfile().then(user => {
        $scope.iFrameUrl = function () {
          const url = `${FRONTEND_VUE_URL}/frames/contact_agenda_new?authToken=${AdRequest.getToken()}`;
          return $sce.trustAsResourceUrl(url);
        };
      });
    }

    function handleIframeMessage ({data}) {
        if (data.type !== 'contactBookEvent') {
            return;
        }
        const {message} = data;
        const actionMap = {
            notify: notifyMessage,
            redirect: deskRedirect,
            newTab: redirectNewTab
        };
        const fun = actionMap[message.action];
        if (fun) fun(message);
    };

    function notifyMessage(message) {
        const {text: messageText, type} = message;
        if(type === 'success') {
            AdNotification.success(201, messageText);
        }
        else {
            AdNotification.error(500, messageText);
        }
    }

    function deskRedirect({url}) {
        window.location.href = url;
    }

    function redirectNewTab({url}) {
        window.open(url, "_blank")
    }

    authFn();
  }
})(angular.module('postCenterWebClientApp'));