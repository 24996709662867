'use strict';

angular.module('postCenterWebClientApp')
  .directive('dashboardKpi', [function () {
    return {
      scope : {
        title: '@',
        helpText: '@',
        kpi: '=',
        icon: '@'
      },
      templateUrl: 'blocks/analytics/views/dashboard_kpi.html',
      restrict: 'E',
      controller: ['$scope', '$uibModal', function ($scope, $uibModal) {
        var title = $scope.title;
        var helpText = $scope.helpText;
        $scope.openHelpModal = function () {
          $uibModal.open({
            templateUrl: 'blocks/analytics/views/dashboard_help_modal.html',
            size: 'sm',
            controller: ['$scope', '$uibModalInstance', '$filter', function ($scope, $uibModalInstance, $filter) {

              $scope.title = title;
              $scope.helpText = $filter('translate')(helpText);

              $scope.cancel = function() {
                $uibModalInstance.dismiss('cancel');
              };
            }],
          });
        };
      }],
      link: function($scope, $element){}
    };
  }]);
