'use strict';

interface SocialNetworkUser {
  avatar?: string
  has_custom_data: boolean
  influencer: boolean
  klout?
  name: string
  resource_id: string
  sn: SocialNetwork
  uid: string
  uname: string
}

interface Case {
    answered: boolean
    closed?
    commented: boolean
    created: number
    current_assignment: CmSummary
    establishment_users: SocialNetworkUser[]
    has_custom_data: boolean
    identifier: number
    important: boolean
    in_sla_time: boolean
    is_addressed: boolean
    is_closed: boolean
    last_author: string
    last_content: string
    last_content_kind: MessageKind
    last_message_time: number
    sla_expiration_time: number
    messages_unread: number
    no_manual_reopen: boolean
    resource_id: string
    show_content: boolean
    sn: SocialNetwork
    title: string
    updated_time: number
    users: SocialNetworkUser[]
    workflow_status: WorkflowStatus
}

class CaseService {

  API_URL = '/api/v1';

  AdRequest: AdRequestService
  adDateFormatter
  adConstants

  constructor(AdRequest, adDateFormatter, adConstants) {
    this.AdRequest = AdRequest
    this.adDateFormatter = adDateFormatter
    this.adConstants = adConstants

    
    Math.trunc =
      Math.trunc ||
      function (num) {
        if (isNaN(num)) {
          return NaN;
        }
        if (num > 0) {
          return Math.floor(num);
        }
        return Math.ceil(num);
      }
  }

  newCase(data) {
    return this.AdRequest.post(`${this.API_URL}/cases/new/`, data)
  }

  get(caseID): Promise<Case> {
    return this.AdRequest.get(`${this.API_URL}/case/${caseID}/`)
  }

  getCaseResume(userID) {
    return this.AdRequest.get(`${this.API_URL}/case/resume/${userID}/`)
  }

  getCaseHistory(caseID) {
    return this.AdRequest.get(`${this.API_URL}/case/history/${caseID}/`)
  }

  assignCM(caseID, data, config) {
    return this.AdRequest.put(`${this.API_URL}/case/${caseID}/`, data, config)
  }

  assignDepartment(caseID, data) {
    return this.AdRequest.post(
      `${this.API_URL}/case/${caseID}/set_department/`,
      data
    )
  }

  addComment(caseID, data, config) {
    return this.AdRequest.put(`${this.API_URL}/case/${caseID}/`, data, config)
  }

  addCustomData(caseID, data, config) {
    return this.AdRequest.put(`${this.API_URL}/case/${caseID}/`, data, config)
  }

  getCrmData(caseID, data) {
    return this.AdRequest.post(`${this.API_URL}/case/${caseID}/crm`, data)
  }

  sendCrmData(caseID, data) {
    return this.AdRequest.get(`${this.API_URL}/case/${caseID}/crm`, data)
  }

  sendCrmDataCustom(caseID, data, config) {
    return this.AdRequest.put(`${this.API_URL}/case/${caseID}/crm`, data)
  }

  setSentiment(caseID, data, config) {
    return this.AdRequest.put(`${this.API_URL}/case/${caseID}/`, data, config)
  }

  close(caseID, config) {
    const data = {
      is_closed: true
    }
    return this.AdRequest.put(`${this.API_URL}/case/${caseID}/`, data, config)
  }

  reopen(caseID, config) {
    const data = {
      is_closed: false
    }
    return this.AdRequest.put(`${this.API_URL}/case/${caseID}/`, data, config)
  }

  updateWorkflowStatus(caseID, workflowStatus, config) {
    const data = {
      workflow_status: workflowStatus,
      is_closed: workflowStatus.kind === this.adConstants.WFS_KIND_CLOSED
    }
    return this.AdRequest.put(`${this.API_URL}/case/${caseID}/`, data, config)
  }

  sendSurvey(caseID, config) {
    const data = {
      ticket_id: caseID
    }
    return this.AdRequest.post(`${this.API_URL}/survey`, data, config)
  }

  important(caseID, status, config) {
    const data = {
      important: status
    }
    return this.AdRequest.put(`${this.API_URL}/case/${caseID}/`, data, config)
  }

  getNewerMessages(caseID, newerThan) {
    const path = newerThan
      ? `${this.API_URL}/case/messages/${caseID}/?newer_than=${newerThan}`
      : `${this.API_URL}/case/messages/${caseID}/`
    return this.AdRequest.get(path)
  }

  getOlderMessages(caseID, olderThan) {
    const path = olderThan
      ? `${this.API_URL}/case/messages/${caseID}/?older_than=${olderThan}`
      : `${this.API_URL}/case/messages/${caseID}/`
    return this.AdRequest.get(path)
  }

  getMessages(caseID) {
    return this.AdRequest.get(`${this.API_URL}/case/messages/${caseID}/`)
  }

  getPreviousCase(caseID) {
    return this.AdRequest.get(`${this.API_URL}/case/previous/${caseID}/`)
  }

  getCustomDataConflicts(caseID1, caseID2) {
    return this.AdRequest.get(`${this.API_URL}/case/conflicts/${caseID1}/${caseID2}/`)
  }

  merge(masterCaseID, absorbedCaseId) {
    return this.AdRequest.post(
      `${this.API_URL}/case/merge/${masterCaseID}/${absorbedCaseId}/`
    )
  }

  createFromMessage(data) {
    return this.AdRequest.post(`${this.API_URL}/cases/`, data)
  }

  getInitials(fullName = '') {
    if (!fullName) {
      return ''
    }
    let initials = ''
    const words = fullName.split(' ')
    words.forEach(word => {
      initials += word.charAt(0).toUpperCase()
    })
    return initials
  }

  getDepartment(caseID) {
    return this.AdRequest.get(`${this.API_URL}/case/${caseID}/set_department/`)
  }

  escalate(caseID, data, config) {
    return this.AdRequest.post(
      `${this.API_URL}/case/escalation/${caseID}/`,
      data,
      config
    )
  }

  setFormattedDates(caseObject) {
    const {
      is_closed: isClosed,
      closed,
      created,
      updated_time: updatedTime
    } = caseObject

    const _created = Math.trunc(created)
    const _updated = Math.trunc(updatedTime)

    caseObject.createdFormatted = this.adDateFormatter.publicationDate(_created)
    caseObject.createdFormattedL = this.adDateFormatter.publicationDateLong(_created)
    caseObject.createdFormattedM = this.adDateFormatter.publicationDateMedium(_created)
    caseObject.updatedFormatted = this.adDateFormatter.publicationDate(_updated)
    caseObject.updatedFormattedL = this.adDateFormatter.publicationDateLong(_updated)
    caseObject.updatedFormattedM = this.adDateFormatter.publicationDateMedium(_updated)

    if (isClosed) {
      caseObject.closedFormatted = this.adDateFormatter.publicationDate(closed)
      caseObject.closedFormattedL = this.adDateFormatter.publicationDateLong(closed)

      const closeTimeSeconds = closed - created
      const closeTime = this.adDateFormatter.responseTime(closeTimeSeconds)
      caseObject['close_time'] = closeTime
    }
  }
}

angular
  .module('postCenterWebClientApp')
  .service('CaseService', CaseService)
