// Common constants values
angular.module('postCenterWebClientApp').constant('adConstants', {
  // Workflow Status Kinds
  WFS_KIND_OPENED: 0,
  WFS_KIND_CLOSED: 1,

  // Workflow Status Categories
  WFS_CATEGORY_OPENED: 0,
  WFS_CATEGORY_IN_PROGRESS: 1,
  WFS_CATEGORY_CLOSED: 2,
  WFS_CATEGORY_IGNORED: 3,

  ErrorLevel: {
    NONE: 1,
    INFO: 2,
    WARNING: 3,
    DANGER: 4
  }
});
