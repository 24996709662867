const EpaKpiOptions = Vue.component('EpaKpiOptions', {
  props: {
    filters: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      agentLimit: 5,
      rangesDates: ['day', 'week', 'month'],
      agentLimits: [5, 10]
    };
  },
  computed: {
    ...Vuex.mapGetters('epaAnalytics', ['loading']),
    getMetric() {
      return this.filters.metric;
    },
    isAgent() {
      const {query_kind} = this.getMetric;
      return query_kind === 'agent';
    },
    renderAs() {
      const {render} = this.getMetric;
      return render === 'table' ? 2 : 1;
    }
  },
  methods: {
    ...Vuex.mapActions('epaAnalytics', [
      'getCsvData',
      'getChartByDate',
      'getChartByAgents',
      'getChartByRender'
    ]),
    setLimitAgent() {
      this.getChartByAgents({limit: this.agentLimit});
    },
    viewAs() {
      const {query_kind, render: lastRender} = this.getMetric;
      const render = lastRender === 'table' ? 'graph' : 'table';
      this.getChartByRender({render, query_kind});
    },
    viewAll() {
      this.getChartByAgents({
        metric: {render: 'table'},
        limit: null
      });
    }
  },
  template: `<div class="text-center d-flex">
    <select
      class="ads-selector mr-1"
      v-if="isAgent"
      v-model="agentLimit"
      @change="setLimitAgent">
      <option v-for="option in agentLimits" :key="option" :value="option">
        {{ $t('show', {total: option}) }}
      </option>
    </select>
    <div class="d-flex btn-group mr-1" v-else>
      <button
        class="btn ads-button--secondary-variant"
        v-for="(range, index) in rangesDates"
        :key="range"
        :class="filters.date_unit === range ? 'active' : ''" 
        @click="getChartByDate(range)">
        {{ $tc('dateRange', index) }}
      </button>
    </div>
    <button class="ads-button ads-button--link" @click="viewAs">
      {{ $tc('tableView', renderAs) }}
    </button>
    <slot v-if="isAgent">
      <hr />
      <button class="ads-button ads-button--link" @click="viewAll">
        {{ $t('showAll') }}
      </button>
    </slot>
    <hr />
    <button
      class="ads-button ads-button--link"
      v-if="!loading"
      @click="getCsvData({query_kind: filters.metric.query_kind, title: title})">
      {{ $t('download') }}
    </button>
    <Loader v-if="loading" size="2x" />
  </div>`,
  i18n: {
    messages: {
      es: {
        tableView: 'Ver como tabla | ver como gráfico',
        show: 'Mostrar {total}',
        dateRange: 'Día | Semana | Mes',
        showAll: 'Ver todos los ejecutivos',
        download: 'Descargar'
      },
      en: {
        tableView: 'View as table | View as graph',
        show: 'Show {total}',
        dateRange: 'Day | Week | Month',
        showAll: 'Show all agents',
        download: 'Download'
      },
      pt: {
        tableView: 'Ver como tabla | ver como gráfico',
        show: 'Mostrar {total}',
        dateRange: 'Dia | Semana | Mês',
        showAll: 'Ver todos os ejecutivos',
        download: 'Baixar'
      }
    }
  }
});
