(function (module) {
  'use strict';
  caseSideView.$inject = [];
  function caseSideView() {
    return {
      templateUrl: 'blocks/case/views/case_side_view.html',
      restrict: 'E',
      scope: {
        caseObject: '=caseObject',
        userObject: '=userObject',
        detailOf: '=detailOf',
        sideViewMenuOption: '=sideViewMenuOption'
      },
      controller: 'caseSideViewCtrl',
      bindToController: true,
      controllerAs: 'vm',
      require: ['caseSideView', '^^caseDetail', '^^adVerticalSeparator'],
      link: function (scope, elem, attrs, ctrls) {
        var vm = ctrls[0];
        var caseDetailCtrl = ctrls[1];
        var adVerticalSeparatorCtrl = ctrls[2];
        caseDetailCtrl.setSideViewCtrl(vm);
        vm.adVerticalSeparatorCtrl = adVerticalSeparatorCtrl;
      }
    };
  }
  module.directive('caseSideView', caseSideView);
})(angular.module('postCenterWebClientApp'));
