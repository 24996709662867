(module => {
  'use strict';
  module.directive('assignTicketsMassive', assignTicketsMassive);
  function assignTicketsMassive() {
    return {
      templateUrl: 'blocks/admin/views/massive_actions/re_assign_tickets.html',
      restrict: 'E',
      controller: 'reAssignTicketsCtrl',
      controllerAs: 'vm'
    };
  }
})(angular.module('postCenterWebClientApp'));
