angular
  .module('postCenterWebClientApp')
  .directive('botcenterConfiguration', () => {
    return {
      templateUrl: 'blocks/admin/views/integration_config/botcenter.html',
      restrict: 'E',
      scope: {
        config: '='
      },
      controller: [
        '$scope',
        '$rootScope',
        'AdRequest',
        '$filter',
        'BusinessRulesService',
        'BotService',
        '$q',
        function(
          $scope,
          $rootScope,
          AdRequest,
          $filter,
          BusinessRulesService,
          BotService,
          $q
        ) {
          $scope.availableTriggers = [rules.triggers.MessageTrigger];

          $scope.availableConditions = [
            rules.conditions.SocialAccountCondition.identifier,
            rules.conditions.CaseTypificationCondition.identifier,
            rules.conditions.MessageKeywordsCondition.identifier,
            rules.conditions.MessageMatchesRegexCondition.identifier,
            rules.conditions.InBusinessTimeCondition.identifier,
            rules.conditions.NotInBusinessTimeCondition.identifier,
            rules.conditions.InWeekdayCondition.identifier,
            rules.conditions.NotAssignedToCMCondition.identifier,
            rules.conditions.InDateTimeRangeCondition.identifier,
            rules.conditions.InTimeRangeCondition.identifier,
            rules.conditions.TicketNotAddressedCondition.identifier,
            rules.conditions.TicketNotOnDerivationCondition.identifier
          ];

          $scope.actionTypes = [
            rules.actions.StartBotSessionAction.identifier,
            rules.actions.EndBotSessionAction.identifier
          ];

          $scope.bots = $rootScope.bots;
          $scope.rules = [];

          BusinessRulesService.get().then(rules => {
            $scope.rules = rules.filter(rule => {
              return $scope.actionTypes.includes(rule.actions[0].identifier);
            });
          });

          $scope.addBot = function() {
            $scope.bots.push(new BotcenterBotConfig());
          };

          $scope.addRule = function() {
            $scope.rules.push(rules.BusinessRule.createEmptyRule());
          };

          $scope.saveBots = function() {
            $q.all(
              $scope.bots.map(bot => {
                return BotService.save(bot).then(savedBot => {
                  return savedBot;
                });
              })
            ).then(savedBots => {
              $scope.bots = savedBots;
              $rootScope.bots = savedBots;
            });
          };

          $scope.saveBotcenterConfig = function() {
            var data = {
              active: $scope.config.active,
              token: $scope.config.token
            };
            AdRequest.post('/api/v1/botcenter/config', data)
              .then($scope.saveBots)
              .then(
                () => {
                  $rootScope.$emit(
                    'successMessage',
                    $filter('translate')('ADMIN_INTEGRATION_SAVE_SUCCESS')
                  );
                  // $scope.config.showing = false;
                },
                () => {
                  $rootScope.$emit(
                    'errorMessage',
                    $filter('translate')('ADMIN_INTEGRATION_SAVE_ERROR')
                  );
                }
              );
          };
        }
      ]
    };
  });
