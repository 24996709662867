'use strict';

angular
  .module('postCenterWebClientApp')
  .filter('onlineStatusFilter', OnlineStatusFilter)

OnlineStatusFilter.$inject = ['$filter'];

function OnlineStatusFilter($filter) {

  return function (onlineStatus, format) {
    if (format === "cssClass") {
      return cssClass(onlineStatus);
    } else {
      return humanText(onlineStatus);
    }
  };

  function humanText(onlineStatus) {
    switch(onlineStatus) {
      case 0:
        return $filter('translate')('COS_ADMIN_OFFLINE');
      case 1:
        return $filter('translate')('COS_ADMIN_IDLE');
      case 2:
        return $filter('translate')('COS_ADMIN_ONLINE');
      case 'offline':
        return $filter('translate')('COS_ADMIN_OFFLINE');
      case 'idle':
        return $filter('translate')('COS_ADMIN_IDLE');
      case 'online':
        return $filter('translate')('COS_ADMIN_ONLINE');
      default:
        return onlineStatus;
    };
  };

  function cssClass(onlineStatus) {
    switch(onlineStatus) {
      case 0:
        return "os-offline";
      case 1:
        return "os-idle";
      case 2:
        return "os-online";
      case 'offline':
        return "os-offline";
      case 'idle':
        return "os-idle";
      case 'online':
        return "os-online";
      default:
        return onlineStatus;
    };
  };
};
