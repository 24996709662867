(module => {
  'use strict';
  SocialAccount.$inject = [
    '$http',
    '$rootScope',
    'AdNotification',
    'adAccountStatus',
    '$state'
  ];
  function SocialAccount(
    $http,
    $rootScope,
    AdNotification,
    adAccountStatus,
    $state
  ) {
    this.getCountryCodes = function () {
      return $http.get('data/country_codes.json');
    };
    this.getSocialAccountsOptions = function (sn, type) {
      let options = [];
      angular.forEach($rootScope.accounts, function (socialAccount) {
        if (socialAccount.type === type && socialAccount.sn === sn) {
          options.push({
            label: socialAccount.username,
            value: socialAccount.id
          });
        }
      });
      return options;
    };

    this.getPrettyChannelName = function (value = '') {
      const dictionary = {
        twitter: 'Twitter',
        facebook: 'Facebook',
        instagram: 'Instagram',
        chat: 'Chat',
        email: 'Email',
        app_store: 'App Store',
        play_store: 'Play Store',
        whatsapp: 'Whatsapp'
      };

      return dictionary[value];
    };

    this.parseSocialAccounts = function (accounts = [], forceAll = false) {
      return accounts
        .filter(account => account.is_connected || forceAll)
        .map(account => {
          const newAccount = Object.assign(account, {
            sn: account.social_network,
            selected: false,
            name: account.visual_name,
            avatar: account.avatar_url,
            type: account.social_type,
            monitored: account.is_monitored,
            is_connected: account.is_connected
          });
          adAccountStatus.set(newAccount);
          return newAccount;
        });
    };

    this.onChannelConnected = function (maxAccounts, allowRedirect = true) {
      AdNotification.success(201, 'login_social_account');
    };
  }

  module.service('SocialAccount', SocialAccount);
})(angular.module('postCenterWebClientApp'));
