'use strict';

var app = angular.module('postCenterWebClientApp');
app.controller('SettingsCtrl', function(
  $scope,
  $rootScope,
  AdNotification,
  InitialData,
  $translate,
  localStorageService,
  AdModal,
  FireTagService,
  StylesService,
  AuthService: AuthService,
  EstablishmentService: EstablishmentService,
  AppStateService: AppStateService,
) {
  FireTagService.setPageView({
    title: 'Mi perfil',
    path: `/${window.location.hash}`
  });

  let toDestroy: {[key: string]: (() => {}) | undefined } = {}

  StylesService.getStyles().then(styles => {
    $scope.styles = styles
  })

  $scope.navbarExpanded = localStorageService.get('navbarExpanded');
  if ($scope.navbarExpanded === null) {
    $scope.navbarExpanded = true;
  }

  $scope.navbarToggle = function() {
    $scope.navbarExpanded = !$scope.navbarExpanded;
    localStorageService.set('navbarExpanded', $scope.navbarExpanded);
  };

  toDestroy.onChildReady = $rootScope.$on('SIDEBAR_READY', () => {
    const data = {
      settingsTab: $rootScope.settingsTab,
    }
    $rootScope.$emit('SETTINGS_GOT_INITIAL_DATA', data);
  });

  $scope.$on('$destroy', () => {

    if (toDestroy.onChildReady) {
      toDestroy.onChildReady();
    }

  });

  function redirectIfRequired(trialStatus: TrialStatus) {
    const isEmpty = trialStatus.days_remaining === null || trialStatus.days_remaining === undefined;
    const isExpired = !isEmpty && trialStatus.days_remaining <= 0;
    const inBilling = AppStateService.currentState().isBillingState();
    if (isExpired && !inBilling) {
      AppStateService.goToInbox()
    }
  }

  function authFn(establishment: Establishment, user: ExtendedUser) {
      const {
        isAdmin, first_name, last_name,
        profile: {language}
      } = user;
      $scope.establishment = establishment.name
      $scope.username = `${first_name} ${last_name}`
      $scope.loader.show = false
      $translate.use(language)
      $scope.isAdmin = isAdmin
      EstablishmentService.getTrialStatus().then(trialStatus => {
        redirectIfRequired(trialStatus);
      })
  }

  function refreshSettings() {
    InitialData.refreshSettings()
      .then(results => authFn(results[0], results[1]))
      .catch(function(exception) {
        AdNotification.error(exception, 'loading_manage_account');
      });
  }

  $scope.openShortcutModal = function() {
    var modalConfig = {
      template: 'blocks/crm/views/shortcut_modal.html'
      //controller: 'CaseDetailCtrl',
      //size:' ',
    };
    AdModal.build(modalConfig);
  };

  function init() {
    $scope.username = '';
    $scope.establishment = $rootScope.establishment;

    $scope.loader = {
      show: true,
      message: 'Loading the awesome...'
    };

    refreshSettings()
  }

  init();
});
