'use strict';

var app = angular.module('postCenterWebClientApp');
app.service('ColumnsOrder', function (AdRequest, $timeout, $rootScope) {
  var path = '/api/v1/columns/order/',
    pendingOrders = null,
    inCooldown = false;

  this.updateOrders = function (columns){
    var newOrders = {};
    angular.forEach(columns, function(column){
      newOrders[column.resource_id] = column.order;
    });
    pendingOrders = newOrders;
    $rootScope.$emit('columnUpdateOrder', newOrders)
    if(!inCooldown){
      doRequest();
    }
  }

  function doRequest(){
    inCooldown = true;
    return AdRequest.put(path, pendingOrders).then(function(response){
      pendingOrders = null;
      return response;
    }).finally(function(){
      $timeout(function(){
        inCooldown = false;
        if(pendingOrders !== null){
          doRequest();
        }
      }, 1000);
    });
  }
});
