'use strict';

angular.module('postCenterWebClientApp')
  .directive('adVerticalSeparatorLeft', function(){
    return {
      restrict: '',
      transclude: true,
      require: ['adVerticalSeparatorLeft', '^^adVerticalSeparator'],
      bindToController: {
        'min': '&'
      },
      controllerAs: 'adVerticalSeparatorLeftCtrl',
      controller: ['$transclude', function($transclude){
        this.transcludeFn = $transclude;
      }],
      link: function(scope, element, attrs, controllers){
        var thisCtrl = controllers[0], parentCtrl = controllers[1];
        parentCtrl.setupLeftPane(thisCtrl);
      }
    };
  });