Vue.component('ad-contact-agenda', {
  data() {
    return {settingsModalStatus: false, firstLoad: false, contactBookNewEnabled: false};
  },
  computed: {...Vuex.mapGetters(['contacts', 'contact', 'locale'])},
  methods: {
    ...Vuex.mapActions(['getContacts', 'getFavorites', 'getTableColumns', 'checkContactBookNewFeature']),
    getTableColumnWidth() {
      // If viewing some Contact details, shrink the column (md and up)
      return this.contact?._id ? 'col-md-6' : 'col-md-12';
    },
    async initializeComponent() {
      
      await this.getContacts();
      
      this.getFavorites();
      this.getTableColumns();
      this.checkFeature();
      this.checkContactBookNewFeature();
      
    },
    toggleSettings() {
      this.settingsModalStatus = !this.settingsModalStatus;
    },
    async checkFeature() {
      try {
        const isEnabled = await this.checkContactBookNewFeature();
        this.contactBookNewEnabled = isEnabled;
      } catch (error) {
        console.error('Error al verificar el feature Contact Book New:', error);
      }
    },
  },
  created() {
    // Set initial data
    this.initializeComponent();
  },
  template: `
    <article class="contact-agenda">
      <SectionHeader
        class="mb-1"
        :title="$t('contacts')"
        support-section="https://adereso.helpkit.so/novedades/3F7uRjAARGuz68fdH4ho7E/nueva-vista-m%C3%B3dulo-%E2%80%9Cclientes%E2%80%9D-/wtWA1rXvXUR9kPEVu8rA8C"
        icon="book">
        <template v-slot:buttons>
        <a v-if="contactBookNewEnabled" href="#/contact_agenda_new/" class="btn btn-warning ads-button--primary section-header--help">
          <span class="fa fa-user"></span>
          <span class="mx-1">{{ $t('CTA.newSection') }}</span>
        </a>
          <button type="button"
                  class="btn btn-dark"
                  @click="toggleSettings()">
            <span class="fa fa-cog"></span>
            <span class="mx-1">{{ $t('CTA.settings')}}</span>
            <span class="fa fa-chevron-down"></span>
          </button>
        </template>
        <template v-slot:subheader>
          <contact-agenda-filters class="py-1 contact-agenda--top-bar" />
        </template>
      </SectionHeader>

      <contact-agenda-settings v-if="settingsModalStatus"
                               @closeSettings="toggleSettings()" />

      <div class="px-2">
        <section class="contact-agenda--body w-100 row">
          <Loader v-if="firstLoad" size="2x" class="m-2"/>
          <div :class="getTableColumnWidth()">
            <contact-agenda-tables v-if="contacts" />
          </div>
          <contact-single v-if="contact._id" class="col-md-6" />
        </section>
      </div>
    </article>
  `
});

Vue.component('favorite-heart', {
  props: {
    favorite: {type: Boolean, default: false}
  },
  template: `
    <button type="button"
            class="btn btn-link p-0">
      <span class="fa fa-heart" :class="{'favorite': favorite}"></span>
    </button>
  `
});

Vue.component('contact-button', {
  props: {
    hide: {type: Number},
    clear: {type: Boolean, default: true}
  },
  methods: {
    ...Vuex.mapActions(['setVisibleForm'])
  },
  computed: {
    ...Vuex.mapState(['contact', 'permissions']),
    isDisabled() {
      const {isAnalyst} = this.permissions;

      return this.hide === 0 || isAnalyst;
    },
    tooltip() {
      if (this.$attrs.is_contactable === 0) {
        return this.$t('noResults.noAccounts');
      } else if (this.isDisabled) {
        return this.$t('forms.noPermission');
      }

      return '';
    }
  },
  template: `
    <div :title="tooltip" v-if="hide !== 0">
      <button type="button"
              class="btn"
              :class="[clear ? 'btn-link p-0' : 'ads-button ads-button--primary d-flex align-center']"
              @click="setVisibleForm('contact')"
              :disabled="isDisabled">
        <span class="fa fa-paper-plane mr-1" :class="{'text-info': clear}"></span>
        {{ $t('CTA.contact') }}
      </button>
    </div>
  `
});

Vue.component('filter-item', {
  props: {
    active: {type: Boolean},
    callback: {type: Function, default: () => {}},
    text: {type: String, default: ''}
  },
  template: `
    <button type="button"
            class="btn btn-rounded"
            :class="[active ? 'btn-teal' : 'btn-secondary']"
            @click="callback()">
      {{ text }}
    </button>
  `
});

Vue.component('contact-agenda-filters', {
  data() {
    return {
      loading: false,
      debouncedQuery: debounce(this.applyFilters, 1000),
      expression: '',
      filters: {
        all: true,
        influencer: false,
        new: false,
        has_custom_data: false
      }
    };
  },
  methods: {
    ...Vuex.mapActions(['getContacts', 'getFavorites']),
    ...Vuex.mapMutations(['SET_FILTERS']),
    applyFilters() {
      const queryString = Object.entries(this.filters).reduce(
        (query, field) => {
          const [key, value] = field;
          if (value) {
            query[key] = 1;
          }
          return query;
        },
        {}
      );

      // Backend parses non-typified clients as 0 instead of 1
      // So this check is required
      if (queryString.has_custom_data) {
        queryString.has_custom_data = 0;
      }

      queryString.expression = this.expression;

      this.SET_FILTERS(queryString);
      this.loading = true;

      Promise.allSettled([this.getContacts(), this.getFavorites()]).finally(
        () => (this.loading = false)
      );
    },
    setFilter(filter) {
      if (filter === 'all') {
        // Overwrite all filters
        Object.keys(this.filters).forEach(key => (this.filters[key] = false));
      } else {
        this.filters[filter] = !this.filters[filter];
      }

      this.filters.all = filter === 'all';
      this.debouncedQuery();
    }
  },
  computed: {
    ...Vuex.mapGetters(['contacts', 'tableColumns']),
    filterItems() {
      const {filters} = this;
      return ['all', 'influencer', 'new', 'has_custom_data'].map(filter => ({
        active: filters[filter],
        onToggle: () => this.setFilter(filter),
        text: `filters.${filter}`,
        tooltip: filter === 'new' ? this.$t('forms.newClientInfo') : ''
      }));
    }
  },
  template: `
    <div class="d-flex">
      <Loader v-if="loading" size="1x" class="mr-1" />
      <div class="d-flex contact-agenda--search-bar">
        <div class="input-group w-100">
          <input type="text"
                class="form-control contact-agenda--text-input"
                :placeholder="$t('filters.searchByName')"
                v-model.trim="expression"
                maxlength="40"
                @keyup="debouncedQuery()" />
          <div class="input-group-btn">
            <button type="button"
                    class="btn btn-default"
                    @click="debouncedQuery()">
              <i class="fa fa-search"></i>
            </button>
          </div>
        </div>
      </div>

      <div class=" d-flex align-center scrollable contact-agenda--filters">
        <span class="mx-1">
          <span class="fa fa-filter"></span>
          {{ $t('CTA.filter') }}:
        </span>
        <filter-item v-for="item in filterItems"
                     :key="item.text"
                     :active="item.active"
                     :callback="item.onToggle"
                     :text="$t(item.text)"
                     class="ml-1"
                     :title="$t(item.tooltip)" />
      </div>
    </div>
  `
});

Vue.component('contact-agenda-none', {
  computed: {
    ...Vuex.mapGetters(['filters']),
    nameFilter() {
      return this.filters.expression;
    },
    filterKeys() {
      if (!this.filters) {
        return [];
      }
      return Object.keys(this.filters);
    }
  },
  template: `
    <div v-show="$store.getters.contacts" class="p-5 d-block mx-auto">

      <img
        src="images/not-found.svg"
        :alt="$t('noResults.title')"
        class="d-block mx-auto contact-agenda--img__not-found"
      />

      <h4 class="bold">{{ $t('noResults.title') }} :(</h4>
      <p>{{ $t('noResults.description') }}</p>

      <template v-if="filterKeys.length">
        <strong>{{ $t('forms.currentFilters') }}</strong>
        <ul>
          <li v-if="filters.expression">
            {{ $t('filters.expression') }}:
            {{ filters.expression }}
          </li>
          <li v-for="filter in filterKeys"
              v-show="filter !== 'all' && filter !== 'expression'"
              :key="'current-' + filter">
            {{ $t('contacts') }}
            {{ $t('filters.' + filter) }}
            <span v-if="filter === 'new'">- {{ $t('filters.whatIsNew') }}</span>
          </li>
        </ul>
      </template>
    </div>
  `
});
