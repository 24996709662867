'use strict';

var app = angular.module('postCenterWebClientApp');

app.controller('WhatsappCheckServerCtrl', function(
  $scope,
  $uibModalInstance,
  SocialAccountService,
  $interval,
  whatsapp,
  whatsappState,
  $timeout
) {
  $scope.percent = 0;
  $scope.waitingForServer = true;

  var uid = whatsapp['cc']['value'] + whatsapp['phone'];

  function getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
  }

  function getModalCallbackParams() {
    return {
      whatsapp: whatsapp,
      whatsappState: whatsappState
    };
  }

  var destroyUpdater = $interval(function() {
    SocialAccountService.connectionStatus('whatsapp', uid).then(
      function(socialAccount) {
        if (socialAccount.machine['is_available']) {
          $timeout(function() {
            $scope.percent = 100;
          });

          $timeout(function() {
            $interval.cancel(destroyUpdater);
            $scope.waitingForServer = false;
            $uibModalInstance.close(getModalCallbackParams());
          }, 3000);
        } else {
          var progress = getRandomArbitrary(3, 6);
          $scope.percent = $scope.percent + Math.round(progress);
          if ($scope.percent > 99) {
            $scope.percent = 99;
          }
        }
      },
      function(data) {}
    );
  }, 1000);

  $scope.$on('$destroy', function() {
    $interval.cancel(destroyUpdater);
  });
});
