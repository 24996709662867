(function (module) {
  'use strict';
  module.controller('CaseDetailCtrl', CaseDetailCtrl);
  CaseDetailCtrl.$inject = [
    '$scope',
    'CaseDetailManager',
    'CaseService',
    'UserService',
    '$timeout',
    'AdNotification',
    '$rootScope',
    'AdMobile',
    'CaseDetailsService',
    'SlaHandlerService',
    'EstablishmentService',
    'AppStateService'
  ];
  function CaseDetailCtrl(
    $scope,
    CaseDetailManager: CaseDetailManager,
    CaseService,
    UserService: UserService,
    $timeout,
    AdNotification,
    $rootScope,
    AdMobile,
    CaseDetailsService,
    SlaHandlerService,
    EstablishmentService: EstablishmentService,
    AppStateService
  ) {
    const vm = this;
    vm.loading = true;
    $scope.toDestroy = {}

    vm.$onInit = () => {
      const {userObject, caseObject} = vm;
      vm.sideViewCtrl = null;
      vm.caseConversationCtrl = null;
      vm.caseMetadataCtrl = null;
      refreshObjects();
      CaseDetailsService.set('caseObject', caseObject);

      vm.hasAnswerTimeSLA = (): boolean => {
        return SlaHandlerService.hasAnswerTimeSLA(vm.caseObject);
      };

      vm.isAnswerTimeSLAExpired = () : boolean => {
        return SlaHandlerService.isAnswerTimeSLAExpired(vm.caseObject);
      }

      vm.getWaitingTime = () => {
        return SlaHandlerService.getWaitingTime(vm.caseObject);
      }

      vm.getSlaConfig = () => {
        return SlaHandlerService.getSlaConfig(vm.caseObject);
      }
    };

    vm.$onDestroy = () => {
      $rootScope.$emit('caseView:closed')
      CaseDetailsService.clear();
      $rootScope.newCaseMessage = false;
    };

    const refreshObjects = () => {
      const user = vm.userObject;

      if (user !== undefined) {
        UserService.getUser(user.resource_id).then(newUserObject => {
          vm.userObject = newUserObject;
          $scope.$broadcast('userCase:hasBeenUpdated', newUserObject);
          CaseDetailsService.set('userObject', newUserObject);
          vm.loading = false;
          $scope.$apply()
        });
      }
    };

    EstablishmentService.getEstablishment().then(establishment => {
      $scope.features = establishment.features;
      $scope.ads_details = $scope.features.ads_details;
    });
    

    $scope.toDestroy.onCaseUpdated = $scope.$on('case:hasBeenUpdated', (event, caseObject, userObject) => {
      if (userObject) {
        vm.userObject = userObject;
      }

      vm.caseObject = caseObject;
      CaseDetailsService.set('caseObject', caseObject);
      $rootScope.newCaseMessage = false;
      CaseDetailsService.set('caseMessage', null);
      refreshObjects();
    });

    let inboxSelectedSub = AppStateService.inboxStateObservable.subscribe(t => {
      vm.closing = true;
      CaseDetailManager.closeCase();
      const caseWrapper = document.getElementsByClassName('selected-case');
      if (caseWrapper.length > 0) {
        caseWrapper.forEach(elem => {
          elem.classList.remove('selected-case');
        });
      }
    })

    $scope.$on('$destroy', () => {
      inboxSelectedSub.unsubscribe()
      for (const [key, destroyMethod] of Object.entries($scope.toDestroy)) {
          if (destroyMethod) {
              destroyMethod();
          }
      }
    });

    vm.creatingCase = false;

    $scope.successCopying = () => {
      AdNotification.success(200, 'case_copy_to_clipboard');
    };

    vm.setSideViewCtrl = function (aSideViewCtrl) {
      vm.sideViewCtrl = aSideViewCtrl;
    };
    vm.setCaseMetadataCtrl = function (aCaseMetadataCtrl) {
      vm.caseMetadataCtrl = aCaseMetadataCtrl;
    };

    vm.showConversation = function (message) {
      vm.sideViewCtrl.setCurrentTab('conversation');
      CaseDetailsService.set('caseMessage', message);
      $rootScope.newCaseMessage = true;
    };

    vm.createCase = function () {
      const oldCaseObject = vm.caseObject;
      vm.creatingCase = true;
      const action = 'create_case_from_message';
      const data = {
        message_id: vm.caseObject._conversation['resource_id']
      };

      CaseService.createFromMessage(data)
        .then(
          caseObject => {
            vm.caseObject = caseObject;
            vm.creatingCase = false;
            return $timeout(10);
          },
          data => {
            AdNotification.error(data, action);
            vm.creatingCase = false;
          }
        )
        .then(() => {
          vm.caseConversationCtrl.changeConversation(
            oldCaseObject._conversation
          );
          $scope.$emit('case:hasBeenUpdated', vm.caseObject);
        });
    };
  }
})(angular.module('postCenterWebClientApp'));
