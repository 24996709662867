(function (module) {
  'use strict';
  botActivationCtrl.$inject = [
    '$scope',
    '$rootScope',
    'BotService',
    '$interval',
    'UserService'
  ];
  function botActivationCtrl(
    $scope,
    $rootScope,
    BotService,
    $interval,
    UserService
  ) {
    const vm = this;

    vm.$onInit = () => {
      init();
    };
    const init = () => {  
      vm.botCenterEnabled = isBotcenterActive(); 
      if (vm.botCenterEnabled) {  
        BotService.getBots().then(function (bots) {
          vm.bots = bots;
        });
      }
      UserService.getProfile().then(
        (user) => {
          vm.username = `${user.first_name} ${user.last_name}`;
          vm.isAdmin = user.isAdmin;
        }
      );
      loadBotTicket();
      vm.botForUserLoading = false;
      vm.loading = false;
      vm.botTicketInterval = $interval(loadBotTicket, 1000 * 10);
    };
    const isBotcenterActive = () => {
      const {integration_configs} = $rootScope.establishment.integrations;
      const integration = integration_configs.find(
        ({name}) => name === 'BOTCENTER'
      );
      return integration ? integration.config.active : false;
    };
    const updateBot = () => {
      vm.bots = [];
      vm.botTicketState = null;
      vm.botCenterEnabled = isBotcenterActive();

      if (vm.botCenterEnabled) {
        vm.bots = $rootScope.bots;
        BotService.getTicketState(vm.caseObject.resource_id).then(state => {
          vm.botTicketState = state;
        });
      }
    };
    const caseUpdatedListenerOff = $scope.$on('case:hasBeenUpdated', (_event, caseObject) => {
      $interval.cancel(vm.botTicketInterval);
      vm.caseObject = caseObject;
      updateBot();
      init();
    });
    const userUpdatedListenerOff = $scope.$on('userCase:hasBeenUpdated', (_event, userCase) => {
      vm.userObject = userCase;
    });

    $scope.$on('$destroy', () => {
      caseUpdatedListenerOff();
      userUpdatedListenerOff();
    });

    vm.$onDestroy = () => {
      $interval.cancel(vm.botTicketInterval);
    };

    const loadBotTicket = function () {
      BotService.getTicketState(vm.caseObject.resource_id).then(state => {
        vm.botTicketState = state;
      });
    };

    vm.activateBot = function (bot) {
      bot.loading = true;
      vm.loading = true;

      BotService.trigger(bot, vm.caseObject.resource_id).then(state => {
        vm.botTicketState = state;
        bot.loading = false;
        vm.loading = false;
      });
    };

    vm.terminateActiveBotSession = function () {
      vm.loading = true;
      BotService.terminateSession(vm.caseObject.resource_id).then(state => {
        vm.botTicketState = state;
        vm.loading = false;
      });
    };

    vm.disableBotForUser = function () {
      const {resource_id: userId} = vm.userObject;
      vm.botForUserLoading = true;
      UserService.setEnableBotForUser(userId, 'false').then(
        () => {
          // success
          vm.botForUserLoading = false;
          vm.userObject['bot_enabled'] = false;
        },
        () => {
          // error
          vm.botForUserLoading = false;
          vm.userObject['bot_enabled'] = true;
        }
      );
    };

    vm.enableBotForUser = function () {
      const {resource_id: userId} = vm.userObject;

      vm.botForUserLoading = true;
      UserService.setEnableBotForUser(userId, 'true').then(
        () => {
          // success
          vm.botForUserLoading = false;
          vm.userObject['bot_enabled'] = true;
        },
        () => {
          // error
          vm.botForUserLoading = false;
          vm.userObject['bot_enabled'] = false;
        }
      );
    };
  }
  module.controller('botActivationCtrl', botActivationCtrl);
})(angular.module('postCenterWebClientApp'));
