(module => {
  'use strict';
  snErrorMessage.$inject = [];
  module.factory('snErrorMessage', snErrorMessage);
  function snErrorMessage() {
    return {
      getMessage({sn = '', code = '0', subcode = '0', locale = 'es'} = {}) {
        if (!sn || !code) {
          return this.messages[locale].default;
        }
        code = !code ? '0' : code;
        subcode = !subcode ? '0' : subcode;
        const {
          [locale]: {
            ['default']: defaultMessage,
            [sn]: {[code]: {[subcode]: message = defaultMessage} = {}}
          }
        } = this.messages;
        return !message ? defaultMessage : message;
      },
      messages: {
        en: {
          default: 'An unexpected error has occurred, please try again',
          facebook: {
            '-1': {
              0: 'Unexpected internal error',
              2018012: 'Unexpected internal error'
            },
            4: {
              0: 'Too many send requests to phone numbers',
              2018022: 'Too many send requests to phone numbers'
            },
            10: {
              0: 'This message is sent outside of allowed window.',
              2018278: 'This message is sent outside of allowed window.',
              2018065: 'This message is sent outside of allowed window. You need News Messaging permission to be able to do it.',
              2018108: "This person isn't receiving messages from you right now."
            },
            100: {
              0: 'Length of param message[text] must be less than or equal to 2000.',
              2018109: 'Attachment size exceeds allowable limit',
              2018047: 'Upload attachment failure.',
              2018001: 'No matching user found',
              2018164: 'Incorrect App ID.',
              2018320: 'Invalid product id.'
            },
            190: {
              0: 'Invalid OAuth access token.',
              460: 'Error validating access token: The session has been invalidated because the user changed their password or Facebook has changed the session for security reasons.'
            },
            200: {
              0: "Message Not Sent: This person isn't available right now.",
              1545041: "Message Not Sent: This person isn't available right now.",
              2018028: 'Cannot message users who are not admins, developers or testers of the app until pages_messaging permission is reviewed and the app is live.',
              2018027: 'Cannot message users who are not admins, developers or testers of the app until pages_messaging_phone_number permission is reviewed and the app is live.'
            },
            551: {
              0: "This person isn't available right now.",
              1545041: "This person isn't available right now."
            },
            613: {
              0: 'Calls to this API have exceeded the rate limit'
            },
            1200: {
              0: 'Temporary send message failure. Please try again later.'
            },
            2022: {
              0: 'No se pudo compartir tu mensaje porque el enlace infringe nuestras Normas comunitarias.'
            }
          },
          twitter: {
            1: {
              0: 'You cannot send messages to users who are not following you or has blocked you'
            },
            2: {
              0: 'You cannot send message because the rate limit exceeded'
            },
            3: {
              0: 'You cannot respond the message because the message is deleted'
            },
            4: {
              0: 'You cannot send the message because is too long'
            },
            5: {
              0: 'You cannot send the message because you have reached the public message limit.'
            },
            6: {
              0: 'You cannot send the message because twitter classified as duplicate. Try adding date and time to your message and send.'
            }
          },
          instagram: {
            1: {
              0: 'An unknown error occurred'
            },
            20: {
              0: 'This person does not allow mentions',
              1772179: 'This person does not allow mentions',
            },
            100: {
              0: 'Message does not exist, cannot be loaded due to missing permissions, or does not support this operation',
              33: 'Message does not exist, cannot be loaded due to missing permissions, or does not support this operation',
            }
          }
        },
        es: {
          default: 'Se ha producido un error inesperado, vuelve a intentar',
          facebook: {
            '-1': {
              0: 'Error inesperado de facebook',
              2018012: 'Error inesperado de facebook'
            },
            4: {
              0: 'Se ha excedido el limite de envíos a números de teléfono',
              2018022: 'Se ha excedido el limite de envíos a números de teléfono'
            },
            10: {
              0: 'Este mensaje está siendo enviado fuera de la ventana de tiempo permitida',
              2018278: 'Este mensaje está siendo enviado fuera de la ventana de tiempo permitida',
              2018065: 'Este mensaje se envía fuera de la ventana permitida. Necesita permiso de mensajería de noticias para poder hacerlo.',
              2018108: 'Esta persona no está recibiendo mensajes desde ti en este momento'
            },
            100: {
              0: 'El largo del mensaje (texto) debe ser menor a 2000 caracteres.',
              2018109: 'El adjunto excede el tamaño límite',
              2018047: 'Error al subir el adjunto',
              2018001: 'No se encontró al usuario',
              2018164: 'App ID incorrecto.',
              2018320: 'ID de producto incorrecto.'
            },
            190: {
              0: 'Token de acceso es inválido',
              460: 'Error validando el token de acceso: La sesión fue invalidada debido a un cambio de contraseña o por razones de seguridad.'
            },
            200: {
              0: 'Mensaje no enviado: Esta persona no se encuentra disponible en este momento.',
              1545041: 'Mensaje no enviado: Esta persona no se encuentra disponible en este momento.',
              2018028: 'Usuarios que no son admin, desarrolladores o tester no pueden enviar mensajes, hasta que los permisos sean validados.',
              2018027: 'Usuarios que no son admin, desarrolladores o tester no pueden enviar mensajes, hasta que los permisos sean validados.'
            },
            551: {
              0: 'Mensaje no enviado: Esta persona no se encuentra disponible en este momento',
              1545041: 'Mensaje no enviado: Esta persona no se encuentra disponible en este momento'
            },
            613: {
              0: 'Se ha excedido el límite de llamadas a la API de Facebook'
            },
            1200: {
              0: 'Temporalmente el envío de mensajes no está disponible. Intente más tarde'
            },
            2022: {
              0: 'No se pudo compartir tu mensaje porque el enlace infringe nuestras Normas comunitarias.'
            }
          },
          twitter: {
            1: {
              0: 'No se puede enviar el mensaje debido a que el cliente no te sigue o te tiene bloqueado'
            },
            2: {
              0: 'No se pudo enviar el mensaje debido a que se alcanzo el límite de mensajes para la cuenta, reintente mas tarde'
            },
            3: {
              0: 'No se pudo enviar el mensaje porque el mensaje que se esta intentando responder se encuentra eliminado o no es visible por su cuenta'
            },
            4: {
              0: 'No se pudo enviar el mensaje porque supera el límite de caracteres permitido por Twitter'
            },
            5: {
              0: 'No se pudo enviar el mensaje porque se ha alcanzado el límite de mensajes publicos.'
            },
            6: {
              0: 'No se pudo enviar el mensaje porque Twitter lo clasificó como duplicado. Intenta agregar [Fecha Hora] a tu mensaje y envía nuevamente.'
            }
          },
          instagram: {
            1: {
              0: 'Ocurrió un error inesperado'
            },
            20: {
              0: 'La configuración del usuario no permite menciones',
              1772179: 'La configuración del usuario no permite menciones',
            },
            100: {
              0: 'El mensaje al que intentas responder no existe o no puede ser cargado o no pertenece a tu página.',
              33: 'El mensaje al que intentas responder no existe o no puede ser cargado o no pertenece a tu página.',
            }
          }
        }
      }
    };
  }
})(angular.module('postCenterWebClientApp'));
