const i18nEpaAnalytics = new VueI18n({
  messages: {
    es: {
      title: 'Satisfacción',
      cards: ['Encuestas por fecha', 'Encuestas por ejecutivo'],
      resume: {
        charts: [
          'Encuestas enviadas y respondidas',
          'Encuestas enviadas y respondidas por ejecutivo'
        ],
        chartsModalInfo: [
          [
            {
              iconClass: 'epa-graph-icon-blue-square',
              title: 'Respondidas en total',
              body: 'Encuestas respondidas en total (completadas + respondidas parcialmente) representadas por la barra azul oscuro.'
            },
            {
              iconClass: 'epa-graph-icon-light-blue-square',
              title: 'Enviadas',
              body: 'Encuestas enviadas, representadas por la barra azul claro.'
            },
            {
              iconClass: 'epa-graph-icon-blue-square-line',
              title: 'Tasa de respuesta',
              body: 'Tasa de respuesta, representada por la línea azul.'
            }
          ],
          [
            {
              iconClass: 'epa-graph-icon-blue-square',
              title: 'Respondidas en total',
              body: 'Encuestas respondidas en total por ejecutivo (completadas + respondidas parcialmente), representadas por la barra azul obscuro'
            },
            {
              iconClass: 'epa-graph-icon-light-blue-square',
              title: 'Enviadas',
              body: 'Encuestas enviadas por ejecutivo, representadas por la barra azul claro'
            },
            {
              iconClass: 'epa-graph-icon-blue-circle',
              title: 'Tasa de respuesta',
              body: 'Tasa de respuesta por ejecutivo (porcentaje de clientes que respondieron a las encuestas enviadas por el ejecutivo'
            }
          ]
        ]
      },
      csat: {
        charts: ['CSAT General', 'CSAT por ejecutivo'],
        chartsModalInfo: [
          [
            {
              iconClass: 'epa-graph-icon-blue-square',
              title: 'Promedio CSAT',
              body: 'Muestra la puntuación media de satisfacción de tus clientes, representada por la barra azul'
            },
            {
              iconClass: 'epa-graph-icon-golden-square-line',
              title: 'CSAT Neto',
              body: 'Muestra el porcentaje del índice de satisfacción de tus clientes, representada por la línea dorada'
            },
            {
              iconClass: 'epa-graph-icon-blue-square-line',
              title: 'CSAT',
              body: 'Muestra el puntaje de valoración positiva (percepción del cliente) respecto al servicio recibido, representada por la línea azul'
            }
          ],
          [
            {
              type: 'table',
              headers: ['Tipo de CSAT', 'Descripción'],
              rows: [
                [
                  'Mejor CSAT',
                  'Muestra el puntaje del más alto al más bajo de valoración positiva obtenida por ejecutivo.'
                ],
                [
                  'Peor CSAT',
                  'Muestra el puntaje del más bajo al más alto de valoración positiva obtenida por ejecutivo.'
                ],
                [
                  'Mejor CSAT neto',
                  'Muestra el porcentaje del más alto al más bajo del índice de satisfacción de tus clientes por ejecutivo.'
                ],
                [
                  'Peor CSAT neto',
                  'Muestra el porcentaje del más bajo al más alto del índice de satisfacción de tus clientesp por ejecutivo.'
                ],
                [
                  'Mejor promedio CSAT',
                  'Muestra la puntuación media del valor más alto al más bajo de satisfacción de tus clientes por ejecutivo.'
                ],
                [
                  'Peor promedio CSAT',
                  'Muestra la puntuación media del valor más bajo al más alto de satisfacción de tus clientes por ejecutivo.'
                ]
              ]
            }
          ]
        ]
      },
      ces: {
        charts: ['CES. Customer effort score', 'CES por ejecutivo'],
        chartsModalInfo: [
          [
            {
              iconClass: 'epa-graph-icon-blue-square',
              title: 'Promedio CES',
              body: 'Muestra la puntuación media de esfuerzo de tus clientes para la resolución de su problema y está representada por la barra azul obscuro'
            },
            {
              iconClass: 'epa-graph-icon-blue-square-line',
              title: 'CES Neto',
              body: 'Muestra el porcentaje del índice de esfuerzo de tus clientes para la resolución de su problema, representada por la línea azul.'
            }
          ],
          [
            {
              type: 'table',
              headers: ['Tipo de CES', 'Descripción'],
              rows: [
                [
                  'Mejor CES neto',
                  'Muestra el porcentaje del más alto al más bajo del menor esfuerzo de tus clientes por ejecutivo.'
                ],
                [
                  'Peor CES neto',
                  'Muestra el porcentaje del más bajo al más alto de menor esfuerzo de tus clientes por ejecutivo.'
                ],
                [
                  'Mejor promedio CES',
                  'Muestra la puntuación media del valor más alto al más bajo de menor esfuerzo de tus clientes por ejecutivo.'
                ],
                [
                  'Peor promedio CES',
                  'Muestra la puntuación media del valor más bajo al más alto de menor esfuerzo de tus clientes por ejecutivo.'
                ]
              ]
            }
          ]
        ]
      },
      fcr: {
        charts: ['FCR. Resolución al primer contacto', 'FCR por ejecutivo'],
        chartsModalInfo: [
          [
            {
              iconClass: 'epa-graph-icon-blue-square',
              title: 'Porcentaje de FCR',
              body: 'Muestra el porcentaje de FCR a manera general, es decir, cuántas encuestas fueron contestadas con la opción "Sï resolvimos en el primer contacto"/ el total de preguntas FCR respondidas y está representado por la barra azul.'
            }
          ],
          [
            {
              iconClass: 'epa-graph-icon-blue-square',
              title: 'Porcentaje de FCR por agente',
              body: 'Muestra el porcentaje de FCR por ejecutivo, es decir, cuántas preguntas FCR de las encuestas enviadas por este agente fueron contestadas por los clientes con la opción "Sí resolvimos en el primer contacto" / el total de preguntas de resolución respondidas y está representado por la barra azul'
            }
          ]
        ]
      },
      res: {
        charts: ['Capacidad resolutiva', 'Capacidad resolutiva por ejecutivo'],
        chartsModalInfo: [
          [
            {
              iconClass: 'epa-graph-icon-blue-square',
              title: 'Capacidad resolutiva general',
              body: 'Muestra el porcentaje de capacidad resolutiva a manera general, es decir, cuántas encuestas fueron contestadas con la opción "Sï resolvimos el problema"/ el total de preguntas de resolución respondidas y está representado por la barra azul.'
            }
          ],
          [
            {
              iconClass: 'epa-graph-icon-blue-square',
              title: 'Capacidad resolutiva general por agente',
              body: 'Muestra el porcentaje de capacidad resolutiva por ejecutivo, es decir, cuántas preguntas de Resolución enviadas en las encuestas enviadas por este ejecutivo, fueron contestadas por los clientes con la opción "Sí resolvimos el problema" / el total de preguntas de resolución respondidas y está representado por la barra azul'
            }
          ]
        ]
      },
      detail: {
        table: 'Detalle de encuestas',
        removedUser: 'Usuario eliminado',
        withoutTeam: 'Sin equipo',
        surveyDeleted: 'Encuesta eliminada'
      },
      filters: {
        clean: 'Limpiar',
        from: 'desde',
        to: 'hasta',
        accounts: 'Cuenta | Cuentas',
        surveys: 'Encuesta | Encuestas',
        agents: 'Ejecutivo | Ejecutivos'
      },
      tabs: {
        resume: 'Enviadas y respondidas',
        csat: 'CSAT',
        ces: 'CES',
        fcr: 'FCR',
        detail: 'Detalle',
        res: 'Resolución'
      },
      expandText: {
        more: 'Ver todo',
        less: 'Ver menos'
      },
      expandTable: 'Ver todos las encuestas | Volver',
      notify: {
        error: {
          download:
            'No se ha podido realizar la descarga, por favor volver a intentar'
        }
      },
      download: {
        button: 'Descargar'
      }
    },
    pt: {
      title: 'Satisfação',
      cards: ['Pesquisas por data', 'Pesquisas do executivo'],
      resume: {
        charts: [
          'Pesquisas enviadas e respondidas',
          'Pesquisas enviadas e respondidas pelo executivo'
        ],
        chartsModalInfo: [
          [
            {
              iconClass: 'epa-graph-icon-blue-square',
              title: 'Total Respondido',
              body: 'Pesquisas respondidas no total (preenchidas + parcialmente respondidas) representadas pela barra azul escura.'
            },
            {
              iconClass: 'epa-graph-icon-light-blue-square',
              title: 'Enviei',
              body: 'Pesquisas enviadas, representadas pela barra azul clara.'
            },
            {
              iconClass: 'epa-graph-icon-blue-square-line',
              title: 'Taxa de resposta',
              body: 'Taxa de resposta, representada pela linha azul.'
            }
          ],
          [
            {
              iconClass: 'epa-graph-icon-blue-square',
              title: 'Total Respondido',
              body: 'Pesquisas respondidas no total por executivo (preenchidas + parcialmente respondidas), representadas pela barra azul escura.'
            },
            {
              iconClass: 'epa-graph-icon-light-blue-square',
              title: 'Enviei',
              body: 'Pesquisas enviadas pelo executivo, representadas pela barra azul clara.'
            },
            {
              iconClass: 'epa-graph-icon-blue-circle',
              title: 'Taxa de resposta',
              body: 'Taxa de resposta por executivo (porcentagem de clientes que responderam às pesquisas enviadas pelo executivo'
            }
          ]
        ]
      },
      csat: {
        charts: ['CSAT Geral', 'CSAT por agente'],
        chartsModalInfo: [
          [
            {
              iconClass: 'epa-graph-icon-blue-square',
              title: 'CSAT médio',
              body: 'Mostra a pontuação média de satisfação de seus clientes, representada pela barra azul'
            },
            {
              iconClass: 'epa-graph-icon-golden-square-line',
              title: 'CSAT Net',
              body: 'Mostra a porcentagem do índice de satisfação de seus clientes, representado pela linha dourada'
            },
            {
              iconClass: 'epa-graph-icon-blue-square-line',
              title: 'CSAT',
              body: 'Mostra a nota de avaliação positiva (percepção do cliente) em relação ao atendimento recebido, representada pela linha azul'
            }
          ],
          [
            {
              type: 'table',
              headers: ['CSAT type', 'Description'],
              rows: [
                [
                  'Melhor CSAT',
                  'Mostra a pontuação da avaliação positiva mais alta para a mais baixa obtida pelo executivo.'
                ],
                [
                  'Pior CSAT',
                  'Mostra a pontuação da avaliação positiva mais baixa até a mais alta obtida pelo executivo.'
                ],
                [
                  'Melhor rede CSAT',
                  'Mostra a porcentagem do maior para o menor índice de satisfação de seus clientes por executivo.'
                ],
                [
                  'Pior rede de CSAT',
                  'Mostre a porcentagem do menor ao maior índice de satisfação do cliente por executivo.'
                ],
                [
                  'Melhor média de CSAT',
                  'Mostra a pontuação média do maior ao menor valor de satisfação do cliente por executivo.'
                ],
                [
                  'Pior média de CSAT',
                  'Mostra a pontuação média do menor ao maior valor de satisfação de seus clientes por executivo.'
                ]
              ]
            }
          ]
        ]
      },
      ces: {
        charts: ['CES. Nota de esforço do cliente', 'CES por agente'],
        chartsModalInfo: [
          [
            {
              iconClass: 'epa-graph-icon-blue-square',
              title: 'Média CES',
              body: 'Mostra a pontuação média de esforço de seus clientes para resolver o problema e é representada pela barra azul escura'
            },
            {
              iconClass: 'epa-graph-icon-blue-square-line',
              title: 'CES Net',
              body: 'Mostra a porcentagem do índice de esforço de seus clientes para a resolução de seu problema, representado pela linha azul.'
            }
          ],
          [
            {
              type: 'table',
              headers: ['Tipo de CES', 'Descripción'],
              rows: [
                [
                  'Melhor net CES',
                  'Exibe a porcentagem da maior para a menor do menor esforço de seus clientes por executivo.'
                ],
                [
                  'Pior CES líquido',
                  'Mostra a porcentagem do menor ao maior de menor esforço de seus clientes por executivo.'
                ],
                [
                  'Melhor média CES',
                  'Mostra a pontuação média do maior ao menor valor de menor esforço de seus clientes por executivo.'
                ],
                [
                  'Pior média CES',
                  'Mostra a pontuação média do menor ao maior valor de menor esforço de seus clientes por executivo.'
                ]
              ]
            }
          ]
        ]
      },
      fcr: {
        charts: ['FCR. ', 'FCR por agente'],
        chartsModalInfo: [
          [
            {
              iconClass: 'epa-graph-icon-blue-square',
              title: 'Porcentagem de RCF',
              body: 'Ele mostra o percentual de FCR em geral, ou seja, quantas pesquisas foram respondidas com a opção "Sim, resolvemos no primeiro contato" / o número total de perguntas de FCR respondidas e é representado pela barra azul.'
            }
          ],
          [
            {
              iconClass: 'epa-graph-icon-blue-square',
              title: 'Porcentagem de FCR por agente',
              body: 'Mostra o percentual de FCR por executivo, ou seja, quantas perguntas de FCR das pesquisas enviadas por esse agente foram respondidas pelos clientes com a opção "Sim, resolvemos no primeiro contato" / número total de questões de resolução respondidas e é representado pela barra azul'
            }
          ]
        ]
      },
      res: {
        charts: [
          'capacidad de resolução',
          'Capacidade de resolução por agente'
        ],
        chartsModalInfo: [
          [
            {
              iconClass: 'epa-graph-icon-blue-square',
              title: 'Capacidade de resolução geral',
              body: 'Ele mostra o percentual de capacidade de resolução em geral, ou seja, quantas pesquisas foram respondidas com a opção "Sim, resolvemos o problema" / o número total de questões de resolução respondidas e é representado pela barra azul.'
            }
          ],
          [
            {
              iconClass: 'epa-graph-icon-blue-square',
              title: 'Capacidade de resolução geral por agente',
              body: 'Mostra o percentual de capacidade de resolução por executivo, ou seja, quantas questões de resolução enviadas nas pesquisas enviadas por este executivo, foram respondidas pelos clientes com a opção "Sim resolvemos o problema" / o número total de questões de resolução respondidas e é representado pela barra azul'
            }
          ]
        ]
      },
      detail: {
        table: 'Detalhe da pesquisa',
        removedUser: 'Usuário excluído',
        withoutTeam: 'Sem time',
        surveyDeleted: 'Pesquisa removida'
      },
      filters: {
        clean: 'Limpar',
        from: 'de',
        to: 'até',
        accounts: 'Conta | Contas',
        surveys: 'Pesquisa | Pesquisas',
        agents: 'Executivo | Executivos'
      },
      tabs: {
        resume: 'Enviado e respondido',
        csat: 'CSAT',
        ces: 'CES',
        fcr: 'FCR',
        detail: 'Detalhe',
        res: 'Resolução'
      },
      expandText: {
        more: 'Ver tudo',
        less: 'Ver menos'
      },
      expandTable: 'Veja todas as pesquisas | Retorna',
      notify: {
        error: {
          download: 'O download falhou, por favor tente novamente'
        }
      },
      download: {
        button: 'Baixar'
      }
    },
    en: {
      title: 'Satisfaction',
      cards: ['Surveys by date', 'Surveys by agent'],
      resume: {
        charts: [
          'Surveys sent and answered',
          'Surveys sent and answered by agent'
        ],
        chartsModalInfo: [
          [
            {
              iconClass: 'epa-graph-icon-blue-square',
              title: 'Answered in total',
              body: 'Surveys answered in total (completed + partially answered) represented by the dark blue bar.'
            },
            {
              iconClass: 'epa-graph-icon-light-blue-square',
              title: 'Sent',
              body: 'Sent surveys, represented by the light blue bar.'
            },
            {
              iconClass: 'epa-graph-icon-blue-square-line',
              title: 'Response rate',
              body: 'Response rate, represented by the blue line.'
            }
          ],
          [
            {
              iconClass: 'epa-graph-icon-blue-square',
              title: 'Answered in total',
              body: 'Surveys answered in total per executive (completed + partially answered), represented by the dark blue bar.'
            },
            {
              iconClass: 'epa-graph-icon-light-blue-square',
              title: 'Sent',
              body: 'Surveys sent by executive, represented by the light blue bar.'
            },
            {
              iconClass: 'epa-graph-icon-blue-circle',
              title: 'Response rate',
              body: 'Response rate per executive (percentage of clients who responded to the surveys sent by the executive'
            }
          ]
        ]
      },
      csat: {
        charts: ['General CSAT', 'CSAT by agent'],
        chartsModalInfo: [
          [
            {
              iconClass: 'epa-graph-icon-blue-square',
              title: 'Average CSAT',
              body: 'Shows the average satisfaction score of your customers, represented by the blue bar'
            },
            {
              iconClass: 'epa-graph-icon-golden-square-line',
              title: 'CSAT Net',
              body: "Shows the percentage of your customers' satisfaction rating, represented by the gold line"
            },
            {
              iconClass: 'epa-graph-icon-blue-square-line',
              title: 'CSAT',
              body: 'Shows the positive assessment score (customer perception) regarding the service received, represented by the blue line'
            }
          ],
          [
            {
              type: 'table',
              headers: ['CSAT type', 'Description'],
              rows: [
                [
                  'Best CSAT',
                  'Shows the score from the highest to the lowest positive assessment obtained by executive.'
                ],
                [
                  'Worst CSAT',
                  'Shows the score from the lowest to the highest positive assessment obtained by executive.'
                ],
                [
                  'Best CSAT net',
                  'Shows the percentage from the highest to the lowest of the satisfaction index of your clients by executive.'
                ],
                [
                  'Worst CSAT net',
                  'Show the percentage from the lowest to the highest of your customer satisfaction index by executive.'
                ],
                [
                  'Best CSAT Average',
                  'Shows the average score from the highest to the lowest value of customer satisfaction by executive.'
                ],
                [
                  'Worst CSAT Average',
                  'Shows the average score from the lowest to the highest value of satisfaction of your clients by executive.'
                ]
              ]
            }
          ]
        ]
      },
      ces: {
        charts: ['CES. Customer effort score', 'CES by agent'],
        chartsModalInfo: [
          [
            {
              iconClass: 'epa-graph-icon-blue-square',
              title: 'Average CES',
              body: "Shows your customers' average effort score for solving their issue and is represented by the dark blue bar"
            },
            {
              iconClass: 'epa-graph-icon-blue-square-line',
              title: 'CES Net',
              body: "It shows the percentage of your customers' effort index for the resolution of their problem, represented by the blue line."
            }
          ],
          [
            {
              type: 'table',
              headers: ['Tipo de CES', 'Descripción'],
              rows: [
                [
                  'Best net CES',
                  "Displays the percentage from highest to lowest of your clients' least effort per executive."
                ],
                [
                  'Worst net CES',
                  'Shows the percentage from the lowest to the highest of least effort of your clients by executive.'
                ],
                [
                  'Best CES average',
                  'Shows the average score from highest to lowest value of least effort of your clients by executive.'
                ],
                [
                  'Worst CES average',
                  'Shows the average score from the lowest to the highest value of least effort of your clients by executive.'
                ]
              ]
            }
          ]
        ]
      },
      fcr: {
        charts: ['FCR. First contact resolution', 'FCR by agent'],
        chartsModalInfo: [
          [
            {
              iconClass: 'epa-graph-icon-blue-square',
              title: 'RCF percentage',
              body: 'It shows the percentage of FCR in general, that is, how many surveys were answered with the option "Yes, we resolved in the first contact" / the total number of FCR questions answered and is represented by the blue bar.'
            }
          ],
          [
            {
              iconClass: 'epa-graph-icon-blue-square',
              title: 'Percentage of FCR per agent',
              body: 'It shows the percentage of FCR per executive, that is, how many FCR questions of the surveys sent by this agent were answered by the clients with the option "Yes, we resolved in the first contact" / the total number of resolution questions answered and is represented by the blue bar'
            }
          ]
        ]
      },
      res: {
        charts: ['Resolution capacity', 'Resolution capacity by agent'],
        chartsModalInfo: [
          [
            {
              iconClass: 'epa-graph-icon-blue-square',
              title: 'General resolution capacity',
              body: 'It shows the percentage of resolution capacity in general, that is, how many surveys were answered with the option "Yes, we solved the problem" / the total number of resolution questions answered and is represented by the blue bar.'
            }
          ],
          [
            {
              iconClass: 'epa-graph-icon-blue-square',
              title: 'General resolution capacity per agent',
              body: 'Shows the percentage of resolution capacity per executive, that is, how many Resolution questions sent in the surveys sent by this executive, were answered by customers with the option "Yes we solved the problem" / the total number of resolution questions answered and is represented by the blue bar'
            }
          ]
        ]
      },
      detail: {
        table: 'Surveys detail',
        removedUser: 'User deleted',
        withoutTeam: 'Without team',
        surveyDeleted: 'Survey deleted'
      },
      filters: {
        clean: 'Clean',
        from: 'from',
        to: 'to',
        accounts: 'Account | Accounts',
        surveys: 'Survey | Surveys',
        agents: 'Agent | Agents'
      },
      tabs: {
        resume: 'Sent and answered',
        csat: 'CSAT',
        ces: 'CES',
        fcr: 'FCR',
        detail: 'Detail',
        res: 'Resolution'
      },
      expandText: {
        more: 'Show all',
        less: 'Show less'
      },
      expandTable: 'View all surveys | Back',
      notify: {
        error: {
          download: 'Download failed, please try again'
        }
      },
      download: {
        button: 'Download'
      }
    }
  },
  fallbackLocale: ['es', 'en', 'pt'],
  silentTranslationWarn: true,
  locale: 'es'
});
