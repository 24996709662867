Vue.component('Loader', {
  props: {
    size: {
      type: String,
      default: '1x',
      validator(size = '') {
        const validSizes = ['1x', '2x', '3x'];
        return validSizes.includes(size);
      }
    }
  },
  computed: {
    iconSize() {
      return `fa-${this.size}`;
    }
  },
  template: `<div :class="iconSize" class="vue-loader">
    <span class="fa fa-spinner fa-spin text-info" />
  </div>`
});
